import React from 'react';
import _ from 'lodash';
import { InputGroup, Autocomplete } from '~/components/form';
import { FilterContainer } from '~/components/crud/styles';

export default function FormFilter({ values, onListProfiles }) {
  return (
    <FilterContainer>
      <InputGroup
        type="text"
        name="name"
        label="Nome"
        maxLength={100} />

      <InputGroup
        type="text"
        name="email"
        label="E-mail"
        maxLength={100} />

      <Autocomplete
        name="profile"
        keyField="id"
        label="Perfil"
        clearable={true}
        value={_.get(values, 'profile')}
        valueFormat={row => `${row.name}`}
        loadData={onListProfiles}
        emptyText={'Pesquise um perfil'}
        tipText={'Digite... '}
        loadingText={'Carregando...'}
        notFoundText={'Não encontrado'}
        />
    </FilterContainer>
  );
}
