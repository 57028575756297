import { createActions, createReducer } from 'reduxsauce';
import produce from 'immer';

export const { Types, Creators } = createActions({
  load: [],
  loadSuccess: ['model'],
  update: ['data', 'actions'],
  sendTestMail: ['data'],
  checkMailIntegration: ['data'],
  createApiKey: ['data'],
  createTemporaryAccess: ['data'],
  webhookSendTest: ['data'],
}, { prefix: 'settings' });

const INITIAL_STATE = {
  loading: false,
  model: null
};

const handleLoad = (state = INITIAL_STATE, action) => produce(state, draft => {
  draft.model = {};
  draft.loading = true;
});

const handleLoadSuccess = (state = INITIAL_STATE, action) => produce(state, draft => {
  draft.loading = false;
  draft.model = action.model
});

export default createReducer(INITIAL_STATE, {
  [Types.LOAD]: handleLoad,
  [Types.LOAD_SUCCESS]: handleLoadSuccess,
});
