import styled from 'styled-components';
import { secondary } from '~/components/mixins/color';

export const InnerContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 10px;
  height: calc(100vh - 90px);
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const CardBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #fff;
  height: ${(props) => props.height || '210px'};
  margin-bottom: 10px;
  border-radius: 3px;
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.5);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 44px;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    padding: 0 5px;
    color: #777;
    border-bottom: 1px solid #efefef;
  }

  .body {
    display: flex;
    flex-direction: column;
    height: 200px;
    width: 100%;
    padding: 10px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .body-lg {
    display: flex;
    flex-direction: column;
    height: 300px;
    width: 100%;
    padding: 10px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .chart {
    height: 270px;
  }

  fieldset {
    border: 1px solid ${secondary.hex()};
    padding: 10px;
    width: 100%;
    min-width: 0;
    margin-bottom: ${(props) => props.noMargin ? '0' : '10px'};
    legend {
      color: #666;
      font-size: 12px;
      padding: 0 10px;
    }
  }

  .empty {
    width: 100%;
    height: 100%;
    font-size: 12px;
    color: #acacac;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .messages-stats {
    border-bottom: 1px solid #eee;
    thead tr {
      background-color: #ddd;
    }
    thead tr td:first-child {
      visibility: hidden;
    }
    td {
      font-size: 12px;
      padding: 5px 10px;
      height: 30px;
      text-align: center
    }
  }
  .messages {
    tbody tr:nth-of-type(odd) {
      border: none;
      background-color: #f3f3f3;
    }
    td {
      font-size: 12px;
      padding: 5px;
      height: 30px;
    }
    thead tr {
      background-color: #ddd;
    }
  }

  .bank-data {
    tbody tr:nth-of-type(odd) {
      border: none;
      background-color: #f3f3f3;
    }
    td {
      font-size: 12px;
      padding: 5px;
      height: 30px;
    }
    thead tr {
      background-color: #ddd;
    }
  }

  .financial {
    border: 1px solid #f3f3f3;
    tr:nth-of-type(odd) {
      border: none;
      background-color: #f3f3f3;
    }
    td {
      font-size: 12px;
      padding: 5px 10px;
      height: 30px;
    }
    td:nth-child(2) {
      text-align: right;
      font-weight: 800;
      color: #666;
    }
  }

  .bank-files {
    tr:nth-of-type(even) {
      background-color: #f3f3f3;
    }
    thead tr {
      background-color: #ddd;
    }
    thead tr td:first-child {
      visibility: hidden;
    }
    td {
      font-size: 12px;
      padding: 5px 10px;
      height: 30px;
    }
    td:nth-child(2), td:nth-child(3) {
      text-align: center;
    }
    tbody tr:first-child td {
      border-top: 1px solid #ddd;
    }
    tbody tr:last-child td {
      border-bottom: 1px solid #ddd;
    }
    tbody tr td {
      border-left: 1px solid #ddd;
      border-right: 1px solid #ddd;
    }
    td:nth-child(2) {
      border-left: thin solid #ddd;
      border-right: thin solid #ddd;
    }
  }
`;

export const EmptyContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  color: #999;
  font-size: 16px;
  opacity: 0.8;

  span {
    padding: 15px;
    background: #fff;
    border-radius: 3px;
  }
  background-image: repeating-linear-gradient(-45deg, transparent, transparent 5px, #fafafa  5px, #fafafa  10px);
  background-image: repeating-linear-gradient(-45deg, transparent 0 5px, #fafafa  5px 10px);
`;
