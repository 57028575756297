import { all, put, call, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import _ from 'lodash';
import api from '~/services/api';
import { getError } from  '~/helper';
import { Creators as RepresentativeActions, Types as RepresentativeTypes } from '~/store/modules/representative';

export function showError(error) {
  const msg =  getError(error);
  toast.error(msg);
};

export function* createOrUpdate({ data, actions }) {
  try {
    let editing = !!((data && data.id)),
      method = editing ? 'put' : 'post',
      path = editing ? 'update' : 'create';

    yield call(api[method], `representatives/${path}`, data);

    toast.success(`Representante ${editing ? 'atualizado' : 'criado'} com sucesso`);
    actions.closeForm && actions.closeForm();
    actions.reloadList && actions.reloadList();
  } catch (error) {
    showError(error);
  }
};

export function* createUser({ data, actions }) {
  try {
    let response = yield call(api.post, 'representatives/create-user', data),
      result = response.data;

    toast.success(`Cadastrado com sucesso, dados enviados para: ${_.get(result, 'email', '')}`);
    actions.closeForm && actions.closeForm();
    actions.reloadList && actions.reloadList();
  } catch (error) {
    showError(error);
  }
};

export function* list({ params }) {
  try {
    const response = yield call(api.post, 'representatives/list', params);
    yield put(RepresentativeActions.listSuccess(response.data));
  } catch (error) {
    showError(error);
  }
};

export function* load({ data }) {
  try {
    let id = _.get(data, 'id'),
    model = {}
    
    if (id && id !== 'new') {
      const response = yield call(api.get, 'representatives/load', { params: { id } });
      model = response.data
    }
    
    yield put(RepresentativeActions.loadSuccess(model));
  } catch (error) {
    showError(error);
  }
};


export function* updatePassword({ data, actions }) {
  try {
    const { name, ...rest } = data;
    const user = Object.assign({ name }, rest.oldPassword ? rest : {});
    yield call(api.put, 'representatives/update-password', user);
    toast.success('Senha alterada com sucesso');
    actions.closeModal();
  } catch (error) {
    showError(error);
  } finally {
    actions.setSubmitting(false);
  }
};

export function* resetPassword({ data, actions }) {
  try {
    const response = yield call(api.put, 'representatives/reset-password', data);
    const success = _.get(response, 'data.success', false)

    if(success) {
      toast.success('Senha redefinida com sucesso');
    }
    else {
      toast.error('Não foi identificado o usuário do representante');
    }
    
    actions.setSubmitting(false);
  } catch (error) {
    showError(error);
  } finally {
    actions.setSubmitting(false);
  }
};


export default all([
  takeLatest(RepresentativeTypes.LIST, list),
  takeLatest(RepresentativeTypes.LOAD, load),
  takeLatest(RepresentativeTypes.CREATE_USER, createUser),
  takeLatest(RepresentativeTypes.CREATE_OR_UPDATE, createOrUpdate),
  takeLatest(RepresentativeTypes.UPDATE_PASSWORD, updatePassword),
  takeLatest(RepresentativeTypes.RESET_PASSWORD, resetPassword),
])
