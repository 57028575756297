import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 10px;
  overflow: hidden;
`;

export const Inner = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  background: #fff;
  flex-direction: column;
  flex: 1;
  padding: 10px;
`;

export const Contents = styled.div`
  display: flex;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100% - 60px);
  flex-direction: column;
  flex: 1;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #e0e0e0;
  flex: 0 0 56px;
  margin-top: 10px;
  padding: 10px;
`;

export const InputButton = styled.div`
  font-size: 20px;
  cursor: pointer;
  right: 0 !important;
  padding: 10px;

  &:hover {
    color: #666 !important;
    background: rgba(0,0,0,0.08);
  }
`;
