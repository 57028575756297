import { createActions, createReducer } from 'reduxsauce';
import produce from 'immer';

export const { Types, Creators } = createActions({
  process: ['data'],
  processSuccess: ['data'],
}, { prefix: 'parsers' });

const INITIAL_STATE = {
  loading: false,
  model: {}
};

const handleProcess = (state = INITIAL_STATE, action) => produce(state, draft => {
  draft.loading = true;
  draft.model = {};
});

const handleProcessSuccess = (state = INITIAL_STATE, action) => produce(state, draft => {
  draft.loading = false;
  draft.model = action.data;
});

export default createReducer(INITIAL_STATE, {
  [Types.PROCESS]: handleProcess,
  [Types.PROCESS_SUCCESS]: handleProcessSuccess,
});
