import { all, put, call, takeLatest, delay } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import _ from 'lodash';
import api from '~/services/api';
import { getError } from '~/helper';
import { Creators as BillActions, Types as BillTypes } from '~/store/modules/bill';
import history from '~/services/history';
import confirm from '~/components/confirm';

export function showError(error) {
  const msg = getError(error);
  toast.error(msg);
}

export function* createOrUpdate({ data, actions }) {
  try {
    let formData = new FormData();
    formData.append('data', JSON.stringify(_.omit(data, 'files')));
    _.each(data.files, (file, index) => {
      formData.append(`files[${index}]`, file);
    });
    let editing = !!(data && data.id),
      method = editing ? 'put' : 'post',
      path = editing ? 'update' : 'create';

    const response = yield call(api[method], `bills/${path}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      }),
      id = _.get(response, 'data.id');

    toast.success(`Fatura ${editing ? 'atualizada' : 'criada'} com sucesso`);

    if (actions.headLess) {
      yield put({ type: BillTypes.LOAD, id });
    } else {
      yield put({ type: BillTypes.LIST, params: { offset: 0 } });

      const historystate = _.get(history, 'location.state', ''),
        historysearch = _.get(history, 'location.search', ''),
        historysource = _.get(history, 'location.sourcepath');
      if (historysource) {
        const historyid = _.get(historystate, 'id');
        history.push({
          pathname: _.isEmpty(historyid) ? `/${historysource}` : `/${historysource}/${historyid}`,
          search: historysearch,
          state: historystate
        });
      } else {
        history.push({
          pathname: `/bills`,
          search: historysearch,
          state: historystate
        });
      }
    }
  } catch (error) {
    showError(error);
  } finally {
    yield put({ type: BillTypes.CREATE_OR_UPDATE_SUCCESS });
  }
}

export function* load({ id }) {
  try {
    let model = {};
    if (id && id !== 'new') {
      let response = yield call(api.get, 'bills/load', { params: { id } });
      model = response.data;
    }

    yield put(BillActions.loadSuccess(model, {}));

    if (model.erpError) {
      let errMsg = `<p style="font-style: italic; padding:5px 10px;">${model.erpError}</p>`;

      const result = yield confirm.show({
        width: 800,
        height: 220,
        title: 'Atenção',
        text: `<strong>Ocorreu o erro abaixo durante a conciliação</strong>: <br/>${errMsg}<br/> `,
        options: [
          { label: 'Ignorar', value: 'skip' },
          { label: 'Tentar novamente', value: 'send', primary: true }
        ]
      });

      if (result === 'send') {
        yield put(BillActions.reprocessErp(_.pick(model, 'id')));
        yield delay(500);
        yield put(BillActions.load(_.get(model, 'id')));
      }
    }
  } catch (error) {
    yield put(BillActions.loadSuccess({}, {}, false));
    showError(error);
  }
}

export function* remove({ id }) {
  try {
    yield call(api.delete, 'bills/remove', { params: { id } });
    toast.success(`Fatura removida com sucesso`);
    history.push('/bills');
  } catch (error) {
    showError(error);
  }
}

export function* checkBillIsInInstallments({ values, email, showModalSendEmail }) {
  try {
    const id = _.get(values, 'id'),
      { data } = yield call(api.get, 'bills/check-in-installments', {
        params: { id }
      }),
      isInInstallments = _.get(data, 'isInInstallments', false);
    yield call(showModalSendEmail, isInInstallments, email, id);
  } catch (error) {
    showError(error);
  }
}

export function* listBankContracts({ params, callback }) {
  try {
    const response = yield call(api.get, 'bills/list-bank-contracts', {
      params
    });
    yield call(callback, response.data);
  } catch (error) {}
}

export function* listBusinessPartners({ term, callback }) {
  try {
    const response = yield call(api.get, 'bills/list-business-partners', {
      params: { term }
    });
    yield call(callback, response.data);
  } catch (error) {}
}

export function* listCompanies({ params, callback }) {
  try {
    const response = yield call(api.get, 'bills/list-companies', { params });
    yield call(callback, response.data);
  } catch (error) {}
}

export function* listBankAccountsGroups({ term, callback }) {
  try {
    const response = yield call(api.get, 'bills/list-bank-accounts-groups', {
      params: { term }
    });
    yield call(callback, response.data);
  } catch (error) {}
}

export function* list({ params }) {
  try {
    const response = yield call(api.post, 'bills/list', params);
    yield put(BillActions.listSuccess(response.data));
  } catch (error) {
    showError(error);
  }
}

export function* updateNotes({ data }) {
  try {
    const response = yield call(api.put, `bills/update-notes`, data);
    yield put(BillActions.updateNotesSuccess(response.data));
    toast.success(`Observação salva com sucesso`);
  } catch (error) {
    showError(error);
  }
}

export function* sendEmail({ data }) {
  try {
    let response = yield call(api.post, `bills/send-email`, data),
      message = _.size(data.ids) === 1 ? 'E-mail enviado' : 'E-mails enviados';
    toast.success(message);
    yield put(BillActions.sendEmailSuccess(response.data));
  } catch (error) {
    console.log(error);
    showError(error);
  }
}

export function* settleBatch({ data, filter, actions }) {
  try {
    actions.setSubmitting(true);
    const response = yield call(api.post, `bills/settle-batch`, data),
      ret = _.get(response, 'data');

    if (ret.success) {
      const message = _.size(data.ids) === 1 ? 'Baixa de fatura enviada com sucesso!' : 'Baixa de faturas enviadas com sucesso!';
      toast.success(message);
      actions.closeModal && actions.closeModal();
      yield put(BillActions.list({ ...filter, offset: 0 }));
    } else {
      toast.error(ret.message);
    }
  } catch (error) {
    showError(error);
  } finally {
    actions.setSubmitting(false);
  }
}

export function* requestErpData({ data }) {
  try {
    yield call(api.post, `bills/request-erp-data`, data);
    toast.success(`Solicitação feita com sucesso!`);
    yield put({ type: BillTypes.LOAD, id: data.id });
  } catch (error) {
    console.log(error);
    showError(error);
  }
}

export function* sendWhatsApp({ data }) {
  try {
    let response = yield call(api.post, `bills/send-whatsapp`, data),
      phone = _.get(response, 'data.phone'),
      text = _.get(response, 'data.text');

    window.open(`https://api.whatsapp.com/send?phone=${phone}&text=${window.encodeURIComponent(text)}`, '_blank');
  } catch (error) {
    console.log(error);
    showError(error);
  }
}

export function* cancel({ data }) {
  try {
    var response = yield call(api.post, `bills/cancel`, data),
      ret = _.get(response, 'data');

    if (ret.success) {
      toast.success(`Fatura cancelada com sucesso!`);
      yield put({ type: BillTypes.LOAD, id: data.id });
    } else {
      toast.error(ret.message);
    }
  } catch (error) {
    showError(error);
  }
}

export function* cancelBatch({ data, filter }) {
  try {
    var response = yield call(api.post, `bills/cancel-batch`, data),
      ret = _.get(response, 'data');

    if (ret.success) {
      const message = _.size(data.ids) === 1 ? 'Fatura cancelada com sucesso!' : 'Faturas canceladas com sucesso!';
      toast.success(message);
      yield put(BillActions.list({ ...filter, offset: 0 }));
    } else {
      toast.error(ret.message);
    }
  } catch (error) {
    showError(error);
  } finally {
    yield put({ type: BillTypes.CANCEL_SUCCESS });
  }
}

export function* reopen({ data }) {
  try {
    var response = yield call(api.post, `bills/reopen`, data),
      ret = _.get(response, 'data');

    if (ret.success) {
      toast.success(`Fatura reaberta com sucesso!`);
      yield put({ type: BillTypes.LOAD, id: data.id });
    } else {
      toast.error(ret.message);
    }
  } catch (error) {
    showError(error);
  }
}

export function* occurrence({ data, shipping }) {
  try {
    yield call(api.post, `bills/occurrence`, { ...data, shipping });
    toast.success(`Fatura processada com sucesso!`);
    yield put({ type: BillTypes.LOAD, id: data.id });
  } catch (error) {
    showError(error);
  }
}

export function* regenerate({ data, callback }) {
  try {
    yield call(api.post, `bills/regenerate`, data);
    toast.success(`Fatura reprocessada com sucesso!`);
    yield put({ type: BillTypes.LOAD, id: data.id });
    callback && callback();
    yield put(BillActions.regenerateSuccess());
  } catch (error) {
    showError(error);
  }
}

export function* reprocessErp({ data }) {
  try {
    yield call(api.post, `bills/reprocess-erp`, data);
    toast.success(`Tentativa de conciliação enviada novamente!`);
    yield put({ type: BillTypes.LOAD, id: data.id });
  } catch (error) {
    showError(error);
  }
}

export function* downloadFile({ data }) {
  try {
    const params = { id: data.id, filename: data.filename };
    const response = yield call(api.get, 'bills/download-file', {
      params,
      responseType: 'blob'
    });

    api.download(response, data.filename);
  } catch (error) {
    showError(error);
  }
}

export function* processInstruction({ data, action }) {
  try {
    yield call(api.post, 'bills/process-instruction', data);
    toast.success(`Instrução processada com sucesso!`);
    action.toggleModal();
    yield put(BillActions.load(_.get(data, 'billId')));
  } catch (error) {
    showError(error);
  }
  yield put(BillActions.processInstructionSuccess());
}

export function* settle({ data }) {
  try {
    yield call(api.post, 'bills/settle', data);
    toast.success(`Baixa manual da fatura enviada com sucesso!`);
    yield put(BillActions.load(_.get(data.data, 'id')));
  } catch (error) {
    showError(error);
  }
}

export default all([
  takeLatest(BillTypes.CREATE_OR_UPDATE, createOrUpdate),
  takeLatest(BillTypes.LOAD, load),
  takeLatest(BillTypes.LIST, list),
  takeLatest(BillTypes.REMOVE, remove),
  takeLatest(BillTypes.LIST_BANK_CONTRACTS, listBankContracts),
  takeLatest(BillTypes.LIST_BUSINESS_PARTNERS, listBusinessPartners),
  takeLatest(BillTypes.LIST_COMPANIES, listCompanies),
  takeLatest(BillTypes.LIST_BANK_ACCOUNTS_GROUPS, listBankAccountsGroups),
  takeLatest(BillTypes.UPDATE_NOTES, updateNotes),
  takeLatest(BillTypes.SEND_EMAIL, sendEmail),
  takeLatest(BillTypes.SETTLE_BATCH, settleBatch),
  takeLatest(BillTypes.REQUEST_ERP_DATA, requestErpData),
  takeLatest(BillTypes.SEND_WHATS_APP, sendWhatsApp),
  takeLatest(BillTypes.CANCEL, cancel),
  takeLatest(BillTypes.CANCEL_BATCH, cancelBatch),
  takeLatest(BillTypes.REOPEN, reopen),
  takeLatest(BillTypes.OCCURRENCE, occurrence),
  takeLatest(BillTypes.REGENERATE, regenerate),
  takeLatest(BillTypes.REPROCESS_ERP, reprocessErp),
  takeLatest(BillTypes.DOWNLOAD_FILE, downloadFile),
  takeLatest(BillTypes.PROCESS_INSTRUCTION, processInstruction),
  takeLatest(BillTypes.SETTLE, settle),
  takeLatest(BillTypes.CHECK_BILL_IS_IN_INSTALLMENTS, checkBillIsInInstallments)
]);
