import { createActions, createReducer } from 'reduxsauce';
import produce from 'immer';
import _ from 'lodash';
import { getBankLogo } from '~/assets/banks';
import { formats } from '~/helper';

export const { Types, Creators } = createActions(
  {
    load: ['params'],
    loadSuccess: ['data'],
    listBankContracts: [],
    listBankContractsSuccess: ['data'],
    listBillTypes: ['term', 'callback'],
    listCompanies: ['term', 'callback'],
    movement: ['oldData', 'data', 'bankContracts'],
    movementSuccess: ['data'],
    movementInBatch: ['bankContract', 'selecteds', 'actions'],
    shippingCreate: ['data', 'actions'],
    shippingCreateSuccess: []
  },
  {
    prefix: 'prepareBills'
  }
);

const INITIAL_STATE = {
  loading: false,
  isShippingCreating: false,
  bills: {},
  bankContracts: {},
  summaryData: {}
};

const handleLoad = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.loading = true;
  });

const handleLoadSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.loading = false;
    draft.bills = _.groupBy(action.data, 'bankContractId');
    draft.summaryData = prepareSummary(draft.bills, draft.summaryData);
  });

const handleListBankContracts = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.loading = true;
  });

const handleListBankContractsSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.loading = false;
    draft.bankContracts = _.keyBy(
      _.map(action.data, row => ({ ...row, logo: getBankLogo(row.code) })),
      'id'
    );
    draft.summaryData = prepareSummary(draft.bills, initSummary(draft.bankContracts));
  });

const handleMovementSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.bills = _.get(action, 'data');
    draft.summaryData = prepareSummary(draft.bills, draft.summaryData);
  });

const handleshippingCreate = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.isShippingCreating = true;
  });

const handleshippingCreateSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.isShippingCreating = false;
  });

const initSummary = (bankContracts) => {
  const summary = {};
  _.each(bankContracts, (bankContract, id) => {
    if (id !== '000') {
      const { bankAccount = {}, accountsReceivable = 0, accountsPayable = 0 } = _.pick(bankContract.extra, ['bankAccount', 'accountsReceivable', 'accountsPayable']);
      const { balanceAmount = 0 } = bankAccount;
      const total = balanceAmount + (accountsReceivable - accountsPayable);

      summary[id] = [
        {
          label: 'Saldo inicial',
          value: formats.currency(balanceAmount)
        },
        {
          label: 'Contas a receber',
          value: formats.currency(accountsReceivable),
          raw: accountsReceivable
        },
        {
          label: 'Contas a pagar',
          value: formats.currency(accountsPayable)
        },
        {
          label: 'Faturas Selecionadas',
          value: formats.currency(0)
        },
        {
          label: 'Saldo',
          value: formats.currency(total),
          raw: total
        }
      ];
    }
  });
  return summary;
};

const prepareSummary = (bills, summaryData) => {
  _.each(bills, (data, id) => {
    const selectedValue = _.reduce(data, (sum, row) => row.value + sum, 0);
    _.each(summaryData, (summary = [], summaryId) => {
      if (summaryId === id) {
        summary[3].value = formats.currency(selectedValue);
        summary[4].value = formats.currency(summary[4].raw + selectedValue);
      }
    });
  });
  return summaryData;
};

export default createReducer(INITIAL_STATE, {
  [Types.LOAD]: handleLoad,
  [Types.LOAD_SUCCESS]: handleLoadSuccess,
  [Types.LIST_BANK_CONTRACTS]: handleListBankContracts,
  [Types.LIST_BANK_CONTRACTS_SUCCESS]: handleListBankContractsSuccess,
  [Types.MOVEMENT_SUCCESS]: handleMovementSuccess,
  [Types.SHIPPING_CREATE]: handleshippingCreate,
  [Types.SHIPPING_CREATE_SUCCESS]: handleshippingCreateSuccess
});
