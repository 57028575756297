
import * as Boleto from '~/pages/bills/forms/boleto';
import * as DebitoAutomatico from '~/pages/bills/forms/debito-automatico';
import * as Pix from '~/pages/bills/forms/pix';
import * as Transferencia from '~/pages/bills/forms/transferencia';

export {
  Boleto,
  DebitoAutomatico,
  Pix,
  Transferencia,
};
