import React, { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MdClear, MdCheck, MdSend } from 'react-icons/md';
import { VscDebugRerun } from 'react-icons/vsc';
import _ from 'lodash';
import FormFilter from '~/pages/webhook-bills/filter';
import confirm from '~/components/confirm';
import { Creators as WebhookActions } from '~/store/modules/webhook-bill';
import { Creators as HeaderActions } from '~/store/modules/header';
import { Container } from '~/pages/webhook-bills/styles';
import { WebhookBillForm } from '~/pages/webhook-bills/form';
import { SubtitleItem, Subtitle } from '~/components/datatable/subtitle';
import FloatingButton from '~/components/floatbutton'
import Crud from '~/components/crud';
import CellStatus from '~/components/datatable/cell-status';
import { red, green } from '~/components/mixins/color';
import useSearchParameters from '~/hooks/use-search-params';
import { formats } from '~/helper';

const columns = [
  {
    name: ' ',
    selector: 'status',
    width: '10px',
    cell: (row) => <CellStatus title="" color={row.success ?  green.hex() : red.hex()} />
  },
  {
    name: 'URL',
    selector: 'url',
    format: (row) => _.get(row, 'url')
  },
  {
    name: 'Faturas com evento',
    selector: 'events',
    width: '150px',
    center: true,
    format: (row) => formats.number(_.size(row.events))
  },
  {
    name: 'Tentativas',
    selector: 'attempts',
    width: '150px',
    center: true,
    format: (row) => formats.number(row.attempts || 1)
  },
  {
    name: 'Enviado em',
    selector: 'sendDate',
    width: '150px',
    format: (row) => formats.dateTimeZone(_.get(row, 'sendDate'), 'dd/MM/yyyy HH:mm')
  }
];

function WebhookBill({ match, acls, history }) {
  const dispatch = useDispatch();
  const state = useSelector(state => state.webhookBill);
  const headerState = useSelector(state => state.header);
  const canWrite = acls.includes('W');
  const [openForm, setOpenForm] = useState(!!_.get(match, 'params.id'));
  const showFilter = headerState.filter.visible;

  const {
    location,
    routeParams,
    getAllParams,
    toStringify
  } = useSearchParameters()

  useLayoutEffect(() => {
    const config = {
      loading: state.loading,
      useFilter: true,
      filter: {
        ...headerState.filter,
        visible: false,
      },
    }
    dispatch(HeaderActions.configure(config))
    // eslint-disable-next-line
  }, [])

  useLayoutEffect(() => {
    const { id, offset, limit = 20, ...filter } = getAllParams()

    const listMethod =
      _.isEmpty(id) || _.size(_.get(state, 'data.rows')) === 0
        ? WebhookActions.list
        : null

    if (id) {
      dispatch(WebhookActions.load({ id }))
    }

    dispatch(
      HeaderActions.callFilter(
        { ...filter, offset: _.toInteger(offset), limit: _.toInteger(limit) },
        listMethod,
      ),
    )
    // eslint-disable-next-line
  }, [location, routeParams])

  async function handleResend(data, actions) {
    let result = await confirm.show({
        title: 'Atenção',
        width: 530,
        text: 'Deseja realmente reenviar os dados para o Webhook?'
      });

    if (result) {
      dispatch(WebhookActions.resend({ ids: [_.get(data, 'data.id')] }, actions));
    }
  };

  const resendBatch = async () => {
    const result = await confirm.show({
        title: 'Atenção',
        text: `Deseja realmente reenviar os dados dos Webhooks selecionados?`
      })
    if (result) {
      let params = { ids: _.map(state.selecteds, 'id') }
      dispatch(WebhookActions.resendBatch(params))
    }
  }

  function changeSelectWebhook(selecteds) {
    dispatch(WebhookActions.changeSelectWebhook(selecteds))
  };

  function handlePageChange(offset, limit) {
    const filter = getAllParams()
    history.push({
      pathname: '/webhook-bills',
      search: `?${toStringify({
        ..._.omit(filter, [
          'id',
          'company',
          'bankAccount',
          'createdAtStart',
          'createdAtEnd',
        ]),
        offset,
        limit,
      })}`,
      state: { ..._.omit(filter, 'id'), offset, limit },
    })
    setOpenForm(false)
  };

  function handleLoad(data) {
    const filter = getAllParams()
    history.push({
      pathname: `/webhook-bills/${_.get(data, 'id', '')}`,
      search: `?${toStringify(
        _.omit(filter, [
          'id',
          'company',
          'bankAccount',
          'createdAtStart',
          'createdAtEnd',
        ]),
      )}`,
      state: filter,
    })
    setOpenForm(true)
  };

  function handleHideFilter() {
    dispatch(HeaderActions.hideFilter());
    setOpenForm(false);
  };

  function handleFilter(filter) {
    const historySearch = {
      ..._.omit(filter, [
        'company',
        'bankAccount',
        'createdAtStart',
        'createdAtEnd',
      ]),
      offset: 0,
    }
    handleHideFilter()
    history.push({
      pathname: `/webhook-bills`,
      search: `?${toStringify(historySearch)}`,
      state: filter,
    })
  };

  function handleOpenDocument(id) {
    history.push(`/bills/${id}`);
  };

  return (
    <Container>
      {!state.model &&
        <Subtitle bottom={25} left={20}>
          <span>Legenda:</span>
          <SubtitleItem color={red.hex()}>Enviado</SubtitleItem>
          <SubtitleItem color={green.hex()}>Não enviado</SubtitleItem>
        </Subtitle>
      }

      {!showFilter && !openForm &&
        _.size(state.selecteds) > 0 && (
          <FloatingButton
            icon={MdSend}
            title="Reenviar em lote"
            onClick={() => resendBatch()}
          />
      )}

      <Crud
        useOpenForm={true}
        openForm={openForm || showFilter}
        hideAdd={true}
        columns={columns}
        emptyText="Nenhum webhook processado."
        formTitle={data => (showFilter ? `Filtro` : 'Dados do Webhook')}
        data={state.data}
        tableLoading={state.loading}
        formLoading={state.formLoading}
        onChangePage={handlePageChange}
        onCloseFilter={handleHideFilter}
        rightWidth={`${showFilter ? '500px' : '700px'}`}
        onRowClicked={handleLoad}
        formOptions={{
          initialValues: showFilter ? headerState.filter.data : (state.model || {}),
          initialTouched: showFilter ? {} : { name: true }
        }}
        renderForm={args => showFilter ?
          <FormFilter
            {...args}
            />
          :
          <WebhookBillForm
            {...args}
            loading={state.formLoading}
            loadingMessage={state.formLoadingMessage}
            openDocument={handleOpenDocument}
            previewMode={!canWrite}
            />
        }
        actions={showFilter ?
          [
            { label: 'Limpar Filtro', icon: MdClear, action: () => handleFilter({}) },
            { label: 'Aplicar Filtro', icon: MdCheck, action: data => handleFilter(data) }
          ]
          :
          [
            {
              label: 'Reenviar',
              icon: VscDebugRerun,
              isSubmit: true,
              isDisabled: () => !canWrite,
              action: handleResend
            }
          ]
        }
        dataTableOptions={{
          ignoreRowClicked: true,
          selectableRowsHighlight: false,
          selectableRows: true,
          selectableRowSelected: (row) => {
            return _.find(state.selecteds, { id: row.id })
          },
          onSelectedRowsChange: ({
            allSelected,
            selectedCount,
            selectedRows,
          }) => {
            changeSelectWebhook(selectedRows)
          },
        }}
        />
    </Container>
  );
}

export default WebhookBill;
