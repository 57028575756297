import React, { useLayoutEffect } from 'react'
import _ from 'lodash'
import {
  Row,
  FlexRow,
  FlexCol,
  BsContainer,
  BsRow,
  BsCol,
} from '~/components/layout'
import { Formik } from 'formik'
import { isBefore, isAfter, addDays } from 'date-fns'
import {
  InputLabel,
  Autocomplete,
  InputDate,
  InputGroup,
  CurrencyField,
} from '~/components/form'
import Fieldset from '~/components/fieldset'
import DataTable from '~/components/datatable'
import { IconButton } from '~/components/button'
import { MdCheck, MdClear } from 'react-icons/md'
import {
  formatBankContractSimple,
  formatBankContract,
} from '~/pages/bank-contracts/form'
import {
  FormContainer,
  TableContainer,
  Summary,
} from '~/pages/credits-receiveds/styles'
import { statusLabels } from '~/pages/credits-receiveds'
import { docmentTypes } from '~/pages/credits-receiveds'
import { formats } from '~/helper'
import { isValidCnpj } from '~/validator'

const customStyles = {
  rows: {
    denseStyle: {
      minHeight: '30px',
    },
  },
}

const sumArray = (list) => {
  return _.sumBy(list, (r) => (isNaN(r.value) ? 0 : Number(r.value)))
}

const getInitialFilter = (values) => {
  const baseDate = _.get(values, 'docDate')

  return {
    dueDateStart: addDays(formats.getDate(baseDate), -5),
    dueDateEnd: addDays(formats.getDate(baseDate), 5),
    identity: _.get(values, 'bankContract.bankAccount.company.identity'),
    senderIdentity: _.get(values, 'senderIdentity'),
  }
}

function Form({
  values,
  setFieldValue,
  previewMode,
  onOpenDocument,
  onListBankContracts,
  onListBills,
  bills,
}) {
  const selecteds = _.get(values, 'orderSelection') || []
  const selectedsRows = _.filter(bills, (r) => selecteds.includes(r.id))
  const bindeds = _.get(values, 'bindeds') || []
  const initialFilter = getInitialFilter(values)

  useLayoutEffect(() => {
    if (values.senderIdentity) {
      onListBills(initialFilter)
    }
    // eslint-disable-next-line
  }, [values.senderIdentity])

  if (_.isEmpty(values)) {
    return null
  }

  const changeSelecteds = (item) => {
    if (_.isArray(item)) {
      setFieldValue('orderSelection', _.map(item, 'id'))
      setFieldValue('orderSelectionSum', sumArray(item))
    } else {
      let list = _.cloneDeep(selecteds || []),
        index = _.findIndex(list, (r) => r === item.id),
        sum = _.get(values, 'orderSelectionSum') || 0
      if (index >= 0) {
        sum -= item.value
        list.splice(index, 1)
      } else {
        sum += item.value
        list.push(item.id)
      }
      setFieldValue('orderSelection', list)
      setFieldValue('orderSelectionSum', sum)
    }
  }

  return (
    <FormContainer>
      <BsContainer>
        <BsRow>
          <BsCol md={4} sm={4}>
            <InputLabel
              label="Situação"
              value={statusLabels[_.get(values, 'status')] || ''}
            />
          </BsCol>

          <BsCol md={5} sm={5}>
            <InputLabel
              label="Valor"
              value={formats.currency(_.get(values, 'value'))}
            />
          </BsCol>

          <BsCol md={5} sm={5}>
            <InputLabel
              label="Data de lançamento"
              value={formats.dateTimeZone(
                _.get(values, 'docDate'),
                'dd/MM/yyyy',
              )}
            />
          </BsCol>

          <BsCol md={5} sm={5}>
            <InputLabel
              label="Número documento"
              value={_.get(values, 'docNumber')}
            />
          </BsCol>

          <BsCol md={5} sm={5}>
            <InputLabel
              label="Tipo"
              value={docmentTypes[_.get(values, 'docType')]}
            />
          </BsCol>

          <BsCol md={24} sm={24}>
            <InputLabel
              label="Remetente"
              value={
                !_.get(values, 'senderIdentity')
                  ? ''
                  : `${formats.cnpj_cpf(
                      isValidCnpj(_.get(values, 'senderIdentity'))
                        ? _.get(values, 'senderIdentity')
                        : String(_.get(values, 'senderIdentity')).length > 3
                        ? String(_.get(values, 'senderIdentity')).substring(3)
                        : _.get(values, 'senderIdentity'),
                    )} - ${_.get(values, 'senderName')}`
              }
            />
          </BsCol>

          <BsCol md={24} sm={24}>
            <InputLabel
              label="Empresa"
              value={
                !_.get(values, 'bankContract.bankAccount.company.identity')
                  ? ''
                  : `${formats.cnpj_cpf(
                      _.get(
                        values,
                        'bankContract.bankAccount.company.identity',
                      ),
                    )} - ${_.get(
                      values,
                      'bankContract.bankAccount.company.name',
                    )}`
              }
            />
          </BsCol>

          <BsCol md={24} sm={24}>
            <Autocomplete
              name="bankContract"
              label="Contrato"
              value={_.get(values, 'bankContract')}
              keyField="id"
              clearable={true}
              loadData={onListBankContracts}
              valueFormat={formatBankContractSimple}
              optionsFormat={formatBankContract}
              emptyText={'Pesquise um contrato'}
              tipText={'Digite... '}
              loadingText={'Carregando...'}
              notFoundText={'Não encontrado'}
              disabled={true}
            />
          </BsCol>

          {!previewMode && (
            <BsCol md={24} sm={24}>
              <Formik enableReinitialize={true} initialValues={initialFilter}>
                {(formik) => (
                  <Fieldset label="Faturas vindas do ERP">
                    <Fieldset label="Filtro">
                      <FlexRow>
                        <FlexCol flex="1">
                          <InputGroup
                            type="text"
                            name="documentNumber"
                            label="Número"
                            maxLength={30}
                            noMargin={true}
                          />
                        </FlexCol>

                        <FlexCol flex="0 0 155px">
                          <InputDate
                            name="dueDateStart"
                            label="lançamento de"
                            noMargin={true}
                            value={_.get(formik, 'values.dueDateStart')}
                            onChange={(value) => {
                              formik.setFieldValue('dueDateStart', value)
                              if (
                                values.dueDateEnd &&
                                isAfter(value, values.dueDateEnd)
                              ) {
                                formik.setFieldValue(
                                  'dueDateStart',
                                  values.dueDateEnd,
                                )
                                formik.setFieldValue('dueDateEnd', value)
                              }
                            }}
                          />
                        </FlexCol>

                        <FlexCol flex="0 0 155px">
                          <InputDate
                            name="dueDateEnd"
                            label="lançamento até"
                            noMargin={true}
                            value={_.get(formik, 'values.dueDateEnd')}
                            onChange={(value) => {
                              formik.setFieldValue('dueDateEnd', value)
                              if (
                                values.dueDateStart &&
                                isBefore(value, values.dueDateStart)
                              ) {
                                formik.setFieldValue('dueDateStart', value)
                                formik.setFieldValue(
                                  'dueDateEnd',
                                  values.dueDateStart,
                                )
                              }
                            }}
                          />
                        </FlexCol>

                        <FlexCol flex="0 0 120px">
                          <CurrencyField
                            name="valueStart"
                            label="Valor de"
                            noMargin={true}
                          />
                        </FlexCol>

                        <FlexCol flex="0 0 120px">
                          <CurrencyField
                            name="valueEnd"
                            label="Valor até"
                            noMargin={true}
                          />
                        </FlexCol>

                        <FlexCol flex="0 0 73px">
                          <Row
                            span={2}
                            gap="1px"
                            align="center"
                            style={{ height: '46px' }}
                          >
                            <IconButton
                              title="Limpar filtro"
                              noMargin={true}
                              onClick={() => {
                                formik.resetForm()
                              }}
                            >
                              <MdClear />
                            </IconButton>

                            <IconButton
                              title="Filtrar"
                              noMargin={true}
                              onClick={() => {
                                onListBills(formik.values)
                              }}
                            >
                              <MdCheck />
                            </IconButton>
                          </Row>
                        </FlexCol>
                      </FlexRow>
                    </Fieldset>

                    <TableContainer>
                      {!_.isEmpty(selectedsRows) && (
                        <Summary>
                          ({_.size(selectedsRows)}){' '}
                          {formats.currency(values.orderSelectionSum || 0)}
                        </Summary>
                      )}

                      <DataTable
                        emptyText="Sem resultados"
                        data={{ rows: bills }}
                        noPagination={true}
                        onRowClicked={changeSelecteds}
                        hideUpdateButton={true}
                        columns={[
                          {
                            name: 'Empresa',
                            selector: 'identity',
                            compact: false,
                            format: (row) =>
                              formats.cnpj_cpf(_.get(row, 'company.identity')),
                          },
                          {
                            name: 'Parceiro',
                            selector: 'businessPartner',
                            compact: false,
                            format: (row) =>
                              formats.cnpj_cpf(
                                _.get(row, 'businessPartner.identity'),
                              ),
                          },
                          {
                            name: 'Documento',
                            selector: 'documentNumber',
                            format: (row) => _.get(row, 'documentNumber'),
                          },
                          {
                            name: 'Data de lançamento',
                            selector: 'docDate',
                            compact: true,
                            center: true,
                            width: '140px',
                            format: (row) =>
                              formats.dateTimeZone(
                                _.get(row, 'docDate'),
                                'dd/MM/yyyy',
                              ),
                          },
                          {
                            name: 'Valor',
                            selector: 'value',
                            compact: false,
                            right: true,
                            format: (row) =>
                              formats.currency(_.get(row, 'value')),
                          },
                        ]}
                        extraOptions={{
                          customStyles,
                          ignoreRowClicked: true,
                          selectableRowsHighlight: false,
                          selectableRows: !previewMode,
                          selectableRowSelected: (row) => {
                            return _.find(selecteds, (r) => r === row.id)
                          },
                          onSelectedRowsChange: ({
                            allSelected,
                            selectedCount,
                            selectedRows,
                          }) => {
                            if (!previewMode) {
                              changeSelecteds(selectedRows)
                            }
                          },
                        }}
                      />
                    </TableContainer>
                  </Fieldset>
                )}
              </Formik>
            </BsCol>
          )}

          {previewMode && !_.isEmpty(bindeds) && (
            <BsCol md={24} sm={24}>
              <Fieldset label="Crédito recebido vinculado">
                <TableContainer>
                  <DataTable
                    noPagination={true}
                    emptyText="Nenhum crédito recebido vinculado"
                    onRowClicked={onOpenDocument}
                    data={{ rows: bindeds }}
                    columns={[
                      {
                        name: 'Documento',
                        selector: 'documentNumber',
                        compact: false,
                        format: (row) => _.get(row, 'documentNumber'),
                      },
                      {
                        name: 'Data de lançamento',
                        selector: 'dueDate',
                        compact: true,
                        center: true,
                        width: '140px',
                        format: (row) =>
                          formats.dateTimeZone(
                            _.get(row, 'dueDate'),
                            'dd/MM/yyyy',
                          ),
                      },
                      {
                        name: 'Valor',
                        selector: 'value',
                        compact: false,
                        right: true,
                        format: (row) => formats.currency(_.get(row, 'value')),
                      },
                    ]}
                    extraOptions={{
                      customStyles,
                    }}
                  />
                </TableContainer>
              </Fieldset>
            </BsCol>
          )}
        </BsRow>
      </BsContainer>
    </FormContainer>
  )
}

export default Form
