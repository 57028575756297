import React from 'react'
import _ from 'lodash'
import Modal from '~/components/modal'
import { SetteBatchContainer } from './styles'
import { InputDate } from '~/components/form'
import { Formik } from 'formik'
import { sub } from 'date-fns'
import * as Yup from 'yup'
import { formats } from '~/helper'

const SettleBatchModal = ({
  isOpen,
  toggleModal,
  settleList,
  handleOnSubmit,
}) => {
  const schema = Yup.object().shape({
    payDate: Yup.date().required('Informe a data de pagamento'),
  })

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{ payDate: new Date() }}
      validationSchema={schema}
      onSubmit={handleOnSubmit}
    >
      {({ isValid, handleSubmit, isSubmitting, handleReset, values }) => {
        const canSettle = () => {
          if (!values.payDate) return []
          return _.filter(settleList, (row) => {
            const documentDate = new Date(row.documentDate)
            values.payDate.setHours(0, 0, 0, 0)
            documentDate.setHours(0, 0, 0, 0)
            return values.payDate >= documentDate
          })
        }

        const cantSettle = () => {
          if (!values.payDate) return []
          
          return _.filter(settleList, (row) => {
            const documentDate = new Date(row.documentDate)
            values.payDate.setHours(0, 0, 0, 0)
            documentDate.setHours(0, 0, 0, 0)
            return values.payDate < documentDate
          })
        }
        
        const sumCanSettle = () => _.sum(_.map(canSettle(), 'value'))

        const sumCantSettle = () => _.sum(_.map(cantSettle(), 'value'))

        return (
          <Modal
            width="650px"
            height="305px"
            title={`Baixa manual de Transferência(s)`}
            hideClose={true}
            open={isOpen}
            bindRefWithSubmit={true}
            noBodyPadding={true}
            hide={toggleModal}
            actions={[
              {
                label: 'Cancelar',
                action: () => {
                  handleReset()
                  toggleModal()
                },
                disabled: isSubmitting,
              },
              {
                label: isSubmitting ? 'Aguarde...' : 'Baixar',
                action: handleSubmit,
                type: 'submit',
                primary: true,
                disabled: !isValid || isSubmitting || _.size(canSettle()) === 0,
              },
            ]}
          >
            <SetteBatchContainer>
              <InputDate
                name="payDate"
                label="Data de pagamento"
                minDate={sub(values.payDate, { years: 1 })}
              />
              <table>
                <thead>
                  <tr>
                    <th className="center">
                      Data pagamento maior ou igual Data emissão
                    </th>
                    <th className="right">Valor selecionado</th>
                    <th className="center">Permite Baixa</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="center">{_.size(canSettle())}</td>
                    <td className="right">{formats.currency(sumCanSettle())}</td>
                    <td className="center green">Sim</td>
                  </tr>
                </tbody>
                <thead>
                  <tr>
                    <th className="center">
                      Data pagamento menor Data emissão
                    </th>
                    <th className="right">Valor selecionado</th>
                    <th className="center">Permite Baixa</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="center">{_.size(cantSettle())}</td>
                    <td className="right">{formats.currency(sumCantSettle())}</td>
                    <td className="center red">Não</td>
                  </tr>
                </tbody>
              </table>
            </SetteBatchContainer>
          </Modal>
        )
      }}
    </Formik>
  )
}

export default SettleBatchModal
