import { createActions, createReducer } from 'reduxsauce';
import produce from 'immer';

export const { Types, Creators } = createActions(
  {
    load: ['params', 'callback'],
    loadSuccess: ['data'],
    loadDetails: ['params', 'callback'],
    loadDetailsSuccess: ['data'],
    loadOrder: ['params', 'callback'],
    loadOrderSuccess: ['data'],
    downloadFile: ['data'],
    executeApproval: ['params', 'callback'],
    executeApprovalSuccess: ['data'],
    listBankContracts: ['params', 'callback'],
    listCompanies: ['params', 'callback'],
    listBusinessPartners: ['term', 'callback'],
  },
  { prefix: 'paymentApproval' }
);

const INITIAL_STATE = {
  loading: false,
  detailLoading: false,
  formLoading: false,
  loadingMessage: 'Carregando ...',
  data: {},
  formData: {}
};

const load = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.loading = true;
    draft.loadingMessage = 'Carregando ...';
  });

const loadSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.loading = false;
    draft.loadingMessage = '';
    draft.data = action.data || {};
    draft.details = {};
    draft.formData = {};
  });

const loadDetails = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.detailLoading = true;
  });

const loadDetailsSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.detailLoading = false;
    draft.details = action.data || {};
    draft.formData = {};
  });

const loadOrder = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.formLoading = true;
  });

const loadOrderSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.formLoading = false;
    draft.formData = action.data || {};
  });

const executeApproval = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.loading = true;
    draft.loadingMessage = 'Processando ...';
  });

const executeApprovalSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.loading = false;
    draft.loadingMessage = '';
  });

export default createReducer(INITIAL_STATE, {
  [Types.LOAD]: load,
  [Types.LOAD_SUCCESS]: loadSuccess,
  [Types.LOAD_DETAILS]: loadDetails,
  [Types.LOAD_DETAILS_SUCCESS]: loadDetailsSuccess,
  [Types.LOAD_ORDER]: loadOrder,
  [Types.LOAD_ORDER_SUCCESS]: loadOrderSuccess,
  [Types.EXECUTE_APPROVAL]: executeApproval,
  [Types.EXECUTE_APPROVAL_SUCCESS]: executeApprovalSuccess
});
