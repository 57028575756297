import React from 'react'
import _ from 'lodash'
import Fieldset from '~/components/fieldset'
import { Row } from '~/components/layout'
import { InputGroup, InputDate, Autocomplete, Select } from '~/components/form'
import { isBefore, isAfter } from 'date-fns'
import { FilterContainer } from '~/components/crud/styles'
import {
  formatBankContractSimple,
  formatBankContract,
} from '~/pages/bank-contracts/form'
import { formats } from '~/helper'

export default function FormFilter({
  values,
  setFieldValue,
  onListPaymentOrderType,
  onListBankContracts,
  onListCompanies,
  onListBusinessPartners,
  onListBankAccountsGroups
}) {
  const cloneValues = _.cloneDeep(values),
    validGroup = _.has(cloneValues, 'group') && !_.isEmpty(cloneValues.group)

  if (validGroup && _.isString(cloneValues.group)) {
    cloneValues.group = { group: cloneValues.group }
  }

  return (
    <FilterContainer>
      <Row span={2}>
        <InputGroup
          type="text"
          name="documentNumber"
          label="Número do documento"
          maxLength={30}
        />
        <InputGroup
          type="text"
          name="extras.NumAtCard"
          label="Nr. Ref. Fornecedor"
          maxLength={30}
        />
      </Row>
      <Row span={2}>
        <InputGroup
          type="text"
          name="ourNumber"
          label="Nosso número"
          maxLength={30}
        />
        <InputGroup
          type="text"
          name="extras.Reference1"
          label="Cód. externo"
          maxLength={30}
        />
      </Row>

      <Autocomplete
        name="paymentOrderType"
        keyField="id"
        label="Tipo da ordem"
        clearable={true}
        value={_.get(values, 'paymentOrderType')}
        valueFormat={(row) => `${row.name}`}
        loadData={onListPaymentOrderType}
        emptyText={'Pesquise o tipo da ordem'}
        tipText={'Digite... '}
        loadingText={'Carregando...'}
        notFoundText={'Não encontrado'}
      />

      <Autocomplete
        name="group"
        keyField="group"
        label="Grupo"
        clearable={true}
        value={cloneValues.group}
        valueFormat={(row) => `${row.group}`}
        loadData={onListBankAccountsGroups}
        emptyText={'Grupo'}
        tipText={'Digite... '}
        loadingText={'Carregando...'}
        notFoundText={'Não encontrado'}
        allowAdd={true}
      />

      <Autocomplete
        name="bankContract"
        label="Contrato"
        value={_.get(values, 'bankContract')}
        keyField="id"
        clearable={true}
        loadData={onListBankContracts}
        valueFormat={formatBankContractSimple}
        optionsFormat={formatBankContract}
        emptyText={'Pesquise um contrato'}
        tipText={'Digite... '}
        loadingText={'Carregando...'}
        notFoundText={'Não encontrado'}
      />

      <Autocomplete
        name="company"
        keyField="id"
        label="Empresa"
        clearable={true}
        value={_.get(values, 'company')}
        valueFormat={(row) => `${formats.cnpj_cpf(row.identity)} - ${row.name}`}
        loadData={(term, callback) => onListCompanies({ term }, callback)}
        emptyText={'Selecione uma empresa'}
        tipText={'Digite... '}
        loadingText={'Carregando...'}
        notFoundText={'Não encontrada'}
      />

      <Autocomplete
        name="businessPartner"
        label="Beneficiário"
        value={_.get(values, 'businessPartner')}
        keyField="id"
        clearable={true}
        valueFormat={(row) => `${formats.cnpj_cpf(row.identity)} - ${row.name}`}
        loadData={onListBusinessPartners}
        emptyText={'Pesquise um Beneficiário'}
        tipText={'Digite... '}
        loadingText={'Carregando...'}
        notFoundText={'Não encontrado'}
      />

      <Select
        name="isBlocked"
        label="Bloqueado"
        options={{
          values: [
            { value: '', label: 'Ambos' },
            { value: 'yes', label: 'Sim' },
            { value: 'no', label: 'Não' },
          ],
        }}
      />

      <Select
        name="hasNumAtCard"
        label="Contém informações no Nr. Ref. Fornecedor"
        options={{
          values: [
            { value: '', label: 'Ambos' },
            { value: 'yes', label: 'Sim' },
            { value: 'no', label: 'Não' },
          ],
        }}
      />

      <Fieldset label="Data de vencimento">
        <Row span={2}>
          <InputDate
            name="dueDateStart"
            label="De"
            onChange={(value) => {
              setFieldValue('dueDateStart', value)
              if (values.dueDateEnd && isAfter(value, values.dueDateEnd)) {
                setFieldValue('dueDateStart', values.dueDateEnd)
                setFieldValue('dueDateEnd', value)
              }
            }}
          />
          <InputDate
            name="dueDateEnd"
            label="Até"
            onChange={(value) => {
              setFieldValue('dueDateEnd', value)
              if (values.dueDateStart && isBefore(value, values.dueDateStart)) {
                setFieldValue('dueDateStart', value)
                setFieldValue('dueDateEnd', values.dueDateStart)
              }
            }}
          />
        </Row>
      </Fieldset>

      <Fieldset label="Data de agendamento">
        <Row span={2}>
          <InputDate
            name="scheduledDateStart"
            label="De"
            onChange={(value) => {
              setFieldValue('scheduledDateStart', value)
              if (
                values.scheduledDateEnd &&
                isAfter(value, values.scheduledDateEnd)
              ) {
                setFieldValue('scheduledDateStart', values.scheduledDateEnd)
                setFieldValue('scheduledDateEnd', value)
              }
            }}
          />

          <InputDate
            name="scheduledDateEnd"
            label="Até"
            onChange={(value) => {
              setFieldValue('scheduledDateEnd', value)
              if (
                values.scheduledDateStart &&
                isBefore(value, values.scheduledDateStart)
              ) {
                setFieldValue('scheduledDateStart', value)
                setFieldValue('scheduledDateEnd', values.scheduledDateStart)
              }
            }}
          />
        </Row>
      </Fieldset>

      <Fieldset label="Data de pagamento">
        <Row span={2}>
          <InputDate
            name="payDateStart"
            label="De"
            onChange={(value) => {
              setFieldValue('payDateStart', value)
              if (values.payDateEnd && isAfter(value, values.payDateEnd)) {
                setFieldValue('payDateStart', values.payDateEnd)
                setFieldValue('payDateEnd', value)
              }
            }}
          />
          <InputDate
            name="payDateEnd"
            label="Até"
            onChange={(value) => {
              setFieldValue('payDateEnd', value)
              if (values.payDateStart && isBefore(value, values.payDateStart)) {
                setFieldValue('payDateStart', value)
                setFieldValue('payDateEnd', values.payDateStart)
              }
            }}
          />
        </Row>
      </Fieldset>
    </FilterContainer>
  )
}
