import React, { useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import useEventListener from '~/hooks/use-event-listener';
import { Background, Title, Container, Content, Footer, Close, FullscreenToggle } from './styles';
import { Button } from '~components/button';

function Modal({ open, headLess, title, hide, noBodyPadding, bindRefWithSubmit, actions, secondaryActions, positive, negative, width, height, hideClose, allowFullscreen, children }) {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const submitRef = useRef(null);

  function handleHide() {
    setIsFullscreen(false);
    hide();
  };

  function handleIsFullscreen() {
    setIsFullscreen(!isFullscreen);
  };

  function buildActions(actions) {
    if (_.isEmpty(actions)) {
      return null;
    }
    return (_.map(actions, ({ label, action, type, disabled = false, color, primary = false}, index) => (
        <Button
          key={index}
          type={type || 'button'}
          ref={type === 'submit' ? (bindRefWithSubmit ? submitRef : null) : null}
          disabled={disabled}
          color={color || (primary ? 'primary' : '')}
          onClick={action}>
          {label}
        </Button>
      )));
  };

  const onKeyDown = (event) => {
    let btnRef = submitRef.current;
    if (event.keyCode === 13 && btnRef && !btnRef.disabled) {
      btnRef.click();
    }
  };

  useEventListener({ name :'keydown', handler: onKeyDown });

  function build() {
    if (!open) return null;

    return (
      <Background isOpen={open} headLess={headLess}>
        <Container fullscreen={isFullscreen} height={height} width={width}>
          {!hideClose &&
            <Close onClick={handleHide} />
          }
          {allowFullscreen === true && (
            <FullscreenToggle
              fullscreen={isFullscreen.toString()}
              onClick={handleIsFullscreen}
            />
          )}
          <Title hideClose={hideClose}>
            <div>{title}</div>
          </Title>

          <Content titleText={title} hideClose={hideClose} noBodyPadding={noBodyPadding}>
            {children}
          </Content>

          <Footer>
            <div className="left">
              {buildActions(secondaryActions)}
            </div>
            <div className="right">
              {buildActions(actions)}
            </div>
          </Footer>
        </Container>
      </Background>
    );
  }

  return ReactDOM.createPortal(build(), document.body);
}

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  allowFullscreen: PropTypes.bool,
  hideClose: PropTypes.bool,
  title: PropTypes.node,
  actions: PropTypes.array,
  secondaryActions: PropTypes.array,
  hide: PropTypes.func.isRequired,
  width: PropTypes.string,
  height: PropTypes.string
};

Modal.defaultProps = {
  open: false,
  allowFullscreen: false,
  hideClose: false,
  actions: [],
  secondaryActions: [],
  width: '80vw',
  height: '80vh'
};

export default Modal;
