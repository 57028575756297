import React, { useEffect } from 'react';
import _ from 'lodash';
import { Container } from './styles';
import { useDispatch } from 'react-redux';
import { Creators as HeaderActions } from '~/store/modules/header';

function Forbidden({ match }) {
  const dispatch = useDispatch();
  let message = 'Você não tem permissão para acessar essa página!';

  useEffect(() => {
    dispatch(HeaderActions.configure({}));
  }, [dispatch]);

  if (_.get(match, 'params.mode') === 'try-again') {
    message = 'Erro ao processar sua solicitação, tente novamente.';
  }

  return (
    <Container>
      <h1>Atenção</h1>
      <p>{message}</p>
    </Container>
  );
}

export default Forbidden;
