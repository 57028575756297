import React from 'react';
import * as Yup from 'yup';
import _ from 'lodash';
import { formats } from '~helper';
import { Row, FlexRow, FlexCol } from '~/components/layout';
import { FormContainer } from '~/pages/bank-statements/styles';
import { formatBankAccountSimple, formatBankAccount } from '~/pages/bank-accounts/form';
import { MdFileDownload } from 'react-icons/md';
import { IconButton } from '~/components/button';
import { FileUpload, Autocomplete, Select, InputLabel } from '~/components/form';
import Tabs from '~/components/tabs';
import { TabStatement } from '~/pages/bank-statements/tab-statements';
import { useSelector } from 'react-redux';

const tabs = [
    { id: 'statements', label: 'Extrato', component: TabStatement },
  ];

const getLayouts = (list = []) => {
  let data = [];
  data.push({ value: '', label: 'Selecione o Layout' })
  _.each(list, (item) => {
    data.push({ value: item, label: _.toUpper(item) })
  });
  return data;
};

const renderNewForm = ({ values, errors, touched, onListBankAccounts, loading, previewMode, setFieldValue }) => {
  let hasAccount = !!_.get(values, 'bankAccount'),
    layoutType = _.get(values, 'layoutType');

  return (
    <FormContainer>
      <Autocomplete
        name="bankAccount"
        keyField='id'
        label="Conta bancária"
        touchedAble={true}
        disabled={previewMode || loading}
        value={_.get(values, 'bankAccount')}
        valueFormat={formatBankAccountSimple}
        optionsFormat={(row) => formatBankAccount(row, 'Empresa')}
        loadData={onListBankAccounts}
        emptyText={'Pesquise uma conta bancária'}
        tipText={'Digite... '}
        loadingText={'Carregando...'}
        notFoundText={'Não encontrada'}
        />

      {hasAccount &&
        <>
          <Row span={2}>
            <InputLabel
              label="Agência"
              value={`${_.get(values, 'bankAccount.bankBranch')}-${_.get(values, 'bankAccount.bankBranchDigit')}`}
              />
            <InputLabel
              label="Número da conta bancária"
              value={`${_.get(values, 'bankAccount.accountNumber')}-${_.get(values, 'bankAccount.accountNumberDigit')}`}
              />
          </Row>

          <InputLabel
            label="Empresa"
            value={`${formats.cnpj_cpf(_.get(values, 'bankAccount.company.identity'))} - ${_.get(values, 'bankAccount.company.name')}`}
            />
        </>
      }

      <Select
        name="layoutType"
        label="Layout do arquivo"
        disabled={!hasAccount || previewMode || loading}
        value={layoutType || ''}
        options={{ values: getLayouts(_.get(values, 'bankAccount.bank.settings.statement.layout')) }} />

      {(hasAccount && layoutType) &&
        <FileUpload
          id="file"
          name="file"
          disabled={previewMode || loading}
          accept={layoutType === 'ofx' ? '.ofx' : '.ret'}
          label="Arquivo"
          multiple={false}
          orientation="vertical"
          />
      }
    </FormContainer>
  )
};

export const BankStatementForm = (props) => {

  const state = useSelector(state => state.bankStatement);

  const { values } = props;

  const { onDownloadFile } = props;

  if (!_.get(props, 'values.id')) {
    return renderNewForm(props);
  }
  return (
    <FormContainer>
       <FlexRow>
        <FlexCol flex="1">
          <InputLabel
            label="Banco"
            value={`${_.get(values, 'bankAccount.bank.code')} - ${_.get(values, 'bankAccount.bank.name')}`} />
        </FlexCol>

        <FlexCol flex="0 0 100px">
          <InputLabel
            label="Layout"
            value={_.toUpper(_.get(values, 'layoutType')) || ''} />
        </FlexCol>

        <FlexCol flex="0 0 50px" align="center">
          <IconButton size={46} noMargin={true} title="Baixar arquivo" onClick={() => onDownloadFile(values)}>
            <MdFileDownload />
          </IconButton>
        </FlexCol>

      </FlexRow>

      <FlexRow>
        <FlexCol flex="0 0 50%">
        <InputLabel
          label="Agência"
          value={`${_.get(values, 'bankAccount.bankBranch') || ''}-${_.get(values, 'bankAccount.bankBranchDigit') || ''}`} />
        </FlexCol>

        <FlexCol flex="1">
          <InputLabel
            label="Conta bancária"
            value={`${_.get(values, 'bankAccount.accountNumber') || ''}-${_.get(values, 'bankAccount.accountNumberDigit') || ''}`} />
        </FlexCol>
      </FlexRow>

      <InputLabel
        label="Empresa"
        value={`${formats.cnpj_cpf(_.get(values, 'bankAccount.company.identity'))} ${_.get(values, 'bankAccount.company.name')}`} />

      <Tabs
        tabs={tabs}
        height="calc(100% - 70px)"
        initialTab={'statements'}
        statements={_.get(values, 'records') || []}
        loading={state.formLoading}
        bankStatementId={_.get(values, 'id') || []}
        />
    </FormContainer>
  )
};

export const BankStatementSchema = Yup.object().shape({
  bankAccount: Yup.mixed().required('Selecione a conta bancária'),
  layoutType: Yup.mixed().required('Selecione o tipo de layout'),
  file: Yup.mixed().required('Escolha o arquivo'),
});
