import React from 'react';
import { MdAttachMoney } from 'react-icons/md';
import { InputGroup, CurrencyField, InputDate, Select } from '~/components/form';
import { BsRow, BsCol } from '~/components/layout';
import _ from 'lodash';

const formatRule = (settings, data, item) => {
  let rule = settings.formatRule;
  if (!rule) {
    return item.print ? item.label : '';
  }
  let text = item.print ? (rule.upper ? _.toUpper(item.label) : item.label) : '',
    byCode = rule.byCode;

  _.each(byCode, (r) => {
    if ((r.codes || []).includes(item.code)) {
      if (_.get(r, 'rule.replace') && _.get(r, 'rule.replacement')) {
        text = text.replace(_.get(r, 'rule.replace'), _.get(r, 'rule.replacement'));
      } else if (_.get(r, 'rule.fixed')) {
        text = _.get(r, 'rule.fixed');
      }
    }
  });
  return text;
};

const renderValField = (n, { type, valField, min, max }, instNum, previewMode) => {
  let attr = {
      type,
      disabled: previewMode,
      name: n(valField),
      label: `Valor para instrução ${instNum}`,
    };

  if (type === 'number') {
    attr.min = min;
    attr.max = max;
  }

  if (type === 'date') {
    return <InputDate {...attr} />;
  } else if (type === 'currency') {
    return <CurrencyField {...attr} icon={MdAttachMoney} />;
  }
  return <InputGroup {...attr} />;
};

export const InstructionFieldForm = ({ setFieldValue, baseField, settings, values, errors, touched, previewMode }) => {
  let n = (field) => {
    return `${baseField ? `${baseField}.` : ''}${field}`;
  };

  let mainInstructions =  _.get(values, `${baseField ? `${baseField}.` : ''}instructions`) || '',
    inst01 = _.get(values, n('inst01')),
    inst01Data = _.find(settings.list, (r) => r.code === inst01) || {},
    inst01InputValue = inst01 && inst01Data.valField,
    firstList = _.filter(settings.list, (r) => !r.justInst02),
    inst02 = _.get(values, n('inst02')),
    inst02Data = _.find(settings.list, (r) => r.code === inst02) || {},
    inst02InputValue = inst02 && inst02Data.valField,
    secondList = _.filter(settings.list, (r) => (r.group !== inst01Data.group && !r.justInst01));

  if (_.isEmpty(settings) || _.isEmpty(settings.list)) {
    return null;
  }
  let withoutInst02 = _.get(settings, 'withoutInst02'),
   hasSecondInst = (inst01 && !_.isEmpty(secondList) && !withoutInst02);

  return (
    <BsRow>
      <BsCol md={hasSecondInst ? 12 : 24} lg={(inst01InputValue ? 12 : 24) / (hasSecondInst ? 2 : 1)}>
        <Select
          name={n('inst01')}
          label="Instrução 1"
          disabled={previewMode}
          onChange={(event) => {
            let value = _.get(event, 'target.value'),
              rawData = _.find(settings.list, (r) => r.code === value) || {},
              instVal = mainInstructions
                .replace(/&lt;inst01&gt;(.*)&lt;\/inst01&gt;/gi, '')
                .replace('<p>&lt;inst01&gt;&lt;/inst01&gt;</p>', '')
                .replace(/<p><\/p>/g, '');

            if (value && rawData.print) {
              let text = formatRule(settings, values, rawData);
              instVal = `<p>&lt;inst01&gt;${text}&lt;/inst01&gt;</p>${instVal}`;

              instVal = instVal
                .replace(/&lt;inst02&gt;(.*)&lt;\/inst02&gt;/gi, '')
                .replace('<p>&lt;inst02&gt;&lt;/inst02&gt;</p>', '')
                .replace(/<p><\/p>/g, '');
            }
            setFieldValue(`${baseField ? `${baseField}.` : ''}inst01`, value);
            setFieldValue(`${baseField ? `${baseField}.` : ''}inst02`, '');
            setFieldValue(`${baseField ? `${baseField}.` : ''}instructions`, instVal);
          }}
          options={
            {
              values: [
                { value: '00', label: 'Sem instrução' },
                ..._.map(firstList, (r) => ({ value: r.code, ...r }))
              ]
            }
          }
          />
      </BsCol>

      {(inst01InputValue) &&
        <BsCol md={!hasSecondInst ? 12 : 6} lg={!hasSecondInst ? 12 : 6}>
          {renderValField(n, inst01Data, '1', previewMode)}
        </BsCol>
      }

      {hasSecondInst &&
        <>
          <BsCol md={12} lg={inst02InputValue ? 6 : 12}>
            <Select
              name={n('inst02')}
              label="Instrução 2"
              disabled={previewMode}
              onChange={(event) => {
                let value = _.get(event, 'target.value'),
                  rawData = _.find(settings.list, (r) => r.code === value) || {},
                  instVal = mainInstructions
                    .replace(/&lt;inst02&gt;(.*)&lt;\/inst02&gt;/gi, '')
                    .replace('<p>&lt;inst02&gt;&lt;/inst02&gt;</p>', '')
                    .replace(/<p><\/p>/g, '');

                if (value && rawData.print) {
                  let text = formatRule(settings, values, rawData);
                  instVal = `${instVal}<p>&lt;inst02&gt;${text}&lt;/inst02&gt;</p>`;
                }
                setFieldValue(`${baseField ? `${baseField}.` : ''}inst02`, value);
                setFieldValue(`${baseField ? `${baseField}.` : ''}instructions`, instVal);
              }}
              options={
                {
                  values: [
                    { value: '00', label: 'Sem instrução' },
                    ..._.map(secondList, (r) => ({ value: r.code, ...r }))
                  ]
                }
              }
              />
          </BsCol>

          {(inst02InputValue) &&
            <BsCol md={6} lg={6}>
              {renderValField(n, inst02Data, '2', previewMode)}
            </BsCol>
          }
        </>
      }
    </BsRow>
  );
};
