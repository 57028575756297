import { all, takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { Creators as CompanyActions, Types as CompanyTypes } from '~/store/modules/company';
import api from '~/services/api';
import { getError } from '~/helper';

export function* update({ data, actions }) {
  try {
    yield call(api.put, `companies/update`, data);

    toast.success(`Empresa atualizada com sucesso`);
    actions.closeForm && actions.closeForm();
    actions.reloadList && actions.reloadList();
  } catch (error) {
    showError(error);
  }
}

export function* list({ params }) {
  try {
    const response = yield call(api.post, 'companies/list', params);
    yield put(CompanyActions.listSuccess(response.data));
  } catch (error) {
    showError(error);
  }
}

export function* load({ data }) {
  try {
    const response = yield call(api.get, 'companies/load', { params: { id: data.id || 'new' } });
    yield put(CompanyActions.loadSuccess(response.data));
  } catch (error) {
    showError(error);
  }
}

export function showError(error) {
  const msg = getError(error);
  toast.error(msg);
}

export default all([
  takeLatest(CompanyTypes.LIST, list),
  takeLatest(CompanyTypes.LOAD, load),
  takeLatest(CompanyTypes.UPDATE, update),
]);
