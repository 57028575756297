import React, { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MdCheck, MdClear } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { Creators as EmailBatchActions } from '~/store/modules/email-batch';
import { Creators as HeaderActions } from '~/store/modules/header';
import { Container } from '~/pages/email-batchs/styles';
import { EmailBatchForm } from '~/pages/email-batchs/form';
import FormFilter from '~/pages/email-batchs/filter';
import Crud from '~/components/crud';
import { formats } from '~/helper';
import useSearchParameters from '~/hooks/use-search-params';

const columns = [
  {
    name: 'Enviado em',
    selector: 'createdAt',
    hide: 'md',
    width: '140px',
    format: row => formats.dateTimeZone(_.get(row, 'createdAt'), 'dd/MM/yyyy HH:mm')
  },
  {
    name: 'Autor',
    selector: 'user',
    format: row => _.get(row, 'user.name') || 'Automático'
  },
  {
    name: 'E-mails enviados',
    selector: 'sentSuccess',
    width: '120px',
    format: row => formats.number(_.get(row, 'sentSuccess') || 0)
  },
  {
    name: 'E-mails não enviados',
    selector: 'sentError',
    width: '120px',
    format: row => formats.number(_.get(row, 'sentError') || 0)
  }
];

function EmailBatch({ acls, match }) {
  const dispatch = useDispatch();
  const state = useSelector(state => state.emailBatch);
  const headerState = useSelector(state => state.header);
  const canWrite = acls.includes('W');
  const history = useHistory();
  const [openForm, setOpenForm] = useState(!!_.get(match, 'params.id'));
  const showFilter = headerState.filter.visible;

  const {
    location,
    routeParams,
    getAllParams,
    toStringify,
  } = useSearchParameters()

  useLayoutEffect(() => {
    const config = {
      loading: state.loading,
      useFilter: true,
      filter: {
        ...headerState.filter,
        visible: false,
      },
    }
    dispatch(HeaderActions.configure(config))
    // eslint-disable-next-line
  }, [])

  useLayoutEffect(() => {
    const { id, offset, limit = 20, ...filter } = getAllParams()

    const listMethod =
      _.isEmpty(id) || _.size(_.get(state, 'data.rows')) === 0
        ? EmailBatchActions.list
        : null

    if (id) {
      dispatch(EmailBatchActions.load({ id }))
    }

    dispatch(
      HeaderActions.callFilter(
        { ...filter, offset: _.toInteger(offset), limit: _.toInteger(limit) },
        listMethod,
      ),
    )
    // eslint-disable-next-line
  }, [location, routeParams])


  function handlePageChange(offset, limit) {
    const filter = getAllParams()
    history.push({
      pathname: '/email-batchs',
      search: `?${toStringify({
        ..._.omit(filter, [
          'id',
          'company',
          'bankAccount',
          'createdAtStart',
          'createdAtEnd',
          'sentDateStart',
          'sentDateEnd',
          'user'
        ]),
        offset,
        limit,
      })}`,
      state: { ..._.omit(filter, 'id'), offset, limit },
    })
    setOpenForm(false)
  };

  function handleLoad(data) {
    const filter = getAllParams()
    history.push({
      pathname: `/email-batchs/${_.get(data, 'id', '')}`,
      search: `?${toStringify(
        _.omit(filter, [
          'id',
          'company',
          'bankAccount',
          'createdAtStart',
          'createdAtEnd',
          'sentDateStart',
          'sentDateEnd',
          'user'
        ]),
      )}`,
      state: filter,
    })
    setOpenForm(true)
  }

  function handleHideFilter() {
    dispatch(HeaderActions.hideFilter());
    setOpenForm(false);
  }

  function handleFilter(filter) {
    const historySearch = {
      ..._.omit(filter, [
        'user',
        'company',
        'bankAccount',
        'createdAtStart',
        'createdAtEnd',
        'sentDateStart',
        'sentDateEnd',
        'user'
      ]),
      offset: 0,
    }
    handleHideFilter()
    history.push({
      pathname: `/email-batchs`,
      search: `?${toStringify(historySearch)}`,
      state: filter,
    })
  }

  function handleListUsers(term, callback) {
    dispatch(EmailBatchActions.listUsers(term, callback));
  }

  function handleOpenDocument(data) {
    history.push(`/bills/${_.get(data, 'id')}`);
    const filter = getAllParams()
    history.push({
      pathname: `/bills/${_.get(data, 'id')}`,
      search: `?${toStringify({
        ..._.omit(filter, [
          'id',
          'company',
          'bankAccount',
          'createdAtStart',
          'createdAtEnd',
          'sentDateStart',
          'sentDateEnd',
          'user'
        ]),
      })}`,
      state: filter,
      sourcepath: 'email-batchs'
    })


  }

  return (
    <Container>
      <Crud
        rightWidth={`${showFilter ? 500 : 1000}px`}
        useOpenForm={true}
        openForm={openForm || showFilter}
        hideAdd={true}
        columns={columns}
        emptyText="Nenhum e-mail enviado"
        formTitle={data => (showFilter ? `Filtro` : `Detalhes do lote de envio`)}
        data={state.data}
        onCloseFilter={handleHideFilter}
        tableLoading={state.loading}
        formLoading={state.formLoading}
        onChangePage={handlePageChange}
        onRowClicked={handleLoad}
        formOptions={{
          initialValues: showFilter ? headerState.filter.data : state.model
        }}
        renderForm={args =>
          showFilter ? (
            <FormFilter {...args} onListUsers={handleListUsers} />
          ) : (
            <EmailBatchForm {...args} openDocument={handleOpenDocument} permissions={state.permissions} canWrite={canWrite} previewMode={!canWrite} />
          )
        }
        actions={
          showFilter
            ? [
                { label: 'Limpar Filtro', icon: MdClear, action: () => handleFilter({}) },
                { label: 'Aplicar Filtro', icon: MdCheck, action: data => handleFilter(data) }
              ]
            : []
        }
      />
    </Container>
  );
}

export default EmailBatch;
