import styled from 'styled-components';
import { white, gray, quinary } from '~/components/mixins/color';

export const Container = styled.div`
  height: 100%;
  max-height: ${props => props.maxHeight};
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 10px;
  gap: 10px;

`;

export const ResultPanel = styled.pre`
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1;
  background: #f7f7f7;
  border: 10px solid white;
  overflow-y: scroll;
`;

export const FormPanel = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 0 0 25%;
  width: 100%;
  height: calc(100%);
  background: ${white.hex()};
  .form-header {
    color: ${gray.hex()};
    border-bottom: 1px solid ${quinary.hex()};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 50px;
    padding: 0 10px;
  }
  .form-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100%);
    padding: 10px;
  }
  .form-actions {
    border-top: 1px solid ${quinary.hex()};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    height: 50px;
    padding: 0 10px;
  }
`;
