import _ from 'lodash';
import { darken } from 'polished';
import React from 'react';
import styled from 'styled-components';
import { link, lightGray } from '../mixins/color'

export const Container = styled.div`
    background: #f7f8f9;
    border-radius: 3px;
    margin: 8px;
    padding: 7px;
    border: 0.5px solid ${lightGray.string()};
    box-shadow: 0 1px 1px 0 ${link.fade(0.5).string()};
    display: flex;
    width: calc(100% - 15px);
    align-items: center;
    justify-items: center;

    &:hover {
      background: ${darken(0.03, "#f8f8fa")};
      cursor: pointer;
    }
  `;

export const Info = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;

    strong {
      font-size: 13px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 15px;
      max-width: 200px;
    }

    p {
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 16px;
      max-width: calc(100%);
    }

    .detail {
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 16px;
      max-width: calc(100%);
    }

    .trailer {
      display: flex;
      flex-direction: row;
      width: 100%;
      
      .info {
        flex: 1;
        display: flex;
        flex-direction: column;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 16px;

        p {
          width: 100%;
        }
      }
      .icons {
        flex: 0 0 80px;
        display: flex;
        flex-direction: row;
        gap: 3px;
        align-items: flex-end;
        justify-content: flex-end;

        svg {
          cursor: help;
        }
      }
    }

    .title {
      display: flex;
      justify-content: space-between;
      width: calc(100%);

      span {
        font-size: 11px;
        text-align: right;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 14px;
        max-width: calc(40% - 10px);
        width: calc(40% - 10px);
      }

      strong {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: calc(60%);
        width: calc(60%);
      }
    }

    @media (max-width: 425px) {
      strong {
        max-width: 150px;
      }
      p {
        max-width: 150px;
      }
      span {
        max-width: 150px;
      }
    }
    `;

const getOptVal = (options, value) => (field) => {
  let opt = _.get(options, field),
    val = _.get(value, opt)

  if (_.isFunction(opt)) {
    val = opt(value)
  }
  return val
}

const Card = ({ options, icons, value, handleClick, ...rest }, ref) => {
  let acessor = getOptVal(options, value)

  return (
    <Container
      ref={ref}
      {...rest}
      color={acessor('color')}
      onClick={(e) => handleClick(value)}>
      <Info>
        <div className="title">
          <strong>{acessor('title')}</strong>
          <span>{acessor('subtitle')}</span>
        </div>
        <div className="detail">{acessor('detail')}</div>
        
        <div className="trailer">
          <div className="info">
            {acessor('info')}
          </div>

          <div className="icons">
            {_.map(icons, ({ icon: Ico, tip, color }, index) => (
              <Ico key={index} size={18} title={tip} style={{ color: color || '#444' }} />
            ))}
          </div>
        </div>
      </Info>
    </Container>
  )
}

export default React.forwardRef(Card);
