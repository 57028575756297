import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import noDataImg from '~/assets/no-data.svg';

const Container = styled.div `
  display: ${props => props.visible ? 'flex' : 'none'};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${props => props.height || 'calc(100% - 60px)'};

  .circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${props => props.size};
    height: ${props => props.size};
    max-height: 130px;
    opacity: 0.4;
    user-select: none;
    pointer-events: none;
  }

  img {
    height: 70%;
    border: none;
    opacity: 1;
  }

  h5 {
    color: ${props => props.color || '#94cce5'} !important;
    font-size: ${props => props.fontSize || '18px'} !important;
    padding: 10px !important;
  }
`;

function EmptyState(props) {
  const { size = '60%', color, text, height, fontSize, visible } = props;

  if (!visible) {
    return null;
  }
  return (
    <Container visible={visible} size={size} height={height} fontSize={fontSize} color={color}>
      <div className="circle">
        <img src={noDataImg} alt="logo" title={size}/>
      </div>
      <h5> {text} </h5>
    </Container>
  );
}

EmptyState.propTypes = {
  text: PropTypes.string.isRequired,
  visible: PropTypes.bool
}

EmptyState.defaultProps = {
  text: '',
  visible: false
}

export default EmptyState;
