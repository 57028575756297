import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Creators as EmailSettingsActions } from '~/store/modules/email-setting';
import { Creators as HeaderActions } from '~/store/modules/header';
import { Container } from '~/pages/email-settings/styles';
import Crud from '~/components/crud';
import { MdSave, MdClear, MdCheck, MdDelete } from 'react-icons/md';
import confirm from '~/components/confirm';
import { formats } from '~/helper';
import FormFilter from '~/pages/email-settings/filter';
import { EmailSettingsForm, EmailSettingsSchema, eventTypes, scopeTypes } from '~/pages/email-settings/form';

const columns = [
  {
    name: 'Descrição',
    selector: 'description',
    compact: false,
    cell: row => _.get(row, 'description')
  },
  {
    name: 'Escopo',
    selector: 'scope',
    width: '150px',
    cell: row => scopeTypes[_.get(row, 'scope')]
  },
  {
    name: 'Evento',
    selector: 'eventType',
    width: '200px',
    cell: row => {
      let event = _.find(eventTypes, { value: _.get(row, 'eventType') })
      return event.label
    }
  },
  {
    name: 'Template',
    selector: 'emailTemplate',
    compact: false,
    hide: 'lg',
    cell: row => _.get(row, 'emailTemplate.description')
  },
  {
    name: 'Cadastrado em',
    selector: 'createdAt',
    width: '140px',
    hide: 'lg',
    format: row => formats.dateTimeZone(_.get(row, 'createdAt'), 'dd/MM/yyyy HH:mm')
  }
]

function EmailSettings({ match, acls }) {
  const dispatch = useDispatch();
  const state = useSelector(state => state.emailSetting);
  const headerState = useSelector(state => state.header);
  const canWrite = (acls || []).includes('W');
  const showFilter = headerState.filter.visible;
  const [openForm, setOpenForm] = useState(!!_.get(match, 'params.id'));

  useEffect(() => {
    const config = {
      loading: state.loading,
      useFilter: true,
      filter: { ...headerState.filter, visible: false }
    }
    dispatch(HeaderActions.configure(config))
    // eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    dispatch(EmailSettingsActions.listTemplates())
    dispatch(EmailSettingsActions.list({ ...headerState.filter.data, offset: 0 }))
    // eslint-disable-next-line
  }, [dispatch]);

  function handlePageChange(offset, limit) {
    dispatch(EmailSettingsActions.list({ ...headerState.filter.data, offset, limit }))
  };

  const handleLoad = data => {
    setOpenForm(true)
    dispatch(EmailSettingsActions.load(data))
  };

  const handleHideFilter = () => {
    dispatch(HeaderActions.hideFilter())
    dispatch(EmailSettingsActions.clearModel())
    setOpenForm(false)
  };

  const handleFilter = data => {
    handleHideFilter()
    dispatch(HeaderActions.callFilter({ ...data, offset: 0 }, EmailSettingsActions.list))
  };

  const handleListBankContracts = (term, movementType, callback) => {
    dispatch(EmailSettingsActions.listBankContracts({ term, movementType }, callback))
  };

  const handleListCompanies = (term, callback) => {
    dispatch(EmailSettingsActions.listCompanies(term, callback))
  };

  const handleListBusinessPartnerGroup = (term, callback) => {
    dispatch(EmailSettingsActions.listBusinessPartnerGroup(term, callback))
  };

  const handleOnSubmit = (data, actions) => {
    dispatch(EmailSettingsActions.createOrUpdate(data, actions))
  };

  async function handleRemove(values, actions) {
    const result = await confirm.show({
      title: 'Atenção',
      text: `Deseja realmente remover esta configuração de e-mail? Essa ação não poderá ser revertida!`,
    });

    if (result) {
      dispatch(EmailSettingsActions.remove(values, actions));
    }
  };

  return (
    <Container>
      <Crud
        useOpenForm={true}
        openForm={openForm || showFilter}
        hideAdd={!canWrite}
        columns={columns}
        emptyText="Nenhuma configuração de e-mail encontrada"
        formTitle={data => (showFilter ? `Filtro` : _.get(data, 'id') ? `Configuração: ${_.get(data, 'description')}` : `Nova configuração`)}
        data={state.data}
        tableLoading={state.loading}
        formLoading={state.formLoading}
        onChangePage={handlePageChange}
        onCloseFilter={handleHideFilter}
        onRowClicked={handleLoad}
        rightWidth={`${showFilter ? '450px' : '650px'}`}
        formOptions={{
          initialValues: showFilter ? headerState.filter.data : state.model || {},
          validationSchema: showFilter ? undefined : EmailSettingsSchema,
          validateOnMount: true
        }}
        renderForm={args =>
          showFilter ? (
            <FormFilter {...args} />
          ) : (
            <EmailSettingsForm
              {...args}
              previewMode={!canWrite}
              templates={state.templates}
              onListBankContracts={handleListBankContracts}
              onListCompanies={handleListCompanies}
              onListBusinessPartnerGroup={handleListBusinessPartnerGroup}
            />
          )
        }
        actions={
          showFilter
            ? [
                { label: 'Limpar Filtro', icon: MdClear, action: () => handleFilter({}) },
                { label: 'Aplicar Filtro', icon: MdCheck, action: data => handleFilter(data) }
              ]
            : [
                {
                  label: 'Salvar',
                  icon: MdSave,
                  isSubmit: true,
                  isDisabled: () => !canWrite,
                  action: handleOnSubmit
                },
                ...(!_.get(state, 'model.id') ? [] : [
                  {
                    label: 'Remover',
                    icon: MdDelete,
                    isDisabled: () => !canWrite,
                    action: handleRemove,
                  }
                ])
              ]
        }
      />
    </Container>
  )
}

export default EmailSettings
