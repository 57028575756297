import React, { useState } from 'react';
import _ from 'lodash';
import { FormContainer } from '~/pages/webhook-bills/styles';
import { InputLabel } from '~/components/form';
import { BsRow, BsCol } from '~/components/layout';
import DataTable from '~/components/datatable';
import Loading from '~/components/loading';
import { formats } from '~/helper';
import Fieldset from '~/components/fieldset';

export const eventsMap = {
    'register_request': 'Envio do registro',
    'register_confirmation': 'Confirmação do registro',
    'due_date_change_request': 'Envio da alteração de vencimento',
    'due_date_change_confirmation': 'Confirmação da alteração de vencimento',
    'decrement_request': 'Envio da concessão de abatimento',
    'decrement_confirmation': 'Confirmação de abatimento',
    'cancel_request': 'Envio de baixa',
    'cancel_confirmation': 'Confirmação de baixa',
    'protest_request': 'Envio da solicitação de protesto',
    'protest_confirmation': 'Confirmação do protesto',
    'paid_confirmation': 'Liquidação',
    'settle_confirmation': 'Liquidação com contrato de transferência',
  };

const getRows = (array = [], bills = {}, offset, pageSize) => {
  let list = _.orderBy(array, ['documentNumber', 'billData.ourNumber'], ['asc', 'asc']),
    pageList = list.slice(offset, offset + pageSize),
    rows = [];
  
  _.each(pageList, (row) => {
    rows.push({
      ...row,
      bill: (_.get(bills, row.id) || {}),
    });
  });
  return rows;
};

const columns = [
  {
    name: 'Documento',
    selector: 'bill',
    width: '130px',
    format: (row) => _.get(row, 'bill.documentNumber')
  },
  {
    name: 'Vencimento',
    selector: 'bill',
    width: '110px',
    center: true,
    format: (row) => formats.dateTimeZone(_.get(row, 'bill.dueDate'), 'dd/MM/yyyy')
  },
  {
    name: 'Valor',
    selector: 'bill',
    width: '130px',
    right: true,
    format: (row) => formats.currency(_.get(row, `bill.value`))
  },
  {
    name: 'Evento',
    selector: 'event',
    format: (row) => eventsMap[row.event] || row.event
  },
];

export const WebhookBillForm = ({ errors, touched, values, loading, loadingMessage, previewMode, openDocument }) => {
  const pageSize = 50;
  const events = _.get(values, 'data.events') || [];
  const count = _.size(events);
  const [ offset, setOffset ] = useState(0);
  
  const onChangePage = (page) => {
    setOffset(page);
  };

  if (loading) {
    return (
      <FormContainer>
        <Loading size={36} label={loadingMessage || 'Carregando...'} />
      </FormContainer>
    );
  }

  return (
    <FormContainer>
      <InputLabel
        label="URL"
        value={_.get(values, 'data.url')}
        />
      <BsRow>
        <BsCol sm={24} md={12}>
          <InputLabel
            label="Tentativas de envio"
            value={formats.number(_.get(values, 'data.attempts'))}
            />
        </BsCol>

        <BsCol sm={24} md={12}>
          <InputLabel
            label="Data da tentativa de envio"
            value={formats.dateTimeZone(_.get(values, 'data.sendDate'), 'dd/MM/yyyy')}
            />
        </BsCol>
      </BsRow>

      <Fieldset label="Eventos">
        <div className="table-container">
          <DataTable
            emptyText={'Nenhum evento encontrado.'}
            pageSize={pageSize}
            data={{ rows: getRows(events, _.get(values, 'bills'), offset, pageSize), offset, count, limit: pageSize }}
            onRowClicked={(r) => openDocument(_.get(r, `id`))}
            onChangePage={onChangePage}
            hideUpdateButton={true}
            columns={columns}
            />
        </div>
      </Fieldset>
    </FormContainer>
  );
};
