import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: ${(props) => props.headLess ? '0' : '15px 15px 15px 0'};
  .app-main {
    display: flex;
    flex-direction: column;
    width: ${(props) => props.headLess ? '100%' : 'calc(100% - 80px)'};
    height: 100%;
    background: ${(props) => props.headLess ? '#ffffff' : '#ccd9ef33'};
    margin-left: ${(props) => props.headLess ? '0' : '80px'};
  }
  .app-container {
    width: 100%;
    height: ${(props) => props.headLess ? 'calc(100%)' : 'calc(100% - 60px)'};
  }
`;

export const ActionsBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  width: 100px;
  display: flex;
  flex: 0 0 100px;
`;
