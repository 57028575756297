import { createActions, createReducer } from 'reduxsauce'
import produce from 'immer'
import _ from 'lodash'

export const { Types, Creators } = createActions(
  {
    list: ['params'],
    listSuccess: ['data'],
    load: ['data'],
    loadSuccess: ['model'],
    listBankContracts: ['params', 'callback'],
    listCompanies: ['term', 'callback'],
    listBusinessPartnerGroup: ['term', 'callback'],
    listTemplates: [],
    templatesSuccess: ['templates'],
    clearModel: [],
    createOrUpdate: ['data', 'actions'],
    remove: ['data', 'actions'],
  },
  { prefix: 'emailSettings' }
)

const INITIAL_STATE = {
  loading: false,
  formLoading: false,
  model: null,
  templates: [],
  data: { rows: [], count: 0, offset: 0 }
}

const handleList = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.loading = true
    draft.data = { rows: [], count: 0, offset: 0 }
  })

const handleListSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.loading = false
    draft.data = action.data
  })

const handleLoad = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.model = null
    draft.formLoading = true
  })

const handleLoadSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.formLoading = false
    draft.model = _.get(action, 'model')
  })

const handleTemplates = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.templates = null
  })

const handleTemplatesSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.templates = _.map(action.templates, (template) => ({ value: _.get(template, 'id'), label: _.get(template, 'description'), movementType: _.get(template, 'movementType') }));
  })

const handleClearModel = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.model = null
    draft.formLoading = false
  })

export default createReducer(INITIAL_STATE, {
  [Types.LIST]: handleList,
  [Types.LIST_SUCCESS]: handleListSuccess,
  [Types.LOAD]: handleLoad,
  [Types.LOAD_SUCCESS]: handleLoadSuccess,
  [Types.LIST_TEMPLATES]: handleTemplates,
  [Types.TEMPLATES_SUCCESS]: handleTemplatesSuccess,
  [Types.CLEAR_MODEL]: handleClearModel
})
