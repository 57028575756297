import React from 'react';
import _ from 'lodash';
import { isBefore, isAfter } from 'date-fns';
import { Autocomplete, InputDate } from '~/components/form';
import { Row } from '~/components/layout';
import { FilterContainer } from '~/components/crud/styles';
import Fieldset from '~/components/fieldset';

const formatLabeluser = (row) => {
  return `${_.get(row, 'name')} (${_.get(row, 'profile.name')})`
};

export default function FormFilter({ values, setFieldValue, onListUsers }) {
  return (
    <FilterContainer>
      <Fieldset label="Enviados entre">
        <Row span={2}>
          <InputDate
            name="sentDateStart"
            label="De"
            onChange={(value) => {
              setFieldValue('sentDateStart', value)
              if (values.sentDateEnd && isAfter(value, values.sentDateEnd)) {
                setFieldValue('sentDateStart', values.sentDateEnd);
                setFieldValue('sentDateEnd', value)
              }
            }}
            />

          <InputDate
            name="sentDateEnd"
            label="Até"
            onChange={(value) => {
              setFieldValue('sentDateEnd', value)
              if (values.sentDateStart && isBefore(value, values.sentDateStart)) {
                setFieldValue('sentDateStart', value);
                setFieldValue('sentDateEnd', values.sentDateStart);
              }
            }}
            />
        </Row>
      </Fieldset>

      <Autocomplete
        autoFocus={true}
        name="user"
        label="Usuário"
        clearable={true}
        value={_.get(values, 'user')}
        keyField='id'
        valueFormat={(row) => formatLabeluser(row)}
        loadData={onListUsers}
        emptyText={'Pesquise um usuário'}
        tipText={'Digite... '}
        loadingText={'Carregando...'}
        notFoundText={'Não encontrado'}
       />
    </FilterContainer>
  );
}
