import React from 'react';
import PropTypes from 'prop-types';

export default function TextWithHint({ text }) {

  return (
    <div className='text-truncate'
      title={text}
    >
      {text}
    </div>
  );
}

TextWithHint.propTypes = {
  text: PropTypes.string,
}
