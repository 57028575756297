import React, { useState } from 'react';
import _ from 'lodash';
import { TabContainer } from '~/pages/business-partners/styles';
import DataTable from '~/components/datatable';
import { formats } from 'helper';

const getRows = (array = [], offset, pageSize) => {
  return array.slice(offset, offset + pageSize);
};

export const TabBills = ({ errors, touched, values, previewMode, openBill }) => {
  const bills = _.get(values, 'bills') || [];
  const count = _.size(bills);
  const pageSize = 50;
  const [ offset, setOffset ] = useState(0);

  const onChangePage = (offset) => {
    setOffset(offset);
  };

  return (
    <TabContainer>
      <DataTable
        emptyText="Nenhuma fatura vinculada"
        pageSize={pageSize}
        onChangePage={onChangePage}
        hideUpdateButton={true}
        data={{ rows: getRows(bills, offset, pageSize), offset, count, limit: pageSize }}
        onRowClicked={openBill}
        columns={[
          {
            name: 'Nosso número',
            selector: 'billData',
            format: (row) => _.get(row, 'billData.ourNumber') || ''
          },
          {
            name: 'Número documento',
            selector: 'documentNumber',
            format: (row) => _.get(row, 'documentNumber') || ''
          },
          {
            name: 'Vencimento',
            selector: 'dueDate',
            width: '110px',
            format: (row) => formats.dateTimeZone(_.get(row, 'dueDate'), 'dd/MM/yyyy')
          },
          {
            name: 'Pago em',
            selector: 'payDate',
            width: '110px',
            hide: 'md',
            format: (row) => formats.dateTimeZone(_.get(row, 'payDate'), 'dd/MM/yyyy') || '--'
          },
          {
            name: 'Valor',
            selector: 'value',
            width: '130px',
            right: true,
            format: (row) => formats.currency(_.get(row, 'value'))
          },
        ]}
      />
    </TabContainer>
  )
};
