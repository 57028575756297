import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MdSave, MdDelete, MdClear, MdCheck } from 'react-icons/md';
import _ from 'lodash';
import * as Yup from 'yup';
import FormFilter from '~/pages/bank-accounts/filter';
import { Creators as BankAccountActions } from '~/store/modules/bank-account';
import { Creators as HeaderActions } from '~/store/modules/header';
import { Container } from '~/pages/bank-accounts/styles';
import { BankAccountForm, BankAccountSchema, formatBankAccountSimple } from '~/pages/bank-accounts/form';
import { SubtitleItem, Subtitle } from '~/components/datatable/subtitle';
import CellStatus from '~/components/datatable/cell-status';
import confirm from '~/components/confirm';
import Crud from '~/components/crud';
import { red, green } from '~/components/mixins/color';
import { formats } from '~/helper';
import CellBank from '~/components/datatable/cell-bank';

const columns = [
  {
    name: ' ',
    selector: 'status',
    width: '10px',
    cell: (row) => <CellStatus title="" color={row.active ?  green.hex() : red.hex()} />
  },
  {
    name: 'Agência / Conta',
    selector: 'bankBranch',
    width: '160px',
    cell: (row) => <CellBank bankAccount={row} />
  },
  {
    name: 'Empresa',
    selector: 'company',
    format: (row) => !_.get(row, 'company.identity') ? '' : `${formats.cnpj_cpf(_.get(row, 'company.identity'))} - ${_.get(row, 'company.name') || _.get(row, 'company.tradeName')}`
  },
  {
    name: 'Cadastrada em',
    selector: 'createdAt',
    width: '120px',
    hide: 'md',
    format: (row) => formats.dateTimeZone(_.get(row, 'createdAt'), 'dd/MM/yyyy')
  }
];

const customStyles = {
  rows: {
    denseStyle: {
      minHeight: '42px'
    }
  }
}

function BankAccount({ match, acls }) {
  const dispatch = useDispatch();
  const state = useSelector(state => state.bankAccount);
  const headerState = useSelector(state => state.header);
  const canWrite = acls.includes('W');
  const [openForm, setOpenForm] = useState(!!_.get(match, 'params.id'));
  const showFilter = headerState.filter.visible;

  useEffect(() => {
    const config = {
      loading: state.loading,
      useFilter: true,
      filter: { ...headerState.filter, visible: false }
    }
    dispatch(HeaderActions.configure(config));
    // eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    dispatch(BankAccountActions.list({ ...headerState.filter.data, offset: 0 }));
    // eslint-disable-next-line
  }, [dispatch]);

  function handleOnSubmit(data, actions) {
    dispatch(BankAccountActions.createOrUpdate(data, actions));
  };

  async function handleRemove(values, actions) {
    const result = await confirm.show({
      title: 'Atenção',
      text: `Deseja realmente remover esta conta bancária? Essa ação não poderá ser revertida!`,
    });

    if (result) {
      dispatch(BankAccountActions.remove(values, actions));
    }
  };

  function handlePageChange(offset, limit) {
    dispatch(BankAccountActions.list({ ...headerState.filter.data, offset, limit }));
  };

  function handleLoad(data) {
    setOpenForm(true);
    dispatch(BankAccountActions.load(data));
  };

  function handleHideFilter() {
    dispatch(HeaderActions.hideFilter());
    dispatch(BankAccountActions.clearModel());
    setOpenForm(false);
  };

  function handleFilter(data) {
    handleHideFilter();
    dispatch(HeaderActions.callFilter({ ...data, offset: 0 }, BankAccountActions.list));
  };

  async function handleListBanks(term, callback) {
    dispatch(BankAccountActions.listBanks(term, callback));
  };

  async function handleListCompanies(term, callback) {
    dispatch(BankAccountActions.listCompanies(term, callback));
  };

  async function handleListGroups(term, callback) {
    dispatch(BankAccountActions.listGroups(term, callback));
  };
  
  return (
    <Container>
      {!state.model &&
        <Subtitle bottom={25} left={20}>
          <span>Legenda:</span>
          <SubtitleItem color={green.hex()}>Ativa</SubtitleItem>
          <SubtitleItem color={red.hex()}>Inativa</SubtitleItem>
        </Subtitle>
      }

      <Crud
        useOpenForm={true}
        openForm={openForm || showFilter}
        hideAdd={!canWrite}
        columns={columns}
        emptyText="Nenhuma conta bancária encontrada"
        formTitle={data => (showFilter ? `Filtro` : (_.get(data, 'id') ? `Conta bancária - ${formatBankAccountSimple(data, true)}` : `Nova conta bancária`))}
        data={state.data}
        tableLoading={state.loading}
        rightWidth={`${showFilter ? '500px' : '650px'}`}
        formLoading={state.formLoading}
        onChangePage={handlePageChange}
        onCloseFilter={handleHideFilter}
        onRowClicked={handleLoad}
        formOptions={{
          initialValues: showFilter ? headerState.filter.data : (state.model || {}),
          validationSchema: showFilter ? undefined : () => Yup.lazy((values) => BankAccountSchema(values)),
          initialTouched: showFilter ? {} : { accountNumber: true }
        }}
        renderForm={args => showFilter ?
          <FormFilter
            {...args}
            onListBanks={handleListBanks}
            onListCompanies={handleListCompanies}
            />
          :
          <BankAccountForm
            {...args}
            onListBanks={handleListBanks}
            onListCompanies={handleListCompanies}
            onListGroup={handleListGroups}
            previewMode={!canWrite}
            />
        }
        actions={showFilter ?
          [
            { label: 'Limpar Filtro', icon: MdClear, action: () => handleFilter({}) },
            { label: 'Aplicar Filtro', icon: MdCheck, action: data => handleFilter(data) }
          ]
          :
          [
            {
              label: 'Salvar',
              icon: MdSave,
              isSubmit: true,
              isDisabled: () => !canWrite,
              action: handleOnSubmit
            },
            ...(!_.get(state, 'model.id') ? [] : [
              {
                label: 'Remover',
                icon: MdDelete,
                isDisabled: () => !canWrite,
                action: handleRemove,
              }
            ])
          ]
        }
        dataTableOptions={{customStyles}}
        />
    </Container>
  );
}

export default BankAccount;
