import React from 'react';
import { FormContainer } from '~/pages/accounts/styles';
import Tabs from '~/components/tabs';
import { TabGeneral } from '~/pages/accounts/tab-general';
import { TabCompanies } from '~/pages/accounts/tab-companies';
import { TabBankAccounts } from '~/pages/accounts/tab-bank-accounts';
import { TabUsers } from '~/pages/accounts/tab-users';

const tabs = [
    { id: 'general', label: 'Geral', component: TabGeneral },
    { id: 'companies', label: 'Empresas', component: TabCompanies },
    { id: 'bank-accounts', label: 'Contas bancárias', component: TabBankAccounts },
    { id: 'users', label: 'Usuários', component: TabUsers },
  ];

export const AccountForm = (formArgs) => {
  return (
    <FormContainer>
      <Tabs
        initialTab="general"
        height="calc(100% - 70px)"
        tabs={tabs}
        {...formArgs}
        />
    </FormContainer>
  )
};
