import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useField, ErrorMessage } from 'formik';
import styled, { css } from 'styled-components';
import { gray, primary, lightGray } from '~/components/mixins/color';

const Group = styled.div`
  display: grid;
  position: relative;
  margin: ${(props) => props.noMargin ? '0' : '0 0 10px'};
  width: 100%;

  textarea {
    font-family: 'Roboto';
    background: ${lightGray.hex()};
    border: 2px solid ${lightGray.hex()};
    border-radius: 3px;
    padding: 19px 40px 6px 10px;
    width: 100%;
    color: ${gray.hex()};
    position: relative;
    transition: border-color 0.2s ease-in 0s;
    resize: none;

    &:focus-within {
      border-color: ${primary.hex()};
    }
    &:focus + label,
    &:not([value='']) + label {
      font-size: 70%;
      transform: translate3d(0, -100%, 0);
      opacity: 1;
      top: 20px;
      color: ${gray.hex()};
    }
    &:not([value='']) + label {
      color: ${gray.hex()};
    }
    &:focus + label {
      color: ${gray.hex()};
    }
    &:focus::placeholder {
      color: ${gray.hex()};
    }
    & + label {
      position: absolute;
      top: 15px;
      padding-left: 10px;
      transition: all 200ms;
      opacity: 0.7;
    }
    ${props =>
      props.disabled &&
      css`
        opacity: 0.55;
        user-select: none;
        pointer-events: none;
        color: #b7b7b7;
        & + label {
          color: #b7b7b7 !important;
        }
      `};
  }
`;

const TextArea = forwardRef(({ label, name, rows, noMargin, hasError, ...rest }, ref) => {
  const [field] = useField({ name })
  return (
    <>
      <Group noMargin={noMargin}>
        <textarea
          {...rest}
          {...field}
          ref={ref}
          rows={rows}
          value={field.value || ''}
          placeholder={label}
          className={!!hasError ? 'error' : ''}
          />
          {field.value && <label htmlFor={name}>{label}</label>}
      </Group>
      <ErrorMessage name={name} className="error" component="div" />
    </>
  );
});

TextArea.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  hasError: PropTypes.bool,
};

TextArea.defaultProps = {
  hasError: false,
  rows: 3
};

export default TextArea;
