import React, { useState } from 'react';
import * as Yup from 'yup';
import _ from 'lodash';
import { FormContainer } from '~/pages/companies/styles';
import { InputGroup, InputLabel, InputMask, Select, InputPhone, FileImageCropAlt } from '~/components/form';
import { BsRow, BsCol,FlexRow, FlexCol } from '~/components/layout';
import ImageAuth from '~/components/html/image';
import { IconButton } from '~/components/button';
import confirm from '~/components/confirm';
import { BASE_URL } from '~/constants';
import { brStatesValues, MASK_CEP, getCompanyLogoDefault } from '~/constants';
import { MdDelete, MdEdit, MdCheck, MdCancel } from 'react-icons/md';
import { formats } from '~/helper';
import Fieldset from '~/components/fieldset';
import { Creators as SettingsActions } from '~/store/modules/setting';
import { useDispatch, useSelector } from 'react-redux';
import { RiMailSendLine } from 'react-icons/ri';
import useNotification from '~/hooks/use-notification';
import { toast } from 'react-toastify';

export const CompanySchema = Yup.object().shape({
  name: Yup.string().required('Informe a razão social/nome'),
  address: Yup.object().shape({
    address: Yup.string().required('Informe o Logradouro'),
    number: Yup.string().required('Informe o Número'),
    block: Yup.string().required('Informe o Bairro'),
    zip_code: Yup.string().required('Informe o CEP'),
    city: Yup.string().required('Informe a Cidade'),
    state: Yup.string().required('Informe o Estado'),
  }),
  emailSettings: Yup.mixed().test('match', '', function (value) {
    const { path, createError } = this
    if (_.get(value,'customMail')) {
      if (!_.get(value,'host')) {
        return createError({
          path,
          message:
            'Para configuração de SMTP própria, informar o campo "Servidor (SMTP)"',
        })
      }
      if (!_.get(value,'port')) {
        return createError({
          path,
          message:
            'Para configuração de SMTP própria, informar o campo "Porta (SMTP)"',
        })
      }
      if (!_.get(value,'user')) {
        return createError({
          path,
          message:
            'Para configuração de SMTP própria, informar o campo "Usuário (SMTP)"',
        })
      }
      if (!_.get(value,'pass')) {
        return createError({
          path,
          message:
            'Para configuração de SMTP própria, informar o campo "Senha (SMTP)"',
        })
      }
      if (!_.get(value,'senderName') || !_.get(value,'senderEmail')) {
        return createError({
          path,
          message:
            'Para configuração de SMTP própria, informar Nome e E-mail do Remetente',
        })
      }
    }
    return true
  }),
});

const getLogo = (values) => {
  let logo = _.get(values, 'logo');

  if (logo) {
    logo = `${BASE_URL}/companies/load-logo/${values.id}`;
  }
  return _.get(values, 'logo_b64') || logo || getCompanyLogoDefault();
}

const testMessages = {
  'email-test': {
    success: 'E-mail enviado com sucesso',
    error: 'E-mail não enviado, verifique as configurações'
  },
};

export const CompanyForm = ({ errors, touched, values, previewMode, setFieldValue }) => {
  const [editLogo, setEditLogo] = useState(false);
  const [originalLogo, setOriginalLogo] = useState(_.pick(values, 'logo', 'logo_b64'));
  const dispatch = useDispatch(),
  { user } = useSelector(state => state.auth);
  let isCompany = _.size(_.get(values, 'identity')) === 14;

  const handleClearLogo = async () => {
    const result = await confirm.show({
      title: 'Atenção',
      text: `Deseja realmente remover a logo da empresa?`,
    });

    if (result) {
      setEditLogo(false);
      setFieldValue('logo_b64', null);
      setFieldValue('logo', null);
    }
  };

  const handleEditLogo = async () => {
    setOriginalLogo(_.pick(values, 'logo', 'logo_b64'));
    setEditLogo(true);
  };

  const handleCancelEditLogo = async () => {
    setFieldValue('logo', _.get(originalLogo, 'logo'));
    setFieldValue('logo_b64', _.get(originalLogo, 'logo_b64'));
    setEditLogo(false);
  };

  function sendTestMail(values) {
    let testValues = { ...values, smtp: _.get(values, 'emailSettings') }
    dispatch(SettingsActions.sendTestMail(testValues));
  }

  useNotification({
    account: `${_.get(user, 'account.id')}`,
    user: `${_.get(user, 'id')}`,
    entity: 'settings',
    onData: result => {
      let isOk = _.get(result, 'data.isOk'),
        message = testMessages[_.get(result, 'data.type')] || '',
        field = isOk ? 'success' : 'error';

      toast[field](message[field] || '');
    }
  });

  return (
    <FormContainer>
      <InputLabel
        label={isCompany ? 'CNPJ' : 'CPF' }
        value={formats.cnpj_cpf(_.get(values, 'identity'))}
        />

      <InputGroup
        type="text"
        name="name"
        maxLength={150}
        label={isCompany ? 'Razão social' : 'Nome'}
        disabled={previewMode}
        hasError={errors.name && touched.name} />

      <InputGroup
        type="text"
        name="tradeName"
        label={isCompany ? 'Nome fantasia' : 'Apelido'}
        maxLength={150}
        disabled={previewMode}
        hasError={errors.tradeName && touched.tradeName} />

      <FlexRow>
        <FlexCol flex="1">
          <InputGroup
            type="email"
            name="email"
            label="E-mail"
            maxLength={100}
            disabled={previewMode}
            hasError={errors.email && touched.email} />
        </FlexCol>

        <FlexCol flex="0 0 150px">
          <InputPhone
            name="phone"
            label="Telefone"
            country="BR"
            disabled={previewMode}
            value={_.get(values, 'phone')}
            hasError={errors.phone && touched.phone} />
        </FlexCol>
      </FlexRow>

      <Fieldset label="Endereço">
        <FlexRow>
          <FlexCol flex="1">
            <InputGroup
              type="text"
              name="address.address"
              label="Logradouro"
              maxLength={100}
              disabled={previewMode}
              hasError={_.get(errors, 'address.address') && _.get(touched, 'address.address')} />
          </FlexCol>

          <FlexCol flex="0 0 150px">
            <InputGroup
              type="text"
              name="address.number"
              label="Número"
              maxLength={20}
              disabled={previewMode}
              hasError={_.get(errors, 'address.number') && _.get(touched, 'address.number')} />
          </FlexCol>
        </FlexRow>

        <InputGroup
          type="text"
          name="address.complements"
          label="Complemento"
          maxLength={100}
          disabled={previewMode}
          hasError={_.get(errors, 'address.complements') && _.get(touched, 'address.complements')} />

        <FlexRow>
          <FlexCol flex="1">
            <InputGroup
              type="text"
              name="address.block"
              label="Bairro"
              maxLength={50}
              disabled={previewMode}
              hasError={_.get(errors, 'address.block') && _.get(touched, 'address.block')} />
          </FlexCol>

          <FlexCol flex="0 0 200px">
            <InputMask
              name="address.zip_code"
              label="CEP"
              mask={MASK_CEP}
              disabled={previewMode}
              value={_.get(values, 'address.zip_code')}
              hasError={_.get(errors, 'address.zip_code') && _.get(touched, 'address.zip_code')} />
          </FlexCol>
        </FlexRow>

        <FlexRow>
          <FlexCol flex="1">
            <InputGroup
              type="text"
              name="address.city"
              label="Cidade"
              maxLength={100}
              disabled={previewMode}
              hasError={_.get(errors, 'address.city') && _.get(touched, 'address.city')} />
          </FlexCol>

          <FlexCol flex="0 0 200px">
            <Select
              name="address.state"
              label="Estado"
              disabled={previewMode}
              value={_.get(values, 'address.state')}
              options={{ values: [
                { value: '', label: 'Selecione um estado' },
                ...brStatesValues
              ]}}
              hasError={_.get(errors, 'address.state') && _.get(touched, 'address.state')} />
          </FlexCol>
        </FlexRow>
      </Fieldset>

      <Fieldset label="E-mail - configurações">
        <BsRow>
          <BsCol sm={24} md={24} lg={24}>
            <label className={'labelIn'}>
              <input
                type='checkbox'
                disabled={previewMode}
                name={'emailSettings.customMail'}
                checked={_.get(values,'emailSettings.customMail') || false}
                onChange={async (event) => {
                  setFieldValue('emailSettings.customMail', event.target.checked)
                  if(!event.target.checked){
                    setFieldValue('emailSettings', {});
                  }
                }}
              />
            {`Utilizar configurações de SMTP diferente da configuração geral`}
            </label>
          </BsCol>
        </BsRow>
        <BsRow>
          <BsCol sm={12} md={12} lg={7}>
            <InputGroup
              type="text"
              name="emailSettings.host"
              label="Servidor (SMTP)"
              maxLength={200}
              disabled={previewMode || !_.get(values, 'emailSettings.customMail')}
              hasError={_.get(errors, 'emailSettings.host') && _.get(touched, 'emailSettings.host')}
            />
          </BsCol>

          <BsCol sm={12} md={12} lg={5}>
            <InputGroup
              type="number"
              min={0}
              max={65536}
              name="emailSettings.port"
              label="Porta (SMTP)"
              disabled={previewMode || !_.get(values, 'emailSettings.customMail')}
              hasError={_.get(errors, 'emailSettings.port') && _.get(touched, 'emailSettings.port')}
            />
          </BsCol>

          <BsCol sm={12} md={12} lg={7}>
            <InputGroup
              type="text"
              name="emailSettings.user"
              label="Usuário (SMTP)"
              maxLength={100}
              autoComplete="new-password"
              disabled={previewMode || !_.get(values, 'emailSettings.customMail')}
              hasError={_.get(errors, 'emailSettings.user') && _.get(touched, 'emailSettings.user')}
            />
          </BsCol>

          <BsCol sm={12} md={12} lg={5}>
            <InputGroup
              type="password"
              name="emailSettings.pass"
              label="Senha (SMTP)"
              maxLength={100}
              autoComplete="new-password"
              disabled={previewMode || !_.get(values, 'emailSettings.customMail')}
              hasError={_.get(errors, 'emailSettings.pass') && _.get(touched, 'emailSettings.pass')}
            />
          </BsCol>
        </BsRow>

        <BsRow>
          <BsCol sm={12} md={12} lg={12}>
            <InputGroup
              type="text"
              name="emailSettings.senderName"
              label="Nome do Remetente"
              maxLength={100}
              disabled={previewMode || !_.get(values, 'emailSettings.customMail')}
              hasError={_.get(errors, 'emailSettings.senderName') && _.get(touched, 'emailSettings.senderName')}
            />
          </BsCol>

          <BsCol sm={12} md={12} lg={12}>
            <InputGroup
              type="email"
              name="emailSettings.senderEmail"
              label="E-mail do Remetente"
              maxLength={100}
              disabled={previewMode || !_.get(values, 'emailSettings.customMail')}
              hasError={_.get(errors, 'emailSettings.senderEmail') && _.get(touched, 'emailSettings.senderEmail')}
            />
          </BsCol>
        </BsRow>
      </Fieldset>

      <Fieldset label="E-mail - teste de configuração">
        <FlexRow direction="row" gap="0">
          <FlexCol flex="1">
            <InputGroup
              type="text"
              name="test.testMail"
              label="Informe um e-mail"
              maxLength={100}
              autoComplete="new-password"
              disabled={previewMode || !_.get(values, 'emailSettings.customMail')}
            />
          </FlexCol>

          <FlexCol flex="0 0 96px">
            <IconButton
              type="button"
              title="Enviar e-mail de teste"
              noMargin={true}
              disabled={previewMode || !_.get(values, 'test.testMail')}
              onClick={() => sendTestMail(values)}
              size={46}
            >
              <RiMailSendLine />
            </IconButton>
          </FlexCol>
        </FlexRow>
      </Fieldset>

      <Fieldset label="Logo">
        <FlexRow>
          <FlexCol flex="1" direction="column">
            {!editLogo &&
              <div className="empty-logo">
                <ImageAuth src={getLogo(values)} defaultImage={getCompanyLogoDefault()} />
              </div>
            }
            {editLogo &&
              <FileImageCropAlt
                label="Clique aqui para selecionar a imagem"
                name="logo_b64"
                valueAsBase64={true}
                aspect={160/40}
                height={200}
                />
            }
          </FlexCol>

          <FlexCol flex="0 0 40px" direction="column">
            {!editLogo &&
              <IconButton type="button" size={40} noMargin={true} title="Editar" onClick={handleEditLogo}>
                <MdEdit />
              </IconButton>
            }
            {!editLogo &&
              <IconButton type="button" size={40} disabled={editLogo} noMargin={true} title="Remover" onClick={handleClearLogo}>
                <MdDelete />
              </IconButton>
            }
            {editLogo &&
              <IconButton type="button" size={40} disabled={!_.get(values, 'logo_b64')} noMargin={true} title="Finalizar" onClick={() => setEditLogo(false)}>
                <MdCheck />
              </IconButton>
            }
            {editLogo &&
              <IconButton type="button" size={40} noMargin={true} title="Cancelar" onClick={handleCancelEditLogo}>
                <MdCancel />
              </IconButton>
            }
          </FlexCol>
        </FlexRow>
      </Fieldset>
    </FormContainer>
  );
};
