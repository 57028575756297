import React from 'react'
import _ from 'lodash'
import Fieldset from '~/components/fieldset'
import { Row } from '~/components/layout'
import { InputGroup, InputDate, Autocomplete } from '~/components/form'
import { isBefore, isAfter } from 'date-fns'
import { FilterContainer } from '~/components/crud/styles'
import {
  formatBankContractSimple,
  formatBankContract,
} from '~/pages/bank-contracts/form'
import { formats } from '~/helper'

const formatLabeluser = (row) => {
  return `${_.get(row, 'name')} (${_.get(row, 'profile.name')})`
};

export default function FormFilter({
  values,
  setFieldValue,
  onListPaymentOrderType,
  onListBankContracts,
  onListCompanies,
  onListBusinessPartners,
  onListUsers
}) {

  return (
    <FilterContainer>
      <Row span={1}>
        <InputGroup
          type="text"
          name="documentNumber"
          label="Número do documento"
          maxLength={30}
        />
      </Row>
      <Autocomplete
        name="paymentOrderType"
        keyField="id"
        label="Tipo da ordem"
        clearable={true}
        value={_.get(values, 'paymentOrderType')}
        valueFormat={(row) => `${row.name}`}
        loadData={onListPaymentOrderType}
        emptyText={'Pesquise o tipo da ordem'}
        tipText={'Digite... '}
        loadingText={'Carregando...'}
        notFoundText={'Não encontrado'}
      />
      <Autocomplete
        name="bankContract"
        label="Contrato"
        value={_.get(values, 'bankContract')}
        keyField="id"
        clearable={true}
        loadData={onListBankContracts}
        valueFormat={formatBankContractSimple}
        optionsFormat={formatBankContract}
        emptyText={'Pesquise um contrato'}
        tipText={'Digite... '}
        loadingText={'Carregando...'}
        notFoundText={'Não encontrado'}
      />
      <Autocomplete
        name="company"
        keyField="id"
        label="Empresa"
        clearable={true}
        value={_.get(values, 'company')}
        valueFormat={(row) => `${formats.cnpj_cpf(row.identity)} - ${row.name}`}
        loadData={(term, callback) => onListCompanies({ term }, callback)}
        emptyText={'Selecione uma empresa'}
        tipText={'Digite... '}
        loadingText={'Carregando...'}
        notFoundText={'Não encontrada'}
      />
      <Autocomplete
        name="businessPartner"
        label="Beneficiário"
        value={_.get(values, 'businessPartner')}
        keyField="id"
        clearable={true}
        valueFormat={(row) => `${formats.cnpj_cpf(row.identity)} - ${row.name}`}
        loadData={onListBusinessPartners}
        emptyText={'Pesquise um Beneficiário'}
        tipText={'Digite... '}
        loadingText={'Carregando...'}
        notFoundText={'Não encontrado'}
      />
      <Fieldset label="Data de agendamento">
        <Row span={2}>
          <InputDate
            name="scheduledDateStart"
            label="De"
            onChange={(value) => {
              setFieldValue('scheduledDateStart', value)
              if (
                values.scheduledDateEnd &&
                isAfter(value, values.scheduledDateEnd)
              ) {
                setFieldValue('scheduledDateStart', values.scheduledDateEnd)
                setFieldValue('scheduledDateEnd', value)
              }
            }}
          />

          <InputDate
            name="scheduledDateEnd"
            label="Até"
            onChange={(value) => {
              setFieldValue('scheduledDateEnd', value)
              if (
                values.scheduledDateStart &&
                isBefore(value, values.scheduledDateStart)
              ) {
                setFieldValue('scheduledDateStart', value)
                setFieldValue('scheduledDateEnd', values.scheduledDateStart)
              }
            }}
          />
        </Row>
      </Fieldset>
      <Fieldset label="Ordens que o usuário não aprovou">
        <Autocomplete
          autoFocus={true}
          name="user"
          label="Usuário"
          clearable={true}
          value={_.get(values, 'user')}
          keyField='id'
          valueFormat={(row) => formatLabeluser(row)}
          loadData={onListUsers}
          emptyText={'Pesquise um usuário'}
          tipText={'Digite... '}
          loadingText={'Carregando...'}
          notFoundText={'Não encontrado'}
        />
      </Fieldset>
    </FilterContainer>
  )
}
