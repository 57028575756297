import styled from 'styled-components';

export const CrudContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: relative;
  padding: 10px;

  .company-info {
    line-height: 15px;
    width: 100%;
    height: 80%;
    pointer-events: none;
    font-size: 12px;

    td {
      min-height: 20px;
      vertical-align: middle;
    }
    .title {
      width: 70px;
      font-weight: bold;
      font-size: 85%;
      color: #999;
    }
  }
`;

export const  CompanyContainer = styled.div`
  width: 100%;
  height: 100%;
  font-size: 12px;
  padding: 5px;
  display: flex;
  pointer-events: none;


  .company-icons {
    display: grid;
    place-items: center;
    width: 24px;
  }

  .company-text {
    width: ${props => `calc(100% - ${props.hasIcon ? '24px' : '0px'}) `};
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 3px;
    }
  }
`

export const CellIcon = styled.div`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  color: ${(props) => props.color};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: ${(props) => props.size}px;

  & svg path {
    stroke: ${(props) => props.color};
  }
  &:hover:enabled, &:focus:enabled {
    & svg path {
      stroke: ${(props) => props.color};
    }
  }
`;

export const BankAccountContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding-left: 10px;
`;