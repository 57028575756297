import React from 'react';
import _ from 'lodash';
import { Row } from '~/components/layout';
import Fieldset from '~/components/fieldset';
import { FilterContainer } from '~/components/crud/styles';
import { InputDate, Autocomplete } from '~/components/form';
import { isBefore, isAfter } from 'date-fns';
import { formats } from '~/helper';
import { formatBankAccount, formatBankAccountSimple } from '../bank-accounts/form';

export default function FormFilter({ values, setFieldValue, onListBankAccounts, onListCompanies }) {
  return (
    <FilterContainer>
      <Autocomplete
        name="bankAccount"
        label="Conta bancária"
        value={_.get(values, 'bankAccount')}
        keyField="id"
        clearable={true}
        loadData={onListBankAccounts}
        valueFormat={formatBankAccountSimple}
        optionsFormat={r => formatBankAccount(r)}
        emptyText={'Pesquise uma conta bancária'}
        tipText={'Digite... '}
        loadingText={'Carregando...'}
        notFoundText={'Não encontrado'}
      />

      <Autocomplete
        name="company"
        keyField="id"
        label="Emissor"
        clearable={true}
        value={_.get(values, 'company')}
        valueFormat={row => `${formats.cnpj_cpf(row.identity)} - ${row.name || row.tradeName}`}
        loadData={onListCompanies}
        emptyText={'Pesquise um emissor'}
        tipText={'Digite... '}
        loadingText={'Carregando...'}
        notFoundText={'Não encontrada'}
      />

      <Fieldset label="Período">
        <Row span={2}>
          <InputDate
            name="startDateStart"
            label="De"
            onChange={value => {
              setFieldValue('startDateStart', value);
              if (values.endDateEnd && isAfter(value, values.endDateEnd)) {
                setFieldValue('startDateStart', values.endDateEnd);
                setFieldValue('endDateEnd', value);
              }
            }}
          />

          <InputDate
            name="endDateEnd"
            label="Até"
            onChange={value => {
              setFieldValue('endDateEnd', value);
              if (values.startDateStart && isBefore(value, values.startDateStart)) {
                setFieldValue('startDateStart', value);
                setFieldValue('endDateEnd', values.startDateStart);
              }
            }}
          />
        </Row>
      </Fieldset>
    </FilterContainer>
  );
}
