import { createActions, createReducer } from 'reduxsauce';
import produce from 'immer';
import _ from 'lodash';

export const { Types, Creators } = createActions(
  {
    list: ['params'],
    listSuccess: ['data'],
    listBankAccounts: ['params', 'callback'],
    listCompanies: ['params', 'callback'],
    listBankStatementClassification: [],
    listBankStatementClassificationSuccess: ['data'],
    updateBankClassification: ['params', 'index'],
    load: ['id', 'movementId'],
    loadSuccess: ['data'],
    sendToFirebase: ['id', 'movementId'],
    sendToFirebaseSuccess: ['data'],
    clearClassification: ['id', 'movementId'],
    clearClassificationSuccess: [],
    discard: ['id', 'movementId'],
    discardSuccess: [],
    cancel: ['id', 'movementId'],
    reclassifyBankStatement: ['id', 'movementId'],
    getPaymentOrders: ['params', 'bankStatementLine', 'toogleModal'],
    getPaymentOrdersSuccess: ['data']
  },
  { prefix: 'bank-statement-classifications' }
);

const INITIAL_STATE = {
  loading: false,
  formLoading: false,
  modalLoading: false,
  data: { rows: [], count: 0, offset: 0 },
  detail: {},
  optionsClassification: [],
  paymentOrdersData: { rows: [] }
};

const list = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.loading = true;
    draft.data = { rows: [], count: 0, offset: 0 };
    draft.detail = {};
    draft.selecteds = [];
  });

const listSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.loading = false;
    draft.data = action.data;
  });

const load = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.loading = true;
  });

const loadSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.loading = false;
    draft.detail = _.get(action, 'data') || {};
  });

const listBankStatementClassification = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.loading = true;
    draft.optionsClassification = [];
  });

const listBankStatementClassificationSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.loading = false;
    draft.optionsClassification = _.map(action.data, r => ({
      value: r.id,
      label: `[${r.code}] - ${r.description}`,
      code: r.code,
      debit: r.debit,
      credit: r.credit
    }));
  });

const updateBankClassification = (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    const clone = _.cloneDeep(_.get(state, 'detail') || {});
    const line = clone.lines[action.index];
    line.paymentOrder = _.get(action, 'params.paymentOrder');
    line.bankStatementClassification = _.find(state.optionsClassification, f => f.value === _.get(action, 'params.bankStatementClassificationId'));
    draft.detail = clone;
  });
};

const sendToFirebase = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.formLoading = true;
  });

const sendToFirebaseSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.formLoading = false;
  });

const clearClassification = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.formLoading = true;
  });

const clearClassificationSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.formLoading = false;
  });

const discardSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.formLoading = false;
  });

const discard = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.formLoading = false;
  });

const getPaymentOrders = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.formLoading = true;
    draft.bankStatementLineSelected = action.bankStatementLine;
    draft.paymentOrdersData = { rows: [] };
  });

const getPaymentOrdersSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.formLoading = false;
    draft.paymentOrdersData = _.get(action, 'data');
  });

export default createReducer(INITIAL_STATE, {
  [Types.LIST]: list,
  [Types.LIST_SUCCESS]: listSuccess,
  [Types.LOAD]: load,
  [Types.LOAD_SUCCESS]: loadSuccess,
  [Types.LIST_BANK_STATEMENT_CLASSIFICATION]: listBankStatementClassification,
  [Types.LIST_BANK_STATEMENT_CLASSIFICATION_SUCCESS]: listBankStatementClassificationSuccess,
  [Types.UPDATE_BANK_CLASSIFICATION]: updateBankClassification,
  [Types.SEND_TO_FIREBASE]: sendToFirebase,
  [Types.SEND_TO_FIREBASE_SUCCESS]: sendToFirebaseSuccess,
  [Types.CLEAR_CLASSIFICATION]: clearClassification,
  [Types.CLEAR_CLASSIFICATION_SUCCESS]: clearClassificationSuccess,
  [Types.DISCARD]: discard,
  [Types.DISCARD_SUCCESS]: discardSuccess,
  [Types.GET_PAYMENT_ORDERS]: getPaymentOrders,
  [Types.GET_PAYMENT_ORDERS_SUCCESS]: getPaymentOrdersSuccess
});
