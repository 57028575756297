import React, { useEffect, useRef } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import Modal from '~/components/modal';
import { ModalContainer } from '~pages/payment-orders/styles';
import { InputGroup } from '~/components/form';

const CancellationReasonModal = ({ isOpen, toggleModal, handleOnSubmit, loading }) => {

  const cancellationReasonInput = useRef();

  useEffect(() => {
    if(isOpen) {
      cancellationReasonInput.current.value = '';
    }
  }, [isOpen])

  return (
    <Formik initialValues={{ cancellationReason: '' }} validationSchema={schema} onSubmit={handleOnSubmit}>
      {({ errors, touched, isValid, isSubmitting, handleSubmit, handleReset, values }) => {
        return (
          <Modal
            width="700px"
            height="196px"
            title="Motivo do cancelamento"
            hideClose={true}
            open={isOpen}
            bindRefWithSubmit={true}
            hide={toggleModal}
            actions={[
              {
                label: 'Cancelar',
                action: () => {
                  handleReset();
                  toggleModal();
                }
              },
              {
                label: 'Confirmar',
                action: () => {
                  handleSubmit();
                },
                type: 'submit',
                disabled: !isValid || isSubmitting || !_.get(values,'cancellationReason'),
                primary: true
              }
            ]}
          >
            <ModalContainer>
              <InputGroup
                type="text"
                name="cancellationReason"
                disabled={loading}
                label="Informe o motivo do cancelamento"
                hasError={_.get(errors, 'cancellationReason') && _.get(touched, 'cancellationReason')}
                ref={cancellationReasonInput}
              />
            </ModalContainer>
          </Modal>
        );
      }}
    </Formik>
  );
};

const schema = Yup.object().shape({
  cancellationReason: Yup.string()
  .required('Informe o motivo')
  .min(5, 'Mínimo de 5 caracteres')
  .max(1000, 'Máximo de 1000 caracteres')
});

export default CancellationReasonModal;
