import { all, takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { Creators as ShippingActions, Types as ShippingTypes } from '~/store/modules/shipping';
import api from '~/services/api';
import { format } from 'date-fns';
import { getError } from '~/helper';

export function* list({ params }) {
  try {
    const response = yield call(api.post, 'shippings/list', params);
    yield put(ShippingActions.listSuccess(response.data));
  } catch (error) {
    showError(error);
  }
}

export function* load({ data }) {
  try {
    const response = yield call(api.get, 'shippings/load', { params: { id: data.id } });
    yield put(ShippingActions.loadSuccess(response.data));
  } catch (error) {
    showError(error);
  }
}

export function* downloadFile({ data, actions }) {
  try {
    const resp = yield call(api.get, 'shippings/filename', { params: { movementId: data.id } }),
      filename = _.get(resp, 'data.filename'),
      params = { movementId: data.id, filename },
      response = yield call(api.get, 'shippings/download-file', { params, responseType: 'blob' });

    api.download(response, filename);

    yield put({ type: ShippingTypes.LOAD, data: { id: data.id } });
    actions.reloadList && actions.reloadList();
  } catch (error) {
    showError(error);
  }
  yield put(ShippingActions.formActionDone());
}

export function* removeBill({ id, movementId, billId, actions }) {
  try {
    let response = yield call(api.delete, 'shippings/remove-bill', { params: { id, movementId, billId } }),
      data = response.data;
    toast.success(`Fatura desvinculada da remessa com sucesso`);

    if (data.shippingRemoved) {
      actions.closeForm && actions.closeForm();
    } else {
      yield put({ type: ShippingTypes.LOAD, data: { id: movementId } });
    }
    actions.reloadList && actions.reloadList();
  } catch (error) {
    showError(error);
  }
  yield put(ShippingActions.formActionDone());
}

export function* removeBills({ id, actions }) {
  try {
    yield call(api.delete, 'shippings/remove-bills', { params: { id } });
    toast.success(`Remessa removida com sucesso`);
    actions.closeForm && actions.closeForm();
    actions.reloadList && actions.reloadList();
  } catch (error) {
    showError(error);
  }
  yield put(ShippingActions.formActionDone());
}

export function* removePayment({ id, movementId, payment, actions }) {
  try {
    let result = yield call(api.delete, 'shippings/remove-payment', { params: { id, movementId, paymentOrderId: payment } }),
      data = _.get(result, 'data');
    toast.success(`Pagamento desvinculado da remessa com sucesso`);

    if (data.shippingRemoved) {
      actions.closeForm && actions.closeForm();
    } else {
      yield put({ type: ShippingTypes.LOAD, data: { id: movementId } });
    }
    actions.reloadList && actions.reloadList();
  } catch (error) {
    showError(error);
  }
  yield put(ShippingActions.formActionDone());
}

export function* removePayments({ id, actions }) {
  try {
    yield call(api.delete, 'shippings/remove-payments', { params: { id } });
    toast.success(`Remessa removida com sucesso`);
    actions.closeForm && actions.closeForm();
    actions.reloadList && actions.reloadList();
  } catch (error) {
    showError(error);
  }
  yield put(ShippingActions.formActionDone());
}

export function* listBankAccounts({ term, callback }) {
  try {
    const response = yield call(api.get, 'shippings/list-bank-accounts', { params: { term } });
    yield call(callback, response.data);
  } catch (error) {}
}

export function* listCompanies({ term, callback }) {
  try {
    const response = yield call(api.get, 'shippings/list-companies', { params: { term } });
    yield call(callback, response.data);
  } catch (error) {}
}

export function* generateXlsx({ data }) {
  try {
    const params = { id: data },
      response = yield call(api.get, 'shippings/xlsx', { params, responseType: 'blob' }),
      filename = `remessa-${format(new Date(), 'ddMMyyyy-HHmm')}.xlsx`;
    api.download(response, filename);
  } catch (error) {
    showError(error);
  }
  yield put(ShippingActions.formActionDone());
}

export function* cancelChargeMovement({ id, message, actions }) {
  try {
    yield call(api.patch, 'shippings/cancel-charge-movement', { id, message });
    toast.success(`Remessa cancelada com sucesso`);
    actions.closeForm && actions.closeForm();
    actions.reloadList && actions.reloadList();
  } catch (error) {
    showError(error);
  }
  yield put(ShippingActions.formActionDone());
}

export function* cancelPaymentMovement({ id, message, actions }) {
  try {
    yield call(api.patch, 'shippings/cancel-payment-movement', { id, message });
    toast.success(`Remessa cancelada com sucesso`);
    actions.closeForm && actions.closeForm();
    actions.reloadList && actions.reloadList();
  } catch (error) {
    showError(error);
  }
  yield put(ShippingActions.formActionDone());
}

function showError(error) {
  const msg = getError(error);
  toast.error(msg);
}

export default all([
  takeLatest(ShippingTypes.LIST, list),
  takeLatest(ShippingTypes.LOAD, load),
  takeLatest(ShippingTypes.DOWNLOAD_FILE, downloadFile),
  takeLatest(ShippingTypes.REMOVE_BILL, removeBill),
  takeLatest(ShippingTypes.REMOVE_BILLS, removeBills),
  takeLatest(ShippingTypes.REMOVE_PAYMENT, removePayment),
  takeLatest(ShippingTypes.REMOVE_PAYMENTS, removePayments),
  takeLatest(ShippingTypes.LIST_BANK_ACCOUNTS, listBankAccounts),
  takeLatest(ShippingTypes.LIST_COMPANIES, listCompanies),
  takeLatest(ShippingTypes.GENERATE_XLSX, generateXlsx),
  takeLatest(ShippingTypes.CANCEL_CHARGE_MOVEMENT, cancelChargeMovement),
  takeLatest(ShippingTypes.CANCEL_PAYMENT_MOVEMENT, cancelPaymentMovement)
]);
