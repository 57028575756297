
import React from 'react';
import Modal from '~/components/modal';
import styled from 'styled-components';
import help1 from '~/assets/tips/position-help-01.png';
import help2 from '~/assets/tips/position-help-02.png';

const FormContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin-bottom: 10px;

  p {
    line-height: 20px;
    font-size: 14px;
    margin: 5px 0;
    font-weight: normal;
  }
  p.left-space {
    padding-left: 20px;
  }
  p.bold {
    font-weight: bold;
    color: #444;
    margin-top: 10px;
  }
  p.image {
    text-align: center;
    width: 100%;
  }
  p.image img {
    width: auto;
    border: 1px dotted silver;
    margin: 5px; 
  }
`;

const PositionInfoModal = ({ isOpen, toggleModal }) => {
  return (
    <Modal
      width="820px"
      height="600px"
      title={`Localização - Problemas comuns`}
      hideClose={true}
      open={isOpen}
      hide={toggleModal}
      actions={[{ label: 'Fechar', action: toggleModal }]}
      >
      <FormContainer>
        <p>A aprovação de ordens de pagamento precisa que o usuário tenha habilitada a permissão de "Localização".</p>

        <p className="bold">1 - Ao abrir a tela de aprovação</p>
        <p className="left-space">
          O DocPay irá solicitar essa permissão, basta permitir para ter acesso as ações de aprovação.
        </p>
        <p className="image">
          <img alt="tip" src={help1} />
        </p>
        
        <p className="bold">2 - Caso essa permissão esteja negada</p>
        <p className="left-space">
          Basta clicar no cadeado e habilitar onde está realçado em vermelho e recarregar a página (<code>ctrl+R</code> ou <code>ctrl+F5</code>)
        </p>

        <p className="image">
          <img alt="tip" src={help2} />
        </p>
      </FormContainer>
    </Modal>
  )
};

export default PositionInfoModal;
