import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useField } from 'formik';
import CurrencyInput from 'react-currency-input';
import styled, { css } from 'styled-components';
import { primary, gray, lightGray } from '~/components/mixins/color';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Group = styled.div`
  display: grid;
  position: relative;
  margin: ${(props) => props.noMargin ? '0' : '0 0 10px'};
  width: 100%;

  & > svg {
    position: absolute;
    right: 15px;
    align-self: center;
    color: #555;
  }

  &:focus-within > svg {
    color: ${primary.hex()};
  }

  input {
    width: 100%;
    background: ${lightGray.hex()};
    border: 2px solid ${lightGray.hex()};
    border-radius: 4px;
    padding: 20px 0 8px 10px;
    height: 46px;
    color: ${gray.hex()};
    text-align: left;

    &:focus {
      border-color: ${primary.hex()};
    }

    &:focus + label,
    &:not([value=""]) + label {
      font-size: 70%;
      transform: translate3d(0, -100%, 0);
      opacity: 1;
      top: 18px;
    }
    &:not([value=""]) + label {
      color: ${gray.hex()};
    }
    &:focus + label {
      color: ${gray.hex()};
    }
    &:focus::placeholder {
      color: ${gray.hex()};
    }
    & + label {
      position: absolute;
      top: 15px;
      padding-left: 10px;
      transition: all 200ms;
      color: ${gray.hex()};
      opacity: 0.7;
    }
    ${props =>
      props.disabled &&
      css`
        opacity: 0.55;
        user-select: none;
        pointer-events: none;
        color: #b7b7b7;
        & + label {
          color: #b7b7b7 !important;
        }
      `};
  }
`;

const CurrencyField = ({ name, label="", disabled, icon: Icon, noMargin, hasError, onChange, ...rest }) => {
  const [field, meta] = useField(name);

  return <Container>
      <Group disabled={disabled} noMargin={noMargin}>
        <CurrencyInput
          decimalSeparator=','
          thousandSeparator='.'
          allowNegative={false}
          {..._.omit(field, 'onChange')}
          {...rest}
          onChangeEvent={(event, strVal, value) => {
            field.onChange({ target: { value, name } });
            onChange && onChange(value);
          }}
          disabled={disabled}
          className={(hasError) ? 'error' : ''}
          value={field.value}
        />
        <label htmlFor={name}>{label}</label>

        { Icon && <Icon size={20} /> }
      </Group>
    {hasError &&
      <div className="error">{meta.error}</div>
    }
  </Container>
};

CurrencyField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  hasError: PropTypes.bool,
}

CurrencyField.defaultProps = {
  hasError: false
}

export default CurrencyField;

// EXEMPLO DE USO
// <CurrencyField
//     name="value"
//     label="Total"
//     hasError={errors.value && touched.value} />
