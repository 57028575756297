import React, { useState } from 'react';
import _ from 'lodash';
import * as Yup from 'yup';
import { InputMask, InputGroup, InputDate, CurrencyField } from '~/components/form';
import FieldSet from '~/components/fieldset';
import { BsRow, BsCol } from '~/components/layout';
import { barcodeToDigitableLine, digitableLineToBarcode } from '~/helper';
import { isValidSlipDigitableLine } from '~/validator';
import { MASK_TAX_DIGITABLE_LINE, MASK_BARCODE } from '~/constants';
import { FaBarcode } from 'react-icons/fa';
import { BiEditAlt } from 'react-icons/bi';

export const id = 31;

export const Render = ({ values, errors, touched, setFieldValue, previewMode, handleBlur }) => {
  const [barCodeMode, setBarCodeMode] = useState(false);
  const toogleBarCodeMode = () => setBarCodeMode(previous => !previous);
  let bankCode = _.get(values, 'bankAccount.bank.code');
  const editLocked = _.get(values, 'editLocked') || false;

  return (
    <FieldSet label="Orgãos Públicos - Tributo com Código de Barras">
      <BsRow>
        <BsCol md={24} lg={24} xl={24}>
          {!barCodeMode && (
            <InputMask
              icon={FaBarcode}
              mask={MASK_TAX_DIGITABLE_LINE}
              name="orderData.digitableLine"
              label="Linha digitável *"
              disabled={previewMode || editLocked}
              hasError={_.get(errors, 'orderData.digitableLine') && _.get(touched, 'orderData.digitableLine')}
              onChange={event => {
                let value = _.get(event, 'target.value');
                setFieldValue('orderData.digitableLine', value);
                setFieldValue('orderData.barcode', digitableLineToBarcode(value));
              }}
              onIconClick={toogleBarCodeMode}
              iconTooltip="Editar código de barras"
            />
          )}
          {barCodeMode && (
            <InputMask
              icon={BiEditAlt}
              mask={MASK_BARCODE}
              name="orderData.barcode"
              label="Código de barras *"
              disabled={previewMode || editLocked}
              hasError={_.get(errors, 'orderData.barcode') && _.get(touched, 'orderData.barcode')}
              onChange={(event) => {
                let value = _.get(event, 'target.value');
                setFieldValue('orderData.barcode', value);
                setFieldValue('orderData.digitableLine', barcodeToDigitableLine(value));
              }}
              onIconClick={toogleBarCodeMode}
              iconTooltip="Editar linha digitável"
            />
          )}
        </BsCol>
      </BsRow>

      <BsRow>
        <BsCol md={24} lg={24} xl={12}>
        <InputGroup
          type="text"
          name="orderData.publicAgency"
          maxLength={100}
          disabled={previewMode || editLocked}
          label="Nome do orgão público *"
          hasError={_.get(errors, 'orderData.publicAgency') && _.get(touched, 'orderData.publicAgency')}
          />
        </BsCol>

        <BsCol md={24} lg={24} xl={12}>
          <InputGroup
            type="text"
            name="orderData.documentNumber"
            maxLength={20}
            disabled={previewMode || editLocked}
            label="Número da Nota fiscal/Documento"
            hasError={_.get(errors, 'orderData.documentNumber') && _.get(touched, 'orderData.documentNumber')}
            />
        </BsCol>
      </BsRow>

      {bankCode === '001' &&
        <FieldSet label="Banco do Brasil - GRU">
          <BsRow>
            <BsCol md={24} lg={24} xl={8}>
              <InputGroup
                type="text"
                name="orderData.refNumber"
                maxLength={20}
                disabled={previewMode || editLocked}
                label="Nº referência"
                hasError={_.get(errors, 'orderData.refNumber') && _.get(touched, 'orderData.refNumber')}
                />
            </BsCol>

            <BsCol md={24} lg={24} xl={8}>
              <InputDate
                name="orderData.period"
                label="Período *"
                monthView={true}
                disabled={previewMode || editLocked}
                hideErrorLabel={false}
                hasError={_.get(errors, 'orderData.period') && _.get(touched, 'orderData.period')}
                />
            </BsCol>

            <BsCol md={24} lg={24} xl={8}>
              <CurrencyField
                name="orderData.value"
                label="Valor principal *"
                disabled={previewMode || editLocked}
                hasError={_.get(errors, 'orderData.value') && _.get(touched, 'orderData.value')}
                />
            </BsCol>

            <BsCol md={24} lg={24} xl={4}>
              <CurrencyField
                name="orderData.fineValue"
                label="Valor da multa"
                disabled={previewMode || editLocked}
                hasError={_.get(errors, 'orderData.fineValue') && _.get(touched, 'orderData.fineValue')}
                />
            </BsCol>

            <BsCol md={24} lg={24} xl={5}>
              <CurrencyField
                name="orderData.interestValue"
                label="Valor do juros"
                disabled={previewMode || editLocked}
                hasError={_.get(errors, 'orderData.interestValue') && _.get(touched, 'orderData.interestValue')}
                />
            </BsCol>

            <BsCol md={24} lg={24} xl={5}>
              <CurrencyField
                name="orderData.discountValue"
                label="Valor do desconto"
                disabled={previewMode || editLocked}
                hasError={_.get(errors, 'orderData.discountValue') && _.get(touched, 'orderData.discountValue')}
                />
            </BsCol>

            <BsCol md={24} lg={24} xl={5}>
              <CurrencyField
                name="orderData.incrementValue"
                label="Valor outros acréscimos"
                disabled={previewMode || editLocked}
                hasError={_.get(errors, 'orderData.incrementValue') && _.get(touched, 'orderData.incrementValue')}
                />
            </BsCol>

            <BsCol md={24} lg={24} xl={5}>
              <CurrencyField
                name="orderData.reductionValue"
                label="Valor outras deduções"
                disabled={previewMode || editLocked}
                hasError={_.get(errors, 'orderData.reductionValue') && _.get(touched, 'orderData.reductionValue')}
                />
            </BsCol>
          </BsRow>
        </FieldSet>
      }
    </FieldSet>
  )
};

export const Schema = (values) => {
  return ({
    orderData: Yup.object().typeError('Informe os dados da ordem').shape({
      publicAgency: Yup.string().required('Informe o Orgão público'),
      digitableLine: Yup.mixed().test('match', '', function(value) {
        const { path, parent, createError } = this;
        const val = (value || _.get(parent, 'digitableLine') || '').replace(/\D/g, '');
        if (!val) {
          return createError({ path, message: 'Informe a linha digitável' });
        }
        if (val && (_.size(val) !== 48 || !isValidSlipDigitableLine(val))) {
          return createError({ path, message: 'Informe uma linha digitável válida' });
        }
        return true;
      }),
    })
  })
};
