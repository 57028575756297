import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import _ from 'lodash';
import { Creators as HeaderActions } from '~/store/modules/header';

const Container = styled.div`
  overflow: hidden;
  height: 100%;
  padding: 10px;
`;

const Iframe = styled.iframe`
  width: calc(100%);
  height: calc(100vh - 110px);
  overflow: hidden;
  border: none;
  padding: 0;
  margin: 0;
  top: 0;
  left:0;
  bottom:0;
  right:0;
  background-color: #fff;
`;

function Help() {
  const dispatch = useDispatch();
  const { menu } = useSelector(state => state.auth);
  const perms = _.map(menu, 'code').join(';');

  useEffect(() => {
    dispatch(HeaderActions.configure({}));
  }, [dispatch]);

  return (
    <Container>
      <Iframe
        title='DocPay Help'
        frameBorder='0'
        src={`//docpay-help.github.io?perms=${perms}`}
        />
    </Container>
  );
}

export default Help;
