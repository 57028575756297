import defaultAvatar from '~/assets/user.svg';
import defaultCompanyLogo from '~/assets/company.svg';
import 'cleave.js/dist/addons/cleave-phone.br';

export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const PERMISSIONS_NAMES = {
  W: 'Alteração',
  M: 'Cadastro manual',
  T: 'Criar acesso provisório',
  CP: 'Contas a pagar',
  CR: 'Contas a receber',
  S: 'Gerar remessa',
  P: 'Imprimir comprovante',
  PA: 'Ver histórico de aprovação',
  BP: 'Bloquear/desbloquear pagamento',
  PE: 'Ver dados externos'
};

export const getAvatar = ({ id, avatar, avatarBase64 }, changed) => {
  let block = changed ? `&_=${new Date().getTime()}` : '';
  return avatar ? `${BASE_URL}/users/load-avatar?id=${id}${block}` : avatarBase64 || null;
};

export const getAvatarDefault = () => {
  return defaultAvatar;
};

export const getCompanyLogoDefault = () => {
  return defaultCompanyLogo;
};

export const payMethodTypes = {
  '1': 'Boleto',
  '2': 'DOC/TED/Transferência',
  '3': 'PIX'
};

export const UFS = {
  AC: 'Acre',
  AL: 'Alagoas',
  AP: 'Amapá',
  AM: 'Amazonas',
  BA: 'Bahia',
  CE: 'Ceará',
  DF: 'Distrito Federal',
  ES: 'Espírito Santo',
  GO: 'Goiás',
  MA: 'Maranhão',
  MT: 'Mato Grosso',
  MS: 'Mato Grosso do Sul',
  MG: 'Minas Gerais',
  PA: 'Pará',
  PB: 'Paraíba',
  PR: 'Paraná',
  PE: 'Pernambuco',
  PI: 'Piauí',
  RJ: 'Rio de Janeiro',
  RN: 'Rio Grande do Norte',
  RS: 'Rio Grande do Sul',
  RO: 'Rondônia',
  RR: 'Roraima',
  SC: 'Santa Catarina',
  SP: 'São Paulo',
  SE: 'Sergipe',
  TO: 'Tocantins'
};

export const MASK_PHONE = {
  phone: true,
  phoneRegionCode: 'BR',
};

export const MASK_DATE = {
  date: true,
  delimiter: '/',
  datePattern: ['d', 'm', 'Y']
};

export const MASK_CNPJ = {
  numericOnly: true,
  delimiters: ['.', '.', '/', '-'],
  blocks: [2, 3, 3, 4, 2]
};

export const MASK_CPF = {
  numericOnly: true,
  delimiters: ['.', '.', '-'],
  blocks: [3, 3, 3, 2]
};

export const MASK_PIS_PASEP = {
  numericOnly: true,
  delimiters: ['.', '.', '-'],
  blocks: [3, 4, 3, 1]
};

export const MASK_CEP = {
  numericOnly: true,
  delimiters: ['.', '-'],
  blocks: [2, 3, 3]
};

export const MASK_UUID = {
  delimiters: ['-', '-', '-', '-'],
  blocks: [8, 4, 4, 4, 12]
};

export const MASK_BARCODE = {
  numericOnly: true,
  delimiters: [],
  blocks: [44]
};

export const MASK_BANK_DIGITABLE_LINE = {
  numericOnly: true,
  delimiters: ['.', ' ', '.', ' ', '.', ' ', ' '],
  blocks: [5, 5, 5, 6, 5, 6, 1, 14]
};

export const MASK_TAX_DIGITABLE_LINE = {
  numericOnly: true,
  delimiters: [' ', ' ', ' ', ' ', ' ', ' ', ' '],
  blocks: [11, 1, 11, 1, 11, 1, 11, 1]
};

export const slipVariables = {
  10: [
    { id: 'cliente.nome', label: 'Nome do cliente', group: 'Cobrança', movementType: 'charge' },
    { id: 'cliente.id', label: 'CNPJ/CPF do cliente', group: 'Cobrança', movementType: 'charge' },
    { id: 'boleto.valor', label: 'Valor', group: 'Cobrança', movementType: 'charge' },
    { id: 'boleto.dataVencimento', label: 'Data de vencimento', group: 'Cobrança', movementType: 'charge' },
    { id: 'boleto.link', label: 'Link do boleto', group: 'Cobrança', movementType: 'charge' },
    { id: 'boleto.linhaDigitavel', label: 'Linha digitável', group: 'Cobrança', movementType: 'charge' },
    { id: 'boleto.numeroDoc', label: 'Número do documento', group: 'Cobrança', movementType: 'charge' },
    { id: 'boleto.dataDoc', label: 'Data do documento', group: 'Cobrança', movementType: 'charge' },
    { id: 'boleto.jurosMora', label: 'Valor dos juros', group: 'Cobrança', movementType: 'charge' },
    { id: 'boleto.jurosMoraAtualizado', label: 'Valor dos juros atualizado', group: 'Cobrança', movementType: 'charge' },
    { id: 'boleto.multa', label: 'Valor da multa', group: 'Cobrança', movementType: 'charge' },
    { id: 'boleto.desconto', label: 'Valor desconto', group: 'Cobrança', movementType: 'charge' },
    { id: 'boleto.valorTotal', label: 'Valor total (multa + juros)', group: 'Cobrança', movementType: 'charge' },
    { id: 'boleto.chaveNFe', label: 'Chave NF-e', group: 'Cobrança', movementType: 'charge' },
    { id: 'boleto.linkNFSe', label: 'Link NFS-e', group: 'Cobrança', movementType: 'charge' },
    { id: 'boleto.numeroRPS', label: 'Número RPS', group: 'Cobrança', movementType: 'charge' },
    { id: 'boleto.numeroNFSe', label: 'Número NFS-e', group: 'Cobrança', movementType: 'charge' },
    { id: 'boleto.numeroPedido', label: 'Número do Pedido', group: 'Cobrança', movementType: 'charge' },
    { id: 'boleto.protocoloNFSe', label: 'Protocolo NFS-e', group: 'Cobrança', movementType: 'charge' },
    { id: 'boleto.urlPix', label: 'Url PIX', group: 'Cobrança', movementType: 'charge' },
    { id: 'boleto.seuNumero', label: 'Seu número', group: 'Cobrança', movementType: 'charge' },
  ],
  40: [
    { id: 'cliente.nome', label: 'Nome do cliente', group: 'Cobrança', movementType: 'charge' },
    { id: 'cliente.id', label: 'CNPJ/CPF do cliente', group: 'Cobrança', movementType: 'charge' },
    { id: 'transferencia.valor', label: 'Valor', group: 'Cobrança', movementType: 'charge' },
    { id: 'transferencia.numeroDoc', label: 'Número do documento', group: 'Cobrança', movementType: 'charge' },
    { id: 'transferencia.dataDoc', label: 'Data do documento', group: 'Cobrança', movementType: 'charge' },
    { id: 'transferencia.chaveNFe', label: 'Chave NF-e', group: 'Cobrança', movementType: 'charge' },
    { id: 'transferencia.linkNFSe', label: 'Link NFS-e', group: 'Cobrança', movementType: 'charge' },
    { id: 'transferencia.numeroRPS', label: 'Número RPS', group: 'Cobrança', movementType: 'charge' },
    { id: 'transferencia.numeroNFSe', label: 'Número NFS-e', group: 'Cobrança', movementType: 'charge' },
    { id: 'transferencia.numeroPedido', label: 'Número do Pedido', group: 'Cobrança', movementType: 'charge' },
    { id: 'transferencia.protocoloNFSe', label: 'Protocolo NFS-e', group: 'Cobrança', movementType: 'charge' },
  ]
};

export const paymentVariables = [
  { id: 'fornecedor.nome', label: 'Nome do Fornecedor', group: 'Ordem de Pagamento', movementType: 'payment' },
  { id: 'fornecedor.id', label: 'CNPJ/CPF do Fornecedor', group: 'Ordem de Pagamento', movementType: 'payment' },
  { id: 'pagamento.valor', label: 'Valor', group: 'Ordem de Pagamento', movementType: 'payment' },
  { id: 'pagamento.dataVencimento', label: 'Data de vencimento', group: 'Ordem de Pagamento', movementType: 'payment' },
  { id: 'pagamento.numeroDoc', label: 'Número do documento', group: 'Ordem de Pagamento', movementType: 'payment' },
  { id: 'pagamento.listaDocumentos', label: 'Lista de Documentos', group: 'Ordem de Pagamento', movementType: 'payment' },
];

export const acceptValues = [
  { value: '', label: 'Não definido' },
  { value: 'true', label: 'Sim' },
  { value: 'false', label: 'Não' }
];

export const fineDaysTypeValues = [
  { value: '', label: 'Não definido' },
  { value: 'businessDays', label: 'Úteis' },
  { value: 'runningDays', label: 'Corridos' }
];

export const brStatesValues = [
  { value: 'AC', label: 'Acre' },
  { value: 'AL', label: 'Alagoas' },
  { value: 'AP', label: 'Amapá' },
  { value: 'AM', label: 'Amazonas' },
  { value: 'BA', label: 'Bahia' },
  { value: 'CE', label: 'Ceará' },
  { value: 'DF', label: 'Distrito Federal' },
  { value: 'ES', label: 'Espírito Santo' },
  { value: 'GO', label: 'Goiás' },
  { value: 'MA', label: 'Maranhão' },
  { value: 'MT', label: 'Mato Grosso' },
  { value: 'MS', label: 'Mato Grosso do Sul' },
  { value: 'MG', label: 'Minas Gerais' },
  { value: 'PA', label: 'Pará' },
  { value: 'PB', label: 'Paraíba' },
  { value: 'PR', label: 'Paraná' },
  { value: 'PE', label: 'Pernambuco' },
  { value: 'PI', label: 'Piauí' },
  { value: 'RJ', label: 'Rio de Janeiro' },
  { value: 'RN', label: 'Rio Grande do Norte' },
  { value: 'RS', label: 'Rio Grande do Sul' },
  { value: 'RO', label: 'Rondônia' },
  { value: 'RR', label: 'Roraima' },
  { value: 'SC', label: 'Santa Catarina' },
  { value: 'SP', label: 'São Paulo' },
  { value: 'SE', label: 'Sergipe' },
  { value: 'TO', label: 'Tocantins' }
];

export const documentTypeValues = [
  { value: '02', label: 'DM Duplicata Mercantil' },
  { value: '01', label: 'CH Cheque' },
  { value: '03', label: 'DMI Duplicata Mercantil p/ Indicação' },
  { value: '04', label: 'DS Duplicata de Serviço' },
  { value: '05', label: 'DSI Duplicata de Serviço p/ Indicação' },
  { value: '06', label: 'DR Duplicata Rural' },
  { value: '07', label: 'LC Letra de Câmbio' },
  { value: '08', label: 'NCC Nota de Crédito Comercial' },
  { value: '09', label: 'NCE Nota de Crédito a Exportação' },
  { value: '10', label: 'NCI Nota de Crédito Industrial' },
  { value: '11', label: 'NCR Nota de Crédito Rural' },
  { value: '12', label: 'NP Nota Promissória' },
  { value: '13', label: 'NPR Nota Promissória Rural' },
  { value: '14', label: 'TM Triplicata Mercantil' },
  { value: '15', label: 'TS Triplicata de Serviço' },
  { value: '16', label: 'NS Nota de Seguro' },
  { value: '17', label: 'RC Recibo' },
  { value: '18', label: 'FAT Fatura' },
  { value: '19', label: 'ND Nota de Débito' },
  { value: '20', label: 'AP Apólice de Seguro' },
  { value: '21', label: 'ME Mensalidade Escolar' },
  { value: '22', label: 'PC Parcela de Consórcio' },
  { value: '23', label: 'NF Nota Fiscal' },
  { value: '24', label: 'DD Documento de Dívida' },
  { value: '25', label: 'Cédula de Produto Rural' },
  { value: '26', label: 'Warrant' },
  { value: '27', label: 'Dívida Ativa de Estado' },
  { value: '28', label: 'Dívida Ativa de Município' },
  { value: '29', label: 'Dívida Ativa da União' },
  { value: '30', label: 'Encargos condominiais' },
  { value: '31', label: 'CC Cartão de Crédito' },
  { value: '32', label: 'BDP - Boleto de Proposta' },
  { value: '33', label: 'Boleto de Depósito e Aporte' },
  { value: '99', label: 'Outros' }
];

export const sampleTemplateSubject = 'Boleto com data de vencimento em <%=boleto.dataVencimento%>'

export const sampleTemplate = `
  <p>&lt;%=cliente.nome%&gt;,</p>
  <p><br></p>
  <p>Segue em anexo o boleto bancário referente ao documento <strong>&lt;%=boleto.numeroDoc%&gt;</strong>:</p>
  <p><br></p>
  <p>Vencimento:&nbsp;&lt;%=boleto.dataVencimento%&gt;</p>
  <p>Valor: &lt;%=boleto.valor%&gt;</p>
  <p><br></p>
  <p>Você pode visualizar o boleto no link:</p>
  <p>&lt;%=boleto.link%&gt;</p>
  <p><br></p>
  <p>Obrigado.</p>
`;

export const billEventsMap = {
  '001': {
    cnab240: {
      '01': 'register',
      '02': 'cancel',
      '04': 'decrement',
      '05': 'cancel_decrement',
      '06': 'due_date_change',
      '09': 'protest',
    },
    cnab400: {
      '01': 'register',
      '02': 'cancel',
      '04': 'decrement',
      '05': 'cancel_decrement',
      '06': 'due_date_change',
      '09': 'protest',
    },
  },
  '033': {
    cnab240: {
      '01': 'register',
      '02': 'cancel',
      '04': 'decrement',
      '05': 'cancel_decrement',
      '06': 'due_date_change',
      '09': 'protest',
    },
    cnab400: {
      '01': 'register',
      '02': 'cancel',
      '04': 'decrement',
      '05': 'cancel_decrement',
      '06': 'due_date_change',
      '09': 'protest',
    },
  },
  '041': {
    cnab240: {
      '01': 'register',
      '02': 'cancel',
      '04': 'decrement',
      '05': 'cancel_decrement',
      '06': 'due_date_change',
      '09': 'protest',
    },
  },
  '077': {
    cnab400: {
      '01': 'register',
    },
  },
  104: {
    cnab240: {
      '01': 'register',
      '02': 'cancel',
      '04': 'decrement',
      '05': 'cancel_decrement',
      '06': 'due_date_change',
      '09': 'protest',
    },
    cnab400: {
      '01': 'register',
      '02': 'cancel',
      '03': 'decrement',
      '04': 'cancel_decrement',
      '05': 'due_date_change',
      '07': 'protest',
    },
  },
  208: {
    cnab240: {
      '01': 'register',
      '02': 'cancel',
      '04': 'decrement',
      '05': 'cancel_decrement',
      '06': 'due_date_change',
      '09': 'protest',
    },
  },
  218: {
    cnab400: {
      '01': 'register',
      '02': 'cancel',
      '04': 'decrement',
      '05': 'cancel_decrement',
      '06': 'due_date_change',
      '09': 'protest',
    },
  },
  237: {
    cnab240: {
      '01': 'register',
      '02': 'cancel',
      '04': 'decrement',
      '05': 'cancel_decrement',
      '06': 'due_date_change',
      '09': 'protest',
    },
    cnab400: {
      '01': 'register',
      '02': 'cancel',
      '04': 'decrement',
      '05': 'cancel_decrement',
      '06': 'due_date_change',
      '09': 'protest',
    },
  },
  246: {
    cnab240: {
      '01': 'register',
      '02': 'cancel',
      '04': 'decrement',
      '05': 'cancel_decrement',
      '06': 'due_date_change',
      '09': 'protest',
    },
  },
  341: {
    cnab240: {
      '01': 'register',
      '02': 'cancel',
      '04': 'decrement',
      '05': 'cancel_decrement',
      '06': 'due_date_change',
      '09': 'protest',
    },
    cnab400: {
      '01': 'register',
      '02': 'cancel',
      '04': 'decrement',
      '05': 'cancel_decrement',
      '06': 'due_date_change',
      '09': 'protest',
    },
  },
  422: {
    cnab240: {
      '01': 'register',
      '02': 'cancel',
      '04': 'decrement',
      '05': 'cancel_decrement',
      '06': 'due_date_change',
      '09': 'protest',
    },
    cnab400: {
      '01': 'register',
      '02': 'cancel',
      '04': 'decrement',
      '05': 'cancel_decrement',
      '06': 'due_date_change',
      '09': 'protest',
    },
  },
  643: {
    cnab400: {
      '01': 'register',
      '02': 'cancel',
      '04': 'decrement',
      '05': 'cancel_decrement',
      '06': 'due_date_change',
      '09': 'protest',
    },
  },
  707: {
    cnab400: {
      '01': 'register',
      '02': 'cancel',
      '04': 'decrement',
      '05': 'cancel_decrement',
      '06': 'due_date_change',
      '09': 'protest',
    },
  },
  745: {
    cnab240: {
      '01': 'register',
      '02': 'cancel',
      '04': 'decrement',
      '05': 'cancel_decrement',
      '06': 'due_date_change',
      '09': 'protest',
    },
    cnab400: {
      '01': 'register',
      '02': 'cancel',
      '04': 'decrement',
      '05': 'cancel_decrement',
      '06': 'due_date_change',
      '09': 'protest',
    },
  },
  748: {
    cnab240: {
      '01': 'register',
      '02': 'cancel',
      31: 'decrement',
      '05': 'cancel_decrement',
      '06': 'due_date_change',
      '09': 'protest',
    },
    cnab400: {
      '01': 'register',
      '02': 'cancel',
      '05': 'cancel_decrement',
      '06': 'due_date_change',
      '09': 'protest',
    },
  },
  752: {
    cnab400: {
      '01': 'register',
      '02': 'cancel',
      '05': 'cancel_decrement',
      '06': 'due_date_change',
      '09': 'protest',
    },
  },
  756: {
    cnab240: {
      '01': 'register',
      '02': 'cancel',
      31: 'decrement',
      '05': 'cancel_decrement',
      '06': 'due_date_change',
      '09': 'protest',
    },
  },
};

