import React from 'react';
import Fieldset from '~/components/fieldset';
import { Row } from '~/components/layout';
import _ from 'lodash';
import { FilterContainer } from '~/components/crud/styles';
import { InputGroup, InputDate, Autocomplete } from '~/components/form';
import { isBefore, isAfter } from 'date-fns';
import { formats } from '~/helper';

export default function FormFilter(props) {
  return (
    <FilterContainer>
      <Autocomplete
        name="company"
        keyField="id"
        label="Emissor"
        clearable={true}
        value={_.get(props.values, 'company')}
        valueFormat={row => `${formats.cnpj_cpf(row.identity)} - ${row.name || row.tradeName}`}
        loadData={props.onListCompanies}
        emptyText={'Pesquise um emissor'}
        tipText={'Digite... '}
        loadingText={'Carregando...'}
        notFoundText={'Não encontrada'}
      />

      <Autocomplete
        name="businessPartner"
        keyField="id"
        label="Pagador"
        clearable={true}
        value={_.get(props.values, 'businessPartner')}
        valueFormat={row => `${formats.cnpj_cpf(row.identity)} - ${row.name || row.tradeName}`}
        loadData={props.onListBusinessPartners}
        emptyText={'Pesquise um pagador'}
        tipText={'Digite... '}
        loadingText={'Carregando...'}
        notFoundText={'Não encontrado'}
      />

      <InputGroup
        type="text"
        name="documentNumber"
        label="Número do documento"
        maxLength={30} />

      <Fieldset label="Data de Vencimento">
        <Row span={2}>
          <InputDate
            name="dueDateStart"
            label="De"
            onChange={value => {
              props.setFieldValue('dueDateStart', value);
              if (props.values.dueDateEnd && isAfter(value, props.values.dueDateEnd)) {
                props.setFieldValue('dueDateStart', props.values.dueDateEnd);
                props.setFieldValue('dueDateEnd', value);
              }
            }}
          />

          <InputDate
            name="dueDateEnd"
            label="Até"
            onChange={value => {
              props.setFieldValue('dueDateEnd', value);
              if (props.values.dueDateStart && isBefore(value, props.values.dueDateStart)) {
                props.setFieldValue('dueDateStart', value);
                props.setFieldValue('dueDateEnd', props.values.dueDateStart);
              }
            }}
          />
        </Row>
        <Row></Row>
      </Fieldset>

      <Fieldset label="Data de documento">
        <Row span={2}>
          <InputDate
            name="documentDateStart"
            label="De"
            onChange={value => {
              props.setFieldValue('documentDateStart', value);
              if (props.values.documentDateEnd && isAfter(value, props.values.documentDateEnd)) {
                props.setFieldValue('documentDateStart', props.values.documentDateEnd);
                props.setFieldValue('documentDateEnd', value);
              }
            }}
          />

          <InputDate
            name="documentDateEnd"
            label="Até"
            onChange={value => {
              props.setFieldValue('documentDateEnd', value);
              if (props.values.documentDateStart && isBefore(value, props.values.documentDateStart)) {
                props.setFieldValue('documentDateStart', value);
                props.setFieldValue('documentDateEnd', props.values.documentDateStart);
              }
            }}
          />
        </Row>
        <Row></Row>
      </Fieldset>
    </FilterContainer>
  );
}
