import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Creators as HeaderActions } from '~/store/modules/header';
import logoImg from '~/assets/logo-v.svg';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  user-select: none;

  img {
    height: 200px;
    opacity: 0.75;
  }
`;

function Home() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(HeaderActions.configure({}));
  }, [dispatch]);

  return (
    <Container>
      <img src={logoImg} alt='logo' />
    </Container>
  );
}

export default Home;
