import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import _ from 'lodash'

import Modal from '~/components/modal';
import { FormContainer } from '~pages/landing/styles'
import { InputGroup, InputPhone } from '~/components/form'
import Logo from '~components/logo'

const SignUpForm = ({ isOpen, toggleModal, handleOnSubmit }) => {
  return (
    <Formik
      initialValues={{name: ''}}
      validationSchema={schema}
      onSubmit={handleOnSubmit}
      validateOnMount={false}
      initialTouched={{ 'name': true }}
      >
      { ({ errors, isValid, touched, handleSubmit, handleReset, isSubmitting, values }) => (
        <Modal
          width="600px"
          height="472px"
          hideClose={true}
          bindRefWithSubmit={true}
          open={isOpen}
          hide={toggleModal}
          actions={[
            {
              label: 'Cancelar',
              action: () => { handleReset(); toggleModal(); }
            },
            {
              label: isSubmitting ? 'Carregando...' : 'Cadastrar',
              action: handleSubmit,
              type: 'submit',
              disabled: !isValid || isSubmitting,
              primary: true
            }
          ]}
          >
          <FormContainer>
            <Form>
              <Logo inverse height="36px" />

              <h3>
                Preencha os dados abaixo para criar sua conta DocPay
              </h3>

              <InputGroup
                type="text"
                name="name"
                label="Nome"
                autoFocus
                value={values.name}
                maxLength={100}
                hasError={errors.name && touched.name}
                />

              <InputPhone
                name="phoneNumber"
                label="Telefone"
                country="BR"
                value={values.phoneNumber}
                hasError={errors.phoneNumber && touched.phoneNumber}
                />

              <InputGroup
                type="email"
                name="email"
                label="E-mail"
                maxLength={100}
                value={values.email}
                hasError={errors.email && touched.email}
                />

            </Form>
          </FormContainer>
        </Modal>
      )}
    </Formik>
  )
}

const schema = Yup.object().shape({
  name: Yup.string().required('O Nome é obrigatório').test('len', 'Informe um Nome válido', val => _.size(val) >= 3),
  phoneNumber: Yup.string().required('O Telefone é obrigatório').test('len', 'Informe um Telefone válido', val => _.size(String(val).replace(/\D/g,'')) >= 10),
  email: Yup.string().required('O E-mail é obrigatório').email('Informe um E-mail válido')
});

SignUpForm.propTypes = {
  toggleModal: PropTypes.func,
  handleOnSubmit: PropTypes.func
};

export default SignUpForm;
