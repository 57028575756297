import React from 'react';
import * as Yup from 'yup';
import _ from 'lodash';
import { Checkbox, InputGroup } from '~/components/form';
import { FormContainer } from '~/pages/representatives/styles';

export const RepresentativeForm = ({ errors, status, touched, previewMode, setFieldValue }) => {
  return (
    <FormContainer>
      <Checkbox name="active" label="Ativo" halfHeight={true} disabled={previewMode} />

      <InputGroup disabled={previewMode} type="text" name="name" label="Nome" maxLength={100} hasError={_.get(errors, 'name') && _.get(touched, 'name')} />

      <InputGroup
        disabled={previewMode}
        type="email"
        name="email"
        label="E-mail"
        maxLength={100}
        hasError={_.get(errors, 'email') && _.get(touched, 'email')}
        onChange={evt => {
          setFieldValue('email', _.get(evt, 'target.value', '').toLowerCase());
        }}
      />

      {_.get(status, 'msg', false) && <div>{status.msg}</div>}
    </FormContainer>
  );
};

export const RepresentativeSchema = Yup.object().shape({
  name: Yup.string()
    .min(4, 'Verifique se o nome está correto')
    .required('Informe o nome'),
  email: Yup.string()
    .matches(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'Informe um E-mail válido')
    .nullable().email('Informe um E-mail válido'),
});
