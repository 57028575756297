import React from 'react';
import ReactDOM from 'react-dom';
import { Background, Container, Title, Content, Close } from './styles';
import api from '~/services/api';

class PDF extends React.Component {
  state = {
    isOpen: false,
    reportProps: {},
    content: ''
  };

  resolve = null;

  iframeRef = React.createRef(null);

  async loadContent({ url, data, method }) {
    let response;

    let reader = new window.FileReader();

    if (method === 'post') {
      response = await api.post(url, {ids: data}, { responseType: 'blob' });
    } else {
      response = await api.get(url, { responseType: 'blob' });
    }

    reader.readAsDataURL(response.data);
    
    reader.onload = () => {
      let content = reader.result;
      this.setState({ content });
    };
  };

  static create(props = {}) {
    const el = document.createElement('div');
    el.id = `pdf-${new Date().getTime()}`;
    document.body.appendChild(el);

    return ReactDOM.render(<PDF reportProps={props} />, el);
  };

  handleCancel = () => {
    this.setState({ isOpen: false, reportProps: {} });
    this.resolve(false);
  };

  handleConfirm = () => {
    this.setState({ isOpen: false, reportProps: {} });
    this.resolve(true);
  };

  show = (props = {}) => {
    const reportProps = { ...this.props.reportProps, ...props };
    this.setState({ isOpen: true, reportProps });
    return new Promise(res => {
      this.resolve = res;
      if (reportProps.useAuth) {
        this.loadContent(reportProps);
      }
    });
  };

  render() {
    const { isOpen, reportProps, content } = this.state;
    const { title = '', url, width = '70%', height = '90%' } = reportProps;

    if (!url || url.length === 0 || !isOpen) return <></>;

    return (
      <Background isOpen={isOpen}>
        <Container height={height} width={width}>
          <Close onClick={this.handleCancel} />
          <Title>
            <div>{title}</div>
          </Title>
          <Content>
            <iframe
              title="pdf"
              ref={this.iframeRef}
              src={`${reportProps.useAuth ? content : url}`}
              style={{ width: '100%', height: '100%', border: 'none', margin: '0', padding: '0' }}
              onLoad={() => {
                console.log(this.iframeRef.body)
              }}
              />
          </Content>
        </Container>
      </Background>
    );
  }
}

export default PDF;
