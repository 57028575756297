import React, { useState } from 'react'
import _ from 'lodash'
import DataTable from '~/components/datatable'
import { TabContainer } from '~/pages/responses/styles'
import { formats } from 'helper'
import CellBank from '~/components/datatable/cell-bank'
import { isValidCnpj } from '~/validator'
import TextWithHint from '~/components/text-with-hint'

const columns = [
  {
    name: 'Data Lançamento',
    width: '130px',
    selector: 'docDate',
    cell: (row) => <TextWithHint text={formats.dateTimeZone(_.get(row, 'docDate'), 'dd/MM/yyyy')} />,
  },
  {
    name: 'Nº controle',
    width: '120px',
    selector: 'controlNumber',
    cell: (row) => <TextWithHint text={_.get(row, 'controlNumber', '')} />,
  },
  {
    name: 'Valor (R$)',
    selector: 'value',
    width: '120px',
    right: true,
    cell: (row) => <TextWithHint text={formats.decimal(_.get(row, 'value'))} />,
  },
  {
    name: 'Remetente',
    selector: 'senderIdentity',
    hide: 'md',
    wrap: true,
    cell: (row) => {
      let name = _.get(row, 'senderName'),
        identity = isValidCnpj(_.get(row, 'senderIdentity')) ? _.get(row, 'senderIdentity') : String(_.get(row, 'senderIdentity')).length > 3 ? String(_.get(row, 'senderIdentity')).substring(3) : _.get(row, 'senderIdentity'),
        formatedIdentiy = formats.cnpj_cpf(identity) || identity
      return <TextWithHint  text={[formatedIdentiy, name].join(' - ')}/>
    },
  },
  {
    name: 'Banco origem',
    selector: 'bankAccount',
    width: '80px',
    center: true,
    cell: (row) => {
      return (
        <CellBank
          bankCode={_.get(row, 'sourceBankCode')}
        />
      )
    },
  },
]

const getRows = (array = [], offset, limit) => {
  return array.slice(offset, offset + limit)
}

export const TabcreditReceived = ({ creditReceived, openDocument }) => {
  const count = _.size(creditReceived)

  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(20)

  const onChangePage = (offset, limit) => {
    setOffset(offset)
    setLimit(limit)
  }

  return (
    <TabContainer>
      <DataTable
        emptyText="Nenhum crédito recebido processado"
        pageSize={limit}
        onChangePage={onChangePage}
        hideUpdateButton={true}
        data={{
          rows: getRows(creditReceived, offset, limit),
          offset,
          count,
          limit: limit,
        }}
        onRowClicked={openDocument('creditReceived')}
        columns={columns}
      />
    </TabContainer>
  )
}
