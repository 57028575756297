import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MdSave, MdClear, MdCheck, MdFileDownload } from 'react-icons/md';
import _ from 'lodash';
import confirm from '~/components/confirm';
import FormFilter from '~/pages/report-requests/filter';
import { Creators as ReportRequestActions } from '~/store/modules/report-request';
import { Creators as HeaderActions } from '~/store/modules/header';
import { Container } from '~/pages/report-requests/styles';
import CellStatus from '~/components/datatable/cell-status';
import { ReportRequestForm, ReportRequestSchema } from '~/pages/report-requests/form';
import { SubtitleItem, Subtitle } from '~/components/datatable/subtitle';
import Crud from '~/components/crud';
import { formats } from '~/helper';

export const statusColors = {
  pending: '#efd600',
  executing: '#90d1ff',
  available: '#a3d865'
};

export const statusLabels = {
  pending: 'Pendente',
  executing: 'Gerando',
  available: 'Disponível'
};

const columns = [
    {
      name: ' ',
      selector: 'status',
      width: '10px',
      cell: row => {
        let status = row.status || 'pending';
        return <CellStatus title={statusLabels[status]} color={statusColors[status]} />;
      }
    },
    {
      name: 'Relatório',
      selector: 'reportType',
      cell: (row) => _.get(row, 'reportType.name', '')
    },
    {
      name: 'Tipo de Data',
      selector: 'dateType',
      cell: (row) => _.get(row, 'dateType', '') === 'updatedAt' ? 'Última Atualização' : 'Cadastro'
    },
    {
      name: 'Período de',
      selector: 'dateFrom',
      cell: (row) => formats.dateTimeZone(_.get(row, 'dateFrom'), 'dd/MM/yyyy')
    },
    {
      name: 'Período até',
      selector: 'dateTo',
      cell: (row) => formats.dateTimeZone(_.get(row, 'dateTo'), 'dd/MM/yyyy')
    },
    {
      name: 'Usuário',
      selector: 'user',
      cell: (row) => _.get(row, 'user.name', '')
    },
    {
      name: 'Cadastrado em',
      selector: 'createdAt',
      width: '140px',
      hide: 'md',
      format: (row) => formats.dateTimeZone(_.get(row, 'createdAt'), 'dd/MM/yyyy HH:mm')
    },
    {
      name: 'Disponível até',
      selector: 'expiresAt',
      width: '140px',
      hide: 'md',
      format: (row) => formats.dateTimeZone(_.get(row, 'expiresAt'), 'dd/MM/yyyy HH:mm')
    },
  ];

function ReportRequest({ match, acls }) {
  const dispatch = useDispatch();
  const state = useSelector(state => state.reportRequest);
  const headerState = useSelector(state => state.header);
  const canWrite = acls.includes('W');
  const [openForm, setOpenForm] = useState(!!_.get(match, 'params.id'));
  const showFilter = headerState.filter.visible;
  const hasData = _.get(state, 'model.id');

  useEffect(() => {
    const config = {
      loading: state.loading,
      useFilter: true,
      filter: { ...headerState.filter, visible: false }
    }
    dispatch(HeaderActions.configure(config));
    // eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    dispatch(ReportRequestActions.list({ ...headerState.filter.data, offset: 0 }));
    // eslint-disable-next-line
  }, [dispatch]);

  function handleOnSubmit(data, actions) {
    dispatch(ReportRequestActions.createOrUpdate(data, actions));
  };

  function handlePageChange(offset, limit) {
    dispatch(ReportRequestActions.list({ ...headerState.filter.data, offset, limit }));
  };

  function handleLoad(data) {
    setOpenForm(true);
    dispatch(ReportRequestActions.load(data));
  };

  function handleHideFilter() {
    dispatch(HeaderActions.hideFilter());
    dispatch(ReportRequestActions.clearModel());
    setOpenForm(false);
  };

  function handleFilter(data) {
    handleHideFilter();
    dispatch(HeaderActions.callFilter({ ...data, offset: 0 }, ReportRequestActions.list));
  };

  function handleListReportTypes(term, callback) {
    dispatch(ReportRequestActions.listReportTypes(term, callback));
  };

  async function handleDownloadFile(data, actions) {
    const result = await confirm.show({
        title: 'Atenção',
        text: `Deseja baixar o relatório "${data.reportType.name}"?`
      });

    if (result) {
      dispatch(ReportRequestActions.downloadFile(data, actions));
    }
  };

  return (
    <Container>

     {!openForm && !showFilter &&
        <Subtitle bottom={25} left={20}>
          <span>Legenda:</span>
          <SubtitleItem color={statusColors.pending}>Pendente</SubtitleItem>
          <SubtitleItem color={statusColors.executing}>Executando</SubtitleItem>
          <SubtitleItem color={statusColors.available}>Disponível</SubtitleItem>
        </Subtitle>
      }

      <Crud
        useOpenForm={true}
        openForm={openForm || showFilter}
        hideAdd={!canWrite}
        columns={columns}
        emptyText="Nenhum relatório encontrado"
        formTitle={data => showFilter ? `Filtro` : ((_.get(data, 'id')) ? `Relatório (${_.get(data, 'reportType.name')})` : `Novo relatório`)}
        data={state.data}
        tableLoading={state.loading}
        formLoading={state.formLoading}
        onChangePage={handlePageChange}
        onCloseFilter={handleHideFilter}
        onRowClicked={handleLoad}
        rightWidth={`${showFilter ? 450 : 600}px`}
        formOptions={{
          initialValues: showFilter ? headerState.filter.data : (state.model || {}),
          validationSchema: showFilter ? undefined : ReportRequestSchema,
          initialTouched: showFilter ? {} : { filters: {} }
        }}
        renderForm={args => showFilter ?
          <FormFilter
            {...args}
            onListReportTypes={handleListReportTypes}
            />
          :
          <ReportRequestForm
            {...args}
            previewMode={!canWrite || hasData}
            permissions={state.permissions}
            onListReportTypes={handleListReportTypes}
            />
        }
        actions={showFilter ?
          [
            { label: 'Limpar Filtro', icon: MdClear, action: () => handleFilter({}) },
            { label: 'Aplicar Filtro', icon: MdCheck, action: data => handleFilter(data) }
          ]
          :
          [
            {
              label: 'Baixar Arquivo',
              icon: MdFileDownload,
              isDisabled: () => !hasData || (_.get(state, 'model.status') !== 'available') || !canWrite,
              action: handleDownloadFile
            },
            {
              label: 'Salvar',
              icon: MdSave,
              isSubmit: true,
              isDisabled: () => hasData,
              action: handleOnSubmit
            }
          ]
        }
        />
    </Container>
  );
}

export default ReportRequest;
