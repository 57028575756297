import { all, takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { Creators as ReportRequestActions, Types as ReportRequestTypes } from '~/store/modules/report-request';
import api from '~/services/api';
import { getError } from '~/helper';
import { format } from 'date-fns';
import _ from 'lodash';

export function* createOrUpdate({ data, actions }) {
  try {
    let editing = !!((data && data.id)),
      method = editing ? 'put' : 'post',
      path = editing ? 'update' : 'create';

    yield call(api[method], `report-requests/${path}`, data);

    toast.success(`Relatório ${editing ? 'atualizado' : 'criado'} com sucesso`);
    actions.closeForm && actions.closeForm();
    actions.reloadList && actions.reloadList();
  } catch (error) {
    showError(error);
  }
}

export function* list({ params }) {
  try {
    const response = yield call(api.post, 'report-requests/list', params);
    yield put(ReportRequestActions.listSuccess(response.data));
  } catch (error) {
    showError(error);
  }
}

export function* load({ data }) {
  try {
    const response = yield call(api.get, 'report-requests/load', { params: { id: data.id || 'new' } });
    yield put(ReportRequestActions.loadSuccess(response.data));
  } catch (error) {
    showError(error);
  }
}

export function* downloadFile({ data }) {
  try {
    const params = { id: _.get(data, 'id', '') },
      response = yield call(api.get, 'report-requests/download-file', { params, responseType: 'blob' }),
      filename = `relatorio-${_.kebabCase(_.get(data, 'reportType.code', params.id))}-${format(new Date(), 'yyyy-MM-dd-HH-mm-ss')}.xlsx`;
    api.download(response, filename);
  } catch (error) {
    showError(error);
  }
  yield put(ReportRequestActions.formActionDone());
}

export function* listReportTypes({ term, callback }) {
  try {
    const response = yield call(api.get, 'report-requests/list-report-types', { params: { term } });
    yield call(callback, response.data);
  } catch (error) {
  }
};

export function showError(error) {
  const msg = getError(error);
  toast.error(msg);
}

export default all([
  takeLatest(ReportRequestTypes.LIST, list),
  takeLatest(ReportRequestTypes.LOAD, load),
  takeLatest(ReportRequestTypes.LIST_REPORT_TYPES, listReportTypes),
  takeLatest(ReportRequestTypes.CREATE_OR_UPDATE, createOrUpdate),
  takeLatest(ReportRequestTypes.DOWNLOAD_FILE, downloadFile),
]);
