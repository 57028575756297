import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

const Square = styled.div`
  left: 0px;
  position: absolute;
  background: ${props => props.color};
  width: ${props => props.width};
  height: 100%;
`;

function CellStatus({ color, title, width = 3 }) {
  return (
    <Container title={title}>
      <Square color={color} width={`${width}px`} />
    </Container>
  );
}

CellStatus.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  width: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

export default CellStatus;
