import React, { useState } from 'react'
import _ from 'lodash'
import DataTable from '~/components/datatable'
import { formats } from 'helper'
import CellBank from '~/components/datatable/cell-bank'
import { isValidCnpj } from '~/validator'
import TextWithHint from '~/components/text-with-hint'

const billColumns = [
    {
      name: 'Nosso número',
      selector: 'ourNumber',
      cell: (row) => <TextWithHint text={_.get(row, 'ourNumber') || ''} />
    },
    {
      name: 'Vencimento',
      width: '110px',
      selector: 'dueDate',
      cell: (row) => <TextWithHint  text={formats.dateTimeZone(_.get(row, 'dueDate'), 'dd/MM/yyyy')}/>
    },
    {
      name: 'Valor (R$)',
      selector: 'value',
      width: '110px',
      right: true,
      cell: (row) => <TextWithHint text={formats.decimal(_.get(row, 'value'))} />
    },
    {
      name: 'Ocorrência',
      width: '62px',
      selector: 'occurrence',
      center: true,
      cell: (row) => <TextWithHint text={_.get(row, 'occurrence')} />
    },
    {
      name: 'Pago em',
      width: '110px',
      selector: 'payDate',
      right: true,
      cell: (row) => <TextWithHint text={formats.dateTimeZone(_.get(row, 'payDate'), 'dd/MM/yyyy')} />
    },
    {
      name: 'Pago (R$)',
      selector: 'payAmount',
      right: true,
      cell: (row) => <TextWithHint text={formats.decimal(_.get(row, 'payAmount'))} />
    },
    {
      name: 'Multa (R$)',
      selector: 'fine',
      right: true,
      cell: (row) => <TextWithHint text={formats.decimal(_.get(row, 'fine'))} />
    },
    {
      name: 'Juros (R$)',
      selector: 'interest',
      right: true,
      cell: (row) => <TextWithHint text={formats.decimal(_.get(row, 'interest'))} />
    },
  ],
  paymentColumns = [
    {
      name: 'Vencimento',
      width: '110px',
      selector: 'dueDate',
      cell: (row) => <TextWithHint text={formats.dateTimeZone(_.get(row, 'dueDate'), 'dd/MM/yyyy')} />
    },
    {
      name: 'Valor (R$)',
      selector: 'value',
      width: '110px',
      right: true,
      cell: (row) => <TextWithHint text={formats.decimal(_.get(row, 'value'))} />
    },
    {
      name: 'Ocorrência',
      width: '62px',
      selector: 'occurrence',
      center: true,
      cell: (row) => <TextWithHint text={_.get(row, 'occurrence')} />
    },
    {
      name: 'Pago em',
      width: '110px',
      selector: 'payDate',
      right: true,
      cell: (row) => <TextWithHint text={formats.dateTimeZone(_.get(row, 'payDate'), 'dd/MM/yyyy')} />
    },
    {
      name: 'Pago (R$)',
      selector: 'payAmount',
      right: true,
      cell: (row) => <TextWithHint text={formats.decimal(_.get(row, 'payAmount'))} />
    },
    {
      name: 'Multa (R$)',
      selector: 'fine',
      right: true,
      cell: (row) => <TextWithHint text={formats.decimal(_.get(row, 'fine'))} />
    },
    {
      name: 'Juros (R$)',
      selector: 'interest',
      right: true,
      cell: (row) => <TextWithHint text={formats.decimal(_.get(row, 'interest'))} />
    },
  ],
  ddaColumns = [
    {
      name: 'Data Emissão',
      width: '130px',
      selector: 'docDate',
      cell: (row) => <TextWithHint text={formats.dateTimeZone(_.get(row, 'docDate'), 'dd/MM/yyyy')} />
    },
    {
      name: 'Vencimento',
      width: '120px',
      selector: 'dueDate',
      cell: (row) =>
        <TextWithHint text={formats.dateTimeZone(_.get(row, 'dueDate'), 'dd/MM/yyyy')} />
    },
    {
      name: 'Data limite',
      width: '120px',
      selector: 'limitDate',
      cell: (row) =>
        <TextWithHint text={formats.dateTimeZone(_.get(row, 'limitDate'), 'dd/MM/yyyy')} />
    },
    {
      name: 'Valor (R$)',
      selector: 'value',
      width: '120px',
      right: true,
      cell: (row) => <TextWithHint text={formats.decimal(_.get(row, 'value'))} />
    },
    {
      name: 'Beneficiário',
      selector: 'beneficiaryIdentity',
      hide: 'md',
      wrap: true,
      cell: (row) => {
        let name = _.get(row, 'beneficiaryName'),
          identity = _.get(row, 'beneficiaryIdentity')
        return <TextWithHint text={identity && name ? `${formats.cnpj_cpf(identity)} - ${name}` : ''} />
      },
    },
  ],
  creditReceivedColumns = [
    {
      name: 'Data Lançamento',
      width: '130px',
      selector: 'docDate',
      cell: (row) => <TextWithHint text={formats.dateTimeZone(_.get(row, 'docDate'), 'dd/MM/yyyy')} />
    },
    {
      name: 'Nº controle',
      width: '120px',
      selector: 'controlNumber',
      cell: (row) => <TextWithHint text={_.get(row, 'controlNumber', '')} />
    },
    {
      name: 'Valor (R$)',
      selector: 'value',
      width: '120px',
      right: true,
      cell: (row) => <TextWithHint text={formats.decimal(_.get(row, 'value'))} />
    },
    {
      name: 'Remetente',
      selector: 'senderIdentity',
      hide: 'md',
      wrap: true,
      cell: (row) => {
        let name = _.get(row, 'senderName'),
          identity = isValidCnpj(_.get(row, 'senderIdentity')) ? _.get(row, 'senderIdentity') : String(_.get(row, 'senderIdentity')).length > 3 ? String(_.get(row, 'senderIdentity')).substring(3) : _.get(row, 'senderIdentity'),
          formatedIdentiy = formats.cnpj_cpf(identity) || identity
        return <TextWithHint text={[formatedIdentiy, name].join(' - ')}/>
      },
    },
    {
      name: 'Banco origem',
      selector: 'bankAccount',
      width: '80px',
      center: true,
      cell: (row) => {
        return <CellBank bankCode={_.get(row, 'sourceBankCode')} />
      },
    },
    {
      name: 'Erro',
      selector: 'error',
      cell: (row) => {
        const error = _.get(row, 'error') || ''
        return <TextWithHint text={messageErrorsCreditReceived[error] || ''} />
      },
    },
  ]

const messageErrorsCreditReceived = {
  CREDIT_RECEIVED_DUPLICATE: 'Duplicidade na importação',
  CREDIT_RECEIVED_OCCURRENCE: 'Ocorrência do arquivo retorno não é de liquidação',
  BANK_CONTRACT_NOT_FOUND: 'Contrato bancário não encontrado',
}

const getRows = (array = [], offset, limit) => {
  return array.slice(offset, offset + limit)
}

export const TabNotProcesseds = ({ type, notProcesseds, openDocument }) => {
  const count = _.size(notProcesseds)

  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(20)

  const onChangePage = (offset, limit) => {
    setOffset(offset)
    setLimit(limit)
  }

  let keyField = 'billData.ourNumber',
    columns = billColumns

  if (['dda', 'creditReceived'].includes(type)) {
    keyField = 'id'
    columns = type === 'dda' ? ddaColumns : creditReceivedColumns
  } else if (type === 'payment') {
    keyField = 'key'
    columns = paymentColumns
  }

  return (
    <DataTable
      emptyText="Nenhum documento não processado"
      pageSize={limit}
      // keyField={type === 'dda' ? 'id' : (type === 'payment' ? 'key' : 'billData.ourNumber')}
      keyField={keyField}
      onChangePage={onChangePage}
      hideUpdateButton={true}
      onRowClicked={openDocument(type)}
      data={{
        rows: getRows(notProcesseds, offset, limit),
        offset,
        count,
        limit: limit,
      }}
      // columns={type === 'dda' ? ddaColumns : (type === 'payment' ?  paymentColumns : billColumns)}
      columns={columns}
    />
  )
}
