import React, { useLayoutEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  MdCheck,
  MdClear,
  MdAdd,
  MdErrorOutline,
  MdCancel,
} from 'react-icons/md'
import { IoWarningOutline } from 'react-icons/io5'
import { GrDocumentPdf, GrCertificate } from 'react-icons/gr'
import { BiChevronsLeft, BiChevronsRight } from 'react-icons/bi'
import Crud from '~/components/crud'
import {
  Container,
  CompanyContainer,
  SecondarySwitch,
  LeftContainer,
  Dashboard,
  DashboardGroup,
  CellIcon,
} from '~/pages/bills/styles'
import FormFilter from '~/pages/bills/filter'
import { formatBankAccountGrid } from '~/pages/bank-accounts/form'
import { Creators as BillActions } from '~/store/modules/bill'
import { Creators as HeaderActions } from '~/store/modules/header'
import _ from 'lodash'
import { formats, getColorByContrast } from '~/helper'
import useWindowSize from '~/hooks/use-window-size'
import FloatingButton from '~/components/floatbutton'
import confirm from '~/components/confirm'
import CellStatus from '~/components/datatable/cell-status'
import CellBank from '~/components/datatable/cell-bank'
import { BASE_URL } from '~/constants'
import Component from '~/components/floatbutton/group'
import useSearchParameters from '~/hooks/use-search-params'
import PDF from '~/components/pdf'
import SettleBatchModal from './modal-settle-batch'
import useModal from '~/hooks/use-modal'

export const statusLabels = {
  pending: 'Pré-cadastro',
  open: 'Aberta',
  inShipping: 'Em remessa',
  sent: 'Enviada',
  registered: 'Registrada',
  paid: 'Liquidada',
  canceled: 'Cancelada',
  rejected: 'Rejeitada',
}

export const statusColors = {
  pending: '#efd600',
  open: '#40d1ff',
  inShipping: '#9033ff',
  sent: '#4c81f9',
  registered: '#6264a7',
  paid: '#77dd76',
  canceled: '#BA1F33',
  rejected: '#e067e0',
}

const columns = [
  {
    name: ' ',
    selector: 'status',
    width: '12px',
    cell: (row) => {
      let status =
        row.registered && row.status === 'open' ? 'registered' : row.status
      return (
        <CellStatus title={statusLabels[status]} color={statusColors[status]} />
      )
    },
  },
  {
    name: 'Emissor / Pagador',
    selector: 'company',
    wrap: true,
    cell: (row) => {
      const company = _.get(row, 'company') || {},
        hasError = !_.isEmpty(_.get(row, 'generationMessages.errors')),
        hasWarning = !_.isEmpty(_.get(row, 'generationMessages.warnings')),
        hasPdf = _.get(row, 'billData.generated'),
        withProtest = _.get(row, 'withProtest'),
        bp = _.get(row, 'businessPartner') || {},
        hasIcon = hasError || hasWarning || hasPdf || withProtest
      return (
        <CompanyContainer hasIcon={hasIcon}>
          {hasIcon && (
            <div className="company-icons">
              {hasError && (
                <CellIcon size={20} color="#ff0000">
                  <MdErrorOutline />
                </CellIcon>
              )}
              {hasWarning && (
                <CellIcon size={20} color="#efd600">
                  <IoWarningOutline />
                </CellIcon>
              )}
              {hasPdf && (
                <CellIcon size={18} color="#29A8E0">
                  <GrDocumentPdf />
                </CellIcon>
              )}
              {withProtest && (
                <CellIcon size={18} color="#444">
                  <GrCertificate />
                </CellIcon>
              )}
            </div>
          )}
          <div className="company-text">
            <span>
              {formats.cnpj_cpf(_.get(company, 'identity', '')) +
                ' ' +
                (_.get(company, 'name', '') || _.get(company, 'tradeName', ''))}
            </span>
            <span>
              {formats.cnpj_cpf(_.get(bp, 'identity', '')) +
                ' ' +
                (_.get(bp, 'name', '') || _.get(bp, 'tradeName', ''))}
            </span>
          </div>
        </CompanyContainer>
      )
    },
  },
  {
    name: 'Tipo',
    selector: 'bankContract',
    width: '110px',
    center: true,
    hide: 'lg',
    format: (row) => _.get(row, 'bankContract.billType.name') || '',
  },
  {
    name: 'Documento',
    selector: 'documentNumber',
    width: '150px',
    format: (row) => _.get(row, 'documentNumber'),
  },
  {
    name: 'Vencimento',
    selector: 'dueDate',
    center: true,
    width: '130px',
    format: (row) => formats.dateTimeZone(_.get(row, 'dueDate'), 'dd/MM/yyyy'),
  },
  {
    name: 'Pagamento',
    selector: 'payDate',
    width: '130px',
    center: true,
    hide: 'lg',
    format: (row) =>
      formats.dateTimeZone(_.get(row, 'payDate'), 'dd/MM/yyyy') || '--',
  },
  {
    name: 'Valor',
    selector: 'value',
    width: '130px',
    right: true,
    compact: false,
    format: (row) => {
      return `${formats.currency(row.value, 'pt-BR', 'BRL')}`
    },
  },
  {
    name: 'Agência / Conta',
    selector: 'bankContract',
    width: '140px',
    left: true,
    cell: (row) => (
      <CellBank bankAccount={_.get(row, 'bankContract.bankAccount')} />
    ),
  },
  {
    name: ' ',
    selector: 'id',
    width: '5px',
    center: true,
    cell: (row) => <span>&nbsp;</span>,
  },
]

const customStyles = {
  rows: {
    denseStyle: {
      minHeight: '58px',
    },
  },
}

const getColorByBgColor = (bgColor) => {
  return getColorByContrast(bgColor, '#fff', '#000')
}

const getElem = ({ label, value }) => {
  return () => (
    <span>
      <strong>{label}</strong>&nbsp;{value}
      <i style={{ padding: '0 5px' }}></i>
    </span>
  )
}

const getDivider = (array, breakLine) => {
  if (breakLine) {
    array.push(() => <span className="break"></span>)
  } else if (_.size(array) > 0) {
    array.push(() => <span className="spacing"></span>)
  }
}

const getDateFilterText = (startDate, endDate) => {
  if (startDate && endDate) {
    return `${formats.date(startDate)} até ${formats.date(endDate)}`
  } else if (startDate && !endDate) {
    return `a partir de ${formats.date(startDate)}`
  } else if (!startDate && endDate) {
    return `até ${formats.date(endDate)}`
  }
  return ''
}

const getSubtitle = (filter) => {
  let elements = [],
    ourNumber = _.get(filter, 'ourNumber'),
    documentNumber = _.get(filter, 'documentNumber'),
    businessPartner = _.get(filter, 'businessPartner'),
    company = _.get(filter, 'company'),
    bankContract = _.get(filter, 'bankContract')

  if (!_.isEmpty(ourNumber)) {
    elements.push(
      getElem({
        label: 'Nosso número',
        value: ourNumber || '',
      }),
    )
  }
  if (!_.isEmpty(documentNumber)) {
    elements.push(
      getElem({
        label: 'Nº documento',
        value: documentNumber || '',
      }),
    )
  }
  if (!_.isEmpty(bankContract)) {
    getDivider(elements)
    elements.push(
      getElem({
        label: 'Contrato',
        value: formatBankAccountGrid(bankContract.bankAccount),
      }),
    )
  }

  if (!_.isEmpty(company)) {
    getDivider(elements)
    elements.push(
      getElem({
        label: 'Empresa',
        value: `${formats.cnpj_cpf(company.identity)} ${company.name}`,
      }),
    )
  }
  if (!_.isEmpty(businessPartner)) {
    getDivider(elements)
    elements.push(
      getElem({
        label: 'Pagador',
        value: `${formats.cnpj_cpf(businessPartner.identity)} ${
          businessPartner.name
        }`,
      }),
    )
  }

  if (filter.dueDateStart || filter.dueDateEnd) {
    getDivider(elements)
    elements.push(
      getElem({
        label: 'Data de vencimento',
        value: getDateFilterText(filter.dueDateStart, filter.dueDateEnd),
      }),
    )
  }
  if (filter.createdAtStart || filter.createdAtEnd) {
    getDivider(elements)
    elements.push(
      getElem({
        label: 'Data de criação',
        value: getDateFilterText(filter.createdAtStart, filter.createdAtEnd),
      }),
    )
  }
  if (filter.payDateStart || filter.payDateEnd) {
    getDivider(elements)
    elements.push(
      getElem({
        label: 'Data de pagamento',
        value: getDateFilterText(filter.payDateStart, filter.payDateEnd),
      }),
    )
  }

  if (_.isEmpty(elements)) {
    return null
  }

  return _.map(elements, (Element, index) => <Element key={index} />)
}

export default function Bills({ history, match, acls }) {
  const dispatch = useDispatch()
  const state = useSelector((state) => state.bill)
  const headerState = useSelector((state) => state.header)
  const [showSecondaryCards, setShowSecondaryCards] = useState(false)
  const canWrite = acls.includes('W')
  const canCreate = acls.includes('M')
  const showFilter = _.get(headerState, 'filter.visible')
  const filter = _.get(headerState, 'filter.data')
  const dashboard = _.get(state, 'data.summary') || {}
  const statusSelected = _.get(filter, 'status') || 'open'
  const selection = state.selecteds || []
  const hasSelection = !_.isEmpty(selection)
  const windowSize = useWindowSize()
  const cardCount = showSecondaryCards ? 8 : 6
  const cardOffset = showSecondaryCards ? 180 : 160
  const [openForm, setOpenForm] = useState(!!_.get(match, 'params.id'))
  const cardSize = Math.max(
    155,
    Math.floor(((windowSize.width || 1000) - cardOffset) / cardCount),
  )
  const statusGroup1 = [
    'pending',
    'open',
    'inShipping',
    'sent',
    'registered',
    'paid',
  ]
  const statusGroup2 = ['rejected', 'canceled']
  const [isOpenSettleBatchModal, toggleSettleBatchModal] = useModal()

  const {
    location,
    routeParams,
    getAllParams,
    toStringify,
  } = useSearchParameters()

  const canGeneratePdf = _.filter(selection, (item) => {
    const status =
      _.get(item, 'registered') && _.get(item, 'status') === 'open'
        ? 'registered'
        : _.get(item, 'status')
    return (
      !/^(pending|open|rejected|canceled)$/.test(status) &&
      [10].includes(_.get(item, 'bankContract.billType.code')) &&
      _.get(item, 'id') &&
      _.get(item, 'billData.generated')
    )
  })

  const canSendEmail = _.filter(selection, (item) => {
    const status =
      _.get(item, 'registered') && _.get(item, 'status') === 'open'
        ? 'registered'
        : _.get(item, 'status')
    const billType = _.get(item, 'bankContract.billType.code')
    const canGeneratePdf =
      !/^(pending|open|rejected|canceled)$/.test(status) &&
      [10].includes(billType) &&
      _.get(item, 'id') &&
      _.get(item, 'billData.generated')

    return (
      ((/^(registered|inShipping|sent)$/.test(status) && canGeneratePdf) ||
        /^(40)$/.test(billType)) &&
      (_.get(item, 'businessPartner.email') ||
        !_.isEmpty(
          _.map(_.get(item, 'businessPartner.extras.contacts') || [], 'email'),
        ))
    )
  })

  const canSettle = _.filter(selection, (item) => {
    const status =
      _.get(item, 'registered') && _.get(item, 'status') === 'open'
        ? 'registered'
        : _.get(item, 'status')

    return (
      /^(open)$/.test(status) &&
      [40].includes(_.get(item, 'bankContract.billType.code')) &&
      _.get(item, 'id')
    )
  })

  const canCancel = _.filter(selection, (item) => {
    const status = _.get(item, 'status'),
      hasErpData = !!_.get(item, 'erpExtra.DatabaseId'),
      allowOnlyInstructionsByERP = !(_.get(item, 'billData.allowOnlyInstructionsByERP') && hasErpData);

    return (/^(pending|open)$/.test(status) && allowOnlyInstructionsByERP && _.get(item, 'id'))
  })

  useLayoutEffect(() => {
    const { status } = getAllParams()
    const config = {
      loading: state.loading,
      useFilter: true,
      subtitle: getSubtitle(filter || {}),
      filter: {
        ...headerState.filter,
        visible: false,
      },
    }

    dispatch(HeaderActions.configure(config))

    if (!status) {
      handleFilter({
        status: statusSelected,
      })
    }
    // eslint-disable-next-line
  }, [])

  useLayoutEffect(() => {
    const { id, offset, limit = 20, ...filter } = getAllParams()

    const listMethod =
      _.isEmpty(id) || _.size(_.get(state, 'data.rows')) === 0
        ? BillActions.list
        : null

    if (id) {
      dispatch(BillActions.load({ id }))
    }

    dispatch(
      HeaderActions.callFilter(
        { ...filter, offset: _.toInteger(offset), limit: _.toInteger(limit), stateSummary: _.get(state, 'data.summary') },
        listMethod,
      ),
    )
    // eslint-disable-next-line
  }, [location, routeParams])

  function handleLoad(data) {
    const filter = getAllParams(),
      historySearch = _.omit(filter, [
        'id',
        'company',
        'bankAccount',
        'createdAtStart',
        'createdAtEnd',
        'dueDateStart',
        'dueDateEnd',
        'payDateStart',
        'payDateEnd',
        'businessPartner',
        'bankContract',
        'group',
        'stateSummary',
      ]),
      group = _.get(filter, 'group.value') || _.get(filter, 'group')

    if (!_.isEmpty(group)) {
      historySearch.group = group
    }

    history.push({
      pathname: `/bills/${_.get(data, 'id', '')}`,
      search: `?${toStringify(historySearch)}`,
      state: filter,
    })
    setOpenForm(true)
  }

  function handlePageChange(offset, limit) {
    const filter = getAllParams(),
      historySearch = {
        ..._.omit(filter, [
          'id',
          'company',
          'bankAccount',
          'createdAtStart',
          'createdAtEnd',
          'dueDateStart',
          'dueDateEnd',
          'payDateStart',
          'payDateEnd',
          'businessPartner',
          'bankContract',
          'group',
          'stateSummary',
        ]),
        offset,
        limit,
      },
      group = _.get(filter, 'group.value') || _.get(filter, 'group')

    if (!_.isEmpty(group)) {
      historySearch.group = group
    }

    history.push({
      pathname: '/bills',
      search: `?${toStringify(historySearch)}`,
      state: { ..._.omit(filter, 'id'), offset, limit },
    })
    setOpenForm(false)
  }

  function handleHideFilter() {
    dispatch(HeaderActions.hideFilter())
    setOpenForm(false)
  }

  function handleFilter(filter) {
    const historySearch = {
        ..._.omit(filter, [
          'company',
          'bankAccount',
          'createdAtStart',
          'createdAtEnd',
          'dueDateStart',
          'dueDateEnd',
          'payDateStart',
          'payDateEnd',
          'businessPartner',
          'bankContract',
          'group',
          'stateSummary',
        ]),
        offset: 0,
      },
      group = _.get(filter, 'group.value') || _.get(filter, 'group')

    if (!_.isEmpty(group)) {
      historySearch.group = group
    }

    handleHideFilter()
    history.push({
      pathname: `/bills`,
      search: `?${toStringify(historySearch)}`,
      state: filter,
    })
  }

  function handleListBankContracts(term, callback) {
    dispatch(BillActions.listBankContracts({ term }, callback))
  }

  function handleListBusinessPartners(term, callback) {
    dispatch(BillActions.listBusinessPartners(term, callback))
  }

  function handleListCompanies(term, callback) {
    dispatch(BillActions.listCompanies({ term }, callback))
  }

  function handListBankAccountsGroups(term, callback) {
    dispatch(BillActions.listBankAccountsGroups({ term }, callback))
  }

  function changeSelectBills(selecteds) {
    if (/^(sent|registered)$/.test(statusSelected)) {
      let filtered = _.filter(selecteds, (row) => {
        return (
          _.get(row, 'billData.generated') &&
          [10, 11].includes(_.get(row, 'bankContract.billType.code'))
        )
      })
      if (
        _.map(_.sortBy(selection, ['id']), 'id').join(',') !==
        _.map(_.sortBy(filtered, ['id']), 'id').join(',')
      ) {
        dispatch(BillActions.changeSelectBills(filtered))
      }
    } else {
      if (
        _.map(_.sortBy(selection, ['id']), 'id').join(',') !==
        _.map(_.sortBy(selecteds, ['id']), 'id').join(',')
      ) {
        dispatch(BillActions.changeSelectBills(selecteds))
      }
    }
  }

  function filterStatus(status) {
    handleFilter({ ...filter, status })
  }

  async function sendEmails() {
    let size = _.size(canSendEmail),
      plural =
        size === 1
          ? 'o pagador da fatura selecionado'
          : `os pagadores das ${formats.number(size)} faturas selecionadas`,
      result = await confirm.show({
        width: 450,
        title: 'Atenção',
        text: `Deseja enviar e-mail para ${plural}?`,
      })

    if (result) {
      let params = { ids: _.map(canSendEmail, 'id'), isBatch: true }
      dispatch(BillActions.sendEmail(params))
    }
  }

  async function generatePdf() {
    const ids = _.map(canGeneratePdf, 'id')

    if (!_.isEmpty(ids)) {
      await PDF.show({
        title: `Boletos`,
        url: `${BASE_URL}/bills/load-pdf/${ids.join(',')}/boleto.pdf`,
      })
    }
  }

  async function cancelBatch(selecteds) {
    let size = _.size(selecteds),
      result = await confirm.show({
        width: 450,
        title: 'Atenção',
        text:
          size === 1
            ? 'Deseja cancelar a fatura selecionada?'
            : `Deseja cancelar as ${formats.number(
                size,
              )} faturas selecionadas?`,
      })

    if (result) {
      let params = { ids: _.map(selecteds, 'id') }
      dispatch(BillActions.cancelBatch(params, filter))
    }
  }

  async function settleBatch({ payDate }, actions) {
    const ids = _.chain(canSettle)
      .filter((row) => {
        const documentDate = new Date(row.documentDate)
        payDate.setHours(0, 0, 0, 0)
        documentDate.setHours(0, 0, 0, 0)
        return payDate >= documentDate
      })
      .map('id')
      .value()

    const size = _.size(ids),
      result = await confirm.show({
        width: 450,
        title: 'Atenção',
        text:
          size === 1
            ? 'Deseja efetuar a baixa manual da fatura selecionada?'
            : `Deseja efetuar a baixa manual das ${formats.number(
                size,
              )} faturas selecionadas?`,
      })

    if (result) {
      actions.closeModal = toggleSettleBatchModal
      dispatch(BillActions.settleBatch({ ids, payDate }, filter, actions))
    }
  }

  const renderLeftComponent = ({ Table }) => {
    return (
      <LeftContainer>
        <Dashboard cardSize={cardSize}>
          <SecondarySwitch
            title="Ver mais situações"
            onClick={() => setShowSecondaryCards(!showSecondaryCards)}
          >
            {showSecondaryCards ? <BiChevronsLeft /> : <BiChevronsRight />}
          </SecondarySwitch>

          {showSecondaryCards && (
            <div className="section">
              {_.map(statusGroup2, (sts, index) => (
                <DashboardGroup
                  key={index}
                  selected={statusSelected === sts}
                  getCol={getColorByBgColor}
                  title={formats.currency(_.get(dashboard, `${sts}.sum`))}
                  color={statusColors[sts]}
                  onClick={() => filterStatus(sts)}
                >
                  <div className="sum">
                    <span>$ </span>
                    {formats.numberScale(_.get(dashboard, `${sts}.sum`))}
                  </div>
                  <div className="name">
                    <div className="square"></div>
                    {statusLabels[sts]}
                  </div>
                  <div className="count">
                    {formats.number(_.get(dashboard, `${sts}.count`))}
                  </div>
                </DashboardGroup>
              ))}
            </div>
          )}

          <div className="section">
            {_.map(statusGroup1, (sts, index) => (
              <DashboardGroup
                key={index}
                selected={statusSelected === sts}
                getCol={getColorByBgColor}
                color={statusColors[sts]}
                title={formats.currency(_.get(dashboard, `${sts}.sum`))}
                onClick={() => filterStatus(sts)}
              >
                <div className="sum">
                  <span>$ </span>
                  {formats.numberScale(_.get(dashboard, `${sts}.sum`))}
                </div>
                <div className="name">
                  <div className="square"></div>
                  {statusLabels[sts]}
                </div>
                <div className="count">
                  {formats.number(_.get(dashboard, `${sts}.count`))}
                </div>
              </DashboardGroup>
            ))}
          </div>
        </Dashboard>

        <div className="crud-table">{Table}</div>
      </LeftContainer>
    )
  }

  let actions = []

  if (/^(registered|inShipping|sent|open)$/.test(statusSelected)) {
    if (canWrite && _.size(canSendEmail) > 0) {
      actions.push({
        icon: 'fa fa-envelope-o',
        label: 'Enviar e-mail para parceiro(s) de negócio(s)',
        action: () => sendEmails(),
      })
    }

    if (canWrite && _.size(canGeneratePdf) > 0) {
      actions.push({
        icon: 'fa fa-print',
        label: 'Imprimir faturas em lote',
        action: () => generatePdf(_.get(state, 'data.accountId')),
      })
    }
  }

  if (/^(pending|open)$/.test(statusSelected)) {
    if (_.size(canSettle) > 0) {
      actions.push({
        icon: 'fa fa-money',
        label: 'Baixa manual em lote',
        action: toggleSettleBatchModal,
      })
    }

    if (_.size(canCancel) > 0) {
      actions.push({
        icon: 'fa fa-close',
        label: 'Cancelar faturas em lote',
        action: () => cancelBatch(canCancel),
      })
    }
  }

  return (
    <Container>
      {isOpenSettleBatchModal && (
        <SettleBatchModal
          isOpen={isOpenSettleBatchModal}
          toggleModal={toggleSettleBatchModal}
          settleList={canSettle}
          handleOnSubmit={settleBatch}
        />
      )}

      {false && !hasSelection && (
        <FloatingButton
          icon={MdAdd}
          title="Criar novo"
          onClick={() => handleLoad({})}
        />
      )}

      {!showFilter && hasSelection && (
        <>
          {_.size(actions) > 0 && (
            <Component anchorIcon="fa fa-bars" actions={actions}></Component>
          )}
        </>
      )}

      {!showFilter && canCreate && !hasSelection && (
        <FloatingButton
          icon={MdAdd}
          title="Nova fatura"
          onClick={() => history.push('/bills/new')}
        />
      )}

      {!showFilter &&
        hasSelection &&
        (_.size(canCancel) > 0) &&
        /^(pending|open)$/.test(statusSelected) &&   (
          <FloatingButton
            icon={MdCancel}
            title="Cancelar faturas em lote"
            onClick={() => cancelBatch(canCancel)}
          />
        )}

      <Crud
        useOpenForm={true}
        openForm={openForm || showFilter}
        data={state.data}
        columns={columns}
        onRowClicked={handleLoad}
        onChangePage={handlePageChange}
        onCloseFilter={handleHideFilter}
        tableLoading={state.loading}
        hideAdd={true}
        emptyText="Nenhuma fatura encontrada"
        formTitle={() => 'Filtro'}
        renderLeftComponent={renderLeftComponent}
        formOptions={{
          initialValues: headerState.filter.data,
          onSubmit: handleFilter,
        }}
        renderForm={(args) => (
          <FormFilter
            {...args}
            onListBankContracts={handleListBankContracts}
            onListBusinessPartners={handleListBusinessPartners}
            onListCompanies={handleListCompanies}
            onListBankAccountsGroups={handListBankAccountsGroups}
          />
        )}
        actions={[
          {
            label: 'Limpar Filtro',
            icon: MdClear,
            action: () => handleFilter({ status: statusSelected }),
          },
          {
            label: 'Aplicar Filtro',
            icon: MdCheck,
            isSubmit: true,
            action: handleFilter,
          },
        ]}
        dataTableOptions={{
          customStyles,
          ignoreRowClicked: true,
          selectableRowsHighlight: false,
          selectableRows:
            canWrite && !['inShipping', 'paid'].includes(statusSelected),
          selectableRowDisabled: (row) => {
            return row.editLocked
          },
          selectableRowSelected: (row) => {
            return _.find(selection, { id: row.id })
          },
          onSelectedRowsChange: ({
            allSelected,
            selectedCount,
            selectedRows,
          }) => {
            if (canWrite) {
              changeSelectBills(selectedRows)
            }
          },
        }}
      />
    </Container>
  )
}
