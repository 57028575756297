import { all, put, call, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import _ from 'lodash'
import api from '~/services/api'
import { getError } from '~/helper'
import { format } from 'date-fns'
import {
  Creators as ResponsesActions,
  Types as ResponsesTypes,
} from '~/store/modules/response'

export function showError(error) {
  const msg = getError(error)
  toast.error(msg)
}

export function* list({ params }) {
  try {
    const response = yield call(api.post, 'responses/list', params)
    yield put(ResponsesActions.listSuccess(response.data))
  } catch (error) {
    showError(error)
  }
}

export function* load({ data }) {
  try {
    let id = _.get(data, 'id'),
      model = {}

    if (id && id !== 'new') {
      const response = yield call(api.get, 'responses/load', { params: { id } })
      model = response.data
    }

    yield put(ResponsesActions.loadSuccess(model))
  } catch (error) {
    showError(error)
  }
}

export function* create({ data, actions }) {
  try {
    let formData = new FormData()
    formData.append('data', JSON.stringify(_.omit(data, 'file')))
    formData.append('file', _.get(data, 'file[0]'))
    yield call(api.post, `responses/create`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    toast.success(`Arquivo de Retorno processado com sucesso!`)
    actions.closeForm && actions.closeForm()
    actions.reloadList && actions.reloadList()
  } catch (error) {
    if (actions) {
      actions.setSubmitting(false)
    }
    showError(error)
  }
  yield put(ResponsesActions.formActionDone())
}

export function* listBankContracts({ movementType, term, callback }) {
  try {
    const response = yield call(api.get, 'responses/list-bank-contracts', {
      params: { movementType, term },
    })
    yield call(callback, response.data)
  } catch (error) {
    console.log(error)
  }
}

export function* listBankAccounts({ term, callback }) {
  try {
    const response = yield call(api.get, 'responses/list-bank-accounts', {
      params: { term },
    })
    yield call(callback, response.data)
  } catch (error) {}
}

export function* listCompanies({ term, callback }) {
  try {
    const response = yield call(api.get, 'responses/list-companies', {
      params: { term },
    })
    yield call(callback, response.data)
  } catch (error) {}
}

export function* listBanks({ term, callback }) {
  try {
    const response = yield call(api.get, 'responses/list-banks', {
      params: { term },
    })
    yield call(callback, response.data)
  } catch (error) {}
}

export function* downloadFile({ data }) {
  try {
    const params = { id: data.id, filename: data.filename }
    const response = yield call(api.get, 'responses/download-file', {
      params,
      responseType: 'blob',
    })

    api.download(response, data.filename)
  } catch (error) {
    showError(error)
  }
}

export function* reprocessConciliation({ data, actions }) {
  try {
    let response = yield call(
      api.post,
      'responses/reprocess-conciliation',
      data,
    )

    if (_.get(response, 'data.processed')) {
      toast.success(`Reprocessamento iniciado com sucesso`)
      actions.closeForm && actions.closeForm()
      actions.reloadList && actions.reloadList()
    } else {
      toast.error('Reprocessamento não realizado, tente mais tarde!')
    }
  } catch (error) {
    showError(error)
  }
}

export function* generateXlsx({ data }) {
  try {
    const params = { id: data },
      response = yield call(api.get, 'responses/xlsx', {
        params,
        responseType: 'blob',
      }),
      filename = `retorno-${format(new Date(), 'ddMMyyyy-HHmm')}.xlsx`
    api.download(response, filename)
  } catch (error) {
    showError(error)
  }
  yield put(ResponsesActions.formActionDone())
}

export default all([
  takeLatest(ResponsesTypes.CREATE, create),
  takeLatest(ResponsesTypes.LOAD, load),
  takeLatest(ResponsesTypes.LIST, list),
  takeLatest(ResponsesTypes.LIST_BANK_CONTRACTS, listBankContracts),
  takeLatest(ResponsesTypes.LIST_BANK_ACCOUNTS, listBankAccounts),
  takeLatest(ResponsesTypes.DOWNLOAD_FILE, downloadFile),
  takeLatest(ResponsesTypes.REPROCESS_CONCILIATION, reprocessConciliation),
  takeLatest(ResponsesTypes.LIST_COMPANIES, listCompanies),
  takeLatest(ResponsesTypes.LIST_BANKS, listBanks),
  takeLatest(ResponsesTypes.GENERATE_XLSX, generateXlsx),
])
