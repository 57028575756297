import React from 'react';
import b001 from '~/assets/banks/001.svg';
import b004 from '~/assets/banks/004.svg';
import b033 from '~/assets/banks/033.svg';
import b041 from '~/assets/banks/041.svg';
import b077 from '~/assets/banks/077.svg';
import b099 from '~/assets/banks/099.svg';
import b102 from '~/assets/banks/102.svg';
import b104 from '~/assets/banks/104.svg';
import b133 from '~/assets/banks/133.svg';
import b121 from '~/assets/banks/121.svg';
import b208 from '~/assets/banks/208.svg';
import b212 from '~/assets/banks/212.svg';
import b218 from '~/assets/banks/218.svg';
import b237 from '~/assets/banks/237.svg';
import b246 from '~/assets/banks/246.svg';
import b260 from '~/assets/banks/260.svg';
import b280 from '~/assets/banks/280.svg';
import b318 from '~/assets/banks/318.svg';
import b329 from '~/assets/banks/329.svg';
import b332 from '~/assets/banks/332.svg';
import b335 from '~/assets/banks/335.svg';
import b336 from '~/assets/banks/336.svg';
import b341 from '~/assets/banks/341.svg';
import b389 from '~/assets/banks/389.svg';
import b399 from '~/assets/banks/399.svg';
import b403 from '~/assets/banks/403.svg';
import b422 from '~/assets/banks/422.svg';
import b533 from '~/assets/banks/533.svg';
import b600 from '~/assets/banks/600.svg';
import b633 from '~/assets/banks/633.svg';
import b637 from '~/assets/banks/637.svg';
import b643 from '~/assets/banks/643.svg';
import b655 from '~/assets/banks/655.svg';
import b707 from '~/assets/banks/707.svg';
import b745 from '~/assets/banks/745.svg';
import b748 from '~/assets/banks/748.svg';
import b752 from '~/assets/banks/752.svg';
import b756 from '~/assets/banks/756.svg';

export const icons = {
  b001,
  b004,
  b033,
  b041,
  b077,
  b099,
  b102,
  b104,
  b121,
  b133,
  b208,
  b212,
  b218,
  b237,
  b246,
  b260,
  b280,
  b318,
  b329,
  b332,
  b335,
  b336,
  b341,
  b389,
  b399,
  b403,
  b422,
  b533,
  b600,
  b633,
  b637,
  b643,
  b655,
  b707,
  b745,
  b748,
  b752,
  b756,
};

export const EmptyIcon = ({ code }) => {
  return (
    <svg width="260px" height="260px" viewBox="0 0 260 260" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <path d="M30,10 h200 a20,20 0 0 1 20,20 v200 a20,20 0 0 1 -20,20 h-200 a20,20 0 0 1 -20,-20 v-200 a20,20 0 0 1 20,-20 z" fill="#fff" stroke="#555" strokeWidth="3" />
      <text x="50%" y="145" fontSize="125" dominantBaseline="middle" fill="#777" textAnchor="middle">{code}</text>
    </svg>
  );
};

export const getBankLogo = (code) => {
  return (!code || code === '000') ? null : icons[`b${code}`];
};
