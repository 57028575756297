import React, { useState, useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { formats } from '~/helper';
import { primary, white, link } from '~/components/mixins/color';
import { MdFirstPage, MdLastPage, MdNavigateBefore, MdNavigateNext, MdRefresh } from 'react-icons/md';
import EmptyState from '~/components/empty-state';
import Loading from '~/components/loading';
import DataTableComponent, { createTheme } from 'react-data-table-component';
import { Formik } from 'formik';
import { Select } from '../form';

/*
CustomStyle
https://github.com/jbetancur/react-data-table-component/blob/master/src/DataTable/styles.js

Theme:
https://github.com/jbetancur/react-data-table-component/blob/master/src/DataTable/themes.js
*/

createTheme('docpay', {
  selected: {
    text: white.hex(),
    default: primary.string()
  },
  highlightOnHover: {
    text: link.hex(),
    default: primary.fade(0.94).string()
  },
  divider: {
    default: '#fafafa'
  }
});

const Container = styled.div`
  display: grid;
  width: calc(100%);
  height: calc(100%);

  > div {
    padding: ${props => props.padding || '10px'};
    background: #fff;
    border-radius: 2px;
    height: calc(100%);
    overflow: hidden;
  }
  > div > div {
    height: calc(100% - ${props => (props.noPagination ? '0px' : '46px')});
    display: block;
  }
  .rdt_Pagination {
    justify-content: center;

    span {
      margin: 0;
    }
  }
  .rdt_TableBody {
    overflow-y: auto;
    overflow-x: hidden;
  }
  .rdt_TableRow {
    ${props =>
      props.rowMinHeight &&
      css`
        min-height: ${props.rowMinHeight};
      `
    }
  }

  @media (max-width: 599px) {
    .rdt_Pagination {
      justify-content: flex-start;

      span {
        margin: 0 10px;
      }
    }
  }
`;

const PaginationContainer = styled.div`
  display: flex !important;
  height: 56px !important;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  background: #fff;

  span {
    font-size: 13px;
    color: ${link.hex()};
    margin: 0 10px 0 0 !important;
    user-select: none;
  }
`;

const IconButton = styled.button`
  width: 40px;
  height: 40px;
  padding: 8px;
  display: inline-flex;
  margin: 0;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 5px;
  font-size: 24px;
  color: ${link.hex()};
  background: transparent;

  &:hover:enabled,
  &:focus:enabled {
    background: rgba(0, 0, 0, 0.05);
  }
  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: default;
      user-select: none;
    `}

  .left-spacing {
    margin-left: 15px;
  }
`;

const ShowContainer = styled.div`
  width: 100px;
  height: 35px;

  select {
    height: 40px;
    padding-bottom: 2px;
  }

  & + label {
    top: 2px;
  }
`;

const Pagination = (data, hideUpdateButton, onChangePage) => () => {
  let page = parseInt((data.offset || 0) / (data.limit || 1), 10) + 1,
    lastPage = Math.ceil(data.count / data.limit),
    fromNum = data.offset + 1,
    toNum = Math.min(data.limit * page, data.count),
    isFirst = page === 1,
    isLast = page === lastPage;

  return (
    <PaginationContainer>
      <span>
        {fromNum}-{toNum} de {formats.number(data.count)}
      </span>
      <Formik enableReinitialize={true} initialValues={{ limit: data.limit }}>
        {({ values, touched, ...rest }) => (
          <ShowContainer>
            <Select
              noMargin={true}
              label="Registros"
              name="limit"
              onChange={event => {
                onChangePage(1, parseInt(event.target.value));
              }}
              options={{
                defaults: { value: '20', label: '20' },
                values: [
                  { value: '50', label: '50' },
                  { value: '100', label: '100' }
                ]
              }}
            />
          </ShowContainer>
        )}
      </Formik>
      <IconButton disabled={isFirst} onClick={() => onChangePage(1, data.limit)}>
        <MdFirstPage />
      </IconButton>
      <IconButton disabled={isFirst} onClick={() => onChangePage(page - 1, data.limit)}>
        <MdNavigateBefore />
      </IconButton>
      <IconButton disabled={isLast} onClick={() => onChangePage(page + 1, data.limit)}>
        <MdNavigateNext />
      </IconButton>
      <IconButton disabled={isLast} onClick={() => onChangePage(lastPage, data.limit)}>
        <MdLastPage />
      </IconButton>

      {!hideUpdateButton && (
        <IconButton title="Atualizar" className="left-spacing" onClick={() => onChangePage(page, data.limit)}>
          <MdRefresh />
        </IconButton>
      )}
    </PaginationContainer>
  );
};

function DataTable({
  data,
  pageSize,
  columns,
  keyField = 'id',
  extraOptions,
  padding,
  loading,
  emptyText,
  hideUpdateButton,
  noPagination,
  onRowClicked,
  onChangePage,
  customStyles = {},
  rowMinHeight
}) {
  const [selected, setSelected] = useState(null);
  const dataList = _.cloneDeep(data.rows);

  const updateState = useCallback(
    value => {
      onRowClicked(value);
      setSelected(_.get(value, keyField));
    },
    [onRowClicked, keyField]
  );

  const handleChangePage = useCallback(
    (page, limit) => {
      onChangePage(page, limit);
    },
    [onChangePage]
  );

  const selectableRowSelected = row => {
    return _.get(row, keyField) === selected;
  };
  const tableColumns = useMemo(() => columns, [columns]);

  return (
    <Container noPagination={noPagination} padding={padding} rowMinHeight={rowMinHeight}>
      <DataTableComponent
        striped
        highlightOnHover
        pointerOnHover
        dense
        persistTableHead
        showRowHover
        overflowY
        pagination={!noPagination}
        selectableRowsHighlight={true}
        selectableRowSelected={selectableRowSelected}
        noHeader
        theme="docpay"
        paginationServer
        keyField={keyField}
        columns={tableColumns}
        data={dataList}
        paginationComponent={Pagination(data, hideUpdateButton, (page, limit) => handleChangePage((page - 1) * (data.limit || 0), limit))}
        noDataComponent={<EmptyState text={emptyText} visible={true} />}
        onRowClicked={updateState}
        progressComponent={<Loading size={39} label="Carregando..." />}
        paginationPerPage={data.limit || pageSize}
        progressPending={loading}
        paginationTotalRows={data.count || 0}
        customStyles={_.merge(customStyles, _.get(extraOptions, 'customStyles') || {})}
        {..._.omit(extraOptions, 'customStyles')}
      />
    </Container>
  );
}

DataTable.propTypes = {
  data: PropTypes.shape({
    rows: PropTypes.array,
    limit: PropTypes.number,
    count: PropTypes.number
  }).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      hide: PropTypes.oneOf(['sm', 'md', 'lg']), // sm (599px), md (959px), lg (1280px)
      selector: PropTypes.string,
      cell: PropTypes.func
    })
  ).isRequired,
  pageSize: PropTypes.number,
  emptyText: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  noPagination: PropTypes.bool.isRequired,
  keyField: PropTypes.string,
  onRowClicked: PropTypes.func,
  onChangePage: PropTypes.func.isRequired,
  extraOptions: PropTypes.object,
  rowMinHeight: PropTypes.string
};

DataTable.defaultProps = {
  data: {
    rows: [],
    limit: 1,
    count: 0
  },
  loading: false,
  selected: false,
  pageSize: 20,
  emptyText: 'No record',
  onRowClicked: _.noop,
  onChangePage: _.noop,
  noPagination: false,
  extraOptions: {}
};

export default DataTable;
