import React from 'react';
import * as Yup from 'yup';
import { InputGroup, Checkbox, Select } from '~/components/form';
import { BsRow, BsCol } from '~/components/layout';
import { ExtraFields } from '~/pages/bank-contracts/fields-extra';
import Fieldset from '~/components/fieldset';
import _ from 'lodash';

export const PaymentSchema = (values, settings) => {
  let validationFields = {};

  validationFields['minApprovers'] = Yup.mixed().test('match', '', function(value) {
    const { path, parent, createError } = this;
    const hasValue = String(_.get(parent, 'useApproval')) === 'true',
      val = value || _.get(parent, 'minApprovers'),
      tempValue = isNaN(val) ? null : Number(val);

    if (hasValue && (!tempValue || tempValue <= 0)) {
      return createError({ path, message: `Informe a quantidade mínima de aprovadores` });
    }
    return true;
  });

  return validationFields;
};

export const PaymentFields = ({ settings, values, errors, touched, hasEntityBind, previewMode }) => {
  let hasShippingSequence = settings.hasShippingSequence,
    hasAgreement = settings.hasAgreement;

  const span = !hasShippingSequence || !hasAgreement ? 24 : 12;

  return (
    <Fieldset label={`Pagamento`}>
      <BsRow>
        <BsCol md={24} lg={24}>
          <Select
            name="layoutType"
            label="Layout"
            disabled={true}
            value={_.get(values, 'layoutType') || ''}
            hasError={errors.layoutType && touched.layoutType}
            options={{
              values: [
                { value: '', label: 'Escolha o layout' },
                { value: 'cnab240', label: 'CNAB240' }
              ]
            }}
          />
        </BsCol>

        {hasShippingSequence && (
          <BsCol md={24} lg={span}>
            <InputGroup
              type="text"
              name="nextShippingNumber"
              label="Último número de remessa"
              disabled={previewMode}
              hasError={errors.nextShippingNumber && touched.nextShippingNumber}
            />
          </BsCol>
        )}

        {hasAgreement && (
          <BsCol md={24} lg={span}>
            <InputGroup
              type="text"
              name="agreementNumber"
              label="Número de convênio"
              disabled={previewMode}
              hasError={errors.agreementNumber && touched.agreementNumber}
            />
          </BsCol>
        )}

        <ExtraFields
          settings={settings}
          values={values}
          errors={errors}
          touched={touched}
          fieldsPath="fields"
          previewMode={previewMode}
          layoutType={_.get(values, 'layoutType')}
        />
      </BsRow>

      <Fieldset label={`Aprovação de ordens de pagamento`}>
        <BsRow>
          <BsCol sm={24} md={String(_.get(values, 'useApproval') === 'true') ? 12 : 24}>
            <Checkbox name="useApproval" noMargin={true} label="Habilitar aprovação" disabled={previewMode} />
          </BsCol>

          {_.get(values, 'useApproval') && (
            <BsCol sm={24} md={12}>
              <InputGroup
                type="text"
                name="minApprovers"
                noMargin={true}
                label="Quantidade mínima de aprovadores"
                disabled={previewMode}
                hasError={errors.minApprovers && touched.minApprovers}
              />
            </BsCol>
          )}
        </BsRow>
      </Fieldset>
    </Fieldset>
  );
};
