import React from 'react';
import { Select, Autocomplete, InputMask, InputGroup } from '~/components/form';
import FieldSet from '~/components/fieldset';
import { BsRow, BsCol } from '~/components/layout';
import _ from 'lodash';
import { accountTypes } from '~/pages/payment-orders/constants';
import * as Yup from 'yup';
import { MASK_CNPJ, MASK_CPF } from '~constants';
import { isValidCpf, isValidCnpj } from '~/validator';

export const id = 10;

export const onParentChange = ({ field, value, parent, setFieldValue }) => {

  if (field === 'paymentOrderType' && _.get(parent, 'businessPartner.identity') && _.get(parent, 'company.identity')) {
    let bpIdentity = _.get(parent, 'businessPartner.identity'),
       cmpIdentity = _.get(parent, 'company.identity');
    setFieldValue('entryType', (bpIdentity === cmpIdentity) ? '43' : '41');
  } else if (field === 'businessPartner' && value) {
    let bpIdentity = _.get(value, 'identity'),
       cmpIdentity = _.get(parent, 'company.identity');
    setFieldValue('entryType', (bpIdentity === cmpIdentity) ? '43' : '41');

  } else if (field === 'company' && value) {
    let bpIdentity = _.get(parent, 'businessPartner.identity'),
       cmpIdentity = _.get(value, 'identity');
    setFieldValue('entryType', (bpIdentity === cmpIdentity) ? '43' : '41');
  
  } else if ((field === 'bankAccount' && value) ||  _.get(parent, 'bank.code')) {
    let bankCode = _.get(value, 'bank.code') || _.get(parent, 'bank.code'),
      selectedBankCode = _.get(parent, 'orderData.bank.code');

    if (bankCode === selectedBankCode) {
      setFieldValue('orderData.bank', null);
    }
  }
};

const isJudicialDeposit = (values) => {
  return (_.get(values, 'bankAccount.bank.code') === '341' &&  _.get(values, 'orderData.goalTED.value') === '00100')
};

export const Render = ({ errors, touched, previewMode, setFieldValue, values, onDataList, onListBanks }) => {
  let allowedGoalTED = _.get(values,'bankAccount.bank.settings.payment.goalsTED');
  let banckAccountTypes = _.get(values,'bankAccount.bank.settings.payment.accountTypes');
  let allowedAccountTypes = !banckAccountTypes ? accountTypes : _.filter(accountTypes, r => banckAccountTypes.includes(_.get(r, 'value')))
  const editLocked = _.get(values, 'editLocked');

  return (
    <FieldSet label="Transferência - TED">
      <BsRow>
        <BsCol md={24} lg={24} xl={9}>
          <Select
            name="orderData.accountType"
            label="Tipo de conta *"
            disabled={previewMode || editLocked}
            hasError={_.get(errors, 'orderData.accountType') && _.get(touched, 'orderData.accountType')}
            options={{ values: [
                { value: '', 'label': '' },
                ...allowedAccountTypes
              ]
            }}
            />
        </BsCol>

        <BsCol md={24} lg={18} xl={11}>
          <Autocomplete
            name="orderData.goalTED"
            keyField="value"
            disabled={previewMode || editLocked}
            label="Finalidade do TED *"
            value={_.get(values, 'orderData.goalTED')}
            valueFormat={row => `${row.label || ''}`}
            loadData={onDataList('goalsTED', 'value', 'label', allowedGoalTED)}
            emptyText={'Selecione a finalidade do TED *'}
            tipText={'Digite... '}
            loadingText={'Carregando...'}
            notFoundText={'Não encontrada'}
            />
        </BsCol>

        <BsCol md={24} lg={6} xl={4}>
          <Select
            name="orderData.centralizingChamber"
            label="Câmara Centralizadora"
            disabled={previewMode || editLocked}
            hasError={_.get(errors, 'orderData.centralizingChamber') && _.get(touched, 'orderData.centralizingChamber')}
            options={{ values: [
                { value: '018', 'label': '018 - TED (STR, CIP)' },
                { value: '988', 'label': '988 - TED (STR/CIP)' },
              ]
            }}
            />
        </BsCol>
      </BsRow>

      <BsRow>
        <BsCol md={24} lg={24} xl={8}>
          <Autocomplete
            name="orderData.bank"
            minLength={0}
            keyField="code"
            disabled={previewMode || editLocked}
            label="Banco *"
            value={_.get(values, 'orderData.bank')}
            valueFormat={row => `${row.code} - ${row.name}`}
            loadData={(term, callback) => onListBanks({ term, bankCode: _.get(values, 'bankContract.bankAccount.bank.code') }, callback)}
            emptyText={'Pesquise um banco'}
            tipText={'Digite... '}
            loadingText={'Carregando...'}
            notFoundText={'Não encontrado'}
            onChange={value => setFieldValue('orderData.bank', value)}
            hasError={_.get(errors, 'orderData.bank') && _.get(touched, 'orderData.bank')}
            />
        </BsCol>

        <BsCol md={16} lg={8} xl={5}>
          <InputGroup
            type="text"
            name="orderData.bankBranch"
            label="Agência do crédito *"
            disabled={previewMode || editLocked}
            maxLength="10"
            hasError={_.get(errors, 'orderData.bankBranch') && _.get(touched, 'orderData.bankBranch')}
            />
        </BsCol>

        <BsCol md={8} lg={4} xl={3}>
          <InputGroup
            type="text"
            name="orderData.bankBranchDigit"
            label="Digito agência"
            disabled={previewMode || editLocked}
            maxLength="2"
            hasError={_.get(errors, 'orderData.bankBranchDigit') && _.get(touched, 'orderData.bankBranchDigit')}
            />
        </BsCol>

        <BsCol md={16} lg={8} xl={5}>
          <InputGroup
            type="text"
            name="orderData.accountNumber"
            label="Conta do crédito *"
            disabled={previewMode || editLocked}
            maxLength="20"
            hasError={_.get(errors, 'orderData.accountNumber') && _.get(touched, 'orderData.accountNumber')}
            />
        </BsCol>

        <BsCol md={8} lg={4} xl={3}>
          <InputGroup
            type="text"
            name="orderData.accountNumberDigit"
            label="Digito conta *"
            disabled={previewMode || editLocked}
            maxLength="2"
            hasError={_.get(errors, 'orderData.accountNumberDigit') && _.get(touched, 'orderData.accountNumberDigit')}
            />
        </BsCol>
      </BsRow>

      {isJudicialDeposit(values) &&
        <BsRow>
          <BsCol md={24} lg={24} xl={24}>
            <InputGroup
              type="text"
              name="orderData.cit"
              label="Identificador Transação (Deposito Judicial) *"
              disabled={previewMode || editLocked}
              maxLength="20"
              hasError={_.get(errors, 'orderData.cit') && _.get(touched, 'orderData.cit')}
              />
          </BsCol>
        </BsRow>
      }

      {['00012'].includes(_.get(values, 'orderData.goalTED.value')) &&
        <BsRow>
          <BsCol md={24} lg={24} xl={12}>
            <InputGroup
              type="text"
              name="orderData.processId"
              label="Id do processo *"
              disabled={previewMode || editLocked}
              maxLength="20"
              hasError={_.get(errors, 'orderData.processId') && _.get(touched, 'orderData.processId')}
              />
          </BsCol>

          <BsCol md={24} lg={8} xl={4}>
            <Select
              name="orderData.processIdentityType"
              label="Tipo *"
              disabled={previewMode || editLocked}
              hasError={_.get(errors, 'orderData.processIdentityType') && _.get(touched, 'orderData.processIdentityType')}
              onChange={(evt) => {
                setFieldValue('orderData.processIdentityType', evt.target.value);
                setFieldValue('orderData.processIdentity', '');
              }}
              options={{ values: [
                  { value: '', 'label': '' },
                  { value: 'company', 'label': 'CNPJ' },
                  { value: 'person', 'label': 'CPF' },
                ]}}
              />
          </BsCol>

          <BsCol md={24} lg={16} xl={8}>
            {_.get(values, 'orderData.processIdentityType') === 'person' &&
               <InputMask
                disabled={!_.get(values, 'orderData.processIdentityType') || previewMode || editLocked}
                mask={MASK_CPF}
                name="orderData.processIdentity"
                label={'CPF do executado *'}
                hasError={_.get(errors, 'orderData.processIdentity') && _.get(touched, 'orderData.processIdentity')}
                />
            }
            {_.get(values, 'orderData.processIdentityType') !== 'person' &&
              <InputMask
                disabled={!_.get(values, 'orderData.processIdentityType') || previewMode || editLocked}
                mask={MASK_CNPJ}
                name="orderData.processIdentity"
                label={'CNPJ do executado *'}
                hasError={_.get(errors, 'orderData.processIdentity') && _.get(touched, 'orderData.processIdentity')}
                />
            }
          </BsCol>
        </BsRow>
      }
    </FieldSet>
  )
}

export const Schema = (values) => ({
  orderData: Yup.object().typeError('Informe os dados da ordem').shape({
    accountType: Yup.string().required('Informe o tipo de conta'),
    goalTED: Yup.string().required('Informe a finalidade do TED'),
    bankBranch: Yup.string().required('Informe a agência'),
    accountNumber: Yup.string().required('Informe a conta de crédito'),
    accountNumberDigit: Yup.string().required('Informe o digito da conta'),
    processIdentity: Yup.mixed().test('match', '', function(value) {
      const { path, parent, createError } = this;
      const val = value || _.get(parent, 'processIdentity'),
        type = _.get(parent, 'processIdentityType');
      if (val && type === 'person' && !isValidCpf(val)) {
        return createError({ path, message: 'Informe um CPF válido' });
      } else if (val && type === 'company' && !isValidCnpj(val)) {
        return createError({ path, message: 'Informe um CNPJ válido' });
      }
      return true;
    }),
    cit: Yup.string().test('match', '', function(value) {
      const { path, parent, createError } = this
      const val = value || _.get(parent, 'cit')
      if (isJudicialDeposit(values) && _.isEmpty(val)) {
        return createError({ path, message: 'Informe o identificador da transação' })
      }
      return true
    }),
    bank: Yup.mixed().test('match', '', function(value) {
      const { path, parent, createError } = this;
      const selectedBankCode = _.get(value, 'code') || _.get(parent, 'bank.code'),
        bankCode = _.get(values, 'bankContract.bankAccount.bank.code');

      if (!selectedBankCode) {
        return createError({ path, message: 'Selecione o banco' });
      } else if (selectedBankCode === bankCode) {
        return createError({ path, message: 'O banco escolhido deve ser diferente do banco do contrato.' });
      }
      return true;
    })
  })
})
