import React from 'react';
import wave from '~/assets/wave.svg';

export default function Spinner({...props}) {
  if (!props.visible) return null;
  const size = props.size || 20;

  return (
    <img
      src={wave}
      style={{ width: size, height: size, border: 'none', alignSelf: 'center', justifyContent: 'center' }}
      alt="" />
  );
}
