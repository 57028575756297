import { createActions, createReducer } from 'reduxsauce';
import produce from 'immer';
import _ from 'lodash';

export const { Types, Creators } = createActions(
  {
    list: ['params'],
    listSuccess: ['data'],
    load: ['data'],
    loadSuccess: ['model'],
    downloadFile: ['data', 'actions'],
    downloadFileSuccess: ['data'],
    formActionDone: [],
    generateXlsx: ['data'],
    removeBill: ['id', 'movementId', 'billId', 'actions'],
    removeBills: ['id', 'actions'],
    removePayment: ['id', 'movementId', 'payment', 'actions'],
    removePayments: ['id', 'actions'],
    cancelChargeMovement: ['id', 'message', 'actions'],
    cancelPaymentMovement: ['id', 'message', 'actions'],
    listBankAccounts: ['term', 'callback'],
    listCompanies: ['term', 'callback']
  },
  { prefix: 'shippings' }
);

const INITIAL_STATE = {
  loading: false,
  formLoading: false,
  term: null,
  model: {},
  data: { rows: [], count: 0, offset: 0 }
};

const handleList = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.term = action.term;
    draft.loading = true;
    draft.data = { rows: [], count: 0, offset: 0 };
  });

const handleListSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.loading = false;
    draft.data = action.data;
  });

const handleLoad = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.model = {};
    draft.formLoading = true;
  });

const downloadFile = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.formLoading = true;
  });

const downloadFileSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.formLoading = false;
  });

const handleLoadSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.formLoading = false;
    draft.model = _.get(action, 'model.shipping');
  });

const formActionDone = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.formLoading = false;
    draft.formLoadingMessage = '';
  });

const formAction = message => (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.formLoading = true;
    draft.formLoadingMessage = message;
  });

export default createReducer(INITIAL_STATE, {
  [Types.LIST]: handleList,
  [Types.LIST_SUCCESS]: handleListSuccess,
  [Types.LOAD]: handleLoad,
  [Types.LOAD_SUCCESS]: handleLoadSuccess,
  [Types.DOWNLOAD_FILE]: downloadFile,
  [Types.DOWNLOAD_FILE_SUCCESS]: downloadFileSuccess,
  [Types.FORM_ACTION_DONE]: formActionDone,
  [Types.DOWNLOAD_FILE]: formAction('Gerando Arquivo...'),
  [Types.REMOVE_BILL]: formAction('Removendo fatura...'),
  [Types.REMOVE_BILLS]: formAction('Removendo faturas...'),
  [Types.REMOVE_PAYMENT]: formAction('Removendo ordem de pagamento...'),
  [Types.REMOVE_PAYMENTS]: formAction('Removendo ordens de pagamento...'),
  [Types.GENERATE_XLSX]: formAction('Gerando planilha...')
});
