import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { MdSave, MdClear, MdCheck, MdDelete, MdCompareArrows } from 'react-icons/md';
import * as Yup from 'yup';
import { Creators as BankContractActions } from '~/store/modules/bank-contract';
import { Creators as HeaderActions } from '~/store/modules/header';
import { Container } from '~/pages/bank-contracts/styles';
import CellStatus from '~/components/datatable/cell-status';
import { BankContractForm, BankContractSchema, formatBankContractSimple } from '~/pages/bank-contracts/form';
import { SubtitleItem, Subtitle } from '~/components/datatable/subtitle';
import { red, green } from '~/components/mixins/color';
import FormFilter from '~/pages/bank-contracts/filter';
import confirm from '~/components/confirm';
import Crud from '~/components/crud';
import { formats } from '~/helper';
import CellBank from '~/components/datatable/cell-bank';

const columns = [
  {
    name: ' ',
    selector: 'status',
    width: '10px',
    cell: row => <CellStatus title="" color={row.active ? green.hex() : red.hex()} />
  },
  {
    name: 'Agência / Conta',
    selector: 'bank',
    width: '160px',
    cell: row => <CellBank bankAccount={_.get(row, 'bankAccount')} />
  },
  {
    name: 'Tipo',
    selector: 'movementType',
    width: '180px',
    format: row => {
      let type = /^(payment)$/.test(row.movementType) ? 'Pagamento' : 'Cobrança',
        billType = _.get(row, 'billType.code'),
        layout = type === 'Cobrança' && billType === 40 ? _.get(row, 'billType.name') : _.toUpper(_.get(row, 'layoutType')) || '';
      return `${type} - ${layout}`;
    }
  },
  {
    name: 'Empresa',
    selector: 'bankAccount',
    format: row => {
      let company = _.get(row, 'bankAccount.company') || {};
      return `${formats.cnpj_cpf(company.identity)} - ${company.name}`;
    }
  },
  {
    name: 'Cadastrado em',
    selector: 'createdAt',
    width: '140px',
    hide: 'md',
    format: row => formats.dateTimeZone(_.get(row, 'createdAt'), 'dd/MM/yyyy HH:mm')
  }
];

const customStyles = {
  rows: {
    denseStyle: {
      minHeight: '42px'
    }
  }
};

function BankContract({ match, history, location, acls }) {
  const dispatch = useDispatch();
  const state = useSelector(state => state.bankContract);
  const headerState = useSelector(state => state.header);
  const canWrite = acls.includes('W');
  const [openForm, setOpenForm] = useState(!!_.get(match, 'params.id'));
  const showFilter = headerState.filter.visible;

  useEffect(() => {
    const config = {
      loading: state.loading,
      useFilter: true,
      filter: { ...headerState.filter, visible: false }
    };
    dispatch(HeaderActions.configure(config));
    // eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    let id = _.get(match, 'params.id');
    if (id) {
      dispatch(BankContractActions.load({ id }));
      history.replace('/bank-contracts');
    }
  }, [match, dispatch, history]);

  useEffect(() => {
    dispatch(BankContractActions.list({ ...headerState.filter.data, offset: 0 }));
    // eslint-disable-next-line
  }, [dispatch]);

  function handleOnSubmit(data, actions) {
    dispatch(BankContractActions.createOrUpdate(data, actions));
  }

  function handleLoad(data) {
    setOpenForm(true);
    dispatch(BankContractActions.load(data));
  }

  function handlePageChange(offset, limit) {
    dispatch(BankContractActions.list({ ...headerState.filter.data, offset, limit }));
  }

  async function handleListBankAccounts(term, callback) {
    dispatch(BankContractActions.listBankAccounts(term, callback));
  }

  async function handleListBillTypes(term, callback) {
    dispatch(BankContractActions.listBillTypes(term, callback));
  }

  async function handleListCompanies(term, callback) {
    dispatch(BankContractActions.listCompanies(term, callback));
  }

  async function handleListEmailTemplates(term, callback) {
    dispatch(BankContractActions.listEmailTemplates(term, 'whatsapp', callback));
  }

  async function handleRemove(values, actions) {
    const result = await confirm.show({
      title: 'Atenção',
      text: `Deseja realmente remover este contrato? Essa ação não poderá ser revertida!`
    });

    if (result) {
      dispatch(BankContractActions.remove(values, actions));
    }
  }

  function handleHideFilter() {
    dispatch(HeaderActions.hideFilter());
    dispatch(BankContractActions.clearModel());
    setOpenForm(false);
  }

  function handleFilter(data) {
    handleHideFilter();
    dispatch(HeaderActions.callFilter({ ...data, offset: 0 }, BankContractActions.list));
  }

  const getActionsForm = () => {
    const acts = [
        {
          label: 'Salvar',
          icon: MdSave,
          isSubmit: true,
          isDisabled: () => !canWrite,
          action: handleOnSubmit
        }
      ],
      modelId = _.get(state, 'model.id'),
      bankIsDigital = _.get(state, 'model.bankAccount.bank.onlyApi');

    if (modelId) {
      acts.push({
        label: 'Remover',
        icon: MdDelete,
        isDisabled: () => !canWrite,
        action: handleRemove
      });

      if (bankIsDigital) {
        acts.push({
          label: 'Iniciar/Autenticar uso API Cora',
          icon: MdCompareArrows,
          action: digitalBankFlow
        });
      }
    }

    return acts;
  };

  function digitalBankFlow(data, actions) {
    dispatch(BankContractActions.externalApiAuthorizeToContract(data));
  }

  return (
    <Container>
      {!openForm && !showFilter && (
        <Subtitle bottom={25} left={20}>
          <span>Legenda:</span>
          <SubtitleItem color={green.hex()}>Ativo</SubtitleItem>
          <SubtitleItem color={red.hex()}>Inativo</SubtitleItem>
        </Subtitle>
      )}

      <Crud
        useOpenForm={true}
        openForm={openForm || showFilter}
        hideAdd={!canWrite}
        columns={columns}
        emptyText="Nenhum contrato encontrado"
        formTitle={data => (showFilter ? `Filtro` : _.get(data, 'id') ? `Contrato - ${formatBankContractSimple(data, true)}` : `Novo contrato`)}
        data={state.data}
        tableLoading={state.loading}
        formLoading={state.formLoading}
        onCloseFilter={handleHideFilter}
        onChangePage={handlePageChange}
        onRowClicked={handleLoad}
        rightWidth={`${showFilter ? 500 : 830}px`}
        formOptions={{
          initialValues: showFilter ? headerState.filter.data : state.model || {},
          validationSchema: showFilter
            ? undefined
            : () =>
                Yup.lazy(values =>
                  BankContractSchema(values, _.get(values, 'bankAccount.bank.settings'), _.get(values, 'movementType'), _.get(values, 'billType.code'))
                ),
          initialTouched: showFilter ? {} : { bankAccount: true }
        }}
        renderForm={args =>
          showFilter ? (
            <FormFilter
              {...args}
              onListBankAccounts={handleListBankAccounts}
              onListCompanies={handleListCompanies}
              onListEmailTemplates={handleListEmailTemplates}
            />
          ) : (
            <BankContractForm
              {...args}
              previewMode={!canWrite}
              onListBankAccounts={handleListBankAccounts}
              onListBillTypes={handleListBillTypes}
              onListEmailTemplates={handleListEmailTemplates}
            />
          )
        }
        actions={
          showFilter
            ? [
                { label: 'Limpar Filtro', icon: MdClear, action: () => handleFilter({}) },
                { label: 'Aplicar Filtro', icon: MdCheck, action: data => handleFilter(data) }
              ]
            : getActionsForm()
        }
        dataTableOptions={{
          customStyles
        }}
      />
    </Container>
  );
}

export default BankContract;
