import React, { useState } from 'react';
import * as Yup from 'yup';
import { Select, InputGroup, InputMask } from '~/components/form';
import FieldSet from '~/components/fieldset';
import { BsRow, BsCol } from '~/components/layout';
import _ from 'lodash';
import { MASK_CNPJ, MASK_CPF, MASK_PIS_PASEP, MASK_TAX_DIGITABLE_LINE, MASK_BARCODE } from '~constants';
import { isValidCpf, isValidCnpj, isValidPisPasep, isValidSlipDigitableLine } from '~/validator';
import { barcodeToDigitableLine, digitableLineToBarcode } from '~/helper';
import { FaBarcode } from 'react-icons/fa';
import { BiEditAlt } from 'react-icons/bi';

export const id = 43;

export const onParentChange = ({ field, value, parent, setFieldValue }) => {
  let idField = 'orderData.identity',
    idTypeField = 'orderData.identityType',
    nameField = 'orderData.name';

  if (field === 'paymentOrderType' && _.get(parent, 'businessPartner')) {
    let identity = _.get(parent, 'businessPartner.identity');
    setFieldValue(idTypeField, _.size(identity) === 14 ? '02' : '01');
    setFieldValue(idField, identity);
    setFieldValue(nameField, _.get(parent, 'businessPartner.name'));

  } else if (field === 'businessPartner' && value) {
    let identity = _.get(value, 'identity');
    setFieldValue(idTypeField, _.size(identity) === 14 ? '02' : '01');
    setFieldValue(idField, identity);
    setFieldValue(nameField, _.get(value, 'name'));

  }
};

export const Render = ({ values, errors, touched, previewMode, setFieldValue }) => {
  const [barCodeMode, setBarCodeMode] = useState(false);
  const toogleBarCodeMode = () => setBarCodeMode(previous => !previous);
  const editLocked = _.get(values, 'editLocked') || false;

  return (
    <FieldSet label="Tributos - FGTS">
      <BsRow>
        <BsCol md={24} lg={24} xl={12}>
          {!barCodeMode && (
            <InputMask
              icon={FaBarcode}
              mask={MASK_TAX_DIGITABLE_LINE}
              name="orderData.digitableLine"
              label="Linha digitável *"
              disabled={previewMode || editLocked}
              hasError={_.get(errors, 'orderData.digitableLine') && _.get(touched, 'orderData.digitableLine')}
              onChange={event => {
                let value = _.get(event, 'target.value');
                setFieldValue('orderData.digitableLine', value);
                setFieldValue('orderData.barcode', digitableLineToBarcode(value));
              }}
              onIconClick={toogleBarCodeMode}
              iconTooltip="Editar código de barras"
            />
          )}
          {barCodeMode && (
            <InputMask
              icon={BiEditAlt}
              mask={MASK_BARCODE}
              name="orderData.barcode"
              label="Código de barras *"
              disabled={previewMode || editLocked}
              hasError={_.get(errors, 'orderData.barcode') && _.get(touched, 'orderData.barcode')}
              onChange={(event) => {
                let value = _.get(event, 'target.value');
                setFieldValue('orderData.barcode', value);
                setFieldValue('orderData.digitableLine', barcodeToDigitableLine(value));
              }}
              onIconClick={toogleBarCodeMode}
              iconTooltip="Editar linha digitável"
            />
          )}
        </BsCol>

        <BsCol md={24} lg={24} xl={12}>
          <InputGroup
            type="text"
            name="orderData.publicAgency"
            maxLength={100}
            disabled={previewMode || editLocked}
            label="Nome do orgão público"
            hasError={_.get(errors, 'orderData.publicAgency') && _.get(touched, 'orderData.publicAgency')}
            />
        </BsCol>           
      </BsRow>

      <BsRow>
        <BsCol md={24} lg={6} xl={6}>
          <InputGroup
            type="text"
            name="orderData.revenueCode"
            maxLength={20}
            disabled={previewMode || editLocked}
            label="Código da receita"
            hasError={_.get(errors, 'orderData.revenueCode') && _.get(touched, 'orderData.revenueCode')}
            />
        </BsCol>

        <BsCol md={24} lg={6} xl={6}>
          <Select
            name="orderData.identityType"
            label="Tipo da inscrição *"
            disabled={previewMode || editLocked}
            hasError={_.get(errors, 'orderData.identityType') && _.get(touched, 'orderData.identityType')}
            onChange={(evt) => {
              setFieldValue('orderData.identityType', evt.target.value);
              setFieldValue('orderData.identity', '');
            }}
            options={{ values: [
                { value: '', 'label': '' },
                { value: '01', label: 'CPF' },
                { value: '02', label: 'CNPJ' },
                { value: '03', label: 'PIS/PASEP' },
                { value: '04', label: 'CEI' },
                { value: '06', label: 'NB' },
                { value: '07', label: 'Número do Título' },
                { value: '08', label: 'DEBCAD' },
                { value: '09', label: 'REFERÊNCIA' },
              ]}}
            />
        </BsCol>

        <BsCol md={24} lg={12} xl={12}>
          {_.get(values, 'orderData.identityType') === '01' &&
             <InputMask
              disabled={!_.get(values, 'orderData.identityType') || previewMode || editLocked}
              mask={MASK_CPF}
              name="orderData.identity"
              label={'CPF *'}
              hasError={_.get(errors, 'orderData.identity') && _.get(touched, 'orderData.identity')}
              />
          }
          {_.get(values, 'orderData.identityType') === '02' &&
            <InputMask
              disabled={!_.get(values, 'orderData.identityType') || previewMode || editLocked}
              mask={MASK_CNPJ}
              name="orderData.identity"
              label={'CNPJ *'}
              hasError={_.get(errors, 'orderData.identity') && _.get(touched, 'orderData.identity')}
              />
          }
          {_.get(values, 'orderData.identityType') === '03' &&
            <InputMask
              disabled={!_.get(values, 'orderData.identityType') || previewMode || editLocked}
              mask={MASK_PIS_PASEP}
              name="orderData.identity"
              label={'PIS/PASEP *'}
              hasError={_.get(errors, 'orderData.identity') && _.get(touched, 'orderData.identity')}
              />
          }
          {!['01', '02'].includes(_.get(values, 'orderData.identityType')) &&
            <InputGroup
              type="text"
              maxLength={16}
              name="orderData.identity"
              disabled={!_.get(values, 'orderData.identityType') || previewMode || editLocked}
              label={'Número da inscrição *'}
              hasError={_.get(errors, 'orderData.identity') && _.get(touched, 'orderData.identity')}
              />
          }
        </BsCol>
      </BsRow>

      <BsRow>
        <BsCol md={24} lg={8} xl={8}>
          <InputGroup
            type="text"
            name="orderData.idFgts"
            maxLength={16}
            disabled={previewMode || editLocked}
            label="Identificador do Fundo de Garantia *"
            hasError={_.get(errors, 'orderData.idFgts') && _.get(touched, 'orderData.idFgts')}
            />
        </BsCol>

        <BsCol md={24} lg={8} xl={8}>
          <InputGroup
            type="text"
            name="orderData.sealNumber"
            maxLength={9}
            disabled={previewMode || editLocked}
            label="Lacre da conectividade social"
            hasError={_.get(errors, 'orderData.sealNumber') && _.get(touched, 'orderData.sealNumber')}
            />
        </BsCol>

        <BsCol md={24} lg={8} xl={8}>
          <InputGroup
            type="text"
            name="orderData.sealDigit"
            maxLength={1}
            disabled={previewMode || editLocked}
            label="Dígito lacre da conectividade social"
            hasError={_.get(errors, 'orderData.sealDigit') && _.get(touched, 'orderData.sealDigit')}
            />
        </BsCol>
      </BsRow>
    </FieldSet>
  )
};

export const Schema = (values) => ({
  orderData: Yup.object().typeError('Informe os dados da ordem').shape({
    identityType: Yup.string().required('Informe o tipo de inscrição'),
    identity: Yup.mixed().test('match', '', function(value) {
      const { path, parent, createError } = this;
      const val = value || _.get(parent, 'identity'),
        type = _.get(parent, 'identityType');

      if (!val) {
        return createError({ path, message: 'Informe o número da inscrição' });

      } else if (val && type === '01' && !isValidCpf(val)) {
        return createError({ path, message: 'Informe um CPF válido' });

      } else if (val && type === '02' && !isValidCnpj(val)) {
        return createError({ path, message: 'Informe um CNPJ válido' });

      } else if (val && type === '03' && !isValidPisPasep(val)) {
        return createError({ path, message: 'Informe um PIS/PASEP válido' });
      }
      return true;
    }),
    digitableLine: Yup.mixed().test('match', '', function(value) {
      const { path, parent, createError } = this;
      const val = (value || _.get(parent, 'digitableLine') || '').replace(/\D/g, '');
      if (!val) {
        return createError({ path, message: 'Informe a linha digitável' });
      }
      if (val && (_.size(val) !== 48 || !isValidSlipDigitableLine(val))) {
        return createError({ path, message: 'Informe uma linha digitável válida' });
      }
      return true;
    }),
    idFgts: Yup.mixed().test('match', '', function(value) {
      const { path, parent, createError } = this;
      const val = value || _.get(parent, 'idFgts');
      if (!val) {
        return createError({ path, message: 'Informe o identificador do fundo de garantia' });
      }
      return true;
    }),
    sealNumber: Yup.mixed().test('match', '', function(value) {
      const { path, parent, createError } = this;
      const idFgts = _.get(parent, 'idFgts');
      const sealNumber = _.get(parent, 'sealNumber');
      const sealDigit = _.get(parent, 'sealDigit');
      const mustHaveVal = idFgts || sealNumber || sealDigit;

      if (mustHaveVal && !sealNumber) {
        return createError({ path, message: 'Informe o lacre do conectividade social' });
      }
      return true;
    }),
    sealDigit: Yup.mixed().test('match', '', function(value) {
      const { path, parent, createError } = this;
      const idFgts = _.get(parent, 'idFgts');
      const sealNumber = _.get(parent, 'sealNumber');
      const sealDigit = _.get(parent, 'sealDigit');
      const mustHaveVal = idFgts || sealNumber || sealDigit;

      if (mustHaveVal && !sealDigit) {
        return createError({ path, message: 'Informe o dígito lacre do conectividade social' });
      }
      return true;
    }),
  })
});
