import React from 'react'
import * as Yup from 'yup'
import {
  Autocomplete,
  InputGroup,
  CurrencyField,
  Select,
  InputMask,
  InputPhone,
  RichEditor,
  InputLabel,
} from '~/components/form'
import { BsRow, BsCol } from '~/components/layout'
import { ExtraFields } from '~/pages/bank-contracts/fields-extra'
import { ApiFields } from '~/pages/bank-contracts/fields-api'
import { MASK_CPF, MASK_CNPJ, MASK_UUID } from '~/constants'
import { isValidCpf, isValidCnpj, isValidEmail } from '~/validator'
import Fieldset from '~/components/fieldset'
import { acceptValues, slipVariables } from '~/constants'
import { InstructionFieldForm } from '~/pages/bank-contracts/instruction-fields'
import _ from 'lodash'
import { Line } from '~/pages/bank-contracts/styles'

Yup.addMethod(Yup.string, 'codeType', function (errorMessage) {
  return this.test(`agreement-number-type`, errorMessage, function (value) {
    let { path, parent, createError } = this,
      isValid = true,
      settings = _.get(parent, 'bank.settings.charge') || {},
      minLen = _.get(settings, 'walletMinLen') || 1,
      maxLen = _.get(settings, 'walletMaxLen') || 3,
      walletEnum = _.get(settings, 'walletEnum')

    if (walletEnum && !walletEnum.includes(value)) {
      isValid = false
    }
    if (_.size(value) < minLen || _.size(value) > maxLen) {
      isValid = false
    }
    if (!walletEnum && (!value || _.size(value) === 0)) {
      isValid = false
    }
    return isValid || createError({ path, message: errorMessage })
  })
})

Yup.addMethod(Yup.number, 'agreementNumberType', function (errorMessage) {
  return this.test(`agreement-number-type`, errorMessage, function (value) {
    let { path, createError, parent } = this,
      message = errorMessage,
      size = _.size(String(value)),
      sizes = _.get(parent, 'bank.settings.charge.agreementSizes')

    let isValid = !sizes || sizes.includes(size)
    if (sizes && errorMessage) {
      message = `${errorMessage}. Quantidade de carteres válidas: ${sizes.join(
        ', ',
      )}`
    }
    return isValid || createError({ path, message })
  })
})

export const ChargeSchema = (values, config, billType) => {
  let validationFields = {},
    fields = {}

  if ([10].includes(billType)) {
    fields['code'] = Yup.string()
      .codeType('Carteira inválida')
      .required('Informe o Código da carteira')
    fields['ourNumberMode'] = Yup.string().required(
      'Informe o modo de geração do "nosso número"',
    )

    fields['nextOurNumber'] = Yup.mixed().test('match', '', function (value) {
      const { path, parent, createError } = this
      const val = value || _.get(parent, 'fields.nextOurNumber'),
        hasOurNumber = _.get(parent, 'fields.ourNumberMode') === 'auto'

      if (hasOurNumber && !val && val !== 0) {
        return createError({ path, message: 'Informe próximo nosso número' })
      } else if (hasOurNumber && val && isNaN(val)) {
        return createError({ path, message: 'Informe um valor numérico' })
      }
      return true
    })

    validationFields['defaults'] = Yup.object()
      .nullable()
      .shape({
        writeOffDays: Yup.mixed().test(
          'is-int',
          'Dias corridos p/ baixa deve ser superior a zero',
          (value) => !value || value > 0,
        ),
        paymentPlace: Yup.string(),
        documentType: Yup.string().required('Informe o Tipo de documento'),
        protestDays: Yup.mixed().test('match', '', function (value) {
          const { path, parent, createError } = this
          const protestMode = _.get(parent, 'protestDaysMode')
          const hasValue = protestMode && protestMode !== '0' ? true : false
          const val = value || _.get(parent, 'protestDays'),
            tempValue = isNaN(val) ? null : Number(val)

          if (hasValue && (!tempValue || tempValue <= 0)) {
            return createError({
              path,
              message: `Informe os dias para protesto`,
            })
          }
          return true
        }),
        discountValue: Yup.mixed().test('match', '', function (value) {
          const { path, parent, createError } = this
          const type = _.get(parent, 'discountType')
          const hasValue = !_.isEmpty(type) && type !== 'none'
          const val = _.get(parent, 'discountValue')
          const tempValue = Number(value || val)

          if (!!hasValue && (!tempValue || tempValue <= 0)) {
            const typeName = type === 'percentage' ? 'Percentual' : 'Valor'
            return createError({
              path,
              message: `${typeName} aplicado de desconto deve ser superior a zero`,
            })
          }

          return true
        }),
        discountDays: Yup.mixed().test('match', '', function (value) {
          const { path, parent, createError } = this
          const type = _.get(parent, 'discountType'),
            hasValue = /^(percentage|value)$/.test(type),
            val = _.get(parent, 'discountDays'),
            tempValue = parseInt(value || val)
          if (!!hasValue && (!tempValue || tempValue <= 0)) {
            return createError({
              path,
              message:
                'Quantidade de dias anterior ao vencimento que o desconto poderá ser aplicado',
            })
          }
          return true
        }),
        interestValue: Yup.mixed().test('match', '', function (value) {
          const { path, parent, createError } = this
          const type = _.get(parent, 'interestType'),
            val = _.get(parent, 'interestValue'),
            hasValue = /^(dailyPercentage)$/.test(type),
            tempValue = Number(value || val)

          if (!!hasValue && (!tempValue || tempValue <= 0)) {
            const typeName = type === 'percentage' ? 'Percentual' : 'Valor'
            return createError({
              path,
              message: `${typeName} aplicado aos juros deve ser superior a zero`,
            })
          }
          return true
        }),
        fineValue: Yup.mixed().test('match', '', function (value) {
          const { path, parent, createError } = this
          const type = _.get(parent, 'fineType'),
            hasValue = /^(percentage|value)$/.test(type),
            val = _.get(parent, 'fineValue'),
            tempValue = Number(value || val)
          if (!!hasValue && (!tempValue || tempValue <= 0)) {
            const typeName = type === 'percentage' ? 'Percentual' : 'Valor'
            return createError({
              path,
              message: `${typeName} aplicado à multa deve ser superior a zero`,
            })
          }
          return true
        }),
      })
  }

  if ([10].includes(billType) && _.get(values, 'fields.usePix') === 'yes') {
    fields['pixKeyType'] = Yup.string().required('Informe o tipo da chave PIX')
    fields['pixKey'] = Yup.mixed().test('match', '', function (value) {
      const { path, parent, createError } = this
      const val = value || _.get(parent, 'pixKey'),
        type = _.get(parent, 'pixKeyType')

      if (!val && type && type !== '05') {
        return createError({ path, message: 'Informe a chave PIX' })
      } else if (val && type === '03' && !isValidCpf(val)) {
        return createError({ path, message: 'Informe um CPF válido' })
      } else if (val && type === '04' && !isValidCnpj(val)) {
        return createError({ path, message: 'Informe um CNPJ válido' })
      } else if (
        val &&
        type === '01' &&
        _.size(val) !== 10 &&
        _.size(val) !== 11
      ) {
        return createError({ path, message: 'Informe um telefone válido' })
      } else if (val && type === '02' && !isValidEmail(val)) {
        return createError({ path, message: 'Informe um e-mail válido' })
      } else if (val && type === '05' && _.size(val) < 20) {
        return createError({
          path,
          message: 'Informe uma chave aleatória válida',
        })
      }
      return true
    })
  }

  if (
    [10].includes(billType) &&
    _.get(values, 'fields.useGuaranteed') === 'yes'
  ) {
    fields['guaranteedValue'] = Yup.mixed().test('match', '', function (value) {
      const { path, parent, createError } = this
      const val = value || _.get(parent, 'guaranteedValue'),
        tempValue = isNaN(val) ? null : Number(val)

      if (!tempValue || tempValue <= 0) {
        return createError({
          path,
          message: `Informe o valor da conta garantida`,
        })
      }
      return true
    })
  }

  validationFields['fields'] = Yup.object().nullable().shape(fields)

  return validationFields
}

const getLayouts = (settings) => {
  let data = [{ value: '', label: 'Escolha o layout' }],
    list = _.get(settings, 'cnab') || []
  _.each(list, (item) => {
    data.push({ value: item, label: _.toUpper(item) })
  })
  return data
}

const getOurNumberExtraAttr = ({ settings, layoutType }) => {
  let config = _.get(settings, `ourNumberLen.${layoutType}`)
  return config ? { minLength: config[0], maxLength: config[1] } : {}
}

const canUseField = (instructionsConfig, field, values) => {
  let conditional = _.get(instructionsConfig, `fieldsCondition[${field}]`),
    layoutFields = _.get(instructionsConfig, `fields`) || []

  if (!layoutFields.includes(field)) {
    return false
  }
  if (!conditional) {
    return true
  }
  let ref = _.get(values, `defaults.${conditional.field}`),
    evalIn = conditional.in,
    evalNotIn = conditional.notIn

  return (
    (!evalIn || (evalIn && evalIn.includes(ref))) &&
    (!evalNotIn || (evalNotIn && !evalNotIn.includes(ref)))
  )
}

const ourNumberModes = [
  { value: '', label: 'Escolha uma opção' },
  { value: 'auto', label: 'Controlado pelo DocPay' },
  { value: 'response', label: 'Controlado pelo Banco' },
]

const getOurNumberModes = (values, settings) => {
  let version = _.get(values, 'registerApiVersion'),
    versionData = _.find(_.get(settings, 'registerApi.versions') || [], {
      version,
    }),
    allowed = _.get(versionData, 'ourNumberModes')

  return allowed
    ? _.filter(ourNumberModes, (r) => !r.value || allowed.includes(r.value))
    : ourNumberModes
}

const renderSlipFields = ({
  settings,
  bankIsDigital,
  values,
  errors,
  touched,
  formikOnChange,
  onListEmailTemplates,
  setFieldValue,
  setFieldTouched,
  billType,
  hasEntityBind,
  previewMode,
}) => {
  let layoutType = _.get(values, 'layoutType'),
    ourNumberAuto = _.get(values, 'fields.ourNumberMode') === 'auto',
    ourNumberExtraAttributes = getOurNumberExtraAttr({ settings, layoutType }),
    bankDocTypes = _.get(
      settings,
      `documentTypes.${bankIsDigital ? 'api' : layoutType}`,
    ),
    allowedDocumentTypes = _.map(bankDocTypes, ({ id, value }) => ({
      label: value,
      value: id,
    })),
    instructionsConfig = _.get(settings, `instructions.${layoutType}`) || {}

  const hasAgreement = !_.isEmpty(_.get(settings, 'agreementSizes'))
  const hasInterest = canUseField(instructionsConfig, 'hasInterest', values)
  const hasInterestDays = canUseField(
    instructionsConfig,
    'interestDays',
    values,
  )
  const hasFine = canUseField(instructionsConfig, 'hasFine', values)
  const hasFineDays = canUseField(instructionsConfig, 'fineDays', values)
  const hasDiscount = canUseField(instructionsConfig, 'hasDiscount', values)
  const hasDiscountDays = canUseField(
    instructionsConfig,
    'discountDays',
    values,
  )
  const hasProtestDaysMode = canUseField(
    instructionsConfig,
    'protestDaysMode',
    values,
  )
  const hasProtestDays = canUseField(instructionsConfig, 'protestDays', values)
  const hasWriteOffDays = canUseField(
    instructionsConfig,
    'writeOffDays',
    values,
  )
  const hasRegisterTwo = canUseField(
    instructionsConfig,
    'hasRegisterTwo',
    values,
  )

  return (
    <>
      <Fieldset label={`Cobrança - ${_.get(values, 'billType.name')}`}>
        <BsRow>
          <BsCol md={24} lg={24}>
            <Autocomplete
              name="emailTemplate"
              keyField="id"
              label="Template (whatsapp)"
              disabled={previewMode}
              clearable={true}
              value={_.get(values, 'emailTemplate')}
              valueFormat={(row) => `${row.description}`}
              loadData={onListEmailTemplates}
              emptyText={'Pesquise um template (whatsapp)'}
              tipText={'Digite... '}
              loadingText={'Carregando...'}
              notFoundText={'Não encontrado'}
            />
          </BsCol>

          {!bankIsDigital && (
            <BsCol md={24} lg={8}>
              <Select
                name="layoutType"
                label="Layout"
                disabled={previewMode || hasEntityBind}
                value={_.get(values, 'layoutType') || ''}
                hasError={errors.layoutType && touched.layoutType}
                options={{
                  values: [...getLayouts(settings)],
                }}
                onChange={(evt) => {
                  setFieldValue('layoutType', evt.target.value)
                  setFieldValue('defaults', {})
                }}
              />
            </BsCol>
          )}

          <BsCol md={24} lg={hasAgreement ? 8 : !bankIsDigital ? 16 : 24}>
            <InputGroup
              type="text"
              name="fields.code"
              label="Código da Carteira (boleto)"
              minLength={_.get(settings, 'walletLen[0]') || 1}
              maxLength={_.get(settings, 'walletLen[1]') || 3}
              disabled={previewMode}
              hasError={
                _.get(errors, 'fields.code') && _.get(touched, 'fields.code')
              }
            />
          </BsCol>

          {hasAgreement && !bankIsDigital && (
            <BsCol md={24} lg={8}>
              <InputGroup
                type="text"
                name="agreementNumber"
                label="Número de convênio (boleto)"
                disabled={previewMode}
                minLength={_.first(_.get(settings, 'agreementSizes')) || 1}
                maxLength={_.last(_.get(settings, 'agreementSizes')) || 5}
                hasError={errors.agreementNumber && touched.agreementNumber}
              />
            </BsCol>
          )}

          {!bankIsDigital && (
            <BsCol md={24} lg={8}>
              <InputGroup
                type="text"
                name="nextShippingNumber"
                label="Último número de remessa"
                disabled={previewMode}
                hasError={
                  errors.nextShippingNumber && touched.nextShippingNumber
                }
              />
            </BsCol>
          )}

          {!bankIsDigital && (
            <BsCol md={24} lg={ourNumberAuto ? 8 : 16}>
              <Select
                name="fields.ourNumberMode"
                label={`Modo de geração do "nosso número"`}
                disabled={previewMode}
                hasError={
                  _.get(errors, 'fields.ourNumberMode') &&
                  _.get(touched, 'fields.ourNumberMode')
                }
                options={{
                  values: getOurNumberModes(values, settings),
                }}
                onChange={(evt) => {
                  let val = _.get(evt, 'target.value')
                  setFieldValue('fields.ourNumberMode', val)
                  if (val === 'response') {
                    setFieldValue('fields.nextOurNumber', null)
                  }
                }}
              />
            </BsCol>
          )}

          {ourNumberAuto && !bankIsDigital && (
            <BsCol md={24} lg={8}>
              <InputGroup
                type="text"
                name="fields.nextOurNumber"
                label="Último nosso número"
                disabled={previewMode}
                hasError={
                  _.get(errors, 'fields.nextOurNumber') &&
                  _.get(touched, 'fields.nextOurNumber')
                }
                {...ourNumberExtraAttributes}
              />
            </BsCol>
          )}

          {ourNumberAuto && !bankIsDigital && (
            <BsCol md={24} lg={8}>
              <Select
                label="Impressão em lote de boletos gerados"
                name="fields.billPdfBatchDownload"
                disabled={previewMode}
                value={_.get(values, 'fields.billPdfBatchDownload') || ''}
                hasError={
                  _.get(errors, 'fields.billPdfBatchDownload') &&
                  _.get(touched, 'fields.billPdfBatchDownload')
                }
                options={{
                  values: [
                    { value: '', label: 'Selecione uma opção' },
                    { value: 'true', label: 'Sim' },
                    { value: 'false', label: 'Não' },
                  ],
                }}
              />
            </BsCol>
          )}

          {billType === 10 && !bankIsDigital && (
            <BsCol md={12} lg={8}>
              <Select
                name="fields.useGuaranteed"
                label={`Habilitar conta garantida`}
                disabled={previewMode}
                hasError={
                  _.get(errors, 'fields.useGuaranteed') &&
                  _.get(touched, 'fields.useGuaranteed')
                }
                options={{
                  values: [
                    { value: 'no', label: 'Não' },
                    { value: 'yes', label: 'Sim' },
                  ],
                }}
                onChange={(evt) => {
                  let value = _.get(evt, 'target.value')
                  setFieldValue('fields.useGuaranteed', value)
                  if (value !== 'yes') {
                    setFieldValue('fields.guaranteedValue', 0)
                  }
                }}
              />
            </BsCol>
          )}

          {billType === 10 && _.get(values, 'fields.useGuaranteed') === 'yes' && (
            <BsCol md={12} lg={8}>
              <CurrencyField
                name="fields.guaranteedValue"
                precision={2}
                label={'Valor conta garantida'}
                disabled={previewMode}
                hasError={
                  _.get(errors, 'fields.guaranteedValue') &&
                  _.get(touched, 'fields.guaranteedValue')
                }
                // onChange={value => {
                //   setFieldValue('defaults.interestType', value > 0 ? 'dailyPercentage' : 'none');
                // }}
              />
            </BsCol>
          )}

          {!bankIsDigital && (
            <BsCol md={24} lg={billType === 10 ? 18 : 24}>
              <Select
                name="fields.sendEmailMode"
                label={`Forma de determinar o evento "Boleto gerado" (Configurações de E-mail)`}
                disabled={previewMode}
                value={_.get(values, 'layoutType') || ''}
                hasError={
                  _.get(errors, 'fields.sendEmailMode') &&
                  _.get(touched, 'fields.sendEmailMode')
                }
                options={{
                  values: [
                    { value: '', label: 'Não enviar e-mail' },
                    {
                      value: 'closeShipping',
                      label:
                        'Após fechar Remessa (boleto novo e não registrado)',
                    },
                    {
                      value: 'responseInclude',
                      label: 'Após receber Retorno confirmando registro',
                    },
                  ],
                }}
              />
            </BsCol>
          )}

          {billType === 10 && !bankIsDigital && (
            <BsCol md={24} lg={6}>
              <Select
                name="fields.usePix"
                label={`Habilitar PIX Simples`}
                disabled={previewMode}
                hasError={
                  _.get(errors, 'fields.usePix') &&
                  _.get(touched, 'fields.usePix')
                }
                options={{
                  values: [
                    { value: 'no', label: 'Não' },
                    { value: 'yes', label: 'Sim' },
                  ],
                }}
                onChange={(evt) => {
                  let value = _.get(evt, 'target.value')
                  setFieldValue('fields.usePix', value)
                  if (value !== 'yes') {
                    setFieldValue('fields.pixKeyType', '')
                    setFieldValue('fields.pixKey', '')
                  }
                }}
              />
            </BsCol>
          )}

          {billType === 10 &&
            _.get(values, 'fields.usePix') === 'yes' &&
            !bankIsDigital && (
              <>
                <BsCol md={24} lg={6}>
                  <Select
                    name="fields.pixKeyType"
                    label="PIX - Tipo de chave"
                    disabled={previewMode}
                    hasError={
                      _.get(errors, 'fields.pixKeyType') &&
                      _.get(touched, 'fields.pixKeyType')
                    }
                    options={{
                      values: [
                        { value: '', label: '' },
                        { value: '01', label: 'Telefone' },
                        { value: '02', label: 'E-mail' },
                        { value: '03', label: 'CPF' },
                        { value: '04', label: 'CNPJ' },
                        { value: '05', label: 'Chave Aleatória' },
                      ],
                    }}
                    onChange={(evt) => {
                      let value = _.get(evt, 'target.value')
                      setFieldValue('fields.pixKeyType', value)
                      setFieldValue('fields.pixKey', '')
                    }}
                  />
                </BsCol>

                <BsCol md={24} lg={18}>
                  {_.get(values, 'fields.pixKeyType') === '03' && (
                    <InputMask
                      mask={MASK_CPF}
                      name="fields.pixKey"
                      label="PIX - CPF"
                      disabled={previewMode}
                      hasError={
                        _.get(errors, 'fields.pixKey') &&
                        _.get(touched, 'fields.pixKey')
                      }
                    />
                  )}
                  {_.get(values, 'fields.pixKeyType') === '04' && (
                    <InputMask
                      mask={MASK_CNPJ}
                      name="fields.pixKey"
                      label="PIX - CNPJ"
                      disabled={previewMode}
                      hasError={
                        _.get(errors, 'fields.pixKey') &&
                        _.get(touched, 'fields.pixKey')
                      }
                    />
                  )}
                  {_.get(values, 'fields.pixKeyType') === '01' && (
                    <InputPhone
                      name="fields.pixKey"
                      label="PIX - Telefone"
                      country="BR"
                      disabled={previewMode}
                      hasError={
                        _.get(errors, 'fields.pixKey') &&
                        _.get(touched, 'fields.pixKey')
                      }
                    />
                  )}
                  {_.get(values, 'fields.pixKeyType') === '02' && (
                    <InputGroup
                      type="text"
                      name="fields.pixKey"
                      label="PIX - E-mail"
                      disabled={previewMode}
                      maxLength="77"
                      hasError={
                        _.get(errors, 'fields.pixKey') &&
                        _.get(touched, 'fields.pixKey')
                      }
                    />
                  )}
                  {_.get(values, 'fields.pixKeyType') === '05' && (
                    <InputMask
                      mask={MASK_UUID}
                      name="fields.pixKey"
                      label="PIX - Chave Aleatória"
                      disabled={previewMode}
                      hasError={
                        _.get(errors, 'fields.pixKey') &&
                        _.get(touched, 'fields.pixKey')
                      }
                    />
                  )}
                  {!_.get(values, 'fields.pixKeyType') && (
                    <InputLabel label={`Chave PIX`} value=" " />
                  )}
                </BsCol>
              </>
            )}

          {billType === 10 && _.get(values, 'layoutType') === 'cnab400' && (
            <>
              <BsCol md={24} lg={7}>
                <Select
                  name="fields.responsibleEmissionBill"
                  label={`Responsável pela emissão do boleto`}
                  disabled={previewMode}
                  options={{
                    values: [
                      { value: 'docpay', label: 'Docpay' },
                      { value: 'bank', label: 'Banco' },
                    ],
                  }}
                  onChange={(evt) => {
                    let value = _.get(evt, 'target.value')
                    setFieldValue('fields.responsibleEmissionBill', value)
                  }}
                />
              </BsCol>
              <BsCol md={24} lg={7}>
                <Select
                  name="fields.debitAutomatic"
                  label={`Débito automático`}
                  disabled={previewMode}
                  options={{
                    values: [
                      { value: 'no', label: 'Não' },
                      { value: 'yes', label: 'Sim' },
                    ],
                  }}
                  onChange={(evt) => {
                    let value = _.get(evt, 'target.value')
                    setFieldValue('fields.debitAutomatic', value)
                  }}
                />
              </BsCol>
              <BsCol md={24} lg={10}>
                <Select
                  name="fields.noticeAddress"
                  label={`Endereçamento para aviso`}
                  disabled={previewMode}
                  options={{
                    values: [
                      { value: '2', label: 'Não emite aviso' },
                      {
                        value: '1',
                        label: 'Emite aviso, e assume o endereço do pagador',
                      },
                      {
                        value: '0',
                        label:
                          'Emite aviso, e assume o endereço do cliente débitado',
                      },
                    ],
                  }}
                  onChange={(evt) => {
                    let value = _.get(evt, 'target.value')
                    setFieldValue('fields.noticeAddress', value)
                  }}
                />
              </BsCol>
            </>
          )}

          <ExtraFields
            settings={settings}
            values={values}
            errors={errors}
            touched={touched}
            fieldsPath="fields"
            previewMode={previewMode}
            layoutType={_.get(values, 'layoutType')}
          />

          <ApiFields
            settings={settings}
            bankIsDigital={bankIsDigital}
            values={values}
            errors={errors}
            touched={touched}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            previewMode={previewMode}
          />
        </BsRow>
      </Fieldset>

      <Fieldset label={`Cobrança - Boleto - Valores padrão`}>
        <BsRow>
          <BsCol md={8} lg={6}>
            <Select
              name="defaults.accept"
              label="Aceite"
              disabled={
                previewMode || (!_.get(values, 'layoutType') && !bankIsDigital)
              }
              value={_.get(values, 'defaults.accept') || ''}
              hasError={
                _.get(errors, 'defaults.accept') &&
                _.get(touched, 'defaults.accept')
              }
              options={{ values: acceptValues }}
            />
          </BsCol>

          <BsCol md={16} lg={18}>
            <Select
              name="defaults.documentType"
              label="Tipo de Documento"
              value={_.get(values, 'defaults.documentType') || ' '}
              disabled={
                previewMode || (!_.get(values, 'layoutType') && !bankIsDigital)
              }
              hasError={
                _.get(errors, 'defaults.documentType') &&
                _.get(touched, 'defaults.documentType')
              }
              options={{
                values: [
                  { value: '', label: 'Escolha o tipo de documento' },
                  ...allowedDocumentTypes,
                ],
              }}
            />
          </BsCol>

          <BsCol md={24} lg={24} xl={24}>
            <InputGroup
              type="text"
              name="defaults.paymentPlace"
              label="Local de Pagamento"
              maxLength={200}
              disabled={
                previewMode || (!_.get(values, 'layoutType') && !bankIsDigital)
              }
              hasError={
                _.get(errors, 'defaults.paymentPlace') &&
                _.get(touched, 'defaults.paymentPlace')
              }
            />
          </BsCol>

          {hasFine && (
            <>
              <BsCol md={12} lg={hasFineDays ? 6 : 12}>
                <CurrencyField
                  name="defaults.fineValue"
                  label={'Percentual de Multa'}
                  disabled={
                    previewMode ||
                    (!_.get(values, 'layoutType') && !bankIsDigital)
                  }
                  hasError={
                    _.get(errors, 'defaults.fineValue') &&
                    _.get(touched, 'defaults.fineValue')
                  }
                  onChange={(value) => {
                    setFieldValue(
                      'defaults.fineType',
                      value > 0 ? 'percentage' : 'none',
                    )
                  }}
                />
              </BsCol>

              {hasFineDays && (
                <BsCol md={12} lg={6}>
                  <InputGroup
                    type="number"
                    name="defaults.fineDays"
                    label="Dias para multa"
                    disabled={
                      previewMode ||
                      (!_.get(values, 'layoutType') && !bankIsDigital) ||
                      !(_.get(values, 'defaults.fineValue') > 0)
                    }
                    hasError={
                      _.get(errors, 'defaults.fineDays') &&
                      _.get(touched, 'defaults.fineDays')
                    }
                  />
                </BsCol>
              )}
            </>
          )}

          {hasInterest && (
            <>
              <BsCol md={12} lg={hasInterestDays ? 6 : 12}>
                <CurrencyField
                  name="defaults.interestValue"
                  precision={6}
                  label={'Percentual diário de juros de mora'}
                  disabled={
                    previewMode ||
                    (!_.get(values, 'layoutType') && !bankIsDigital)
                  }
                  hasError={
                    _.get(errors, 'defaults.interestValue') &&
                    _.get(touched, 'defaults.interestValue')
                  }
                  onChange={(value) => {
                    setFieldValue(
                      'defaults.interestType',
                      value > 0 ? 'dailyPercentage' : 'none',
                    )
                  }}
                />
              </BsCol>

              {hasInterestDays && (
                <BsCol md={12} lg={6}>
                  <InputGroup
                    type="number"
                    name="defaults.interestDays"
                    label="Dias para juros de mora"
                    disabled={
                      previewMode ||
                      (!_.get(values, 'layoutType') && !bankIsDigital) ||
                      !(_.get(values, 'defaults.interestValue') > 0)
                    }
                    hasError={
                      _.get(errors, 'defaults.interestDays') &&
                      _.get(touched, 'defaults.interestDays')
                    }
                  />
                </BsCol>
              )}
            </>
          )}

          {hasDiscount && (
            <>
              <BsCol md={12} lg={hasDiscountDays ? 6 : 12}>
                <CurrencyField
                  name="defaults.discountValue"
                  label={'Percentual de desconto'}
                  disabled={
                    previewMode ||
                    (!_.get(values, 'layoutType') && !bankIsDigital)
                  }
                  hasError={
                    _.get(errors, 'defaults.discountValue') &&
                    _.get(touched, 'defaults.discountValue')
                  }
                  onChange={(value) => {
                    setFieldValue(
                      'defaults.discountType',
                      value > 0 ? 'percentage' : 'none',
                    )

                    if (!value || value === 0) {
                      setFieldValue('defaults.discountDays', null)
                    }
                  }}
                />
              </BsCol>

              {hasDiscountDays && (
                <BsCol md={12} lg={6}>
                  <InputGroup
                    type="number"
                    name="defaults.discountDays"
                    label="Dias para desconto"
                    disabled={
                      previewMode ||
                      (!_.get(values, 'layoutType') && !bankIsDigital) ||
                      !(_.get(values, 'defaults.discountValue') > 0)
                    }
                    hasError={
                      _.get(errors, 'defaults.discountDays') &&
                      _.get(touched, 'defaults.discountDays')
                    }
                  />
                </BsCol>
              )}
            </>
          )}

          {hasProtestDaysMode && (
            <BsCol md={12} lg={6}>
              <Select
                name="defaults.protestDaysMode"
                label={`Tipo de dias para protesto`}
                disabled={
                  previewMode ||
                  (!_.get(values, 'layoutType') && !bankIsDigital)
                }
                hasError={
                  _.get(errors, 'defaults.protestDaysMode') &&
                  _.get(touched, 'defaults.protestDaysMode')
                }
                options={{
                  values: [
                    { value: '', label: 'Não protestar' },
                    { value: '1', label: 'Dias corridos' },
                    { value: '2', label: 'Dias úteis' },
                    { value: '0', label: 'Sem instrução' },
                  ],
                }}
                onChange={(evt) => {
                  let val = evt.target.value
                  setFieldValue('defaults.protestDaysMode', val)
                  if (!val) {
                    setFieldValue('defaults.protestDays', null)
                  }
                }}
              />
            </BsCol>
          )}

          {hasProtestDays &&
            /^(1|2)$/.test(_.get(values, 'defaults.protestDaysMode')) && (
              <BsCol md={12} lg={6}>
                <InputGroup
                  type="number"
                  name="defaults.protestDays"
                  label="Dias para Protesto"
                  disabled={
                    previewMode ||
                    (!_.get(values, 'layoutType') && !bankIsDigital)
                  }
                  maxLength={3}
                  hasError={
                    _.get(errors, 'defaults.protestDays') &&
                    _.get(touched, 'defaults.protestDays')
                  }
                />
              </BsCol>
            )}

          {hasWriteOffDays && (
            <BsCol md={12} lg={6}>
              <InputGroup
                type="number"
                name="defaults.writeOffDays"
                label="Dias para Baixa"
                disabled={
                  previewMode ||
                  (!_.get(values, 'layoutType') && !bankIsDigital)
                }
                maxLength={3}
                hasError={
                  _.get(errors, 'defaults.writeOffDays') &&
                  _.get(touched, 'defaults.writeOffDays')
                }
              />
            </BsCol>
          )}

          {_.get(values, 'bankAccount') && (
            <BsCol md={24}>
              <InstructionFieldForm
                settings={_.get(settings, `instructions.${layoutType}`) || {}}
                baseField="defaults"
                wallet={_.get(values, 'bank')}
                errors={errors}
                touched={touched}
                values={values}
                setFieldValue={setFieldValue}
                disabled={
                  previewMode ||
                  (!_.get(values, 'layoutType') && !bankIsDigital)
                }
              />
            </BsCol>
          )}

          <BsCol md={24} lg={24}>
            <RichEditor
              name="defaults.statement"
              placeholder="Demonstrativo"
              height={80}
              valueFormat="text"
              maxLength={1000}
              modules={{ toolbar: [] }}
              variables={slipVariables[10]}
              disabled={
                previewMode || (!_.get(values, 'layoutType') && !bankIsDigital)
              }
            />
          </BsCol>

          <BsCol md={24} lg={24}>
            <RichEditor
              name="defaults.instructions"
              placeholder="Instruções para o caixa"
              height={80}
              maxLength={1000}
              valueFormat="text"
              modules={{ toolbar: [] }}
              variables={slipVariables[10]}
              disabled={
                previewMode || (!_.get(values, 'layoutType') && !bankIsDigital)
              }
            />
          </BsCol>

          {hasRegisterTwo && layoutType === 'cnab400' && (
            <BsCol md={8} lg={6}>
              <Select
                name="defaults.registerTwo"
                label="Gerar registro 2 (opcional)"
                disabled={
                  previewMode ||
                  (!_.get(values, 'layoutType') && !bankIsDigital)
                }
                value={_.get(values, 'defaults.registerTwo') || ''}
                hasError={
                  _.get(errors, 'defaults.registerTwo') &&
                  _.get(touched, 'defaults.registerTwo')
                }
                options={{ values: acceptValues }}
              />
            </BsCol>
          )}
          {hasRegisterTwo && _.get(values, 'defaults.registerTwo') === 'true' && (
            <>
              <BsCol md={24} lg={24} xl={24}>
                <InputGroup
                  type="text"
                  name="defaults.message_1"
                  label="Mensagem 1"
                  maxLength={80}
                  disabled={
                    previewMode ||
                    (!_.get(values, 'layoutType') && !bankIsDigital)
                  }
                  hasError={
                    _.get(errors, 'defaults.message_1') &&
                    _.get(touched, 'defaults.message_1')
                  }
                />
              </BsCol>
              <BsCol md={24} lg={24} xl={24}>
                <InputGroup
                  type="text"
                  name="defaults.message_2"
                  label="Mensagem 2"
                  maxLength={80}
                  disabled={
                    previewMode ||
                    (!_.get(values, 'layoutType') && !bankIsDigital)
                  }
                  hasError={
                    _.get(errors, 'defaults.message_2') &&
                    _.get(touched, 'defaults.message_2')
                  }
                />
              </BsCol>
              <BsCol md={24} lg={24} xl={24}>
                <InputGroup
                  type="text"
                  name="defaults.message_3"
                  label="Mensagem 3"
                  maxLength={80}
                  disabled={
                    previewMode ||
                    (!_.get(values, 'layoutType') && !bankIsDigital)
                  }
                  hasError={
                    _.get(errors, 'defaults.message_3') &&
                    _.get(touched, 'defaults.message_3')
                  }
                />
              </BsCol>
              <BsCol md={24} lg={24} xl={24}>
                <InputGroup
                  type="text"
                  name="defaults.message_4"
                  label="Mensagem 4"
                  maxLength={80}
                  disabled={
                    previewMode ||
                    (!_.get(values, 'layoutType') && !bankIsDigital)
                  }
                  hasError={
                    _.get(errors, 'defaults.message_4') &&
                    _.get(touched, 'defaults.message_4')
                  }
                />
              </BsCol>

              <BsCol md={12} lg={6}>
                <CurrencyField
                  name="defaults.discountValue_2"
                  label={'Percentual de desconto 2'}
                  disabled={
                    previewMode ||
                    (!_.get(values, 'layoutType') && !bankIsDigital)
                  }
                  hasError={
                    _.get(errors, 'defaults.discountValue_2') &&
                    _.get(touched, 'defaults.discountValue_2')
                  }
                  onChange={(value) => {
                    setFieldValue(
                      'defaults.discountType_2',
                      value > 0 ? 'percentage' : 'none',
                    )

                    if (!value || value === 0) {
                      setFieldValue('defaults.discountDays_2', null)
                    }
                  }}
                />
              </BsCol>
              <BsCol md={12} lg={6}>
                <InputGroup
                  type="number"
                  name="defaults.discountDays_2"
                  label="Dias para desconto 2"
                  disabled={
                    previewMode ||
                    (!_.get(values, 'layoutType') && !bankIsDigital) ||
                    !(_.get(values, 'defaults.discountValue_2') > 0)
                  }
                  hasError={
                    _.get(errors, 'defaults.discountDays_2') &&
                    _.get(touched, 'defaults.discountDays_2')
                  }
                />
              </BsCol>

              <BsCol md={12} lg={6}>
                <CurrencyField
                  name="defaults.discountValue_3"
                  label={'Percentual de desconto 3'}
                  disabled={
                    previewMode ||
                    (!_.get(values, 'layoutType') && !bankIsDigital)
                  }
                  hasError={
                    _.get(errors, 'defaults.discountValue_3') &&
                    _.get(touched, 'defaults.discountValue_3')
                  }
                  onChange={(value) => {
                    setFieldValue(
                      'defaults.discountType_3',
                      value > 0 ? 'percentage' : 'none',
                    )

                    if (!value || value === 0) {
                      setFieldValue('defaults.discountDays_3', null)
                    }
                  }}
                />
              </BsCol>
              <BsCol md={12} lg={6}>
                <InputGroup
                  type="number"
                  name="defaults.discountDays_3"
                  label="Dias para desconto 3"
                  disabled={
                    previewMode ||
                    (!_.get(values, 'layoutType') && !bankIsDigital) ||
                    !(_.get(values, 'defaults.discountValue_3') > 0)
                  }
                  hasError={
                    _.get(errors, 'defaults.discountDays_3') &&
                    _.get(touched, 'defaults.discountDays_3')
                  }
                />
              </BsCol>
            </>
          )}
        </BsRow>
      </Fieldset>
    </>
  )
}

const renderAutomaticDebit = ({
  settings,
  values,
  errors,
  touched,
  previewMode,
}) => {
  return (
    <Fieldset label={`Cobrança - Débito automatico`}>
      <BsRow></BsRow>
    </Fieldset>
  )
}

const renderPix = ({ settings, values, errors, touched, previewMode }) => {
  return (
    <Fieldset label={`Cobrança - PIX`}>
      <BsRow></BsRow>
    </Fieldset>
  )
}

const renderTransfer = ({
  settings,
  bankIsDigital,
  billType,
  values,
  errors,
  touched,
  setFieldValue,
  hasEntityBind,
  onListEmailTemplates,
  previewMode,
}) => {
  const isAutoBind = (_.get(values,'fields.creditReceivedAutoBind') || 'no') === 'yes' ? true : false

  return (
    <Fieldset label={`Cobrança - Transferência`}>
      <BsRow>
        <BsCol md={24} lg={24}>
            <Autocomplete
              name="emailTemplate"
              keyField="id"
              label="Template (whatsapp)"
              disabled={previewMode}
              clearable={true}
              value={_.get(values, 'emailTemplate')}
              valueFormat={(row) => `${row.description}`}
              loadData={onListEmailTemplates}
              emptyText={'Pesquise um template (whatsapp)'}
              tipText={'Digite... '}
              loadingText={'Carregando...'}
              notFoundText={'Não encontrado'}
            />
          </BsCol>
      </BsRow>
      <BsRow>
        {!bankIsDigital && (
          <BsCol md={24} lg={12}>
            <Select
              name="fields.sendEmailMode"
              label={`Forma de determinar o evento "Transferência gerada" (Configurações de E-mail)`}
              disabled={previewMode}
              value={_.get(values, 'layoutType') || ''}
              hasError={
                _.get(errors, 'fields.sendEmailMode') &&
                _.get(touched, 'fields.sendEmailMode')
              }
              options={{
                values: [
                  { value: '', label: 'Não enviar e-mail' },
                  { value: 'saveBill', label: 'Após cadastrar uma transferência' },
                ],
              }}
            />
          </BsCol>
        )}
        <BsCol md={24} lg={12}>
          <InputGroup
            type="text"
            name="fields.creditReceivedControlNumber"
            label="Número de controle (Créditos recebidos)"
            disabled={previewMode}
            minLength="4"
            maxLength="4"
            hasError={
              _.get(errors, 'fields.creditReceivedControlNumber') &&
              _.get(touched, 'fields.creditReceivedControlNumber')
            }
          />
        </BsCol>
      </BsRow>
      <BsRow>
        <BsCol md={12} lg={8}>
          <Select
            name="fields.creditReceivedAutoBind"
            label={`Vincular créditos recebidos automático`}
            disabled={previewMode}
            value={_.get(values, 'fields.creditReceivedAutoBind') || ''}
            hasError={
              _.get(errors, 'fields.creditReceivedAutoBind') &&
              _.get(touched, 'fields.creditReceivedAutoBind')
            }
            options={{
              values: [
                { value: 'no', label: 'Não' },
                { value: 'yes', label: 'Sim' },
              ],
            }}
            onChange={(evt) => {
              setFieldValue('fields.creditReceivedAutoBind', evt.target.value)
              setFieldValue('fields.autoBindDaysBefore', 0)
              setFieldValue('fields.autoBindDaysAfter', 0)
            }}
          />
        </BsCol>
        { isAutoBind && (
          <>
            <BsCol md={8} lg={8}>
              <InputGroup
                type="number"
                min={0}
                name="fields.autoBindDaysBefore"
                label="Considerar dias antes do vencimento"
                disabled={previewMode}
                hasError={_.get(errors, 'fields.autoBindDaysBefore') && _.get(touched, 'fields.autoBindDaysBefore')}
                onChange={(evt) => {
                  const value = evt.target.value || 0
                  setFieldValue('fields.autoBindDaysBefore', value)
                }}
              />
            </BsCol>
            <BsCol md={8} lg={8}>
              <InputGroup
                type="number"
                min={0}
                name="fields.autoBindDaysAfter"
                label="Considerar dias após o vencimento"
                disabled={previewMode}
                hasError={_.get(errors, 'fields.autoBindDaysAfter') && _.get(touched, 'fields.autoBindDaysAfter')}
                onChange={(evt) => {
                  const value = evt.target.value || 0
                  setFieldValue('fields.autoBindDaysAfter', value)
                }}
              />
            </BsCol>
          </>
        )}
      </BsRow>
    </Fieldset>
  )
}

const renderEmptySettings = () => {
  return (
    <Fieldset label={`Atenção`}>
      <Line>Tipo de cobrança não implementado para este banco</Line>
    </Fieldset>
  )
}

export const ChargeFields = (props) => {
  const { billType } = props,
    settings = _.get(props, 'settings') || {}

  if ([10, 11].includes(billType)) {
    if (_.isEmpty(settings)) {
      return renderEmptySettings()
    }
    return renderSlipFields({ ...props, settings })
  } else if ([20].includes(billType)) {
    return renderAutomaticDebit({ ...props, settings })
  } else if ([30].includes(billType)) {
    return renderPix({ ...props, settings })
  } else if ([40].includes(billType)) {
    return renderTransfer({ ...props, settings })
  }
  return null
}
