import React, { useLayoutEffect } from 'react';
import _ from 'lodash';
import { Row, FlexRow, FlexCol, BsContainer, BsRow, BsCol } from '~/components/layout';
import { Formik } from 'formik';
import { isBefore, isAfter, addDays } from 'date-fns';
import { InputLabel, InputDate, InputGroup, CurrencyField } from '~/components/form';
import Fieldset from '~/components/fieldset';
import DataTable from '~/components/datatable';
import { IconButton } from '~/components/button';
import { MdCheck, MdClear } from 'react-icons/md';
import { FormContainer, TableContainer, Summary } from '~/pages/digital-charges/styles';
import { statusLabels } from '~/pages/digital-charges';
import { formats, barcodeToDigitableLine } from '~/helper';

const customStyles = {
  rows: {
    denseStyle: {
      minHeight: '30px'
    }
  }
};

const sumArray = list => {
  return _.sumBy(list, r => (isNaN(r.value) ? 0 : Number(r.value)));
};

const getInitialFilter = values => {
  const baseDate = _.get(values, 'dueDate');

  return {
    dueDateStart: addDays(formats.getDate(baseDate), -5),
    dueDateEnd: addDays(formats.getDate(baseDate), 5),
    identity: _.get(values, 'company.identity'),
    beneficiaryIdentity: _.get(values, 'beneficiaryIdentity'),
    guarantorIdentity: _.get(values, 'guarantorIdentity')
  };
};

function Form({ values, setFieldValue, previewMode, onListBankContracts, onListOrderDigitalCharge, listOrderDigitalCharge }) {
  const selecteds = _.get(values, 'orderSelection') || [];
  const selectedsRows = _.filter(listOrderDigitalCharge, r => selecteds.includes(r.id));
  const bindeds = _.get(values, 'bindeds') || [];
  const initialFilter = getInitialFilter(values);

  useLayoutEffect(() => {
    if (values.beneficiaryIdentity) {
      onListOrderDigitalCharge(initialFilter);
    }
    // eslint-disable-next-line
  }, [values.beneficiaryIdentity]);

  if (_.isEmpty(values)) {
    return null;
  }

  const changeSelecteds = item => {
    if (_.isArray(item)) {
      setFieldValue('orderSelection', _.map(item, 'id'));
      setFieldValue('orderSelectionSum', sumArray(item));
    } else {
      let list = _.cloneDeep(selecteds || []),
        index = _.findIndex(list, r => r === item.id),
        sum = _.get(values, 'orderSelectionSum') || 0;
      if (index >= 0) {
        sum -= item.value;
        list.splice(index, 1);
      } else {
        sum += item.value;
        list.push(item.id);
      }
      setFieldValue('orderSelection', list);
      setFieldValue('orderSelectionSum', sum);
    }
  };

  return (
    <FormContainer>
      <BsContainer>
        <BsRow>
          <BsCol md={4} sm={4}>
            <InputLabel label="Situação" value={statusLabels[_.get(values, 'status')] || ''} />
          </BsCol>

          <BsCol md={5} sm={5}>
            <InputLabel label="Valor" value={formats.currency(_.get(values, 'value'))} />
          </BsCol>

          <BsCol md={5} sm={5}>
            <InputLabel label="Data de emissão" value={formats.dateTimeZone(_.get(values, 'docDate'), 'dd/MM/yyyy')} />
          </BsCol>

          <BsCol md={5} sm={5}>
            <InputLabel label="Data de vencimento" value={formats.dateTimeZone(_.get(values, 'dueDate'), 'dd/MM/yyyy')} />
          </BsCol>

          <BsCol md={5} sm={5}>
            <InputLabel label="Data limite de pagamento" value={formats.dateTimeZone(_.get(values, 'limitDate'), 'dd/MM/yyyy')} />
          </BsCol>

          <BsCol md={19} sm={19}>
            <InputLabel label="Linha digitável" value={formats.digitableLine(barcodeToDigitableLine(_.get(values, 'barcode')))} />
          </BsCol>

          <BsCol md={5} sm={5}>
            <InputLabel label="Número documento" value={_.get(values, 'documentNumber')} />
          </BsCol>

          <BsCol md={24} sm={24}>
            <InputLabel
              label="Empresa"
              value={!_.get(values, 'company.identity') ? '' : `${formats.cnpj_cpf(_.get(values, 'company.identity'))} - ${_.get(values, 'company.name')}`}
            />
          </BsCol>

          <BsCol md={24} sm={24}>
            <InputLabel
              label="Beneficiário"
              value={
                !_.get(values, 'beneficiaryIdentity') ? '' : `${formats.cnpj_cpf(_.get(values, 'beneficiaryIdentity'))} - ${_.get(values, 'beneficiaryName')}`
              }
            />
          </BsCol>

          <BsCol md={24} sm={24}>
            <InputLabel
              label="Avalista"
              value={!_.get(values, 'guarantorIdentity') ? '' : `${formats.cnpj_cpf(_.get(values, 'guarantorIdentity'))} - ${_.get(values, 'guarantorName')}`}
            />
          </BsCol>

          {!previewMode && (
            <BsCol md={24} sm={24}>
              <Formik enableReinitialize={true} initialValues={initialFilter}>
                {formik => (
                  <Fieldset label="Cobranças eletrônicas vindas do ERP">
                    <Fieldset label="Filtro">
                      <FlexRow>
                        <FlexCol flex="1">
                          <InputGroup type="text" name="documentNumber" label="Número" maxLength={30} noMargin={true} />
                        </FlexCol>

                        <FlexCol flex="0 0 155px">
                          <InputDate
                            name="dueDateStart"
                            label="Vencimento de"
                            noMargin={true}
                            value={_.get(formik, 'values.dueDateStart')}
                            onChange={value => {
                              formik.setFieldValue('dueDateStart', value);
                              if (values.dueDateEnd && isAfter(value, values.dueDateEnd)) {
                                formik.setFieldValue('dueDateStart', values.dueDateEnd);
                                formik.setFieldValue('dueDateEnd', value);
                              }
                            }}
                          />
                        </FlexCol>

                        <FlexCol flex="0 0 155px">
                          <InputDate
                            name="dueDateEnd"
                            label="Vencimento até"
                            noMargin={true}
                            value={_.get(formik, 'values.dueDateEnd')}
                            onChange={value => {
                              formik.setFieldValue('dueDateEnd', value);
                              if (values.dueDateStart && isBefore(value, values.dueDateStart)) {
                                formik.setFieldValue('dueDateStart', value);
                                formik.setFieldValue('dueDateEnd', values.dueDateStart);
                              }
                            }}
                          />
                        </FlexCol>

                        <FlexCol flex="0 0 120px">
                          <CurrencyField name="valueStart" label="Valor de" noMargin={true} />
                        </FlexCol>

                        <FlexCol flex="0 0 120px">
                          <CurrencyField name="valueEnd" label="Valor até" noMargin={true} />
                        </FlexCol>

                        <FlexCol flex="0 0 73px">
                          <Row span={2} gap="1px" align="center" style={{ height: '46px' }}>
                            <IconButton
                              title="Limpar filtro"
                              noMargin={true}
                              onClick={() => {
                                formik.resetForm();
                              }}
                            >
                              <MdClear />
                            </IconButton>

                            <IconButton
                              title="Filtrar"
                              noMargin={true}
                              onClick={() => {
                                onListOrderDigitalCharge(formik.values);
                              }}
                            >
                              <MdCheck />
                            </IconButton>
                          </Row>
                        </FlexCol>
                      </FlexRow>
                    </Fieldset>

                    <TableContainer>
                      {!_.isEmpty(selectedsRows) && (
                        <Summary>
                          ({_.size(selectedsRows)}) {formats.currency(values.orderSelectionSum || 0)}
                        </Summary>
                      )}

                      <DataTable
                        emptyText="Sem resultados"
                        data={{ rows: listOrderDigitalCharge }}
                        noPagination={true}
                        onRowClicked={changeSelecteds}
                        hideUpdateButton={true}
                        columns={[
                          {
                            name: 'Empresa',
                            selector: 'identity',
                            compact: false,
                            format: row => formats.cnpj_cpf(_.get(row, 'company.identity'))
                          },
                          {
                            name: 'Parceiro',
                            selector: 'businessPartner',
                            compact: false,
                            format: row => formats.cnpj_cpf(_.get(row, 'businessPartner.identity'))
                          },
                          {
                            name: 'Documento',
                            selector: 'documentNumber',
                            format: row => _.get(row, 'documentNumber')
                          },
                          {
                            name: 'Data de vencimento',
                            selector: 'dueDate',
                            compact: true,
                            center: true,
                            width: '140px',
                            format: row => formats.dateTimeZone(_.get(row, 'dueDate'), 'dd/MM/yyyy')
                          },
                          {
                            name: 'Valor',
                            selector: 'value',
                            compact: false,
                            right: true,
                            format: row => formats.currency(_.get(row, 'value'))
                          }
                        ]}
                        extraOptions={{
                          customStyles,
                          ignoreRowClicked: true,
                          selectableRowsHighlight: false,
                          selectableRows: !previewMode,
                          selectableRowSelected: row => {
                            return _.find(selecteds, r => r === row.id);
                          },
                          onSelectedRowsChange: ({ allSelected, selectedCount, selectedRows }) => {
                            if (!previewMode) {
                              changeSelecteds(selectedRows);
                            }
                          }
                        }}
                      />
                    </TableContainer>
                  </Fieldset>
                )}
              </Formik>
            </BsCol>
          )}

          {previewMode && !_.isEmpty(bindeds) && (
            <BsCol md={24} sm={24}>
              <Fieldset label="Cobranças eletrônicas vinculadas">
                <TableContainer>
                  <DataTable
                    noPagination={true}
                    emptyText="Nenhuma cobrança vinculada"
                    onRowClicked={_.noop}
                    data={{ rows: bindeds }}
                    columns={[
                      {
                        name: 'Documento',
                        selector: 'documentNumber',
                        compact: false,
                        format: row => _.get(row, 'documentNumber')
                      },
                      {
                        name: 'Data de vencimento',
                        selector: 'dueDate',
                        compact: true,
                        center: true,
                        width: '140px',
                        format: row => formats.dateTimeZone(_.get(row, 'dueDate'), 'dd/MM/yyyy')
                      },
                      {
                        name: 'Valor',
                        selector: 'value',
                        compact: false,
                        right: true,
                        format: row => formats.currency(_.get(row, 'value'))
                      }
                    ]}
                    extraOptions={{
                      customStyles
                    }}
                  />
                </TableContainer>
              </Fieldset>
            </BsCol>
          )}
        </BsRow>
      </BsContainer>
    </FormContainer>
  );
}

export default Form;
