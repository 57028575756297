import * as TED from '~/pages/payment-orders/forms/ted';
import * as DOC from '~/pages/payment-orders/forms/doc';
import * as DepositoJudicial from '~/pages/payment-orders/forms/deposito-judicial';
import * as ContaCorrente from '~/pages/payment-orders/forms/conta-corrente';
import * as ContaPoupanca from '~/pages/payment-orders/forms/conta-poupanca';
import * as OrdemPagamento from '~/pages/payment-orders/forms/ordem-pagamento';
import * as Boletos from '~/pages/payment-orders/forms/boletos';
import * as ConcessionariasConta from '~/pages/payment-orders/forms/concessionarias-conta';
import * as TributoCodigoBarras from '~/pages/payment-orders/forms/tributo-codigo-barras';
import * as PIX from '~/pages/payment-orders/forms/pix';
import * as PixQrCode from '~/pages/payment-orders/forms/pix-qr-code';
import * as Darf from '~/pages/payment-orders/forms/darf';
import * as DarfSimples from '~/pages/payment-orders/forms/darf-simples';
import * as GPS from '~/pages/payment-orders/forms/gps';
import * as FGTS from '~/pages/payment-orders/forms/fgts';
import * as GareSp from '~/pages/payment-orders/forms/gare-sp';
import * as DARJ from '~/pages/payment-orders/forms/darj';
import * as IPVA from '~/pages/payment-orders/forms/ipva';
import * as DPVAT from '~/pages/payment-orders/forms/dpvat';
import * as Licenciamento from '~/pages/payment-orders/forms/licenciamento';
import * as CobrancaEletronica from '~/pages/payment-orders/forms/cobranca-eletronica';

export {
  TED,
  DOC,
  PIX,
  DepositoJudicial,
  ContaCorrente,
  ContaPoupanca,
  OrdemPagamento,
  Boletos,
  PixQrCode,
  ConcessionariasConta,
  TributoCodigoBarras,
  Darf,
  DarfSimples,
  GPS,
  FGTS,
  GareSp,
  IPVA,
  DPVAT,
  Licenciamento,
  DARJ,
  CobrancaEletronica,
}
;
