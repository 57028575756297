import React from 'react';
import { Autocomplete, InputGroup } from '~/components/form';
import FieldSet from '~/components/fieldset';
import { BsRow, BsCol } from '~/components/layout';
import _ from 'lodash';
import * as Yup from 'yup';

export const id = 15;

export const Render = ({ values, previewMode, errors, touched, setFieldValue, onListBanks }) => {
  const editLocked = _.get(values, 'editLocked') || false;
  return (
    <FieldSet label="Transferência - Depósito Judicial">
      <BsRow>
        <BsCol md={24} lg={24} xl={8}>
          <Autocomplete
            name="orderData.bank"
            minLength={0}
            keyField="code"
            label="Banco do crédito *"
            disabled={previewMode || editLocked}
            value={_.get(values, 'orderData.bank')}
            valueFormat={row => `${row.code} - ${row.name}`}
            loadData={onListBanks}
            emptyText={'Pesquise um banco'}
            tipText={'Digite... '}
            loadingText={'Carregando...'}
            notFoundText={'Não encontrado'}
            onChange={value => setFieldValue('orderData.bank', value)}
            hasError={
              _.get(errors, 'orderData.bank') &&
              _.get(touched, 'orderData.bank')
            }
          />
        </BsCol>

        <BsCol md={16} lg={8} xl={5}>
          <InputGroup
            type="text"
            name="orderData.bankBranch"
            label="Agência do crédito *"
            disabled={previewMode || editLocked}
            maxLength="10"
            hasError={_.get(errors, 'orderData.bankBranch') && _.get(touched, 'orderData.bankBranch')}
            />
        </BsCol>

        <BsCol md={8} lg={4} xl={3}>
          <InputGroup
            type="text"
            name="orderData.bankBranchDigit"
            label="Digito agência"
            disabled={previewMode || editLocked}
            maxLength="2"
            hasError={_.get(errors, 'orderData.bankBranchDigit') && _.get(touched, 'orderData.bankBranchDigit')}
            />
        </BsCol>

        <BsCol md={16} lg={8} xl={5}>
          <InputGroup
            type="text"
            name="orderData.accountNumber"
            label="Conta corrente *"
            disabled={previewMode || editLocked}
            maxLength="20"
            hasError={_.get(errors, 'orderData.accountNumber') && _.get(touched, 'orderData.accountNumber')}
            />
        </BsCol>

        <BsCol md={8} lg={4} xl={3}>
          <InputGroup
            type="text"
            name="orderData.accountNumberDigit"
            label="Digito conta *"
            disabled={previewMode || editLocked}
            maxLength="2"
            hasError={_.get(errors, 'orderData.accountNumberDigit') && _.get(touched, 'orderData.accountNumberDigit')}
            />
        </BsCol>
      </BsRow>

      <BsRow>
        <BsCol md={24} lg={24} xl={8}>
          <InputGroup
            type="text"
            name="orderData.depositId"
            label="Id do Depósito *"
            disabled={previewMode || editLocked}
            maxLength="20"
            hasError={_.get(errors, 'orderData.depositId') && _.get(touched, 'orderData.depositId')}
            />
        </BsCol>
      </BsRow>
    </FieldSet>
  )
}

export const Schema = (values) => ({
  orderData: Yup.object().typeError('Informe os dados da ordem').shape({
    bank: Yup.string().required('Selecione o banco'),
    bankBranch: Yup.string().required('Informe a agência'),
    accountNumber: Yup.string().required('Informe a conta de crédito'),
    accountNumberDigit: Yup.string().required('Informe o digito da conta'),
    depositId: Yup.string().required('Informe o Id do depósito'),
  })
});
