import { createActions, createReducer } from 'reduxsauce';
import produce from 'immer';
import _ from 'lodash';

export const { Types, Creators } = createActions({
  list: ['params'],
  listSuccess: ['data'],
  load: ['data'],
  loadSuccess: ['model'],
  downloadFile: ['data', 'actions'],
  downloadFileSuccess: ['data'],
  reprocessFile: ['data', 'actions'],
  reprocessFileSuccess: ['data'],
  formActionDone: [],
  clearModel: [],
}, { prefix: 'ediFile' });

const INITIAL_STATE = {
  loading: false,
  formLoading: false,
  term: null,
  model: null,
  permissions: [],
  data: { rows: [], count: 0, offset: 0 }
};

const handleList = (state = INITIAL_STATE, action) => produce(state, draft => {
  draft.term = action.term;
  draft.loading = true;
  draft.data = { rows: [], count: 0, offset: 0 }
});

const handleListSuccess = (state = INITIAL_STATE, action) => produce(state, draft => {
  draft.loading = false;
  draft.data = action.data;
});

const handleLoad = (state = INITIAL_STATE, action) => produce(state, draft => {
  draft.model = null;
  draft.formLoading = true;
});

const handleLoadSuccess = (state = INITIAL_STATE, action) => produce(state, draft => {
  draft.formLoading = false;
  draft.model = _.get(action, 'model.ediFile');
});

const downloadFile = (state = INITIAL_STATE, action) => produce(state, draft => {
  draft.formLoading = true;
});

const downloadFileSuccess = (state = INITIAL_STATE, action) => produce(state, draft => {
  draft.formLoading = false;
});

const reprocessFile = (state = INITIAL_STATE, action) => produce(state, draft => {
  draft.formLoading = true;
});

const reprocessFileSuccess = (state = INITIAL_STATE, action) => produce(state, draft => {
  draft.formLoading = false;
});

const handleClearModel = (state = INITIAL_STATE, action) => produce(state, draft => {
  draft.model = null;
  draft.formLoading = false;
});

const formActionDone = (state = INITIAL_STATE, action) => produce(state, draft => {
  draft.formLoading = false;
  draft.formLoadingMessage = '';
});

const formAction = message => (state = INITIAL_STATE, action) => produce(state, draft => {
  draft.formLoading = true;
  draft.formLoadingMessage = message;
});

export default createReducer(INITIAL_STATE, {
  [Types.LIST]: handleList,
  [Types.LIST_SUCCESS]: handleListSuccess,
  [Types.LOAD]: handleLoad,
  [Types.LOAD_SUCCESS]: handleLoadSuccess,
  [Types.DOWNLOAD_FILE]: downloadFile,
  [Types.DOWNLOAD_FILE_SUCCESS]: downloadFileSuccess,
  [Types.REPROCESS_FILE]: reprocessFile,
  [Types.REPROCESS_FILE_SUCCESS]: reprocessFileSuccess,
  [Types.DOWNLOAD_FILE]: formAction('Gerando Arquivo...'),
  [Types.FORM_ACTION_DONE]: formActionDone,
  [Types.CLEAR_MODEL]: handleClearModel,
});
