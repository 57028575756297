import Color from 'color';

export { Color };

// export const primary = Color('#29A8E0'); // 4C6A78 f46524 29A8E0 355665
export const primary = Color('#29A8E0'); // 4C6A78 f46524 068bc5 355665
export const secondary = Color('#ececec');
export const tertiary = Color('#ffffff');
export const quaternary = Color('#b2beb5');
export const quinary = Color('#e0e0e0');
export const senary = Color('#ffffff');
export const septenary = Color('#ffffff');
export const octanary = Color('#dedcf0');

export const black = Color('#000');
export const white = Color('#fff');
export const lightGray = Color('#f7f7f7');
export const gray = Color('#666');
export const green = Color('#77dd76');
export const red = Color('#ff6962');
export const blue = Color('#40d1ff');
export const orange = Color('#ff6600');
export const purple = Color('#ff00ff');

export const link = Color('#596077');
export const accent = Color('#ef4871');
