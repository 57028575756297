import React, { useLayoutEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MdCheck, MdClear, MdSave, MdCancel, MdOpenInNew, MdEditNote } from 'react-icons/md';
import { GrDocumentPdf } from 'react-icons/gr';
import { BiUnlink } from 'react-icons/bi';
import _ from 'lodash';
import { BASE_URL } from '~/constants';
import Crud from '~/components/crud';
import CellStatus from '~/components/datatable/cell-status';
import { Container, LeftContainer, Dashboard, DashboardGroup } from '~/pages/digital-charges/styles';
import { Creators as DigitalChargeActions } from '~/store/modules/digital-charge';
import { Creators as HeaderActions } from '~/store/modules/header';
import { formats, getColorByContrast, barcodeToDigitableLine } from '~/helper';
import confirm from '~/components/confirm';
import FormFilter from '~/pages/digital-charges/filter';
import Form from '~/pages/digital-charges/form';
import CellBank from '~/components/datatable/cell-bank';
import useSearchParameters from '~/hooks/use-search-params';
import PDF from '~/components/pdf';
import { toast } from 'react-toastify';

export const statusColors = {
  open: '#90d1ff',
  binded: '#a3d865',
  discarded: '#BA1F33',
}

export const statusLabels = {
  open: 'Aberta',
  binded: 'Gerada',
  discarded: 'Descartada',
}

export const columns = [
  {
    name: ' ',
    selector: 'status',
    width: '10px',
    cell: (row) => {
      let status = row.status || 'open'
      return (
        <CellStatus title={statusLabels[status]} color={statusColors[status]} />
      )
    },
  },
  {
    name: 'Banco',
    selector: 'barcode',
    width: '46px',
    cell: (row) => (
      <CellBank bankCode={String(_.get(row, 'barcode')).substr(0, 3)} />
    ),
  },
  {
    name: 'Empresa / Beneficiário',
    selector: 'company',
    wrap: true,
    hide: 'lg',
    cell: (row) => {
      const company = _.get(row, 'company') || {},
        name = _.get(row, 'beneficiaryName') || '',
        identity = _.get(row, 'beneficiaryIdentity')

      return (
        <table className="company-info">
          <tbody>
            <tr>
              <td width="120">{formats.cnpj_cpf(company.identity)}</td>
              <td title={company.name || company.tradeName}>
                {_.truncate(company.name || company.tradeName, { length: 50 })}
              </td>
            </tr>
            <tr>
              <td width="120">{formats.cnpj_cpf(identity)}</td>
              <td title={name}>{_.truncate(name, { length: 50 })}</td>
            </tr>
          </tbody>
        </table>
      )
    },
  },
  {
    name: 'Número documento',
    width: '140px',
    compact: true,
    selector: 'documentNumber',
    format: (row) => _.get(row, 'documentNumber'),
  },
  {
    name: 'Data emissão',
    selector: 'docDate',
    center: true,
    width: '105px',
    hide: 'lg',
    format: (row) => formats.dateTimeZone(_.get(row, 'docDate'), 'dd/MM/yyyy'),
  },
  {
    name: 'Vencimento',
    selector: 'dueDate',
    center: true,
    width: '105px',
    format: (row) => formats.dateTimeZone(_.get(row, 'dueDate'), 'dd/MM/yyyy'),
  },
  {
    name: 'Valor',
    width: '130px',
    right: true,
    compact: false,
    selector: 'value',
    format: (row) => {
      return `${formats.currency(row.value, 'pt-BR', 'BRL')}`
    },
  },
  {
    name: 'Linha digitável',
    selector: 'barcode',
    wrap: true,
    hide: 'lg',
    format: (row) =>
      formats.digitableLine(barcodeToDigitableLine(_.get(row, 'barcode'))),
  },
]

const detailTitle = (values = {}) => {
  let dateText = formats.dateTimeZone(values.dueDate, 'dd/MM/yyyy'),
    valueText = formats.currency(values.value, 'pt-BR', 'BRL')
  return `Cobrança eletrônica - ${dateText} - ${valueText}`
}

const customStyles = {
  rows: {
    denseStyle: {
      minHeight: '58px',
    },
  },
}

const formActions = (
  values,
  canWrite,
  onCancel,
  onSave,
  onReopen,
  handleGeneratePdf,
  OnUnlik,
  onOpenDocument,
) => {
  let actions = []

  if (!!values.id) {
    actions.push({
      label: 'Gerar PDF',
      icon: GrDocumentPdf,
      action: () =>
        handleGeneratePdf(
          _.pick(values, 'id', 'value', 'dueDate', 'businessPartner'),
        ),
    })
  }

  if (values.bindeds != null) {
    let canUnlink = true

    _.forEach(values.bindeds, (bind) => {
      if (!/^(open|pending)$/.test(bind.status)) {
        canUnlink = false
      }
    })

    if (canUnlink && canWrite) {
      actions.push({
        label: 'Desvincular do pagamento',
        icon: BiUnlink,
        action: OnUnlik,
      })
    }
  }

  if (canWrite && values.status === 'open') {
    actions.push({
      label: 'Descartar',
      icon: MdCancel,
      action: onCancel,
    })
    actions.push({
      label: 'Processar',
      icon: MdSave,
      action: onSave,
    })
  }
  if (canWrite && values.status === 'discarded') {
    actions.push({
      label: 'Mudar status para aberta',
      icon: MdEditNote,
      action: onReopen,
    })
  }
  if (values.paymentOrderId) {
    actions.push({
      label: 'Ver Ordem de Pagamento',
      icon: MdOpenInNew,
      action: onOpenDocument,
    })
  }
  return actions
}

const getColorByBgColor = (bgColor) => {
  return getColorByContrast(bgColor, '#fff', '#000')
}

const getElem = ({ label, value }) => {
  return () => (
    <span>
      <strong>{label}</strong>&nbsp;{value}
      <i style={{ padding: '0 5px' }}></i>
    </span>
  )
}

const getDivider = (array, breakLine) => {
  if (breakLine) {
    array.push(() => <span className="break"></span>)
  } else if (_.size(array) > 0) {
    array.push(() => <span className="spacing"></span>)
  }
}

const getDateFilterText = (startDate, endDate) => {
  if (startDate && endDate) {
    return `${formats.date(startDate)} até ${formats.date(endDate)}`
  } else if (startDate && !endDate) {
    return `a partir de ${formats.date(startDate)}`
  } else if (!startDate && endDate) {
    return `até ${formats.date(endDate)}`
  }
  return ''
}

const getSubtitle = (filter) => {
  let elements = [],
    company = _.get(filter, 'company')

  if (!_.isEmpty(company)) {
    getDivider(elements)
    elements.push(
      getElem({
        label: 'Empresa',
        value: `${formats.cnpj_cpf(company.identity)} ${company.name}`,
      }),
    )
  }
  if (!_.isEmpty(filter.beneficiaryIdentity)) {
    getDivider(elements)
    elements.push(
      getElem({
        label: 'Beneficiário',
        value: `${formats.cnpj_cpf(filter.beneficiaryIdentity)}`,
      }),
    )
  }

  if (filter.dueDateStart || filter.dueDateEnd) {
    getDivider(elements)
    elements.push(
      getElem({
        label: 'Data de vencimento',
        value: getDateFilterText(filter.dueDateStart, filter.dueDateEnd),
      }),
    )
  }
  if (filter.limitDateStart || filter.limitDateEnd) {
    getDivider(elements)
    elements.push(
      getElem({
        label: 'Data limite',
        value: getDateFilterText(filter.limitDateStart, filter.limitDateEnd),
      }),
    )
  }

  if (_.isEmpty(elements)) {
    return null
  }

  return _.map(elements, (Element, index) => <Element key={index} />)
}

export default function DigitalCharge({ history, match, acls }) {
  const canWrite = acls.includes('W');
  const dispatch = useDispatch();
  const state = useSelector(state => state.digitalCharge);
  const headerState = useSelector(state => state.header);
  const dashboard = _.get(state, 'data.summary') || {};
  const filter = _.get(headerState, 'filter.data');
  const statusSelected = _.get(filter, 'status') || 'open';
  const [openForm, setOpenForm] = useState(!!_.get(match, 'params.id'));
  const showFilter = headerState.filter.visible;
  const statusGroup = ['open', 'binded', 'discarded'];
  const cardSize = 250;

  const {
    location,
    routeParams,
    getAllParams,
    toStringify,
  } = useSearchParameters()

  useLayoutEffect(() => {
    const config = {
      loading: state.loading,
      useFilter: true,
      filter: {
        ...headerState.filter,
        visible: false,
      },
      subtitle: getSubtitle(filter || {})
    }
    dispatch(HeaderActions.configure(config))
    // eslint-disable-next-line
  }, [])

  useLayoutEffect(() => {
    const { id, offset, limit = 20, ...filter } = getAllParams()

    const listMethod =
      (_.isEmpty(id) && !showFilter) || _.size(_.get(state, 'data.rows')) === 0
        ? DigitalChargeActions.list
        : null
        
    if (id && !showFilter) {
      dispatch(DigitalChargeActions.load({ id }))
    }

    dispatch(
      HeaderActions.callFilter(
        { ...filter, offset: _.toInteger(offset), limit: _.toInteger(limit) },
        listMethod,
      ),
    )
    // eslint-disable-next-line
  }, [location, routeParams])

  function handleLoad(data) {
    const filter = getAllParams()
    history.push({
      pathname: `/digital-charges/${_.get(data, 'id', '')}`,
      search: `?${toStringify(
        _.omit(filter, [
          'id',
          'company',
          'bankAccount',
          'createdAtStart',
          'createdAtEnd',
          'dueDateStart',
          'dueDateEnd',
          'limitDateStart',
          'limitDateEnd'
        ]),
      )}`,
      state: filter,
    })
    setOpenForm(true)
  }

  function handlePageChange(offset, limit) {
    const filter = getAllParams()
    history.push({
      pathname: '/digital-charges',
      search: `?${toStringify({
        ..._.omit(filter, [
          'id',
          'company',
          'bankAccount',
          'createdAtStart',
          'createdAtEnd',
          'dueDateStart',
          'dueDateEnd',
          'limitDateStart',
          'limitDateEnd'
        ]),
        offset,
        limit,
      })}`,
      state: { ..._.omit(filter, 'id'), offset, limit },
    })
    setOpenForm(false)
  }

  function handleHideFilter() {
    const fromPage = _.get(location, 'state.from', false)
    dispatch(HeaderActions.hideFilter());
    dispatch(DigitalChargeActions.clearModel());
    setOpenForm(false);

    if(fromPage && (fromPage !== 'digital-charges')) {
      history.goBack();
    }
  }

  function handleFilter(filter) {
    const historySearch = {
      ..._.omit(filter, [
        'company',
        'bankAccount',
        'createdAtStart',
        'createdAtEnd',
        'dueDateStart',
        'dueDateEnd',
        'limitDateStart',
        'limitDateEnd'
      ]),
      offset: 0,
    }
    handleHideFilter()
    history.push({
      pathname: `/digital-charges`,
      search: `?${toStringify(historySearch)}`,
      state: filter,
    })
  }

  function handleListOrderDigitalCharge(params) {
    dispatch(DigitalChargeActions.listOrderDigitalCharge(params))
  }

  function handleListCompanies(term, callback) {
    dispatch(DigitalChargeActions.listCompanies(term, callback))
  }

  function handleListBankContracts(term, callback) {
    dispatch(DigitalChargeActions.listBankContracts(term, callback))
  }

  const handleGeneratePdf = async (data) => {
    if (data.id) {
      await PDF.show({
        title: `DDA - valor: ${formats.currency(
          _.get(data, 'value'),
        )}, vencimento: ${formats.dateTimeZone(_.get(data, 'dueDate'))}`,
        url: `${BASE_URL}/digital-charges/pdf/${data.id}/dda.pdf`,
      })
    }
  }

  const handleOpenDocument = (data) => {
    const filter = getAllParams()
    history.push({ pathname: `/payment-orders/${data.paymentOrderId}`,
      search: `?${toStringify(
        _.omit(filter, [
          'id',
          'company',
          'bankAccount',
          'createdAtStart',
          'createdAtEnd',
          'dueDateStart',
          'dueDateEnd',
          'limitDateStart',
          'limitDateEnd'
        ]),
      )}`,
      state: filter,
      sourcepath: 'digital-charges'})
  }

  function filterStatus(status) {
    handleFilter({ ...filter, status })
  }

  async function handleSave(data, actions) {
    let selection = _.get(data, 'orderSelection') || []

    if (_.isEmpty(selection) && data.allowProcessingDDaNoDocuments !== 'yes') {
      toast.error(
        'Não é possível processar cobrança eletrônica sem víncular documentos!',
      )
      return
    }

    let selectionSum = _.get(data, 'orderSelectionSum') || 0,
      options = {
        width: 450,
        title: 'Atenção',
        text: !_.isEmpty(selection)
          ? 'Deseja realmente processar a cobrança eletrônica?'
          : 'Essa cobrança eletrônica não está vinculada a fatura já existente. Será gerado uma ordem de pagamento sem vínculo. Deseja prosseguir?',
      }

    if (
      !_.isEmpty(selection) &&
      _.round(selectionSum, 2) !== _.round(data.value, 2)
    ) {
      let docText =
        _.size(selection) === 1
          ? 'do documento selecionado'
          : 'dos documentos selecionados'
      options.text = `O valor total ${docText} (${formats.currency(
        selectionSum,
      )}) difere do valor da cobrança eletrônica (${formats.currency(
        data.value,
      )}), deseja continuar?`
    }
    let result = await confirm.show(options)

    if (result) {
      dispatch(DigitalChargeActions.save(data, actions))
    }
  }

  async function handleReopen(data, actions) {
    let result = await confirm.show({
      width: 500,
      title: 'Atenção',
      text: `Deseja realmente alterar o status da cobrança eletrônica para "aberta"?`,
    })

    if (result) {
      dispatch(DigitalChargeActions.reopen(data, actions))
    }
  }

  async function handleCancel(data, actions) {
    let result = await confirm.show({
      width: 600,
      title: 'Atenção',
      text: `Deseja realmente descartar a cobrança eletrônica? Essa ação não poderá ser desfeita.`,
    })

    if (result) {
      dispatch(DigitalChargeActions.cancel(data.id, actions))
    }
  }

  async function handleUnlink(data, actions) {
    let result = await confirm.show({
      width: 600,
      title: 'Atenção',
      text: `Deseja realmente desvincular essa cobrança eletrônica da ordem de pagamento?`,
    })

    if (result) {
      dispatch(DigitalChargeActions.unlink(data.id, actions))
    }
  }

  const renderLeftComponent = ({ Table }) => {
    return (
      <LeftContainer>
        <Dashboard cardSize={cardSize}>
          <div className="section">
            {_.map(statusGroup, (sts, index) => (
              <DashboardGroup
                key={index}
                selected={statusSelected === sts}
                getCol={getColorByBgColor}
                color={statusColors[sts]}
                onClick={() => filterStatus(sts)}
              >
                <div className="sum">
                  <span>$ </span>
                  {formats.numberScale(_.get(dashboard, `${sts}.sum`))}
                </div>
                <div className="name">
                  <div className="square"></div>
                  {statusLabels[sts]}
                </div>
                <div className="count">
                  {formats.number(_.get(dashboard, `${sts}.count`))}
                </div>
              </DashboardGroup>
            ))}
          </div>
        </Dashboard>

        <div className="crud-table">{Table}</div>
      </LeftContainer>
    )
  }

  return (
    <Container>
      <Crud
        openForm={openForm || showFilter}
        useOpenForm={true}
        data={state.data}
        columns={columns}
        onRowClicked={handleLoad}
        onChangePage={handlePageChange}
        onCloseFilter={handleHideFilter}
        tableLoading={state.loading}
        formLoading={state.formLoading}
        formTitle={(row) => (showFilter ? 'Filtro' : detailTitle(row))}
        hideAdd={true}
        rightPadding="0"
        renderLeftComponent={renderLeftComponent}
        rightWidth={`${showFilter ? '500px' : '920px'}`}
        emptyText="Nenhuma cobrança eletrônica encontrada"
        formOptions={{
          onSubmit: handleFilter,
          initialValues: showFilter ? headerState.filter.data : state.model,
        }}
        renderForm={(args) =>
          showFilter ? (
            <FormFilter {...args} onListCompanies={handleListCompanies} />
          ) : (
            <Form
              {...args}
              previewMode={!canWrite || _.get(state, 'model.status') !== 'open'}
              onListBankContracts={handleListBankContracts}
              onListOrderDigitalCharge={handleListOrderDigitalCharge}
              listOrderDigitalCharge={state.listOrderDigitalCharge}
            />
          )
        }
        actions={
          !showFilter
            ? formActions(
                _.get(state, 'model') || {},
                canWrite,
                handleCancel,
                handleSave,
                handleReopen,
                handleGeneratePdf,
                handleUnlink,
                handleOpenDocument,
              )
            : [
                {
                  label: 'Limpar Filtro',
                  icon: MdClear,
                  action: () => handleFilter({ status: statusSelected }),
                },
                {
                  label: 'Aplicar Filtro',
                  icon: MdCheck,
                  action: (data) => handleFilter(data),
                },
              ]
        }
        dataTableOptions={{
          customStyles,
        }}
      />
    </Container>
  )
}
