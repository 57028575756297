import React from 'react';
import _ from 'lodash';
import { Select, Autocomplete } from '~/components/form';
import { FilterContainer } from '~/components/crud/styles';
import { formatBankAccountSimple, formatBankAccount } from '~/pages/bank-accounts/form';
import { formats } from '~/helper';

export default function FormFilter({ values, onListBankAccounts, onListCompanies }) {
  return (
    <FilterContainer>
      <Select
        name="movementType"
        label="Tipo"
        options={{ values: [
            { "value": "", "label": "Todos"},
            { "value": "charge", "label": "Cobrança"},
            { "value": "payment", "label": "Pagamento"}
          ] }}
          />

      <Select
        name="layoutType"
        label="Layout"
        options={{ values: [
            { "value": "", "label": "Todos"},
            { "value": "cnab240", "label": "CNAB240"},
            { "value": "cnab400", "label": "CNAB400"}
          ] }}
          />

      <Autocomplete
        name="bankAccount"
        keyField='id'
        label="Conta bancária"
        clearable={true}
        value={_.get(values, 'bankAccount')}
        valueFormat={formatBankAccountSimple}
        optionsFormat={(row) => formatBankAccount(row, 'Beneficiário')}
        loadData={onListBankAccounts}
        emptyText={'Pesquise uma conta bancária'}
        tipText={'Digite... '}
        loadingText={'Carregando...'}
        notFoundText={'Não encontrada'}
        />

      <Autocomplete
        name="company"
        keyField="id"
        label="Beneficiário"
        clearable={true}
        value={_.get(values, 'company')}
        valueFormat={row => `${formats.cnpj_cpf(row.identity)} - ${row.name || row.tradeName}`}
        loadData={onListCompanies}
        emptyText={'Pesquise um beneficiário'}
        tipText={'Digite... '}
        loadingText={'Carregando...'}
        notFoundText={'Não encontrada'}
        />
    </FilterContainer>
  );
}
