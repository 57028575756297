import React, { useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MdSave, MdCheck, MdClear } from 'react-icons/md'
import _ from 'lodash'
import { Creators as BankStatementActions } from '~/store/modules/bank-statement'
import { Creators as HeaderActions } from '~/store/modules/header'
import { Container } from '~/pages/bank-statements/styles'
import {
  BankStatementForm,
  BankStatementSchema,
} from '~/pages/bank-statements/form'
import FormFilter from '~/pages/bank-statements/filter'
import Crud from '~/components/crud'
import { formats } from '~/helper'
import CellBank from '~/components/datatable/cell-bank'
import CellStatus from '~/components/datatable/cell-status'
import { blue, purple } from '~/components/mixins/color'
import { SubtitleItem, Subtitle } from '~/components/datatable/subtitle'
import useSearchParameters from '~/hooks/use-search-params'

const statsColors = {
  processed: blue.hex(),
  processing: purple.hex(),
}

const columns = [
  {
    name: ' ',
    selector: 'status',
    width: '10px',
    cell: (row) => (
      <CellStatus title="" color={statsColors[row.status || 'processed']} />
    ),
  },
  {
    name: 'Nome do arquivo',
    selector: 'filename',
    hide: 'md',
  },
  {
    name: 'Layout',
    selector: 'layoutType',
    width: '100px',
    center: true,
    format: (row) => _.toUpper(_.get(row, 'layoutType')) || '',
  },
  {
    name: 'Agência / Conta',
    selector: 'bankAccount',
    width: '160px',
    left: true,
    cell: (row) => <CellBank bankAccount={_.get(row, 'bankAccount')} />,
  },
  {
    name: 'Empresa',
    selector: 'bankAccount',
    format: (row) => {
      let company = _.get(row, 'bankAccount.company') || {}
      return `${formats.cnpj_cpf(company.identity)} - ${company.name}`
    },
  },
  {
    name: 'Qtde. Registros',
    selector: 'linesCount',
    hide: 'md',
    width: '160px',
    center: true,
    format: (row) => formats.number(_.get(row, 'linesCount')) || '-',
  },
  {
    name: 'Importado por',
    selector: 'user',
    hide: 'md',
    width: '210px',
    format: (row) =>
      !_.get(row, 'user.name')
        ? '(Importado automaticamente)'
        : _.get(row, 'user.name') || '',
  },
  {
    name: 'Importado em',
    selector: 'createdAt',
    hide: 'md',
    width: '140px',
    format: (row) =>
      formats.dateTimeZone(_.get(row, 'createdAt'), 'dd/MM/yyyy HH:mm'),
  },
]

const customStyles = {
  rows: {
    denseStyle: {
      minHeight: '42px',
    },
  },
}

function BankStatement({ history, match, acls }) {
  const dispatch = useDispatch()
  const state = useSelector((state) => state.bankStatement)
  const headerState = useSelector((state) => state.header)
  const canWrite = acls.includes('W')
  const isNew = !_.get(state, 'model.id')
  const [openForm, setOpenForm] = useState(false)
  const showFilter = headerState.filter.visible

  const {
    location,
    routeParams,
    getAllParams,
    toStringify,
  } = useSearchParameters()

  useLayoutEffect(() => {
    const config = {
      loading: state.loading,
      useFilter: true,
      filter: {
        ...headerState.filter,
        visible: false,
      },
    }
    dispatch(HeaderActions.configure(config))
    // eslint-disable-next-line
  }, [])

  useLayoutEffect(() => {
    const { id, offset = 0, limit = 20, ...filter } = getAllParams()

    const listMethod =
      _.isEmpty(id) || _.size(_.get(state, 'data.rows')) === 0
        ? BankStatementActions.list
        : null

    if (id) {
      dispatch(BankStatementActions.load({ id }))
    }

    dispatch(
      HeaderActions.callFilter(
        { ...filter, offset: _.toInteger(offset), limit: _.toInteger(limit) },
        listMethod,
      ),
    )
    // eslint-disable-next-line
  }, [location, routeParams])

  function handleOnSubmit(data, actions) {
    dispatch(BankStatementActions.create(data, actions))
  }

  function handlePageChange(offset, limit) {
    const filter = getAllParams()
    history.push({
      pathname: '/bank-statements',
      search: `?${toStringify({
        ..._.omit(filter, [
          'id',
          'company',
          'bankAccount',
          'createdAtStart',
          'createdAtEnd',
        ]),
        offset,
        limit,
      })}`,
      state: { ..._.omit(filter, 'id'), offset, limit },
    })
    setOpenForm(false)
  }

  function handleLoad(data) {
    const filter = getAllParams(),
      id = _.get(data, 'id') || 'new'

    if (id === 'new') {
      delete filter.id
    }

    history.push({
      pathname: `/bank-statements/${_.get(data, 'id', '')}`,
      search: `?${toStringify(
        _.omit(filter, [
          'id',
          'company',
          'bankAccount',
          'createdAtStart',
          'createdAtEnd',
        ]),
      )}`,
      state: filter,
    })
    setOpenForm(true)
  }

  function handleHideFilter() {
    dispatch(HeaderActions.hideFilter())
    setOpenForm(false)
  }

  function handleFilter(filter) {
    const historySearch = {
      ..._.omit(filter, [
        'company',
        'bankAccount',
        'createdAtStart',
        'createdAtEnd',
      ]),
      offset: 0,
    }
    handleHideFilter()
    history.push({
      pathname: `/bank-statements`,
      search: `?${toStringify(historySearch)}`,
      state: filter,
    })
  }

  async function handleDownloadFile(data) {
    dispatch(BankStatementActions.downloadFile(data))
  }

  function handleListBankAccounts(term, callback) {
    dispatch(BankStatementActions.listBankAccounts(term, callback))
  }

  return (
    <Container>
      {!openForm && !showFilter && (
        <Subtitle bottom={40} left={100}>
          <span>Legenda:</span>
          <SubtitleItem color={statsColors['processing']}>
            Processando
          </SubtitleItem>
          <SubtitleItem color={statsColors['processed']}>
            Concluído
          </SubtitleItem>
        </Subtitle>
      )}

      <Crud
        useOpenForm={true}
        openForm={openForm || showFilter}
        hideAdd={!canWrite}
        columns={columns}
        emptyText="Nenhum extrato bancário encontrado"
        formTitle={(data) =>
          showFilter
            ? `Filtro`
            : _.get(data, 'id')
            ? `Extrato bancário (${data.filename})`
            : `Importar extrato bancário`
        }
        data={state.data}
        tableLoading={state.loading}
        formLoading={state.formLoading}
        onCloseFilter={handleHideFilter}
        onChangePage={handlePageChange}
        onRowClicked={handleLoad}
        rightWidth={`${isNew || showFilter ? 500 : 700}px`}
        formOptions={{
          initialValues: showFilter ? headerState.filter.data : state.model,
          validationSchema: showFilter ? undefined : BankStatementSchema,
        }}
        renderForm={(args) =>
          showFilter ? (
            <FormFilter {...args} onListBankAccounts={handleListBankAccounts} />
          ) : (
            <BankStatementForm
              {...args}
              loading={state.formLoading}
              previewMode={!canWrite}
              onListBankAccounts={handleListBankAccounts}
              onDownloadFile={handleDownloadFile}
            />
          )
        }
        actions={
          showFilter
            ? [
                {
                  label: 'Limpar Filtro',
                  icon: MdClear,
                  action: () => handleFilter({}),
                },
                {
                  label: 'Aplicar Filtro',
                  icon: MdCheck,
                  action: (data) => handleFilter(data),
                },
              ]
            : isNew && [
                {
                  label: 'Salvar',
                  icon: MdSave,
                  isSubmit: true,
                  isDisabled: () => !canWrite,
                  action: handleOnSubmit,
                },
              ]
        }
        dataTableOptions={{ customStyles }}
      />
    </Container>
  )
}

export default BankStatement
