import React from 'react'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import { FaChevronRight } from 'react-icons/fa'
import styled from 'styled-components'
import { gray } from '../mixins/color'

const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: ${(props) => props.hasSubtitle ? 'flex-end' : 'center'};
  flex-direction: row;
  font-size: 16px;
  color: ${gray.darken(0.2).hex()};

  svg {
    margin: 4px 5px;
  }
`

const Breadcrumb = ({ data, hasSubtitle }) => {
  const { location } = useHistory()
  const current = `/${String(location.pathname).split('/')[1]}`

  if (_.size(data) === 0) {
    return null
  }
  let found = _.find(_.flattenDeep(_.map(data, 'subNav')), { path: current })
  if (!found) {
    found = _.find(data, { path: current })
  }
  const group = _.get(found, 'group'),
    title = _.get(found, 'noBreadcrumb') ? '' : _.get(found, 'title'),
    selectedPath = _.kebabCase(group || 'home')

  return (
    <Container hasSubtitle={hasSubtitle}>
      {group !== selectedPath && (
        <>
          {group}
          <FaChevronRight size={10} />
        </>
      )}
      {title}
    </Container>
  )
}

export default Breadcrumb
