import React from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import Menu from '~/components/menu'
import Header from '~/components/header'
import Spinner from '~/components/spinner'
import { FaFilter } from 'react-icons/fa'
import { Wrapper, ActionsBar } from '~/pages/_layouts/default/styles'
import { Creators as HeaderActions } from '~/store/modules/header'
import { IconButton } from '~/components/button'
import _ from 'lodash'
import Breadcrumb from '~/components/header/breadcrumb'

export default function Default({ children, headLess, isLogin }) {
  const dispatch = useDispatch()
  const sidebarData = useSelector(
    (state) => _.get(state, 'auth.sidebarData') || [],
  )
  const header = useSelector((state) => state.header)
  const hideMenuAndHeader = headLess || isLogin
  const subTitle = _.get(header, 'subtitle')
  const headerSubTitle = _.filter(subTitle, (s) => _.has(s, '$$typeof')) || []

  function toogleFilter() {
    const act = header.filter.visible
      ? HeaderActions.hideFilter
      : HeaderActions.showFilter
    dispatch(act())
  }

  if (hideMenuAndHeader) {
    return (
      <Wrapper headLess={hideMenuAndHeader}>
        <div className="app-main">
          <div className="app-container">{children}</div>
        </div>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <Menu />
      <div className="app-main">
        <Header hasSubtitle={!!headerSubTitle}>
          <div className="main-title">
            <div className="title">
              <Breadcrumb data={sidebarData} hasSubtitle={!!headerSubTitle} />
            </div>

            {headerSubTitle && <div className="subtitle">{headerSubTitle}</div>}
          </div>

          <Spinner visible={header.loading} />

          <ActionsBar>
            {header.filter.use && (
              <IconButton
                color={header.filter.visible ? 'primary' : ''}
                unselected={!header.filter.visible}
                type="button"
                title="Filtro"
                onClick={toogleFilter}
              >
                <FaFilter />
              </IconButton>
            )}
          </ActionsBar>
        </Header>
        <div className="app-container">{children}</div>
      </div>
    </Wrapper>
  )
}

Default.propTypes = {
  children: PropTypes.element.isRequired,
}
