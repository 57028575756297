import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { startOfDay } from 'date-fns';
import { GrDocumentPdf } from 'react-icons/gr';
import {
  MdKeyboardBackspace,
  MdMonetizationOn,
  MdFileDownload,
  MdQueuePlayNext,
  MdMailOutline,
  MdDelete,
  MdSave,
  MdCancel,
  MdErrorOutline,
  MdAutorenew,
  MdFactCheck
} from 'react-icons/md';
import { IoWarningOutline } from 'react-icons/io5';
import { FaCheck, FaWhatsapp } from 'react-icons/fa';
import { VscSymbolOperator, VscOutput } from 'react-icons/vsc';
import { formats, onlyNumbers, validateEmail } from '~/helper';
import { Creators as HeaderActions } from '~/store/modules/header';
import { Creators as BillActions } from '~/store/modules/bill';
import * as Forms from '~/pages/bills/forms';
import confirm from '~/components/confirm';
import EmptyState from '~/components/empty-state';
import useModal from '~/hooks/use-modal';
import Fieldset from '~/components/fieldset';
import DataTable from '~/components/datatable';
import { BsContainer, BsRow, BsCol } from '~/components/layout';
import { IconButton } from '~/components/button';
import { InputGroup, InputLabel, InputDate, Autocomplete, FileUpload, CurrencyField } from '~/components/form';
import { formatBankContractCharge, formatBankContract } from '~/pages/bank-contracts/form';
import InstructionModal from '~/pages/bills/modal-instruction';
import ExtraModal from '~/pages/bills/modal-extra';
import BalancesModal from '~/pages/bills/modal-balances';
import { FormContainer, AttachmentTableContainer, FormToolbar, CellIcon, DetailTableContainer, TinyDetailTableContainer, Line } from '~/pages/bills/styles';
import { statusLabels } from '~/pages/bills';
import { BASE_URL } from '~/constants';
import PDF from '~/components/pdf';
import { Menu, MenuItem, MenuDivider } from '@szhsin/react-menu';
import CellStatus from '~/components/datatable/cell-status';
import { green } from '~/components/mixins/color';
import { SubtitleItem, Subtitle } from '~/components/datatable/subtitle';
import { docmentTypes } from '~/pages/credits-receiveds/index';

export const scopeTypes = {
  general: 'Geral',
  company: 'Empresa',
  bankContract: 'Contrato',
  businessPartnerGroup: 'Grupo de parceiro'
};

const statsColors = {
  success: green.hex(),
  error: '#BA1F33'
};

const SubForm = props => {
  let code = _.get(props, 'values.bankContract.billType.code'),
    found = _.find(Forms, { id: code }),
    El = _.get(found, 'Render');

  return El ? <El {...props} /> : null;
};

const getSubFormSchema = values => {
  let found = _.find(Forms, {
      id: _.get(values, 'bankContract.billType.code')
    }),
    schema = _.get(found, 'Schema');
  return schema ? schema(values) : {};
};

const customStyles = {
  rows: {
    denseStyle: {
      minHeight: '30px'
    }
  }
};

function BillDetail({ history, match, acls, location, headLess }) {
  const dispatch = useDispatch();
  const canWrite = acls.includes('W');
  const state = useSelector(state => state.bill);
  const headerState = useSelector(state => state.header);
  const [isOpenInstructionModal, toggleInstructionModal] = useModal();
  const [isOpenExtraModal, toggleExtraModal] = useModal();
  const [isOpenBalancesModal, toggleBalancesModal] = useModal();
  const canViewExtra = acls.includes('PE');

  const today = startOfDay(new Date());
  const generalConfig = _.get(state, 'generalConfig') || {};
  const loading = state.formLoading;
  const status = _.get(state, 'model.registered') && _.get(state, 'model.status') === 'open' ? 'registered' : _.get(state, 'model.status');

  const instructionsUpdatePdf = _.get(state, 'model.instructionsUpdatePdf') || false;
  const instructionChanges = _.get(state, 'model.instructionChanges') || {};
  const hasPdfUpdate = instructionsUpdatePdf && !instructionChanges.generated;
  const billType = _.get(state, 'model.bankContract.billType.code');
  const bankSettings = _.get(state, 'model.bankContract.bankAccount.bank.settings');
  const bankIsDigital = _.get(state, 'model.bankContract.bankAccount.bank.onlyApi');
  const previewMode = loading || !canWrite || (status && !/^(pending|open|rejected)$/.test(status));
  const editLocked = _.get(state, 'model.editLocked') || false;
  const canEdit = canWrite && !previewMode && !editLocked;
  const hasErpData = !!_.get(state, 'model.erpExtra.DatabaseId');
  const erpError = _.get(state, 'model.erpError');
  const allowOnlyInstructionsByERP = !(_.get(state, 'model.cfgData.allowOnlyInstructionsByERP') && hasErpData) || _.get(state, 'model.cfgData.withoutBalance');
  const hasBalanceData = !_.isEmpty(_.get(state, 'model.cfgData.balances'));
  const hasBalanceDecrement = _.get(state, 'model.cfgData.hasBalanceDecrement');
  const genMessages = [];
  const logsMessages = [];
  

  _.each(_.get(state, 'model.logs.messages') || [], (message, i) => logsMessages.push({ message, id: i }));
  _.each(_.get(state, 'model.generationMessages.errors') || [], message => genMessages.push({ message, type: 'error' }));
  _.each(_.get(state, 'model.generationMessages.warnings') || [], message => genMessages.push({ message, type: 'warning' }));

  const canCancel = /^(pending|open|rejected|registered)$/.test(status) && canWrite && allowOnlyInstructionsByERP;
  const canGeneratePdf =
    !/^(pending|open|rejected|canceled)$/.test(status) && [10].includes(billType) && _.get(state, 'model.id') && _.get(state, 'model.billData.generated');

  const canSendEmail =
    ((/^(registered|inShipping|sent)$/.test(status) && canGeneratePdf) || (/^(40)$/.test(billType) && _.get(state, 'model.id'))) &&
    (_.get(state, 'model.businessPartner.email') || _.size(_.get(state, 'model.businessPartner.extras.contacts')) >= 1);

  const canShareWhatsApp = (
    (/^(registered|inShipping|sent)$/.test(status) && canGeneratePdf) || // Boleto
    (/^(paid)$/.test(status)) // Transferência
  ) && _.get(state, 'model.businessPartner.phone') && [10, 40].includes(billType);
  const canRequestErpData = /^(pending|open)$/.test(status) && hasErpData;
  const canRunInstructions = canWrite && status === 'registered' && !bankIsDigital && allowOnlyInstructionsByERP;
  const canRemove = canWrite && status === 'registered' && _.get(state, 'model.legacyYourNumber');
  const canReopen = /^(canceled|rejected)$/.test(status) && canWrite && allowOnlyInstructionsByERP;
  const canSettle = /^(open)$/.test(status) && canWrite && [40].includes(billType); //&& hasErpData;

  if (erpError) {
    genMessages.unshift({ message: erpError, type: 'error' });
  }

  useEffect(() => {
    const { id } = _.get(match, 'params') || {};
    dispatch(BillActions.load(id));
  }, [dispatch, match, location.search]);

  useEffect(() => {
    const id = _.get(match, 'params.id'),
      config = {
        subtitle: `${id === 'new' ? 'Nova' : 'Edição da'} Fatura`,
        filter: { ...headerState.filter, visible: false, scope: '/bills' }
      };

    if (state && state.model) {
      dispatch(HeaderActions.configure(config));
    }
    // eslint-disable-next-line
  }, [state, dispatch, match]);

  const handleListBusinessPartners = (term, callback) => {
    dispatch(BillActions.listBusinessPartners(term, callback));
  };

  const handleListCompanies = (values, term, callback) => {
    let identity = _.get(values, 'bankContract.bankAccount.company.identity'),
      bindPolicy = _.get(values, 'bankContract.documentBindPolicy');

    dispatch(BillActions.listCompanies({ term, identity, bindPolicy }, callback));
  };

  const handleListBankContracts = (values, term, callback) => {
    let company = onlyNumbers(_.get(values, 'company.identity'));
    dispatch(BillActions.listBankContracts({ term, company }, callback));
  };

  async function handleDownloadAttachment(file) {
    let data = { id: _.get(state, 'model.id'), filename: file.name };
    dispatch(BillActions.downloadFile(data));
  }

  async function handleSendEmail(values, email) {
    dispatch(BillActions.checkBillIsInInstallments(values, email, showModalSendEmail));
  }

  async function showModalSendEmail(isInInstallments, email, billId) {
    const options = !isInInstallments
      ? null
      : [
          { label: 'Cancelar', value: false },
          { label: 'Todas as parcelas', value: 'allInstallments' },
          {
            label: 'Apenas parcela selecionada',
            value: 'singleInstallment',
            primary: true
          }
        ];
    const confirmProps = {
      width: 600,
      height: 200,
      title: 'Atenção',
      text: isInInstallments
        ? `Essa fatura está parcelada, como deseja enviá-la para o email ${email.replaceAll(/;/gi, ', ')}?`
        : `Deseja enviar essa fatura para o email ${email.replaceAll(/;/gi, ', ')}?`
    };

    if (options) {
      confirmProps.options = options;
    }

    if (email) {
      const result = await confirm.show(confirmProps);

      if (/(allInstallments|singleInstallment)/.test(result) || result) {
        const allInstallments = result === 'allInstallments';
        dispatch(BillActions.sendEmail({ ids: [billId], email, allInstallments }));
      }
    }
  }

  async function handleRequestErpData(values) {
    dispatch(BillActions.requestErpData({ id: values.id }));
  }

  const processInstruction = (data, action) => {
    action.toggleModal = toggleInstructionModal;
    dispatch(BillActions.processInstruction(data, action));
  };

  async function handleSendWhatsApp({ values, cellular }) {
    let phone = formats.phone(cellular);
    if (phone) {
      const result = await confirm.show({
        width: 600,
        title: 'Atenção',
        text: `Deseja compartilhar via Whatsapp essa fatura com o telefone ${phone}?`
      });

      if (result) {
        dispatch(BillActions.sendWhatsApp({ id: values.id, cellular }));
      }
    }
  }

  async function handleRemoveAttachment(file, setFieldValue, values, index) {
    const result = await confirm.show({
      title: 'Atenção',
      text: `Deseja realmente remover esse anexo?`
    });

    if (result && index >= 0) {
      let attachments = _.cloneDeep(_.get(values, 'attachments') || []);
      attachments.splice(index, 1);
      setFieldValue('attachments', attachments);
    }
  }

  const handleOnSubmit = async (form, justAttachments) => {
    let errors = await form.validateForm(),
      values = form.values,
      actions = { headLess };

    if (_.isEmpty(errors)) {
      let params = _.cloneDeep(justAttachments ? _.pick(values, 'id', 'files', 'attachments', 'emails') : values);
      params.justAttachments = !!justAttachments;
      dispatch(BillActions.createOrUpdate(params, actions));
    } else {
      let base = _.omit(errors, 'billData');
      if (_.isEmpty(base) && !_.isEmpty(errors.billData)) {
        base = errors.billData;
      }
      toast.error(_.first(_.values(base)) || 'Cadastro inválido');
    }
  };

  const handleOnRemove = async data => {
    const result = await confirm.show({
      title: 'Atenção',
      text: `Deseja realmente remover essa Fatura? Essa ação não poderá ser desfeita`
    });

    if (result) {
      dispatch(BillActions.remove(_.get(data, 'id')));
    }
  };

  const handleOnCancel = async data => {
    const result = await confirm.show({
      title: 'Atenção',
      text: `Deseja realmente cancelar essa Fatura? Essa ação não poderá ser desfeita`
    });

    if (result) {
      dispatch(BillActions.cancel(data));
    }
  };

  const handleOnReopen = async data => {
    const result = await confirm.show({
      title: 'Atenção',
      text: `Deseja realmente reabrir essa Fatura?`
    });

    if (result) {
      dispatch(BillActions.reopen(data));
    }
  };

  function applyDefaultValues(values, data, bankContract) {
    dispatch(BillActions.applyDefaultValues(values, data, bankContract));
  }

  const showPdf = data => {
    if (data.id) {
      PDF.show({
        title: `[${formats.currency(_.get(data, 'value'))}] - ${formats.cnpj_cpf(_.get(data, 'businessPartner.identity'))} - ${_.get(
          data,
          'businessPartner.name'
        )}`,
        url: `${BASE_URL}/bills/load-pdf/${data.id}/boleto.pdf`
      });
    }
  };

  const handleGeneratePdf = async (data, hasPdfUpdate) => {
    if (hasPdfUpdate) {
      const result = await confirm.show({
        title: 'Atenção',
        text: `Instruções vinculadas a essa fatura foram feitas e o PDF não foi atualizado, deseja atualizar agora?`
      });
      if (result) {
        dispatch(
          BillActions.regenerate({ id: _.get(data, 'id'), unsafe: hasPdfUpdate }, () => {
            showPdf(data);
          })
        );
      } else {
        showPdf(data);
      }
    } else {
      showPdf(data);
    }
  };

  const handleOnSettle = async form => {
    let errors = await form.validateForm(),
      values = form.values;

    if (_.isEmpty(errors)) {
      const result = await confirm.show({
        title: 'Atenção',
        text: `Deseja efetuar a baixa manual dessa fatura?`
      });
      if (result) {
        dispatch(BillActions.settle({ data: values }));
      }
    } else {
      let base = _.omit(errors, 'billData');
      if (_.isEmpty(base) && !_.isEmpty(errors.billData)) {
        base = errors.billData;
      }
      toast.error(_.first(_.values(base)) || 'Cadastro inválido');
    }
  };

  const getValidBillEmails = strEmails => {
    let result = [];
    if (strEmails) {
      const arrEmails = strEmails.replace(/\s/g, '').split(',');
      _.map(arrEmails, mail => {
        if (mail) {
          const validMail = validateEmail(mail);
          if (validMail) result.push(mail);
        }
      });
    }
    return result;
  };

  const EmailMenu = ({ values }) => {
    let businessPartner = _.get(values, 'businessPartner');
    let emails = _.get(businessPartner, 'extras.contacts') || [];
    let partnerEmail = _.get(businessPartner, 'email');
    let emailList = [];
    const billEmails = getValidBillEmails(_.get(values, 'emails')) || [];

    if (!_.isEmpty(partnerEmail)) {
      emailList.push({
        key: 'Principal',
        value: _.get(businessPartner, 'email')
      });
    }

    if (!_.isEmpty(emails)) {
      _.map(emails, contact => {
        if (_.get(contact, 'email', '') !== '' && _.get(contact, 'email', '').length >= 10) {
          emailList.push({
            key: _.get(contact, 'name'),
            value: _.get(contact, 'email')
          });
        }
      });
    }

    if (!_.isEmpty(billEmails)) {
      _.map(billEmails, (mail, index) => {
        emailList.push({
          key: `billMail${index + 1}`,
          value: mail
        });
      });
    }

    emailList = _.uniqBy(emailList, 'value');

    let selecteds = '';
    for (let email of emailList) {
      selecteds += ';' + email.value;
    }

    const [emailSelecteds, setEmailSelecteds] = useState(selecteds);

    return (
      <Menu
        direction="top"
        arrow="true"
        menuButton={
          <IconButton type="button" title="Enviar por e-mail">
            <MdMailOutline />
          </IconButton>
        }
      >
        {_.isEmpty(emailList) && (
          <MenuItem>
            <span>Nenhum e-mail encontrado.</span>
          </MenuItem>
        )}
        {!_.isEmpty(emailList) &&
          _.map(emailList, (cellEmailObj, index) => {
            return (
              <MenuItem
                type="checkbox"
                key={index}
                value={cellEmailObj.value}
                checked={_.size(emailSelecteds.split(';').filter(item => item === cellEmailObj.value)) === 1}
                onClick={e => {
                  var split = [];
                  if (e.checked) {
                    split = emailSelecteds.split(';');
                    split.push(e.value);
                  } else {
                    split = emailSelecteds.split(';');
                    split = split.filter(item => item !== e.value);
                  }
                  setEmailSelecteds(split.join(';'));
                  e.keepOpen = true;
                }}
              >
                <span>{cellEmailObj.value}</span>
              </MenuItem>
            );
          })}
        <MenuDivider />
        {!_.isEmpty(emailList) && (
          <MenuItem onClick={() => handleSendEmail(values, emailSelecteds.substring(1))}>
            <Line>
              <FaCheck />
              <span>Enviar para e-mails selecionados</span>
            </Line>
          </MenuItem>
        )}
      </Menu>
    );
  };

  const WhatsAppMenu = ({ values }) => {
    let businessPartner = _.get(values, 'businessPartner');
    let contacts = _.get(businessPartner, 'extras.contacts') || [];
    let partnerPhone = _.get(businessPartner, 'phone');
    let cellPhoneList = [];
    let isCustomer = _.get(businessPartner, 'isCustomer');
    let isSupplier = _.get(businessPartner, 'isSupplier');

    if (isCustomer && isSupplier) {
      contacts = _.filter(contacts, { isCustomer: true });
    }

    if (partnerPhone.length >= 10) {
      cellPhoneList.push({
        key: 'Principal',
        value: _.get(businessPartner, 'phone')
      });
    }

    if (!_.isEmpty(contacts)) {
      _.map(contacts, contact => {
        if (_.get(contact, 'cellular', '') !== '' && _.get(contact, 'cellular', '').length >= 10) {
          cellPhoneList.push({
            key: _.get(contact, 'name'),
            value: _.get(contact, 'cellular')
          });
        }
      });
    }

    return (
      <Menu
        direction="top"
        arrow="true"
        menuButton={
          <IconButton type="button" title="Compartilhar via whatsApp">
            {' '}
            <FaWhatsapp />{' '}
          </IconButton>
        }
      >
        {_.isEmpty(cellPhoneList) && (
          <MenuItem>
            <span>Nenhum número encontrado.</span>
          </MenuItem>
        )}
        {!_.isEmpty(cellPhoneList) &&
          _.map(cellPhoneList, (cellPhoneObj, index) => (
            <MenuItem key={index} value={cellPhoneObj.value} onClick={() => handleSendWhatsApp({ values, cellular: cellPhoneObj.value })}>
              <Line>
                <FaWhatsapp className="img" />
                <span>
                  {cellPhoneObj.key} - {formats.phone(cellPhoneObj.value)}
                </span>
              </Line>
            </MenuItem>
          ))}
      </Menu>
    );
  };

  const getDecrements = values => {
    const changeDecrements = _.get(values, 'instructionChanges.decrements'),
      billDecrements = _.get(values, 'decrements'),
      allowStatus = /^(sent|inShipping)$/.test(_.get(values, 'status')),
      pdfMode = _.get(values, 'cfgData.instructionsUpdatePdfMode');
    let totalDecrements = 0;

    if (allowStatus && pdfMode === 'unsafe') {
      totalDecrements = _.reduce(changeDecrements, (sum, n) => sum + _.get(n, 'value', 0), 0);
    } else {
      totalDecrements = _.reduce(billDecrements, (sum, n) => sum + _.get(n, 'value', 0), 0);
    }

    return totalDecrements > 0 ? formats.currency(totalDecrements) : '';
  };

  if (loading) {
    return <EmptyState visible={true} text="Carregando..." />;
  }

  if (!_.get(state, 'model.id') && _.get(match, 'params.id') !== 'new') {
    return <EmptyState visible={true} text="Fatura não encontrada!" />;
  }

  return (
    <Formik
      enableReinitialize={true}
      validateOnMount={false}
      validationSchema={() => Yup.lazy(values => Schema(getSubFormSchema(values)))}
      initialValues={state.model}
    >
      {props => {
        let { values, isValid, errors, touched, setFieldValue } = props,
          status = values.registered && values.status === 'open' ? 'registered' : values.status;

        let totalDecrements = getDecrements(values);

        return (
          <FormContainer headLess={headLess}>
            <BsContainer>
              <div className="main">
                <Fieldset label="Informações gerais">
                  <BsRow>
                    <BsCol md={8} lg={8}>
                      <Autocomplete
                        name="company"
                        keyField="id"
                        label="Emissor *"
                        disabled={hasErpData || previewMode || editLocked}
                        value={_.get(values, 'company')}
                        valueFormat={row => `${formats.cnpj_cpf(row.identity)} - ${row.name}`}
                        loadData={(term, callback) => handleListCompanies(values, term, callback)}
                        emptyText={'Selecione um emissor *'}
                        tipText={'Digite... '}
                        loadingText={'Carregando...'}
                        notFoundText={'Não encontrada'}
                      />
                    </BsCol>

                    <BsCol md={8} lg={8}>
                      <Autocomplete
                        name="businessPartner"
                        keyField="id"
                        label="Pagador *"
                        disabled={hasErpData || !_.get(values, 'company') || previewMode || editLocked}
                        value={_.get(values, 'businessPartner')}
                        valueFormat={row => `${formats.cnpj_cpf(row.identity)} - ${row.name}`}
                        loadData={handleListBusinessPartners}
                        emptyText={'Selecione um pagador *'}
                        tipText={'Digite... '}
                        loadingText={'Carregando...'}
                        notFoundText="Não encontrado"
                      />
                    </BsCol>

                    <BsCol md={8} lg={8}>
                      <Autocomplete
                        autoFocus={true}
                        name="bankContract"
                        keyField="id"
                        label="Contrato *"
                        tipText="Digite... "
                        loadingText="Carregando..."
                        notFoundText="Não encontrado"
                        emptyText="Informe um contrato"
                        disabled={!_.get(values, 'company') || previewMode || editLocked}
                        value={values.bankContract}
                        loadData={(term, callback) => handleListBankContracts(values, term, callback)}
                        valueFormat={formatBankContractCharge}
                        optionsFormat={r => formatBankContract(r, { billType: true })}
                        onChange={value => {
                          setFieldValue('billData', {});
                          setFieldValue('bankContract', value);
                          const contractConfig = _.get(value, 'defaults') || {};
                          applyDefaultValues(values, !_.isEmpty(contractConfig) ? contractConfig : generalConfig, value);
                        }}
                      />
                    </BsCol>

                    <BsCol md={8} lg={8} xl={4}>
                      <InputLabel label="Situação" value={statusLabels[status]} />
                    </BsCol>

                    <BsCol md={8} lg={8} xl={4}>
                      <InputGroup type="text" disabled={hasErpData || previewMode || editLocked} name="documentNumber" maxLength={20} label="Nº da fatura *" />
                    </BsCol>

                    <BsCol md={8} lg={8} xl={4}>
                      <InputDate
                        name="documentDate"
                        label="Data de emissão *"
                        hideErrorLabel={false}
                        disabled={hasErpData || previewMode || editLocked}
                        minDate={today}
                        hasError={_.get(errors, 'documentDate') && _.get(touched, 'documentDate')}
                      />
                    </BsCol>

                    <BsCol md={8} lg={8} xl={4}>
                      <InputDate
                        name="dueDate"
                        label="Data vencimento *"
                        hideErrorLabel={false}
                        disabled={hasErpData || previewMode || editLocked}
                        minDate={today}
                        hasError={_.get(errors, 'dueDate') && _.get(touched, 'dueDate')}
                      />
                    </BsCol>

                    <BsCol md={8} lg={8} xl={4}>
                      <CurrencyField
                        name="baseValue"
                        disabled={hasErpData || previewMode || editLocked}
                        label="Valor da fatura *"
                        hasError={errors.baseValue && touched.baseValue}
                        onChange={val => {
                          setFieldValue('baseValue', val);
                          setFieldValue('value', val);
                        }}
                      />
                    </BsCol>

                    <BsCol md={8} lg={8} xl={4}>
                      <CurrencyField
                        name="value"
                        disabled={(hasErpData && !/^(open|pending)/.test(status)) || previewMode || editLocked || hasBalanceDecrement || !allowOnlyInstructionsByERP}
                        label="Valor da cobrança *"
                        hasError={errors.value && touched.value}
                      />
                    </BsCol>

                    <BsCol md={4} lg={4} xl={4}>
                      <InputLabel label="Seu número" value={values.legacyYourNumber || values.yourNumber} />
                    </BsCol>

                    <BsCol md={4} lg={4} xl={4}>
                      <InputDate
                        name="payDate"
                        label="Data do pagamento"
                        hideErrorLabel={false}
                        disabled={!/^(open)/.test(status) || previewMode || editLocked || ![40].includes(billType)}
                        hasError={_.get(errors, 'payDate') && _.get(touched, 'payDate')}
                      />
                    </BsCol>

                    <BsCol md={8} lg={8} xl={8}>
                      <InputLabel label="Valor do pagamento" value={values.payAmount ? formats.currency(values.payAmount) : ''} />
                    </BsCol>

                    <BsCol md={4} lg={4}>
                      <InputLabel
                        label="Valor dos encargos"
                        value={values.interestValue || values.fineValue ? formats.currency((values.interestValue || 0) + (values.fineValue || 0)) : ''}
                      />
                    </BsCol>

                    <BsCol md={4} lg={4}>
                      <InputLabel label="Valor do abatimento" value={totalDecrements} />
                    </BsCol>

                    <BsCol md={24} lg={24}>
                      <InputGroup type="text" name="emails" label="E-mail(s) da fatura" disabled={loading || !canWrite} maxLength={1000} />
                    </BsCol>

                    <BsCol md={24} lg={24}>
                      <InputGroup type="text" name="notes" label="Observações" disabled={previewMode || editLocked} maxLength={1000} noMargin={true} />
                    </BsCol>
                  </BsRow>
                </Fieldset>

                {!_.isEmpty(genMessages) && (
                  <Fieldset label="Mensagens de Erros / Alertas">
                    <DetailTableContainer>
                      <DataTable
                        noPagination={true}
                        emptyText="Nenhuma mensagem encontrada"
                        hideUpdateButton={true}
                        data={{ rows: genMessages }}
                        onRowClicked={_.noop}
                        padding="0"
                        extraOptions={{
                          noTableHead: true
                        }}
                        columns={[
                          {
                            name: 'Tipo',
                            selector: 'type',
                            width: '22px',
                            cell: row => {
                              let isErr = row.type === 'error';
                              return <CellIcon color={isErr ? '#ff0000' : '#efd600'}> {isErr ? <MdErrorOutline /> : <IoWarningOutline />}</CellIcon>;
                            }
                          },
                          {
                            name: 'Mensagem',
                            selector: 'message',
                            format: row => row.message
                          }
                        ]}
                      />
                    </DetailTableContainer>
                  </Fieldset>
                )}

                <SubForm {...props} history={history} previewMode={previewMode || !_.get(values, 'bankContract') || editLocked} />

                <Fieldset label="Anexos">
                  <BsRow>
                    <BsCol md={24} lg={12}>
                      <AttachmentTableContainer>
                        <DataTable
                          emptyText="Nenhum anexo vinculado"
                          noPagination={true}
                          onRowClicked={_.noop}
                          data={{ rows: _.get(values, 'attachments') || [] }}
                          extraOptions={{
                            noTableHead: true,
                            customStyles
                          }}
                          columns={[
                            {
                              name: 'Arquivo',
                              selector: 'name',
                              format: row => _.get(row, 'name') || ''
                            },
                            {
                              name: 'Ação',
                              width: '80px',
                              center: true,
                              cell: (row, index) => (
                                <>
                                  <IconButton size={28} title="Baixar arquivo" noMargin={true} onClick={() => handleDownloadAttachment(row)}>
                                    <MdFileDownload />
                                  </IconButton>

                                  <IconButton
                                    size={28}
                                    title="Remover arquivo"
                                    noMargin={true}
                                    onClick={() => handleRemoveAttachment(row, setFieldValue, values, index)}
                                  >
                                    <MdDelete />
                                  </IconButton>
                                </>
                              )
                            }
                          ]}
                        />
                      </AttachmentTableContainer>
                    </BsCol>

                    <BsCol md={24} lg={12}>
                      <FileUpload
                        id="files"
                        name="files"
                        multiple={true}
                        noMargin={true}
                        height={164}
                        label="Arquivos selecionados"
                        disabled={!_.get(state, 'model.bankContract') || editLocked}
                        accept=".xml,.gif,.jpg,.jpeg,.png,.pdf,.zip,.rar,.xls,.xlsx,.doc,.docx,.ppt,.pptx"
                      />
                    </BsCol>
                  </BsRow>
                </Fieldset>

                {_.size(_.get(values, 'creditsReceiveds')) > 0 && (
                  <Fieldset label="Créditos recebidos vinculados">
                    <BsRow>
                      <BsCol md={24} lg={24}>
                        <DetailTableContainer>
                          <DataTable
                            emptyText="Nenhum crédito recebido vinculado"
                            noPagination={true}
                            onRowClicked={_.noop}
                            data={{
                              rows: _.get(values, 'creditsReceiveds') || []
                            }}
                            extraOptions={{
                              selectableRowsHighlight: false
                            }}
                            rowMinHeight="52px"
                            columns={[
                              {
                                name: 'Remetente',
                                selector: 'bankContract',
                                wrap: true,
                                hide: 'lg',
                                cell: row => {
                                  const name = _.get(row, 'senderName') || '',
                                    identity = _.get(row, 'senderIdentity');

                                  return (
                                    <table className="company-info">
                                      <tbody>
                                        <tr>
                                          <td width="120">{formats.cnpj_cpf(identity)}</td>
                                          <td title={name}>{_.truncate(name, { length: 50 })}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  );
                                }
                              },
                              {
                                name: 'Número controle',
                                width: '150px',
                                compact: true,
                                center: true,
                                selector: 'controlNumber',
                                format: row => _.get(row, 'controlNumber')
                              },
                              {
                                name: 'Número documento',
                                width: '180px',
                                compact: true,
                                center: true,
                                selector: 'docNumber',
                                format: row => _.get(row, 'docNumber')
                              },
                              {
                                name: 'Data lançamento',
                                selector: 'docDate',
                                center: true,
                                width: '105px',
                                hide: 'lg',
                                format: row => formats.dateTimeZone(_.get(row, 'docDate'), 'dd/MM/yyyy')
                              },
                              {
                                name: 'Valor',
                                width: '180px',
                                right: true,
                                compact: false,
                                selector: 'value',
                                format: row => {
                                  return `${formats.currency(row.value, 'pt-BR', 'BRL')}`;
                                }
                              },
                              {
                                name: 'Tipo',
                                selector: 'docType',
                                width: '130px',
                                hide: 'lg',
                                format: row => docmentTypes[_.get(row, 'docType')]
                              }
                            ]}
                          />
                        </DetailTableContainer>
                      </BsCol>
                    </BsRow>
                    <BsRow style={{ paddingLeft: '12px', height: '20px' }}>
                      <Subtitle position="unset">
                        <SubtitleItem color={statsColors['success']}>Sucesso</SubtitleItem>
                        <SubtitleItem color={statsColors['error']}>Erro</SubtitleItem>
                      </Subtitle>
                    </BsRow>
                  </Fieldset>
                )}

                {_.size(_.get(values, 'emailSends')) >= 1 && (
                  <Fieldset label="E-mails enviados">
                    <BsRow>
                      <BsCol md={24} lg={24}>
                        <DetailTableContainer>
                          <DataTable
                            emptyText="Nenhum e-mail enviado"
                            noPagination={true}
                            onRowClicked={_.noop}
                            data={{ rows: _.get(values, 'emailSends') || [] }}
                            extraOptions={{
                              selectableRowsHighlight: false
                            }}
                            columns={[
                              {
                                name: ' ',
                                selector: 'batch.id',
                                compact: true,
                                width: '10px',
                                cell: row => <CellStatus title="" color={statsColors[_.get(row, 'sendAt') != null ? 'success' : 'error']} />
                              },
                              {
                                name: 'Enviado em',
                                selector: 'createdAt',
                                width: '140px',
                                format: row => formats.dateTimeZone(_.get(row, 'createdAt'), 'dd/MM/yyyy HH:mm')
                              },
                              {
                                name: 'Autor',
                                selector: 'user.name',
                                width: '150px',
                                format: row => _.get(row, 'batch.user.name') || 'Automático'
                              },
                              {
                                name: 'E-mails',
                                selector: 'emailSettings',
                                wrap: true,
                                compact: true,
                                format: row => {
                                  try {
                                    return _.filter(_.get(row, 'emailSettings') || [], r => r.results)[0].results[0].envelope.to.join(', ') || '';
                                  } catch (err) {
                                    return '';
                                  }
                                }
                              },
                              {
                                name: 'Template',
                                selector: 'emailSettings',
                                wrap: true,
                                compact: true,
                                format: row => {
                                  let templatesList = _.filter(_.get(row, 'emailSettings') || [], r => !!r.description),
                                    templates = [];
                                  for (let row of templatesList) {
                                    templates.push(`${row.description} (${scopeTypes[row.scope]})`);
                                  }
                                  return templates.join(', ');
                                }
                              },
                              {
                                name: 'Descrição',
                                selector: 'sendAt',
                                width: '180px',
                                format: row => (_.get(row, 'sendAt') != null ? 'Enviado com sucesso' : _.get(row, 'error'))
                              }
                            ]}
                          />
                        </DetailTableContainer>
                      </BsCol>
                    </BsRow>
                    <BsRow style={{ paddingLeft: '12px', height: '20px' }}>
                      <Subtitle position="unset">
                        <SubtitleItem color={statsColors['success']}>Sucesso</SubtitleItem>
                        <SubtitleItem color={statsColors['error']}>Erro</SubtitleItem>
                      </Subtitle>
                    </BsRow>
                  </Fieldset>
                )}

                {!_.isEmpty(logsMessages) && (
                  <Fieldset label="Log de Ações">
                    <TinyDetailTableContainer>
                      <DataTable
                        noPagination={true}
                        emptyText="Nenhum log encontrado"
                        hideUpdateButton={true}
                        data={{ rows: logsMessages }}
                        onRowClicked={_.noop}
                        padding="0"
                        extraOptions={{
                          noTableHead: true
                        }}
                        columns={[
                          {
                            name: 'Mensagem',
                            selector: 'message',
                            format: row => row.message
                          }
                        ]}
                      />
                    </TinyDetailTableContainer>
                  </Fieldset>
                )}
              </div>

              <FormToolbar>
                <div className="buttons">
                  {!headLess && (
                    <IconButton type="button" title="Voltar" disabled={loading} onClick={() => history.go(-1)}>
                      <MdKeyboardBackspace />
                    </IconButton>
                  )}

                  {canRemove && !editLocked && (
                    <IconButton type="button" title="Remover" disabled={loading} onClick={() => handleOnRemove(values)}>
                      <MdDelete />
                    </IconButton>
                  )}

                  {canRunInstructions && !editLocked && (
                    <IconButton type="button" title="Instruções" disabled={loading} onClick={toggleInstructionModal}>
                      <VscSymbolOperator />
                    </IconButton>
                  )}

                  {hasBalanceData && (
                    <IconButton type="button" title="Alterações de Valor" disabled={loading} onClick={toggleBalancesModal}>
                      <MdFactCheck />
                    </IconButton>
                  )}

                  {canViewExtra && hasErpData && (
                    <IconButton type="button" title="Dados Externos" disabled={loading} onClick={toggleExtraModal}>
                      <VscOutput />
                    </IconButton>
                  )}

                  {canRequestErpData && !editLocked && (
                    <IconButton type="button" title="Solicitar dados do ERP" disabled={loading} onClick={() => handleRequestErpData(values)}>
                      <MdQueuePlayNext />
                    </IconButton>
                  )}

                  {canSendEmail && <EmailMenu values={values} />}

                  {canShareWhatsApp && <WhatsAppMenu values={values} />}

                  {canCancel && !editLocked && (
                    <IconButton type="button" title="Cancelar" disabled={!isValid || loading} onClick={() => handleOnCancel(values)}>
                      <MdCancel />
                    </IconButton>
                  )}

                  {canGeneratePdf && (
                    <IconButton
                      type="button"
                      title="Gerar boleto"
                      disabled={loading}
                      color={hasPdfUpdate ? 'errorText' : ''}
                      onClick={() => handleGeneratePdf(values, hasPdfUpdate)}
                    >
                      <GrDocumentPdf />
                    </IconButton>
                  )}

                  {canReopen && !editLocked && (
                    <IconButton type="button" title="Reabrir" disabled={loading} onClick={() => handleOnReopen(values)}>
                      <MdAutorenew />
                    </IconButton>
                  )}

                  {canEdit && !editLocked && (
                    <IconButton type="button" title="Salvar" disabled={loading} onClick={() => handleOnSubmit(props)}>
                      <MdSave />
                    </IconButton>
                  )}

                  {!canEdit && canWrite && !editLocked && (
                    <IconButton type="button" title="Salvar anexos/e-mails" disabled={loading} onClick={() => handleOnSubmit(props, true)}>
                      <MdSave />
                    </IconButton>
                  )}
                  {canSettle && !editLocked && (
                    <IconButton type="button" title="Baixa Manual" disabled={loading} onClick={() => handleOnSettle(props)}>
                      <MdMonetizationOn />
                    </IconButton>
                  )}
                  {/*
                  <IconButton type="button" color="error" title="Gerar PDF" onClick={() => {
                    dispatch(BillActions.regenerate({ id: _.get(state, 'model.id') }));
                    }}>
                    PP
                  </IconButton>
                  */}
                </div>
              </FormToolbar>
              {isOpenInstructionModal && (
                <InstructionModal
                  loading={state.modalLoading}
                  isOpen={isOpenInstructionModal}
                  toggleModal={toggleInstructionModal}
                  handleOnSubmit={processInstruction}
                  parent={values}
                  settings={bankSettings}
                />
              )}
              {isOpenExtraModal && <ExtraModal loading={state.modalLoading} isOpen={isOpenExtraModal} toggleModal={toggleExtraModal} parent={values} />}
              {isOpenBalancesModal && <BalancesModal loading={state.modalLoading} isOpen={isOpenBalancesModal} toggleModal={toggleBalancesModal} parent={values} />}
            </BsContainer>
          </FormContainer>
        );
      }}
    </Formik>
  );
}

const Schema = subSchema => {
  const validadeBillEmails = strEmails => {
    let result = true;

    const arrEmails = strEmails.replace(/\s/g, '').split(',');
    _.map(arrEmails, mail => {
      if (mail) {
        const validMail = validateEmail(mail);
        if (!validMail) result = false;
      }
    });
    return result;
  };

  return Yup.object().shape({
    //bankContract: Yup.mixed().required('Informe o contrato'),
    businessPartner: Yup.mixed().required('Informe o pagador'),
    documentNumber: Yup.mixed().required('Informe o número do documento'),
    dueDate: Yup.string()
      .typeError('Informe a data de vencimento')
      .required('Informe a data de vencimento'),
    value: Yup.mixed().test('match', '', function(value) {
      const { path, createError, parent } = this;

      if (!value || value < 0) {
        return createError({
          path,
          message: 'Informe um valor de cobrança deve ser informado e maior que zero'
        });
      }
      if (value && value > parent.baseValue) {
        return createError({
          path,
          message: 'O valor de cobrança deve ser inferior ao valor da fatura'
        });
      }
      return true;
    }),
    baseValue: Yup.mixed().test('match', '', function(value) {
      const { path, createError } = this;
      if (!value || value < 0) {
        return createError({
          path,
          message: 'Informe um valor da fatura deve ser informado e maior que zero'
        });
      }
      return true;
    }),
    emails: Yup.mixed().test('match', '', function(value) {
      const { path, createError } = this;
      if (value) {
        const validMail = validadeBillEmails(value);
        if (!validMail) {
          return createError({
            path,
            message: 'Campo "E-mail(s) da fatura" possui e-mail inválido'
          });
        }
      }
      return true;
    }),
    payDate: Yup.mixed().test('match', '', function(value) {
      const { path, createError, parent } = this;

      let documentDate = new Date(_.get(parent, 'documentDate')).getTime(),
          payDate = value ? new Date(value).getTime() : 0;

      if(value && documentDate > payDate) {
        return createError({ path, message: 'A data de pagamento deve ser posterior ou igual à data de emissão' })
      }

      return true;
    }).nullable(),
    ...subSchema
  });
};

export default BillDetail;
