import { all, put, call, takeLatest, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '~/services/api';
import { formats } from '~/helper';
import { getError } from '~/helper';
import { Creators as Actions, Types } from '~/store/modules/prepare-payment-order';
import _ from 'lodash';
import { allowedOrdersTypes } from '../../../pages/payment-orders/constants'

function* getFilterData() {
  const headerState = yield select(state => state.header);
  return _.get(headerState, 'filter.data') || {};
}

export function showError(error) {
  const msg = getError(error);
  toast.error(msg);
}

export function* load({ params }) {
  try {
    const response = yield call(api.post, 'prepare-payment-orders/load', { ...params });
    yield put(Actions.loadSuccess(response.data));
  } catch (error) {
    showError(error);
  }
}

export function* getCompanies() {
  try {
    const response = yield call(api.get, 'prepare-payment-orders/companies');
    yield put(Actions.getCompaniesSuccess(response.data));
  } catch (error) {
    showError(error);
  }
}

export function* listCompanies({ term, callback }) {
  try {
    const response = yield call(api.get, 'prepare-payment-orders/list-companies', {
      params: { term }
    });
    yield call(callback, response.data);
  } catch (error) {}
}

export function* listPaymentOrderType({ term, callback }) {
  try {
    const response = yield call(api.get, 'prepare-payment-orders/list-payment-order-type', { params: { term } });
    yield call(callback, response.data);
  } catch (error) {}
}

export function* listBankAccounts() {
  try {
    const response = yield call(api.get, 'prepare-payment-orders/list-bank-accounts');
    yield put(Actions.listBankAccountsSuccess(response.data));
  } catch (error) {
    showError(error);
  }
}

export function* listBusinessPartners({ term, callback }) {
  try {
    const response = yield call(api.get, 'prepare-payment-orders/list-business-partners', { params: { term } });
    yield call(callback, response.data);
  } catch (error) {}
}

export function* listBankContracts({ params, callback }) {
  try {
    const response = yield call(api.get, 'prepare-payment-orders/list-bank-contracts', { params });
    yield call(callback, response.data);
  } catch (error) {}
}

const checkContractPolicy = (bankContract, items) => {
  let errorMessage = '',
    dstPolicy = _.get(bankContract, 'bankContract.documentBindPolicy') || 'any',
    dstIdentity = _.get(bankContract, 'company.identity'),
    dstIdentityBase = String(dstIdentity).substr(0, 8);

  for (let item of items) {
    let identity = _.get(item, 'company.identity'),
      identityBase = String(identity).substr(0, 8);

    if (dstPolicy === 'baseCnpj' && dstIdentityBase !== identityBase) {
      errorMessage = `Contrato destino aceita apenas faturas de CNPJs com raiz ${dstIdentityBase}`;
      return { allowByContract: false, errorMessage };
    } else if (dstPolicy === 'cnpj' && dstIdentity !== identity) {
      errorMessage = `Contrato destino aceita apenas faturas do CNPJ ${formats.cnpj_cpf(dstIdentity)}`;
      return { allowByContract: false, errorMessage };
    }
  }
  return { allowByContract: true };
};

export function* movement({ oldData, data, bankAccounts }) {
  try {
    const { source, destination } = data;
    const previewData = _.cloneDeep(oldData);
    const sourceItems = _.get(previewData, source.droppableId) || [];
    const [updated] = sourceItems.splice(source.index, 1);
    const bankAccount = _.get(bankAccounts, _.get(destination, 'droppableId'));
    const banks = _.get(updated, 'paymentOrderType.banks') || [];
    const paymentOrderType = _.get(updated, 'paymentOrderType.name') || [];
    const bankCode = _.get(bankAccount, 'code');
    const bankLabel = _.get(bankAccount, 'label');
    const allowMovement = banks.includes(bankCode);
    const extrasBankState = _.get(updated, 'paymentOrderType.extras.bankState');
    const bankStates = _.get(updated, `paymentOrderType.extras.bankState[${bankCode}]`) || [];
    const allowedStates = !extrasBankState || bankStates.includes(_.get(updated, 'orderData.state.value'));
    const dstBankContract = _.get(bankAccounts, `${_.get(destination, 'droppableId')}.extra`);
    const { allowByContract, errorMessage } = checkContractPolicy(dstBankContract, [updated]);

    if (!allowByContract) {
      showError({ error: { message: errorMessage } });
      return;
    }
    if (bankCode !== '000' && (!allowMovement || !allowedStates)) {
      showError({ error: { message: `A orderm de pagamento do tipo ${paymentOrderType} não é suportada por ${bankLabel}` } });
      return;
    }
    let digitableLine = _.get(updated, 'orderData.digitableLine'),
      company = _.get(updated, 'company'),
      businessPartner = _.get(updated, 'businessPartner');

    if (_.get(updated, 'paymentOrderType.code') === 20 && digitableLine) {
      _.set(updated, 'entryType', bankCode === String(digitableLine).substring(0, 3) ? '30' : '31');
    }
    if (_.get(updated, 'paymentOrderType.code') === 10 && company && businessPartner) {
      let cmpIdentity = _.get(company, 'identity'),
        bpIdentity = _.get(businessPartner, 'identity');
      _.set(updated, 'entryType', bpIdentity === cmpIdentity ? '43' : '41');
    }

    if (bankAccount && source.droppableId !== destination.droppableId) {
      updated.bankAccountId = bankAccount.id;
      updated.bankContractId = _.get(bankAccount, 'extra.bankContract.id');

      const destItems = _.get(previewData, destination.droppableId) || [];
      destItems.splice(destination.index, 0, updated);

      const preparedData = {
        ...previewData,
        [source.droppableId]: [...sourceItems],
        [destination.droppableId]: [...destItems]
      };

      let ordersType = _.get(dstBankContract, 'bank.settings.payment.ordersType');

      let ordersTypesMap = allowedOrdersTypes(ordersType);

      applyDefaultValues(updated, _.get(updated, 'paymentOrderType.code'), ordersTypesMap, ordersType);

      yield put(Actions.movementSuccess(preparedData));
      yield call(api.put, `prepare-payment-orders/change-bank-account/${updated.id}`, _.pick(updated, 'bankAccountId', 'bankContractId', 'entryType', 'serviceType'));
    }
  } catch (error) {
    showError(error);
    yield put(Actions.load(yield call(getFilterData)));
  }
}

const applyDefaultValues = (updated, code, ordersTypesMap) => {
  let defaults = getList(code, 'defaults', ordersTypesMap) || {};
  _.set(updated, 'serviceType', defaults.serviceType);
};

const getList = (code, field, ordersTypesMap) => {
  if (!code) {
    return [];
  }
  return _.get(ordersTypesMap, `${code}.${field}`) || [];
};

export function* movementInBatch({ bankAccount, selecteds, actions }) {
  try {
    const { allowByContract, errorMessage } = checkContractPolicy(bankAccount, selecteds);

    if (!allowByContract) {
      showError({ error: { message: errorMessage } });
      return;
    }
    actions.setSubmitting && actions.setSubmitting(true);
    const bankAccountId = _.get(bankAccount, 'id');
    const bankContractId = _.get(bankAccount, 'bankContract.id');

    let paymentOrders = [];

    for (let i = 0; i < selecteds.length; i++) {

      let updated = { id: selecteds[i].id, serviceType: null };

      const ordersType = _.get(bankAccount, 'bank.settings.payment.ordersType');
      const ordersTypesMap = allowedOrdersTypes(ordersType);
      applyDefaultValues(updated, _.get(selecteds[i], 'paymentOrderType.code'), ordersTypesMap, ordersType);

      paymentOrders.push(updated);

    }

    yield call(api.put, `prepare-payment-orders/change-bank-account-in-batch`, { paymentOrders, bankContractId, bankAccountId });

    yield put(Actions.load(yield call(getFilterData)));

    actions.closeModal();
  } catch (error) {
    showError(error);
  } finally {
    actions.setSubmitting && actions.setSubmitting(false);
    actions.resetForm && actions.resetForm();
  }
}

export function* handleshippingCreate({ data, actions }) {
  try {
    yield call(api.post, 'prepare-payment-orders/create-payment-shipping', data);

    let size = _.size(_.keys(data)),
      message = size === 1 ? `Remessa criada com sucesso.` : `${size} Remessas criadas com sucesso.`;
    toast.success(message);
    actions.closeModal && actions.closeModal();

    yield put(Actions.load(yield call(getFilterData)));
  } catch (error) {
    showError(error);
  } finally {
    yield put(Actions.shippingCreateSuccess());
  }
}

export function* markAsWrong({ data }) {
  try {
    yield call(api.post, 'prepare-payment-orders/mark-as-wrong', data);

    let size = _.size(data),
      message = size === 1 ? `Ordem de pagamento atualizado com sucesso.` : `${size} Ordens de pagamento atualizadas com sucesso.`;

    toast.success(message);
    yield put(Actions.load(yield call(getFilterData)));
  } catch (error) {
    showError(error);
  }
}

export default all([
  takeLatest(Types.LOAD, load),
  takeLatest(Types.GET_COMPANIES, getCompanies),
  takeLatest(Types.LIST_COMPANIES, listCompanies),
  takeLatest(Types.LIST_PAYMENT_ORDER_TYPE, listPaymentOrderType),
  takeLatest(Types.LIST_BANK_ACCOUNTS, listBankAccounts),
  takeLatest(Types.LIST_BANK_ACCOUNTS, listBankAccounts),
  takeLatest(Types.MOVEMENT, movement),
  takeLatest(Types.MOVEMENT_IN_BATCH, movementInBatch),
  takeLatest(Types.SHIPPING_CREATE, handleshippingCreate),
  takeLatest(Types.MARK_AS_WRONG, markAsWrong),
  takeLatest(Types.LIST_BUSINESS_PARTNERS, listBusinessPartners),
  takeLatest(Types.LIST_BANK_CONTRACTS, listBankContracts),
]);
