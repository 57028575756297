import { all, put, call, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import _ from 'lodash';

import api from '~/services/api';
import { getError } from '~/helper';
import { Creators as BankContractActions, Types as BankContractTypes } from '~/store/modules/bank-contract';

const defaultValue = {
  active: true,
  autoShippingIntervalInMin: '0',
  autoShipping: 'false',
  fields: { ourNumberMode: 'auto', autoBindDaysBefore: 0, autoBindDaysAfter: 0 }
};

export function showError(error) {
  const msg = getError(error);
  toast.error(msg);
}

export function* createOrUpdate({ data, actions }) {
  try {
    let editing = !!(data && data.id),
      method = editing ? 'put' : 'post',
      path = editing ? 'update' : 'create';

    yield call(api[method], `bank-contracts/${path}`, data);

    toast.success(`Contrato ${editing ? 'atualizado' : 'criado'} com sucesso`);
    actions.closeForm && actions.closeForm();
    actions.reloadList && actions.reloadList();
  } catch (error) {
    showError(error);
  }
}

export function* list({ params }) {
  try {
    const response = yield call(api.post, 'bank-contracts/list', params);
    yield put(BankContractActions.listSuccess(response.data));
  } catch (error) {
    showError(error);
  }
}

export function* listBankAccounts({ term, callback }) {
  try {
    const response = yield call(api.get, 'bank-contracts/list-bank-accounts', { params: { term } });
    yield call(callback, response.data);
  } catch (error) {}
}

export function* listCompanies({ term, callback }) {
  try {
    const response = yield call(api.get, 'bank-contracts/list-companies', { params: { term } });
    yield call(callback, response.data);
  } catch (error) {}
}

export function* listEmailTemplates({ term, templateType, callback }) {
  try {
    const response = yield call(api.get, 'bank-contracts/list-email-templates', { params: { term, templateType } });
    yield call(callback, response.data);
  } catch (error) {}
}

export function* listBillTypes({ term, callback }) {
  try {
    const response = yield call(api.get, 'bank-contracts/list-bill-types', { params: { term } });
    yield call(callback, response.data);
  } catch (error) {}
}

export function* load({ data }) {
  try {
    if (_.isEmpty(data)) {
      yield put(BankContractActions.loadSuccess(defaultValue));
      return;
    }
    const response = yield call(api.get, 'bank-contracts/load', { params: { id: data.id } });
    yield put(BankContractActions.loadSuccess(response.data));
  } catch (error) {
    showError(error);
  }
}

export function* remove({ data, actions }) {
  try {
    let params = _.pick(data, 'id');
    yield call(api.delete, `bank-contracts/remove`, { params });

    toast.success(`Contrato removido com sucesso`);
    actions.closeForm && actions.closeForm();
    actions.reloadList && actions.reloadList();
  } catch (error) {
    showError(error);
  }
}

export function* externalApiAuthorizeToContract({ data }) {
  try {
    const response = yield call(api.get, 'bank-contracts/external-api-authorize', { params: { id: data.id } });
    const url = _.get(response, 'data.url');
    if (_.isEmpty(url)) throw new Error('Url de autorização inválida');
    window.open(url, '_blank');
  } catch (error) {
    showError(error);
  }
}

export default all([
  takeLatest(BankContractTypes.CREATE_OR_UPDATE, createOrUpdate),
  takeLatest(BankContractTypes.LIST, list),
  takeLatest(BankContractTypes.LOAD, load),
  takeLatest(BankContractTypes.REMOVE, remove),
  takeLatest(BankContractTypes.LIST_BANK_ACCOUNTS, listBankAccounts),
  takeLatest(BankContractTypes.LIST_COMPANIES, listCompanies),
  takeLatest(BankContractTypes.LIST_EMAIL_TEMPLATES, listEmailTemplates),
  takeLatest(BankContractTypes.LIST_BILL_TYPES, listBillTypes),
  takeLatest(BankContractTypes.EXTERNAL_API_AUTHORIZE_TO_CONTRACT, externalApiAuthorizeToContract)
]);
