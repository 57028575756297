import styled, { css } from 'styled-components';
import { primary, secondary, white, red, green, lightGray, accent } from '~/components/mixins/color';

const colorsMap = {
    primary: {
      text: white.hex(),
      background: primary.hex(),
      border: primary.hex(),
      hover: primary.darken(0.15).hex()
    },
    secondary: {
      text: primary.hex(),
      background: '#ccd9ef33',
      border: '#ccd9ef33',
      hover:  white.darken(0.05).hex(),
    },
    error: {
      text: white.hex(),
      background: red.darken(0.15).hex(),
      border: red.darken(0.15).hex(),
      hover: red.darken(0.20).hex()
    },
    errorText: {
      text: red.darken(0.15).hex(),
      background: 'transparent',
      border: secondary.hex(),
      hover:  white.darken(0.05).hex(),
    },
    success: {
      text: white.hex(),
      background: green.darken(0.15).hex(),
      border: green.darken(0.15).hex(),
      hover: green.darken(0.20).hex()
    },
    successText: {
      text: green.darken(0.15).hex(),
      background: 'transparent',
      border: secondary.hex(),
      hover:  white.darken(0.05).hex(),
    },
    unselected: {
      text: white.hex(),
      background: lightGray.darken(0.15).hex(),
      border: lightGray.darken(0.15).hex(),
      hover: lightGray.darken(0.25).hex()
    },
    accent: {
      text: white.hex(),
      background: accent.hex(),
      border: accent.hex(),
      hover: accent.darken(0.25).hex()
    },
    default: {
      text: primary.hex(),
      background: 'transparent',
      border: secondary.hex(),
      hover:  white.darken(0.05).hex(),
    }
  };

const getColor = (props) => {
  return colorsMap[props.color || 'default'] || colorsMap['default'];
};

export const Button = styled.button`
  color: ${(props) => getColor(props).text};
  border: 1px solid ${(props) => getColor(props).border};
  background: ${(props) => getColor(props).background};
  margin: ${(props) => props.noMargin ? '0' : '5px 0px 5px 5px'};
  height: ${(props) => props.height || '40'}px;
  font-size: ${(props) => props.fontSize || '15'}px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 14px;
  font-weight: bold;
  min-width: 100px;
  transition: background 0.2s;

  &:hover:enabled, &:focus:enabled {
    background: ${(props) => getColor(props).hover};
  }

  ${(props) =>
    css`svg { margin-${(typeof props.children[0] === 'string') ? 'left' : 'right'}: 5px; } `
  }
  ${(props) => props.disabled && css`
    opacity: 0.5;
    cursor: not-allowed;
    user-select: none;
    font-weight: normal;
  `}
`;

export const IconButton = styled.button`
  width: ${props => props.size || '36'}px;
  height: ${props => props.size || '36'}px;
  margin: ${(props) => props.noMargin ? '0' : (props.margin || '5px 0px 5px 5px')};
  color: ${(props) => getColor(props).text};
  border: 1px solid ${(props) => getColor(props).border};
  background: ${(props) => getColor(props).background};
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  font-size: 20px;

  & svg path {
    stroke: ${(props) => getColor(props).text};
  }
  &:hover:enabled, &:focus:enabled {
    background: ${(props) => getColor(props).hover};
    & svg path {
      stroke: ${(props) => getColor(props).text};
    }
  }
  ${props =>
    props.disabled &&
    css`
      opacity: 0.2;
      cursor: not-allowed;
      user-select: none;
      font-weight: normal;
    `}
`;
