
import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import _ from 'lodash';
import Modal from '~/components/modal';
import confirm from '~/components/confirm';
import Fieldset from '~/components/fieldset';
import { startOfDay } from 'date-fns';
import { MdDelete } from 'react-icons/md';
import { Button, IconButton } from '~/components/button';
import { formats } from '~/helper';
import Loading from '~/components/loading';
import { InstructionContainer, FormContainer } from '~pages/bills/styles';
import { InputLabel, Select, InputDate, CurrencyField } from '~/components/form';
import { BsContainer, BsRow, BsCol } from '~/components/layout';

const baseInstructionList = {
    '10': 'Alteração de Vencimento',
    '20': 'Concessão de Abatimento',
    '21': 'Cancelamento de Abatimento',
    '30': 'Protestar',
    '31': 'Cancelar protesto',
    '40': 'Sustar Protesto e Baixar Título',
    '41': 'Sustar Protesto e Manter em Carteira',
  };

const populateInstruction = (data, bankInstruction) => {
  let formData = { ...data, ...(_.get(bankInstruction, 'fixedValues') || {}) },
    mappedValues = _.get(bankInstruction, 'mappedValues') || {};

  for (let tgtField in mappedValues) {
    let srcField = mappedValues[tgtField];
    _.set(formData, tgtField, _.get(data, srcField));
  }
  return formData;
};

const getProtestDays = (ranges, mode) => {
  let array = [],
    base = _.get(ranges, mode);

  if (base && _.isObject(base) && base.min && base.max) {
    for (let i = base.min; i <= base.max; i++) {
      array.push(i);
    }
  }
  if (base && _.isArray(base)) {
    array = base;
  }
  return _.map(array, (value) => ({ label: `${value}`, value: `${value}` }));
};

const getInstructionList = (bankInstructions, values, usedInstructions) => {
  let allowed = _.keys(bankInstructions),
    docType = _.get(values, 'billData.documentType');

  // Restrição pelos valores da fatura
  if (!_.get(values, 'withProtest')) {
    _.remove(allowed, (r) => /^(31|40|41)$/.test(r));
  } else {
    _.remove(allowed, (r) => /^(10|20|21|30)$/.test(r));
  }
  if (_.isEmpty(_.get(values, 'decrements'))) {
    _.remove(allowed, (r) => /^(21)$/.test(r));
  }

  // Restrição pelo banco e layout
  for (let type in _.pick(bankInstructions, allowed)) {
    let bankInstruction = bankInstructions[type];

    if (!_.isEmpty(bankInstruction.allowedDocTypes) && !bankInstruction.allowedDocTypes.includes(docType)) {
      _.remove(allowed, (r) => r === type);
    }
    if (!_.isEmpty(bankInstruction.deniedDocTypes) && bankInstruction.deniedDocTypes.includes(docType)) {
      _.remove(allowed, (r) => r === type);
    }
  }
  if (/^(20|21)$/.test(usedInstructions)) {
    _.remove(allowed, (r) => /^(20|21)$/.test(r));
  } else {
    _.remove(allowed, (r) => usedInstructions.includes(r));
  }
  return _.map(_.pick(baseInstructionList, allowed), (label, value) => ({ label, value }));
};

const isValidForm = (values, parent) => {
  let type = _.get(values, 'instruction'),
    decrementValue = _.get(values, 'decrementValue'),
    dueDate = _.get(values, 'dueDate'),
    now = startOfDay(new Date()),
    invalidFields = [];

  if (/^(10)$/.test(type) && (!dueDate || dueDate < now)) {
    invalidFields.push('dueDate');

  } else if (/^(20|21)$/.test(type) && !decrementValue) {
    invalidFields.push('decrementValue');

  } else if (/^(20)$/.test(type) && (decrementValue < 0 || decrementValue > _.get(parent, 'value'))) {
    invalidFields.push('decrementValue');

  } else if (/^(30)$/.test(type) && !_.get(values, 'protestDays')) {
    invalidFields.push('protestDays');
  }
  return type && _.isEmpty(invalidFields);
};

const InstructionSubForm = ({ values, parent, disabled, bankInstructions, setFieldValue, settings, errors, touched }) => {
  let type = _.get(values, 'instruction'),
    protestDaysModes = _.get(bankInstructions, `['30'].dayModes`) || {};

  if (/^(10)$/.test(type)) {
    return (
      <BsRow>
        <BsCol sm={24} md={24} lg={24}>
          <InputDate
            name="dueDate"
            label="Data vencimento"
            hideErrorLabel={false}
            minDate={new Date()}
            disabled={disabled}
            hasError={_.get(errors, 'dueDate') && _.get(touched, 'dueDate')}
            />
        </BsCol>
      </BsRow>
    );

  } else if (/^(20)$/.test(type)) {
    return (
      <BsRow>
        <BsCol sm={24} md={24} lg={24}>
          <CurrencyField
            name="decrementValue"
            label="Valor abatimento"
            disabled={disabled}
            hasError={_.get(errors, 'decrementValue') && _.get(touched, 'decrementValue')}
            />
        </BsCol>
      </BsRow>
    );

  } else if (/^(21)$/.test(type)) {
    return (
      <BsRow>
        <BsCol sm={24} md={24} lg={24}>
          <Select
            name="decrementValue"
            label="Abatimento para cancelar"
            disabled={disabled}
            hasError={_.get(errors, 'decrementValue') && _.get(touched, 'decrementValue')}
            options={{ values: [
              { value: '', label: 'Selecione' },
              ..._.map(_.get(parent, `decrements`), ({ value, date }) => ({
                  label: `Valor: ${formats.currency(value)}, Data: ${formats.dateTimeZone(date)}`,
                  value: `${value}`
                }))
              ]}}
            />
        </BsCol>
      </BsRow>
    );

  } else if (/^(30)$/.test(type)) {
    return (
      <BsRow>
        <BsCol sm={24} md={12} lg={12}>
          <Select
            name="protestDaysMode"
            label="Tipo de dia"
            disabled={disabled}
            hasError={_.get(errors, 'protestDaysMode') && _.get(touched, 'protestDaysMode')}
            options={{ values: _.map(protestDaysModes, (label, value) => ({ label, value })) }}
            onChange={(evt) => {
              setFieldValue('protestDaysMode', _.get(evt, 'target.value'));
              setFieldValue('protestDays', '');
            }}
            />
        </BsCol>

        <BsCol sm={24} md={12} lg={12}>
          <Select
            name="protestDays"
            label="Dias de prazo para Protesto"
            disabled={disabled || !_.get(values, 'protestDaysMode')}
            hasError={_.get(errors, 'protestDays') && _.get(touched, 'protestDays')}
            options={{ values: [
               { value: '', label: 'Selecione' },
                ...getProtestDays(_.get(bankInstructions, `['30'].dayModeRanges`), _.get(values, 'protestDaysMode'))
            ]}}
            />
        </BsCol>
      </BsRow>
    );
  }
  return null;
};

const InstructionsForm = ({ parent, loading, settings, usedInstructions, addInstruction }) => {
   let bankAccount = _.get(parent, 'bankContract.bankAccount') || {},
    layoutType = _.get(parent, 'bankContract.layoutType') || '',
    bankInstructions = _.get(settings, `charge.bankInstructions.${layoutType}`) || {},
    instructionList = getInstructionList(bankInstructions, parent, usedInstructions);

  return (
    <Formik
      initialValues={{}}
      enableReinitialize={true}
      validateOnMount={false}
      initialTouched={{ 'instruction': true }}
      >
      { ({ errors, handleReset, touched, setFieldValue, values, ...formArgs }) => (
        <Fieldset label="Dados da instrução">
          <Form>
            <div className="inst-form-contents">
              <BsRow>
                <BsCol sm={24} md={24} lg={24}>
                  <Select
                    name="instruction"
                    label="Instrução"
                    disabled={loading}
                    hasError={_.get(errors, 'instruction') && _.get(touched, 'instruction')}
                    options={{ values: [
                        { value: '', label: 'Escolha a instrução' },
                        ...instructionList
                      ]
                    }}
                    onChange={(evt) => {
                      setFieldValue('instruction', _.get(evt, 'target.value'));
                      setFieldValue('dueDate', null);
                      setFieldValue('decrementValue', null);
                      setFieldValue('protestDaysMode', '1');
                      setFieldValue('protestDays', null);
                    }}
                    />
                </BsCol>

                <BsCol sm={24} md={24} lg={24}>
                  {/^(10|20|21|30|31|40|41)$/.test(_.get(values, 'instruction')) &&
                    <InstructionSubForm
                      values={values}
                      parent={parent}
                      errors={errors}
                      touched={touched}
                      disabled={loading}
                      bankInstructions={bankInstructions}
                      settings={_.get(bankAccount, 'bank.settings')}
                      setFieldValue={setFieldValue}
                      />
                  }
                </BsCol>
              </BsRow>
            </div>

            <div className="inst-form-toolbar">
              <Button
                height={30}
                disabled={loading}
                onClick={handleReset}>
                Limpar
              </Button>

              <Button
                height={30}
                color="primary"
                disabled={loading || !isValidForm(values, parent)}
                onClick={() => { handleReset(); addInstruction(populateInstruction(values, _.get(bankInstructions, values.instruction))); }}>
                Adicionar
              </Button>
            </div>
          </Form>
        </Fieldset>
      )}
    </Formik>
  )
};

const InstructionsList = ({ parent, settings, instructionsList, removeInstruction }) => {
  const layoutType = _.get(parent, 'bankContract.layoutType') || '',
    bankInstructions = _.get(settings, `charge.bankInstructions.${layoutType}`) || {},
    protestDaysModes = _.get(bankInstructions, `['30'].dayModes`) || {};

  return (
    <Fieldset label="Instruções a serem processadas" className="inst-list">
      {_.isEmpty(instructionsList) && 
        <div className="inst-empty">
          Nenhuma instrução.
        </div>
      }

      {_.map(instructionsList || [], (value, index) => (
        <div key={index} className="inst-column">
          <div className="inst-column-data">
            <strong>
              {baseInstructionList[value.instruction] || value.instruction}
            </strong>
            {value.instruction === '10' && 
              <span>Data de vencimento: {formats.dateTimeZone(value.dueDate, 'dd/MM/yyyy')}</span>
            }
            {value.instruction === '20' && 
              <span>Valor: {formats.currency(value.decrementValue)}</span>
            }
            {value.instruction === '21' && 
              <span>Valor: {formats.currency(value.decrementValue)}</span>
            }
            {value.instruction === '30' && 
              <div>
                <span>Tipo de dia: {protestDaysModes[value.protestDaysMode] || value.protestDaysMode}, </span>
                <span>Dias: {value.protestDays}</span>
              </div>
            }
          </div>

          <IconButton size={36} margin="0">
            <MdDelete onClick={() => removeInstruction(index)} />
          </IconButton>
        </div>
      ))}
    </Fieldset>
  );
};

const InstructionsModal = ({ isOpen, loading, toggleModal, handleOnSubmit, parent, settings }) => {

  const [instructionsList, setInstructionsList] = useState([]);

  let bankAccount = _.get(parent, 'bankContract.bankAccount') || {},
    parentFields = {
      billId: _.get(parent, 'id'),
      bankContractId: _.get(parent, 'bankContract.id'),
      layoutType: _.get(parent, 'bankContract.layoutType'),
      bankCode: _.get(parent, 'bankContract.bankAccount.bank.code'),
    },
    infoFieldSpan = _.get(parent, 'withProtest') ? 8 : 12;

  const addInstruction = (row) => {
    let list = _.cloneDeep(instructionsList);
    list.push(row);
    setInstructionsList(list);
  };

  const removeInstruction = async (index) => {
    if (index >= 0) {
      const result = await confirm.show({
        width: 450,
        title: 'Atenção',
        text: `Deseja realmente remover esta instrução?`
      })

      if (result) {
        let list = _.cloneDeep(instructionsList);
        list.splice(index, 1);
        setInstructionsList(list);
      }
    }
  };

  return (
    <Modal
      width="800px"
      height="468px"
      title={`Instruções`}
      hideClose={true}
      open={isOpen}
      bindRefWithSubmit={true}
      noBodyPadding={true}
      hide={toggleModal}
      actions={
        [
          {
            label: 'Cancelar',
            disabled: loading,
            action: () => { toggleModal(); }
          },
          {
            label: loading ? 'Processando...' : 'Enviar',
            action: () => handleOnSubmit({ instructions: instructionsList, ...parentFields }, {}),
            type: 'submit',
            disabled: loading || _.isEmpty(instructionsList),
            primary: true
          }
        ]
      }
      >
      <InstructionContainer>
        <BsContainer>
          <BsRow>
            <BsCol sm={24} md={12} lg={12}>
              <InputLabel
                label="Banco"
                value={`${_.get(bankAccount, 'bank.code')} ${_.get(bankAccount, 'bank.name')}`}
                />
            </BsCol>

            <BsCol sm={8} md={4} lg={4}>
              <InputLabel
                label="Agência"
                value={`${_.get(bankAccount, 'bankBranch')}-${_.get(bankAccount, 'bankBranchDigit')}`}
                />
            </BsCol>

            <BsCol sm={8} md={4} lg={4}>
              <InputLabel
                label="Conta"
                value={`${_.get(bankAccount, 'accountNumber')}-${_.get(bankAccount, 'accountNumberDigit')}`}
                />
            </BsCol>

            <BsCol sm={8} md={4} lg={4}>
              <InputLabel
                label={'Nosso Número'}
                value={_.get(parent, 'billData.ourNumber') || ''}
                />
            </BsCol>

            <BsCol sm={infoFieldSpan} md={infoFieldSpan} lg={infoFieldSpan}>
              <InputLabel
                label={'Data de vencimento'}
                value={formats.dateTimeZone(_.get(parent, 'dueDate'))}
                />
            </BsCol>

            <BsCol sm={infoFieldSpan} md={infoFieldSpan} lg={infoFieldSpan}>
              <InputLabel
                label={'Valor'}
                value={formats.currency(_.get(parent, 'value'))}
                />
            </BsCol>

            {_.get(parent, 'withProtest') &&
              <BsCol sm={8} md={8} lg={8}>
                <InputLabel
                  label={'Protestar a partir de'}
                  value={formats.dateTimeZone(_.get(parent, 'protestDate'))}
                  />
              </BsCol>
            }

            {loading &&
              <FormContainer>
                <Loading size={36} label={'Processando...'} />
              </FormContainer>
            }

            {!loading &&
              <>
                <BsCol sm={24} md={12}>
                  <InstructionsForm
                    parent={parent}
                    settings={settings}
                    addInstruction={addInstruction}
                    usedInstructions={_.map(instructionsList, 'instruction')}
                    />
                </BsCol>

                <BsCol sm={24} md={12}>
                  <InstructionsList
                    parent={parent}
                    settings={settings}
                    instructionsList={instructionsList}
                    removeInstruction={removeInstruction}
                    />
                </BsCol>
              </>
            }
          </BsRow>
        </BsContainer>
      </InstructionContainer>
    </Modal>
  )
};

export default InstructionsModal;
