import { createActions, createReducer } from 'reduxsauce';
import produce from 'immer';

export const { Types, Creators } = createActions({
  load: ['params'],
  loadSuccess: ['data'],
  listBankAccounts: ['term', 'callback'],
  listCompanies: ['term', 'callback'],
}, { prefix: 'dashboard' });

const INITIAL_STATE = {
  loading: false,
  data: {}
};

const handleLoad = (state = INITIAL_STATE, action) => produce(state, draft => {
  draft.loading = true;
});

const handleLoadSuccess = (state = INITIAL_STATE, action) => produce(state, draft => {
  draft.loading = false;
  draft.data = action.data || {};
});

export default createReducer(INITIAL_STATE, {
  [Types.LOAD]: handleLoad,
  [Types.LOAD_SUCCESS]: handleLoadSuccess,
});
