
import { getSlipType, digitableLineToBarcode } from '~/helper';

const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export function isValidEmail(value) {
  return emailRegex.test(value);
};

export function allCharsSame(s) {
  let val = String(s);
  for (let i = 1; i < val.length; i++) {
    if (val[0] !== val[i]) {
      return false;
    }
  }
  return true;
};

export function isValidCpf(value) {
  let cpf = String(value).replace(/[^\d]+/g,'');

  if (cpf === '') {
    return false;
  }
  // Elimina CPFs invalidos conhecidos

  if (cpf === '' || cpf.length !== 11) {
    return false;
  }
  if (allCharsSame(cpf)) {
    return false;
  }
  // Valida 1o digito
  let add = 0;
  for (let i = 0; i < 9; i ++)
    add += parseInt(cpf.charAt(i)) * (10 - i);
  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) {
    rev = 0;
  }
  if (rev !== parseInt(cpf.charAt(9))) {
    return false;
  }
  // Valida 2o digito
  add = 0;
  for (let i = 0; i < 10; i ++) {
    add += parseInt(cpf.charAt(i)) * (11 - i);
  }
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) {
    rev = 0;
  }
  return (rev === parseInt(cpf.charAt(10)));
};

export function isValidCnpj(value) {
  let cnpj = String(value).replace(/[^\d]+/g,'');

  if (cnpj === '' || cnpj.length !== 14) {
      return false;
  }
  if (allCharsSame(cnpj)) {
      return false;
  }

  let tamanho = cnpj.length - 2
  let numeros = cnpj.substring(0, tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado !== parseInt(digitos.charAt(0))) {
    return false;
  }

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }
  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  return resultado === parseInt(digitos.charAt(1));
};

export function isValidPisPasep(value) {
  let numPIS = String(value).replace(/[^\d]+/g,''),
    multiplierBase = '3298765432',
    total = 0,
    remain = 0,
    multiply = 0,
    multiplier = 0,
    digit = 99;

  if (allCharsSame(numPIS)) {
    return false;
  }
  for (var i = 0; i < 10; i++) {
    multiply = parseInt( numPIS.substring( i, i + 1 ) );
    multiplier = parseInt( multiplierBase.substring( i, i + 1 ) );
    total += multiply * multiplier;
  }

  remain = 11 - total % 11;
  remain = (remain === 10 || remain === 11) ? 0 : remain;

  digit = parseInt('' + numPIS.charAt(10));
  return remain === digit;
};

export function calculateMod11(value) {
  let code = String(value).replace(/[^0-9]/g, ''),
    sequence = [4, 3, 2, 9, 8, 7, 6, 5],
    digit = 0,
    j = 0,
    DAC = 0;

  for (let i = 0; i < code.length; i++) {
    let mult = sequence[j];
    j++;
    j %= sequence.length;
    digit += mult * parseInt(code.charAt(i));
  }
  DAC = digit % 11;

  if (DAC === 0 || DAC === 1 || DAC === 10) {
    return 1;
  }
  return (11 - DAC);
};

export function calculateMod11_2(value) {
  let code = String(value).replace(/[^0-9]/g, ''),
    sequence = [4, 3, 2, 9, 8, 7, 6, 5],
    digit = 0,
    j = 0,
    DAC = 0;

  for (let i = 0; i < code.length; i++) {
    let mult = sequence[j];
    j++;
    j %= sequence.length;
    digit += mult * parseInt(code.charAt(i));
  }
  DAC = digit % 11;

  if (DAC === 0 || DAC === 1) {
    return 0;
  }
  if (DAC === 10) {
    return 1;
  }
  return (11 - DAC);
};

export function calculateMod10(value) {
  let number = String(value).replace(/\D/g, ''),
    i,
    mult = 2,
    sum = 0,
    s = '';

  for (i = number.length - 1; i >= 0; i--) {
    s = (mult * parseInt(number.charAt(i))) + s;
    if (--mult < 1) {
      mult = 2;
    }
  }
  for (i = 0; i < s.length; i++) {
    sum = sum + parseInt(s.charAt(i));
  }
  sum = sum % 10;
  if (sum !== 0) {
    sum = 10 - sum;
  }
  return sum;
};

export function barcodeDV(value, position, mod) {
  let code = String(value).replace(/[^0-9]/g, '');
  code = code.split('');
  code.splice(position, 1);
  code = code.join('');

  if (mod === 10) {
    return calculateMod10(code);
  }
  return position === 4 ? calculateMod11(code) : calculateMod11_2(code);
};

export function isValueOrReference(value) {
  let code = String(value).replace(/[^0-9]/g, ''),
    reference = code.substr(2, 1);

  if (reference === '6') {
    return { mod: 10, efetivo: true };
  } else if (reference === '7') {
    return { mod: 10, efetivo: false };
  } else if (reference === '8') {
    return { mod: 11, efetivo: true };
  } else if (reference === '9') {
    return { mod: 11, efetivo: false };
  }
  return { mod: 11 };
};

export function isValidSlipBarcode(value) {
  let code = String(value).replace(/[^0-9]/g, ''),
    result = '',
    type = getSlipType(code);

  if (/^(BANCO|CARTAO_DE_CREDITO)$/.test(type)) {
    const dv = barcodeDV(code, 4, 11);
    result = code.substr(0, 4) + dv + code.substr(5);

  } else {
    const valueOrReference = isValueOrReference(code);
  
    result = code.split('');
    result.splice(3, 1);
    result = result.join('');

    const dv = barcodeDV(code, 3, valueOrReference.mod);
  
    result = result.substr(0, 3) + dv + result.substr(3);
  }
  return code === result;
};

export function isValidSlipDigitableLine(value) {
  let barcode = digitableLineToBarcode(value);

  if (allCharsSame(barcode)) {
    return false;
  }
  return isValidSlipBarcode(barcode);
};
