import React, { useState } from 'react';
import _ from 'lodash';
import DataTable from '~/components/datatable';
import { TabContainer } from '~/pages/responses/styles';
import { formats } from 'helper';
import { SubtitleItem, Subtitle } from '~/components/datatable/subtitle';
import TextWithHint from '~/components/text-with-hint';

const columns = [
    {
      name: 'Nº Documento',
      selector: 'paymentOrder',
      cell: (row) => <TextWithHint text={_.get(row, 'paymentOrder.documentNumber') || ''} />
    },
    {
      name: 'Vencimento',
      width: '120px',
      selector: 'paymentOrder',
      cell: (row) => <TextWithHint text={formats.dateTimeZone(_.get(row, 'paymentOrder.dueDate'), 'dd/MM/yyyy')} />
    },
    {
      name: 'Valor (R$)',
      selector: 'value',
      width: '120px',
      right: true,
      cell: (row) => <TextWithHint text={formats.decimal(_.get(row, 'paymentOrder.value'))} />
    },
    {
      name: 'Ocorrência',
      selector: 'occurrence',
      center: true,
      width: '140px',
      cell: (row) => <TextWithHint style={{ width: '100%' }} text={`${_.get(row, 'occurrence.code')} - ${_.get(row, 'occurrence.description')}`} />
    },
    {
      name: 'Pago em',
      width: '140px',
      selector: 'paymentOrder',
      right: true,
      cell: (row) => <TextWithHint text={formats.dateTimeZone(_.get(row, 'paymentOrder.payDate'), 'dd/MM/yyyy')} />
    },
    {
      name: 'Pago (R$)',
      selector: 'paymentOrder',
      right: true,
      cell: (row) => <TextWithHint text={formats.decimal(_.get(row, 'paymentOrder.payAmount'))} />
    },
    {
      name: 'Multa (R$)',
      selector: 'extras',
      right: true,
      cell: (row) => <TextWithHint text={formats.decimal(_.get(row, 'extras.fine'))} />
    },
    {
      name: 'Juros (R$)',
      selector: 'extras',
      right: true,
      cell: (row) => <TextWithHint text={formats.decimal(_.get(row, 'extras.interest'))} />
    },
    {
      name: 'Desc. (R$)',
      selector: 'extras',
      right: true,
      cell: (row) => <TextWithHint text={formats.decimal(_.get(row, 'extras.discount'))} />
    }
  ];

const getRows = (array = [], offset, limit) => {
  return array.slice(offset, offset + limit);
};

const conditionalRowStyles = [
  {
    when: (row) => !!_.get(row, 'paymentOrder.erpError'),
    style: { color: '#e067e0 !important', '&:hover': { color: '#e067e0' }}
  }
];

export const TabPaymentOrders = ({ paymentOrders, documentsWithError, openDocument, tabId }) => {

  const hasError = tabId === 'paymentOrdersError';
  const list = hasError ? documentsWithError : paymentOrders;
  const count = _.size(list);

  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(20);

  const onChangePage = (offset, limit) => {
    setOffset(offset);
    setLimit(limit);
  };

  return (
    <TabContainer>
      { hasError &&
        <Subtitle bottom={15} left={10}>
          <span>Legenda:</span>
          <SubtitleItem color={'#e067e0'}>Não conciliado no ERP</SubtitleItem>
        </Subtitle>
      }

      <DataTable
        emptyText="Nenhum pagamento processado"
        pageSize={limit}
        onChangePage={onChangePage}
        hideUpdateButton={true}
        data={{ rows: getRows(list, offset, limit), offset, count, limit: limit }}
        onRowClicked={openDocument('payment')}
        columns={columns}
        extraOptions={{
          conditionalRowStyles: conditionalRowStyles,
        }}
      />
    </TabContainer>
  )
};
