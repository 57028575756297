
import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import _ from 'lodash';
import Modal from '~/components/modal';
import confirm from '~/components/confirm';
import Fieldset from '~/components/fieldset';
import { startOfDay } from 'date-fns';
import { MdDelete } from 'react-icons/md';
import { Button, IconButton } from '~/components/button';
import { formats } from '~/helper';
import Loading from '~/components/loading';
import { InstructionContainer, FormContainer } from '~pages/payment-orders/styles';
import { InputLabel, Select, InputDate, CurrencyField } from '~/components/form';
import { BsContainer, BsRow, BsCol } from '~/components/layout';

const baseInstructionList = {
    '10': 'Alteração de Data de Agendamento',
    '20': 'Alteração de Valor de Pagamento',
  };

const populateInstruction = (data, bankInstruction) => {
  let formData = { ...data, ...(_.get(bankInstruction, 'fixedValues') || {}) },
    mappedValues = _.get(bankInstruction, 'mappedValues') || {};

  for (let tgtField in mappedValues) {
    let srcField = mappedValues[tgtField];
    _.set(formData, tgtField, _.get(data, srcField));
  }
  return formData;
};

const getInstructionList = (bankInstructions, values, usedInstructions) => {
  let allowed = _.keys(bankInstructions),
    docType = _.get(values, 'paymentOrderType.code');

  // Restrição pelo banco e layout
  for (let type in _.pick(bankInstructions, allowed)) {
    let bankInstruction = bankInstructions[type];

    if (!_.isEmpty(bankInstruction.allowedDocTypes) && !bankInstruction.allowedDocTypes.includes(docType)) {
      _.remove(allowed, (r) => r === type);
    }
    if (!_.isEmpty(bankInstruction.deniedDocTypes) && bankInstruction.deniedDocTypes.includes(docType)) {
      _.remove(allowed, (r) => r === type);
    }
  }

  _.remove(allowed, (r) => usedInstructions.includes(r));

  return _.map(_.pick(baseInstructionList, allowed), (label, value) => ({ label, value }));
};

const isValidForm = (values, parent) => {
  let type = _.get(values, 'instruction'),
    scheduledDate = _.get(values, 'scheduledDate'),
    amount = _.get(values, 'amount'),
    baseAmount = _.get(parent, 'baseAmount'),
    now = startOfDay(new Date()),
    invalidFields = [];

  if (/^(10)$/.test(type) && (!scheduledDate || scheduledDate < now)) {
    invalidFields.push('scheduledDate');
  } 
  if (/^(20)$/.test(type) && (!amount || amount > baseAmount)) {
    invalidFields.push('amount');
  } 
  return type && _.isEmpty(invalidFields);
};

const InstructionSubForm = ({ values, parent, disabled, bankInstructions, setFieldValue, settings, errors, touched }) => {
  let type = _.get(values, 'instruction')

  if (/^(10)$/.test(type)) {
    return (
      <BsRow>
        <BsCol sm={24} md={24} lg={24}>
          <InputDate
            name="scheduledDate"
            label="Data de agendamento"
            hideErrorLabel={false}
            minDate={new Date()}
            disabled={disabled}
            hasError={_.get(errors, 'scheduledDate') && _.get(touched, 'scheduledDate')}
            />
        </BsCol>
      </BsRow>
    );
  } else if (/^(20)$/.test(type)) {
    return (
      <BsRow>
        <BsCol sm={24} md={24} lg={24}>
          <CurrencyField
            name="amount"
            label="Valor a Pagar"
            disabled={disabled}
            hasError={_.get(errors, 'amount') && _.get(touched, 'amount')}
            />
        </BsCol>
      </BsRow>
    );
  }
  return null;
};

const InstructionsForm = ({ parent, loading, settings, usedInstructions, addInstruction }) => {
   let bankAccount = _.get(parent, 'bankContract.bankAccount') || {},
    layoutType = _.get(parent, 'bankContract.layoutType') || '',
    bankInstructions = _.get(settings, `payment.bankInstructions.${layoutType}`) || {},
    instructionList = getInstructionList(bankInstructions, parent, usedInstructions);

  return (
    <Formik
      initialValues={{}}
      enableReinitialize={true}
      validateOnMount={false}
      initialTouched={{ 'instruction': true }}
      >
      { ({ errors, handleReset, touched, setFieldValue, values, ...formArgs }) => (
        <Fieldset label="Dados da instrução">
          <Form>
            <div className="inst-form-contents">
              <BsRow>
                <BsCol sm={24} md={24} lg={24}>
                  <Select
                    name="instruction"
                    label="Instrução"
                    disabled={loading}
                    hasError={_.get(errors, 'instruction') && _.get(touched, 'instruction')}
                    options={{ values: [
                        { value: '', label: 'Escolha a instrução' },
                        ...instructionList
                      ]
                    }}
                    onChange={(evt) => {
                      setFieldValue('instruction', _.get(evt, 'target.value'));
                      setFieldValue('scheduledDate', null);
                      setFieldValue('amount', null);
                    }}
                    />
                </BsCol>

                <BsCol sm={24} md={24} lg={24}>
                  {/^(10|20)$/.test(_.get(values, 'instruction')) &&
                    <InstructionSubForm
                      values={values}
                      parent={parent}
                      errors={errors}
                      touched={touched}
                      disabled={loading}
                      bankInstructions={bankInstructions}
                      settings={_.get(bankAccount, 'bank.settings')}
                      setFieldValue={setFieldValue}
                      />
                  }
                </BsCol>
              </BsRow>
            </div>

            <div className="inst-form-toolbar">
              <Button
                height={30}
                disabled={loading}
                onClick={handleReset}>
                Limpar
              </Button>

              <Button
                height={30}
                color="primary"
                disabled={loading || !isValidForm(values, parent)}
                onClick={() => { handleReset(); addInstruction(populateInstruction(values, _.get(bankInstructions, values.instruction))); }}>
                Adicionar
              </Button>
            </div>
          </Form>
        </Fieldset>
      )}
    </Formik>
  )
};

const InstructionsList = ({ parent, settings, instructionsList, removeInstruction }) => {

  return (
    <Fieldset label="Instruções a serem processadas" className="inst-list">
      {_.isEmpty(instructionsList) && 
        <div className="inst-empty">
          Nenhuma instrução.
        </div>
      }

      {_.map(instructionsList || [], (value, index) => (
        <div key={index} className="inst-column">
          <div className="inst-column-data">
            <strong>
              {baseInstructionList[value.instruction] || value.instruction}
            </strong>
            {value.instruction === '10' && 
              <span>Data de Agendamento: {formats.dateTimeZone(value.scheduledDate, 'dd/MM/yyyy')}</span>
            }
            {value.instruction === '20' && 
              <span>Novo Valor a Pagar: {formats.currency(value.amount)}</span>
            }
          </div>

          <IconButton size={36} margin="0">
            <MdDelete onClick={() => removeInstruction(index)} />
          </IconButton>
        </div>
      ))}
    </Fieldset>
  );
};

const InstructionsModal = ({ isOpen, loading, toggleModal, handleOnSubmit, parent, settings }) => {

  const [instructionsList, setInstructionsList] = useState([]);

  let bankAccount = _.get(parent, 'bankContract.bankAccount') || {},
    parentFields = {
      paymentOrderId: _.get(parent, 'id'),
      bankContractId: _.get(parent, 'bankContract.id'),
      layoutType: _.get(parent, 'bankContract.layoutType'),
      bankCode: _.get(parent, 'bankContract.bankAccount.bank.code'),
    }

  const addInstruction = (row) => {
    if(!!row.scheduledDate){
      row.scheduledDate.setHours(0)
    }
    let list = _.cloneDeep(instructionsList);
    list.push(row);
    setInstructionsList(list);
  };

  const removeInstruction = async (index) => {
    if (index >= 0) {
      const result = await confirm.show({
        width: 450,
        title: 'Atenção',
        text: `Deseja realmente remover esta instrução?`
      })

      if (result) {
        let list = _.cloneDeep(instructionsList);
        list.splice(index, 1);
        setInstructionsList(list);
      }
    }
  };

  return (
    <Modal
      width="800px"
      height="468px"
      title={`Instruções`}
      hideClose={true}
      open={isOpen}
      bindRefWithSubmit={true}
      noBodyPadding={true}
      hide={toggleModal}
      actions={
        [
          {
            label: 'Cancelar',
            disabled: loading,
            action: () => { toggleModal(); }
          },
          {
            label: loading ? 'Processando...' : 'Enviar',
            action: () => handleOnSubmit({ instructions: instructionsList, ...parentFields }, {}),
            type: 'submit',
            disabled: loading || _.isEmpty(instructionsList),
            primary: true
          }
        ]
      }
      >
      <InstructionContainer>
        <BsContainer>
          <BsRow>
            <BsCol sm={24} md={12} lg={12}>
              <InputLabel
                label="Banco"
                value={`${_.get(bankAccount, 'bank.code')} ${_.get(bankAccount, 'bank.name')}`}
                />
            </BsCol>

            <BsCol sm={8} md={4} lg={4}>
              <InputLabel
                label="Agência"
                value={`${_.get(bankAccount, 'bankBranch')}-${_.get(bankAccount, 'bankBranchDigit')}`}
                />
            </BsCol>

            <BsCol sm={8} md={4} lg={4}>
              <InputLabel
                label="Conta"
                value={`${_.get(bankAccount, 'accountNumber')}-${_.get(bankAccount, 'accountNumberDigit')}`}
                />
            </BsCol>

            <BsCol sm={8} md={4} lg={4}>
              <InputLabel
                label={'Nosso Número'}
                value={_.get(parent, 'ourNumber') || ''}
                />
            </BsCol>

            <BsCol sm={12} md={12} lg={12}>
              <InputLabel
                label={'Data de agendamento'}
                value={formats.dateTimeZone(_.get(parent, 'scheduledDate'))}
                />
            </BsCol>

            <BsCol sm={6} md={6} lg={6}>
              <InputLabel
                label={'Valor Ordem Pagamento'}
                value={formats.currency(_.get(parent, 'baseAmount'))}
                />
            </BsCol>

            <BsCol sm={6} md={6} lg={6}>
              <InputLabel
                label={'Valor A Pagar'}
                value={formats.currency(_.get(parent, 'value'))}
                />
            </BsCol>

            {loading &&
              <FormContainer>
                <Loading size={36} label={'Processando...'} />
              </FormContainer>
            }

            {!loading &&
              <>
                <BsCol sm={24} md={12}>
                  <InstructionsForm
                    parent={parent}
                    settings={settings}
                    addInstruction={addInstruction}
                    usedInstructions={_.map(instructionsList, 'instruction')}
                    />
                </BsCol>

                <BsCol sm={24} md={12}>
                  <InstructionsList
                    parent={parent}
                    settings={settings}
                    instructionsList={instructionsList}
                    removeInstruction={removeInstruction}
                    />
                </BsCol>
              </>
            }
          </BsRow>
        </BsContainer>
      </InstructionContainer>
    </Modal>
  )
};

export default InstructionsModal;
