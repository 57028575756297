import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import Modal from '~/components/modal';
import { ModalContainer, BankAccountContainer } from '~pages/prepare-payment-order/styles';
import { Autocomplete } from '~components/form';
import DataTable from '~/components/datatable';
import { formatBankAccountSimple, formatBankAccount } from '~/pages/bank-accounts/form';

const PreparePaymentOrderModal = ({
  isOpen,
  dataList,
  dataColumns,
  selecteds,
  closeModal,
  handleOnSubmit,
  onListBankAccounts,
  handleOnChangeSelect,
  bankAccountMovementFrom
}) => {

  const isInvalid = values => {
    if (!values.bankAccount || _.size(selecteds) === 0) {
      return true;
    }
    return false;
  };

  const getDataList = ({ bankAccount }) => {
    const bankCode = _.get(bankAccount, 'bank.code');

    return !bankCode
      ? dataList
      : _.filter(dataList, f => {
          const extrasBankState = _.get(f, 'paymentOrderType.extras.bankState');
          const bankStates = _.get(f, `paymentOrderType.extras.bankState[${bankCode}]`) || [];
          const allowedStates = !extrasBankState || bankStates.includes(_.get(f, 'orderData.state.value'));
          const banks = _.get(f, 'paymentOrderType.banks') || [];
          return banks.includes(bankCode) && allowedStates;
        });
  };

  const getTitle = () => {
    if (!bankAccountMovementFrom) return 'Selecione os títulos que serão pagos';
    return (
      <BankAccountContainer>
        <div>
          {bankAccountMovementFrom.logo && <img className="logo" src={bankAccountMovementFrom.logo} alt="action" />}
          <h2>
            <label>{bankAccountMovementFrom.label}</label>
            <span>{bankAccountMovementFrom.detail}</span>
          </h2>
        </div>
        <h1>[Selecione o contrato de destino]</h1>
      </BankAccountContainer>
    );
  };

  const handleLoadData = (term, callback) => {
    onListBankAccounts(term, data => {
      const newData = _.filter(data, f => f.id !== _.get(bankAccountMovementFrom, 'id'));
      callback(newData);
    });
  };

  const getSecondaryActions = ({ isSubmitting, values, setSubmitting }) => {
    if (!bankAccountMovementFrom) return [];
    return [
      {
        label: 'Retornar para Ordens de pagamento',
        action: () => {
          setSubmitting(true);
          const data = _.cloneDeep(values);
          delete data.bankAccount;
          handleOnSubmit(data, { setSubmitting });
        },
        type: 'button',
        disabled: isSubmitting || _.size(selecteds) === 0
      }
    ];
  };

  return (
    <Formik initialValues={{}} validationSchema={schema} onSubmit={handleOnSubmit} validateOnMount={false}>
      {({ errors, isValid, touched, handleSubmit, handleReset, isSubmitting, values, setSubmitting }) => {
        return (
          <Modal
            width="950px"
            height="600px"
            title={getTitle()}
            hideClose={true}
            open={isOpen}
            bindRefWithSubmit={true}
            hide={closeModal}
            actions={[
              {
                label: 'Cancelar',
                action: () => {
                  handleReset();
                  closeModal();
                }
              },
              {
                label: 'Confirmar',
                action: handleSubmit,
                type: 'submit',
                disabled: !isValid || isSubmitting || isInvalid(values),
                primary: true
              }
            ]}
            secondaryActions={getSecondaryActions({ isSubmitting, values, setSubmitting })}
          >
            <ModalContainer>
              <Form>
                <Autocomplete
                  autoFocus={true}
                  name="bankAccount"
                  keyField="id"
                  clearable={true}
                  label="Contrato"
                  valueFormat={formatBankAccountSimple}
                  optionsFormat={row => formatBankAccount(row, 'Empresa')}
                  tipText={'Digite... '}
                  loadingText={'Carregando...'}
                  notFoundText={'Não encontrada'}
                  emptyText={'Pesquise um contrato'}
                  value={_.get(values, 'bankAccount')}
                  loadData={handleLoadData}
                  hasError={_.get(errors, 'bankAccount') && _.get(touched, 'bankAccount')}
                />
              </Form>

              <div style={{ height: '90%' }}>
                <DataTable
                  emptyText="Nenhuma ordem de pagamento encontrada"
                  data={{
                    rows: getDataList(values)
                  }}
                  columns={dataColumns}
                  noPagination={true}
                  hideUpdateButton={true}
                  onRowClicked={handleOnChangeSelect}
                  extraOptions={{
                    ignoreRowClicked: true,
                    selectableRows: true,
                    selectableRowsHighlight: false,
                    selectableRowSelected: row => {
                      return _.find(selecteds, { id: row.id });
                    },
                    onSelectedRowsChange: ({ allSelected, selectedCount, selectedRows }) => {
                      handleOnChangeSelect(selectedRows);
                    }
                  }}
                />
              </div>
            </ModalContainer>
          </Modal>
        );
      }}
    </Formik>
  );
};

const schema = Yup.object().shape({
  bankAccount: Yup.mixed().required('Selecione a contrato')
});

export default PreparePaymentOrderModal;
