import React from 'react';
import * as Yup from 'yup';
import _ from 'lodash';
import { formats } from '~/helper';
import { FormContainer } from '~/pages/bank-contracts/styles';
import Fieldset from '~/components/fieldset';
import { Checkbox, InputLabel, Autocomplete, Select } from '~/components/form';
import { Row, BsContainer } from '~/components/layout';
import { ExtraSchema } from '~/pages/bank-contracts/fields-extra';
import { ApiSchema } from '~/pages/bank-contracts/fields-api';
import { ChargeFields, ChargeSchema } from '~/pages/bank-contracts/fields-charge';
import { PaymentFields, PaymentSchema } from '~/pages/bank-contracts/fields-payment';
import { formatBankAccountSimple, formatBankAccount, formatBankAccountGrid } from '~/pages/bank-accounts/form';

export const formatBankContractSimple = (r, omitBankName) => {
  let bankAcct = _.get(r, 'bankAccount') || {},
    bank = _.get(bankAcct, 'bank') || {},
    company = _.get(bankAcct, 'company') || {},
    bankStr = omitBankName ? bank.code : `${bank.code} ${bank.name}`,
    acctNumStr = `${bankAcct.accountNumber}-${bankAcct.accountNumberDigit}`,
    companyStr = `${formats.cnpj_cpf(company.identity)}`;

  return `${bankStr} - ${acctNumStr} - ${companyStr}`;
};

export const formatBankContractCharge = (r, omitBankName) => {
  let bankAcct = _.get(r, 'bankAccount') || {},
    bank = _.get(r, 'bankAccount.bank') || {},
    bankAcctText = formatBankAccountGrid(bankAcct),
    bankStr = omitBankName ? bank.code : `${bank.code} ${bank.name}`,
    layoutStr = _.get(r, 'billType.code') === 40 ? _.get(r, 'billType.name') : _.toUpper(r.layoutType),
    company = _.get(bankAcct, 'company') || {},
    companyStr = `${formats.cnpj_cpf(company.identity)} - ${company.name}`;

  return `${bankStr} - ${bankAcctText} - ${layoutStr} - ${companyStr}`;
};

export const formatBankContractTitle = r => {
  let bankAcct = _.get(r, 'bankAccount') || {},
    bank = _.get(bankAcct, 'bank') || {},
    bankStr = `${bank.code} ${bank.name}`,
    layoutStr = _.toUpper(r.layoutType),
    typeStr = r.movementType === 'charge' ? 'Cobrança' : 'Pagamento',
    acctNumStr = `${bankAcct.accountNumber}-${bankAcct.accountNumberDigit}`;

  return `${bankStr} - ${typeStr} ${layoutStr} - ${acctNumStr}`;
};

export const formatBankContract = (r, opts) => {
  let billType = _.get(opts, 'billType'),
    bankAcct = _.get(r, 'bankAccount') || {},
    bank = _.get(bankAcct, 'bank') || {},
    company = _.get(bankAcct, 'company') || {},
    layoutStr = _.get(r, 'billType.code') === 40 ? 'Transferência' : _.toUpper(r.layoutType),
    typeStr = r.movementType === 'charge' ? 'Cobrança' : 'Pagamento',
    bankStr = `${bank.code} ${bank.name}`,
    billTypeStr = _.get(r, 'billType.name'),
    branchStr = `${bankAcct.bankBranch}-${bankAcct.bankBranchDigit}`,
    acctNumStr = `${bankAcct.accountNumber}-${bankAcct.accountNumberDigit}`,
    companyStr = `${formats.cnpj_cpf(company.identity)} - ${company.name}`;

  return (
    <span>
      <strong>Banco</strong>: {bankStr}
      <br />
      {typeStr} - {layoutStr}
      {billType && (
        <>
          , <strong>Tipo</strong>: {billTypeStr}{' '}
        </>
      )}
      <br />
      <strong>Agência</strong>: {branchStr}, <strong>Conta</strong>: {acctNumStr}
      <br />
      <strong>Empresa</strong>: {companyStr}
    </span>
  );
};

const getShippingNamingOpts = (settings, movement) => {
  let data = [],
    list = _.filter(_.get(settings, 'shippingNaming') || [], { movement });
  _.each(list, item => {
    data.push({ value: item.name, label: item.name });
  });
  return data;
};

const getMovementTypes = data => {
  const list = [{ value: '', label: 'Escolha o tipo' }],
    { hasPayment, hasSlip } = _.get(data, 'bank.settings') || _.get(data, 'bankAccount.bank.settings') || {};

  if (hasSlip) {
    list.push({ value: 'charge', label: 'Cobrança' });
  }
  if (hasPayment) {
    list.push({ value: 'payment', label: 'Pagamento' });
  }
  return list;
};

export const BankContractForm = ({
  errors,
  status,
  touched,
  values,
  previewMode,
  setFieldTouched,
  setFieldValue,
  onListEmailTemplates,
  onListBillTypes,
  onListBankAccounts
}) => {
  let bank = _.get(values, 'bankAccount.bank'),
    settings = _.get(bank, 'settings') || {},
    hasAccount = !!_.get(values, 'bankAccount'),
    movementType = _.get(values, 'movementType'),
    isCharge = movementType === 'charge',
    isPayment = movementType === 'payment',
    hasEntityBind = _.get(values, 'hasEntityBind'),
    hasAutoClose = String(_.get(values, 'autoCloseShipping')) === 'true',
    hasCycle = String(_.get(values, 'autoShipping')) === 'true' || String(_.get(values, 'autoCloseShipping')) === 'true',
    hasDisablefileDestination =
      String(_.get(values, 'fileDestination')) === 'van' && /^(COB|PAG)$/.test(String(_.get(values, 'shippingNaming').substring(0, 3))),
    billType = _.get(values, 'billType.code'),
    isDigital = _.get(values, 'bankAccount.bank.onlyApi');

  let emptySettings = !!(isCharge && _.isEmpty(_.get(settings,'charge')));

  return (
    <FormContainer>
      <BsContainer>
        <Checkbox name="active" label="Ativo" halfHeight={true} disabled={previewMode || !hasAccount} />

        <Autocomplete
          name="bankAccount"
          keyField="id"
          label="Conta bancária"
          disabled={previewMode || hasEntityBind}
          clearable={true}
          value={_.get(values, 'bankAccount')}
          valueFormat={formatBankAccountSimple}
          optionsFormat={row => formatBankAccount(row, 'Beneficiário')}
          loadData={onListBankAccounts}
          emptyText={'Pesquise uma conta bancária'}
          tipText={'Digite... '}
          loadingText={'Carregando...'}
          notFoundText={'Não encontrada'}
          onChange={val => {
            setFieldValue('bankAccount', val);
            setFieldValue('autoShippingIntervalInMin', '0');
            setFieldValue('extraFields', {});
            setFieldValue('movementType', _.get(getMovementTypes(val), '[1].value'));
            if(_.get(getMovementTypes(val), '[1].value') === 'payment'){
              setFieldValue('layoutType', 'cnab240');
            }
          }}
        />

        {hasAccount && (
          <>
            <Row span={3}>
              <InputLabel label="Agência" value={`${_.get(values, 'bankAccount.bankBranch')}-${_.get(values, 'bankAccount.bankBranchDigit')}`} />
              <InputLabel
                label="Número da conta bancária"
                value={`${_.get(values, 'bankAccount.accountNumber')}-${_.get(values, 'bankAccount.accountNumberDigit')}`}
              />
              <InputLabel value={_.get(values, 'bankAccount.bank.onlyApi') ? 'Sim' : 'Não'} label="Banco Digital" />
            </Row>

            <InputLabel
              label="Beneficiário"
              value={`${formats.cnpj_cpf(_.get(values, 'bankAccount.company.identity'))} - ${_.get(values, 'bankAccount.company.name')}`}
            />
          </>
        )}

        <Row span={isCharge ? 2 : 1}>
          <Select
            name="movementType"
            label="Tipo"
            disabled={previewMode || !hasAccount || hasEntityBind}
            value={_.get(values, 'movementType') || ''}
            hasError={errors.layoutType && touched.layoutType}
            options={{
              values: getMovementTypes(values)
            }}
            onChange={evt => {
              let value = _.get(evt, 'target.value');
              setFieldValue('movementType', value);
              setFieldValue('layoutType', value === 'payment' ? 'cnab240' : '');
            }}
          />

          {isCharge && (
            <Autocomplete
              name="billType"
              keyField="id"
              label="Tipo da cobrança"
              disabled={previewMode || !hasAccount || hasEntityBind}
              value={_.get(values, 'billType')}
              valueFormat={row => `${row.name || ''}`}
              loadData={onListBillTypes}
              emptyText={'Selecione o tipo da cobrança'}
              tipText={'Digite... '}
              loadingText={'Carregando...'}
              notFoundText={'Não encontrado'}
              onChange={value => {
                setFieldValue('billType', value);
                if (value.code === 40) {
                  setFieldValue('layoutType', 'cnab240');
                  setFieldValue('autoShipping', 'false');
                  setFieldValue('autoShippingIntervalInMin', '0');
                  setFieldValue('shippingNaming', _.get(_.first(getShippingNamingOpts(settings, movementType)), 'value'));
                }
              }}
            />
          )}
        </Row>

        {isCharge && (
          <ChargeFields
            settings={_.get(settings, 'charge') || {}}
            bankIsDigital={isDigital}
            values={values}
            errors={errors}
            touched={touched}
            setFieldValue={setFieldValue}
            hasEntityBind={hasEntityBind}
            onListEmailTemplates={onListEmailTemplates}
            billType={_.get(values, 'billType.code')}
            previewMode={previewMode || !hasAccount}
          />
        )}

        {isPayment && (
          <PaymentFields
            settings={_.get(settings, 'payment') || {}}
            values={values}
            errors={errors}
            touched={touched}
            setFieldValue={setFieldValue}
            hasEntityBind={hasEntityBind}
            previewMode={previewMode || !hasAccount}
          />
        )}

        {billType !== 40 && !isDigital && !emptySettings &&(
          <Fieldset label="Troca de arquivos com o banco">
            <Row span={2 + (hasAutoClose ? 1 : 0)}>
              <Select
                name="autoShipping"
                label="Geração de arquivo de remessa"
                disabled={previewMode || !hasAccount}
                hasError={_.get(errors, 'autoShipping') && _.get(touched, 'autoShipping')}
                options={{
                  values: [
                    { value: 'false', label: 'Manual' },
                    { value: 'true', label: 'Automática' }
                  ]
                }}
                onChange={evt => {
                  let val = evt.target.value;
                  setFieldValue('autoShipping', val);
                  if (!values.autoShippingIntervalInMin && String(val) === 'true') {
                    setFieldValue('autoShippingIntervalInMin', 15);
                  }
                }}
              />

              <Select
                name="autoCloseShipping"
                label="Fechamento da remessa"
                disabled={previewMode || !hasAccount}
                hasError={_.get(errors, 'autoCloseShipping') && _.get(touched, 'autoCloseShipping')}
                options={{
                  values: [
                    { value: 'false', label: 'Manual' },
                    { value: 'true', label: 'Automática' }
                  ]
                }}
                onChange={evt => {
                  let val = evt.target.value;
                  setFieldValue('autoCloseShipping', val);

                  if (String(val) === 'false') {
                    setFieldValue('fileDestination', '');
                  }

                  if (String(val) === 'true') {
                    if (!values.autoShippingIntervalInMin) {
                      setFieldValue('autoShippingIntervalInMin', 15);
                    }
                    setFieldValue('fileDestination', 'erp');
                  } else {
                    setFieldValue('autoShippingIntervalInMin', null);
                    setFieldValue('fileDestination', null);
                  }
                }}
              />

              {hasAutoClose && (
                <Select
                  name="fileDestination"
                  label="Disponibilização do arquivo"
                  disabled={previewMode || !hasAccount}
                  hasError={_.get(errors, 'fileDestination') && _.get(touched, 'fileDestination')}
                  options={{
                    values: [
                      { value: '', label: 'Escolha uma opção' },
                      { value: 'van', label: 'DocPay - VAN' },
                      { value: 'erp', label: 'ERP' }
                    ]
                  }}
                  onChange={evt => {
                    let val = evt.target.value;
                    setFieldValue('fileDestination', val);
                    if (val === 'van') {
                      const options = getShippingNamingOpts(settings, movementType);

                      if (_.get(values, 'movementType') === 'payment') {
                        options.forEach(c => {
                          if (c.value.substring(0, 3) === 'PAG') {
                            setFieldValue('shippingNaming', c.value);
                          }
                        });
                      }
                      if (_.get(values, 'movementType') === 'charge') {
                        options.forEach(c => {
                          if (c.value.substring(0, 3) === 'COB') {
                            setFieldValue('shippingNaming', c.value);
                          }
                        });
                      }
                    }
                  }}
                />
              )}
            </Row>
            {hasCycle && (
              <Row span={1}>
                <Select
                  name="autoShippingIntervalInMin"
                  label="Intervalo para o ciclo de geração das remessas"
                  disabled={previewMode || !hasAccount}
                  hasError={_.get(errors, 'autoShippingIntervalInMin') && _.get(touched, 'autoShippingIntervalInMin')}
                  options={{
                    values: [
                      { value: '1', label: 'a cada 1 minuto' },
                      { value: '15', label: 'a cada 15 minutos' },
                      { value: '30', label: 'a cada 30 minutos' },
                      { value: '45', label: 'a cada 45 minutos' }
                    ]
                  }}
                />
              </Row>
            )}

            <Select
              name="shippingNaming"
              label="Padrão de nomeação das remessas"
              disabled={hasDisablefileDestination || previewMode || !hasAccount}
              hasError={_.get(errors, 'shippingNaming') && _.get(touched, 'shippingNaming')}
              options={{ values: [{ value: '', label: 'Selecione um padrão' }, ...getShippingNamingOpts(settings, movementType)] }}
            />

            <Select
              name="documentBindPolicy"
              label="Permissão de vínculo de documentos"
              disabled={previewMode || !hasAccount}
              hasError={_.get(errors, 'documentBindPolicy') && _.get(touched, 'documentBindPolicy')}
              options={{
                values: [
                  { value: 'any', label: 'Livre' },
                  { value: 'baseCnpj', label: 'Apenas para mesma raiz de CNPJ' },
                  { value: 'cnpj', label: 'Apenas para o CNPJ completo' }
                ]
              }}
            />
          </Fieldset>
        )}
      </BsContainer>
    </FormContainer>
  );
};

export const BankContractSchema = (values, settings, movementType, billType) => {
  let isCharge = movementType === 'charge',
    isPayment = movementType === 'payment',
    layoutType = _.get(values, 'layoutType'),
    emptySettings= !!(isCharge && _.isEmpty(_.get(settings,'charge')));

  return Yup.object().shape({
    bankAccount: Yup.object().required('Selecione a conta bancária').nullable(),
    movementType: Yup.string().required('Informe o Tipo'),
    layoutType: Yup.mixed().test('match', '', function(value) {
      const { path, parent, createError } = this,
        val = value || _.get(parent, 'layoutType'),
        isDigital = value || _.get(parent, 'bankAccount.bank.onlyApi');

      if (!isDigital && !val) {
        return createError({ path, message: 'Informe o Layout' });
      }
      return true;
    }),
    nextShippingNumber: Yup.mixed().test('match', '', function(value) {
      const { path, parent, createError } = this;
      const val = value || _.get(parent, 'nextShippingNumber'),
        hasShippingSequence = (isCharge && billType !== 40) || (_.get(settings, 'payment.hasShippingSequence') && isPayment),
        isDigital = value || _.get(parent, 'bankAccount.bank.onlyApi');

      if (!isDigital) {
        if (hasShippingSequence && !val) {
          return createError({ path, message: 'Informe o último número de remessa' });
        } else if (hasShippingSequence && val && isNaN(val)) {
          return createError({ path, message: 'Informe um valor numérico' });
        }
      }
      return true;
    }),
    agreementNumber: Yup.mixed().test('match', '', function(value) {
      const { path, parent, createError } = this;
      const val = value || _.get(parent, 'agreementNumber'),
        hasAgreement =
          (isCharge && billType !== 40 && !_.isEmpty(_.get(settings, 'charge.agreementSizes'))) || (isPayment && _.get(settings, 'payment.hasAgreement'));

      if (hasAgreement && !val) {
        return createError({ path, message: 'Informe o número de convênio' });
      } else if (hasAgreement && val && (isNaN(val) && isCharge)) {
        return createError({ path, message: 'Informe um valor numérico' });
      }
      return true;
    }),
    fileDestination: Yup.mixed().test('match', '', function(value) {
      const { path, parent, createError } = this;
      const val = value || _.get(parent, 'fileDestination'),
        hasVal = String(_.get(parent, 'autoShippingIntervalInMin')) === '15';

      if (hasVal && !val) {
        return createError({ path, message: 'Informe a disponibilização do arquivo' });
      }
      return true;
    }),
    shippingNaming: Yup.mixed().test('match', '', function(value) {
      const { path, parent, createError } = this;
      const val = value || _.get(parent, 'shippingNaming'),
        hasVal = isPayment || (isCharge && [10].includes(billType)),
        isDigital = value || _.get(parent, 'bankAccount.bank.onlyApi');

      if (!isDigital && hasVal && !val) {
        return createError({ path, message: 'Informe o padrão de nomeação das remessas' });
      }
      return true;
    }),
    billType: Yup.mixed().test('match', '', function(value) {
      const { path, parent, createError } = this;
      const val = value || _.get(parent, 'billType');

      if (isCharge && !val) {
        return createError({ path, message: 'Informe o tipo de cobrança' });
      }

      if(isCharge && emptySettings && _.get(val,'code') === 10) {
        return createError({ path, message: 'Tipo de cobrança não implementado' });
      }

      return true;
    }),

    ...(!isCharge ? {} : ChargeSchema(values, settings, billType)),
    ...(!isPayment ? {} : PaymentSchema(values, settings)),
    ...(isCharge && billType === 40 ? {} : ExtraSchema(values, settings, isCharge ? 'charge.fields' : 'payment.fields', layoutType)),
    ...ApiSchema(values, settings)
  });
};
