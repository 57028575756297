import styled from "styled-components";
import { gray } from '../mixins/color'

export const Container = styled.div`
  display: flex;
  width: 100%;
  user-select: none;
`;

export const Content = styled.div`
  height: 60px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
  display: flex;
  align-items: center;
`;

export const Toolbar = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  gap: 10px;
  flex: 1;
  overflow: hidden;

  .main-title {
    display: flex;
    flex-direction: column;
    justify-content: ${(props) => !props.hasSubtitle ? 'center' : 'space-between'};
    width: calc(100% - 100px);
    max-width: calc(100% - 100px);
    overflow: hidden;
    flex: 1;
    height: 60px;
  }

  .title {
    display: flex;
    overflow: hidden;
    height: 32px;
    align-items: flex-start;
    justify-content: ${(props) => !props.hasSubtitle ? 'center' : 'flex-end'};
    flex-direction: column;
  }

  .subtitle {
    display: flex;
    overflow: hidden;
    flex-wrap: wrap;
    height: 28px;
    align-items: center;
    justify-content:flex-start;
    flex-direction: row;
    color: ${gray.fade(0.8).hex()};
    font-weight: 400;
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: break-spaces;
    line-height: 14px;

    .spacing {
      padding: 0 5px;
    }
    .break {
      flex-basis: 100%;
      height: 0;
    }
  }
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 0 0 260px;
  width: 260px;
  max-width: 260px;
  padding-left: 10px;
  margin-left: 10px;
  border-left: 1px solid rgba(0, 0, 0, 0.03);
  cursor: default;

  @media (max-width: 1024px) {
    display: none;
  }

  .profile-info {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    flex: 1;

    strong {
      display: block;
      color: #555;
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 200px;
    }

    span {
      display: block;
      margin-top: 1px;
      font-size: 12px;
      color: #999;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: lowercase;
      max-width: 200px;

      &::first-line {
        text-transform: capitalize;
      }
    }
  }
  .profile-avatar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    width: 50px;
    flex: 0 0 50px;
  }

  img {
    height: 35px;
    width: 35px;
    object-fit: cover;
    border-radius: 10px;
    transition: background 0.5s;

    &:hover {
      opacity: 0.6;
    }
  }
`;
