import React from 'react';
import { Select, InputLabel, InputGroup, CurrencyField } from '~/components/form';
import Fieldset from '~/components/fieldset';
import { BsRow, BsCol } from '~/components/layout';
import _ from 'lodash';
import * as Yup from 'yup';
import { formats } from '~/helper';
import DataTable from '~/components/datatable';
import { getOccurrenceReasonLabel } from '~/pages/settings/response-constants';
import { DetailTableContainer, TinyDetailTableContainer, RowExpander, RowInstruction } from '~/pages/bills/styles';
import { InstructionFieldForm } from '~/pages/bank-contracts/instruction-fields';
import useSearchParameters from '~/hooks/use-search-params';
import { billEventsMap } from '~/constants';
import { blue, green, purple } from '~/components/mixins/color';
import CellStatus from '~/components/datatable/cell-status';

export const id = 10;

const statsColors = {
  open: green.hex(),
  closed: blue.hex(),
  canceled: '#BA1F33',
  processing: purple.hex()
};

const OccurenceRow = ({ data, bankCode }) => {
  let list = _.get(data, 'extras.occurrenceReasons') || [],
    occurrence = _.get(data, 'occurrence.code');

  return (
    <RowExpander>
      {_.map(list, (row, index) => (
        <div key={index}> {getOccurrenceReasonLabel(bankCode, occurrence, row)} </div>
      ))}
    </RowExpander>
  );
};

const renderApiError = values => {
  const errors = _.get(values, 'registerApiData.errors') || {};
  const payload = _.get(values, 'registerApiData.payload') || {};

  if (_.isEmpty(errors) && _.isEmpty(payload)) {
    return null;
  }
  return (
    <BsRow>
      <BsCol md={24}>
        <Fieldset label="Registro via API - Log">
          <TinyDetailTableContainer>
            <DataTable
              noPagination={true}
              emptyText="Nenhuma remessa vinculada"
              hideUpdateButton={true}
              data={{ rows: [_.get(values, 'registerApiData')] }}
              onRowClicked={_.noop}
              padding="0"
              columns={[
                {
                  name: 'Data',
                  selector: 'createdAt',
                  compact: true,
                  width: '140px',
                  format: row => formats.dateTimeZone(_.get(row, 'time'), 'dd/MM/yyyy HH:mm')
                },
                {
                  name: 'Usuário',
                  selector: 'user',
                  compact: true,
                  width: '250px',
                  format: row => _.get(row, 'user.name')
                },
                {
                  name: 'Código erro',
                  selector: 'errors',
                  center: true,
                  compact: true,
                  width: '100px',
                  format: row => _.get(row, 'errors.code') || '00'
                },
                {
                  name: 'Mensagem de erro',
                  selector: 'errors',
                  compact: true,
                  cell: row => {
                    let text = _.get(row, 'errors.message') || JSON.stringify(row.payload);
                    return (
                      <div className="text-truncate" title={text}>
                        {text}
                      </div>
                    );
                  }
                }
              ]}
            />
          </TinyDetailTableContainer>
        </Fieldset>
      </BsCol>
    </BsRow>
  );
};

const columnsShipping = bankIsDigital => {
  const data = [
    {
      name: ' ',
      selector: 'status',
      compact: true,
      width: '10px',
      cell: row => <CellStatus title="" color={statsColors[_.get(row, 'movement.status') || 'open']} />
    },
    {
      name: 'Layout',
      selector: 'movement',
      compact: true,
      width: '90px',
      format: row => _.toUpper(_.get(row, 'movement.bankContract.layoutType'))
    },
    {
      name: 'Ocorrência',
      selector: 'occurrence',
      wrap: true,
      compact: true,
      format: row => `${_.get(row, 'occurrence.code')} - ${_.get(row, 'occurrence.description')}`
    },
    {
      name: 'Data da ocorrência',
      selector: 'createdAt',
      center: true,
      compact: true,
      width: '140px',
      format: row => formats.dateTimeZone(_.get(row, 'extras.occurrenceDate') || _.get(row, 'createdAt'), 'dd/MM/yyyy HH:mm')
    }
  ];
  if (bankIsDigital) {
    data.splice(1, 1);
  }
  return data;
};

const columnsResponse = bankIsDigital => {
  const data = [
    {
      name: 'Layout',
      selector: 'movement',
      width: '90px',
      format: row => _.toUpper(_.get(row, 'movement.bankContract.layoutType'))
    },
    {
      name: 'Ocorrência',
      selector: 'occurrence',
      wrap: true,
      compact: true,
      format: row => `${_.get(row, 'occurrence.code')} - ${_.get(row, 'occurrence.description')}`
    },
    {
      name: 'Data da ocorrência',
      selector: 'createdAt',
      width: '140px',
      center: true,
      compact: true,
      format: row => formats.dateTimeZone(_.get(row, 'extras.occurrenceDate') || _.get(row, 'createdAt'), 'dd/MM/yyyy')
    }
  ];
  if (bankIsDigital) {
    data.splice(0, 1);
  }
  return data;
};

const ShippingOccurence = ({ data }) => {
  const shipping = _.cloneDeep(data)
  const type = _.get(shipping,'occurrenceEvent') || ''
  let result = ''

  if(type === 'protest'){
    const protestMode = _.get(shipping,'protestDaysMode') === '1'? 'Dias corridos' : 'Dias úteis',
      protestDays = _.get(shipping,'protestDays') || 0;
    
    result = `Tipo de dia: ${protestMode}, Dias: ${protestDays}`
  }
  if(type === 'due_date_change'){
    const dueDate = formats.dateTimeZone(_.get(shipping, 'dueDate'), 'dd/MM/yyyy')
    result = `Data de vencimento: ${dueDate}`
  }
  if(type === 'decrement' || type === 'cancel_decrement'){
    const decrementValue = formats.currency(_.get(shipping,'decrementValue') || 0)
    result = `Valor: ${decrementValue}`
  }
  return (
    <RowInstruction>
      <div> {result} </div>
    </RowInstruction>
  );
};

const renderMovements = (values, onOpenMovement) => {
  const registeredFromApi = _.get(values, 'registerApiData.registered');

  let movements = _.cloneDeep(_.get(values, 'movementsOcc') || []);
  let bankCode = _.get(values, 'bankContract.bankAccount.bank.code'),
    type = _.toLower(_.get(values, 'bankContract.layoutType')) || 'cnab240';

  if (registeredFromApi) {
    movements.unshift({
      createdAt: _.get(values, 'registerApiData.time'),
      movement: {
        status: 'closed',
        movementType: 'charge',
        fileType: 'response',
        bankContract: {
          id: _.get(values, 'bankContract.id'),
          layoutType: _.get(values, 'bankContract.layoutType')
        }
      },
      occurrence: {
        code: '02',
        description: 'Entrada confirmada (via API)'
      }
    });

    movements.unshift({
      createdAt: _.get(values, 'createdAt'),
      movement: {
        status: 'closed',
        movementType: 'charge',
        fileType: 'shipping',
        bankContract: {
          id: _.get(values, 'bankContract.id'),
          layoutType: _.get(values, 'bankContract.layoutType')
        }
      },
      occurrence: {
        code: '01',
        description: 'Entrada de título (via API)'
      }
    });
  }

  if (_.isEmpty(movements)) {
    return null;
  }
  let shippings = _.orderBy(
      _.filter(movements, r => _.get(r, 'movement.fileType') === 'shipping'),
      ['createdAt'],
      ['desc']
    ),
    responses = _.orderBy(
      _.filter(movements, r => _.get(r, 'movement.fileType') === 'response'),
      ['createdAt'],
      ['desc']
    );
  
  shippings = _.map(shippings,(row)=>{
    const event = _.get(row,'occurrence.code')
    row.occurrenceEvent = _.get(billEventsMap, `[${bankCode}].[${type}].[${event}]`) || ''
    return row
  })

  return (
    <BsRow>
      <BsCol md={24} lg={12} xl={12}>
        <Fieldset label="Remessas">
          <DetailTableContainer>
            <DataTable
              noPagination={true}
              emptyText="Nenhuma remessa vinculada"
              hideUpdateButton={true}
              data={{ rows: shippings }}
              onRowClicked={onOpenMovement}
              padding="0"
              columns={columnsShipping(registeredFromApi)}
              extraOptions={{
                expandableRows: true,
                highlightOnHover: true,
                expandOnRowClicked: true,
                selectableRows: false,
                selectableRowsHighlight: false,
                expandableRowDisabled: row => !/^(protest|due_date_change|decrement|cancel_decrement)$/.test(_.get(row, 'occurrenceEvent')),
                expandableRowsComponent: <ShippingOccurence data={values} />
              }}
            />
          </DetailTableContainer>
        </Fieldset>
      </BsCol>

      <BsCol md={24} lg={12} xl={12}>
        <Fieldset label="Retornos">
          <DetailTableContainer>
            <DataTable
              noPagination={true}
              emptyText="Nenhum retorno vinculado"
              data={{ rows: responses }}
              onRowClicked={onOpenMovement}
              padding="0"
              columns={columnsResponse(registeredFromApi)}
              extraOptions={{
                expandableRows: true,
                highlightOnHover: true,
                expandOnRowClicked: true,
                selectableRows: false,
                selectableRowsHighlight: false,
                expandableRowDisabled: row => _.isEmpty(_.get(row, 'extras.occurrenceReasons')),
                expandableRowsComponent: <OccurenceRow data={values} bankCode={bankCode} />
              }}
            />
          </DetailTableContainer>
        </Fieldset>
      </BsCol>
    </BsRow>
  );
};
const canUseField = (instructionsConfig, field, values) => {
  let conditional = _.get(instructionsConfig, `fieldsCondition[${field}]`),
    layoutFields = _.get(instructionsConfig, `fields`) || [];

  if (!layoutFields.includes(field)) {
    return false;
  }
  if (!conditional) {
    return true;
  }
  let ref = _.get(values, `billData.${conditional.field}`),
    evalIn = conditional.in,
    evalNotIn = conditional.notIn;

  return (!evalIn || (evalIn && evalIn.includes(ref))) && (!evalNotIn || (evalNotIn && !evalNotIn.includes(ref)));
};

export const Render = ({ errors, previewMode, history, touched, setFieldValue, isValid, values }) => {
  const bankCode = _.get(values, 'bankContract.bankAccount.bank.code') || '';
  const layoutType = _.get(values, 'bankContract.layoutType') || '';
  const bankKey = `${bankCode}.${layoutType}`;
  const bankSettings = _.get(values, 'bankContract.bankAccount.bank.settings.charge') || {};
  const bankDocTypes = _.get(bankSettings, `documentTypes.${layoutType}`);
  const allowedDocumentTypes = _.map(bankDocTypes, ({ id, value }) => ({ label: value, value: id }));
  const instructionsConfig = _.get(bankSettings, `instructions.${layoutType}`) || {};

  const hasInterest = canUseField(instructionsConfig, 'hasInterest', values);
  const hasInterestDays = canUseField(instructionsConfig, 'interestDays', values);
  const hasFine = canUseField(instructionsConfig, 'hasFine', values);
  const hasFineDays = canUseField(instructionsConfig, 'fineDays', values);
  const hasDiscount = canUseField(instructionsConfig, 'hasDiscount', values);
  const hasDiscountDays = canUseField(instructionsConfig, 'discountDays', values);
  const hasProtestDays = canUseField(instructionsConfig, 'protestDays', values);
  const hasWriteOffDays = canUseField(instructionsConfig, 'writeOffDays', values);
  const hasRegisterTwo = canUseField(instructionsConfig, 'hasRegisterTwo', values);
  const isDiscountWallet = _.get(values, 'wallet', '1') === '2';
  const editLocked = _.get(values, 'editLocked') || false;

  const { location } = useSearchParameters()

  function handleOpenMovement({ movement }) {
    let id = _.get(movement, 'id'),
      fileType = _.get(movement, 'fileType');
    if (id && fileType) {
      const currentPage = _.get(_.reject(_.get(location, 'pathname', '').split('/'), _.isEmpty), '[0]');
      history.push(`/${fileType === 'response' ? 'responses' : 'shippings'}/${id}`, {from: currentPage});
    }
  }

  return (
    <Fieldset label="Boleto">
      <BsRow>
        <BsCol md={6} lg={3} xl={3}>
          <InputLabel label="Nosso número" value={_.get(values, 'billData.ourNumber') || ''} />
        </BsCol>

        <BsCol md={6} lg={3} xl={isDiscountWallet ? 2 : 3}>
          <Select
            name="billData.accept"
            label="Aceite"
            disabled={previewMode || editLocked}
            hasError={_.get(errors, 'billData.accept') && _.get(touched, 'billData.accept')}
            options={{
              values: [
                { value: 'false', label: 'Não' },
                { value: 'true', label: 'Sim' }
              ]
            }}
          />
        </BsCol>

        <BsCol md={6} lg={5} xl={isDiscountWallet ? 4 : 5}>
          <Select
            name="billData.documentType"
            label="Tipo de Documento *"
            disabled={previewMode || editLocked}
            hasError={_.get(errors, 'billData.documentType') && _.get(touched, 'billData.documentType')}
            options={{ values: [{ value: '', label: 'Escolha o tipo de documento' }, ...allowedDocumentTypes] }}
          />
        </BsCol>

        {isDiscountWallet && (
          <BsCol md={6} lg={5} xl={2}>
            <InputLabel label="Tipo de Boleto" value="Descontado" />
          </BsCol>
        )}

        <BsCol md={6} lg={hasProtestDays && hasWriteOffDays ? 5 : hasProtestDays || hasWriteOffDays ? 9 : 13}>
          <InputGroup
            type="text"
            name="billData.paymentPlace"
            label="Local de Pagamento"
            maxLength={200}
            disabled={previewMode || editLocked}
            hasError={_.get(errors, 'billData.paymentPlace') && _.get(touched, 'billData.paymentPlace')}
          />
        </BsCol>

        {hasProtestDays && (
          <BsCol md={8} lg={4}>
            <InputGroup
              type="number"
              name="billData.protestDays"
              label="Dias para Protesto"
              disabled={previewMode || editLocked}
              maxLength={3}
              hasError={_.get(errors, 'billData.protestDays') && _.get(touched, 'billData.protestDays')}
            />
          </BsCol>
        )}

        {hasWriteOffDays && (
          <BsCol md={8} lg={4}>
            <InputGroup
              type="number"
              name="billData.writeOffDays"
              label="Dias para Baixa"
              disabled={previewMode || editLocked}
              maxLength={3}
              hasError={_.get(errors, 'billData.writeOffDays') && _.get(touched, 'billData.writeOffDays')}
            />
          </BsCol>
        )}

        {hasInterest && (
          <>
            <BsCol md={8} lg={hasInterestDays ? 4 : 8}>
              <CurrencyField
                name="billData.interestValue"
                label={'Percentual diário de juros de mora'}
                precision={6}
                disabled={previewMode || editLocked}
                hasError={_.get(errors, 'billData.interestValue') && _.get(touched, 'billData.interestValue')}
                onChange={value => {
                  setFieldValue('billData.interestType', value > 0 ? 'dailyPercentage' : 'none');
                }}
              />
            </BsCol>

            {hasInterestDays && (
              <BsCol md={8} lg={4}>
                <InputGroup
                  type="number"
                  name="billData.interestDays"
                  label="Dias para juros de mora"
                  disabled={previewMode || !(_.get(values, 'billData.interestValue') > 0) || editLocked}
                  hasError={_.get(errors, 'billData.interestDays') && _.get(touched, 'billData.interestDays')}
                />
              </BsCol>
            )}
          </>
        )}

        {hasFine && (
          <>
            <BsCol md={8} lg={hasFineDays ? 4 : 8}>
              <CurrencyField
                name="billData.fineValue"
                label={'Percentual de multa'}
                disabled={previewMode || editLocked}
                hasError={_.get(errors, 'billData.fineValue') && _.get(touched, 'billData.fineValue')}
                onChange={value => {
                  setFieldValue('billData.fineType', value > 0 ? 'percentage' : 'none');
                }}
              />
            </BsCol>

            {hasFineDays && (
              <BsCol md={8} lg={4}>
                <InputGroup
                  type="number"
                  name="billData.fineDays"
                  label="Dias para multa"
                  disabled={previewMode || !(_.get(values, 'billData.fineValue') > 0) || editLocked}
                  hasError={_.get(errors, 'billData.fineDays') && _.get(touched, 'billData.fineDays')}
                />
              </BsCol>
            )}
          </>
        )}

        {hasDiscount && (
          <>
            <BsCol md={8} lg={hasDiscountDays ? 4 : 8}>
              <CurrencyField
                name="billData.discountValue"
                label={`${_.get(values, 'billData.discountType') !== 'value' ? 'Percentual' : 'Valor'} do desconto`}
                disabled={previewMode || editLocked}
                hasError={_.get(errors, 'billData.discountValue') && _.get(touched, 'billData.discountValue')}
                onChange={value => {
                  if (!value) {
                    setFieldValue('billData.discountType', 'none');
                  } else if (!_.get(values, 'billData.discountType') && value > 0) {
                    setFieldValue('billData.discountType', 'percentage');
                  }
                }}
              />
            </BsCol>

            {hasDiscountDays && (
              <BsCol md={8} lg={4}>
                <InputGroup
                  type="number"
                  name="billData.discountDays"
                  label="Dias para desconto"
                  disabled={previewMode || !(_.get(values, 'billData.discountValue') > 0) || editLocked}
                  hasError={_.get(errors, 'billData.discountDays') && _.get(touched, 'billData.discountDays')}
                />
              </BsCol>
            )}
          </>
        )}

        <BsCol md={24}>
          <InstructionFieldForm
            bankKey={bankKey}
            settings={_.get(bankSettings, `instructions.${layoutType}`) || {}}
            bankContract={values.bankContract}
            errors={errors}
            baseField="billData"
            isValid={isValid}
            touched={touched}
            values={values}
            setFieldValue={setFieldValue}
            previewMode={previewMode}
          />
        </BsCol>

        {hasRegisterTwo && layoutType === 'cnab400' && (
          <BsCol md={8} lg={6}>
            <Select
              name="billData.registerTwo"
              label="Gerar registro 2 (opcional)"
              disabled={previewMode || editLocked}
              value={_.get(values, 'billData.registerTwo') || ''}
              hasError={_.get(errors, 'billData.registerTwo') && _.get(touched, 'billData.registerTwo')}
              options={{ values: [
                { value: '', label: 'Não definido' },
                { value: 'true', label: 'Sim' },
                { value: 'false', label: 'Não' }
              ] }}
            />
          </BsCol>
        )}

        {hasRegisterTwo &&  _.get(values, 'billData.registerTwo') === 'true' && (
          <>
            <BsCol md={24} lg={24} xl={24}>
              <InputGroup
                type="text"
                name="billData.message_1"
                label="Mensagem 1"
                maxLength={80}
                disabled={previewMode || editLocked}
                hasError={_.get(errors, 'billData.message_1') && _.get(touched, 'billData.message_1')}
              />
            </BsCol> 
            <BsCol md={24} lg={24} xl={24}>
              <InputGroup
                type="text"
                name="billData.message_2"
                label="Mensagem 2"
                maxLength={80}
                disabled={previewMode || editLocked}
                hasError={_.get(errors, 'billData.message_2') && _.get(touched, 'billData.message_2')}
              />
            </BsCol>
            <BsCol md={24} lg={24} xl={24}>
              <InputGroup
                type="text"
                name="billData.message_3"
                label="Mensagem 3"
                maxLength={80}
                disabled={previewMode || editLocked}
                hasError={_.get(errors, 'billData.message_3') && _.get(touched, 'billData.message_3')}
              />
            </BsCol>
            <BsCol md={24} lg={24} xl={24}>
              <InputGroup
                type="text"
                name="billData.message_4"
                label="Mensagem 4"
                maxLength={80}
                disabled={previewMode || editLocked}
                hasError={_.get(errors, 'billData.message_4') && _.get(touched, 'billData.message_4')}
              />
            </BsCol>    
          
            <BsCol md={12} lg={6}>
              <CurrencyField
                name="billData.discountValue_2"
                label={'Percentual de desconto 2'}
                disabled={previewMode || editLocked}
                hasError={_.get(errors, 'billData.discountValue_2') && _.get(touched, 'billData.discountValue_2')}
                onChange={value => {
                  setFieldValue('billData.discountType_2', value > 0 ? 'percentage' : 'none');

                  if (!value || value === 0) {
                    setFieldValue('billData.discountDays_2', null);
                  }
                }}
              />
            </BsCol>
            <BsCol md={12} lg={6}>
              <InputGroup
                type="number"
                name="billData.discountDays_2"
                label="Dias para desconto 2"
                disabled={previewMode || !(_.get(values, 'billData.discountValue_2') > 0) || editLocked}
                hasError={_.get(errors, 'billData.discountDays_2') && _.get(touched, 'billData.discountDays_2')}
              />
            </BsCol>

            <BsCol md={12} lg={6}>
              <CurrencyField
                name="billData.discountValue_3"
                label={'Percentual de desconto 3'}
                disabled={previewMode || editLocked}
                hasError={_.get(errors, 'billData.discountValue_3') && _.get(touched, 'billData.discountValue_3')}
                onChange={value => {
                  setFieldValue('billData.discountType_3', value > 0 ? 'percentage' : 'none');

                  if (!value || value === 0) {
                    setFieldValue('billData.discountDays_3', null);
                  }
                }}
              />
            </BsCol>
            <BsCol md={12} lg={6}>
              <InputGroup
                type="number"
                name="billData.discountDays_3"
                label="Dias para desconto 3"
                disabled={previewMode || !(_.get(values, 'billData.discountValue_3') > 0) || editLocked}
                hasError={_.get(errors, 'billData.discountDays_3') && _.get(touched, 'billData.discountDays_3')}
              />
            </BsCol>
          
          </>
        )}

      </BsRow>

      {renderApiError(values)}

      {renderMovements(values, handleOpenMovement)}
    </Fieldset>
  );
};

export const Schema = values => ({
  billData: Yup.object()
    .typeError('Informe os dados da ordem')
    .shape({
      protestDays: Yup.mixed().test('is-int', 'Dias corridos p/ protesto deve ser superior a zero', value => !value || value > 0),
      writeOffDays: Yup.mixed().test('is-int', 'Dias corridos p/ baixa deve ser superior a zero', value => !value || value > 0),
      documentType: Yup.string().required('Informe o tipo de documento'),
      discountValue: Yup.mixed().test('match', '', function(value) {
        const { path, parent, createError } = this;
        const hasValue = /^(percentage|value)$/.test(parent.discountType);
        const tempValue = Number(value);
        if (!!hasValue && (!tempValue || tempValue <= 0)) {
          return createError({ path, message: `Valor deve ser superior a zero` });
        }
        return true;
      }),
      discountDays: Yup.mixed().test('match', '', function(value) {
        const { path, parent, createError } = this;
        const hasValue = /^(percentage|value)$/.test(parent.discountType);
        const tempValue = Number(value);
        if (!!hasValue && tempValue < 0) {
          return createError({ path, message: 'Dias anteriores ao vencimento para aplicar o desconto' });
        }
        return true;
      }),
      interestValue: Yup.mixed().test('match', '', function(value) {
        const { path, parent, createError } = this;
        const hasValue = /^(dailyPercentage)$/.test(parent.interestType);
        const tempValue = Number(value);
        if (!!hasValue && (!tempValue || tempValue <= 0)) {
          return createError({ path, message: `Valor deve ser superior a zero` });
        }
        return true;
      }),
      interestDays: Yup.mixed().test('match', '', function(value) {
        const { path, parent, createError } = this;
        const hasValue = /^(dailyPercentage)$/.test(parent.interestType);
        const tempValue = Number(value);
        if (!!hasValue && tempValue < 0) {
          return createError({ path, message: 'Dias após o vencimento para incidir juros' });
        }
        return true;
      }),
      fineValue: Yup.mixed().test('match', '', function(value) {
        const { path, parent, createError } = this;
        const hasValue = /^(percentage|value)$/.test(parent.fineType);
        const tempValue = Number(value);
        if (!!hasValue && (!tempValue || tempValue <= 0)) {
          return createError({ path, message: `Valor deve ser superior a zero` });
        }
        return true;
      }),
      fineDays: Yup.mixed().test('match', '', function(value) {
        const { path, parent, createError } = this;
        const hasValue = /^(percentage|value)$/.test(parent.fineType);
        const tempValue = Number(value);
        if (!!hasValue && tempValue < 0) {
          return createError({ path, message: 'Dias após o vencimento para incidir multa' });
        }
        return true;
      })
    })
});
