import React from 'react'
import _ from 'lodash'
import { Row } from '~/components/layout'
import Fieldset from '~/components/fieldset'
import { FilterContainer } from '~/components/crud/styles'
import { InputGroup, InputDate, Autocomplete, Select } from '~/components/form'
import {
  formatBankContractCharge,
  formatBankContract,
} from '~/pages/bank-contracts/form'
import { isBefore, isAfter } from 'date-fns'
import { formats } from '~/helper'

export default function FormFilter({
  values,
  setFieldValue,
  onListBankContracts,
  onListBusinessPartners,
  onListCompanies,
  onListBankAccountsGroups,
}) {
  const cloneValues = _.cloneDeep(values),
    validGroup = _.has(cloneValues, 'group') && !_.isEmpty(cloneValues.group)

  if (validGroup && _.isString(cloneValues.group)) {
    cloneValues.group = { group: cloneValues.group }
  }

  return (
    <FilterContainer>
      <Row span={2}>
        <InputGroup
          type="text"
          name="ourNumber"
          label="Nosso número"
          maxLength={30}
        />
        <InputGroup
          type="text"
          name="documentNumber"
          label="Número do documento"
          maxLength={30}
        />
      </Row>

      <Autocomplete
        name="group"
        keyField="group"
        label="Grupo"
        clearable={true}
        value={cloneValues.group}
        valueFormat={(row) => `${row.group}`}
        loadData={onListBankAccountsGroups}
        emptyText={'Grupo'}
        tipText={'Digite... '}
        loadingText={'Carregando...'}
        notFoundText={'Não encontrado'}
        allowAdd={true}
      />

      <Autocomplete
        name="bankContract"
        label="Contrato"
        value={_.get(values, 'bankContract')}
        keyField="id"
        clearable={true}
        loadData={onListBankContracts}
        valueFormat={formatBankContractCharge}
        optionsFormat={(r) => formatBankContract(r, { billType: true })}
        emptyText={'Pesquise um contrato'}
        tipText={'Digite... '}
        loadingText={'Carregando...'}
        notFoundText={'Não encontrado'}
      />

      <Autocomplete
        name="company"
        keyField="id"
        label="Emissor"
        clearable={true}
        value={_.get(values, 'company')}
        valueFormat={(row) =>
          `${formats.cnpj_cpf(row.identity)} - ${row.name || row.tradeName}`
        }
        loadData={onListCompanies}
        emptyText={'Pesquise um emissor'}
        tipText={'Digite... '}
        loadingText={'Carregando...'}
        notFoundText={'Não encontrada'}
      />

      <Autocomplete
        name="businessPartner"
        label="Pagador"
        value={_.get(values, 'businessPartner')}
        keyField="id"
        clearable={true}
        valueFormat={(row) => `${formats.cnpj_cpf(row.identity)} - ${row.name}`}
        loadData={onListBusinessPartners}
        emptyText={'Pesquise um pagador'}
        tipText={'Digite... '}
        loadingText={'Carregando...'}
        notFoundText={'Não encontrado'}
      />

      <Select
        name="registeredWithApi"
        label="Registrados com API"
        options={{
          values: [
            { value: '', label: 'Todos' },
            { value: 'no', label: 'Não registrados com API' },
            { value: 'yes', label: 'Apenas registrados com API' },
          ],
        }}
      />

      <Fieldset label="Cadastrado em">
        <Row span={2}>
          <InputDate
            name="createdAtStart"
            label="De"
            onChange={(value) => {
              setFieldValue('createdAtStart', value)
              if (values.createdAtEnd && isAfter(value, values.createdAtEnd)) {
                setFieldValue('createdAtStart', values.createdAtEnd)
                setFieldValue('createdAtEnd', value)
              }
            }}
          />

          <InputDate
            name="createdAtEnd"
            label="Até"
            onChange={(value) => {
              setFieldValue('createdAtEnd', value)
              if (
                values.createdAtStart &&
                isBefore(value, values.createdAtStart)
              ) {
                setFieldValue('createdAtStart', value)
                setFieldValue('createdAtEnd', values.createdAtStart)
              }
            }}
          />
        </Row>
      </Fieldset>

      <Fieldset label="Data de Vencimento">
        <Row span={2}>
          <InputDate
            name="dueDateStart"
            label="De"
            onChange={(value) => {
              setFieldValue('dueDateStart', value)
              if (values.dueDateEnd && isAfter(value, values.dueDateEnd)) {
                setFieldValue('dueDateStart', values.dueDateEnd)
                setFieldValue('dueDateEnd', value)
              }
            }}
          />

          <InputDate
            name="dueDateEnd"
            label="Até"
            onChange={(value) => {
              setFieldValue('dueDateEnd', value)
              if (values.dueDateStart && isBefore(value, values.dueDateStart)) {
                setFieldValue('dueDateStart', value)
                setFieldValue('dueDateEnd', values.dueDateStart)
              }
            }}
          />
        </Row>
      </Fieldset>

      <Fieldset label="Data de Pagamento">
        <Row span={2}>
          <InputDate
            name="payDateStart"
            label="De"
            onChange={(value) => {
              setFieldValue('payDateStart', value)
              if (values.payDateEnd && isAfter(value, values.payDateEnd)) {
                setFieldValue('payDateStart', values.payDateEnd)
                setFieldValue('payDateEnd', value)
              }
            }}
          />

          <InputDate
            name="payDateEnd"
            label="Até"
            onChange={(value) => {
              setFieldValue('payDateEnd', value)
              if (values.payDateStart && isBefore(value, values.payDateStart)) {
                setFieldValue('payDateStart', value)
                setFieldValue('payDateEnd', values.payDateStart)
              }
            }}
          />
        </Row>
      </Fieldset>
    </FilterContainer>
  )
}
