import React from 'react';
import _ from 'lodash';
import DataTable from '~/components/datatable';
import { formats } from 'helper';
import { BillDetailTableContainer } from '~/pages/email-batchs/styles';

const columns = [
  {
    name: 'Data',
    width: '140px',
    selector: 'createdAt',
    format: (row) => formats.dateTimeZone(_.get(row, 'createdAt'), 'dd/MM/yyyy HH:mm')
  },
  {
    name: 'Motivo',
    selector: 'error',
    wrap: true,
    format: (row) => _.get(row, 'error')
  },
  {
    name: 'Faturas',
    selector: 'faturas',
    wrap: true,
    format: (row) => {
      const installments = _.get(row, 'installments') || [];
      return _.size(installments);
    }
  },
];

export const TabSendError = ({ sentError, openDocument }) => {

  let installments = 0;

  for (let i = 0; i < _.size(sentError || []); i++) {
    installments += _.size(_.get(sentError[i], 'installments'));
  }

  return (
    <>
      <DataTable
        emptyText="Nenhuma fatura encontrada."
        noPagination={true}
        hideUpdateButton={true}
        data={{ rows: sentError}}
        onRowClicked={_.noop}
        padding="0"
        columns={columns}
        extraOptions={{
          expandableRows: true,
          highlightOnHover: true,
          expandOnRowClicked: true,
          selectableRows: false,
          selectableRowsHighlight: false,
          expandableRowsComponent: <BillRow sents={sentError} openDocument={openDocument} />
        }}
      />
      <label><b>Total de faturas:</b> {installments}</label>
    </>
  )
};

const columnsDetails = [
  {
    name: 'Nr. Documento',
    selector: 'documentNumber',
    width: '150px',
    format: (row) => _.get(row, 'documentNumber')
  },
  {
    name: 'Nosso número',
    selector: 'ourNumber',
    width: '150px',
    format: (row) => _.get(row, 'ourNumber')
  },
  {
    name: 'Vencimento',
    width: '120px',
    selector: 'dueDate',
    format: (row) => formats.dateTimeZone(_.get(row, 'dueDate'), 'dd/MM/yyyy')
  },
  {
    name: 'Valor (R$)',
    selector: 'value',
    width: '120px',
    right: true,
    format: (row) => formats.decimal(_.get(row, 'value'))
  },
];

const BillRow = ({ data = {}, openDocument }) => {

  let rows = data.installments;

  return (
    <BillDetailTableContainer style={{height: _.size(rows) === 1 ? '70px' : '140px'}}>
      <DataTable
        noPagination={true}
        emptyText="Nenhum histórico encontrado."
        hideUpdateButton={true}
        noTableHeader={true}
        data={{ rows }}
        onRowClicked={openDocument}
        padding="0"
        columns={columnsDetails}
        extraOptions={{
          selectableRowsHighlight: false
        }}
      />
    </BillDetailTableContainer>
      
  );
};