import styled from 'styled-components'
import { white, primary } from '~/components/mixins/color'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 10px;
  position: relative;

  .company-info {
    line-height: 15px;
    width: 100%;
    height: 80%;
    pointer-events: none;
    font-size: 12px;

    td {
      min-height: 20px;
      height: auto;
      vertical-align: middle;
    }
    .title {
      width: 70px;
      font-weight: bold;
      font-size: 85%;
      color: #999;
    }
  }
`

export const LeftContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  gap: 10px;

  .crud-table {
    display: flex;
    height: calc(100% - 120px);
    width: 100%;
    flex: 1;
  }
`

export const DashboardGroup = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 60px;
  gap: 3px;
  padding: 10px;
  border-right: 1px solid #f0f0f0;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #ffffff;

  &:last-child {
    border: none;
  }
  .name {
    font-size: 10px;
    font-weight: normal;
    color: #999;
    margin-bottom: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .square {
    background: ${(props) => props.color};
    width: 10px;
    height: 10px;
    margin-right: 7px;
  }
  .sum {
    display: flex;
    align-items: center;
    font-weight: 800;
    font-size: 20px;
    color: #444;

    span {
      font-size: 14px;
      color: #888;
      margin-right: 3px;
    }
  }
  .count {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    padding: 5px;
    height: 20px;
    bottom: -20px;
    width: 40px;
    border-radius: 3px;
    border: 1px solid #f0f0f0;
    border: 1px solid ${(props) => props.color};
    border-color: ${(props) => (props.selected ? props.color : '#f0f0f0')};
    color: ${(props) => (props.selected ? props.getCol(props.color) : '#555')};
    background: ${(props) => (props.selected ? props.color : '#eee')};
  }
`

export const Dashboard = styled.div`
  position: relative;
  display: flex;
  height: 120px;
  min-height: 120px;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 120px;
  padding: 0 10px;
  gap: 15px;
  overflow-x: auto;
  background: ${white.hex()};

  .section {
    border: 1px solid #f0f0f0;
    border-radius: 3px;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.03);
    grid-auto-columns: minmax(${(props) => props.cardSize}px, 1fr);
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 0px;
    padding: 10px 0;

    ${DashboardGroup} {
      width: ${(props) => props.cardSize}px;
    }
  }
`

export const SecondarySwitch = styled.button`
  width: 22px;
  height: 22px;
  position: absolute;
  top: 12px;
  left: 5px;
  background: #aaa;
  border: none;
  border-radius: 2px;
  font-size: 30px;
  z-index: 3;
  color: #fefefe;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: ${primary.hex()};
  }
`

export const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: ${(props) => (props.headLess ? '0' : '10px 5px')};

  form {
    height: 100%;
    width: 100%;
  }
  .form-warn {
    height: 60px;
    min-height: 60px;
    width: 100%;
  }
  h3 {
    height: 60px;
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #aaa;
  }
  .main {
    background: ${white.hex()};
    padding: 10px;
    display: flex;
    flex-direction: column;
    height: calc(100% - 60px);
    overflow-y: auto;
    overflow-x: hidden;
  }
`

export const FormToolbar = styled.div`
  display: flex;
  min-height: 56px;
  height: 56px;
  border-top: 1px solid #e0e0e0;
  background: ${white.hex()};

  .buttons {
    padding: 0px 10px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
  }
`

export const DetailTableContainer = styled.div`
  display: flex;
  width: 100%;
  height: 200px;
  min-height: 200px;
`

export const TinyDetailTableContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100px;
  min-height: 100px;
`

export const AttachmentTableContainer = styled.div`
  display: flex;
  width: 100%;
  height: 170px;
  min-height: 170px;
`

export const CellIcon = styled.div`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  color: ${(props) => props.color};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: ${(props) => props.size}px;

  & svg path {
    stroke: ${(props) => props.color};
  }
  &:hover:enabled,
  &:focus:enabled {
    & svg path {
      stroke: ${(props) => props.color};
    }
  }
`

export const RowExpander = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 10px 10px 18px;
  min-height: 30px;
  flex-direction: column;

  > div {
    line-height: 28px;
  }
`

export const RowInstruction = styled.div`
  display: flex;
  width: 100%;
  padding: 5px 18px 5px 18px;
  min-height: 30px;
  flex-direction: column;
  text-align: right;
  

  > div {
    line-height: 23px;
  }
`

export const InstructionContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 10px 5px;

  .inst-empty {
    display: flex;
    width: 100%;
    padding: 10px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #999;
  }
  .inst-form-contents {
    border: none;
    margin: 0;
    padding: 0;
    display: flex;
    height: 166px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    flex-direction: column;
    justify-content: space-between;
  }
  .inst-list {
    height: 236px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .inst-form-toolbar {
    display: flex;
    min-height: 35px;
    height: 35px;
    padding-top: 5px;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid #e0e0e0;
  }
  .inst-column:last-child {
    border-bottom: none;
  }
  .inst-column {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 50px;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;

    strong {
      color: #999;
      font-size: 11px;
      line-height: 16px;
    }
    .inst-column-data {
      display: flex;
      width: 100%;
      flex: 1;
      color: #666;
      flex-direction: column;
      font-size: 12px;
      font-weight: normal;
      line-height: 20px;
      padding: 10px 5px;
    }
  }
`

export const Line = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .img {
    width: 20px;
    height: auto;
    object-fit: contain;
    color: #29a8e0;
  }

  h4 {
    font-size: 13px;
    min-width: 125px;
  }

  span {
    font-size: 14px;
    color: #666;
    margin-left: 20px;
  }
`

export const ExtraContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 10px 5px;
`

export const CompanyContainer = styled.div`
  width: 100%;
  height: 100%;
  font-size: 12px;
  padding: 5px;
  display: flex;
  pointer-events: none;

  .company-icons {
    display: grid;
    place-items: center;
    width: 24px;
  }

  .company-text {
    width: ${(props) => `calc(100% - ${props.hasIcon ? '24px' : '0px'}) `};
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 3px;
    }
  }
`

export const SetteBatchContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 10px;

  table {
    width: 100%;
    border: 1px solid #ccc;

    tr {
      height: 30px;
      border: 1px solid #d8d8d8;
    }

    th {
      background: #e8e8e8;
    }

    th,
    td {
      text-align: left;
      padding: 5px;

    }
    
    .green {
      color: #77dd76;
      font-weight: 600;
    }
    .red {
      color: #ff6962;
      font-weight: 600;
    }

    th.center,
    td.center {
      text-align: center;
    }

    th.right,
    td.right {
      text-align: right;
    }

    tr:nth-child(even) td {
      background: #f8f8f8;
    }
  }
`
export const TabContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  height: calc(100%);
  padding: 10px;
  padding-top: 0px;
  overflow-y: auto;
  overflow-x: hidden;

  .empty {
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 14px;
  }
`;
