import { createActions, createReducer } from 'reduxsauce';
import produce from 'immer';
import _ from 'lodash';

export const { Types, Creators } = createActions({
  load: ['data'],
  loadSuccess: ['data'],
  loadStatement: ['id', 'offset', 'limit'],
  loadStatementSuccess: ['data'],
  list: ['params'],
  listSuccess: ['data'],
  listBankAccounts: ['term', 'callback'],
  create: ['data', 'actions'],
  createSuccess: ['data'],
  remove: ['data', 'actions'],
  downloadFile: ['data'],
}, { prefix: 'bankStatement' });

const INITIAL_STATE = {
  loading: false,
  formLoading: false,
  data: { rows: [], count: 0, offset: 0 },
  model: {}
};

const handleList = (state = INITIAL_STATE, action) => produce(state, draft => {
  draft.loading = true;
  draft.data = { rows: [], count: 0, offset: 0 };
  draft.model = {};
});

const handleListSuccess = (state = INITIAL_STATE, action) => produce(state, draft => {
  draft.loading = false;
  draft.data = action.data;
});

const handleLoad = (state = INITIAL_STATE, action) => produce(state, draft => {
  draft.model = {};
  draft.formLoading = true;
});

const handleLoadSuccess = (state = INITIAL_STATE, action) => produce(state, draft => {
  draft.formLoading = false;
  draft.model = _.get(action, 'data');
});

const handleLoadStatement = (state = INITIAL_STATE, action) => produce(state, draft => {
  draft.formLoading = true;
});

const handleLoadStatementSuccess = (state = INITIAL_STATE, action) => produce(state, draft => {
  draft.formLoading = false;
  draft.model = _.get(action, 'data');
});

const handleCreate = (state = INITIAL_STATE, action) => produce(state, draft => {
  draft.formLoading = true;
});

const handleCreateSuccess = (state = INITIAL_STATE, action) => produce(state, draft => {
  draft.formLoading = false;
});

export default createReducer(INITIAL_STATE, {
  [Types.LIST]: handleList,
  [Types.LOAD]: handleLoad,
  [Types.CREATE]: handleCreate,
  [Types.LIST_SUCCESS]: handleListSuccess,
  [Types.LOAD_SUCCESS]: handleLoadSuccess,
  [Types.CREATE_SUCCESS]: handleCreateSuccess,
  [Types.LOAD_STATEMENT]: handleLoadStatement,
  [Types.LOAD_STATEMENT_SUCCESS]: handleLoadStatementSuccess,
});
