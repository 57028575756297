import React from 'react';
import _ from 'lodash';
import Modal from '~/components/modal';
import { ExtraContainer } from '~pages/bills/styles';
import { BsContainer, BsRow, BsCol } from '~/components/layout';
import ReactJson from 'react-json-view'

const ExtraModal = ({ isOpen, loading, toggleModal, parent }) => {

  const erpExtra = _.get(parent, 'erpExtra') || {};
  const dataExtra = _.omitBy(erpExtra, _.isNil);

  return (
    <Modal
      width="800px"
      height="668px"
      title={`Dados Externos`}
      hideClose={true}
      open={isOpen}
      bindRefWithSubmit={true}
      noBodyPadding={true}
      hide={toggleModal}
      actions={
        [
          {
            label: 'Cancelar',
            disabled: loading,
            action: () => { toggleModal(); }
          },
        ]
      }
      >
      <ExtraContainer>
        <BsContainer>
          <BsRow>
            <BsCol md={22} lg={22}>
              <ReactJson displayDataTypes={false} name={false} src={dataExtra} />
            </BsCol>  
          </BsRow>
        </BsContainer>
      </ExtraContainer>
        
    </Modal>
  )

}

export default ExtraModal;