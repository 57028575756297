import _ from 'lodash';
import React from 'react';
import DataTable from '~/components/datatable';
import Modal from '~/components/modal';
import { ModalContainer } from './styles';
import { statusMap, statusColors } from '~/pages/payment-orders/constants';
import CellStatus from '~/components/datatable/cell-status';
import { formats } from '~/helper';
import CellBank from '~/components/datatable/cell-bank';
import Fieldset from '~/components/fieldset';
import { InputLabel } from '~/components/form';
import { Row } from '~/components/layout';

const columns = [
  {
    name: ' ',
    selector: 'status',
    width: '5px',
    cell: row => <CellStatus title={statusMap[row.status]} color={statusColors[row.status]} />
  },
  {
    name: 'Agência / Conta',
    selector: 'bankAccount',
    width: '120px',
    left: true,
    hide: 'lg',
    cell: row => <CellBank bankAccount={_.get(row, 'bankAccount')} />
  },
  {
    name: 'Empresa / Beneficiário',
    selector: 'company',
    wrap: true,
    cell: row => {
      const company = _.get(row, 'company') || {},
        bp = _.get(row, 'businessPartner') || {};

      return (
        <table className="company-info">
          <tbody>
            <tr>
              <td width="120">{formats.cnpj_cpf(company.identity)}</td>
              <td>{company.name || company.tradeName} &nbsp;</td>
            </tr>
            <tr>
              <td width="120">{formats.cnpj_cpf(bp.identity)}</td>
              <td>{bp.name || bp.tradeName}</td>
            </tr>
          </tbody>
        </table>
      );
    }
  },
  {
    name: 'Nr. Documento',
    selector: 'documentNumber',
    hide: 'lg',
    width: '140px',
    right: true,
    format: row => _.get(row, 'documentNumber')
  },
  {
    name: 'Vencimento',
    selector: 'dueDate',
    width: '120px',
    right: true,
    format: row => formats.dateTimeZone(_.get(row, 'dueDate'), 'dd/MM/yyyy')
  },
  {
    name: 'Valor',
    selector: 'value',
    right: true,
    width: '115px',
    compact: false,
    format: row => formats.currency(_.get(row, 'value'))
  }
];

const SelectPaymentOrderModal = ({ isOpen, loading, toggleModal, paymentOrdersData, bankStatementLine, handleSelectPaymentOrder }) => {
  const { debit = 0, credit = 0 } = bankStatementLine || {};

  return (
    <Modal
      width="80%"
      height="80%"
      title="Ordens de pagamento"
      hideClose={true}
      open={isOpen}
      bindRefWithSubmit={true}
      noBodyPadding={true}
      hide={toggleModal}
      actions={[
        {
          label: 'Cancelar',
          disabled: loading,
          action: () => {
            toggleModal();
          }
        }
      ]}
    >
      <ModalContainer>
        <Fieldset label="Linha do extrato">
          <Row span={3}>
            <InputLabel label="Descrição" value={_.get(bankStatementLine, 'memo')} />
            <InputLabel label="Data" value={formats.dateTimeZone(_.get(bankStatementLine, 'dueDate'), 'dd/MM/yyyy') || '--'} />
            {debit > 0 && <InputLabel label="Débito" value={formats.currency(_.get(bankStatementLine, 'debit') || 0)} />}
            {credit > 0 && <InputLabel label="Débito" value={formats.currency(_.get(bankStatementLine, 'credit') || 0)} />}
          </Row>
        </Fieldset>
        <Fieldset label="Ordens de pagamento (Débito automático)" height="100%">
          <DataTable
            noPagination={true}
            columns={columns}
            data={paymentOrdersData}
            emptyText="Nenhuma ordem de pagamento encontrado"
            hideUpdateButton={true}
            onRowClicked={handleSelectPaymentOrder}
            extraOptions={{
              ignoreRowClicked: true,
              selectableRows: false,
              selectableRowsHighlight: false,
              customStyles: {
                rows: {
                  denseStyle: {
                    minHeight: '42px'
                  }
                }
              }
            }}
          />
        </Fieldset>
      </ModalContainer>
    </Modal>
  );
};

export default SelectPaymentOrderModal;
