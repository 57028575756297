import { all, put, call, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '~/services/api';
import _ from 'lodash';
import { getError } from '~/helper';
import { Creators as DigitalChargeActions, Types as DigitalChargeTypes } from '~/store/modules/digital-charge';

export function showError(error) {
  const msg = getError(error);
  toast.error(msg);
};

export function* list({ params }) {
  try {
    const response = yield call(api.post, 'digital-charges/list', params);
    yield put(DigitalChargeActions.listSuccess(response.data));
  } catch (error) {
    showError(error);
  }
};

export function* load({ data }) {
  try {
    const response = yield call(api.get, 'digital-charges/load', { params: { id: data.id } });
    yield put(DigitalChargeActions.loadSuccess(response.data));
  } catch (error) {
    showError(error);
  }
};

export function* cancel({ id, actions }) {
  try {
    yield call(api.post, 'digital-charges/cancel', { id });
    toast.success(`Cobrança digital descartada com sucesso`);
    actions.closeForm && actions.closeForm();
    actions.reloadList && actions.reloadList();
  } catch (error) {
    showError(error);
  }
};

export function* save({ data, actions }) {
  try {
    let response = yield call(api.post, 'digital-charges/save', data);

    if (_.get(response, 'data.processed')) {
      toast.success(`Cobrança digital processada com sucesso`);
      actions.closeForm && actions.closeForm();
      actions.reloadList && actions.reloadList();
    } else {
      toast.error('Cobrança digital não processada, tente mais tarde!');
    }
  } catch (error) {
    showError(error);
  }
};

export function* reopen({ data, actions }) {
  try {
    yield call(api.post, 'digital-charges/reopen', data);

    toast.success(`Cobrança digital alterada com sucesso`);

    actions.closeForm && actions.closeForm();
    actions.reloadList && actions.reloadList();
  } catch (error) {
    showError(error);
  }
};

export function* listCompanies({ term, callback }) {
  try {
    const response = yield call(api.get, 'digital-charges/list-companies', {
      params: { term }
    });
    yield call(callback, response.data);
  } catch (error) {}
};

export function* listBankContracts({ term, callback }) {
  try {
    const response = yield call(api.get, 'digital-charges/list-bank-contracts', {
      params: { term }
    });
    yield call(callback, response.data);
  } catch (error) {}
};

export function* listOrderDigitalCharge({ params }) {
  try {
    const response = yield call(api.post, 'digital-charges/list-order-digital-charge', params);
    yield put(DigitalChargeActions.listOrderDigitalChargeSuccess(response.data));
  } catch (error) {}
};

export function* unlinkPaymentOrder({ id, actions }) {
  try {
    
    yield call(api.post, 'digital-charges/unlink', {id: id} );

    toast.success(`Cobrança eletrônica desvinculada com sucesso`);
    actions.closeForm && actions.closeForm();
    actions.reloadList && actions.reloadList();

  } catch (error) {
    toast.error('Cobrança eletrônica não foi desvinculada, tente mais tarde!');
  }
};

export default all([
  takeLatest(DigitalChargeTypes.LOAD, load),
  takeLatest(DigitalChargeTypes.LIST, list),
  takeLatest(DigitalChargeTypes.CANCEL, cancel),
  takeLatest(DigitalChargeTypes.SAVE, save),
  takeLatest(DigitalChargeTypes.REOPEN, reopen),
  takeLatest(DigitalChargeTypes.LIST_COMPANIES, listCompanies),
  takeLatest(DigitalChargeTypes.LIST_BANK_CONTRACTS, listBankContracts),
  takeLatest(DigitalChargeTypes.LIST_ORDER_DIGITAL_CHARGE, listOrderDigitalCharge),
  takeLatest(DigitalChargeTypes.UNLINK, unlinkPaymentOrder),
]);
