import { ErrorMessage, Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import Cleave from 'cleave.js/react';
import styled, { css } from 'styled-components';
import { primary, red, gray, lightGray } from '~/components/mixins/color';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Group = styled.div`
  display: grid;
  position: relative;
  margin: ${(props) => props.noMargin ? '0' : '0 0 10px'};
  width: 100%;

  & > svg {
    position: absolute;
    right: 15px;
    align-self: center;
    color: #555;
  }

  &:focus-within > input {
    border-color: ${(props) => props.hasError ? red.hex() : primary.hex()};
  }

  &:focus-within > svg {
    color: ${(props) => props.hasError ? red.hex() : primary.hex()};
  }

  ${(props) => props.required && css`
    label {
      font-weight: 800;
    }
  `};

  input {
    background: ${lightGray.hex()};
    border: 2px solid ${lightGray.hex()};
    border-radius: 4px;
    padding: 22px 0 8px 10px;
    color: ${gray.hex()};
    height: 46px;
    width: 100%;
    transition: border-color 0.2s ease-in 0s;

    &:disabled {
      opacity: 0.75;
    }

    &:focus + label,
    &:not([value=""]) + label {
      font-size: 70%;
      transform: translate3d(0, -100%, 0);
      opacity: 1;
      top: 20px;
    }
    & + label {
      position: absolute;
      top: 15px;
      padding-left: 10px;
      transition: all 200ms;
      color: ${gray.hex()};
    }
    ${props =>
      props.disabled &&
      css`
        opacity: 0.55;
        user-select: none;
        pointer-events: none;
        color: #b7b7b7;
        & + label {
          color: #b7b7b7 !important;
        }
      `};
  }
`;

const options = {
  numericOnly: true,
  delimiters: [' ', ' '],
  blocks: [2, 4, 5],
};

const InputPhone = ({ name, label = '', required, country, hideErrorLabel, noMargin, hasError, icon: Icon, className, onChange, ...rest }) => {

  return (
    <Container>
      <Field type="text" id={name} name={name}>
        {({ field }) => (
          <Group required={required} noMargin={noMargin} hasError={hasError}>
            <Cleave
              {...field}
              {...rest}
              placeholder={`${label}${required ? ' (*)' : ''}`}
              options={options}
              maxLength={13}
              value={field.value || ''}
              style={field.value ? {} : { padding: "15px 8px" }}
              onChange={(event) => {
                const tempEvent = event;
                tempEvent.target.value = event.target.rawValue;
                onChange ? onChange(tempEvent) : field.onChange(tempEvent);
              }}
              />
            {field.value && <label htmlFor={name}>{label}{required ? ' (*)' : ''}</label>}
            {Icon && <Icon size={20} />}
          </Group>
        )}
      </Field>
      {!hideErrorLabel && <ErrorMessage name={name} className='error' component='div' /> }
    </Container>
  );
};

InputPhone.propTypes = {
  name: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  label: PropTypes.string,
  hasError: PropTypes.bool,
};

InputPhone.defaultProps = {
  hasError: false,
};

export default InputPhone;

/*
  EXEMPLO DE USO

  import { InputPhone } from '~/components/form';

  <InputPhone
    country="BR"
    name="phone"
    label="Telefone"
    hasError={errors.phone && touched.phone} />

*/
