
import React from 'react';
import { BsRow, BsCol } from '~/components/layout';

export const id = 91;

export const Render = () => {

  return (
    <BsRow className="form-warn">
      <BsCol md={24} lg={24} xl={24}>
        <h3>Escolha outro Tipo da Ordem.</h3>
      </BsCol>
    </BsRow>
  );
};

export const Schema = (values) => ({
});
