import React from 'react';
import AvatarForm from '~/pages/users/update-avatar';
import UpdatePassword from '~/pages/users/update-password';

function ProfileModals({profileData, passwordFrmIsOpen, closeModalPassword, handleUpdatePassword, avatarData, avatarFrmIsOpen, closeModalAvatar, handleUpdateAvatar }) {
  return (
    <>
      <AvatarForm
        title={<>Mude a sua foto</>}
        data={{avatar: avatarData}}
        handleOnSubmit={handleUpdateAvatar}
        isOpen={avatarFrmIsOpen}
        closeModal={closeModalAvatar}
      />

      <UpdatePassword
        title={<>Modifique sua senha</>}
        data={profileData}
        handleOnSubmit={handleUpdatePassword}
        isOpen={passwordFrmIsOpen}
        closeModal={closeModalPassword}
      />
    </>
  )
}

export default ProfileModals;
