import { createActions, createReducer } from 'reduxsauce';
import produce from 'immer';
import _ from 'lodash';
import { getBankLogo } from '~/assets/banks';
import { allowedOrdersTypes } from '~/pages/payment-orders/constants';
import { formats } from '~/helper';

export const { Types, Creators } = createActions(
  {
    load: ['params'],
    loadSuccess: ['data'],
    shippingCreate: ['data', 'actions'],
    shippingCreateSuccess: [],
    getCompanies: [],
    getCompaniesSuccess: ['data'],
    listCompanies: ['term', 'callback'],
    listPaymentOrderType: ['term', 'callback'],
    listBankAccounts: [],
    listBankAccountsSuccess: ['data'],
    movement: ['oldData', 'data', 'bankAccounts'],
    movementSuccess: ['data'],
    movementInBatch: ['bankAccount', 'selecteds', 'actions'],
    markAsWrong: ['data'],
    listBusinessPartners: ['term', 'callback'],
    listBankContracts: ['params', 'callback'],
  },
  { prefix: 'preparePaymentOrder' }
);

const INITIAL_STATE = {
  loading: false,
  isShippingCreating: false,
  paymentOrders: {},
  companies: [],
  bankAccounts: [],
  summaryData: {}
};

const handleLoad = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.loading = true;
    draft.paymentOrders = {};
  });

const handleLoadSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.loading = false;
    draft.paymentOrders = _.groupBy(action.data, 'bankAccountId');

    draft.summaryData = prepareSummary(draft.paymentOrders, draft.summaryData);
  });

const handleGetCompanies = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.loading = true;
    draft.companies = [];
  });

const handleGetCompaniesSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.loading = false;
    draft.companies = _.get(action, 'data');
  });

const handleListBankAccounts = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.loading = true;
    draft.bankAccounts = [];
  });

const handleListBankAccountsSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.loading = false;
    draft.summaryData = prepareSummary(draft.paymentOrders, initSummary(draft.bankAccounts));

    let bankAccounts = _.map(action.data, row => {
      let ordersType = _.get(row, 'extra.bank.settings.payment.ordersType'),
        ordersTypesMap = allowedOrdersTypes(ordersType);
      return { ...row, ordersTypesMap, logo: getBankLogo(row.code) };
    });

    draft.bankAccounts = _.keyBy(bankAccounts, 'id');
  });

const handleMovementSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.paymentOrders = _.get(action, 'data');
    draft.summaryData = prepareSummary(draft.paymentOrders, draft.summaryData);
  });

const initSummary = bankAccounts => {
  const summary = {};
  _.each(bankAccounts, (bankAccount, id) => {
    if (id !== '000') {
      const { balanceAmount = 0, accountsReceivable = 0, accountsPayable = 0 } = _.pick(bankAccount.extra, [
        'balanceAmount',
        'accountsReceivable',
        'accountsPayable'
      ]);
      const total = balanceAmount + (accountsReceivable - accountsPayable);
      summary[id] = [
        {
          label: 'Saldo inicial',
          value: formats.currency(balanceAmount)
        },
        {
          label: 'Contas a receber',
          value: formats.currency(accountsReceivable)
        },
        {
          label: 'Contas a pagar',
          value: formats.currency(accountsPayable)
        },
        {
          label: 'Pagamentos Selecionados',
          value: formats.currency(0)
        },
        {
          label: 'Saldo',
          value: formats.currency(total),
          raw: total
        }
      ];
    }
  });
  return summary;
};

const prepareSummary = (paymentOrders, summaryData) => {
  _.each(paymentOrders, (data, id) => {
    const selectedValue = _.reduce(data, (sum, row) => {
      let total = _.get(row,'orderData.total') || 0;
      let value = total !== 0 ? total : row.value;

      return value + sum;
    }, 0);
    _.each(summaryData, (summary = [], summaryId) => {
      if (summaryId === id) {
        summary[3].value = formats.currency(selectedValue);
        summary[4].value = formats.currency(summary[4].raw - selectedValue);
      }
    });
  });
  return summaryData;
};

const handleshippingCreate = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.isShippingCreating = true;
  });

const handleshippingCreateSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.isShippingCreating = false;
  });

export default createReducer(INITIAL_STATE, {
  [Types.LOAD]: handleLoad,
  [Types.LOAD_SUCCESS]: handleLoadSuccess,
  [Types.SHIPPING_CREATE]: handleshippingCreate,
  [Types.SHIPPING_CREATE_SUCCESS]: handleshippingCreateSuccess,
  [Types.GET_COMPANIES]: handleGetCompanies,
  [Types.GET_COMPANIES_SUCCESS]: handleGetCompaniesSuccess,
  [Types.LIST_BANK_ACCOUNTS]: handleListBankAccounts,
  [Types.LIST_BANK_ACCOUNTS_SUCCESS]: handleListBankAccountsSuccess,
  [Types.MOVEMENT_SUCCESS]: handleMovementSuccess
});
