import React, { useLayoutEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as HeaderActions } from '~/store/modules/header';
import { Creators as PaymentApprovalActions } from '~/store/modules/payment-approval';
import { Select } from '~/components/form';
import { Formik } from 'formik';
import { FaThumbsDown, FaThumbsUp } from 'react-icons/fa';
import Table from '~/components/table';
import usePosition from '~/hooks/use-position';
import Loading from '~/components/loading';
import { formats } from '~/helper';
import CellBank from '~/components/datatable/cell-bank';
import { Container, InnerContainer, FormHeaderIn, FormContainer, CompanyContainer } from '~/pages/payment-approvals/styles';
import DataTable from '~/components/datatable';
import EmptyState from '~/components/empty-state';
import { Button, IconButton } from '~components/button';
import confirm from '~/components/confirm';
import DetailModal from '~/pages/payment-approvals/form';
import PositionInfoModal from '~/pages/payment-approvals/modal-position-info';
import { FlexRow, FlexCol } from '~/components/layout';
import FormFilter from '~/pages/payment-approvals/filter';
import { MdKeyboardBackspace, MdCheck, MdClear } from 'react-icons/md';
import { FormHeader, FormToolbar } from '~/components/crud/styles';

const groupByTypes = {
  bankContract: 'Conta bancária',
  scheduledDate: 'Data de agendamento',
  paymentOrderType: 'Tipo de ordem'
};

const columns = ({ handleApproval, positionEnabled }) => [
  {
    name: 'Empresa / Beneficiário',
    selector: 'company',
    wrap: true,
    cell: (row) => {
      const company = _.get(row, 'company') || {},
        bp = _.get(row, 'businessPartner') || {};
      return (
        <CompanyContainer>
          <div className="company-text">
            <span>
              {formats.cnpj_cpf(_.get(company, 'identity', '')) +
                ' ' +
                (_.get(company, 'name', '') || _.get(company, 'tradeName', ''))}
            </span>
            <span>
              {formats.cnpj_cpf(_.get(bp, 'identity', '')) +
                ' ' +
                (_.get(bp, 'name', '') || _.get(bp, 'tradeName', ''))}
            </span>
          </div>
        </CompanyContainer>
      )
    },
  },
  {
    name: 'Nr. Documento',
    selector: 'documentNumber',
    hide: 'lg',
    width: '130px',
    center: true,
    format: row => _.get(row, 'documentNumber')
  },
  {
    name: 'Agendamento',
    selector: 'scheduledDate',
    width: '110px',
    center: true,
    format: row => formats.dateTimeZone(_.get(row, 'scheduledDate'), 'dd/MM/yyyy')
  },
  {
    name: 'Valor',
    selector: 'value',
    right: true,
    width: '140px',
    compact: false,
    format: row => row.isPartial ? formats.currency(_.get(row, 'value')) : formats.currency(_.get(row, 'orderData.total') || _.get(row, 'value'))
  },
  {
    name: 'Aprovações',
    selector: 'approvals',
    width: '100px',
    center: true,
    format: row => {
      const approval = _.get(row, 'paymentApprovals[0]') || {},
        minApprovers = _.get(approval,'criteria.minApprovers') || 0,
        approvers = _.size(_.get(approval, 'approvers') || []);

    return `${approvers} de ${minApprovers}`
    }
  },
  {
    name: 'Ações',
    selector: 'id',
    width: '100px',
    center: true,
    cell: row => (
      <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
        <IconButton size={28} noMargin={true} color="success" disabled={!positionEnabled} title="Aprovar" onClick={() => handleApproval(row, true)}>
          <FaThumbsUp />
        </IconButton>

        <IconButton size={28} noMargin={true} color="error" disabled={!positionEnabled} title="Rejeitar" onClick={() => handleApproval(row, false)}>
          <FaThumbsDown />
        </IconButton>
      </div>
    )
  }
];

const customStyles = {
  rows: {
    denseStyle: {
      minHeight: '58px'
    }
  }
};

const textType = groupBy => {
  if (groupBy === 'scheduledDate') {
    return 'uma data de agendamento';
  } else if (groupBy === 'bankContract') {
    return 'uma conta bancária';
  }
  return 'um tipo de ordem';
};

function PaymentApproval({ history }) {
  const dispatch = useDispatch(),
    state = useSelector(state => state.paymentApproval),
    [isOpenPositionInfoModal, setOpenPositionInfoModal] = useState(false),
    [isOpenDetailModal, setOpenDetailModal] = useState(false),
    [selectedRow, setSelectedRow] = useState(null),
    [filterGroup, setFilterGroup] = useState({ groupBy: 'paymentOrderType', selected: null }),
    { position, positionPerm } = usePosition(),
    loading = state.loading,
    loadingMessage = state.loadingMessage,
    detailLoading = state.detailLoading || loading,
    positionEnabled = /^(granted)/.test(positionPerm),
    hasOrders = !_.isEmpty(_.get(state, 'details.rows')),
    headerState = useSelector(state => state.header);

  const [limit, setLimit] = useState(20);

  useLayoutEffect(() => {
    const config = {
      loading: state.loading,
      useFilter: true,
      filter: {
        ...headerState.filter,
        visible: false,
        scope: '/payment-approvals'
      },
    }
    dispatch(HeaderActions.configure(config))
    // eslint-disable-next-line
  }, [])

  useLayoutEffect(() => {
    if(_.isEmpty(_.get(state,'data'))){
      dispatch(HeaderActions.callFilter(filterGroup, (data) => PaymentApprovalActions.load(data,loadDetailsFirst)));
    } else {
      if(!_.get(filterGroup,'selected')){
        handleLoad(filterGroup,loadDetailsFirst);
      }
    }
    // eslint-disable-next-line
  }, [filterGroup])

  function loadDetailsFirst(data, params) {
    const selectData = _.cloneDeep(_.get(data,'summary.rows',[])),
      firstRow = selectData[0]
    let filterFirst = _.cloneDeep(params);
    if (!_.isEmpty(selectData)){
      filterFirst.selected = firstRow;
      setFilterGroup(filterFirst)
      if(!_.isEmpty(firstRow)){
        setSelectedRow(firstRow.name);
      }
      handleLoadDetails(0, limit, filterFirst);
    }
  }
  
  function loadDetailsAfterClear(data, params) {
    const selectData = _.cloneDeep(_.get(data,'summary.rows',[])),
      firstRow = selectData[0]

    let afterClearGroup = { groupBy: _.get(params,'groupBy') }
    if (!_.isEmpty(selectData)){
      afterClearGroup.selected = firstRow
      setFilterGroup(afterClearGroup)
      
      if(!_.isEmpty(firstRow)){
        setSelectedRow(firstRow.name);
      }
      handleLoadDetails(0, limit, {...afterClearGroup, forceClear: true});
    }
  }


  function handleLoad(data, callback) {
    const params = { ...headerState.filter.data, ...data }
    dispatch(PaymentApprovalActions.load(params, callback));

    if(!callback) {
      setSelectedRow(null)
    }
  }

  const handleHideFilter = () => {
    dispatch(HeaderActions.hideFilter());
  };

  const handleFilter = filterData => {
    let params = {}
    if(!_.isEmpty(filterData)){
      params = { ..._.omit(filterData,['selected','forceClear']), groupBy: _.get(filterGroup,'groupBy')}
    } 
    
    setSelectedRow(null)
    dispatch(HeaderActions.callFilter(params, (data) => PaymentApprovalActions.load(data,loadDetailsFirst)));
    handleHideFilter();
  };

  const handleFilterClear = () => {
    const params = { groupBy: _.get(filterGroup, 'groupBy') }
    setSelectedRow(null)
    dispatch(HeaderActions.callFilter(params, (data) => PaymentApprovalActions.load(data,loadDetailsAfterClear)));
    handleHideFilter();
  }

  function handleLoadDetails(offset = 0, limitDetails, filterSelected) {
    const filterRow = _.get(filterSelected,'selected.filter', {})
    let params = {
      ...headerState.filter.data,
      ...filterRow,
      ...filterSelected,
      offset, 
      limit: limitDetails
    }
    if (_.get(filterSelected,'forceClear')){
      params = { groupBy: _.get(filterGroup,'groupBy'), ...filterRow, limit: limitDetails, offset }
    }
    dispatch(PaymentApprovalActions.loadDetails(params));
  }

  function handlePageChange(offset, limitPg) {
    if(limitPg){
      setLimit(limitPg)
    }
    handleLoadDetails(offset, limitPg, _.omit(filterGroup,'forceClear'))
  };

  function handleLoadOrder(id) {
    setOpenDetailModal(true);
    dispatch(PaymentApprovalActions.loadOrder({ id }));
  }

  async function onDownloadAttachment({ id, filename }) {
    dispatch(PaymentApprovalActions.downloadFile({ id, filename }));
  }

  function afterApproval(originParams = {}) {
    handleLoad({ ...headerState.filter.data, ...originParams }, data => {
      let rows = _.get(data, 'summary.rows'),
        name = selectedRow;
      if (!_.find(rows, { name })) {
        setFilterGroup({ ..._.omit(filterGroup,['selected','forceClear'])})
        setSelectedRow(null);
      } else {
        handleLoadDetails(0, limit, _.omit(filterGroup,'forceClear'));
      }
    });
  }

  async function handleApproval(row, approved, callback) {
    let { option, prompt } = await confirm.show({
      title: 'Atenção',
      width: 500,
      isPrompt: !approved,
      promptLabel: 'Motivo da rejeição',
      promptLength: 5,
      asObject: true,
      text: `Deseja realmente "${approved ? 'Aprovar' : 'Rejeitar'}" essa ordem de pagamento?`
    });

    if (option) {
      let params = { approved, position, id: row.id, rejectReason: prompt, filterGroup };
      dispatch(PaymentApprovalActions.executeApproval(params, afterApproval));
      callback && callback();
    }
  }

  async function handleAllApproval(approved) {
    let { option, prompt } = await confirm.show({
      title: 'Atenção',
      width: 500,
      isPrompt: !approved,
      promptLabel: 'Motivo da rejeição',
      promptLength: 5,
      asObject: true,
      text: `Deseja realmente "${approved ? 'Aprovar' : 'Rejeitar'}" todas as ordens de pagamento filtradas?`
    });

    if (option) {
      let groupBy = _.get(filterGroup, 'groupBy');
      let criteria = _.get(filterGroup, 'selected.filter');
      const params = { approved, position, rejectReason: prompt, ...criteria , ...headerState.filter.data, groupBy};
      dispatch(PaymentApprovalActions.executeApproval(params, afterApproval));
    }
  }

  function handleListBankContracts(term, callback) {
    dispatch(PaymentApprovalActions.listBankContracts({ term }, callback));
  }

  function handleListCompanies(term, callback) {
    dispatch(PaymentApprovalActions.listCompanies({ term }, callback));
  }

  function handleListBusinessPartners(term, callback) {
    dispatch(PaymentApprovalActions.listBusinessPartners(term, callback));
  }
  

  const Filter = () => {
    return (
      <div className="rightFilter">
        <Formik onSubmit={handleFilter} initialValues={headerState.filter.data} enableReinitialize={true} validateOnMount={true}>
          {args => {
            return (
              <>
                <FormHeader>Filtro</FormHeader>
                
                <FormFilter
                  {...args}
                  onListBankContracts={handleListBankContracts}
                  onListCompanies={handleListCompanies}
                  onListBusinessPartners={handleListBusinessPartners}
                />

                <FormToolbar>
                  <IconButton title="Cancelar" disabled={state.loading} onClick={handleHideFilter}>
                    <MdKeyboardBackspace />
                  </IconButton>
                  <IconButton title="Limpar Filtro" disabled={state.loading} onClick={handleFilterClear}>
                    <MdClear />
                  </IconButton>
                  <IconButton title="Aplicar Filtro" disabled={state.loading} onClick={args.handleSubmit}>
                    <MdCheck />
                  </IconButton>
                </FormToolbar>
              </>
            );
          }}
        </Formik>
      </div>
    );
  };

  return (
    <Container showForm={headerState.filter.visible} rightWidth="calc(100% - 400px)">
      <div className="left">
        <Formik enableReinitialize={true} validateOnMount={false} initialValues={filterGroup}>
          {({ values, setFieldValue }) => (
            <InnerContainer>
              <FlexRow>
                <FlexCol flex="1">
                  <Select
                    name="groupBy"
                    label="Agrupar por"
                    disabled={loading || detailLoading}
                    value={_.get(filterGroup, 'groupBy') || 'paymentOrderType'}
                    options={{ values: _.map(groupByTypes, (label, value) => ({ label, value })) }}
                    onChange={evt => {
                      let value = _.get(evt, 'target.value');
                      setFieldValue('groupBy', value);
                      setFieldValue('selected', null);
                      setFilterGroup({ groupBy: value})
                    }}
                  />
                </FlexCol>
              </FlexRow>

              {loading && (
                <InnerContainer>
                  <Loading size={36} label={loadingMessage || 'Carregando...'} />
                </InnerContainer>
              )}

              {!loading && (
                <Table
                  rowHeight={46}
                  rows={_.get(state, 'data.summary.rows') || []}
                  keyField="name"
                  fixedSelected={selectedRow}
                  emptyText="Nenhuma ordem de pagamento encontrada."
                  columns={[
                    {
                      align: 'left',
                      width: '270px',
                      label: groupByTypes[filterGroup.groupBy] || '',
                      format: row => {
                        if (filterGroup.groupBy === 'bankContract') {
                          return <CellBank bankAccount={_.get(row, 'bankContract.bankAccount')} />;
                        }
                        return row.name;
                      }
                    },
                    {
                      align: 'right',
                      width: '100px',
                      label: 'Valor total',
                      format: row => formats.currency(_.get(row, 'payValue') || _.get(row, 'value') || 0)
                    }
                  ]}
                  footer={[
                    {
                      align: 'left',
                      width: '270px',
                      format: () => 'Total'
                    },
                    {
                      align: 'right',
                      width: '100px',
                      format: () => formats.currency(_.get(state, 'data.summary.payTotal') || _.get(state, 'data.summary.total') || 0)
                    }
                  ]}
                  onRowClicked={row => {
                    let filterData = _.cloneDeep(filterGroup);
                    filterData.selected = row;
                    setFilterGroup(filterData);
                    setSelectedRow(row.name);
                    handleLoadDetails(0, limit, filterData);
                  }}
                />
              )}
            </InnerContainer>
          )}
        </Formik>
      </div>

      <div className="right">
        <FormHeaderIn>
          {_.get(filterGroup, 'selected.name') && (
            <div className="title">
              {!!selectedRow && <div className="text-truncate">{`${groupByTypes[filterGroup.groupBy]}: ${_.get(filterGroup, 'selected.name')}`}</div>}
            </div>
          )}

          {_.get(filterGroup, 'selected') && (
            <div className="actions">
              {/^(denied|prompt)/.test(positionPerm) && (
                <Button type="button" height={30} fontSize={12} disabled={positionPerm === 'prompt'} onClick={() => setOpenPositionInfoModal(true)}>
                  Permita o acesso a sua Localização
                </Button>
              )}

              {positionEnabled && hasOrders && !loading && (
                <>
                  <Button type="button" color="success" height={30} fontSize={12} onClick={() => handleAllApproval(true)}>
                    Aprovar todos
                  </Button>

                  <Button type="button" color="error" height={30} fontSize={12} onClick={() => handleAllApproval(false)}>
                    Rejeitar todos
                  </Button>
                </>
              )}
            </div>
          )}
        </FormHeaderIn>

        <FormContainer>
          {detailLoading && <Loading size={36} label={loadingMessage || 'Carregando...'} />}

          {!detailLoading && !selectedRow && (
            <EmptyState text={`Selecione ${textType(filterGroup.groupBy)} ao lado para ver as ordens de pagamento vinculadas.`} visible={true} />
          )}

          {!detailLoading && selectedRow && hasOrders && (
            <DataTable
              emptyText={'Nenhuma ordem de pagamento encontrada'}
              data={state.details || {}}
              onRowClicked={r => handleLoadOrder(_.get(r, 'id'))}
              onChangePage={handlePageChange}
              hideUpdateButton={true}
              extraOptions={{ customStyles }}
              columns={columns({ handleApproval, positionEnabled })}
            />
          )}
        </FormContainer>
      </div>

      {isOpenPositionInfoModal && <PositionInfoModal isOpen={isOpenPositionInfoModal} toggleModal={() => setOpenPositionInfoModal(!isOpenPositionInfoModal)} />}

      {isOpenDetailModal && (
        <DetailModal
          isOpen={isOpenDetailModal}
          toggleModal={() => setOpenDetailModal(!isOpenDetailModal)}
          loading={state.formLoading}
          values={state.formData}
          onApproval={(approved, callback) => handleApproval(state.formData, approved, callback)}
          handleDownloadAttachment={onDownloadAttachment}
        />
      )}

      <Filter />

    </Container>
  );
}

export default PaymentApproval;
