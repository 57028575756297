
import React from 'react';
import _ from 'lodash';
import * as Yup from 'yup';
import { InputGroup, CurrencyField, InputDate } from '~/components/form';
import FieldSet from '~/components/fieldset';
import { BsRow, BsCol } from '~/components/layout';

export const id = 42;

export const onParentChange = ({ field, value, parent, setFieldValue }) => {
  let idField = 'orderData.identity',
    nameField = 'orderData.name';

  if (field === 'paymentOrderType' && _.get(parent, 'company')) {
    let identity = _.get(parent, 'company.identity');
    setFieldValue(idField, identity);
    setFieldValue(nameField, _.get(parent, 'company.name'));

  } else if (field === 'company' && value) {
    let identity = _.get(value, 'identity');
    setFieldValue(idField, identity);
    setFieldValue(nameField, _.get(value, 'name'));

  }
};

export const Render = ({ values, errors, touched, setFieldValue, previewMode }) => {
  const editLocked = _.get(values, 'editLocked') || false;
  return (
    <FieldSet label="Tributos - GPS">
      <BsRow>
        <BsCol md={24} lg={6} xl={4}>
          <InputGroup
            type="text"
            name="orderData.identity"
            maxLength={40}
            disabled={previewMode || editLocked}
            label="Identificador *"
            hasError={_.get(errors, 'orderData.identity') && _.get(touched, 'orderData.identity')}
            />
        </BsCol>

        <BsCol md={24} lg={18} xl={20}>
          <InputGroup
            type="text"
            name="orderData.name"
            maxLength={30}
            disabled={previewMode || editLocked}
            label="Nome do contribuinte *"
            hasError={_.get(errors, 'orderData.name') && _.get(touched, 'orderData.name')}
            />
        </BsCol>
      </BsRow>

      <BsRow>
        <BsCol md={24} lg={12} xl={12}>
          <InputGroup
            type="text"
            name="orderData.revenueCode"
            maxLength={10}
            disabled={previewMode || editLocked}
            label="Código da receita *"
            hasError={_.get(errors, 'orderData.revenueCode') && _.get(touched, 'orderData.revenueCode')}
            />
        </BsCol>

        <BsCol md={24} lg={12} xl={12}>
         <InputDate
            name="orderData.period"
            label="Competência *"
            monthView={true}
            hideErrorLabel={false}
            disabled={previewMode || editLocked}
            hasError={_.get(errors, 'orderData.period') && _.get(touched, 'orderData.period')}
            />
        </BsCol>
      </BsRow>

      <BsRow>
        <BsCol md={24} lg={12} xl={12}>
          <CurrencyField
            name="orderData.taxValue"
            label="Valor do tributo *"
            disabled={previewMode || editLocked}
            hasError={_.get(errors, 'orderData.taxValue') && _.get(touched, 'orderData.taxValue')}
            onChange={(val) => {
              let taxValue = val || 0,
                otherValue = _.get(values, 'orderData.otherValue') || 0,
                restatement = _.get(values, 'orderData.restatement') || 0;
              setFieldValue('orderData.amountCollected', (otherValue + restatement + taxValue));
            }}
            />
        </BsCol>

        <BsCol md={24} lg={12} xl={12}>
          <CurrencyField
            name="orderData.otherValue"
            label="Valor outras entidades"
            disabled={previewMode || editLocked}
            hasError={_.get(errors, 'orderData.otherValue') && _.get(touched, 'orderData.otherValue')}
            onChange={(val) => {
              let taxValue = _.get(values, 'orderData.taxValue') || 0,
                otherValue = val || 0,
                restatement = _.get(values, 'orderData.restatement') || 0;

              setFieldValue('orderData.amountCollected', (otherValue + restatement + taxValue));
            }}
            />
        </BsCol>

        <BsCol md={24} lg={12} xl={12}>
          <CurrencyField
            name="orderData.restatement"
            label="Atualização monetária"
            disabled={previewMode || editLocked}
            hasError={_.get(errors, 'orderData.restatement') && _.get(touched, 'orderData.restatement')}
            onChange={(val) => {
              let taxValue = _.get(values, 'orderData.taxValue') || 0,
                otherValue = _.get(values, 'orderData.otherValue') || 0,
                restatement = val || 0;

              setFieldValue('orderData.amountCollected', (otherValue + restatement + taxValue));
            }}
            />
        </BsCol>

        <BsCol md={24} lg={12} xl={12}>
          <CurrencyField
            name="orderData.amountCollected"
            label="Valor arrecadado"
            disabled={true}
            hasError={_.get(errors, 'orderData.amountCollected') && _.get(touched, 'orderData.taxValue')}
            />
        </BsCol>
      </BsRow>
    </FieldSet>
  )
};

export const Schema = (values) => ({
  orderData: Yup.object().typeError('Informe os dados da ordem').shape({
    identity: Yup.string().required('Informe o identificador'),
    name: Yup.string().required('Informe o nome do contribuinte'),
    revenueCode: Yup.string().required('Informe o código da receita'),
    period: Yup.string().typeError('Informe a competência').required('Informe a competência'),
    taxValue: Yup.number().required('Informe o valor do tributo'),
    amountCollected: Yup.mixed().test('match', '', function(value) {
      const { path, parent, createError } = this;
      const val = value || _.get(parent, 'amountCollected'),
        baseVal = _.get(values, 'value') || 0;

      if (!val) {
        return createError({ path, message: 'Informe o valor do tributo' });
      } else if (val !== baseVal) {
        return createError({ path, message: 'Valor arrecadado difere do Valor' });
      }
      return true;
    })
  })
});
