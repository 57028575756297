import React, { useState } from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import styled from 'styled-components'

import Modal from '~/components/modal'
import { InputGroup } from '~/components/form'
import _ from 'lodash'
import { FaCheck, FaExclamationCircle } from 'react-icons/fa'
import { green, red } from '~/components/mixins/color'

const Container = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;

  span {
    color: #d61305;
  }
`

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100px;
  justify-content: center;
  color: #555;

  fieldset {
    margin-top: 30px;
    border: none;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 5px;

    li {
      display: flex;
      align-items: center;

      svg {
        margin-right: 10px;
      }
    }
  }
`

const initialErrors = {
  'Mínimo de 8 caracteres': true,
  'Máximo de 50 caracteres': true,
  'Mínimo de um número': true,
  'Mínimo de uma letra minúscula': true,
  'Mínimo de uma letra maiúscula': true,
  'Mínimo de um caracter especial': true,
  'Não pode possuir espaços': true,
}

function PasswordForm({
  title,
  data,
  isOpen,
  closeModal,
  handleOnSubmit,
  handleCancelling,
}) {
  const [messages, setMessages] = useState(initialErrors)

  const setErrors = (error) => {
    const currentErrors = _.get(error, 'errors') || [],
      newMessages = _.cloneDeep(messages)
    _.each(newMessages, (__, key) => {
      newMessages[key] = currentErrors.includes(key)
    })
    setMessages(newMessages)
  }

  const validator = async (e) => {
    try {
      e.preventDefault()
      const password = e.target.value
      await passwordSchema.validate(password, { abortEarly: false })
      setErrors({})
    } catch (err) {
      setErrors(err)
    }
  }

  const resetCloseModal = (handleReset) => {
    handleReset()
    setErrors({ errors: _.keys(initialErrors) })
    closeModal()
  }

  return (
    <Formik
      initialErrors={true}
      enableReinitialize={true}
      initialValues={data}
      validationSchema={schema}
      onSubmit={handleOnSubmit}
    >
      {({
        errors,
        isValid,
        touched,
        handleSubmit,
        isSubmitting,
        handleReset,
      }) => (
        <Modal
          width="400px"
          height="450px"
          open={isOpen}
          hide={() => resetCloseModal(handleReset)}
          title={title}
          actions={[
            {
              label: 'Cancelar',
              action: () => resetCloseModal(handleReset),
              disabled: isSubmitting,
            },
            {
              label: isSubmitting ? 'Aguarde...' : 'Enviar',
              action: handleSubmit,
              type: 'submit',
              primary: true,
              disabled: !isValid || isSubmitting,
            },
          ]}
        >
          <Container>
            <InputGroup
              type="password"
              name="oldPassword"
              label="Senha atual"
              maxLength={20}
              hasError={errors.oldPassword && touched.oldPassword}
            />

            <InputGroup
              type="password"
              name="password"
              label="Nova senha"
              maxLength={50}
              onChange={validator}
            />

            <InputGroup
              type="password"
              name="confirmPassword"
              label="Confirmação de senha"
              maxLength={50}
              hasError={errors.confirmPassword && touched.confirmPassword}
            />

            <ErrorContainer>
              {
                <fieldset>
                  <legend>Senha deve ter:</legend>
                  <ul>
                    {Object.keys(messages).map((msg, index) => (
                      <li key={index}>
                        {messages[msg] === false ? (
                          <FaCheck color={green} />
                        ) : (
                          <FaExclamationCircle color={red} />
                        )}
                        {msg}
                      </li>
                    ))}
                  </ul>
                </fieldset>
              }
            </ErrorContainer>
          </Container>
        </Modal>
      )}
    </Formik>
  )
}

const passwordSchema = Yup.string()
  .min(8, 'Mínimo de 8 caracteres')
  .max(50, 'Máximo de 50 caracteres')
  .matches(/\d+/, 'Mínimo de um número')
  .matches(/[a-z]+/, 'Mínimo de uma letra minúscula')
  .matches(/[A-Z]+/, 'Mínimo de uma letra maiúscula')
  .matches(/[!@#$%^&*()\-+]+/, 'Mínimo de um caracter especial')
  .test(
    'Não pode possuir espaços',
    'Não pode possuir espaços',
    (value) => !/\s+/.test(value),
  )

const confirmPasswordSchema = Yup.string()
  .required('Confirme a sua nova senha')
  .test(
    'passwords-match',
    'Deve corresponder ao valor do campo "Nova Senha"',
    function (value) {
      return this.parent.password === value
    },
  )

const schema = Yup.object().shape({
  oldPassword: Yup.string().required('Informe a sua senha atual'),
  password: passwordSchema,
  confirmPassword: confirmPasswordSchema,
})

PasswordForm.propTypes = {
  title: PropTypes.node,
  data: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  handleOnSubmit: PropTypes.func,
  handleCancelling: PropTypes.func,
}

PasswordForm.defaultProps = {
  isOpen: false,
  handleOnSubmit: () => {},
  handleCancelling: () => {},
}

export default PasswordForm
