import React from 'react';
import { Select, InputGroup, InputMask } from '~/components/form';
import FieldSet from '~/components/fieldset';
import { BsRow, BsCol, FlexRow, FlexCol } from '~/components/layout';
import { Button } from '~/components/button';
import { identityTypes } from '~/pages/payment-orders/constants';
import { isValidCpf, isValidCnpj, isValidPisPasep } from '~/validator';
import { MASK_CPF, MASK_CNPJ, MASK_PIS_PASEP } from '~/constants';
import * as Yup from 'yup';
import _ from 'lodash';

export const id = 21;

export const onParentChange = ({ field, value, parent, setFieldValue }) => {
  let idField = 'orderData.identity',
    idTypeField = 'orderData.identityType',
    nameField = 'orderData.name';

  if (field === 'paymentOrderType' && _.get(parent, 'businessPartner')) {
    let identity = _.get(parent, 'businessPartner.identity');
    setFieldValue(idTypeField, _.size(identity) === 14 ? '2' : '1');
    setFieldValue(idField, identity);
    setFieldValue(nameField, _.get(parent, 'businessPartner.name'));

  } else if (field === 'businessPartner' && value) {
    let identity = _.get(value, 'identity');
    setFieldValue(idTypeField, _.size(identity) === 14 ? '2' : '1');
    setFieldValue(idField, identity);
    setFieldValue(nameField, _.get(value, 'name'));
  }
};

export const Render = ({ values, errors, touched, setFieldValue, previewMode, getPixQrCodeData }) => {
  const editLocked = _.get(values, 'editLocked') || false;
  return (
    <FieldSet label="Títulos - PIX QR Code">
      <BsRow>
        <BsCol md={24} lg={5} xl={4}>
          <Select
            name="orderData.identityType"
            label="Tipo de inscrição *"
            disabled={previewMode || editLocked}
            hasError={_.get(errors, 'orderData.identityType') && _.get(touched, 'orderData.identityType')}
            options={{ values: [{ value: '', 'label': '' }, ...identityTypes]}}
            onChange={(evt) => {
              setFieldValue('orderData.identityType', evt.target.value);
              setFieldValue('orderData.identity', '');
            }}
            />
        </BsCol>

        <BsCol md={24} lg={7} xl={8}>
          {(_.get(values, 'orderData.identityType') === '1') &&
            <InputMask
              mask={MASK_CPF}
              name="orderData.identity"
              label="CPF *"
              disabled={previewMode || editLocked}
              hasError={_.get(errors, 'orderData.identity') && _.get(touched, 'orderData.identity')}
              />
          }
          {(_.get(values, 'orderData.identityType') === '2') &&
            <InputMask
              mask={MASK_CNPJ}
              name="orderData.identity"
              label="CNPJ *"
              disabled={previewMode || editLocked}
              hasError={_.get(errors, 'orderData.identity') && _.get(touched, 'orderData.identity')}
              />
          }
          {(_.get(values, 'orderData.identityType') === '3') &&
            <InputMask
              mask={MASK_PIS_PASEP}
              name="orderData.identity"
              label="PIS/PASEP *"
              disabled={previewMode || editLocked}
              hasError={_.get(errors, 'orderData.identity') && _.get(touched, 'orderData.identity')}
              />
          }
          {(!/^(1|2|3)/.test(_.get(values, 'orderData.identityType'))) &&
            <InputGroup
              type="text"
              name="orderData.identity"
              label="Número da inscrição *"
              disabled={previewMode || _.get(values, 'orderData.identityType') === '0' || editLocked}
              maxLength="15"
              />
          }
        </BsCol>

        <BsCol md={24} lg={12} xl={12}>
          <InputGroup
            type="text"
            name="orderData.name"
            label="Beneficiário *"
            disabled={previewMode || editLocked}
            hasError={_.get(errors, 'orderData.name') && _.get(touched, 'orderData.name')}
            />
        </BsCol>
      </BsRow>

      <BsRow>
        <BsCol md={24} lg={24} xl={24}>
          <FlexRow gap="0">
            <FlexCol flex="1">
              <InputGroup
                  type="text"
                  name="orderData.pixQrCode"
                  label="QR Code PIX *"
                  disabled={previewMode || editLocked}
                  hasError={_.get(errors, 'orderData.pixQrCode') && _.get(touched, 'orderData.pixQrCode')}
                  />
            </FlexCol>

            <FlexCol flex="0 0 100px">
              <Button
                title="Consultar dados do QR Code"
                noMargin={true}
                height={46}
                color="primary"
                disabled={!_.get(values, 'orderData.pixQrCode') || previewMode || editLocked}
                onClick={() => getPixQrCodeData(_.get(values, 'orderData.pixQrCode'), values)}>
                Consultar
              </Button>
            </FlexCol>
          </FlexRow>
        </BsCol>
      </BsRow>

      <BsRow>
        <BsCol md={24} lg={24} xl={12}>
          <InputGroup
            type="text"
            name="orderData.pixUrl"
            label="URL PIX *"
            disabled={true}
            maxLength="80"
            hasError={_.get(errors, 'orderData.pixUrl') && _.get(touched, 'orderData.pixUrl')}
            />
        </BsCol>

        <BsCol md={24} lg={24} xl={12}>
          <InputGroup
            type="text"
            disabled={true}
            name="orderData.transactionId"
            label="TxID"
            maxLength="30"
            />
        </BsCol>
      </BsRow>
    </FieldSet>
  )
};

export const Schema = (values) => ({
  orderData: Yup.object().typeError('Informe os dados da ordem').shape({
    name: Yup.string().required('Informe o Beneficiário'),
    identityType: Yup.string().required('Informe o tipo de inscrição'),
    identity: Yup.mixed().test('match', '', function(value) {
      const { path, parent, createError } = this;
      const val = value || _.get(parent, 'identity'),
        type = _.get(parent, 'identityType');

      if (!val) {
        return createError({ path, message: 'Informe o número da inscrição' });

      } else if (val && type === '1' && !isValidCpf(val)) {
        return createError({ path, message: 'Informe um CPF válido' });

      } else if (val && type === '2' && !isValidCnpj(val)) {
        return createError({ path, message: 'Informe um CNPJ válido' });

      } else if (val && type === '3' && !isValidPisPasep(val)) {
        return createError({ path, message: 'Informe um PIS/PASEP válido' });
      }
      return true;
    }),
    pixQrCode: Yup.string().required('Informe o QR Code do PIX'),
  })
});
