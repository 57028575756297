import React from 'react';
import * as Yup from 'yup';
import { InputGroup, Select, CurrencyField } from '~/components/form';
import { BsCol } from '~/components/layout';
import _ from 'lodash';

const buildValidation = field => {
  return Yup.mixed().test('match', '', function(val) {
    const { path, parent, createError } = this,
      value = val || _.get(parent, path),
      length = _.size(String(value || '')),
      part = field.type === 'number' ? 'dígitos' : 'caracteres';

    if (field.required && !value) {
      return createError({ path, message: `Informe um valor para ${_.toLower(field.label)}` });
    }
    if (value && field.maxLength && field.maxLength < length) {
      return createError({ path, message: `${field.label} deve ter no máximo ${field.maxLength} ${part}.` });
    }
    if (value && field.minLength && field.minLength > length) {
      return createError({ path, message: `${field.label} deve ter no minimo ${field.minLength} ${part}.` });
    }
    if (value && field.type === 'number' && isNaN(value)) {
      return createError({ path, message: `${field.label} deve ser numérico.` });
    }
    return false;
  });
};

export const ExtraSchema = (values, settings, fieldsPath, layoutType) => {
  let validationFields = {},
    fields = _.filter(_.get(settings, fieldsPath) || [], f => !f.layout || f.layout === layoutType) || [];
  _.each(fields, field => {
    validationFields[`extraFields.${field.name}`] = buildValidation(field);
  });
  return validationFields;
};

export const ExtraFields = ({ settings, values, fieldsPath, errors, touched, previewMode, layoutType }) => {
  let fields = _.filter(_.get(settings, fieldsPath) || [], f => !f.layout || f.layout === layoutType),
    size = _.size(fields),
    isEven = size % 2 === 0,
    lastIdx = size - 1;

  return (
    <>
      {_.map(fields, (field, idx) => (
        <BsCol md={24} lg={isEven || (!isEven && lastIdx !== idx) ? 12 : 24} key={idx}>
          {field.type === 'select' && (
            <Select
              name={`extraFields.${field.name}`}
              disabled={previewMode}
              hasError={_.get(errors, `extraFields.${field.name}`) && _.get(touched, `extraFields.${field.name}`)}
              value={_.get(values, `extraFields.${field.name}`) || ''}
              label={field.label}
              options={{ values: field.options }}
            />
          )}
          
          {field.type === 'currency' && (
            <CurrencyField
              name={`extraFields.${field.name}`}
              label={field.label}
              disabled={previewMode}
              hasError={_.get(errors, `extraFields.${field.name}`) && _.get(touched, `extraFields.${field.name}`)}
              {..._.omit(field, 'name', 'type')}
            />
          )}

          {field.type !== 'select' && field.type !== 'currency' &&(
            <InputGroup
              type="text"
              name={`extraFields.${field.name}`}
              disabled={previewMode}
              hasError={_.get(errors, `extraFields.${field.name}`) && _.get(touched, `extraFields.${field.name}`)}
              {..._.omit(field, 'name', 'type')}
            />
          )}
        </BsCol>
      ))}
    </>
  );
};
