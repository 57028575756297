import React, { useState } from 'react';
import _ from 'lodash';
import { TabContainer } from '~/pages/responses/styles';
import DataTable from '~/components/datatable';
import { formats } from 'helper';
import { SubtitleItem, Subtitle } from '~/components/datatable/subtitle';
import { statusLabels } from '~/pages/bills';
import TextWithHint from '~/components/text-with-hint';

const columns = [
    {
      name: 'Nº da fatura',
      selector: 'bill',
      width: '160px',
      cell: (row) => <TextWithHint text={_.get(row, 'bill.documentNumber') || ''} />
    },
    {
      name: 'Nosso número',
      selector: 'bill',
      width: '160px',
      cell: (row) => <TextWithHint text={_.get(row, 'bill.billData.ourNumber') || ''} />
    },
    {
      name: 'Situação',
      selector: 'bill',
      width: '100px',
      cell: (row) => {
        const status = _.get(row, 'bill.registered') && _.get(row, 'bill.status') === 'open' ? 'registered' : _.get(row, 'bill.status');
        return <TextWithHint text={statusLabels[status]}/>;
      }
    },
    {
      name: 'Vencimento',
      selector: 'bill',
      width: '115px',
      cell: (row) => <TextWithHint text={formats.dateTimeZone(_.get(row, 'bill.dueDate'), 'dd/MM/yyyy')} />
    },
    {
      name: 'Valor (R$)',
      selector: 'value',
      right: true,
      cell: (row) => <TextWithHint text={formats.decimal(_.get(row, 'bill.value'))} />
    },
    {
      name: 'Ocorrência',
      selector: 'occurrence',
      center: true,
      width: '140px',
      cell: (row) => <TextWithHint style={{ width: '100%' }} text={`${_.get(row, 'occurrence.code')} - ${_.get(row, 'occurrence.description')}`} />
    },
    {
      name: 'Pago em',
      selector: 'extras',
      right: true,
      width: '115px',
      cell: (row) => <TextWithHint text={formats.dateTimeZone(_.get(row, 'extras.payDate'), 'dd/MM/yyyy')} />
    },
    {
      name: 'Pago (R$)',
      selector: 'extras',
      right: true,
      cell: (row) => <TextWithHint text={formats.decimal(_.get(row, 'extras.payAmount'))} />
    },
    {
      name: 'Desc. (R$)',
      selector: 'discount',
      right: true,
      cell: (row) => <TextWithHint text={formats.decimal(_.get(row, 'discount'))} />
    }
  ];

const getRows = (array = [], offset, limit) => {
  return array.slice(offset, offset + limit);
};

const conditionalRowStyles = [
  {
    when: (row) => !!_.get(row, 'bill.erpError'),
    style: { color: '#e067e0 !important', '&:hover': { color: '#e067e0' }}
  }
];

export const TabBills = ({ bills, documentsWithError, openDocument, tabId }) => {
  const hasError = tabId === 'billsError';
  const list = hasError ? documentsWithError : bills;
  const count = _.size(list);

  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(20);

  const onChangePage = (offset, limit) => {
    setOffset(offset);
    setLimit(limit);
  };

  return (
    <TabContainer>
      { hasError &&
        <Subtitle bottom={15} left={10}>
          <span>Legenda:</span>
          <SubtitleItem color={'#e067e0'}>Não conciliado no ERP</SubtitleItem>
        </Subtitle>
      }

      <DataTable
        emptyText="Nenhuma fatura processada"
        pageSize={limit}
        onChangePage={onChangePage}
        data={{ rows: getRows(list, offset, limit), offset, count, limit: limit }}
        onRowClicked={openDocument('charge')}
        columns={columns}
        hideUpdateButton={true}
        extraOptions={{
          conditionalRowStyles: conditionalRowStyles,
        }}
      />
    </TabContainer>
  )
};
