import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import * as Yup from 'yup';
import { BASE_URL } from '~/constants';
import { toast } from 'react-toastify';
import * as Forms from '~/pages/payment-orders/forms';
import { formatBankContractSimple, formatBankContract } from '~/pages/bank-contracts/form';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as HeaderActions } from '~/store/modules/header';
import { Formik } from 'formik';
import { startOfDay } from 'date-fns';
import {
  FormContainer,
  FormToolbar,
  AttachmentTableContainer,
  DetailTableContainer,
  ApprovalDetailTableContainer,
  TinyDetailTableContainer,
  CellIcon,
  Line
} from '~/pages/payment-orders/styles';
import CellStatus from '~/components/datatable/cell-status';
import Modal from '~/components/modal';
import EmptyState from '~/components/empty-state';
import { BusinessPartnerForm, BusinessPartnerSchema } from '~/pages/business-partners/form';
import { IconButton } from '~/components/button';
import { InputGroup, InputLabel, InputDate, Autocomplete, CurrencyField, Select, FileUpload } from '~/components/form';
import { FlexRow, FlexCol, BsContainer, BsRow, BsCol } from '~/components/layout';
import { statusMap, beneficiaryNotes } from '~/pages/payment-orders/constants';
import { GrDocumentPdf } from 'react-icons/gr';
import { BiUnlink } from 'react-icons/bi';
import { VscOutput, VscSymbolOperator } from 'react-icons/vsc';
import { MdKeyboardBackspace, MdDelete, MdFileDownload, MdErrorOutline, MdAdd, MdSave, MdQueuePlayNext, MdCancel, MdMailOutline, MdLock, MdLockOpen, MdAutorenew, MdFactCheck } from 'react-icons/md';
import { IoWarningOutline, IoDocumentAttach } from 'react-icons/io5';
import { formats, validateEmail } from '~/helper';
import { Creators as PaymentOrderActions } from '~/store/modules/payment-order';
import DataTable from '~/components/datatable';
import Fieldset from '~/components/fieldset';
import confirm from '~/components/confirm';
import PDF from '~/components/pdf';
import { blue, green, purple } from '~/components/mixins/color';
import ExtraModal from '~/pages/payment-orders/modal-extra';
import BalancesModal from '~/pages/payment-orders/modal-balances';
import useModal from '~/hooks/use-modal';
import { SubtitleItem, Subtitle } from '~/components/datatable/subtitle';
import { Menu, MenuItem, MenuDivider } from '@szhsin/react-menu';
import { FaCheck } from 'react-icons/fa';
import InstructionModal from '~/pages/payment-orders/modal-instruction';
import CancellationReasonModal from '~/pages/payment-orders/modal-cancellation-reason';

export const scopeTypes = {
  'general': 'Geral',
  'company': 'Empresa',
  'bankContract': 'Contrato',
  'businessPartnerGroup': 'Grupo de parceiro',
};

const statsColors = {
  success: green.hex(),
  error: '#BA1F33',
};

const SubForm = props => {
  let paymentOrderType = _.get(props, 'values.paymentOrderType'),
    found = _.find(Forms, { id: _.get(paymentOrderType, 'code') }),
    El = _.get(found, 'Render');

  return El ? <El {...props} /> : null;
};

const customStyles = {
  rows: {
    denseStyle: {
      minHeight: '30px'
    }
  }
};

const getSubFormSchema = values => {
  let found = _.find(Forms, { id: _.get(values, 'paymentOrderType.code') }),
    schema = _.get(found, 'Schema');
  return schema ? schema(values) : {};
};

const propagateSubForm = ({ values, setFieldValue }, field, value) => {
  let id = field === 'paymentOrderType' ? _.get(value, 'code') : _.get(values, 'paymentOrderType.code');
  let found = _.find(Forms, { id });

  if (_.get(found, 'onParentChange')) {
    found.onParentChange({ field, value, setFieldValue, parent: values });
  }
};

const renderMovements = (values, onOpenMovement) => {
  let movements = _.get(values, 'movementOcc') || [];

  if (_.isEmpty(movements)) {
    return null;
  }
  let shippings = _.orderBy(
      _.filter(movements, r => _.get(r, 'movement.fileType') === 'shipping'),
      ['createdAt'],
      ['desc']
    ),
    responses = _.orderBy(
      _.filter(movements, r => _.get(r, 'movement.fileType') === 'response'),
      ['createdAt'],
      ['desc']
    );

  const statsColors = {
    open: green.hex(),
    closed: blue.hex(),
    canceled: '#BA1F33',
    processing: purple.hex()
  };

  return (
    <BsRow>
      <BsCol md={24} lg={12} xl={12}>
        <Fieldset label="Remessas">
          <DetailTableContainer>
            <DataTable
              noPagination={true}
              emptyText="Nenhuma remessa vinculada"
              hideUpdateButton={true}
              data={{ rows: shippings }}
              onRowClicked={onOpenMovement}
              padding="0"
              columns={[
                {
                  name: ' ',
                  selector: 'status',
                  compact: true,
                  width: '10px',
                  cell: row => <CellStatus title="" color={statsColors[_.get(row, 'movement.status') || 'open']} />
                },
                {
                  name: 'Layout',
                  selector: 'movement',
                  compact: true,
                  width: '90px',
                  format: row => _.toUpper(_.get(row, 'movement.layoutType'))
                },
                {
                  name: 'Ocorrência',
                  selector: 'occurrence',
                  wrap: true,
                  compact: true,
                  format: row => `${_.get(row, 'occurrence.code')} - ${_.get(row, 'occurrence.description')}`
                },
                {
                  name: 'Data da ocorrência',
                  selector: 'createdAt',
                  compact: true,
                  width: '140px',
                  format: row => formats.dateTimeZone(_.get(row, 'createdAt'), 'dd/MM/yyyy HH:mm')
                }
              ]}
            />
          </DetailTableContainer>
        </Fieldset>
      </BsCol>

      <BsCol md={24} lg={12} xl={12}>
        <Fieldset label="Retornos">
          <DetailTableContainer>
            <DataTable
              noPagination={true}
              emptyText="Nenhum retorno vinculado"
              data={{ rows: responses }}
              onRowClicked={onOpenMovement}
              padding="0"
              columns={[
                {
                  name: 'Layout',
                  selector: 'movement',
                  width: '90px',
                  format: row => _.toUpper(_.get(row, 'movement.layoutType'))
                },
                {
                  name: 'Ocorrência',
                  selector: 'occurrence',
                  wrap: true,
                  compact: true,
                  format: row => `${_.get(row, 'occurrence.code')} - ${_.get(row, 'occurrence.description')}`
                },
                {
                  name: 'Data da ocorrência',
                  selector: 'createdAt',
                  width: '140px',
                  compact: true,
                  format: row => formats.dateTimeZone(_.get(row, 'createdAt'), 'dd/MM/yyyy HH:mm')
                }
              ]}
            />
          </DetailTableContainer>
        </Fieldset>
      </BsCol>
    </BsRow>
  );
};

const HistoryRow = ({ data = {} }) => {
  let rows = _.orderBy(data.history, ['createdAt'], ['desc']);

  return (
    <ApprovalDetailTableContainer>
      <DataTable
        noPagination={true}
        emptyText="Nenhum histórico encontrado."
        hideUpdateButton={true}
        noTableHeader={true}
        data={{ rows }}
        onRowClicked={_.noop}
        padding="0"
        columns={[
          {
            name: 'Usuário',
            selector: 'user',
            compact: true,
            width: '300px',
            format: row => _.get(row, 'user.name')
          },
          {
            name: 'Decisão',
            selector: 'approved',
            compact: true,
            center: true,
            width: '100px',
            format: row => (row.approved ? 'Aprovou' : 'Rejeitou')
          },
          {
            name: 'Localização',
            selector: 'extras',
            compact: true,
            center: true,
            width: '200px',
            cell: row => {
              let tip = _.get(row, 'extras.positionTip') || `${_.get(row, 'extras.position.latitude')}, ${_.get(row, 'extras.position.longitude')}`,
                text = _.get(row, 'extras.positionText') || `${_.get(row, 'extras.position.latitude')}, ${_.get(row, 'extras.position.longitude')}`;

              return (
                <div className="text-truncate" title={`Ver no mapa: ${tip}`}>
                  <a className="app-link" rel="noopener noreferrer" target="_blank" href={_.get(row, 'extras.position.link')}>
                    {text}
                  </a>
                </div>
              );
            }
          },
          {
            name: 'IP',
            selector: 'extras',
            compact: true,
            center: true,
            width: '180px',
            format: row => _.get(row, 'extras.access.address')
          },
          {
            name: 'Motivo da rejeição',
            selector: 'extras',
            compact: true,
            format: row => _.get(row, 'extras.rejectReason') || ''
          },
          {
            name: 'Data',
            selector: 'createdAt',
            compact: true,
            width: '140px',
            format: row => formats.dateTimeZone(_.get(row, 'createdAt'), 'dd/MM/yyyy HH:mm')
          }
        ]}
        extraOptions={{
          highlightOnHover: false,
          selectableRows: false,
          selectableRowsHighlight: false
        }}
      />
    </ApprovalDetailTableContainer>
  );
};

const renderApprovalsHistory = values => {
  let approvals = _.get(values, 'approvalHistory') || [];

  if (_.isEmpty(approvals)) {
    return null;
  }

  return (
    <BsRow>
      <BsCol md={24} lg={24} xl={24}>
        <Fieldset label="Histórico de aprovações">
          <DetailTableContainer>
            <DataTable
              noPagination={true}
              emptyText="Nenhuma aprovação encontrada."
              hideUpdateButton={true}
              noTableHeader={true}
              data={{ rows: approvals }}
              onRowClicked={_.noop}
              padding="0"
              columns={[
                {
                  name: 'Descrição',
                  selector: 'completed',
                  compact: true,
                  format: row => {
                    let text = 'Em Aprovação';

                    if (row.completed) {
                      let repproved = _.find(_.get(row, 'history'), { approved: false });
                      text = repproved ? 'Rejeitada' : 'Aprovada';
                    }
                    return `Rotina de aprovação - ${text}`;
                  }
                },
                {
                  name: 'Min. Aprovadores',
                  selector: 'createdAt',
                  compact: true,
                  center: true,
                  width: '300px',
                  format: row => `Mínimo de aprovadores: ${_.get(row, 'criteria.minApprovers')}`
                },
                {
                  name: 'Data',
                  selector: 'createdAt',
                  compact: true,
                  right: true,
                  width: '140px',
                  format: row => formats.dateTimeZone(_.get(row, 'createdAt'), 'dd/MM/yyyy HH:mm')
                }
              ]}
              extraOptions={{
                noTableHead: true,
                expandableRows: true,
                highlightOnHover: true,
                expandOnRowClicked: true,
                selectableRows: false,
                selectableRowsHighlight: false,
                expandableRowDisabled: row => _.isEmpty(_.get(row, 'history')),
                expandableRowsComponent: <HistoryRow />
              }}
            />
          </DetailTableContainer>
        </Fieldset>
      </BsCol>
    </BsRow>
  );
};

const renderBinds = values => {
  let binds = _.get(values, 'binds') || [];

  if (_.isEmpty(binds)) {
    return null;
  }
  return (
    <Fieldset label="Ordens de pagamento vinculadas">
      <DetailTableContainer>
        <DataTable
          noPagination={true}
          emptyText="Nenhuma ordem de pagamento vinculada"
          data={{ rows: binds }}
          onRowClicked={_.noop}
          padding="0"
          columns={[
            {
              name: 'Tipo',
              selector: 'paymentOrderType',
              wrap: true,
              format: row => _.get(row, 'paymentOrderType.name')
            },
            {
              name: 'Nr. Documento',
              selector: 'documentNumber',
              width: '150px',
              right: true,
              format: row => _.get(row, 'documentNumber')
            },
            {
              name: 'Vencimento',
              selector: 'dueDate',
              width: '120px',
              right: true,
              format: row => formats.dateTimeZone(_.get(row, 'dueDate'), 'dd/MM/yyyy')
            },
            {
              name: 'Agendamento',
              selector: 'scheduledDate',
              width: '120px',
              right: true,
              format: row => formats.dateTimeZone(_.get(row, 'scheduledDate'), 'dd/MM/yyyy')
            },
            {
              name: 'Valor',
              selector: 'value',
              right: true,
              width: '180px',
              format: row => formats.currency(_.get(row, 'value'))
            },
            {
              name: 'Cadastrada em',
              selector: 'createdAt',
              width: '140px',
              format: row => formats.dateTimeZone(_.get(row, 'createdAt'), 'dd/MM/yyyy HH:mm')
            }
          ]}
        />
      </DetailTableContainer>
    </Fieldset>
  );
};

const validateByOrderType = values => {
  let orderType = _.get(values, 'paymentOrderType.code'),
    digitableLine = _.get(values, 'orderData.digitableLine'),
    bankCode = _.get(values, 'bankContract.bankAccount.bank.code'),
    company = _.get(values, 'company'),
    businessPartner = _.get(values, 'businessPartner'),
    entryType = _.get(values, 'entryType');

  if (orderType === 20 && digitableLine) {
    let correctValue = bankCode === String(digitableLine).substring(0, 3) ? '30' : '31';
    if (entryType !== correctValue) {
      return false;
    }
  }
  if (orderType === 10 && company && businessPartner) {
    let cmpIdentity = _.get(company, 'identity'),
      bpIdentity = _.get(businessPartner, 'identity'),
      correctValue = bpIdentity === cmpIdentity ? '43' : '41';

    if (entryType !== correctValue) {
      return false;
    }
  }
  return true;
};

function PaymentOrderDetail({ history, match, acls, location, headLess }) {
  const dispatch = useDispatch();
  const canWrite = acls.includes('W');
  const canPrint = acls.includes('P');
  const canViewExtra = acls.includes('PE');
  const state = useSelector(state => state.paymentOrder);
  const headerState = useSelector(state => state.header);
  const loading = state.formLoading;
  const today = startOfDay(new Date());
  const status = _.get(state, 'model.status');
  const [paymentOrderReopen, setPaymentOrderReopen] = useState(false);
  const [paymentOrderId, setPaymentOrderId] = useState('');
  const orderType = _.get(state, 'model.paymentOrderType.code');
  const previewMode = loading || !canWrite || (status && !/^(pending|open)$/.test(status));
  const canEdit = canWrite && !previewMode;
  const hasErpData = !!_.get(state, 'model.extras.DatabaseId');
  const hasBalanceData = !_.isEmpty(_.get(state, 'model.balances'));
  const ordersTypesMap = _.get(state, 'ordersTypesMap') || {};
  const [isOpenExtraModal, toggleExtraModal] = useModal();
  const [isOpenBalancesModal, toggleBalancesModal] = useModal();
  const canSendEmail = /^(closed)$/.test(status) && (_.get(state, 'model.businessPartner.email') || _.size(_.get(state, 'model.businessPartner.extras.contacts')) >= 1);
  const canWriteSpec = canWrite && !/^(returned|notApproved|rejected)$/.test(status)
  const [isOpenInstructionModal, toggleInstructionModal] = useModal();
  const bankSettings = _.get(state, 'model.bankContract.bankAccount.bank.settings');

  const erpError = _.get(state, 'model.erpError');
  const genMessages = [];
  const logsMessages = [];

  _.each(_.get(state, 'model.generationMessages.errors') || [], message => genMessages.push({ message, type: 'error' }));
  _.each(_.get(state, 'model.generationMessages.warnings') || [], message => genMessages.push({ message, type: 'warning' }));
  _.each(_.get(state, 'model.logs.messages') || [], (message, i) => logsMessages.push({ message, id: i }) );

  if (erpError) {
    genMessages.unshift({ message: erpError, type: 'error' });
  }

  const canCancel = /^(pending|open|notApproved|rejected|returned|scheduled)$/.test(status) && canWrite && !headLess;


  const isBlocked = (_.get(state, 'model.isBlocked') || false);

  const canBlock = !isBlocked && /^(pending|open)$/.test(status) && acls.includes('BP');
  const canUnBlock = isBlocked && /^(pending|open)$/.test(status) && acls.includes('BP');

  const canUnlink = /^(open|pending)$/.test(status) && _.get(state, 'model.digitalCharge') && orderType === 20 && canWrite && !headLess;
  const canGeneratePdf = /^(closed)$/.test(status);
  const fromDda = orderType === 20 && _.get(state, 'model.digitalCharge');
  const canRequestErpData = /^(pending|open)$/.test(status) && hasErpData && !fromDda;
  const canReopen = /^(notApproved|rejected|canceled|returned)$/.test(status) && canWrite;
  const editLocked = _.get(state, 'model.editLocked') || false;
  const allowOnlyInstructionsByERP = !(_.get(state, 'model.cfgData.allowOnlyInstructionsByERP') && hasErpData);
  const canRunInstructions = canWrite && status === 'scheduled' && allowOnlyInstructionsByERP;
  const requireCancellationReason = _.get(state, 'model.cfgData.requireCancellationReason');
  const [isOpenCancellationReasonModal, toggleCancellationReasonModal] = useModal();
  const cancellationReason = _.get(state, 'model.cancellationReason')

  useEffect(() => {
    const { id } = _.get(match, 'params') || {};
    dispatch(PaymentOrderActions.load(id));
    setPaymentOrderId(id)
  }, [dispatch, match, location.search]);

  useEffect(() => {
    const id = _.get(match, 'params.id'),
      config = {
        subtitle: isBlocked ? Blocked() : `${id === 'new' ? 'Nova' : 'Edição da'} ordem de pagamento`,
        filter: { ...headerState.filter, visible: false, scope: '/payment-orders' }
      };

    if (state && state.model) {
      dispatch(HeaderActions.configure(config));
    }
    // eslint-disable-next-line
  }, [state, dispatch, match]);

  // Sempre que o state paymentOrderReopen for atualizado e for igual a true e o status do model
  // for igual a "pending" então irá fazer uma nova requisição
  useEffect(() => {
    if (paymentOrderReopen && status === 'pending') {
      dispatch(PaymentOrderActions.load(paymentOrderId));
      setPaymentOrderReopen(false);
    }
  }, [dispatch, paymentOrderId, paymentOrderReopen, status])

  const getNotesFromBankCode = code => {
    if (_.isEmpty(code)) {
      return [];
    }
    return _.filter(beneficiaryNotes, f => f.bankCode === code);
  };

  const handleDataList = (field, valueField = 'value', labelField = 'label', allowedValues, filterFunction = (filtered) => filtered) => (term, callback) => {
    let list = _.get(state, field) || [],
      termRegex = new RegExp(_.deburr(term), 'i'),
      allowed = !allowedValues ? list : _.filter(list, r => allowedValues.includes(_.get(r, valueField))),
      filtered = _.filter(allowed, r => {
        return termRegex.test(_.deburr(_.get(r, labelField)));
      });

      filtered = filterFunction(filtered);

    callback(filtered.slice(0, 30));
  };

  const getDataList = field => {
    return _.get(state, field) || [];
  };

  const handleListBankContracts = (term, company, banks, callback) => {
    dispatch(PaymentOrderActions.listBankContracts({ term, company, banks }, callback));
  };

  const handleListCompanies = (params, callback) => {
    dispatch(PaymentOrderActions.listCompanies(params, callback));
  };

  const handleListBusinessPartners = (term, callback) => {
    dispatch(PaymentOrderActions.listBusinessPartners(term, callback));
  };

  const handleListBanks = (term, callback) => {
    let params = _.isObject(term) ? term : { term };
    dispatch(
      PaymentOrderActions.listBanks(params, list => {
        callback(_.map(list, r => _.pick(r, 'code', 'name', 'ispb')));
      })
    );
  };

  function handleListCities(state, term, callback) {
    dispatch(PaymentOrderActions.listCities(state, term, callback));
  }

  function handlePixQrCodeData(code, model) {
    dispatch(PaymentOrderActions.getPixQrCodeData(code, model));
  }

  const handleOnSubmit = async (form, justAttachments) => {
    let errors = await form.validateForm(),
      actions = { headLess };

    if (_.isEmpty(errors)) {
      let params = _.cloneDeep(justAttachments ? _.pick(form.values, 'id', 'files', 'attachments', 'emails') : form.values);
      params.justAttachments = !!justAttachments;
      dispatch(PaymentOrderActions.createOrUpdate(params, actions));
    } else {
      let base = _.omit(errors, 'orderData');
      if (_.isEmpty(base) && !_.isEmpty(errors.orderData)) {
        base = errors.orderData;
      }
      toast.error(_.first(_.values(base)) || 'Cadastro inválido');
    }
  };

  const isInvalidForm = form => {
    return false;
    // let { isValid } = form;
    // return !isValid || loading;
  };

  function handleSearchCnpj(value, model) {
    dispatch(PaymentOrderActions.searchCnpjRequest(value, model));
  }

  const openBusinessPartnerModal = () => {
    dispatch(PaymentOrderActions.openBusinessPartnerModal());
  };

  const closeBusinessPartnerModal = () => {
    dispatch(PaymentOrderActions.closeBusinessPartnerModal());
  };

  const saveBusinessPartnerModal = (values, model) => {
    dispatch(PaymentOrderActions.saveBusinessPartnerModal(values, model));
  };

  const handleListBusinessPartnerGroup = (term, callback) => {
    dispatch(PaymentOrderActions.listBusinessPartnerGroup(term, callback));
  };

  function handleOpenMovement({ movement }) {
    let id = _.get(movement, 'id'),
      fileType = _.get(movement, 'fileType');
    if (id && fileType) {
      const currentPage = _.get(_.reject(_.get(location, 'pathname', '').split('/'), _.isEmpty), '[0]');
      history.push(`/${fileType === 'response' ? 'responses' : 'shippings'}/${id}`, {from: currentPage});
    }
  }

  const handleOnBlock = async data => {
    const result = await confirm.show({
      title: 'Atenção',
      text: `Deseja realmente bloquear essa ordem de pagamento?`
    });

    if (result) {
      dispatch(PaymentOrderActions.block(data));
    }
  };

  const handleOnUnBlock = async data => {
    const result = await confirm.show({
      title: 'Atenção',
      text: `Deseja realmente desbloquear essa ordem de pagamento?`
    });

    if (result) {
      dispatch(PaymentOrderActions.unblock(data));
    }
  };

  const handleOnCancel = async data => {
    if (requireCancellationReason){
      toggleCancellationReasonModal(true)
      return
    }

    const result = await confirm.show({
      title: 'Atenção',
      text: `Deseja realmente cancelar essa ordem de pagamento? Essa ação não poderá ser desfeita`
    });

    if (result) {
      dispatch(PaymentOrderActions.cancel(data));
    }
  };

  const handleOnReopen = async data => {
    const result = await confirm.show({
      title: 'Atenção',
      text: `Deseja realmente reabrir essa ordem de pagamento? Essa ação não poderá ser desfeita`
    });

    if (result) {
      dispatch(PaymentOrderActions.reopenStatus(data));

      setPaymentOrderReopen(true);
    }
  };

  const processInstruction = (data, action) => {
    action.toggleModal = toggleInstructionModal;
    dispatch(PaymentOrderActions.processInstruction(data, action));
  };

  const handleOnSubmitCancellationReason = async ({ cancellationReason }) => {
    const action = {
      toggleModal: toggleCancellationReasonModal
    }
    const data = {
      id: _.get(state, 'model.id'),
      cancellationReason
    }

    let result = await confirm.show({
      title: 'Atenção',
      width: 500,
      text: `Deseja realmente cancelar essa ordem de pagamento? Essa ação não poderá ser desfeita`
    })

    if (result) {
      dispatch(PaymentOrderActions.cancel(data, action));
    }
  }

  async function handleRequestErpData(values) {
    dispatch(PaymentOrderActions.requestErpData({ id: values.id }));
  }

  const handleOnUnlink = async data => {
    const result = await confirm.show({
      title: 'Atenção',
      text: `Deseja realmente desvincular essa ordem de pagamento da cobrança eletrônica?`
    });

    if (result) {
      dispatch(PaymentOrderActions.unlink(data));
    }
  };

  async function handleDownloadAttachment(file) {
    let data = { id: _.get(state, 'model.id'), filename: file.name };
    dispatch(PaymentOrderActions.downloadFile(data));
  }

  async function handleRemoveAttachment(file, setFieldValue, values, index) {
    const result = await confirm.show({
      title: 'Atenção',
      text: `Deseja realmente remover esse anexo?`
    });

    if (result && index >= 0) {
      let attachments = _.cloneDeep(_.get(values, 'attachments') || []);
      attachments.splice(index, 1);
      setFieldValue('attachments', attachments);
    }
  }

  const handleGeneratePdf = async data => {
    if (data.id) {
      await PDF.show({
        title: `[${formats.currency(_.get(data, 'value'))}] - ${formats.cnpj_cpf(_.get(data, 'businessPartner.identity'))} - ${_.get(
          data,
          'businessPartner.name'
        )}`,
        url: `${BASE_URL}/payment-orders/pdf/${data.id}/comprovante.pdf`
      });
    }
  };

  const getList = (code, field, newMapping) => {
    if (!code) {
      return [];
    }
    return _.get(newMapping || ordersTypesMap, `${code}.${field}`) || [];
  };

  async function updateOrdersTypesMap({ setFieldValue, values }, bankContract) {
    let ordersType = _.get(bankContract, 'bankAccount.bank.settings.payment.ordersType');

    await dispatch(
      PaymentOrderActions.updateOrdersTypesMap(ordersType, newMapping => {
        applyDefaultValues(setFieldValue, _.get(values, 'paymentOrderType.code'), newMapping);
      })
    );
  }

  const applyDefaultValues = (setFieldValue, code, newMapping) => {
    let defaults = getList(code, 'defaults', newMapping) || {};

    if (defaults.serviceType) {
      setFieldValue('serviceType', defaults.serviceType);
    }
    if (defaults.entryType) {
      setFieldValue('entryType', defaults.entryType);
    }
  };

  if (loading) {
    return <EmptyState visible={true} text="Carregando..." />;
  }
  if (!_.get(state, 'model.id') && _.get(match, 'params.id') !== 'new') {
    return <EmptyState visible={true} text="Ordem de pagamento não encontrada!" />;
  }

  async function handleSendEmail(values, email) {

    const confirmProps = {
      width: 600,
      height: 200,
      title: 'Atenção',
      text: `Deseja enviar o comprovante para o email ${email.replaceAll(/;/ig, ', ')}?`
    };

    if (email) {
      const result = await confirm.show(confirmProps);
      if (result) {
        dispatch(PaymentOrderActions.sendEmail({ id: values.id, email }));
      }
    }

  };

  const getValidOrderEmails = (strEmails) => {
    let result = [];
    if (strEmails) {
      const arrEmails = strEmails.replace(/\s/g, "").split(',');
      _.map(arrEmails, (mail) => {
        if(mail){
          const validMail = validateEmail(mail);
          if (validMail)
            result.push(mail)
        }
      });
    }
    return result
  }

  const EmailMenu = ({ values }) => {

    let businessPartner = _.get(values, 'businessPartner');
    let emails = _.get(businessPartner, 'extras.contacts') || [];
    let partnerEmail = _.get(businessPartner, 'email');
    let emailList = [];
    const orderEmails = getValidOrderEmails(_.get(values,'emails')) || [];

    if (!_.isEmpty(partnerEmail)){
      emailList.push({ key: 'Principal', value: _.get(businessPartner,'email') });
    }

    if(!_.isEmpty(emails)){
      _.map(emails, contact => {
        if(_.get(contact, 'email', '') !== '' && _.get(contact, 'email', '').length >= 10){
          emailList.push({ key: _.get(contact, 'name'), value: _.get(contact, 'email') });
        }
      });
    }

    if(!_.isEmpty(orderEmails)) {
      _.map(orderEmails, (mail, index) => {
        emailList.push({
          key: `orderMail${index + 1}`,
          value: mail
        })
      })
    }

    emailList = _.uniqBy(emailList, 'value')

    let selecteds = '';
    for (let email of emailList) {
      selecteds += ';' + email.value;
    }

    const [emailSelecteds, setEmailSelecteds] = useState(selecteds);

    return (
      <Menu direction="top" arrow="true"  menuButton={<IconButton type="button" title="Enviar por e-mail"><MdMailOutline /></IconButton>}>
        {_.isEmpty(emailList) && (
          <MenuItem>
            <span>Nenhum e-mail encontrato.</span>
          </MenuItem>
        )}
        {!_.isEmpty(emailList) && _.map(emailList, (cellEmailObj, index) => {
          return <MenuItem type="checkbox" key={index} value={cellEmailObj.value} checked={_.size(emailSelecteds.split(';').filter(item => item === cellEmailObj.value)) === 1} onClick={(e) => {
            var split = [];
            if (e.checked) {
              split = emailSelecteds.split(';');
              split.push(e.value);
            } else {
              split = emailSelecteds.split(';');
              split = split.filter(item => item !== e.value);
            }
            setEmailSelecteds(split.join(';'));
            e.keepOpen = true;
            } }>
            <span>{cellEmailObj.value}</span>
          </MenuItem>
        })}
        <MenuDivider />
        {!_.isEmpty(emailList) && (
          <MenuItem onClick={() => handleSendEmail(values, emailSelecteds.substring(1)) }>
            <Line>
              <FaCheck />
              <span>Enviar para e-mails selecionados</span>
            </Line>
          </MenuItem>
        )}
      </Menu>
    )
  }


  return (
    <Formik
      enableReinitialize={true}
      validateOnMount={false}
      validationSchema={() => Yup.lazy(values => Schema(values, getSubFormSchema(values)))}
      initialValues={state.model}
    >
      {props => {
        const hasOrderType = _.get(props, 'values.paymentOrderType.code');
        return (
          <FormContainer headLess={headLess}>
            <BsContainer>
              <div className="main">
                <Fieldset label="Informações gerais">
                  <BsRow>
                    <BsCol md={12} lg={6} xl={3}>
                      <InputLabel label="Número" value={props.values.yourNumber} />
                    </BsCol>

                    <BsCol md={12} lg={12} xl={6}>
                      <Autocomplete
                        name="paymentOrderType"
                        keyField="id"
                        label="Tipo da ordem *"
                        disabled={previewMode || fromDda || editLocked}
                        value={_.get(props, 'values.paymentOrderType')}
                        valueFormat={row => `${row.name || ''}`}
                        loadData={handleDataList('paymentOrderTypes', 'id', 'name')}
                        emptyText={'Selecione o tipo da ordem *'}
                        tipText={'Digite... '}
                        loadingText={'Carregando...'}
                        notFoundText={'Não encontrado'}
                        onChange={paymentOrderType => {
                          props.setFieldValue('paymentOrderType', paymentOrderType);
                          props.setFieldValue('orderData', {});
                          applyDefaultValues(props.setFieldValue, _.get(paymentOrderType, 'code'));
                          propagateSubForm(props, 'paymentOrderType', paymentOrderType);
                        }}
                      />
                    </BsCol>

                    <BsCol md={12} lg={6} xl={3}>
                      <InputDate
                        name="dueDate"
                        label="Data vencimento *"
                        hideErrorLabel={false}
                        disabled={previewMode || !hasOrderType || fromDda || editLocked}
                        minDate={today}
                        hasError={_.get(props.errors, 'dueDate') && _.get(props.touched, 'dueDate')}
                        onChange={val => {
                          props.setFieldValue('dueDate', val);
                          props.setFieldValue('scheduledDate', val);
                          propagateSubForm(props, 'dueDate', val);
                        }}
                      />
                    </BsCol>

                    <BsCol md={12} lg={6} xl={3}>
                      <InputDate
                        name="scheduledDate"
                        label="Data agendamento *"
                        disabled={previewMode || !hasOrderType || editLocked}
                        hideErrorLabel={false}
                        minDate={today}
                        hasError={_.get(props.errors, 'scheduledDate') && _.get(props.touched, 'scheduledDate')}
                      />
                    </BsCol>

                    <BsCol md={8} lg={6} xl={3}>
                      <InputGroup type="text" disabled={previewMode || !hasOrderType || fromDda || editLocked} name="documentNumber" maxLength={20} label="Nº documento *" />
                    </BsCol>

                    <BsCol md={8} lg={6} xl={3}>
                      <InputLabel label="Situação" value={statusMap[props.values.status]} />
                    </BsCol>

                    <BsCol md={8} lg={6} xl={3}>
                      <InputLabel label="Nosso número" value={props.values.ourNumber} />
                    </BsCol>
                  </BsRow>

                  <BsRow>
                    <BsCol md={24} lg={8} xl={8}>
                      <Autocomplete
                        name="company"
                        keyField="id"
                        label="Empresa *"
                        disabled={
                          (hasErpData && !_.get(props.values, 'digitalCharge') && _.get(props.values, 'company')) || previewMode || !hasOrderType || fromDda || editLocked
                        }
                        value={_.get(props.values, 'company')}
                        valueFormat={row => `${formats.cnpj_cpf(row.identity)} - ${row.name}`}
                        loadData={(term, callback) =>
                          handleListCompanies(
                            {
                              term,
                              identity: _.get(props, 'values.bankContract.bankAccount.company.identity'),
                              bindPolicy: _.get(props, 'values.bankContract.documentBindPolicy')
                            },
                            callback
                          )
                        }
                        emptyText={'Selecione uma empresa *'}
                        tipText={'Digite... '}
                        loadingText={'Carregando...'}
                        notFoundText={'Não encontrada'}
                        onChange={value => {
                          props.setFieldValue('company', value);
                          propagateSubForm(props, 'company', value);
                        }}
                      />
                    </BsCol>

                    <BsCol md={24} lg={8} xl={8}>
                      <FlexRow gap="0">
                        <FlexCol flex="1">
                          <Autocomplete
                            name="businessPartner"
                            keyField="id"
                            label="Beneficiário *"
                            disabled={(hasErpData && _.get(props.values, 'businessPartner')) || previewMode || !hasOrderType || fromDda || editLocked}
                            value={_.get(props.values, 'businessPartner')}
                            valueFormat={row => `${formats.cnpj_cpf(row.identity)} - ${row.name}`}
                            loadData={handleListBusinessPartners}
                            emptyText={'Selecione um beneficiário *'}
                            tipText={'Digite... '}
                            loadingText={'Carregando...'}
                            notFoundText="Não encontrado"
                            onChange={value => {
                              props.setFieldValue('businessPartner', value);
                              propagateSubForm(props, 'businessPartner', value);
                            }}
                          />
                        </FlexCol>
                        {!hasErpData && !fromDda && (
                          <FlexCol flex="0 0 50px">
                            <IconButton
                              size={46}
                              noMargin={true}
                              color="primary"
                              disabled={previewMode || !hasOrderType || editLocked}
                              title="Cadastrar novo"
                              onClick={openBusinessPartnerModal}
                            >
                              <MdAdd />
                            </IconButton>
                          </FlexCol>
                        )}
                      </FlexRow>
                    </BsCol>

                    <BsCol md={24} lg={8} xl={8}>
                      <Autocomplete
                        name="bankContract"
                        label="Contrato (débito)"
                        value={_.get(props, 'values.bankContract')}
                        disabled={previewMode || !hasOrderType || editLocked}
                        keyField="id"
                        clearable={true}
                        valueFormat={formatBankContractSimple}
                        optionsFormat={formatBankContract}
                        loadData={(term, callback) =>
                          handleListBankContracts(term, _.get(props, 'values.company.identity'), _.get(props, 'values.paymentOrderType.banks') || [], callback)
                        }
                        emptyText={'Pesquise um contrato'}
                        tipText={'Digite... '}
                        loadingText={'Carregando...'}
                        notFoundText={'Não encontrado'}
                        onChange={value => {
                          props.setFieldValue('bankContract', value);
                          props.setFieldValue('bankAccount', _.get(value, 'bankAccount'));
                          props.setFieldValue('bankAccountId', _.get(value, 'bankAccountId'));

                          updateOrdersTypesMap(props, value);
                          propagateSubForm(props, 'bankAccount', _.get(value, 'bankAccount'));
                        }}
                      />
                    </BsCol>
                  </BsRow>

                  <BsRow>
                    <BsCol md={5} lg={3} xl={3}>
                      <InputLabel
                        label="Cód. Externo"
                        disabled
                        value={_.get(props, 'values.extras.Reference1', '')}
                      />
                    </BsCol>

                    <BsCol md={5} lg={3} xl={3}>
                      <InputLabel
                        label="Nr. Ref. Fornecedor"
                        disabled
                        value={_.get(props, 'values.extras.NumAtCard', '')}
                      />
                    </BsCol>

                    <BsCol md={5} lg={3} xl={3}>
                      <InputLabel label="Moeda" value="BRL (Real)" />
                    </BsCol>

                    <BsCol md={15} lg={5} xl={5}>
                      <CurrencyField
                        name="value"
                        disabled={(hasErpData && _.get(props.values, 'value')) || previewMode || !hasOrderType || fromDda || editLocked}
                        label="Valor"
                        hasError={props.errors.value && props.touched.value}
                      />
                    </BsCol>

                    <BsCol md={18} lg={10} xl={10}>
                      <Select
                        name="notes"
                        disabled={previewMode || !hasOrderType || _.isEmpty(_.get(props, 'values.bankContract.bankAccount.bank')) || editLocked}
                        label="Aviso ao beneficiário"
                        hasError={_.get(props.errors, 'notes') && _.get(props.touched, 'notes')}
                        options={{
                          values: [{ value: '', label: '' }, ...getNotesFromBankCode(_.get(props, 'values.bankContract.bankAccount.bank.code'))]
                        }}
                      />
                    </BsCol>

                    <BsCol md={24} lg={24}>
                      <InputGroup
                        type="text"
                        name="emails"
                        label="E-mail(s) da ordem de pagamento"
                        disabled={loading || !canWriteSpec || editLocked}
                        maxLength={1000}
                      />
                    </BsCol>
                  </BsRow>
                </Fieldset>

                {cancellationReason && (
                  <Fieldset label="Motivo do cancelamento">
                    <BsRow>
                      <BsCol md={24} lg={24} xl={24}>
                        <InputGroup type="text" name="cancellationReason" disabled={true} value={cancellationReason} />
                      </BsCol>
                    </BsRow>
                  </Fieldset>
                )}

                {!_.isEmpty(genMessages) && (
                  <Fieldset label="Mensagens de Erros / Alertas">
                    <DetailTableContainer>
                      <DataTable
                        noPagination={true}
                        emptyText="Nenhuma mensagem encontrada"
                        hideUpdateButton={true}
                        data={{ rows: genMessages }}
                        onRowClicked={_.noop}
                        padding="0"
                        extraOptions={{
                          noTableHead: true
                        }}
                        columns={[
                          {
                            name: 'Tipo',
                            selector: 'type',
                            width: '22px',
                            cell: row => {
                              let isErr = row.type === 'error';
                              return <CellIcon color={isErr ? '#ff0000' : '#efd600'}> {isErr ? <MdErrorOutline /> : <IoWarningOutline />}</CellIcon>;
                            }
                          },
                          {
                            name: 'Mensagem',
                            selector: 'message',
                            format: row => row.message
                          }
                        ]}
                      />
                    </DetailTableContainer>
                  </Fieldset>
                )}

                <SubForm
                  {...props}
                  fromDda={fromDda}
                  previewMode={previewMode || !hasOrderType}
                  onDataList={handleDataList}
                  getDataList={getDataList}
                  onListBanks={handleListBanks}
                  onListCities={handleListCities}
                  getPixQrCodeData={handlePixQrCodeData}
                />

                <Fieldset label="Processamento">
                  <BsRow>
                    <BsCol md={12} lg={12} xl={12}>
                      <Select
                        name="serviceType"
                        label="Tipo de serviço *"
                        disabled={previewMode || !hasOrderType || fromDda || editLocked}
                        hasError={_.get(props.errors, 'serviceType') && _.get(props.touched, 'serviceType')}
                        options={{
                          values: [{ value: '', label: 'Escolha uma opção' }, ...getList(_.get(props, 'values.paymentOrderType.code'), 'serviceType')]
                        }}
                      />
                    </BsCol>

                    <BsCol md={12} lg={12} xl={12}>
                      <Select
                        name="entryType"
                        label="Forma de lançamento *"
                        disabled={previewMode || !hasOrderType || fromDda || editLocked}
                        hasError={_.get(props.errors, 'entryType') && _.get(props.touched, 'entryType')}
                        options={{
                          values: [{ value: '', label: 'Escolha uma opção' }, ...getList(_.get(props, 'values.paymentOrderType.code'), 'entryType')]
                        }}
                      />
                    </BsCol>
                  </BsRow>

                  <BsRow>
                    <BsCol md={8} lg={8} xl={8}>
                      <InputLabel label="Data do pagamento" value={formats.dateTimeZone(_.get(props, 'values.payDate'), 'dd/MM/yyyy')} />
                    </BsCol>
                    <BsCol md={8} lg={8} xl={8}>
                      <InputLabel label="Valor do pagamento" value={formats.currency(_.get(props, 'values.payAmount') || 0)} />
                    </BsCol>
                    <BsCol md={8} lg={8} xl={8}>
                      <InputLabel label="Autenticação" value={_.get(props, 'values.authNumber')} />
                    </BsCol>
                  </BsRow>
                </Fieldset>

                <Fieldset label="Anexos">
                  <BsRow>
                    <BsCol md={24} lg={12}>
                      <AttachmentTableContainer>
                        <DataTable
                          emptyText="Nenhum anexo vinculado"
                          noPagination={true}
                          onRowClicked={_.noop}
                          data={{ rows: _.get(props, 'values.attachments') || [] }}
                          extraOptions={{ noTableHead: true, customStyles }}
                          columns={[
                            {
                              name: 'Arquivo',
                              selector: 'name',
                              format: row => _.get(row, 'name') || ''
                            },
                            {
                              name: 'Ação',
                              width: '80px',
                              center: true,
                              cell: (row, index) => (
                                <>
                                  <IconButton size={28} title="Baixar arquivo" noMargin={true} onClick={() => handleDownloadAttachment(row)}>
                                    <MdFileDownload />
                                  </IconButton>

                                  <IconButton
                                    size={28}
                                    title="Remover arquivo"
                                    noMargin={true}
                                    onClick={() => handleRemoveAttachment(row, props.setFieldValue, props.values, index)}
                                  >
                                    <MdDelete />
                                  </IconButton>
                                </>
                              )
                            }
                          ]}
                        />
                      </AttachmentTableContainer>
                    </BsCol>

                    <BsCol md={24} lg={12}>
                      <FileUpload
                        id="files"
                        name="files"
                        multiple={true}
                        noMargin={true}
                        height={164}
                        label="Arquivos selecionados"
                        disabled={!_.get(props, 'values.paymentOrderType') || editLocked}
                        accept=".xml,.gif,.jpg,.jpeg,.png,.pdf,.zip,.rar,.xls,.xlsx,.doc,.docx,.ppt,.pptx"
                      />
                    </BsCol>
                  </BsRow>
                </Fieldset>

                {renderMovements(props.values, handleOpenMovement)}

                {renderApprovalsHistory(props.values)}

                {renderBinds(props.values)}

                {(_.size(_.get(props.values, 'emailSends')) >= 1 &&
                  <Fieldset label="E-mails enviados">
                    <BsRow>
                      <BsCol md={24} lg={24}>
                        <DetailTableContainer>
                          <DataTable
                            emptyText="Nenhum e-mail enviado"
                            noPagination={true}
                            onRowClicked={_.noop}
                            data={{ rows: _.get(props.values, 'emailSends') || [] }}
                            extraOptions={{
                              selectableRowsHighlight: false,
                            }}
                            rowMinHeight='52px'
                            columns={[
                              {
                                name: ' ',
                                selector: 'status',
                                compact: true,
                                width: '10px',
                                cell: row => <CellStatus title="" color={statsColors[_.get(row, 'sendAt') != null ? "success" : "error"]} />
                              },
                              {
                                name: 'Enviado em',
                                selector: 'createdAt',
                                width: '140px',
                                format: row => formats.dateTimeZone(_.get(row, 'createdAt'), 'dd/MM/yyyy HH:mm')
                              },
                              {
                                name: 'Autor',
                                selector: 'user.name',
                                width: '150px',
                                format: row => _.get(row, 'batch.user.name') || 'Automático'
                              },
                              {
                                name: 'E-mails',
                                selector: 'emailSettings',
                                wrap: true,
                                compact: true,
                                format: row => {
                                  try {
                                    return _.filter(_.get(row, 'emailSettings') || [], (r) => r.results)[0].results[0].envelope.to.join(', ') || '';
                                  }
                                  catch (err) {
                                    return '';
                                  }
                                }
                              },
                              {
                                name: 'Template',
                                selector: 'emailSettings',
                                wrap: true,
                                compact: true,
                                format: (row) => {
                                  let templatesList = _.filter(_.get(row, 'emailSettings') || [], (r) => !!r.description),
                                    templates = [];
                                  for(let row of templatesList) {
                                    templates.push(`${row.description} (${scopeTypes[row.scope]})`)
                                  }
                                  return templates.join(', ')
                                }
                              },
                              {
                                name: 'Descrição',
                                selector: 'sendAt',
                                width: '180px',
                                format: row => _.get(row, 'sendAt') != null ? "Enviado com sucesso" : _.get(row, 'error')
                              },
                            ]}
                          />
                        </DetailTableContainer>
                      </BsCol>
                    </BsRow>
                    <BsRow style={{paddingLeft: '12px', height: '20px'}}>
                        <Subtitle position='unset'>
                          <SubtitleItem color={statsColors['success']}>Sucesso</SubtitleItem>
                          <SubtitleItem color={statsColors['error']}>Erro</SubtitleItem>
                        </Subtitle>
                    </BsRow>
                  </Fieldset>
                )}

                {!_.isEmpty(logsMessages) && (
                  <Fieldset label="Log de Ações">
                    <TinyDetailTableContainer>
                      <DataTable
                        noPagination={true}
                        emptyText="Nenhum log encontrado"
                        hideUpdateButton={true}
                        data={{ rows: logsMessages }}
                        onRowClicked={_.noop}
                        padding="0"
                        extraOptions={{
                          noTableHead: true,
                        }}
                        columns={[
                          {
                            name: 'Mensagem',
                            selector: 'message',
                            format: (row) => row.message,
                          },
                        ]}
                      />
                    </TinyDetailTableContainer>
                  </Fieldset>
                )}

              </div>

              <FormToolbar>
                <div className="buttons">
                  {!headLess && (
                    <IconButton title="Voltar" type="button" disabled={loading} onClick={() => history.go(-1)}>
                      <MdKeyboardBackspace />
                    </IconButton>
                  )}

                  {canBlock && !editLocked &&(
                    <IconButton type="button" title="Bloquear" disabled={!props.isValid || loading} onClick={() => handleOnBlock(props.values)}>
                      <MdLock />
                    </IconButton>
                  )}

                  {canUnBlock && !editLocked &&(
                    <IconButton type="button" title="Desbloquear" disabled={!props.isValid || loading} onClick={() => handleOnUnBlock(props.values)}>
                      <MdLockOpen />
                    </IconButton>
                  )}

                  {canRunInstructions && (
                    <IconButton type="button" title="Instruções" disabled={loading} onClick={toggleInstructionModal}>
                      <VscSymbolOperator />
                    </IconButton>
                  )}

                  {hasBalanceData && (
                    <IconButton type="button" title="Alterações de Valor" disabled={loading} onClick={toggleBalancesModal}>
                      <MdFactCheck />
                    </IconButton>
                  )}

                  {canViewExtra && hasErpData &&(
                    <IconButton type="button" title="Dados Externos" disabled={loading} onClick={toggleExtraModal}>
                      <VscOutput />
                    </IconButton>
                  )}

                  {canRequestErpData && !editLocked &&(
                    <IconButton type="button" title="Solicitar dados do ERP" disabled={loading} onClick={() => handleRequestErpData(props.values)}>
                      <MdQueuePlayNext />
                    </IconButton>
                  )}

                  {canCancel && !editLocked &&(
                    <IconButton type="button" title="Cancelar" disabled={!props.isValid || loading} onClick={() => handleOnCancel(props.values)}>
                      <MdCancel />
                    </IconButton>
                  )}

                  {canGeneratePdf && canPrint && !editLocked &&(
                    <IconButton type="button" title="Gerar comprovante de pagamento" disabled={loading} onClick={() => handleGeneratePdf(props.values)}>
                      <GrDocumentPdf />
                    </IconButton>
                  )}

                  {canGeneratePdf && canPrint && canSendEmail && !editLocked &&(
                    <EmailMenu values={props.values} />
                  )}

                  {canUnlink && !editLocked &&(
                    <IconButton type="button" title="Desvincular da cobrança eletrônica" disabled={loading} onClick={() => handleOnUnlink(props.values)}>
                      <BiUnlink />
                    </IconButton>
                  )}

                  {canEdit && !editLocked &&(
                    <IconButton type="button" title="Salvar" disabled={isInvalidForm(props)} onClick={() => handleOnSubmit(props)}>
                      <MdSave />
                    </IconButton>
                  )}

                  {!canEdit && canWriteSpec && !editLocked &&(
                    <IconButton type="button" title="Salvar anexos/e-mails" disabled={isInvalidForm(props)} onClick={() => handleOnSubmit(props, true)}>
                      <IoDocumentAttach />
                    </IconButton>
                  )}

                  {canReopen && !editLocked &&(
                    <IconButton type="button" title="Reabrir" disabled={!props.isValid || loading} onClick={() => handleOnReopen(props.values)}>
                      <MdAutorenew />
                    </IconButton>
                  )}

                </div>
              </FormToolbar>
              {isOpenExtraModal && (
                <ExtraModal
                  loading={state.modalLoading}
                  isOpen={isOpenExtraModal}
                  toggleModal={toggleExtraModal}
                  parent={_.get(props, 'values')}
                />
              )}
              {isOpenBalancesModal && (
                <BalancesModal
                  loading={state.modalLoading}
                  isOpen={isOpenBalancesModal}
                  toggleModal={toggleBalancesModal}
                  parent={_.get(props, 'values')}
                />
              )}
              {isOpenInstructionModal && (
                <InstructionModal
                  loading={state.modalLoading}
                  isOpen={isOpenInstructionModal}
                  toggleModal={toggleInstructionModal}
                  handleOnSubmit={processInstruction}
                  parent={props.values}
                  settings={bankSettings}
                />
              )}
              {isOpenCancellationReasonModal && (
                <CancellationReasonModal
                  loading={state.modalLoading}
                  isOpen={isOpenCancellationReasonModal}
                  toggleModal={toggleCancellationReasonModal}
                  handleOnSubmit={handleOnSubmitCancellationReason}
                />
              )}
            </BsContainer>

            <Formik
              initialValues={state.businessPartner}
              validationSchema={BusinessPartnerSchema}
              initialTouched={{ entryType: true, serviceType: true }}
              enableReinitialize={true}
              validateOnMount={false}
            >
              {args => {
                return (
                  <Modal
                    width="800px"
                    height="80%"
                    title="Novo beneficiário"
                    hideClose={true}
                    noBodyPadding={true}
                    open={state.isOpenBusinessPartnerModal}
                    loading={state.isLoadingBusinessPartnerModal}
                    hide={closeBusinessPartnerModal}
                    actions={[
                      {
                        label: 'Cancelar',
                        action: closeBusinessPartnerModal,
                        disabled: state.isLoadingBusinessPartnerModal
                      },
                      {
                        label: 'Salvar',
                        action: () => saveBusinessPartnerModal(args.values, props.values),
                        disabled: state.isLoadingBusinessPartnerModal || !args.isValid,
                        primary: true
                      }
                    ]}
                  >
                    <BusinessPartnerForm
                      {...args}
                      noTabs={true}
                      loading={state.isLoadingBusinessPartnerModal}
                      openBill={_.noop}
                      onSearchCnpj={handleSearchCnpj}
                      onListBusinessPartnerGroup={handleListBusinessPartnerGroup}
                    />
                  </Modal>
                );
              }}
            </Formik>
          </FormContainer>
        );
      }}
    </Formik>
  );
}

const Schema = (values, subSchema) => {

  const validadeOrderEmails = (strEmails) => {
    let result = true;

    const arrEmails = strEmails.replace(/\s/g, "").split(',');
    _.map(arrEmails, (mail) => {
      if(mail){
        const validMail = validateEmail(mail);
        if (!validMail)
          result = false
      }
    });
    return result
  }

  return Yup.object().shape({
    paymentOrderType: Yup.mixed().required('Informe o tipo de ordem'),
    company: Yup.mixed().required('Informe a empresa'),
    businessPartner: Yup.mixed().required('Informe o beneficiário'),
    dueDate: Yup.string()
      .typeError('Informe a data de vencimento')
      .required('Informe a data de vencimento'),
    documentNumber: Yup.string().required('Informe o número do documento'),
    scheduledDate: Yup.mixed().test('match', '', function(value) {
      const { path, parent, createError } = this;
      const val = value || _.get(parent, 'scheduledDate');
      const orderTypeCode = _.get(parent, 'paymentOrderType.code');

      if (!val) {
        return createError({ path, message: 'Informe a data de agendamento' });
      }
      if (String(orderTypeCode) !== '50' && val && formats.dateTimeZone(val, 'yyyy-MM-dd') < formats.dateTimeZone(new Date(), 'yyyy-MM-dd')) {
        return createError({ path, message: 'A data de agendamento não pode ser inferior a hoje' });
      }
      return true;
    }),
    value: Yup.mixed().test('match', '', function(value) {
      const { path, parent, createError } = this;
      const paymentOrderType = _.get(parent, 'paymentOrderType.code');

      if (!value || value < 0) {
        return createError({ path, message: 'Informe um valor válido' });
      }

      if (paymentOrderType === 11 && value > 4999.99) {
        return createError({ path, message: 'DOC não pode ser superior a R$ 4.999,99' });
      }
      return true;
    }),
    serviceType: Yup.string().required('Informe o tipo de serviço'),
    entryType: Yup.mixed().test('match', '', function(value) {
      const { path, parent, createError } = this;
      const entryType = _.get(parent, 'entryType');

      if (!entryType) {
        return createError({ path, message: 'Informe a forma de lançamento' });
      } else if (!validateByOrderType(values)) {
        return createError({ path, message: 'Forma de lançamento inválida' });
      }
      return true;
    }),
    emails: Yup.mixed().test('match', '', function (value) {
      const { path, createError } = this
      if (value) {
        const validMail = validadeOrderEmails(value);
        if(!validMail){
          return createError({
            path,
            message:
              'Campo "E-mail(s) da ordem de pagamento" possui e-mail inválido',
          })
        }
      }
      return true
    }),
    ...subSchema
  });
};

const Blocked = () =>
{
  return <span style={{color: 'red', fontWeight: 'bold'}}>Ordem bloqueada para pagamento <MdLock /></span>;
}

export default PaymentOrderDetail;
