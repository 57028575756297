import React, { useLayoutEffect } from 'react'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { MdKeyboardBackspace, MdClear, MdCheck } from 'react-icons/md'
import { Creators as HeaderActions } from '~/store/modules/header'
import { Creators as DashboardActions } from '~/store/modules/dashboard'
import { Container, FormHeader, FormToolbar } from '~/components/crud/styles'
import { IconButton } from '~/components/button'
import { addDays, subDays, format } from 'date-fns'
import { BsRow, BsCol } from '~/components/layout'
import FormFilter from '~/pages/dashboard/filter'
import { Formik } from 'formik'
import Spinner from '~/components/spinner'
import Loading from '~/components/loading'
import { formats } from '~/helper'
import { formatBankAccountGrid } from '~/pages/bank-accounts/form'
import {
  statusLabels as billStatusLabels,
  statusColors as billStatusColors,
} from '~/pages/bills'
import {
  statusMap as orderStatusLabels,
  statusColors as orderStatusColors,
} from '~/pages/payment-orders/constants'
import { ResponsivePie } from '@nivo/pie'
import { ResponsiveBar } from '@nivo/bar'
import { MdErrorOutline } from 'react-icons/md'
import { IoWarningOutline } from 'react-icons/io5'
import { CellIcon } from '~/pages/bills/styles'
import {
  InnerContainer,
  CardBox,
  EmptyContainer,
} from '~/pages/dashboard/styles'
import useSearchParameters from '~/hooks/use-search-params'

const dayRanges = [
  'até 7 dias',
  'de 8 a 30 dias',
  'de 31 a 60 dias',
  'de 61 a 90 dias',
  'mais de 90 dias',
]
const dayRangesBase = [
  { min: 0, max: 7 },
  { min: 8, max: 30 },
  { min: 31, max: 60 },
  { min: 61, max: 90 },
  { min: 91, max: null },
]

const getElem = ({ label, value }) => {
  return () => (
    <span>
      <strong>{label}</strong>&nbsp;{value}
      <i style={{ padding: '0 5px' }}></i>
    </span>
  )
}

const getDivider = (array, breakLine) => {
  if (_.size(array) > 0) {
    array.push(() => <span className="spacing"></span>)
  }
}

const getSubtitle = (filter) => {
  let elements = [],
    company = _.get(filter, 'company'),
    bankAccount = _.get(filter, 'bankAccount')

  if (!_.isEmpty(company)) {
    getDivider(elements)
    elements.push(
      getElem({
        label: 'Empresa',
        value: `${formats.cnpj_cpf(company.identity)} ${company.name}`,
      }),
    )
  }

  if (!_.isEmpty(bankAccount)) {
    getDivider(elements)
    elements.push(
      getElem({
        label: 'Conta bancária',
        value: formatBankAccountGrid(bankAccount),
      }),
    )
  }

  if (_.isEmpty(elements)) {
    return null
  }

  return _.map(elements, (Element, index) => <Element key={index} />)
}

const Empty = ({ message }) => {
  return (
    <EmptyContainer>
      <span>{message}</span>
    </EmptyContainer>
  )
}

const BarTooltip = (type) => ({ data, id, color }) => {
  return (
    <div
      style={{
        lineHeight: '20px',
        background: 'white',
        padding: '5px 10px',
        boxShadow:
          'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
      }}
    >
      <span
        style={{
          background: color,
          width: '10px',
          height: '10px',
          display: 'inline-block',
          marginRight: '5px',
        }}
      ></span>
      <span style={{ fontSize: '80%' }}> {id} </span>
      <br />
      <span style={{ fontSize: '90%' }}> {data.id} </span>
      <br />
      <span style={{ fontSize: '90%' }}>
        {formats.number(data[`${id}Count`])}{' '}
        {formats.plural(
          data[`${id}Count`],
          type === 'bill' ? 'Fatura' : 'Ordem de pagamento',
          type === 'bill' ? 'Faturas' : 'Ordens de pagamento',
        )}
      </span>
      <br />
      <strong style={{ fontSize: '90%' }}>
        Valor: {formats.currency(data[id])}
      </strong>
    </div>
  )
}

const renderBarChart = ({
  data,
  xAxis,
  yAxis,
  columns,
  getColors,
  type,
  openFilter,
}) => {
  return (
    <ResponsiveBar
      data={data}
      keys={columns}
      indexBy="id"
      margin={{ top: 40, right: 20, bottom: 20, left: 90 }}
      padding={0.15}
      innerPadding={2}
      groupMode="grouped"
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      valueFormat=" >-.2f"
      colors={getColors || { scheme: 'nivo' }}
      tooltip={BarTooltip(type)}
      onClick={({ data, id }) => {
        let isOverdue = id === 'Vencida',
          dateFn = isOverdue ? subDays : addDays,
          minVal = isOverdue ? data.max : data.min || 0,
          maxVal = isOverdue ? data.min || 0 : data.max,
          dueDateStart =
            minVal !== null && minVal >= 0
              ? format(dateFn(data.today, minVal), 'yyyy-MM-dd')
              : '',
          dueDateEnd =
            maxVal !== null && maxVal >= 0
              ? format(dateFn(data.today, maxVal), 'yyyy-MM-dd')
              : ''

        openFilter(type, {
          dueDateStart,
          dueDateEnd,
          status: type === 'bill' ? 'registered' : 'scheduled',
        })
      }}
      borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
      }}
      axisLeft={{
        tickSize: 10,
        tickPadding: 5,
        tickRotation: 0,
        legend: xAxis,
        legendPosition: 'middle',
        legendOffset: -80,
        format: (v) => `R$ ${v}`,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ theme: 'background' }}
      legends={[
        {
          dataFrom: 'keys',
          anchor: 'top',
          direction: 'row',
          justify: false,
          translateX: 0,
          translateY: -40,
          itemDirection: 'left-to-right',
          itemWidth: 70,
          itemHeight: 10,
          itemsSpacing: 5,
          symbolSize: 10,
          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      role="application"
      ariaLabel="Nivo bar chart demo"
      barAriaLabel={function (e) {
        return e.id + ': ' + e.formattedValue + ' in country: ' + e.indexValue
      }}
    />
  )
}

const PieTooltip = (type) => ({ datum }) => {
  return (
    <div
      style={{
        lineHeight: '20px',
        background: 'white',
        padding: '5px 10px',
        boxShadow:
          'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
      }}
    >
      <span
        style={{
          background: datum.color,
          width: '10px',
          height: '10px',
          display: 'inline-block',
          marginRight: '5px',
        }}
      ></span>
      <span style={{ fontSize: '90%' }}> {datum.label} </span>
      <br />
      <strong>
        {formats.number(datum.value)}{' '}
        {formats.plural(
          datum.value,
          type === 'bill' ? 'Fatura' : 'Ordem de pagamento',
          type === 'bill' ? 'Faturas' : 'Ordens de pagamento',
        )}
        <br />
        {formats.currency(_.get(datum, 'data.total') || 0)}
      </strong>
    </div>
  )
}

const renderPieChart = ({ data, getColors, type, openFilter }) => {
  return (
    <ResponsivePie
      data={data}
      margin={{ top: 10, right: 50, bottom: 10, left: 10 }}
      sortByValue={false}
      cornerRadius={1}
      activeOuterRadiusOffset={8}
      colors={getColors || { scheme: 'nivo' }}
      borderWidth={1}
      borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
      enableArcLinkLabels={false}
      enableArcLabels={false}
      animate={true}
      tooltip={PieTooltip(type)}
      onClick={({ data }) => openFilter(type, { status: data.key })}
      legends={[
        {
          anchor: 'right',
          direction: 'column',
          justify: false,
          translateX: 0,
          translateY: 0,
          itemWidth: 70,
          itemHeight: 10,
          itemsSpacing: 5,
          symbolSize: 10,
          itemDirection: 'left-to-right',
        },
      ]}
    />
  )
}

const renderFinancialData = (data) => {
  return (
    <table cellSpacing="0" cellPadding="0" className="financial">
      <tbody>
        <tr>
          <td>Saldo inicial (Extratos)</td>
          <td>{formats.currency(_.get(data, 'accountsBalance') || 0)}</td>
        </tr>
        <tr>
          <td>Faturas</td>
          <td>{formats.currency(_.get(data, 'bills') || 0)}</td>
        </tr>
        <tr>
          <td>Ordens de pagamento</td>
          <td>{formats.currency(_.get(data, 'paymentOrders') || 0)}</td>
        </tr>
        <tr>
          <td>DDA</td>
          <td>{formats.currency(_.get(data, 'digitalCharges') || 0)}</td>
        </tr>
        <tr>
          <td>Saldo final</td>
          <td>{formats.currency(_.get(data, 'accountsBalanceFinal') || 0)}</td>
        </tr>
      </tbody>
    </table>
  )
}

const renderBankFilesStats = (data) => {
  return (
    <table cellSpacing="0" cellPadding="0" className="bank-files">
      <thead>
        <tr>
          <td></td>
          <td width="30%">Remessas</td>
          <td width="30%">Retornos</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Faturas</td>
          <td>{formats.number(_.get(data, 'charge.shipping') || 0)}</td>
          <td>{formats.number(_.get(data, 'charge.response') || 0)}</td>
        </tr>
        <tr>
          <td>Ordens de pagamento</td>
          <td>{formats.number(_.get(data, 'payment.shipping') || 0)}</td>
          <td>{formats.number(_.get(data, 'payment.response') || 0)}</td>
        </tr>
        <tr>
          <td>DDA</td>
          <td>-</td>
          <td>{formats.number(_.get(data, 'dda.response') || 0)}</td>
        </tr>
        <tr>
          <td>Extratos</td>
          <td>-</td>
          <td>{formats.number(_.get(data, 'bankStatement.response') || 0)}</td>
        </tr>
      </tbody>
    </table>
  )
}

const renderMessagesStats = (
  { errors, warnings, messagesList },
  goToEntity,
) => {
  return (
    <>
      <table className="messages-stats" cellSpacing="0" cellPadding="0">
        <thead>
          <tr>
            <td></td>
            <td width="38%">Erros ({errors > 20 ? '20+' : formats.number(errors || 0)})</td>
            <td width="38%">Alertas ({warnings > 20 ? '20+' : formats.number(warnings || 0)})</td>
          </tr>
        </thead>
      </table>

      <table className="messages" cellSpacing="0" cellPadding="0">
        <thead>
          <tr>
            <td width="20px"></td>
            <td>Data</td>
            <td width="76%">Assunto</td>
          </tr>
        </thead>
        <tbody>
          {_.isEmpty(messagesList) && (
            <tr>
              <td colSpan={3} style={{ textAlign: 'center', color: '#999' }}>
                Nenhuma mensagem encontrada!
              </td>
            </tr>
          )}
          {_.map(messagesList, (r, index) => (
            <tr
              key={index}
              className="cursor-pointer"
              onClick={() => goToEntity(r.entity, r.id)}
            >
              <td>
                {r.type === 'error' && (
                  <CellIcon size={20} color="#ff0000">
                    <MdErrorOutline />
                  </CellIcon>
                )}
                {r.type === 'warning' && (
                  <CellIcon size={20} color="#efd600">
                    <IoWarningOutline />
                  </CellIcon>
                )}
              </td>
              <td>{formats.dateTimeZone(r.createdAt, 'dd/MM')}</td>
              <td>{r.message}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}

const renderBillsSection = ({
  valuesChart,
  statusChart,
  count,
  today,
  openFilter,
}) => {
  let billPie = [],
    billBar = [],
    columns = {
      'A vencer': billStatusColors['open'],
      Vencida: billStatusColors['canceled'],
    },
    getBarColors = (bar) => columns[bar.id],
    getPieColors = (piece) => _.get(piece, 'data.color')

  for (let key in billStatusLabels) {
    let label = billStatusLabels[key],
      color = billStatusColors[key],
      value = _.get(statusChart, `[${key}].count`) || 0,
      total = _.get(statusChart, `[${key}].total`) || 0
    if (value) {
      billPie.push({ label, value, color, id: label, key, total })
    }
  }

  for (let i = 0; i < dayRanges.length; i++) {
    let id = dayRanges[i],
      rangeData = dayRangesBase[i],
      open = _.get(valuesChart, `open[${i}].sum`) || 0,
      openCount = _.get(valuesChart, `open[${i}].count`) || 0,
      overdue = _.get(valuesChart, `overdue[${i}].sum`) || 0,
      overdueCount = _.get(valuesChart, `overdue[${i}].count`) || 0

    billBar.push({
      id,
      'A vencer': open,
      Vencida: overdue,
      'A vencerCount': openCount,
      VencidaCount: overdueCount,
      today,
      ...rangeData,
    })
  }

  return (
    <BsRow>
      <BsCol sm={24} md={24} lg={12} xl={14}>
        <fieldset className="chart">
          <legend>Faturas registradas</legend>
          {!_.get(statusChart, 'registered.count') && (
            <Empty message={'Nenhuma fatura registrada encontrada'} />
          )}
          {_.get(statusChart, 'registered.count') &&
            renderBarChart({
              data: billBar,
              xAxis: 'Valor',
              yAxis: 'Dias',
              columns: _.keys(columns),
              getColors: getBarColors,
              type: 'bill',
              openFilter,
            })}
        </fieldset>
      </BsCol>

      <BsCol sm={24} md={24} lg={12} xl={10}>
        <fieldset className="chart">
          <legend>Faturas por situação</legend>
          {!count && <Empty message={'Nenhuma fatura encontrada'} />}
          {!!count &&
            renderPieChart({
              data: billPie,
              getColors: getPieColors,
              openFilter,
              type: 'bill',
            })}
        </fieldset>
      </BsCol>
    </BsRow>
  )
}

const renderPaymentOrdersSection = ({
  valuesChart,
  statusChart,
  count,
  today,
  openFilter,
}) => {
  let paymentOrderPie = [],
    paymentOrderBar = [],
    columns = {
      'A vencer': orderStatusColors['open'],
      Vencida: orderStatusColors['canceled'],
    },
    getBarColors = (bar) => columns[bar.id],
    getPieColors = (piece) => _.get(piece, 'data.color')

  for (let key in orderStatusLabels) {
    let label = orderStatusLabels[key],
      color = orderStatusColors[key],
      value = _.get(statusChart, `[${key}].count`) || 0,
      total = _.get(statusChart, `[${key}].total`) || 0
    if (value) {
      paymentOrderPie.push({ label, value, color, id: label, key, total })
    }
  }
  for (let i = 0; i < dayRanges.length; i++) {
    let id = dayRanges[i],
      rangeData = dayRangesBase[i],
      open = _.get(valuesChart, `open[${i}].sum`) || 0,
      openCount = _.get(valuesChart, `open[${i}].count`) || 0,
      overdue = _.get(valuesChart, `overdue[${i}].sum`) || 0,
      overdueCount = _.get(valuesChart, `overdue[${i}].count`) || 0

    paymentOrderBar.push({
      id,
      'A vencer': open,
      Vencida: overdue,
      'A vencerCount': openCount,
      VencidaCount: overdueCount,
      today,
      ...rangeData,
    })
  }

  return (
    <BsRow>
      <BsCol sm={24} md={24} lg={12} xl={14}>
        <fieldset className="chart">
          <legend>Ordens de pagamento agendadas</legend>
          {!_.get(statusChart, 'scheduled.count') && (
            <Empty message={'Nenhuma ordem de pagamento agendada encontrada'} />
          )}
          {_.get(statusChart, 'scheduled.count') &&
            renderBarChart({
              data: paymentOrderBar,
              xAxis: 'Valor',
              yAxis: 'Dias',
              columns: _.keys(columns),
              getColors: getBarColors,
              openFilter,
              type: 'paymentOrder',
            })}
        </fieldset>
      </BsCol>

      <BsCol sm={24} md={24} lg={12} xl={10}>
        <fieldset className="chart">
          <legend>Ordens de pagamento por situação</legend>
          {!count && (
            <Empty message={'Nenhuma ordem de pagamento encontrada'} />
          )}
          {!!count &&
            renderPieChart({
              data: paymentOrderPie,
              getColors: getPieColors,
              openFilter,
              type: 'paymentOrder',
            })}
        </fieldset>
      </BsCol>
    </BsRow>
  )
}

const renderBankData = (data) => {

  const hasSurNames = _.size(_.filter(data, item => _.get(item, 'surName') != null)) > 0;
  const hasGroup = _.size(_.filter(data, item => _.get(item, 'group') != null)) > 0;

  const grouped = _.groupBy(_.sortBy(data, ['group']), 'group');
  const sorted = _.sortBy(data, ['bank.name', 'accountNumber', 'accountNumberDigit']);

  return (
    <table cellSpacing="0" cellPadding="0" className="bank-data">
      <thead>
        <tr>
          <td>Banco</td>
          <td>Razão social/Nome</td>
          {hasSurNames && <td>Apelido</td>}
          <td>Conta</td>
          <td style={{ textAlign: 'right' }}>Saldo</td>
          <td style={{ textAlign: 'center' }}>Última Atualização</td>
        </tr>
      </thead>
      
        {_.isEmpty(data) &&
        <tbody>
          <tr>
            <td colSpan={hasSurNames ? 6 : 5} style={{ textAlign: 'center', color: '#999' }}>
              Nenhuma conta bancária encontrada!
            </td>
          </tr>
        </tbody>
        }

        {!hasGroup &&
          <tbody>
            {_.map(sorted, (r, index) => (
              renderRow(r, index, hasSurNames)
            ))}
          </tbody>
        }

        {hasGroup && 
        _.map(grouped, (item, index) => (
          _.filter(sorted, item2 => _.get(item2, 'group') === (index === "null" ? null : index)).length > 0 &&
          <tbody key={index}>
            <tr key={index} style={{backgroundColor: '#D3D3D3'}}>
              <td colSpan={hasSurNames ? 6 : 5}><b>{index === "null" ? "Sem grupo" : index}</b></td>
            </tr>
            {_.map(_.filter(sorted, item2 => _.get(item2, 'group') === (index === "null" ? null : index)), (r, index2) => (
              renderRow(r, index2, hasSurNames)
            ))}
            <tr>
              <td colSpan={hasSurNames ? 4 : 3}></td>
              <td style={{ textAlign: 'right'}}>{formats.currency(_.sumBy(_.filter(sorted, item2 => _.get(item2, 'group') === (index === "null" ? null : index)), 'balanceAmount'))}</td>
              <td></td>
            </tr>
          </tbody>
        ))}

      <tbody>
        <tr>
          <td colSpan={hasSurNames ? 4 : 3}></td>
          <td style={{ textAlign: 'right'}}><b>Total Geral:</b> {formats.currency(_.sumBy(data, 'balanceAmount'))}</td>
          <td></td>
        </tr>
      </tbody>

    </table>
  );
};

const renderRow = (r, index, hasSurNames) => {
  return (
    <tr key={index}>
      <td>{_.get(r,'bank.name','')}</td>
      <td>{_.get(r,'company.name','')}</td>
      {hasSurNames && <td>{_.get(r,'surName','')}</td>}
      <td>{`${r.accountNumber}-${r.accountNumberDigit}`}</td>
      <td style={{ textAlign: 'right'}}>{formats.currency(r.balanceAmount)}</td>
      <td style={{ textAlign: 'center'}}>{formats.dateTimeZone(r.balanceDate, 'dd/MM/yyyy')}</td>
    </tr>
  );
}

function Dashboard({ history }) {
  const dispatch = useDispatch(),
    state = useSelector((state) => state.dashboard),
    loading = state.loading,
    headerState = useSelector((state) => state.header),
    showFilter = _.get(headerState, 'filter.visible'),
    filter = _.get(headerState, 'filter.data'),
    today = formats.getDate(_.get(state, 'data.today'))

  const {
    location,
    routeParams,
    getAllParams,
    toStringify,
  } = useSearchParameters()

  useLayoutEffect(() => {
    const config = {
      loading: state.loading,
      useFilter: true,
      subtitle: getSubtitle(filter || {}),
      filter: {
        ...headerState.filter,
        visible: false,
        scope: '/dashboard',
      },
    }
    dispatch(HeaderActions.configure(config))
    // eslint-disable-next-line
  }, [state])

  useLayoutEffect(() => {
    const filterParams = getAllParams()

    dispatch(
      HeaderActions.callFilter(
        { ...filterParams, offset: 0 },
        DashboardActions.load,
      ),
    )

    // eslint-disable-next-line
  }, [location, routeParams])

  function handleListCompanies(term, callback) {
    dispatch(DashboardActions.listCompanies(term, callback))
  }

  function handleListBankAccounts(term, callback) {
    dispatch(DashboardActions.listBankAccounts(term, callback))
  }

  function handleHideFilter() {
    dispatch(HeaderActions.hideFilter())
  }

  function handleFilter(data) {
    const historySearch = {
      ..._.omit(data, ['company', 'bankAccount', 'offset']),
    }
    handleHideFilter()
    history.push({
      pathname: `/dashboard`,
      search: `?${toStringify(historySearch)}`,
      state: data,
    })
  }

  function goToEntity(entity, id) {
    const filter = getAllParams()
    let historypath
    if (entity === 'bill') {
      historypath = `/bills/${id}` 
    } else if (entity === 'paymentOrder') {
      historypath = `/payment-orders/${id}` 
    }
    if (historypath) {
      history.push( {pathname: historypath,
      search: `?${toStringify({
        ..._.omit(filter, [
          'company', 'bankAccount', 'offset'
        ]),
      })}`,
      state: filter, sourcepath: 'dashboard' })
    }  
  }

  function openFilter(entity, filter) {
    const historySearch = { ..._.omit(filter, ['company', 'bankAccount']) }
    if (entity === 'bill') {
      history.push({
        pathname: `/bills`,
        search: `?${toStringify(historySearch)}`,
        state: filter,
      })
    } else if (entity === 'paymentOrder') {
      history.push({
        pathname: `/payment-orders`,
        search: `?${toStringify(historySearch)}`,
        state: filter,
      })
    }
  }

  return (
    <Container showForm={showFilter} rightWidth="30%">
      <div className="left">
        {loading && (
          <InnerContainer>
            <Loading size={36} label={'Carregando...'} />
          </InnerContainer>
        )}

        {!loading && (
          <InnerContainer>
            <BsRow>
              <BsCol sm={24} md={8} lg={8}>
                <CardBox>
                  <div className="title">Financeiro</div>
                  <div className="body">
                    {renderFinancialData(_.get(state, 'data.financial') || {})}
                  </div>
                </CardBox>
              </BsCol>

              <BsCol sm={24} md={8} lg={8}>
                <CardBox>
                  <div className="title">
                    Documentos processados nos últimos 7 dias
                  </div>
                  <div className="body">
                    {renderBankFilesStats(_.get(state, 'data.bankFiles') || {})}
                  </div>
                </CardBox>
              </BsCol>

              <BsCol sm={24} md={8} lg={8}>
                <CardBox>
                  <div className="title">Mensagens/Alertas</div>
                  <div className="body">
                    {renderMessagesStats(
                      _.get(state, 'data.messages') || {},
                      goToEntity,
                    )}
                  </div>
                </CardBox>
              </BsCol>
            </BsRow>

            <BsRow>
              <BsCol sm={24} md={24} lg={24}>
                <CardBox height={'350px'}>
                  <div className="title">Contas a Receber</div>
                  <div className="body-lg">
                    {renderBillsSection({
                      ...(_.get(state, 'data.receivable') || {}),
                      today,
                      openFilter,
                    })}
                  </div>
                </CardBox>
              </BsCol>
            </BsRow>

            <BsRow>
              <BsCol sm={24} md={24} lg={24}>
                <CardBox height={'350px'}>
                  <div className="title">Contas a Pagar</div>
                  <div className="body-lg">
                    {renderPaymentOrdersSection({
                      ...(_.get(state, 'data.payable') || {}),
                      today,
                      openFilter,
                    })}
                  </div>
                </CardBox>
              </BsCol>
            </BsRow>

            <BsRow>
              <BsCol sm={24} md={24} lg={24}>
                <CardBox height={'350px'}>
                  <div className="title">Saldo por Conta Bancária</div>
                  <div className="body-lg">
                    {renderBankData(
                      _.get(state, 'data.bankAccountFiles') || {},
                    )}
                  </div>
                </CardBox>
              </BsCol>
            </BsRow>
          </InnerContainer>
        )}
      </div>

      {showFilter && (
        <div className="right">
          <Formik
            enableReinitialize={true}
            validateOnMount={!loading}
            initialValues={_.get(headerState, 'filter.data')}
            onSubmit={handleFilter}
          >
            {(args) => {
              return (
                <>
                  <FormHeader>
                    Filtro
                    <Spinner visible={loading} />
                  </FormHeader>

                  <fieldset className="form-contents" disabled={loading}>
                    <FormFilter
                      {...args}
                      onListCompanies={handleListCompanies}
                      onListBankAccounts={handleListBankAccounts}
                    />
                  </fieldset>

                  <FormToolbar>
                    <IconButton
                      title="Voltar"
                      type={'button'}
                      disabled={loading}
                      onClick={handleHideFilter}
                    >
                      <MdKeyboardBackspace />
                    </IconButton>

                    <IconButton
                      title="Limpar Filtro"
                      type={'button'}
                      disabled={loading}
                      onClick={() => handleFilter({})}
                    >
                      <MdClear />
                    </IconButton>

                    <IconButton
                      title="Aplicar Filtro"
                      type={'button'}
                      disabled={loading}
                      onClick={() => handleFilter(args.values)}
                    >
                      <MdCheck />
                    </IconButton>
                  </FormToolbar>
                </>
              )
            }}
          </Formik>
        </div>
      )}
    </Container>
  )
}

export default Dashboard
