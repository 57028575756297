import { all, put, call, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import _ from 'lodash';
import api from '~/services/api';
import { getError } from  '~/helper';
import { Creators as AuthActions } from '~/store/modules/auth';
import { Creators as UserActions, Types as UserTypes } from '~/store/modules/user';

export function showError(error) {
  const msg =  getError(error);
  toast.error(msg);
};

export function* createOrUpdate({ data, actions }) {
  try {
    let editing = !!((data && data.id)),
      method = editing ? 'put' : 'post',
      path = editing ? 'update' : 'create';

    yield call(api[method], `users/${path}`, data);

    toast.success(`Usuário ${editing ? 'atualizado' : 'criado'} com sucesso`);
    actions.closeForm && actions.closeForm();
    actions.reloadList && actions.reloadList();
  } catch (error) {
    showError(error);
  }
};

export function* list({ params }) {
  try {
    const response = yield call(api.post, 'users/list', params);
    yield put(UserActions.listSuccess(response.data));
  } catch (error) {
    showError(error);
  }
};

export function* load({ data }) {
  try {
    if (_.isEmpty(data)) {
      yield put(UserActions.loadSuccess({ active: true }));
      return
    }
    const response = yield call(api.get, 'users/load', { params: { id: data.id } });
    yield put(UserActions.loadSuccess(response.data));
  } catch (error) {
    showError(error);
  }
};

export function* updateAvatar({ data, actions }) {
  try {
    const formData = new FormData();
    formData.append('file', data);
    const response = yield call(api.post, 'users/update-avatar', formData);
    const avatar = _.get(response, 'data.avatar');
    yield put(AuthActions.updateAvatarSuccess(avatar));
    toast.success('Foto atualizada com sucesso!');
    actions.closeModal();
  } catch (error) {
    showError(error);
  } finally {
    actions.setSubmitting(false);
  }
};

export function* updatePassword({ data, actions }) {
  try {
    const { name, ...rest } = data;
    const user = Object.assign({ name }, rest.oldPassword ? rest : {});
    yield call(api.put, 'users/update-password', user);
    toast.success('Senha alterada com sucesso');
    actions.closeModal();
  } catch (error) {
    showError(error);
  } finally {
    actions.setSubmitting(false);
  }
};

export function* resetPassword({ data, actions }) {
  try {
    yield call(api.put, 'users/reset-password', data);
    toast.success('Senha redefinida com sucesso');
    actions.setSubmitting(false);
  } catch (error) {
    showError(error);
  } finally {
    actions.setSubmitting(false);
  }
};

export function* listProfiles({ term, callback }) {
  try {
    const response = yield call(api.get, 'users/list-profiles', { params: { term } });
    yield call(callback, response.data);
  } catch (error) {
  }
};

export default all([
  takeLatest(UserTypes.LIST, list),
  takeLatest(UserTypes.LOAD, load),
  takeLatest(UserTypes.CREATE_OR_UPDATE, createOrUpdate),
  takeLatest(UserTypes.LIST_PROFILES, listProfiles),
  takeLatest(UserTypes.UPDATE_PASSWORD, updatePassword),
  takeLatest(UserTypes.UPDATE_AVATAR, updateAvatar),
  takeLatest(UserTypes.RESET_PASSWORD, resetPassword),
])
