import React, { useEffect, useReducer, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Creators as BankStatementClassificationsActions } from '~/store/modules/bank-statement-classifications'
import { Creators as HeaderActions } from '~/store/modules/header'
import _ from 'lodash'
import {
  DetailContainer,
  TableContainer,
  Table,
  CellIcon,
} from '~/pages/bank-statement-classifications/styles'
import { BsCol, BsContainer, BsRow } from '~/components/layout'
import { InputLabel } from '~/components/form'
import { formats } from '~/helper'
import { statusColors, statusLabels } from '.'
import Fieldset from '~/components/fieldset'
import CellStatus from '~/components/datatable/cell-status'
import DataTable from '~/components/datatable'
import { Subtitle, SubtitleItem } from '~/components/datatable/subtitle'
import { FormToolbar } from '../bills/styles'
import { IconButton } from '~/components/button'
import {
  MdChecklist,
  MdClearAll,
  MdKeyboardBackspace,
  MdOutlineCancel,
  MdOutlinePublishedWithChanges,
  MdPlayArrow,
  MdOutlineArchive,
} from 'react-icons/md'
import Select from 'react-select'
import { primary, red, secondary } from '~/components/mixins/color'
import confirm from '~/components/confirm'
import SelectPaymentOrderModal from './modal-select-payment-order'
import ShowPaymentOrderModal from './modal-show-payment-order'
import { FaInfoCircle, FaLink } from 'react-icons/fa'

const statusColorsLine = {
  pending: '#40d1ff',
  success: '#77dd76',
  error: '#BA1F33',
}

const statuLabelsLine = {
  pending: 'Não processado',
  error: 'Erro no processamento',
  success: 'Sucesso',
}

const columns = (
  optionsClassification = [],
  handleOnBankClassification,
  hanldeShowPaymentOrderModal,
  canEdit,
) => [
  {
    name: '   ',
    selector: 'statusClassification',
    width: '12px',
    cell: (row) => {
      let status = row.statusClassification
          ? row.statusClassification
          : 'pending',
        title = statuLabelsLine[status],
        error = _.get(row, 'errorClassification') || ''

      if (!_.isEmpty(error)) {
        title = `${title}:\n${error}`
      }

      return (
        <CellStatus title={title} color={statusColorsLine[status]} width={5} />
      )
    },
  },
  {
    name: 'Ações',
    selector: 'linked',
    width: '30px',
    center: true,
    cell: (row) => {
      const po = _.get(row, 'paymentOrder')

      let status = row.statusClassification
          ? row.statusClassification
          : 'pending',
        title = statuLabelsLine[status],
        error = _.get(row, 'errorClassification') || ''

      if (!_.isEmpty(error)) {
        title = `${title}:\n${error}`
      }

      return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          {!_.isEmpty(error) && (
            <CellIcon hasClick={false}>
              <FaInfoCircle size={14} color={red.string()} title={title} />
            </CellIcon>
          )}
          {po && (
            <CellIcon hasClick={true}>
              <FaLink size={14} onClick={hanldeShowPaymentOrderModal(po)} />
            </CellIcon>
          )}
        </div>
      )
    },
  },
  {
    name: 'Data',
    selector: 'dueDate',
    width: '120px',
    center: true,
    hide: 'lg',
    format: (row) =>
      formats.dateTimeZone(_.get(row, 'dueDate'), 'dd/MM/yyyy') || '--',
  },
  {
    name: 'Referência',
    selector: 'reference',
    width: '150px',
    hide: 'lg',
    format: (row) => _.get(row, 'reference'),
  },
  {
    name: 'Débito',
    selector: 'debit',
    width: '150px',
    format: (row) => formats.currency(_.get(row, 'debit') || 0),
  },
  {
    name: 'Crédito',
    selector: 'credit',
    width: '150px',
    format: (row) => formats.currency(_.get(row, 'credit') || 0),
  },
  {
    name: 'Descrição',
    selector: 'memo',
    format: (row) => _.get(row, 'memo'),
  },
  {
    name: 'Rank (%)',
    selector: 'rank',
    width: '80px',
    right: true,
    format: (row) => formats.decimal((_.get(row, 'rank') || 0) * 100),
  },
  {
    name: 'Classificação',
    selector: 'classification',
    width: '500px',
    cell: (row, index) => {
      const { credit, debit } = row,
        isDebit = credit < debit,
        isCredit = credit > debit

      return (
        <div style={{ width: '500px' }}>
          <Select
            isDisabled={!canEdit}
            value={_.get(row, 'bankStatementClassification') || {}}
            isClearable={true}
            isSearchable={true}
            name="bankStatementClassification"
            options={_.filter(
              optionsClassification,
              (f) => f.debit === isDebit || f.credit === isCredit,
            )}
            menuPortalTarget={document.body}
            menuPosition="fixed"
            menuPlacement="auto"
            menuContainerStyle={{ top: 'auto', bottom: '100%' }}
            styles={{
              control: (base, { isFocused, isSelected, isDisabled }) => ({
                ...base,
                border: `1px solid ${secondary.hex()}`,
                height: '40px',
                color: 'white',
                borderRadius: '3px',
                '&:focus': {
                  borderColor: primary.hex(),
                },
                '&:hover': {
                  borderColor: primary.hex(),
                },
              }),
            }}
            onChange={(data) => handleOnBankClassification(row, data, index)}
          />
        </div>
      )
    },
  },
]

export default function BankStatementClassificationsDetail({
  history,
  match,
  acls,
  location,
  headLess,
}) {
  const dispatch = useDispatch()
  const headerState = useSelector((state) => state.header)
  const state = useSelector((state) => state.bankStatementClassifications)
  const lines = _.get(state, 'detail.lines') || []
  const summary = _.get(state, 'detail.summary') || []
  const canWrite = acls.includes('W')
  const canCancel = _.get(state, 'detail.canCancel') && canWrite
  const canClearClassification =
    _.get(state, 'detail.header.status') === 'open' && canWrite
  const canReclassifyBankStatement = canClearClassification
  const isDiscarded = _.get(state, 'detail.header.status') === 'discarded'
  const isRunning = _.get(state, 'detail.header.status') === 'running'
  const canResend =
    isRunning &&
    _.some(lines, (f) => f.statusClassification === 'error') &&
    canWrite
  const isNoClassification = _.some(
    lines,
    (f) => _.get(f, 'bankStatementClassification.code') === '000000',
  )
  const canProcess =
    !isNoClassification &&
    canClearClassification &&
    !_.get(state, 'detail.blocked') &&
    _.every(lines, (l) => l.bankStatementClassification)
  const canDiscard =
    !isDiscarded &&
    (canClearClassification ||
      (isRunning && _.every(lines, (f) => f.statusClassification === 'error')))
  const [
    isOpenSelectPaymentOrderModal,
    toogleSelectPaymentOrderModal,
  ] = useReducer((state) => {
    return !state
  }, false)
  const [isOpenShowPaymentOrderModal, toogleShowPaymentOrderModal] = useReducer(
    (state) => {
      return !state
    },
    false,
  )
  const handleSelectPaymentOrderCurrying = useRef(_.noop)
  const selectPaymentOrder = useRef({})

  useEffect(() => {
    const { id, movementId } = _.get(match, 'params') || {}
    dispatch(
      BankStatementClassificationsActions.listBankStatementClassification(),
    )
    dispatch(BankStatementClassificationsActions.load(id, movementId))
  }, [dispatch, match])

  useEffect(() => {
    const config = {
      subtitle: `Arquivo de Extrato`,
      filter: {
        ...headerState.filter,
        visible: false,
        scope: '/bank-statement-classifications',
      },
    }

    if (state && state.detail) {
      dispatch(HeaderActions.configure(config))
    }
    // eslint-disable-next-line
  }, [state, dispatch])

  const getCompanyData = (data) => {
    const name = _.get(data, 'header.movement.bankAccount.company.name'),
      identity = _.get(data, 'header.movement.bankAccount.company.identity')

    return `${formats.cnpj_cpf(identity)} ${name}`
  }

  const getDate = (data, field, mask) => {
    const date = _.get(data, `header.${field}`) || ''
    return formats.date(date, mask)
  }

  const getBank = (data) => {
    const bankCode = _.get(data, 'header.movement.bankAccount.bank.code'),
      bankName = _.get(data, 'header.movement.bankAccount.bank.name')
    return `${bankCode} - ${bankName}`
  }

  const getBankBranch = (data) => {
    const bankBranch = _.get(data, 'header.movement.bankAccount.bankBranch'),
      bankBranchDigit = _.get(
        data,
        'header.movement.bankAccount.bankBranchDigit',
      )
    return `${bankBranch}-${bankBranchDigit}`
  }

  const getBankAccount = (data) => {
    const accountNumber = _.get(
        data,
        'header.movement.bankAccount.accountNumber',
      ),
      accountNumberDigit = _.get(
        data,
        'header.movement.bankAccount.accountNumberDigit',
      )
    return `${accountNumber}-${accountNumberDigit}`
  }

  const handleClearClassification = async () => {
    const result = await confirm.show({
      title: 'Atenção',
      text: `Deseja realmente limpar todas classificações feitas do extrato?`,
    })

    if (result) {
      const { id, movementId } = _.get(match, 'params') || {}
      dispatch(
        BankStatementClassificationsActions.clearClassification(id, movementId),
      )
    }
  }

  const handleProcess = async () => {
    const result = await confirm.show({
      title: 'Atenção',
      text: `Deseja realmente efetuar o processamento/classificação do extrato?`,
    })

    if (result) {
      const { id, movementId } = _.get(match, 'params') || {}
      dispatch(
        BankStatementClassificationsActions.sendToFirebase(id, movementId),
      )
    }
  }

  const handleCancel = async () => {
    const result = await confirm.show({
      title: 'Atenção',
      text: `Deseja realmente cancelar todo processamento/classificação do extrato?`,
    })

    if (result) {
      const { id, movementId } = _.get(match, 'params') || {}
      dispatch(BankStatementClassificationsActions.cancel(id, movementId))
    }
  }

  const handleReclassifyBankStatement = async () => {
    const result = await confirm.show({
      title: 'Atenção',
      text: `Essa ação poderá reclassificar várias linhas do extrato, deseja continuar o processamento?`,
    })

    if (result) {
      const { id, movementId } = _.get(match, 'params') || {}
      dispatch(
        BankStatementClassificationsActions.reclassifyBankStatement(
          id,
          movementId,
        ),
      )
    }
  }

  const handleOnBankClassification = (row, classification, index) => {
    const { id, memo: description } = row,
      { id: positionId, movementId } = _.get(match, 'params') || {},
      bankStatementClassificationId = _.get(classification, 'value'),
      code = _.get(classification, 'code')

    handleSelectPaymentOrderCurrying.current = handleSelectPaymentOrder({
      id,
      description,
      bankStatementClassificationId,
      positionId,
      movementId,
      index,
    })

    if (code === '100004') {
      showSelectPaymentOrderModal(row)
    } else {
      handleSelectPaymentOrderCurrying.current()
    }
  }

  const showSelectPaymentOrderModal = (bankStatementLine) => {
    const startDate = getDate(state.detail, 'startDate'),
      endDate = getDate(state.detail, 'endDate'),
      bankAccountId = _.get(state, 'detail.header.movement.bankAccount.id'),
      paymentOrdersId = _.chain(lines)
        .filter((f) => !_.isEmpty(f.paymentOrder))
        .map('paymentOrder.id')
        .value()

    dispatch(
      BankStatementClassificationsActions.getPaymentOrders(
        { startDate, endDate, bankAccountId, paymentOrdersId },
        bankStatementLine,
        toogleSelectPaymentOrderModal,
      ),
    )
  }

  const handleSelectPaymentOrder = (data) => (paymentOrder) => {
    const {
      id,
      bankStatementClassificationId,
      description,
      positionId,
      movementId,
      index,
    } = data

    if (paymentOrder) {
      toogleSelectPaymentOrderModal()
    }

    dispatch(
      BankStatementClassificationsActions.updateBankClassification(
        {
          id,
          bankStatementClassificationId,
          description,
          positionId,
          movementId,
          paymentOrder,
        },
        index,
      ),
    )
  }

  const showPaymentOrderModal = (paymentOrder) => () => {
    selectPaymentOrder.current = paymentOrder || {}
    toogleShowPaymentOrderModal()
  }

  const handleDiscard = async () => {
    const result = await confirm.show({
      title: 'Atenção',
      text: `Deseja realmente descartar a classificação do extrato?`,
    })

    if (result) {
      const { id, movementId } = _.get(match, 'params') || {}
      dispatch(BankStatementClassificationsActions.discard(id, movementId))
    }
  }

  return (
    <DetailContainer headLess={headLess}>
      {/* <Spinner visible={state.formLoading} /> */}
      {/* {!state.formLoading && ( */}
      <BsContainer>
        <div className="main">
          <div className="data-summary">
            <Fieldset label="Informações gerais" className="left">
              <BsRow>
                <BsCol md={24} lg={24}>
                  <InputLabel
                    label="Empresa"
                    value={getCompanyData(state.detail)}
                    span={2}
                  />
                </BsCol>
              </BsRow>
              <BsRow>
                <BsCol md={8} lg={8}>
                  <InputLabel
                    label="Período de"
                    value={getDate(state.detail, 'startDate')}
                  />
                </BsCol>
                <BsCol md={8} lg={8}>
                  <InputLabel
                    label="Período até"
                    value={getDate(state.detail, 'endDate')}
                  />
                </BsCol>
                <BsCol md={8} lg={8}>
                  <InputLabel
                    label="Última atualização"
                    value={getDate(
                      state.detail,
                      'updatedAt',
                      'dd/MM/yyyy HH:mm:ss',
                    )}
                  />
                </BsCol>
              </BsRow>

              <BsRow>
                <BsCol md={12} lg={12}>
                  <InputLabel label="Banco" value={getBank(state.detail)} />
                </BsCol>
                <BsCol md={6} lg={6}>
                  <InputLabel
                    label="Agência"
                    value={getBankBranch(state.detail)}
                  />
                </BsCol>
                <BsCol md={6} lg={6}>
                  <InputLabel
                    label="Conta bancária"
                    value={getBankAccount(state.detail)}
                  />
                </BsCol>
              </BsRow>

              <BsRow>
                <BsCol md={16} lg={16}>
                  <InputLabel
                    label="Arquivo"
                    value={_.get(state.detail, 'header.movement.filename')}
                  />
                </BsCol>
                <BsCol md={8} lg={8}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      position: 'relative',
                    }}
                  >
                    <InputLabel
                      label="Status"
                      value={statusLabels[_.get(state.detail, 'header.status')]}
                    />
                    <div
                      style={{
                        width: '100%',
                        background: `${
                          statusColors[_.get(state.detail, 'header.status')]
                        }`,
                        height: '1px',
                        position: 'absolute',
                        bottom: '10px',
                      }}
                    ></div>
                  </div>
                </BsCol>
              </BsRow>
            </Fieldset>

            <Fieldset label="Sumário" className="right">
              <BsRow>
                <BsCol md={24} lg={12}>
                  <Table>
                    <thead>
                      <tr className="header">
                        <th colSpan={3}>Faturas</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="title">
                        <th></th>
                        <th align="right">Documentos</th>
                        <th align="right">Extrato</th>
                      </tr>
                      <tr>
                        <td className="left">Títulos</td>
                        <td align="right">
                          {formats.currency(
                            _.get(summary, 'docs.bills.bonds') || 0,
                          )}
                        </td>
                        <td align="right">
                          {formats.currency(
                            _.get(summary, 'statements.bills.bonds') || 0,
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="left">Transferências</td>
                        <td align="right">
                          {formats.currency(
                            _.get(summary, 'docs.bills.transfers') || 0,
                          )}
                        </td>
                        <td align="right">
                          {formats.currency(
                            _.get(summary, 'statements.bills.transfers') || 0,
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="left">Créditos Recebidos</td>
                        <td align="right">
                          {formats.currency(
                            _.get(summary, 'docs.bills.receivedCredits') || 0,
                          )}
                        </td>
                        <td align="right">
                          {formats.currency(
                            _.get(
                              summary,
                              'statements.bills.receivedCredits',
                            ) || 0,
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </BsCol>

                <BsCol md={24} lg={12}>
                  <Table>
                    <thead>
                      <tr className="header">
                        <th colSpan={3}>Ordens de pagamento</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="title">
                        <th></th>
                        <th align="right">Documentos</th>
                        <th align="right">Extrato</th>
                      </tr>
                      <tr>
                        <td align="left">Títulos</td>
                        <td align="right">
                          {formats.currency(
                            _.get(summary, 'docs.payments.bonds') || 0,
                          )}
                        </td>
                        <td align="right">
                          {formats.currency(
                            _.get(summary, 'statements.payments.bonds') || 0,
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td align="left">Transferências</td>
                        <td align="right">
                          {formats.currency(
                            _.get(summary, 'docs.payments.transfers') || 0,
                          )}
                        </td>
                        <td align="right">
                          {formats.currency(
                            _.get(summary, 'statements.payments.transfers') ||
                              0,
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td align="left">Tributos/Concessionárias</td>
                        <td align="right">
                          {formats.currency(
                            _.get(summary, 'docs.payments.taxes') || 0,
                          )}
                        </td>
                        <td align="right">
                          {formats.currency(
                            _.get(summary, 'statements.payments.taxes') || 0,
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td align="left">Débito Automático</td>
                        <td align="right">
                          {formats.currency(
                            _.get(summary, 'docs.payments.automaticDebit') || 0,
                          )}
                        </td>
                        <td align="right">
                          {formats.currency(
                            _.get(
                              summary,
                              'statements.payments.automaticDebit',
                            ) || 0,
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </BsCol>
              </BsRow>
            </Fieldset>
          </div>
          <Fieldset label="Movimento" className="grid">
            <TableContainer>
              <DataTable
                noPagination={true}
                columns={columns(
                  _.get(state, 'optionsClassification'),
                  handleOnBankClassification,
                  showPaymentOrderModal,
                  canClearClassification,
                )}
                data={{ rows: lines }}
                emptyText="Nenhuma movimento encontrado"
                hideUpdateButton={true}
                extraOptions={{
                  ignoreRowClicked: true,
                  selectableRows: false,
                  selectableRowsHighlight: false,
                  customStyles: {
                    rows: {
                      denseStyle: {
                        minHeight: '42px',
                      },
                    },
                  },
                }}
              />

              <Subtitle bottom={5} left={10}>
                <span>Legenda:</span>
                {_.map(statuLabelsLine, (v, k) => (
                  <SubtitleItem
                    key={`sts-line-[${k}]`}
                    color={statusColorsLine[k]}
                  >
                    {v}
                  </SubtitleItem>
                ))}
              </Subtitle>
            </TableContainer>
          </Fieldset>
        </div>
        <FormToolbar>
          <div className="buttons">
            <IconButton
              type="button"
              title="Voltar"
              onClick={() => history.goBack()}
            >
              <MdKeyboardBackspace />
            </IconButton>
            {canResend && (
              <IconButton
                type="button"
                title="Reenviar"
                onClick={handleProcess}
              >
                <MdOutlinePublishedWithChanges />
              </IconButton>
            )}
            {canCancel && (
              <IconButton type="button" title="Cancelar" onClick={handleCancel}>
                <MdOutlineCancel />
              </IconButton>
            )}
            {canClearClassification && (
              <IconButton
                type="button"
                title="Limpar classificações"
                onClick={handleClearClassification}
              >
                <MdClearAll />
              </IconButton>
            )}
            {canReclassifyBankStatement && (
              <IconButton
                type="button"
                title="Reclassificar"
                onClick={handleReclassifyBankStatement}
              >
                <MdChecklist />
              </IconButton>
            )}
            {canDiscard && (
              <IconButton
                type="button"
                title="Descartar"
                onClick={handleDiscard}
              >
                <MdOutlineArchive />
              </IconButton>
            )}
            {canProcess && (
              <IconButton
                type="button"
                title="Processar"
                onClick={handleProcess}
              >
                <MdPlayArrow />
              </IconButton>
            )}
          </div>
        </FormToolbar>
        {isOpenSelectPaymentOrderModal && (
          <SelectPaymentOrderModal
            isOpen={isOpenSelectPaymentOrderModal}
            loading={state.loading}
            toggleModal={toogleSelectPaymentOrderModal}
            paymentOrdersData={state.paymentOrdersData}
            handleSelectPaymentOrder={handleSelectPaymentOrderCurrying.current}
            bankStatementLine={state.bankStatementLineSelected}
          />
        )}
        {isOpenShowPaymentOrderModal && (
          <ShowPaymentOrderModal
            isOpen={isOpenShowPaymentOrderModal}
            toggleModal={toogleShowPaymentOrderModal}
            paymentOrderData={selectPaymentOrder.current}
          />
        )}
      </BsContainer>
      {/* )} */}
    </DetailContainer>
  )
}
