import { createActions, createReducer } from 'reduxsauce';
import produce from 'immer';
import _ from 'lodash';

export const { Types, Creators } = createActions(
  {
    list: ['params'],
    listSuccess: ['data'],
    load: ['id'],
    checkBillIsInInstallments: ['values', 'email', 'showModalSendEmail'],
    loadSuccess: ['data', 'generalConfig'],
    settle: ['data'],
    remove: ['id'],
    listBankContracts: ['params', 'callback'],
    listCompanies: ['params', 'callback'],
    listBankAccountsGroups: ['params', 'callback'],
    listBusinessPartners: ['term', 'callback'],
    createOrUpdate: ['data', 'actions'],
    createOrUpdateSuccess: [],
    updateNotes: ['data'],
    updateNotesSuccess: ['data'],
    applyDefaultValues: ['values', 'data', 'bankContract'],
    sendEmail: ['data'],
    settleBatch: ['data', 'filter', 'actions'],
    sendEmailSuccess: ['data'],
    requestErpData: ['data'],
    sendWhatsApp: ['data'],
    cancel: ['data'],
    cancelBatch: ['data', 'filter'],
    cancelSuccess: [],
    reopen: ['data'],
    reopenSuccess: [],
    occurrence: ['data', 'shipping'],
    regenerate: ['data', 'callback'],
    regenerateSuccess: [],
    reprocessErp: ['data'],
    downloadFile: ['data'],
    changeViewTab: ['tab'],
    changeSelectBills: ['data'],
    processInstruction: ['data', 'action'],
    processInstructionSuccess: []
  },
  { prefix: 'bills' }
);

const INITIAL_STATE = {
  loading: false,
  formLoading: false,
  modalLoading: false,
  data: { rows: [], count: 0, offset: 0 },
  viewTab: 'general',
  model: {},
  generalConfig: {},
  selecteds: []
};

const list = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.loading = true;
    draft.data = { rows: [], count: 0, offset: 0 };
    draft.model = {};
    draft.selecteds = [];
  });

const listSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.loading = false;
    draft.data = action.data;
  });

const load = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.formLoading = true;
    draft.model = {};
  });

const loadSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    let model = _.get(action, 'data');
    draft.formLoading = false;
    draft.generalConfig = action.generalConfig;
    draft.viewTab = 'general';
    draft.model = model;
  });

const sendEmailSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.loading = false;
    draft.selecteds = [];
  });

const updateNotesSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.model.notes = action.data.notes;
  });

const applyDefaultValues = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    let copy = _.cloneDeep(action.values),
      erpParams = _.get(copy, 'erpParams') || {};

    _.set(copy, 'billData', {})

    for (let key in action.data) {
      let value = action.data[key];
      _.set(copy, `billData.${key}`, value);
    }
    if (erpParams.discountValue > 0 && /^(value|percentage)$/.test(erpParams.discountType)) {
      _.set(copy, 'billData.discountType', erpParams.discountType);
      _.set(copy, 'billData.discountValue', erpParams.discountValue);
      _.set(copy, 'billData.discountDays', 0);
    }
    draft.model = copy;
    draft.model.bankContract = action.bankContract;
  });

const changeSelectBills = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.selecteds = action.data;
  });

const changeViewTab = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.viewTab = action.tab;
  });

const createOrUpdate = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.formLoading = true;
  });

const createOrUpdateSuccess = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.formLoading = false;
  });

  const cancel = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.formLoading = true;
  });

const cancelSuccess = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.formLoading = false;
  });

  const reopen = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.formLoading = true;
  });

const reopenSuccess = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.formLoading = false;
  });

const processInstruction = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.modalLoading = true;
  });

const processInstructionSuccess = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.modalLoading = false;
  });

const regenerate = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.formLoading = true;
  });

const regenerateSuccess = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.formLoading = false;
  });

export default createReducer(INITIAL_STATE, {
  [Types.LIST]: list,
  [Types.LIST_SUCCESS]: listSuccess,
  [Types.LOAD]: load,
  [Types.LOAD_SUCCESS]: loadSuccess,
  [Types.UPDATE_NOTES_SUCCESS]: updateNotesSuccess,
  [Types.APPLY_DEFAULT_VALUES]: applyDefaultValues,
  [Types.CHANGE_SELECT_BILLS]: changeSelectBills,
  [Types.CHANGE_VIEW_TAB]: changeViewTab,
  [Types.SEND_EMAIL_SUCCESS]: sendEmailSuccess,
  [Types.CREATE_OR_UPDATE]: createOrUpdate,
  [Types.CREATE_OR_UPDATE_SUCCESS]: createOrUpdateSuccess,
  [Types.CANCEL]: cancel,
  [Types.CANCEL_SUCCESS]: cancelSuccess,
  [Types.REOPEN]: reopen,
  [Types.REOPEN_SUCCESS]: reopenSuccess,
  [Types.PROCESS_INSTRUCTION]: processInstruction,
  [Types.PROCESS_INSTRUCTION_SUCCESS]: processInstructionSuccess,
  [Types.REGENERATE]: regenerate,
  [Types.REGENERATE_SUCCESS]: regenerateSuccess
});
