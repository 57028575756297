import React from 'react';
import { Select, Autocomplete, InputGroup, InputLabel } from '~/components/form';
import FieldSet from '~/components/fieldset';
import { BsRow, BsCol } from '~/components/layout';
import _ from 'lodash';
import { accountTypes } from '~/pages/payment-orders/constants';
import * as Yup from 'yup';

export const id = 11;

export const onParentChange = ({ field, value, parent, setFieldValue }) => {

  if ((field === 'bankAccount' && value) ||  _.get(parent, 'bank.code')) {
    let bankCode = _.get(value, 'bank.code') || _.get(parent, 'bank.code'),
      selectedBankCode = _.get(parent, 'orderData.bank.code');

    if (bankCode === selectedBankCode) {
      setFieldValue('orderData.bank', null);
    }
  }
};

export const Render = ({ errors, touched, previewMode, setFieldValue, values, onDataList, onListBanks }) => {
  let allowedGoalDOC = _.get(values,'bankAccount.bank.settings.payment.goalsDOC');
  let banckAccountTypes = _.get(values,'bankAccount.bank.settings.payment.accountTypes');
  let allowedAccountTypes = !banckAccountTypes ? accountTypes : _.filter(accountTypes, r => banckAccountTypes.includes(_.get(r, 'value')))
  const editLocked = _.get(values, 'editLocked') || false;

  return (
    <FieldSet label="Transferência - DOC">
      <BsRow>
        <BsCol md={24} lg={24} xl={9}>
          <Select
            name="orderData.accountType"
            label="Tipo de conta *"
            disabled={previewMode || editLocked}
            hasError={_.get(errors, 'orderData.accountType') && _.get(touched, 'orderData.accountType')}
            options={{ values: [{ value: '', label: '' }, ...(allowedAccountTypes || [])] }}
            />
        </BsCol>

        <BsCol md={24} lg={18} xl={12}>
          <Autocomplete
            name="orderData.goalDOC"
            keyField="value"
            label="Finalidade do DOC *"
            disabled={previewMode || editLocked}
            value={_.get(values, 'orderData.goalDOC')}
            valueFormat={row => `${row.label || ''}`}
            loadData={onDataList('goalsDOC', 'value', 'label',allowedGoalDOC)}
            emptyText={'Selecione a finalidade do DOC *'}
            tipText={'Digite... '}
            loadingText={'Carregando...'}
            notFoundText={'Não encontrada'}
            />
        </BsCol>

        <BsCol md={24} lg={6} xl={3}>
          <InputLabel
            label={`Câmara Centralizadora`}
            value="700 - DOC (COMPE)" />
        </BsCol>
      </BsRow>

      <BsRow>
        <BsCol md={24} lg={24} xl={8}>
          <Autocomplete
            name="orderData.bank"
            minLength={0}
            keyField="code"
            disabled={previewMode || editLocked}
            label="Banco *"
            value={_.get(values, 'orderData.bank')}
            valueFormat={row => `${row.code} - ${row.name}`}
            loadData={(term, callback) => onListBanks({ term, bankCode: _.get(values, 'bankContract.bankAccount.bank.code') }, callback)}
            emptyText={'Pesquise um banco'}
            tipText={'Digite... '}
            loadingText={'Carregando...'}
            notFoundText={'Não encontrado'}
            onChange={value => setFieldValue('orderData.bank', value)}
            hasError={_.get(errors, 'orderData.bank') && _.get(touched, 'orderData.bank')}
            />
        </BsCol>

        <BsCol md={16} lg={8} xl={5}>
          <InputGroup
            type="text"
            name="orderData.bankBranch"
            label="Agência do crédito *"
            disabled={previewMode || editLocked}
            maxLength="10"
            hasError={_.get(errors, 'orderData.bankBranch') && _.get(touched, 'orderData.bankBranch')}
            />
        </BsCol>

        <BsCol md={8} lg={4} xl={3}>
          <InputGroup
            type="text"
            name="orderData.bankBranchDigit"
            label="Digito agência"
            disabled={previewMode || editLocked}
            maxLength="2"
            hasError={_.get(errors, 'orderData.bankBranchDigit') && _.get(touched, 'orderData.bankBranchDigit')}
            />
        </BsCol>

        <BsCol md={16} lg={8} xl={5}>
          <InputGroup
            type="text"
            name="orderData.accountNumber"
            label="Conta do crédito *"
            disabled={previewMode || editLocked}
            maxLength="20"
            hasError={_.get(errors, 'orderData.accountNumber') && _.get(touched, 'orderData.accountNumber')}
            />
        </BsCol>

        <BsCol md={8} lg={4} xl={3}>
          <InputGroup
            type="text"
            name="orderData.accountNumberDigit"
            label="Digito conta *"
            disabled={previewMode || editLocked}
            maxLength="2"
            hasError={_.get(errors, 'orderData.accountNumberDigit') && _.get(touched, 'orderData.accountNumberDigit')}
            />
        </BsCol>
      </BsRow>
    </FieldSet>
  )
}

export const Schema = (values) => ({
  orderData: Yup.object().typeError('Informe os dados da ordem').shape({
    accountType: Yup.string().required('Informe o tipo de conta'),
    goalDOC: Yup.string().required('Informe a finalidade do DOC'),
    bankBranch: Yup.string().required('Informe a agência'),
    accountNumber: Yup.string().required('Informe a conta de crédito'),
    accountNumberDigit: Yup.string().required('Informe o digito da conta'),
    bank: Yup.mixed().test('match', '', function(value) {
      const { path, parent, createError } = this;
      const selectedBankCode = _.get(value, 'code') || _.get(parent, 'bank.code'),
        bankCode = _.get(values, 'bankContract.bankAccount.bank.code');

      if (!selectedBankCode) {
        return createError({ path, message: 'Selecione o banco' });
      } else if (selectedBankCode === bankCode) {
        return createError({ path, message: 'O banco escolhido deve ser diferente do banco do contrato.' });
      }
      return true;
    })
  })
})
