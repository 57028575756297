import React, { useState } from 'react';
import _ from 'lodash';
import Modal from '~/components/modal';
import DataTable from '~/components/datatable';
import { TabContainer } from '~pages/bills/styles';
import { formats } from 'helper';

const ExtraModal = ({ isOpen, loading, toggleModal, parent }) => {
  const balances = _.get(parent, 'cfgData.balances') || [];
  const count = _.size(balances);
  const pageSize = 50;
  const [ offset, setOffset ] = useState(0);

  const onChangePage = (offset) => {
    setOffset(offset);
  };

  return (
    <Modal
      width="900px"
      height="400px"
      title={`Alterações de Valor da Fatura`}
      hideClose={true}
      open={isOpen}
      bindRefWithSubmit={true}
      noBodyPadding={true}
      hide={toggleModal}
      actions={
        [
          {
            label: 'Cancelar',
            disabled: loading,
            action: () => { toggleModal(); }
          },
        ]
      }
      >
      <TabContainer>
        <DataTable
          emptyText="Nenhuma alteração de valor"
          pageSize={pageSize}
          onChangePage={onChangePage}
          hideUpdateButton={true}
          noPagination={true}
          data={{ rows: balances, offset, count, limit: pageSize }}
          columns={[
            {
              name: 'Descrição',
              selector: 'description',
              format: (row) => _.get(row, 'description', '')
            },
            {
              name: 'Data',
              selector: 'balanceDate',
              width: '140px',
              format: (row) => formats.dateTimeZone(_.get(row, 'balanceDate'), 'dd/MM/yyyy')
            },
            {
              name: 'Vl.Crédito',
              selector: 'credit',
              width: '130px',
              right: true,
              format: (row) => formats.currency(_.get(row, 'credit'))
            },
            {
              name: 'Vl.Débito',
              selector: 'debit',
              width: '130px',
              right: true,
              format: (row) => formats.currency(_.get(row, 'debit'))
            },
            {
              name: 'Saldo',
              selector: 'balance',
              width: '130px',
              right: true,
              format: (row) => formats.currency(_.get(row, 'balance'))
            },
          ]}
        />
      </TabContainer>
        
    </Modal>
  )

}

export default ExtraModal;