import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MdOfflinePin, MdClear, MdCheck, MdFileDownload } from 'react-icons/md';
import _ from 'lodash';
import confirm from '~/components/confirm';
import FormFilter from '~/pages/edi-files/filter';
import { Creators as EdiFilesActions } from '~/store/modules/edi-file';
import { Creators as HeaderActions } from '~/store/modules/header';
import { Container } from '~/pages/edi-files/styles';
import { EdiFileForm, EdiFileSchema } from '~/pages/edi-files/form';
import Crud from '~/components/crud';
import { formats } from '~/helper';

const columns = [
    {
      name: 'CNPJ/CPF',
      selector: 'vatNumber',
      width: '150px',
      cell: (row) => _.get(row, 'vatNumber', '')
    },
    {
      name: 'Empresa',
      selector: 'company',
      cell: (row) => _.get(row, 'company', '')
    },
    {
      name: 'Nome do Arquivo',
      selector: 'filename',
      cell: (row) => _.get(row, 'filename', '')
    },
    {
      name: 'Banco',
      selector: 'bankCode',
      width: '50px',
      cell: (row) => _.get(row, 'bankCode', '')
    },
    {
      name: 'Nr.Conta',
      selector: 'account',
      width: '150px',
      cell: (row) => _.get(row, 'account', '')
    },
    {
      name: 'Layout',
      selector: 'layout',
      width: '150px',
      cell: (row) => _.get(row, 'layout', '')
    },
    {
      name: 'Importado em',
      selector: 'createdAt',
      width: '140px',
      hide: 'md',
      format: (row) => formats.dateTimeZone(_.get(row, 'createdAt'), 'dd/MM/yyyy HH:mm')
    },
    {
      name: 'Reprocessado em',
      selector: 'reprocessedAt',
      width: '140px',
      hide: 'md',
      format: (row) => formats.dateTimeZone(_.get(row, 'reprocessedAt'), 'dd/MM/yyyy HH:mm')
    },
  ];

function EdiFile({ match, acls }) {
  const dispatch = useDispatch();
  const state = useSelector(state => state.ediFile);
  const headerState = useSelector(state => state.header);
  const canWrite = acls.includes('W');
  const [openForm, setOpenForm] = useState(!!_.get(match, 'params.id'));
  const showFilter = headerState.filter.visible;
  const hasData = _.get(state, 'model.id');

  useEffect(() => {
    const config = {
      loading: state.loading,
      useFilter: true,
      filter: { ...headerState.filter, visible: false }
    }
    dispatch(HeaderActions.configure(config));
    // eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    dispatch(EdiFilesActions.list({ ...headerState.filter.data, offset: 0 }));
    // eslint-disable-next-line
  }, [dispatch]);

  function handlePageChange(offset, limit) {
    dispatch(EdiFilesActions.list({ ...headerState.filter.data, offset, limit }));
  };

  function handleLoad(data) {
    setOpenForm(true);
    dispatch(EdiFilesActions.load(data));
  };

  function handleHideFilter() {
    dispatch(HeaderActions.hideFilter());
    dispatch(EdiFilesActions.clearModel());
    setOpenForm(false);
  };

  function handleFilter(data) {
    handleHideFilter();
    dispatch(HeaderActions.callFilter({ ...data, offset: 0 }, EdiFilesActions.list));
  };

  async function handleDownloadFile(data, actions) {
    const result = await confirm.show({
        title: 'Atenção',
        text: `Deseja baixar o arquivo retorno [${data.filename}]?`
      });

    if (result) {
      dispatch(EdiFilesActions.downloadFile(data, actions));
    }
  };

  async function handleReprocessFile(data, actions) {
    const result = await confirm.show({
        title: 'Atenção',
        text: `Deseja reprocessar o arquivo retorno [${data.filename}]?`
      });

    if (result) {
      dispatch(EdiFilesActions.reprocessFile(data, actions));
    }
  };

  return (
    <Container>

      <Crud
        useOpenForm={true}
        openForm={openForm || showFilter}
        hideAdd={true}
        columns={columns}
        emptyText="Nenhum arquivo retorno encontrado"
        formTitle={data => showFilter ? `Filtro` : ((_.get(data, 'id')) ? `Arquivo (${_.get(data, 'filename')})` : `Novo arquivo`)}
        data={state.data}
        tableLoading={state.loading}
        formLoading={state.formLoading}
        onChangePage={handlePageChange}
        onCloseFilter={handleHideFilter}
        onRowClicked={handleLoad}
        rightWidth={`${showFilter ? 450 : 600}px`}
        formOptions={{
          initialValues: showFilter ? headerState.filter.data : (state.model || {}),
          validationSchema: showFilter ? undefined : EdiFileSchema,
          initialTouched: showFilter ? {} : { filters: {} }
        }}
        renderForm={args => showFilter ?
          <FormFilter
            {...args}
            />
          :
          <EdiFileForm
            {...args}
            previewMode={!canWrite || hasData}
            permissions={state.permissions}
            />
        }
        actions={showFilter ?
          [
            { label: 'Limpar Filtro', icon: MdClear, action: () => handleFilter({}) },
            { label: 'Aplicar Filtro', icon: MdCheck, action: data => handleFilter(data) }
          ]
          :
          [
            {
              label: 'Baixar Arquivo',
              icon: MdFileDownload,
              isDisabled: () => !hasData,
              action: handleDownloadFile
            },
            {
              label: 'Reprocessar',
              icon: MdOfflinePin,
              isSubmit: true,
              isDisabled: () => !hasData || _.get(state, 'model.reprocessedAt'),
              action: handleReprocessFile
            }
          ]
        }
        />
    </Container>
  );
}

export default EdiFile;
