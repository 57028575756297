import { takeLatest, select, put, all } from 'redux-saga/effects'
import {
  Creators as HeaderActions,
  Types as HeaderTypes,
} from '~/store/modules/header'
import _ from 'lodash'

export function* configure({ options }) {
  yield put(HeaderActions.success(options))
}

export async function getFilterData() {
  const headerState = await select((state) => state.header)
  return _.get(headerState, 'filter.data') || {}
}

export function* filter({ data, actionFilter }) {
  yield put(HeaderActions.applyFilter(data))
  if (actionFilter) {
    yield put(actionFilter(data))
  }
}

export function* resetFilter({ payload }) {
  const { filter } = yield select((state) => state.header),
    scope = _.get(filter, 'scope'),
    location = _.get(payload, 'location.pathname')

  try {
    if (String(location).indexOf(scope) !== 0) {
      yield put(HeaderActions.applyFilter({}))
    }
  } catch (err) {}
}

export default all([
  takeLatest('@@router/LOCATION_CHANGE', resetFilter),
  takeLatest(HeaderTypes.CONFIGURE, configure),
  takeLatest(HeaderTypes.CALL_FILTER, filter),
  takeLatest(HeaderTypes.FILTER_DATA, getFilterData),
])
