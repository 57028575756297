import React, { useState } from 'react';
import _ from 'lodash';
import { FormContainer } from '~/pages/webhook-payment-orders/styles';
import { InputLabel } from '~/components/form';
import { BsRow, BsCol } from '~/components/layout';
import DataTable from '~/components/datatable';
import Loading from '~/components/loading';
import { formats } from '~/helper';
import Fieldset from '~/components/fieldset';

export const eventsMap = {
    'data_confirmation': 'Ordem alterada de pré-cadastro/não aprovada para aberta',
    'register_request': 'Envio do agendamento',
    'register_confirmation': 'Confirmação do agendamento',
    'cancel_request': 'Envio de exclusão',
    'cancel_confirmation': 'Confirmação de exclusão',
    'rejected_confirmation': 'Recebido retorno de rejeição',
    'paid_confirmation': 'Liquidação',
    'approval_request': 'Enviada para aprovação',
    'approval_confirmation': 'Aprovação confirmada',
    'approval_reject': 'Aprovação rejeitada',
    'returned_confirmation': 'Devolução',
  };

const getRows = (array = [], paymentOrders = {}, offset, pageSize) => {
  let list = _.orderBy(array, ['documentNumber', 'ourNumber'], ['asc', 'asc']),
    pageList = list.slice(offset, offset + pageSize),
    rows = [];
  
  _.each(pageList, (row) => {
    rows.push({
      ...row,
      paymentOrder: (_.get(paymentOrders, row.id) || {}),
    });
  });
  return rows;
};

const columns = [
  {
    name: 'Documento',
    selector: 'paymentOrder',
    width: '110px',
    format: (row) => _.get(row, 'paymentOrder.documentNumber')
  },
  {
    name: 'Vencimento',
    selector: 'paymentOrder',
    width: '110px',
    center: true,
    format: (row) => formats.dateTimeZone(_.get(row, 'paymentOrder.dueDate'), 'dd/MM/yyyy')
  },
  {
    name: 'Agendamento',
    selector: 'paymentOrder',
    width: '110px',
    center: true,
    format: (row) => formats.dateTimeZone(_.get(row, 'paymentOrder.scheduledDate'), 'dd/MM/yyyy')
  },
  {
    name: 'Valor',
    selector: 'paymentOrder',
    width: '110px',
    right: true,
    format: (row) => formats.currency(_.get(row, `paymentOrder.value`))
  },
  {
    name: 'Evento',
    selector: 'event',
    format: (row) => eventsMap[row.event] || row.event
  },
];

export const WebhookPaymentOrderForm = ({ errors, touched, values, loading, loadingMessage, previewMode, openDocument }) => {
  const pageSize = 50;
  const events = _.get(values, 'data.events') || [];
  const count = _.size(events);
  const [ offset, setOffset ] = useState(0);
  
  const onChangePage = (page) => {
    setOffset(page);
  };

  if (loading) {
    return (
      <FormContainer>
        <Loading size={36} label={loadingMessage || 'Carregando...'} />
      </FormContainer>
    );
  }

  return (
    <FormContainer>
      <InputLabel
        label="URL"
        value={_.get(values, 'data.url')}
        />
      <BsRow>
        <BsCol sm={24} md={12}>
          <InputLabel
            label="Tentativas de envio"
            value={formats.number(_.get(values, 'data.attempts'))}
            />
        </BsCol>

        <BsCol sm={24} md={12}>
          <InputLabel
            label="Data da tentativa de envio"
            value={formats.dateTimeZone(_.get(values, 'data.sendDate'), 'dd/MM/yyyy')}
            />
        </BsCol>
      </BsRow>

      <Fieldset label="Eventos">
        <div className="table-container">
          <DataTable
            emptyText={'Nenhum evento encontrado.'}
            pageSize={pageSize}
            data={{ rows: getRows(events, _.get(values, 'paymentOrders'), offset, pageSize), offset, count, limit: pageSize }}
            onRowClicked={(r) => openDocument(_.get(r, `id`))}
            onChangePage={onChangePage}
            hideUpdateButton={true}
            columns={columns}
            />
        </div>
      </Fieldset>
    </FormContainer>
  );
};
