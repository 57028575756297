import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MdSave, MdClear, MdCheck, MdDelete } from 'react-icons/md';
import _ from 'lodash';
import { Creators as EmailTemplatesActions } from '~/store/modules/email-template';
import { Creators as HeaderActions } from '~/store/modules/header';
import { Container } from '~/pages/email-templates/styles';
import FormFilter from '~/pages/email-templates/filter';
import { EmailTemplatesForm, EmailTemplatesSchema, types } from '~/pages/email-templates/form';
import confirm from '~/components/confirm';
import Crud from '~/components/crud';
import { formats } from '~/helper';

const columns = [
  {
    name: 'Descrição',
    selector: 'description',
    compact: false,
    cell: row => _.get(row, 'description')
  },
  {
    name: 'Tipo',
    selector: 'type',
    width: '150px',
    cell: row => types[_.get(row, 'type')]
  },
  {
    name: 'Cadastrado em',
    selector: 'createdAt',
    width: '140px',
    hide: 'md',
    format: row => formats.dateTimeZone(_.get(row, 'createdAt'), 'dd/MM/yyyy HH:mm')
  },
  {
    name: 'Movimento',
    selector: 'movementType',
    width: '150px',
    format: row => /^(payment)$/.test(row.movementType) ? 'Pagamento' : 'Cobrança'
  }
];

function EmailTemplates({ match, acls }) {
  const dispatch = useDispatch();
  const state = useSelector(state => state.emailTemplate);
  const headerState = useSelector(state => state.header);
  const canWrite = acls.includes('W');
  const [openForm, setOpenForm] = useState(!!_.get(match, 'params.id'));
  const showFilter = headerState.filter.visible;

  useEffect(() => {
    const config = {
      loading: state.loading,
      useFilter: true,
      filter: { ...headerState.filter, visible: false }
    }
    dispatch(HeaderActions.configure(config))
    // eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    dispatch(EmailTemplatesActions.list({ ...headerState.filter.data, offset: 0 }))
    // eslint-disable-next-line
  }, [dispatch]);

  function handleOnSubmit(data, actions) {
    dispatch(EmailTemplatesActions.createOrUpdate(data, actions));
  };

  function handlePageChange(offset, limit) {
    dispatch(EmailTemplatesActions.list({ ...headerState.filter.data, offset, limit }))
  };

  function handleLoad(data) {
    setOpenForm(true);
    dispatch(EmailTemplatesActions.load(data));
  };

  function handleHideFilter() {
    dispatch(HeaderActions.hideFilter());
    dispatch(EmailTemplatesActions.clearModel());
    setOpenForm(false);
  };

  function handleFilter(data) {
    handleHideFilter()
    dispatch(HeaderActions.callFilter({ ...data, offset: 0 }, EmailTemplatesActions.list))
  };

  async function handleRemove(values, actions) {
    const result = await confirm.show({
      title: 'Atenção',
      text: `Deseja realmente remover este template? Essa ação não poderá ser revertida!`,
    });

    if (result) {
      dispatch(EmailTemplatesActions.remove(values, actions));
    }
  };

  return (
    <Container>
      <Crud
        useOpenForm={true}
        openForm={openForm || showFilter}
        hideAdd={!canWrite}
        columns={columns}
        emptyText="Nenhum template de e-mail encontrado"
        formTitle={(data) => (showFilter ? `Filtro` : _.get(data, 'id') ? `Template: ${_.get(data, 'description')}` : `Novo template`)}
        data={state.data}
        tableLoading={state.loading}
        formLoading={state.formLoading}
        onChangePage={handlePageChange}
        onCloseFilter={handleHideFilter}
        onRowClicked={handleLoad}
        rightWidth={`${showFilter ? '450px' : '70%'}`}
        formOptions={{
          initialValues: showFilter ? headerState.filter.data : state.model || {},
          validationSchema: showFilter ? undefined : EmailTemplatesSchema,
        }}
        renderForm={args => (showFilter ? <FormFilter {...args} /> : <EmailTemplatesForm {...args} previewMode={!canWrite} permissions={state.permissions} />)}
        actions={
          showFilter
            ? [
                { label: 'Limpar Filtro', icon: MdClear, action: () => handleFilter({}) },
                { label: 'Aplicar Filtro', icon: MdCheck, action: data => handleFilter(data) }
              ]
            : [
                {
                  label: 'Salvar',
                  icon: MdSave,
                  isSubmit: true,
                  isDisabled: () => !canWrite,
                  action: handleOnSubmit
                },
                ...(!_.get(state, 'model.id') ? [] : [
                  {
                    label: 'Remover',
                    icon: MdDelete,
                    isDisabled: () => !canWrite,
                    action: handleRemove,
                  }
                ])
              ]
        }
      />
    </Container>
  )
}

export default EmailTemplates
