import React from 'react';
import _ from 'lodash';
import DataTable from '~/components/datatable';
import { formats } from 'helper';
import { Creators as BankStatementActions } from '~/store/modules/bank-statement';
import { useDispatch } from 'react-redux';

const columns = [
    {
      name: 'Data',
      width: '110px',
      selector: 'dueDate',
      format: (row) => formats.dateTimeZone(_.get(row, 'dueDate'), 'dd/MM/yyyy')
    },
    {
      name: 'Débito (R$)',
      selector: 'debit',
      right: true,
      width: '120px',
      format: (row) => formats.decimal(_.get(row, 'debit'))
    },
    {
      name: 'Crédito (R$)',
      selector: 'credit',
      right: true,
      width: '120px',
      format: (row) => formats.decimal(_.get(row, 'credit'))
    },
    {
      name: 'Memo',
      selector: 'memo',
      hide: 'md'
    },
  ];

export const TabStatement = ({ statements, openDocument, bankStatementId, loading }) => {

  const dispatch = useDispatch();

  const onChangePage = (offset, pageSize) => {
    dispatch(BankStatementActions.loadStatement(bankStatementId, offset, pageSize));
  };

  return (
    <DataTable
      emptyText="Nenhum item encontrado"
      pageSize={statements.count}
      loading={loading}
      onChangePage={onChangePage}
      hideUpdateButton={true}
      data={statements}
      onRowClicked={openDocument}
      columns={columns}
      />
  )
};
