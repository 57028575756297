import React from 'react'
import * as Yup from 'yup'
import _ from 'lodash'
import { FormContainer } from '~/pages/business-partners/styles'
import Tabs from '~/components/tabs'
import { TabGeneral } from '~/pages/business-partners/tab-general'
import { TabBills } from '~/pages/business-partners/tab-bills'
import { TabContacts } from '~/pages/business-partners/tab-contacts'
import { TabPaymentOrders } from '~/pages/business-partners/tab-payment-orders';
import { TabRepresentatives } from '~/pages/business-partners/tab-representatives';
import { isValidCpf, isValidCnpj } from '~/validator';
import PasswordResetModal from '~/pages/business-partners/modal-password-reset';
import CreateUserModal from '~/pages/business-partners/modal-create-user';

const tabs = ({ values, hasAccountsReceivable, hasAccountsPayable }) => {
  let list = [],
    bills = _.get(values, 'bills'),
    paymentOrders = _.get(values, 'paymentOrders'),
    representatives = _.get(values, 'representatives', []);

  list.push({ id: 'general', label: 'Dados Gerais', component: TabGeneral })
  if (values.id) {
    list.push({
      id: 'contacts',
      label: `Contatos (${_.size(_.get(values, 'extras.contacts'))})`,
      component: TabContacts
    });
    if (hasAccountsReceivable && _.get(values, 'isCustomer') && !_.isEmpty(bills)) {
      list.push({
        id: 'bills',
        label: `Faturas (${_.size(bills)})`,
        component: TabBills
      });
    }
    if (hasAccountsPayable && _.get(values, 'isSupplier') && !_.isEmpty(paymentOrders)) {
      list.push({
        id: 'paymentOrders',
        label: `Ordens de Pagamento (${_.size(paymentOrders)})`,
        component: TabPaymentOrders
      });
    }
    if (hasAccountsReceivable && _.get(values, 'isCustomer')) {
      list.push({
        id: 'representatives',
        label: `Representantes (${_.size(representatives)})`,
        component: TabRepresentatives
      });
    }
  }
  return list;
}

export const BusinessPartnerForm = (formArgs) => {
  let previewMode = formArgs.loading || formArgs.previewMode

  if (formArgs.noTabs) {
    return (
      <FormContainer>
        <TabGeneral {...formArgs} previewMode={previewMode} />
      </FormContainer>
    )
  }

  return (
    <FormContainer>
      <PasswordResetModal {...formArgs} />
      <CreateUserModal {...formArgs} />
      <Tabs
        initialTab="general"
        tabs={tabs(formArgs)}
        height="calc(100% - 70px)"
        {...formArgs}
        previewMode={previewMode}
        disabled={previewMode}
      />
    </FormContainer>
  )
};

export const BusinessPartnerSchema = Yup.object().shape({
  name: Yup.string().min(4, 'Verifique se o nome está correto').required('Informe o nome'),
  identityType: Yup.string().required('Informe o Tipo de Identificador'),
  identity: Yup.mixed().test('match', '', function(value) {
    const { path, parent, createError } = this;
    const val = value || _.get(parent, 'identity'),
      type = _.get(parent, 'identityType');
    if (!val) {
      return createError({ path, message: 'Informe um CPF ou um CNPJ' });
    } else if (val && type === 'person' && !isValidCpf(val)) {
      return createError({ path, message: 'Informe um CPF válido' });
    } else if (val && type === 'company' && !isValidCnpj(val)) {
      return createError({ path, message: 'Informe um CNPJ válido' });
    }
    return true;
  }),
  email: Yup.string()
    .matches(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'Informe um E-mail válido')
    .nullable().email('Informe um E-mail válido'),
  address: Yup.object().shape({
    address: Yup.string().required('Informe a rua'),
    number: Yup.string().required('Informe o número'),
    block: Yup.string().required('Informe o bairro'),
    zip_code: Yup.string().required('Informe o CEP'),
    city: Yup.string().required('Informe a cidade'),
    state: Yup.string().required('Informe o Estado')
  })
});
