import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';
import { gray, lightGray, white, tertiary } from '~/components/mixins/color';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0px;
`;

const Group = styled.div`
  display: grid;
  position: relative;
  margin: ${props => props.noMargin ? '0' : '0 0 10px'};
  width: 100%;
  min-width: 0px;

  textarea {
    background: ${lightGray.hex()};
    border: 2px solid ${tertiary.hex()};
    border-radius: 2px;
    padding: ${props => props.padding || '22px 0 8px 10px'};
    height: ${props => props.height || '46px'};
    font-size: ${props => props.fontSize || '14px'};
    resize: none;
    color: ${gray.hex()};
    transition: border-color 0.2s ease-in 0s;
    min-width: 0px;

    & + label {
      font-size: 70%;
      transform: translate3d(0, -100%, 0);
      opacity: 1;
      top: 20px;
      color: ${white.hex()};
      position: absolute;
      top: 15px;
      padding-left: 10px;
      transition: all 200ms;
      color: ${gray.hex()};
      opacity: 0.75;
    }
  }

  input {
    background: ${lightGray.hex()};
    border: 2px solid ${lightGray.hex()};
    opacity: 0.55;
    border-radius: 2px;
    padding: ${props => props.padding || (!props.noLabel ? '19px 10px 6px 10px' : '12px 10px 12px 10px')};
    height: ${props => props.height || '46px'};
    font-size: ${props => props.fontSize || '14px'};
    color: ${gray.hex()};
    position: relative;
    transition: border-color 0.2s ease-in 0s;
    min-width: 0px;

    & + label {
      font-size: 70%;
      transform: translate3d(0, -100%, 0);
      opacity: 1;
      top: 20px;
      color: ${white.hex()};
      position: absolute;
      top: 15px;
      padding-left: 10px;
      transition: all 200ms;
      color: ${gray.hex()};
      opacity: 0.75;
    }
  }
  span {
    background: ${lightGray.hex()};
    border: 2px solid ${lightGray.hex()};
    border-radius: 2px;
    padding: ${props => props.padding || (!props.noLabel ? '19px 10px 6px 10px' : '12px 10px 12px 10px')};
    height: ${props => props.height || '46px'};
    font-size: ${props => props.fontSize || '14px'};
    color: ${gray.hex()};
    position: relative;
    transition: border-color 0.2s ease-in 0s;
    min-width: 0px;
    margin: 0 !important;

    & + label {
      font-size: 70%;
      transform: translate3d(0, -100%, 0);
      opacity: 1;
      top: 20px;
      color: ${white.hex()};
      position: absolute;
      top: 15px;
      padding-left: 10px;
      transition: all 200ms;
      color: ${gray.hex()};
      opacity: 0.75;
    }
  }
`;

const hasVal = (val) => {
  return !_.isUndefined(val) && !_.isNull(val) && val !== '';
};

const InputLabel = ({ label, value, height, fontSize, padding, rows, children, noMargin }) => (
  <Container>
    <Group noMargin={noMargin} height={height} fontSize={fontSize} padding={padding} noLabel={!label}>
      {children &&
        <span>{children}</span>
      }
      {!children && (rows ?
          <textarea
            placeholder={label}
            value={hasVal(value) ? value : ''}
            disabled={true}
            />
          :
          <input
            type="text"
            placeholder={label}
            value={hasVal(value) ? value : ''}
            disabled={true}
          />
        )}
      <label htmlFor={label}>{label}</label>
    </Group>
  </Container>
);

InputLabel.propTypes = {
  label: PropTypes.string.isRequired,
};

export default InputLabel;
