import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import styled from 'styled-components';
import { primary, white, link } from '~/components/mixins/color';
import { Button } from '~/components/button';
import { InputGroup } from '~/components/form';
import Logo from '~components/logo';
import { Form, Formik } from 'formik';
import { Creators as AuthActions } from '~/store/modules/auth';
import { RiMailLine, RiLockPasswordLine } from 'react-icons/ri';

const gradient = `linear-gradient(135deg, ${primary.hex()}, ${primary.hex()}, ${primary.fade(0.35).string()})`;

const Container = styled.div`
  display: flex;
  flex: 1;
  background: ${white.hex()};
  user-select: none;
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 36%;
  height: 100vh;
  padding: 80px;
  background: ${gradient};
  user-select: none;

  ${Logo} {
    left: 50px;
  }
  @media (max-width: 1280px) {
    padding: 50px;
  }
`;

const Right = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  .forget {
    position: absolute;
    top: 20px;
    right: 20px;
    font-weight: 500;
    font-size: 14px;
    color: ${link.hex()};
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    padding: 10px;
    font-size: 16px;
    color: ${link.hex()};
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;

  @media (max-width: 1280px) {
    width: 50%;
  }

  button {
    width: 200px;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  h3 {
    font-size: 28px;
    font-weight: 500;
    color: ${link.fade(0.2).string()};
    padding: 30px;
  }
`;

const renderLogin = ({ errors, touched, setUsername }) => {
  return (
    <>
      <InputGroup
        icon={() => <RiMailLine />}
        type="username"
        name="username"
        maxLength={100}
        label="E-mail / Login"
        autoFocus
        autoComplete="new-password"
        hasError={errors.username && touched.username}
        onChange={e => setUsername(e.target.value.toLowerCase())}
      />

      <InputGroup
        icon={() => <RiLockPasswordLine />}
        type="password"
        name="password"
        maxLength={50}
        label="Senha"
        autoComplete="new-password"
        hasError={errors.password && touched.password}
      />
    </>
  );
};

const renderForgetPassword = ({ errors, touched, setUsername }) => {
  return (
    <>
      <InputGroup
        icon={() => <RiMailLine />}
        type="username"
        name="username"
        maxLength={100}
        label="E-mail"
        autoFocus
        autoComplete="new-password"
        hasError={errors.username && touched.username}
        onChange={e => setUsername(e.target.value.toLowerCase())}
      />
    </>
  );
};

const Login = ({ history }) => {
  const dispatch = useDispatch();
  const authState = useSelector(state => state.auth);
  const [isLoginMode, changeLoginMode] = useState(true);

  const [username, setUsername] = useState('');

  useEffect(() => {
    if (!!_.get(authState, 'token')) {
      history.push('/home');
    }
  }, [authState, history]);

  const handleLoginSubmit = (data, actions) => {
    dispatch(AuthActions.login(data, actions));
  };

  const redefinePassword = (data, actions) => {
    dispatch(AuthActions.redefinePassword(data, { ...actions, closeForm: () => changeLoginMode(true) }));
  };

  return (
    <Container>
      <Left>
        <Logo height="300px" />
      </Left>

      <Right>
        <span className="forget" onClick={() => changeLoginMode(!isLoginMode)}>
          {isLoginMode ? 'Esqueci minha senha' : 'Acessar sistema'}
        </span>

        <div />

        <FormContainer>
          <h3>{isLoginMode ? 'Dados de acesso' : 'Solicitar nova senha'}</h3>
          <Formik
            initialValues={isLoginMode ? { username: username, password: '' } : { username: username }}
            enableReinitialize={true}
            validateOnMount={false}
            onSubmit={isLoginMode ? handleLoginSubmit : redefinePassword}
            initialTouched={{ username: true }}
          >
            {({ isValid, isSubmitting, handleReset, handleSubmit, ...formArgs }) => (
              <Form>
                {isLoginMode && renderLogin({ ...formArgs, setUsername, changeLoginMode })}
                {!isLoginMode && renderForgetPassword({ ...formArgs, setUsername, changeLoginMode })}
                <Button color="accent" type="submit" disabled={isSubmitting}>
                  {isSubmitting ? 'Aguarde...' : isLoginMode ? 'Entrar' : 'Enviar'}
                </Button>
              </Form>
            )}
          </Formik>
        </FormContainer>

        <p>Plataforma simplificada de gestão financeira</p>
      </Right>
    </Container>
  );
};

export default Login;
