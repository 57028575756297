import styled from 'styled-components'
import { gray, white, lightGray } from '~/components/mixins/color'

export const Container = styled.div`
  height: calc(100% - 15px);
  width: ${props => (props.sidebar ? '300' : '80')}px;
  min-width: ${props => (props.sidebar ? '300' : '80')}px;

  background: ${white.hex()};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  transition: all 100ms;

  z-index: 999;
  position: fixed;
  border-right: 1px solid ${props => (props.sidebar ? lightGray.hex() : 'none')};
`

export const Profile = styled.div`
  display: flex;
  align-self: flex-start;
  justify-content: flex-start;
  padding-left: 23px;
  height: 50px;
  width: 100%;

  img {
    cursor: pointer;
    justify-content: center;
    height: 38px;
    width: 38px;
    border-radius: 2px;
  }
  span {
    display: flex;
    flex: 1;
    align-self: center;
    padding-left: 14px;
    color: ${gray.hex()};
  }
  @media (min-width: 1025px) {
    display: none;
  }
`

export const Aside = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  .menu-bottom,
  .menu-bottom a {
    width: 100%;
  }
`
