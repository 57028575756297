import { createActions, createReducer } from 'reduxsauce';
import produce from 'immer';
import _ from 'lodash';

export const { Types, Creators } = createActions({
  load: ['id'],
  loadSuccess: ['data'],
  update: ['data', 'source'],
  create: ['data', 'source', 'callback'],
  createSuccess: ['data', 'callback'],
  updateWithPassword: ['data', 'source', 'callback'],
  updateWithPasswordSuccess: ['data'],
  list: ['params'],
  listSuccess: ['data'],
  listPayments: ['params', 'callback'],
}, { prefix: 'paymentConciliation' });

const INITIAL_STATE = {
  loading: false,
  formLoading: false,
  modalLoading: false,
  data: { rows: [], count: 0, offset: 0 },
  model: {}
};

const handleList = (state = INITIAL_STATE, action) => produce(state, draft => {
  draft.loading = true;
  draft.data = { rows: [], count: 0, offset: 0 };
  draft.model = {};
});

const handleListSuccess = (state = INITIAL_STATE, action) => produce(state, draft => {
  draft.loading = false;
  draft.data = _.get(action, 'data');
});

const handleLoad = (state = INITIAL_STATE, action) => produce(state, draft => {
  draft.model = {};
  draft.formLoading = true;
});

const handleLoadSuccess = (state = INITIAL_STATE, action) => produce(state, draft => {
  draft.formLoading = false;
  draft.model = _.get(action, 'data');
});

const handleCreate = (state = INITIAL_STATE) => produce(state, draft => {
  draft.formLoading = true;
});

const handleCreateSuccess = (state = INITIAL_STATE) => produce(state, draft => {
  draft.formLoading = false;
});

const handleUpdateWithPassword = (state = INITIAL_STATE) => produce(state, draft => {
  draft.modalLoading = true;
});

const handleUpdateWithPasswordSuccess = (state = INITIAL_STATE) => produce(state, draft => {
  draft.modalLoading = false;
});

export default createReducer(INITIAL_STATE, {
  [Types.LIST]: handleList,
  [Types.LOAD]: handleLoad,
  [Types.CREATE]: handleCreate,
  [Types.UPDATE_WITH_PASSWORD]: handleUpdateWithPassword,
  [Types.LIST_SUCCESS]: handleListSuccess,
  [Types.LOAD_SUCCESS]: handleLoadSuccess,
  [Types.CREATE_SUCCESS]: handleCreateSuccess,
  [Types.UPDATE_WITH_PASSWORD_SUCCESS]: handleUpdateWithPasswordSuccess,
});
