import { all, takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {Creators as SettingsActions, Types as SettingsTypes } from '~/store/modules/setting';
import api from '~/services/api';
import _ from 'lodash';
import { getError } from '~/helper';

export function* update({ data, actions }) {
  try {
    yield call(api.put, `settings/update`, data);
    yield put(SettingsActions.load());

    toast.success(`Configurações atualizadas com sucesso`);
  } catch (error) {
    showError(error);
  }
}

export function* load() {
  try {
    const response = yield call(api.get, 'settings/load');
    yield put(SettingsActions.loadSuccess(response.data));
  } catch (error) {
    showError(error);
  }
}

export function* sendTestMail({ data }) {
  try {
    yield call(api.post, 'settings/email-server-test', data);
  } catch (error) {
    showError(error);
  }
}

export function* checkMailIntegration({ data }) {
  try {
    const response = yield call(api.post, 'settings/check-mail-integration', data),
      result = response.data;

    if (result.isOk) {
      toast.success(`E-mail "${result.email}" está disponível e foi vínculado a sua conta`);
    } else {
      toast.error(`E-mail "${result.email}" não está disponível`);
    }
  } catch (error) {
    showError(error);
  }
};

export function* createApiKey({ data }) {
  try {
    yield call(api.post, 'settings/generate-api-key', data);
    toast.success(`Chave de API ${data.disable ? 'desabilitada' : 'gerada'} com sucesso!`);
    yield put(SettingsActions.load(data));
  } catch (error) {
    showError(error);
  }
};

export function* createTemporaryAccess({ data }) {
  try {
    yield call(api.post, 'settings/create-temporary-access', data);
    toast.success(`Acesso provisório ${data.disable ? 'desabilitado' : 'gerado'} com sucesso!`);
    yield put(SettingsActions.load(data));
  } catch (error) {
    showError(error);
  }
};

export function* webhookSendTest({ data }) {
  try {
    let response = yield call(api.post, 'settings/webhook-send-test', data);

    if (_.get(response, 'data.success')) {
      toast.success(`Webhook enviado!`);
    } else {
      toast.error(`Webhook não enviado!`);
    }
  } catch (error) {
    showError(error);
  }
};

export function showError(error) {
  const msg = getError(error);
  toast.error(msg);
}

export default all([
  takeLatest(SettingsTypes.LOAD, load),
  takeLatest(SettingsTypes.UPDATE, update),
  takeLatest(SettingsTypes.SEND_TEST_MAIL, sendTestMail),
  takeLatest(SettingsTypes.CREATE_API_KEY, createApiKey),
  takeLatest(SettingsTypes.CHECK_MAIL_INTEGRATION, checkMailIntegration),
  takeLatest(SettingsTypes.CREATE_TEMPORARY_ACCESS, createTemporaryAccess),
  takeLatest(SettingsTypes.WEBHOOK_SEND_TEST, webhookSendTest),
]);
