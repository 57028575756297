import styled from 'styled-components'
import { primary, tertiary, white, accent, gray } from '~/components/mixins/color'

export const Container = styled.div`
  height: 100%;
  max-height: ${props => props.maxHeight};
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 10px;
  position: relative;

  @media (max-width: 425px) {
    max-height: 100%;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 0 auto;
  width: 100%;
  height: calc(100%);
  padding: 10px;

  .labelIn {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-indent: 0px;
    color: #4A4C50;
    line-height: 20px;
    width: 100%;
    padding-bottom: 5px;
  }

  input[type='checkbox'] {
    width: 13px;
    height: 13px;
    padding: 0;
    margin: 0 5px 0 0;
    vertical-align: bottom;
    position: relative;
    overflow: hidden;
  }
`

export const TabContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  height: calc(100%);
  padding: 10px;
  padding-top: 0px;
  overflow-y: auto;
  overflow-x: hidden;

  .empty {
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 14px;
  }
`;

export const ContainerRepresentatives = styled.div`
  margin-top: 10px;
  width: 100%;
`

export const Item = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 46px;
  padding: 10px 0 10px 10px;
  background: ${props => props.index % 2 === 0 ? gray.fade(0.94).string() : white.hex()};
  border: 2px solid ${tertiary.hex()};
  border-radius: 3px;
  color: ${gray.hex()};

  &:hover {
    background-color: ${primary.fade(0.94).string()};
  }

  & > svg {
    color: ${gray.hex()};
    transition: color 0.2s ease 0s;

    &:hover {
      color: ${accent.hex()};
      cursor: pointer;
    }
  }

  & .column-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-right: 10px;

    .first {
      display: flex;
      flex-direction: column;
      width: 320px;
    }

    .supp-width {
      width: 82px;
    }

    .cust-width {
      width: 55px;
    }

    .phone-width {
      width: 105px;
    }

    .cell-width {
      width: 115px;
    }
  }

`;
