import styled from 'styled-components';
import { Form } from 'formik';

export const Container = styled.div`
  height: 100%;
  max-height: ${(props) => props.maxHeight};
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 10px;
  position: relative;

  @media (max-width: 425px) {
    max-height: 100%;
  }
`;

export const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  padding: 10px;

  .empty-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    width: 550px;
    border: 1px dashed rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    img {
      width: auto;
      height: 135px;
      max-width: 500px;
      max-height: 135px;
      min-height: 135px;
    }
  }

  .labelIn {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-indent: 0px;
    color: #4A4C50;
    line-height: 20px;
    width: 100%;
    padding-bottom: 5px;
  }

  input[type='checkbox'] {
    width: 13px;
    height: 13px;
    padding: 0;
    margin: 0 5px 0 0;
    vertical-align: bottom;
    position: relative;
    overflow: hidden;
  }
`;
