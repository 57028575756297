import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import confirm from '~/components/confirm';
import { Creators as SettingsActions } from '~/store/modules/setting';
import { Creators as HeaderActions } from '~/store/modules/header';
import { BsRow, BsCol, FlexRow, FlexCol } from '~/components/layout';
import useNotification from '~/hooks/use-notification';
import { MdSave, MdInfoOutline, MdQueuePlayNext } from 'react-icons/md';
import { FaRegCopy } from 'react-icons/fa';
import { ImUserCheck, ImUserMinus, ImUserPlus } from 'react-icons/im';
import { RiMailSendLine } from 'react-icons/ri';
import { TiDocumentText } from 'react-icons/ti';
import { CgPlayListAdd, CgPlayListRemove } from 'react-icons/cg';
import WebhookEventsModal from '~/pages/settings/modal-webhook-events';
import EmailInfoModal from '~/pages/settings/modal-email-info';
import { Container, Contents, Actions, InputButton, Inner } from '~/pages/settings/styles';
import { InputGroup, Select, Checkbox } from '~/components/form';
import Fieldset from '~/components/fieldset';
import { IconButton } from '~/components/button';
import { eventsMap as billEventsMap } from '~/pages/webhook-bills/form';
import { eventsMap as paymentOrderEventsMap } from '~/pages/webhook-payment-orders/form';
import { statusLabels } from '~/pages/bills';

const testMessages = {
  'email-test': {
    success: 'E-mail enviado com sucesso',
    error: 'E-mail não enviado, verifique as configurações'
  },
  'service-layer-test': {
    success: 'Conexão estabelecida com sucesso',
    error: 'Falha na conexão, verifique as configurações'
  }
};

const clonedStatusLabels = _.omit(statusLabels, 'pending', 'rejected');

const CopyIcon = ({ text }) => {
  return (
    <InputButton title="Clique para Copiar" className="addon" onClick={() => navigator.clipboard.writeText(text)}>
      <FaRegCopy />
    </InputButton>
  );
};

function Settings({ acls }) {
  const dispatch = useDispatch(),
    state = useSelector(state => state.setting),
    [isOpenWebhookEventsModal, setOpenWebhookEventsModal] = useState(false),
    [isOpenEmailInfoModal, setOpenEmailInfoModal] = useState(false),
    { user } = useSelector(state => state.auth),
    canWrite = acls.includes('W'),
    previewMode = !canWrite,
    canCreateTempAccess = acls.includes('T');

  useEffect(() => {
    const config = {
      loading: state.loading
    };
    dispatch(HeaderActions.configure(config));
  }, [state, dispatch]);

  useEffect(() => {
    dispatch(SettingsActions.load());
  }, [dispatch]);

  const handleUpdate = async ({ values, ...rest }) => {
    let errors = await rest.validateForm()
    if(_.isEmpty(errors)){
      dispatch(SettingsActions.update(values, rest));
    } else {
      let base = _.omit(errors, 'businessPartner');
      let complement = ''
      if (_.isEmpty(base) && !_.isEmpty(_.get(errors,'businessPartner'))) {
        base = errors.businessPartner;
        complement = ' em Parceiros de negócios'
      }
      toast.error(`${_.first(_.values(base))}${complement}`);
    }
  }

  function sendTestMail(values) {
    dispatch(SettingsActions.sendTestMail(values));
  }

  async function sendWebhookTest(values) {
    let url = _.get(values, 'slipDefaults.webhookUrl'),
      tokenLocation = _.get(values, 'slipDefaults.webhookTokenLocation'),
      tokenName = _.get(values, 'slipDefaults.webhookTokenName'),
      tokenValue = _.get(values, 'slipDefaults.webhookTokenValue'),
      webhookEvents = _.get(values, 'slipDefaults.webhookEvents') || {},
      eventTypes = _.keys(_.pickBy(webhookEvents, (value, key) => value)),
      params = { url, eventTypes, entity: 'bill', tokenLocation, tokenName, tokenValue };

    if (url && !_.isEmpty(eventTypes)) {
      let answer = await confirm.show({
        width: 600,
        title: 'Atenção',
        text: `Deseja realmente enviar um dado de teste para URL informada?`
      });
      if (answer) {
        dispatch(SettingsActions.webhookSendTest(params));
      }
    } else {
      await confirm.show({
        width: 600,
        title: 'Atenção',
        text: `Informe a URL e escolha ao menos um evento para enviar o teste!`,
        options: [{ label: 'Fechar', value: false }]
      });
    }
  }

  async function goToApiDocs(values) {
    window.open(values.apiDocsUrl, '_blank');
  }

  async function createApiKey(values, isNew, disable) {
    let action = disable ? 'desabilitar a' : isNew ? 'criar uma' : 'gerar uma nova',
      result = await confirm.show({
        width: 500,
        title: 'Atenção',
        text: `Deseja realmente ${action} chave de API ?`
      });

    if (result) {
      dispatch(SettingsActions.createApiKey({ disable }));
    }
  }

  async function createTemporaryAccess(values, isNew, disable) {
    let action = disable ? 'desabilitar o' : isNew ? 'habilitar o' : 'gerar um novo',
      result = await confirm.show({
        width: 500,
        title: 'Atenção',
        text: `Deseja realmente ${action} acesso provisório ?`
      });

    if (result) {
      dispatch(SettingsActions.createTemporaryAccess({ disable }));
    }
  }

  useNotification({
    account: `${_.get(user, 'account.id')}`,
    user: `${_.get(user, 'id')}`,
    entity: 'settings',
    onData: result => {
      let isOk = _.get(result, 'data.isOk'),
        message = testMessages[_.get(result, 'data.type')] || '',
        field = isOk ? 'success' : 'error';

      toast[field](message[field] || '');
    }
  });

  return (
    <Container>
      <Inner>
        <Formik
          enableReinitialize={true}
          validateOnMount={true}
          initialValues={state.model}
        >
          {({ values, touched, errors, setFieldValue, ...rest }) => (
            <>
              <Contents>
                <BsRow>
                  <BsCol sm={24} md={24} lg={12}>
                    <Fieldset label="E-mail - configurações">
                      <BsRow>
                        <BsCol sm={12} md={12} lg={6}>
                          <InputGroup
                            type="text"
                            name="smtp.host"
                            label="Servidor (SMTP)"
                            maxLength={200}
                            disabled={previewMode}
                            hasError={_.get(errors, 'smtp.host') && _.get(touched, 'smtp.host')}
                          />
                        </BsCol>

                        <BsCol sm={12} md={12} lg={6}>
                          <InputGroup
                            type="number"
                            min={0}
                            max={65536}
                            name="smtp.port"
                            label="Porta (SMTP)"
                            disabled={previewMode}
                            hasError={_.get(errors, 'smtp.port') && _.get(touched, 'smtp.port')}
                          />
                        </BsCol>

                        <BsCol sm={12} md={12} lg={6}>
                          <InputGroup
                            type="text"
                            name="smtp.user"
                            label="Usuário (SMTP)"
                            maxLength={100}
                            autoComplete="new-password"
                            disabled={previewMode}
                            hasError={_.get(errors, 'smtp.user') && _.get(touched, 'smtp.user')}
                          />
                        </BsCol>

                        <BsCol sm={12} md={12} lg={6}>
                          <InputGroup
                            type="password"
                            name="smtp.pass"
                            label="Senha (SMTP)"
                            maxLength={100}
                            autoComplete="new-password"
                            disabled={previewMode}
                            hasError={_.get(errors, 'smtp.pass') && _.get(touched, 'smtp.pass')}
                          />
                        </BsCol>
                      </BsRow>

                      <BsRow>
                        <BsCol sm={12} md={12} lg={12}>
                          <InputGroup
                            type="text"
                            name="smtp.senderName"
                            label="Nome do Remetente"
                            maxLength={100}
                            disabled={previewMode}
                            hasError={_.get(errors, 'smtp.senderName') && _.get(touched, 'smtp.senderName')}
                          />
                        </BsCol>

                        <BsCol sm={12} md={12} lg={12}>
                          <InputGroup
                            type="email"
                            name="smtp.senderEmail"
                            label="E-mail do Remetente"
                            maxLength={100}
                            disabled={previewMode}
                            hasError={_.get(errors, 'smtp.senderEmail') && _.get(touched, 'smtp.senderEmail')}
                          />
                        </BsCol>
                      </BsRow>
                    </Fieldset>
                    <Fieldset label="E-mail - teste de configuração">
                      <FlexRow direction="row" gap="0">
                        <FlexCol flex="1">
                          <InputGroup
                            type="text"
                            name="test.testMail"
                            label="Informe um e-mail"
                            maxLength={100}
                            autoComplete="new-password"
                            disabled={previewMode}
                          />
                        </FlexCol>

                        <FlexCol flex="0 0 96px">
                          <IconButton
                            type="button"
                            title="Enviar e-mail de teste"
                            noMargin={true}
                            disabled={previewMode || !_.get(values, 'test.testMail')}
                            onClick={() => sendTestMail(values)}
                            size={46}
                          >
                            <RiMailSendLine />
                          </IconButton>

                          <IconButton
                            type="button"
                            title="Problemas para enviar email"
                            color="primary"
                            disabled={previewMode}
                            onClick={() => setOpenEmailInfoModal(true)}
                            noMargin={true}
                            size={46}
                          >
                            <MdInfoOutline />
                          </IconButton>
                        </FlexCol>
                      </FlexRow>
                    </Fieldset>
                    <Fieldset label="E-mail - e-mail de cópia">
                      <BsRow>
                        <BsCol sm={24} md={24} lg={24}>
                          <InputGroup
                            type="text"
                            name="emailTemplate.cco"
                            label="Esse e-mail receberá uma cópia dos e-mails enviados para os clientes"
                            maxLength={100}
                            disabled={previewMode}
                          />
                        </BsCol>
                      </BsRow>
                    </Fieldset>
                    {_.get(values, 'apiKey') && (
                      <Fieldset label="DocPay - API">
                        <FlexRow gap="0" align="center">
                          <FlexCol flex="1">
                            <InputGroup
                              type="text"
                              name="apiKey"
                              readOnly
                              noMargin={true}
                              label="Chave da API"
                              icon={() => <CopyIcon text={_.get(values, 'apiKey')} />}
                            />
                          </FlexCol>

                          <FlexCol flex="0 0 140px">
                            <IconButton
                              type="button"
                              title="Ver documentação"
                              color="primary"
                              disabled={previewMode}
                              onClick={() => goToApiDocs(values)}
                              noMargin={true}
                              size={46}
                            >
                              <TiDocumentText />
                            </IconButton>

                            <IconButton
                              type="button"
                              disabled={previewMode}
                              onClick={() => createApiKey(values, false, false)}
                              title="Gerar nova chave"
                              noMargin={true}
                              color="success"
                              size={46}
                            >
                              <CgPlayListAdd />
                            </IconButton>

                            <IconButton
                              type="button"
                              color="error"
                              disabled={previewMode}
                              title="Desabilitar chave"
                              onClick={() => createApiKey(values, false, true)}
                              noMargin={true}
                              size={46}
                            >
                              <CgPlayListRemove />
                            </IconButton>
                          </FlexCol>
                        </FlexRow>
                      </Fieldset>
                    )}

                    <Fieldset label="Extrato">
                      <FlexRow gap="0" align="center">
                        <FlexCol flex="1">
                          <Select
                            name="bankPages.allowClassification"
                            label="Deseja integrar ao ERP os extratos bancários sem classificação"
                            value={_.get(values, 'bankPages.allowClassification', 'yes')}
                            disabled={previewMode}
                            options={{
                              defaults: { value: 'yes', label: 'Sim' },
                              values: [
                                { value: 'no', label: 'Não' }
                              ]
                            }}
                          />
                        </FlexCol>
                      </FlexRow>
                      <FlexRow gap="0" align="center">
                        <FlexCol flex="1">
                          <Select
                            name="bankPages.allowComposeValues"
                            label="Utilizar data final como filtro de documentos no sumário da Classificação de Extratos"
                            value={_.get(values, 'bankPages.allowComposeValues', 'no')}
                            disabled={previewMode}
                            options={{
                              defaults: { value: 'no', label: 'Não' },
                              values: [
                                { value: 'yes', label: 'Sim' }
                              ]
                            }}
                          />
                        </FlexCol>
                      </FlexRow>
                    </Fieldset>

                    <Fieldset label="Créditos Recebidos">
                      <FlexRow gap="0" align="center">
                        <FlexCol flex="1">
                          <Select
                            name="creditsReceiveds.sendToERP"
                            label="Deseja que os créditos recebidos sejam enviados diretamente ao ERP"
                            value={_.get(values, 'creditsReceiveds.sendToERP', 'no')}
                            disabled={previewMode}
                            options={{
                              defaults: { value: 'no', label: 'Não' },
                              values: [
                                { value: 'yes', label: 'Sim' }
                              ]
                            }}
                          />
                        </FlexCol>
                      </FlexRow>
                    </Fieldset>

                    <Fieldset label="Ordem de Pagamento">
                      <FlexRow gap="0" align="center">
                        <FlexCol flex="1">
                          <Select
                            name="orderDefaults.actionToReopen"
                            label="Ação ao reabrir ordem de pagamento"
                            value={_.get(values, 'orderDefaults.actionToReopen', '1')}
                            disabled={previewMode}
                            options={{
                              defaults: { value: '1', label: 'Retirar o contrato da ordem de pagamento' },
                              values: [
                                { value: '2', label: 'Bloquear a ordem de pagamento' },
                                { value: '3', label: 'Somente reabrir' },
                              ]
                            }}
                          />
                        </FlexCol>
                      </FlexRow>
                      <FlexRow gap="0" align="center">
                        <FlexCol flex="1">
                          <Select
                            name="orderDefaults.requireCancellationReason"
                            label="Solicitar motivo de cancelamento?"
                            value={_.get(values, 'orderDefaults.requireCancellationReason', 'no')}
                            disabled={previewMode}
                            options={{
                              defaults: { value: 'no', label: 'Não' },
                              values: [
                                { value: 'yes', label: 'Sim' },
                              ]
                            }}
                          />
                        </FlexCol>
                      </FlexRow>
                    </Fieldset>

                    <Fieldset label="Cobrança eletrônica">
                      <BsRow>
                        <Select
                          name="digitalCharges.matchRule"
                          label="Regra de vínculo automático, combinação de:"
                          value={_.get(values, 'digitalCharges.matchRule', '1')}
                          disabled={previewMode}
                          options={{
                            defaults: { value: '1', label: ' CNPJ, Data de Vencimento e Valor' },
                            values: [
                              { value: '2', label: 'CNPJ, Data de Vencimento, Valor e Número do Documento' },
                              { value: '3', label: 'Sem vínculo automático, somente manual' },
                              { value: '4', label: 'Regra em cascata' },
                            ]
                          }}
                        />
                      </BsRow>
                    </Fieldset>

                    <Fieldset label="Parceiros de negócios">
                      <BsRow>
                        <BsCol sm={24} md={24} lg={24}>
                          <Select
                            name="businessPartner.autoCreateUser"
                            label="Criar usuário na integração de clientes com o ERP?"
                            value={_.get(values, 'businessPartner.autoCreateUser', 'no')}
                            disabled={previewMode}
                            options={{
                              defaults: { value: 'no', label: 'Não' },
                              values: [
                                { value: 'yes', label: 'Sim' },
                              ]
                            }}
                            onChange={(evt) => {
                              setFieldValue('businessPartner.autoCreateUser', _.get(evt, 'target.value'));
                              setFieldValue('businessPartner.userCompany', '');
                            }}
                          />
                        </BsCol>
                      </BsRow>
                    </Fieldset>

                    {_.get(values, 'temporaryAccessUrl') && (
                      <Fieldset label="Acesso Provisório">
                        <FlexRow gap="10px" align="center">
                          <FlexCol flex="1">
                            <InputGroup
                              type="text"
                              name="temporaryAccessUrl"
                              readOnly
                              noMargin={true}
                              label="URL do acesso provisório"
                              icon={() => <CopyIcon text={_.get(values, 'temporaryAccessUrl')} />}
                            />
                          </FlexCol>

                          <FlexCol flex="0 0 92px">
                            <IconButton
                              type="button"
                              disabled={previewMode}
                              onClick={() => createTemporaryAccess(values, false, false)}
                              title="Gerar novo acesso provisório"
                              noMargin={true}
                              color="success"
                              size={46}
                            >
                              <ImUserCheck />
                            </IconButton>

                            <IconButton
                              type="button"
                              color="error"
                              disabled={previewMode}
                              title="Desabilitar acesso provisório"
                              onClick={() => createTemporaryAccess(values, false, true)}
                              noMargin={true}
                              size={46}
                            >
                              <ImUserMinus />
                            </IconButton>
                          </FlexCol>
                        </FlexRow>
                      </Fieldset>
                    )}
                  </BsCol>

                  <BsCol sm={24} md={24} lg={12}>
                    <Fieldset label="SAP Business One">
                      {_.get(values, 'hash') && (
                        <InputGroup
                          type="text"
                          name="hash"
                          readOnly
                          noMargin={true}
                          label="Hash para Configuração"
                          icon={() => <CopyIcon text={_.get(values, 'hash')} />}
                        />
                      )}
                    </Fieldset>

                    <Fieldset label="Status padrão para fatura do portal do cliente">
                      <BsRow>
                        {_.map(clonedStatusLabels, (label, id) => (
                          <BsCol key={id} sm={12} md={4} lg={4}>
                            <Checkbox halfHeight={true} name={`filterDefaults.${id}`} label={label} disabled={previewMode} />
                          </BsCol>
                        ))}
                      </BsRow>
                    </Fieldset>

                    <Fieldset label="Fatura - configurações">
                      <BsRow style={{ padding: '5px' }}>
                        <BsCol sm={24} md={24} lg={24}>
                          <Select
                            name="slipDefaults.holidayDueDateMode"
                            label="Data de vencimento de faturas em feriados"
                            value={_.get(values, 'slipDefaults.holidayDueDateMode') || ' '}
                            disabled={previewMode}
                            options={{
                              values: [
                                { value: 'keep', label: 'Manter a data' },
                                { value: 'anticipate', label: 'Antecipar para o dia útil anterior' },
                                { value: 'postpone', label: 'Postergar para o próximo dia útil' }
                              ]
                            }}
                          />
                        </BsCol>

                        <BsCol sm={24} md={24} lg={24}>
                          <Select
                            name="slipDefaults.encryptPdf"
                            label="Senha em PDF de faturas compartilhados"
                            value={_.get(values, 'slipDefaults.encryptPdf') || ' '}
                            disabled={previewMode}
                            options={{
                              values: [
                                { value: 'no', label: 'Não colocar senha' },
                                { value: 'yes', label: 'Colocar senha (3 primeiros digitos e 2 últimos dígitos do CNPJ/CPF do pagador)' }
                              ]
                            }}
                          />
                        </BsCol>

                        <BsCol sm={24} md={24} lg={24}>
                          <Select
                            name="slipDefaults.instructionsUpdatePdfMode"
                            label="Atualizar PDF da fatura no processamento de Instruções"
                            value={_.get(values, 'slipDefaults.instructionsUpdatePdfMode') || ' '}
                            disabled={previewMode}
                            options={{
                              values: [
                                { value: 'normal', label: 'Após confirmação da instrução pelo banco' },
                                { value: 'unsafe', label: 'Sem confirmação da instrução pelo banco' }
                              ]
                            }}
                          />
                        </BsCol>

                        <BsCol sm={24} md={24} lg={24}>
                          <Select
                            name="slipDefaults.groupBills"
                            label="Envio de faturas por e-mail"
                            value={_.get(values, 'slipDefaults.groupBills') || ' '}
                            disabled={previewMode}
                            options={{
                              values: [
                                { value: 'no', label: 'Enviar um e-mail para cada parcela da fatura' },
                                { value: 'yes', label: 'Enviar um e-mail para todas as parcelas da fatura' }
                              ]
                            }}
                          />
                        </BsCol>

                        <BsCol sm={24} md={24} lg={24}>
                          <Select
                            name="slipDefaults.allowProcessingDDaNoDocuments"
                            label="Permitir processamento de DDA sem vinculo de documentos"
                            value={_.get(values, 'slipDefaults.allowProcessingDDaNoDocuments') || ' '}
                            disabled={previewMode}
                            options={{
                              values: [
                                { value: 'no', label: 'Não' },
                                { value: 'yes', label: 'Sim' }
                              ]
                            }}
                          />
                        </BsCol>

                        <BsCol sm={24} md={24} lg={24}>
                          <Select
                            name="slipDefaults.allowOnlyInstructionsByERP"
                            label="Permitir instruções apenas via ERP"
                            value={_.get(values, 'slipDefaults.allowOnlyInstructionsByERP') || ' '}
                            disabled={previewMode}
                            options={{
                              values: [
                                { value: 'no', label: 'Não' },
                                { value: 'yes', label: 'Sim' }
                              ]
                            }}
                          />
                        </BsCol>

                        <BsCol sm={24} md={24} lg={24}>
                          <Select
                            name="slipDefaults.actionToReopen"
                            label="Ação ao reabrir fatura"
                            value={_.get(values, 'slipDefaults.actionToReopen', '1')}
                            disabled={previewMode}
                            options={{
                              defaults: { value: '1', label: 'Retirar o contrato da fatura' },
                              values: [
                                { value: '2', label: 'Somente reabrir' },
                              ]
                            }}
                          />
                        </BsCol>

                        <Fieldset label="Webhook - Eventos" noMargin={true}>
                          <FlexRow gap="0" align="center" style={{ marginBottom: '10px' }}>
                            <FlexCol flex="1">
                              <InputGroup
                                type="text"
                                name="slipDefaults.webhookUrl"
                                label="URL (Webhook)"
                                maxLength={300}
                                noMargin={true}
                                disabled={previewMode}
                                hasError={_.get(errors, 'slipDefaults.webhookUrl') && _.get(touched, 'slipDefaults.webhookUrl')}
                              />
                            </FlexCol>

                            <FlexCol flex="0 0 92px">
                              <IconButton
                                type="button"
                                title="Ver informações"
                                color="primary"
                                disabled={previewMode}
                                onClick={() => setOpenWebhookEventsModal(true)}
                                noMargin={true}
                                size={46}
                              >
                                <MdInfoOutline />
                              </IconButton>

                              <IconButton
                                type="button"
                                title="Testar envio"
                                color="success"
                                disabled={previewMode}
                                onClick={() => sendWebhookTest(values)}
                                noMargin={true}
                                size={46}
                              >
                                <MdQueuePlayNext />
                              </IconButton>
                            </FlexCol>
                          </FlexRow>

                          <BsRow>
                            <BsCol sm={12} md={12} lg={8}>
                              <Select
                                name="slipDefaults.webhookTokenLocation"
                                label="Modo de envio (ApiKey)"
                                value={_.get(values, 'slipDefaults.webhookTokenLocation') || ''}
                                disabled={previewMode}
                                options={{
                                  values: [
                                    { value: '', label: 'Não enviar' },
                                    { value: 'header', label: 'Header' },
                                    { value: 'body', label: 'Body' }
                                  ]
                                }}
                              />
                            </BsCol>

                            <BsCol sm={12} md={12} lg={8}>
                              <InputGroup
                                type="text"
                                name="slipDefaults.webhookTokenName"
                                label="Nome (ApiKey)"
                                maxLength={300}
                                noMargin={true}
                                disabled={previewMode || !_.get(values, 'slipDefaults.webhookTokenLocation')}
                                hasError={_.get(errors, 'slipDefaults.webhookTokenName') && _.get(touched, 'slipDefaults.webhookTokenName')}
                              />
                            </BsCol>

                            <BsCol sm={12} md={12} lg={8}>
                              <InputGroup
                                type="text"
                                name="slipDefaults.webhookTokenValue"
                                label="Valor (ApiKey)"
                                maxLength={300}
                                noMargin={true}
                                disabled={previewMode || !_.get(values, 'slipDefaults.webhookTokenLocation')}
                                hasError={_.get(errors, 'slipDefaults.webhookTokenValue') && _.get(touched, 'slipDefaults.webhookTokenValue')}
                              />
                            </BsCol>
                          </BsRow>

                          <Fieldset label="Eventos da fatura para enviar via Webhook" noMargin={true}>
                            <BsRow>
                              {_.map(billEventsMap, (label, id) => (
                                <BsCol key={id} sm={24} md={12} lg={12}>
                                  <Checkbox halfHeight={true} name={`slipDefaults.webhookEvents.${id}`} label={label} disabled={previewMode} />
                                </BsCol>
                              ))}
                            </BsRow>
                          </Fieldset>

                          <br />

                          <Fieldset label="Eventos da ordem de pagamento para enviar via Webhook" noMargin={true}>
                            <BsRow>
                              {_.map(paymentOrderEventsMap, (label, id) => (
                                <BsCol key={id} sm={24} md={12} lg={12}>
                                  <Checkbox halfHeight={true} name={`orderDefaults.webhookEvents.${id}`} label={label} disabled={previewMode} />
                                </BsCol>
                              ))}
                            </BsRow>
                          </Fieldset>
                        </Fieldset>
                      </BsRow>
                    </Fieldset>
                  </BsCol>
                </BsRow>
              </Contents>

              <Actions>
                {!previewMode && !_.get(values, 'apiKey') && (
                  <IconButton type="button" title="Habilitar uso da API do DocPay" onClick={() => createApiKey(values, true)}>
                    <TiDocumentText />
                  </IconButton>
                )}

                {!previewMode && canCreateTempAccess && !_.get(values, 'temporaryAccessUrl') && (
                  <IconButton type="button" title="Criar novo acesso provisório" onClick={() => createTemporaryAccess(values, true)}>
                    <ImUserPlus />
                  </IconButton>
                )}

                <IconButton type="button" title="Salvar" disabled={previewMode} onClick={() => handleUpdate({ values, ...rest })}>
                  <MdSave />
                </IconButton>
              </Actions>
            </>
          )}
        </Formik>
      </Inner>

      {isOpenWebhookEventsModal && (
        <WebhookEventsModal isOpen={isOpenWebhookEventsModal} toggleModal={() => setOpenWebhookEventsModal(!isOpenWebhookEventsModal)} />
      )}
      {isOpenEmailInfoModal && <EmailInfoModal isOpen={isOpenEmailInfoModal} toggleModal={() => setOpenEmailInfoModal(!isOpenEmailInfoModal)} />}
    </Container>
  );
}

export default Settings;
