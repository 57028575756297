import React from 'react';
import _ from 'lodash';
import { ErrorMessage, Field } from 'formik';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { parseISO, isValid } from 'date-fns';
import DateFnsUtils from '~/components/form/input-date/date-utils';
import { primary, white, red, gray, lightGray } from '~/components/mixins/color';
import ptBrLocale from 'date-fns/locale/pt-BR';
import { MuiPickersUtilsProvider, DatePicker, KeyboardDateTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Group = styled.div`
  display: grid;
  position: relative;
  margin: ${props => (props.noMargin ? '0' : '0 0 10px')};
  width: 100%;

  & > svg {
    position: absolute;
    right: 15px;
    align-self: center;
    color: #555;
  }

  &:focus-within > input {
    border-color: ${primary.hex()};
  }

  &:focus-within > svg {
    color: ${primary.hex()};
  }

  ${props =>
    props.required &&
    css`
      label {
        font-weight: 800;
      }
    `};

  .date-time-picker {
    background: ${lightGray.hex()};
    border: 2px solid ${props => (props.hasError ? red.hex() : lightGray.hex())};
    border-radius: 4px;
    padding: ${props => props.padding || '20px 0 8px 10px'};
    height: ${props => props.height || '46'}px;
    transition: border-color 0.2s ease-in 0s;
    &:focus + label,
    &:not([value='']) + label {
      font-size: 70%;
      transform: translate3d(0, -100%, 0);
      opacity: 1;
      top: ${props => props.labelTop || '20'}px;
    }
    & + label {
      position: absolute;
      top: ${props => props.labelTop || '14'}px;
      padding-left: 10px;
      transition: all 200ms;
      color: ${gray.hex()};
    }

    ${props =>
      props.disabled &&
      css`
        opacity: 0.55;
        user-select: none;
        pointer-events: none;
        color: #b7b7b7;
        & + label {
          color: #b7b7b7 !important;
        }
      `};
  }
`;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: primary.hex(),
      contrastText: white.hex()
    }
  },
  shadows: Array(25).fill('none'),
  overrides: {
    MuiInput: {
      input: {
        border: 'none',
        color: gray.hex(),
        margin: '0',
        padding: '0',
        height: '16px',
        fontSize: '14px',
        fontFamily: 'Ubuntu'
      }
    },
    MuiIconButton: {
      root: {
        padding: '10px',
        paddingLeft: '0'
      }
    },
    MuiSvgIcon: {
      root: {
        width: '20px',
        height: '20px'
      }
    }
  }
});

const getValue = value => {
  if (_.isDate(value) && !_.isString(value)) {
    return value;
  }

  if (value) {
    // desconsiderar timezone
    value = (value || '').split('T')[0]
  }

  return value && _.isString(value) ? parseISO(value) : null;
};

const isValidDate = (value, withTime, b, c) => {
  let val = getValue(value);
  return !value || (val && isValid(val));
};

const InputDate = ({
  name,
  label = '',
  labelTop,
  extraOptions = {},
  dateFormat,
  padding,
  noMargin,
  height,
  justPicker,
  monthView,
  yearView,
  required,
  disabled,
  hideErrorLabel,
  withTime,
  hasError,
  className,
  ...rest
}) => {
  let Picker = withTime ? KeyboardDateTimePicker : KeyboardDatePicker,
    format = withTime ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy',
    variant = undefined,
    views = undefined;

  if (justPicker) {
    Picker = DatePicker;
    variant = 'inline';
  }
  if (monthView) {
    format = 'MM/yyyy';
    views = ['year', 'month'];
  }
  if (yearView) {
    format = 'yyyy';
    views = ['year'];
  }
  return (
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider locale={ptBrLocale} utils={DateFnsUtils}>
        <Container>
          <Field type="text" id={name} name={name}>
            {({ field }) => (
              <Group
                disabled={disabled}
                noMargin={noMargin}
                height={height}
                padding={padding}
                required={required}
                labelTop={labelTop}
                hasError={hasError || !isValidDate(field.value, withTime)}
              >
                <Picker
                  value={getValue(field.value)}
                  helperText=""
                  variant={variant}
                  views={views}
                  format={format}
                  className="date-time-picker"
                  autoOk={!withTime}
                  ampm={false}
                  disabled={disabled}
                  minDate={rest.minDate}
                  maxDate={rest.maxDate}
                  placeholder={label}
                  {...extraOptions}
                  KeyboardButtonProps={{ tabIndex: '-1' }}
                  InputProps={{ disableUnderline: true }}
                  onChange={value => (rest.onChange ? rest.onChange(value) : field.onChange({ target: { value, name } }))}
                />
                {field.value && (
                  <label htmlFor={name}>
                    {label}
                    {required ? ' (*)' : ''}
                  </label>
                )}
              </Group>
            )}
          </Field>
          {!hideErrorLabel && <ErrorMessage name={name} className="error" component="div" />}
        </Container>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
};

InputDate.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  hasError: PropTypes.bool
};

InputDate.defaultProps = {
  hasError: false
};

export default InputDate;
