import React, { forwardRef } from 'react'
import { ErrorMessage, Field } from 'formik'
import PropTypes from 'prop-types'
import _ from 'lodash'
import styled, { css } from 'styled-components'
import { primary, lightGray, gray } from '~/components/mixins/color'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Group = styled.div`
  display: grid;
  position: relative;
  width: 100%;
  margin: ${(props) => (props.noMargin ? '0' : '0 0 10px')};

  & > svg,
  & > .addon {
    position: absolute;
    right: 15px;
    align-self: center;
    color: ${gray.hex()};
    transition: color 0.2s ease 0s;
  }

  &:focus-within > input {
    border-color: ${primary.hex()};
  }

  &:focus-within > svg,
  &:focus-within > .addon {
    color: ${primary.hex()};
  }

  input {
    background: ${lightGray.hex()};
    border: 2px solid ${lightGray.hex()};
    border-radius: 2px;
    padding: ${(props) =>
      !props.noLabel
        ? `19px ${props.hasIcon ? '40px' : '10px'} 8px 10px`
        : `12px ${props.hasIcon ? '40px' : '10px'} 12px 10px`};
    color: #555;
    height: 46px;
    width: 100%;
    position: relative;
    transition: border-color 0.2s ease-in 0s;

    &:focus + label,
    &:not([value='']) + label {
      font-size: 70%;
      transform: translate3d(0, -100%, 0);
      opacity: 1;
      top: 20px;
      color: ${gray.hex()};
    }
    &:not([value='']) + label {
      color: ${gray.hex()};
    }
    &:focus + label {
      color: ${gray.hex()};
    }
    &:focus::placeholder {
      color: ${gray.hex()};
    }
    & + label {
      position: absolute;
      top: 15px;
      padding-left: 10px;
      transition: all 200ms;
      opacity: 0.7;
    }
    ${(props) =>
      props.disabled &&
      css`
        opacity: 0.55;
        user-select: none;
        pointer-events: none;
        color: #b7b7b7;
        & + label {
          color: #b7b7b7 !important;
        }
      `};
  }
`

const hasVal = (val) => {
  return !_.isUndefined(val) && !_.isNull(val) && val !== ''
}

const InputGroup = forwardRef(
  (
    {
      type,
      name,
      label,
      noMargin,
      disabled,
      hasError,
      icon: Icon,
      hideErrorLabel,
      onChange,
      ...rest
    },
    ref,
  ) => (
    <Container>
      <Field id={name} name={name}>
        {({ field, form, ...props }) => (
          <Group
            disabled={disabled}
            hasIcon={!!Icon}
            noMargin={noMargin}
            noLabel={!label}
          >
            <input
              {...field}
              {...props}
              {...rest}
              type={type}
              className={!!hasError ? 'error' : ''}
              placeholder={label}
              value={hasVal(field.value) ? field.value : ''}
              style={hasVal(field.value) ? {} : { padding: '15px 8px' }}
              ref={ref}
              onChange={(event) => {
                onChange && onChange(event)
                form.handleChange(event)
              }}
            />
            {hasVal(field.value) && type !== 'hidden' && (
              <label htmlFor={name}>{label}</label>
            )}
            {Icon && type !== 'hidden' && <Icon size={20} />}
          </Group>
        )}
      </Field>
      {!hideErrorLabel && (
        <ErrorMessage name={name} className="error" component="div" />
      )}
    </Container>
  ),
)

InputGroup.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  hasError: PropTypes.bool,
  icon: PropTypes.func,
}

InputGroup.defaultProps = {
  hasError: false,
}

export default InputGroup
