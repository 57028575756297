import { ErrorMessage, Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import Cleave from 'cleave.js/react';
import styled, { css } from 'styled-components';
import { primary, red, gray, lightGray } from '~/components/mixins/color';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Group = styled.div`
  display: grid;
  position: relative;
  margin: ${props => (props.noMargin ? '0' : '0 0 10px')};
  width: 100%;

  & > svg {
    position: absolute;
    right: 15px;
    align-self: center;
    color: #555;

    &:hover {
      color: ${props => props.hasClick ? primary.hex() : '#555'};
      cursor: ${props => props.hasClick ? 'pointer' : 'normal'};
    }
  }

  &:focus-within > input {
    border-color: ${props => (props.hasError ? red.hex() : primary.hex())};
  }

  &:focus-within > svg {
    color: ${props => (props.hasError ? red.hex() : primary.hex())};
  }

  ${props =>
    props.required &&
    css`
      label {
        font-weight: 800;
      }
    `};

  input {
    background: ${lightGray.hex()};
    border: 2px solid ${props => (props.hasError ? red.hex() : lightGray.hex())};
    border-radius: 4px;
    padding: 22px 0 8px 10px;
    color: ${gray.hex()};
    height: 46px;
    width: 100%;
    transition: border-color 0.2s ease-in 0s;

    &:focus + label,
    &:not([value='']) + label {
      font-size: 70%;
      transform: translate3d(0, -100%, 0);
      opacity: 1;
      top: 20px;
    }
    & + label {
      position: absolute;
      top: 15px;
      padding-left: 10px;
      transition: all 200ms;
      color: ${gray.hex()};
    }
    ${props =>
      props.disabled &&
      css`
        opacity: 0.55;
        user-select: none;
        pointer-events: none;
        color: #b7b7b7;
        & + label {
          color: #b7b7b7 !important;
        }
      `};
  }
`;

const InputMask = ({ name, label = '', disabled = false, required, hideErrorLabel, noMargin, mask = {}, hasError, icon: Icon, className, onChange, onIconClick, iconTooltip, ...rest }) => (
  <Container>
    <Field type="text" id={name} name={name}>
      {({ field }) => (
        <Group required={required} disabled={disabled} noMargin={noMargin} hasError={hasError} hasClick={onIconClick ?? false}>
          <Cleave
            {...field}
            {...rest}
            disabled={disabled}
            placeholder={`${label}${required ? ' (*)' : ''}`}
            options={mask}
            value={field.value || ''}
            style={field.value ? {} : { padding: '15px 8px' }}
            onChange={(event) => {
              const tempEvent = event;
              tempEvent.target.value = event.target.rawValue;
              onChange ? onChange(tempEvent) : field.onChange(tempEvent);
            }}
          />
          {field.value && (
            <label htmlFor={name}>
              {label}
              {required ? ' (*)' : ''}
            </label>
          )}
          {(Icon && !disabled) && <Icon  size={20} title={iconTooltip || ''} onClick={onIconClick} />}
        </Group>
      )}
    </Field>
    {!hideErrorLabel && <ErrorMessage name={name} className="error" component="div" />}
  </Container>
);

InputMask.propTypes = {
  name: PropTypes.string.isRequired,
  mask: PropTypes.object.isRequired,
  label: PropTypes.string,
  hasError: PropTypes.bool
};

InputMask.defaultProps = {
  hasError: false
};

export default InputMask;
