import React from 'react';
import _ from 'lodash';
import { InputGroup, Autocomplete } from '~/components/form';
import { FilterContainer } from '~/components/crud/styles';
import { formats } from '~/helper';

export default function FormFilter({ values, onListBanks, onListCompanies }) {
  return (
    <FilterContainer>
      <Autocomplete
        name="bank"
        keyField="id"
        label="Banco"
        clearable={true}
        value={_.get(values, 'bank')}
        valueFormat={row => `${row.code} - ${row.name}`}
        loadData={onListBanks}
        emptyText={'Pesquise um banco'}
        tipText={'Digite... '}
        loadingText={'Carregando...'}
        notFoundText={'Não encontrado'}
      />

      <Autocomplete
        name="company"
        keyField="id"
        label="Beneficiário"
        clearable={true}
        value={_.get(values, 'company')}
        valueFormat={row => `${formats.cnpj_cpf(row.identity)} - ${row.name || row.tradeName}`}
        loadData={onListCompanies}
        emptyText={'Pesquise um beneficiário'}
        tipText={'Digite... '}
        loadingText={'Carregando...'}
        notFoundText={'Não encontrada'}
        />

      <InputGroup
        type="text"
        name="bankBranch"
        label="Número da agência"
        maxLength={10}
        />

      <InputGroup
        type="text"
        name="accountNumber"
        label="Número da conta"
        maxLength={20}
        />
    </FilterContainer>
  );
}
