import React from 'react';
import _ from 'lodash';
import { Autocomplete } from '~/components/form';
import { FilterContainer } from '~/components/crud/styles';

export default function FormFilter({ values, onListReportTypes }) {
  return (
    <FilterContainer>
      <Autocomplete
        name="reportType"
        keyField="id"
        label="Tipo do Relatório"
        value={_.get(values, 'reportType')}
        valueFormat={row => `${row.name}`}
        loadData={onListReportTypes}
        emptyText={'Pesquise um tipo de relatório'}
        tipText={'Digite... '}
        loadingText={'Carregando...'}
        notFoundText={'Não encontrado'}
      />
    </FilterContainer>
  );
}
