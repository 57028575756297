import styled from 'styled-components';
import { white, gray, quinary } from '~/components/mixins/color';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0 10px;
  gap: 10px;
  height: calc(100vh - 100px);

  .left {
    display: flex;
    flex: 0 0 400px;
    width: 400px;
    height: 100%;
    box-shadow: -1px -1px 8px 1px rgb(0 0 0 / 10%);
    background: #fff;
    border-radius: 2px;
    padding: 10px;
  }
  .right {
    display: flex;
    position: relative;
    flex: 1;
    width: calc(100% - 400px);
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    background: #fff;
    border-radius: 2px;
    box-shadow: -1px -1px 8px 1px rgb(0 0 0 / 10%);
  }

  .rightFilter {
    display: ${props => (props.showForm ? 'flex' : 'none')};
    box-shadow: -1px -1px 8px 1px rgb(0 0 0 / 10%);
    flex-direction: column;
    position: relative;
    flex: 0 0 25%;
    background: #fff;
    width: 100%;
    border-radius: 3px;
    justify-content: space-between;
  }

`;

export const InnerContainer = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const FormHeaderIn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 0 10px;
  color: ${gray.hex()};
  border-bottom: 1px solid ${quinary.hex()};

  .title {
    flex: 1;
    display: flex;
    flex-direction: row;
    font-size: 16px;
    font-weight: bold;
    justify-content: flex-start;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    div {
      display: flex;
      width: calc(100% - 10px);
      max-width: calc(100% - 10px);
    }
  }
  .actions {
    width: 240px;
    flex: 0 0 240px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const ModalContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;

  form {
    height: 100%;
    width: 100%;
  }
  .form-warn {
    height: 60px;
    min-height: 60px;
  }
  h3 {
    height: 60px;
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #aaa;
  }
  .main {
    background: ${white.hex()};
    padding: 10px;
    display: flex;
    flex-direction: column;
    height: calc(100% - 5px);
    overflow-y: auto;
    overflow-x: hidden;
  }
`;

export const  CompanyContainer = styled.div`
  width: 100%;
  height: 100%;
  font-size: 12px;
  padding: 5px;
  display: flex;
  pointer-events: none;

  .company-text {
    width: ${props => `calc(100% - ${props.hasIcon ? '24px' : '0px'}) `};
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 3px;
    }
  }
`