import React, { useState } from 'react';
import * as Yup from 'yup';
import Fieldset from '~/components/fieldset';
import { InputGroup, FileUploadInline, Select } from '~/components/form';
import { BsCol } from '~/components/layout';
import _ from 'lodash';

const buildValidation = field => {
  return Yup.mixed().test('match', '', function(val) {
    const { path, parent, createError } = this,
      value = val || _.get(parent, path),
      length = _.size(String(value || '')),
      part = field.type === 'number' ? 'dígitos' : 'caracteres';

    if (field.required && !value) {
      return createError({ path, message: `Preencha o campo: ${_.toLower(field.label)}` });
    }
    if (value && field.maxLength && field.maxLength < length) {
      return createError({ path, message: `${field.label} deve ter no máximo ${field.maxLength} ${part}.` });
    }
    if (value && field.minLength && field.minLength > length) {
      return createError({ path, message: `${field.label} deve ter no minimo ${field.minLength} ${part}.` });
    }
    if (value && field.type === 'number' && isNaN(value)) {
      return createError({ path, message: `${field.label} deve ser numérico.` });
    }
    return false;
  });
};

export const ApiSchema = (values, settings) => {
  const versions = _.get(settings, 'charge.registerApi.versions') || [],
    version = values.registerApiVersion,
    validationFields = {};

  if (version) {
    let fields = _.get(_.find(versions, { version }), 'fields') || [];
    _.each(fields, field => {
      validationFields[`registerApiData.${field.name}`] = buildValidation(field);
    });
  }
  return validationFields;
};

export const ApiFields = ({ settings, bankIsDigital, values, errors, touched, setFieldValue, previewMode }) => {
  const versions = _.get(settings, 'registerApi.versions') || [],
    useRegisterApi = _.get(settings, 'registerApi.enabled') || false,
    mappedVersions = [{ value: '', label: 'Não utilizar nenhuma API' }, ..._.map(versions, r => ({ value: r.version, label: `API ${r.version}` }))],
    [selectedVersion, setSelectedVersion] = useState();

  if (!useRegisterApi) {
    return null;
  }

  if (bankIsDigital && _.isEmpty(selectedVersion)) {
    const version = _.get(mappedVersions, '[1].value');
    setFieldValue('registerApiVersion', version);
    setSelectedVersion(version);
  }

  const getFields = () => _.get(_.find(versions, { version: selectedVersion }), 'fields') || [];

  return (
    <Fieldset label="Registro de boletos via API">
      <BsCol md={24} lg={24}>
        <Select
          name="registerApiVersion"
          value={_.get(values, 'registerApiVersion')}
          label={`Versão da API`}
          disabled={previewMode}
          hasError={_.get(errors, 'registerApiVersion') && _.get(touched, 'registerApiVersion')}
          options={{
            values: mappedVersions
          }}
          onChange={evt => {
            let version = _.get(evt, 'target.value'),
              selected = _.find(versions, { version });

            setFieldValue('registerApiVersion', version);
            setSelectedVersion(version);

            if (!_.includes(_.get(selected, 'ourNumberModes') || [], _.get(values, 'fields.ourNumberMode'))) {
              setFieldValue('fields.ourNumberMode', '');
            }
          }}
        />
      </BsCol>

      {!_.isEmpty(values.registerApiVersion) &&
        _.map(getFields(), (field, idx) => (
          <BsCol md={24} lg={24} key={idx}>
            {/^(select)$/.test(field.type) && (
              <Select
                name={`registerApiData.${field.name}`}
                disabled={previewMode}
                hasError={_.get(errors, `registerApiData.${field.name}`) && _.get(touched, `registerApiData.${field.name}`)}
                value={_.get(values, `registerApiData.${field.name}`) || ''}
                label={field.label}
                options={{ values: field.options }}
              />
            )}

            {/^(file)$/.test(field.type) && (
              <FileUploadInline name={`registerApiData.${field.name}`} accept={field.accept} label={field.label} selectedLabel={field.selectedLabel} />
            )}

            {!/^(select|file|link)$/.test(field.type) && (
              <InputGroup
                type="text"
                name={`registerApiData.${field.name}`}
                disabled={previewMode}
                hasError={_.get(errors, `registerApiData.${field.name}`) && _.get(touched, `registerApiData.${field.name}`)}
                {..._.omit(field, 'name', 'type')}
              />
            )}
          </BsCol>
        ))}
    </Fieldset>
  );
};
