import React from 'react';
import _ from 'lodash';
import { InputGroup, Select } from '~/components/form';
import { FilterContainer } from '~/components/crud/styles';
import { types } from '~/pages/email-templates/form';

export default function FormFilter({ values }) {
  return (
    <FilterContainer>
      <InputGroup
        type="text"
        name="description"
        label="Assunto"
        maxLength={100} />

      <Select
        name="type"
        label="Tipo"
        options={{
          values: [
            { value: '', label: 'Todos' },
            ..._.map(types, (label, value) => ({ value, label }))
          ]
        }}
        />
    </FilterContainer>
  );
}
