import React, { useLayoutEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  MdCheck,
  MdClear,
  MdErrorOutline,
  MdAdd,
  MdMailOutline,
  MdLock,
  MdAutorenew,
} from 'react-icons/md'
import { BiChevronsLeft, BiChevronsRight } from 'react-icons/bi'
import { IoWarningOutline } from 'react-icons/io5'
import Crud from '~/components/crud'
import {
  CrudContainer,
  CompanyContainer,
  SecondarySwitch,
  LeftContainer,
  Dashboard,
  DashboardGroup,
  CellIcon,
} from '~/pages/payment-orders/styles'
import FormFilter from '~/pages/payment-orders/filter'
import { Creators as PaymentOrderActions } from '~/store/modules/payment-order'
import { formatBankAccountGrid } from '~/pages/bank-accounts/form'
import { Creators as HeaderActions } from '~/store/modules/header'
import _ from 'lodash'
import { formats, getColorByContrast } from '~/helper'
import confirm from '~/components/confirm'
import useWindowSize from '~/hooks/use-window-size'
import CellStatus from '~/components/datatable/cell-status'
import { statusMap, statusColors } from '~/pages/payment-orders/constants'
import CellBank from '~/components/datatable/cell-bank'
import FloatingButton from '~/components/floatbutton'
import Component from '~/components/floatbutton/group'
import ChangeScheduledDateModal from './modal-change-scheduled-date'
import useSearchParameters from '~/hooks/use-search-params'
import CancellationReasonModal from './modal-cancellation-reason'
import useModal from '~/hooks/use-modal'

const columns = [
  {
    name: ' ',
    selector: 'status',
    width: '10px',
    cell: (row) => (
      <CellStatus
        title={statusMap[row.status]}
        color={statusColors[row.status]}
      />
    ),
  },
  {
    name: 'Empresa / Beneficiário',
    selector: 'company',
    wrap: true,
    cell: (row) => {
      const company = _.get(row, 'company') || {},
        hasError = !_.isEmpty(_.get(row, 'generationMessages.errors')),
        hasWarning = !_.isEmpty(_.get(row, 'generationMessages.warnings')),
        bp = _.get(row, 'businessPartner') || {},
        isBlocked = _.get(row,'isBlocked');

      return (
        <CompanyContainer hasIcon={hasError || hasWarning || isBlocked}>
          {(hasError || hasWarning || isBlocked) && (
            <div className="company-icons">
              {hasError && (
                <CellIcon size={20} color="#ff0000">
                  <MdErrorOutline />
                </CellIcon>
              )}
              {hasWarning && (
                <CellIcon size={20} color="#efd600">
                  <IoWarningOutline />
                </CellIcon>
              )}
              {isBlocked && (
                <CellIcon size={20} color="#ff0000">
                  <MdLock />
                </CellIcon>
              )}
            </div>
          )}
          <div className="company-text">
            <span>
              {formats.cnpj_cpf(_.get(company, 'identity', '')) +
                ' ' +
                (_.get(company, 'name', '') || _.get(company, 'tradeName', ''))}
            </span>
            <span>
              {formats.cnpj_cpf(_.get(bp, 'identity', '')) +
                ' ' +
                (_.get(bp, 'name', '') || _.get(bp, 'tradeName', ''))}
            </span>
          </div>
        </CompanyContainer>
      )
    },
  },
  {
    name: 'Tipo',
    selector: 'paymentOrderType',
    width: '180px',
    wrap: true,
    hide: 'lg',
    format: (row) => _.get(row, 'paymentOrderType.name'),
  },
  {
    name: 'Nr. Documento',
    selector: 'documentNumber',
    hide: 'lg',
    width: '150px',
    right: true,
    format: (row) => _.get(row, 'documentNumber'),
  },
  {
    name: 'Vencimento',
    selector: 'dueDate',
    width: '100px',
    right: true,
    format: (row) => formats.dateTimeZone(_.get(row, 'dueDate'), 'dd/MM/yyyy'),
  },
  {
    name: 'Agendamento',
    selector: 'scheduledDate',
    width: '100px',
    right: true,
    format: (row) =>
      formats.dateTimeZone(_.get(row, 'scheduledDate'), 'dd/MM/yyyy'),
  },
  {
    name: 'Valor Doc.',
    selector: 'value',
    right: true,
    width: '150px',
    compact: false,
    format: (row) => row.isPartial ? formats.currency(_.get(row, 'baseAmount')) : formats.currency(_.get(row, 'value')),
  },
  {
    name: 'Valor a Pagar',
    selector: 'total',
    right: true,
    width: '150px',
    compact: false,
    format: (row) => row.isPartial ? formats.currency(_.get(row, 'value')) :
      formats.currency(_.get(row, 'orderData.total') || _.get(row, 'value')),
  },
  {
    name: 'Agência / Conta',
    selector: 'bankAccount',
    width: '120px',
    left: true,
    hide: 'lg',
    cell: (row) => <CellBank bankAccount={_.get(row, 'bankAccount')} />,
  },
  {
    name: ' ',
    selector: 'id',
    width: '5px',
    center: true,
    cell: (row) => <span>&nbsp;</span>,
  },
]

const customStyles = {
  rows: {
    denseStyle: {
      minHeight: '58px',
    },
  },
}

const getElem = ({ label, value }) => {
  return () => (
    <span>
      <strong>{label}</strong>&nbsp;{value}
      <i style={{ padding: '0 5px' }}></i>
    </span>
  )
}

const getDivider = (array, breakLine) => {
  if (breakLine) {
    array.push(() => <span className="break"></span>)
  } else if (_.size(array) > 0) {
    array.push(() => <span className="spacing"></span>)
  }
}

const getDateFilterText = (startDate, endDate) => {
  if (startDate && endDate) {
    return `${formats.date(startDate)} até ${formats.date(endDate)}`
  } else if (startDate && !endDate) {
    return `a partir de ${formats.date(startDate)}`
  } else if (!startDate && endDate) {
    return `até ${formats.date(endDate)}`
  }
  return ''
}

const getSubtitle = (filter) => {
  let elements = [],
    paymentOrderType = _.get(filter, 'paymentOrderType'),
    businessPartner = _.get(filter, 'businessPartner'),
    company = _.get(filter, 'company'),
    bankAccount = _.get(filter, 'bankAccount')

  if (!_.isEmpty(paymentOrderType)) {
    elements.push(
      getElem({
        label: 'Tipo',
        value: _.get(paymentOrderType, 'name') || '',
      }),
    )
  }
  if (!_.isEmpty(bankAccount)) {
    getDivider(elements)
    elements.push(
      getElem({
        label: 'Contrato',
        value: formatBankAccountGrid(bankAccount),
      }),
    )
  }

  if (!_.isEmpty(company)) {
    getDivider(elements)
    elements.push(
      getElem({
        label: 'Empresa',
        value: `${formats.cnpj_cpf(company.identity)} ${company.name}`,
      }),
    )
  }
  if (!_.isEmpty(businessPartner)) {
    getDivider(elements)
    elements.push(
      getElem({
        label: 'Beneficiário',
        value: `${formats.cnpj_cpf(businessPartner.identity)} ${
          businessPartner.name
        }`,
      }),
    )
  }

  if (filter.dueDateStart || filter.dueDateEnd) {
    getDivider(elements)
    elements.push(
      getElem({
        label: 'Data de vencimento',
        value: getDateFilterText(filter.dueDateStart, filter.dueDateEnd),
      }),
    )
  }
  if (filter.scheduledDateStart || filter.scheduledDateEnd) {
    getDivider(elements)
    elements.push(
      getElem({
        label: 'Data de agendamento',
        value: getDateFilterText(
          filter.scheduledDateStart,
          filter.scheduledDateEnd,
        ),
      }),
    )
  }
  if (filter.payDateStart || filter.payDateEnd) {
    getDivider(elements)
    elements.push(
      getElem({
        label: 'Data de pagamento',
        value: getDateFilterText(filter.payDateStart, filter.payDateEnd),
      }),
    )
  }

  if (_.get(filter, 'documentNumber', false)) {
    getDivider(elements)
    elements.push(
      getElem({
        label: 'Número do documento',
        value: filter.documentNumber,
      }),
    )
  }

  if (_.get(filter, 'ourNumber', false)) {
    getDivider(elements)
    elements.push(
      getElem({
        label: 'Nosso número',
        value: filter.ourNumber,
      }),
    )
  }

  if (_.get(filter, 'extras.Reference1', false)) {
    getDivider(elements)
    elements.push(
      getElem({
        label: 'Cód. externo',
        value: filter.extras.Reference1,
      }),
    )
  }

  if (_.isEmpty(elements)) {
    return null
  }

  return _.map(elements, (Element, index) => <Element key={index} />)
}


export default function PaymentOrders({ history, match, acls }) {
  const dispatch = useDispatch()
  const state = useSelector((state) => state.paymentOrder)
  const headerState = useSelector((state) => state.header)
  const [showSecondaryCards, setShowSecondaryCards] = useState(false)
  const filter = _.get(headerState, 'filter.data')
  const canCreate = acls.includes('M')
  const showFilter = headerState.filter.visible
  const dashboard = _.get(state, 'data.summary') || {}
  const statusSelected = _.get(filter, 'status') || 'open'
  const selection = state.selecteds || []
  const windowSize = useWindowSize()
  const cardCount = showSecondaryCards ? 11 : 6
  const cardOffset = showSecondaryCards ? 180 : 150
  const [openForm, setOpenForm] = useState(!!_.get(match, 'params.id'))
  const requireCancellationReason = _.get(state, 'data.cfgData.requireCancellationReason');
  const cardSize = Math.max(
    155,
    Math.floor(((windowSize.width || 1000) - cardOffset) / cardCount),
  )
  const statusGroup1 = [
    'pending',
    'open',
    'inShipping',
    'sent',
    'scheduled',
    'closed'
  ]
  const statusGroup2 = ['rejected', 'canceled', 'returned', 'waitCancel', 'waitingApproval', 'notApproved']
  const [openScheduledDateModal, setOpenScheduledDateModal] = useState(false)
  const canBlockUnblock = acls.includes('BP')

  const {
    location,
    routeParams,
    getAllParams,
    toStringify,
  } = useSearchParameters()

  const canSendEmail = _.filter(selection, (item) => {
    const status = _.get(item, 'status')
    return (
      /^(closed)$/.test(status) &&
      (_.get(item, 'businessPartner.email') ||
        !_.isEmpty(
          _.map(_.get(item, 'businessPartner.extras.contacts') || [], 'email'),
        ))
    )
  })

  const [isOpenCancellationReasonModal, toggleCancellationReasonModal] = useModal();

  useLayoutEffect(() => {
    const config = {
      loading: state.loading,
      useFilter: true,
      filter: {
        ...headerState.filter,
        visible: false,
      },
      subtitle: getSubtitle(filter || {}),
    }
    dispatch(HeaderActions.configure(config))
    dispatch(PaymentOrderActions.listPaymentOrderTypes())
    // eslint-disable-next-line
  }, [])

  useLayoutEffect(() => {
    const { id, offset, limit = 20, ...filter } = getAllParams()

    const listMethod =
      _.isEmpty(id) || _.size(_.get(state, 'data.rows')) === 0
        ? PaymentOrderActions.list
        : null

    if (id) {
      dispatch(PaymentOrderActions.load({ id }))
    }

    dispatch(
      HeaderActions.callFilter(
        { ...filter, offset: _.toInteger(offset), limit: _.toInteger(limit), stateSummary: _.get(state, 'data.summary') },
        listMethod,
      ),
    )
    // eslint-disable-next-line
  }, [location, routeParams])

  function handleLoad(data) {
    const filter = getAllParams()
    const id = _.get(data, 'id', false)
    let pathname = `/payment-orders`

    if (id) {
      pathname = `${pathname}/${id}`
    } else {
      pathname = `${pathname}/new`
    }

    const historySearch = _.omit(filter, [
        'id',
        'company',
        'bankAccount',
        'createdAtStart',
        'createdAtEnd',
        'dueDateStart',
        'dueDateEnd',
        'scheduledDateStart',
        'scheduledDateEnd',
        'payDateStart',
        'payDateEnd',
        'businessPartner',
        'paymentOrderType',
        'bankContract',
        'group',
        'stateSummary',
      ]),
      group = _.get(filter, 'group.value') || _.get(filter, 'group')

    if (!_.isEmpty(group)) {
      historySearch.group = group
    }

    history.push({
      pathname,
      search: `?${toStringify(historySearch)}`,
      state: filter,
    })
    setOpenForm(true)
  }

  function handlePageChange(offset, limit) {
    const filter = getAllParams(),
      historySearch = {
        ..._.omit(filter, [
          'id',
          'company',
          'bankAccount',
          'createdAtStart',
          'createdAtEnd',
          'dueDateStart',
          'dueDateEnd',
          'scheduledDateStart',
          'scheduledDateEnd',
          'payDateStart',
          'payDateEnd',
          'businessPartner',
          'paymentOrderType',
          'bankContract',
          'group',
          'stateSummary',
        ]),
        offset,
        limit,
      },
      group = _.get(filter, 'group.value') || _.get(filter, 'group')

    if (!_.isEmpty(group)) {
      historySearch.group = group
    }

    history.push({
      pathname: '/payment-orders',
      search: `?${toStringify(historySearch)}`,
      state: { ..._.omit(filter, 'id'), offset, limit },
    })
    setOpenForm(false)
  }

  function handleListPaymentOrderType(term, callback) {
    dispatch(PaymentOrderActions.listPaymentOrderType(term, callback))
  }

  function handleListBankContracts(term, callback) {
    dispatch(PaymentOrderActions.listBankContracts({ term }, callback))
  }

  const handleListCompanies = (params, callback) => {
    dispatch(PaymentOrderActions.listCompanies(params, callback))
  }

  function handleListBusinessPartners(term, callback) {
    dispatch(PaymentOrderActions.listBusinessPartners(term, callback))
  }

  function handleHideFilter() {
    dispatch(HeaderActions.hideFilter())
    setOpenForm(false)
  }

  function handleFilter(filter) {
    const historySearch = {
        ..._.omit(filter, [
          'company',
          'bankAccount',
          'createdAtStart',
          'createdAtEnd',
          'dueDateStart',
          'dueDateEnd',
          'scheduledDateStart',
          'scheduledDateEnd',
          'payDateStart',
          'payDateEnd',
          'businessPartner',
          'paymentOrderType',
          'bankContract',
          'documentNumber',
          'ourNumber',
          'extras',
          'group',
          'stateSummary',
        ]),
        offset: 0,
      },
      group = _.get(filter, 'group.value') || _.get(filter, 'group')

    if (!_.isEmpty(group)) {
      historySearch.group = group
    }

    handleHideFilter()
    history.push({
      pathname: `/payment-orders`,
      search: `?${toStringify(historySearch)}`,
      state: filter,
    })
  }

  function filterStatus(status) {
    handleFilter({ ...filter, status })
  }

  async function sendEmails() {
    let size = _.size(canSendEmail),
      plural =
        size === 1
          ? 'o comprovante de pagamento selecionado'
          : `os ${formats.number(size)} comprovantes de pagamento selecionados`,
      result = await confirm.show({
        width: 450,
        title: 'Atenção',
        text: `Deseja enviar ${plural} por e-mail?`,
      })

    if (result) {
      let params = { ids: _.map(canSendEmail, 'id'), isBatch: true }
      dispatch(PaymentOrderActions.sendEmail(params))
    }
  }

  const getColorByBgColor = (bgColor) => {
    return getColorByContrast(bgColor, '#fff', '#000')
  }

  const confirmCancel = async (cancellationReason, action) => {
    const size = _.size(state.selecteds);
    const data = {
      ids: _.map(state.selecteds, 'id'),
      cancellationReason
    }
    const result = await confirm.show({
      title: 'Atenção',
      width: 500,
      text:
          size === 1
            ? `Deseja realmente cancelar essa ordem de pagamento? Essa ação não poderá ser desfeita`
            : `Deseja realmente cancelar as ${size} ordens de pagamentos? Essa ação não poderá ser desfeita`,
    })

    if (result) {
      dispatch(PaymentOrderActions.cancelBatch(data, action));
    }
    return result;
  }

  const handleOnSubmitCancellationReason = async ({ cancellationReason }) => {
    const action = {
      toggleModal: toggleCancellationReasonModal
    }
    if (await confirmCancel(cancellationReason, action)) {
      toggleCancellationReasonModal(false);
    }
  }

  const blockUnblockBatch = async () => {
    const size = _.size(state.selecteds),
      listIsBlocked = _.map(state.selecteds, 'isBlocked'),
      types = _.uniq(listIsBlocked);

    const blockedText = _.size(types) === 1
      ? types[0] === true
        ? 'desbloquear'
        : 'bloquear'
      : 'bloquear/desbloquear'

    const result = await confirm.show({
      title: 'Atenção',
      text:
        size === 1
          ? `Deseja realmente ${blockedText} essa ordem de pagamento?`
          : `Deseja realmente ${blockedText} as ${size} ordens de pagamentos?`,
    })

    if (result) {
      let params = { ids: _.map(state.selecteds, 'id'), rows: state.selecteds }
      dispatch(PaymentOrderActions.blockUnblockBatch(params, filter))
    }

  }

  const reopenBatch = async () => {
    const size = _.size(state.selecteds),
      result = await confirm.show({
        title: 'Atenção',
        text:
          size === 1
            ? `Deseja realmente reabrir essa ordem de pagamento? Essa ação não poderá ser desfeita`
            : `Deseja realmente reabrir as ${size} ordens de pagamentos? Essa ação não poderá ser desfeita`,
      })
    if (result) {
      let params = { ids: _.map(state.selecteds, 'id') }
      dispatch(PaymentOrderActions.reopenBatch(params, filter))
    }
  }

  function handListBankAccountsGroups(term, callback) {
    dispatch(PaymentOrderActions.listBankAccountsGroups({ term }, callback))
  }

  const renderLeftComponent = ({ Table }) => {
    return (
      <LeftContainer>
        <Dashboard cardSize={cardSize}>
          <SecondarySwitch
            title="Ver mais situações"
            onClick={() => setShowSecondaryCards(!showSecondaryCards)}
          >
            {showSecondaryCards ? <BiChevronsLeft /> : <BiChevronsRight />}
          </SecondarySwitch>

          {showSecondaryCards && (
            <div className="section">
              {_.map(statusGroup2, (sts, index) => (
                <DashboardGroup
                  key={index}
                  selected={statusSelected === sts}
                  getCol={getColorByBgColor}
                  title={formats.currency(_.get(dashboard, `${sts}.sum`))}
                  color={statusColors[sts]}
                  onClick={() => filterStatus(sts)}
                >
                  <div className="sum">
                    <span>$ </span>
                    {formats.numberScale(_.get(dashboard, `${sts}.sum`))}
                  </div>
                  <div className="name">
                    <div className="square"></div>
                    {statusMap[sts]}
                  </div>
                  <div className="count">
                    {formats.number(_.get(dashboard, `${sts}.count`))}
                  </div>
                </DashboardGroup>
              ))}
            </div>
          )}

          <div className="section">
            {_.map(statusGroup1, (sts, index) => (
              <DashboardGroup
                key={index}
                selected={statusSelected === sts}
                getCol={getColorByBgColor}
                title={formats.currency(_.get(dashboard, `${sts}.sum`))}
                color={statusColors[sts]}
                onClick={() => filterStatus(sts)}
              >
                <div className="sum">
                  <span>$ </span>
                  {formats.numberScale(_.get(dashboard, `${sts}.sum`))}
                </div>
                <div className="name">
                  <div className="square"></div>
                  {statusMap[sts]}
                </div>
                <div className="count">
                  {formats.number(_.get(dashboard, `${sts}.count`))}
                </div>
              </DashboardGroup>
            ))}
          </div>
        </Dashboard>

        <div className="crud-table">{Table}</div>
      </LeftContainer>
    )
  }

  function changeSelectPayments(selecteds) {
    dispatch(PaymentOrderActions.changeSelectPayments(selecteds))
  }

  const handleOnSubmitChangeScheduledDate = async ({ scheduledDate }) => {
    const closeModal = () => setOpenScheduledDateModal(false)

    let result = await confirm.show({
      title: 'Atenção',
      width: 500,
      text: `Deseja alterar a data de pagamento das ordens de pagamento selecionadas?`,
    })

    if (result) {
      dispatch(
        PaymentOrderActions.changeScheduledDate(
          state.selecteds,
          scheduledDate,
          { closeModal, filterData: headerState.filter.data },
        ),
      )
    }
  }

  const selectedsActions = [
    {
      icon: 'fa fa-trash',
      label: 'Cancelar pagamentos em lote',
      action: () => requireCancellationReason ? toggleCancellationReasonModal(true) : confirmCancel(),
    }
  ]

  if (canBlockUnblock) {
    selectedsActions.push({
      icon: 'fa fa-lock',
      label: 'Bloquear/Desbloquear em lote',
      action: () => blockUnblockBatch()
    })
  }

  if(/^(pending|open)$/.test(statusSelected)) {
    selectedsActions.push({
      icon: 'fa fa-calendar',
      label: 'Alterar data de agendamento em lote',
      action: () => setOpenScheduledDateModal(true),
    });
  }

  return (
    <CrudContainer>
      {!showFilter &&
        _.size(state.selecteds) > 0 &&
        !/^(closed|notApproved|rejected|canceled|returned)$/.test(statusSelected) && (
          <Component
            anchorIcon="fa fa-bars"
            actions={selectedsActions}
          ></Component>
        )}

      {!showFilter && canCreate && _.size(state.selecteds) === 0 && (
        <FloatingButton
          icon={MdAdd}
          title="Nova fatura"
          onClick={() => handleLoad()}
        />
      )}



      {!showFilter &&
        _.size(canSendEmail) > 0 &&
        /^(closed)$/.test(statusSelected) && (
          <FloatingButton
            icon={MdMailOutline}
            title="Enviar e-mail para parceiro(s) de negócio(s)"
            onClick={() => sendEmails()}
          />
        )}

      {!showFilter &&
        _.size(state.selecteds) > 0 &&
        /^(notApproved|rejected|canceled|returned)$/.test(statusSelected) && (
          <FloatingButton
            icon={MdAutorenew}
            title="Reabrir pagamentos em lote"
            onClick={() => reopenBatch()}
          />
      )}


      <ChangeScheduledDateModal
        isOpen={openScheduledDateModal}
        closeModal={() => setOpenScheduledDateModal(false)}
        handleOnSubmit={handleOnSubmitChangeScheduledDate}
      />

      <CancellationReasonModal
        loading={state.modalLoading}
        isOpen={isOpenCancellationReasonModal}
        toggleModal={toggleCancellationReasonModal}
        handleOnSubmit={handleOnSubmitCancellationReason}
      />

      <Crud
        useOpenForm={true}
        openForm={openForm || showFilter}
        data={state.data}
        columns={columns}
        onRowClicked={handleLoad}
        onChangePage={handlePageChange}
        onCloseFilter={handleHideFilter}
        tableLoading={state.loading}
        hideAdd={true}
        formTitle={() => 'Filtro'}
        renderLeftComponent={renderLeftComponent}
        emptyText="Nenhuma ordem de pagamento encontrada"
        formOptions={{
          initialValues: headerState.filter.data,
          validateOnMount: { scheduledDate: true, paymentOrderType: true },
          onSubmit: handleFilter,
        }}
        renderForm={(args) => (
          <FormFilter
            {...args}
            onListPaymentOrderType={handleListPaymentOrderType}
            onListBankContracts={handleListBankContracts}
            onListCompanies={handleListCompanies}
            onListBusinessPartners={handleListBusinessPartners}
            onListBankAccountsGroups={handListBankAccountsGroups}
          />
        )}
        actions={[
          {
            label: 'Limpar Filtro',
            icon: MdClear,
            action: () => handleFilter({ status: statusSelected }),
          },
          {
            label: 'Aplicar Filtro',
            icon: MdCheck,
            isSubmit: true,
            action: handleFilter,
          },
        ]}
        dataTableOptions={{
          customStyles,
          ignoreRowClicked: true,
          selectableRowsHighlight: false,
          selectableRows: ['pending', 'open', 'closed', 'notApproved', 'rejected', 'canceled', 'returned', 'scheduled'].includes(
            statusSelected,
          ),
          selectableRowDisabled: (row) => {
            return row.editLocked
          },
          selectableRowSelected: (row) => {
            return _.find(state.selecteds, { id: row.id })
          },
          onSelectedRowsChange: ({
            allSelected,
            selectedCount,
            selectedRows,
          }) => {
            changeSelectPayments(selectedRows)
          },
        }}
      />
    </CrudContainer>
  )
}
