import React, { useState } from 'react';
import _ from 'lodash';
import DataTable from '~/components/datatable';
import { TableContainer } from '~/pages/accounts/styles';
import { formatBankAccountGrid } from '~/pages/bank-accounts/form';

const columns = [
    {
      name: 'Banco',
      selector: 'bank',
      format: (row) => `${_.get(row, 'bank.code')} - ${_.get(row, 'bank.name')}`
    },
    {
      name: 'Agência / Conta',
      selector: 'bankAccount',
      width: '180px',
      format: (row) => formatBankAccountGrid(_.get(row, 'bankAccount'))
    }
  ];

const getRows = (array = [], offset, pageSize) => {
  return array.slice(offset, offset + pageSize);
};

export const TabBankAccounts = ({ values }) => {
  const rows = _.get(values, 'bankAccounts') || [];
  const count = _.size(rows);
  const pageSize = 50;
  const [ offset, setOffset ] = useState(0);

  const onChangePage = (offset) => {
    setOffset(offset);
  };

  return (
    <TableContainer>
      <DataTable
        emptyText="Nenhuma conta bancária vinculada"
        pageSize={pageSize}
        onChangePage={onChangePage}
        hideUpdateButton={true}
        data={{ rows: getRows(rows, offset, pageSize), offset, count, limit: pageSize }}
        columns={columns}
        extraOptions={{
          ignoreRowClicked: true,
          selectableRows: false,
          selectableRowsHighlight: false
        }}
        />
    </TableContainer>
  )
};
