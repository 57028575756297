import styled from 'styled-components';
import { white } from '~/components/mixins/color';

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  padding: 10px;
  position: relative;

  .company-info {
    line-height: 15px;
    width: 100%;
    height: 80%;
    pointer-events: none;
    font-size: 12px;

    td {
      min-height: 20px;
      vertical-align: middle;
    }
    .title {
      width: 70px;
      font-weight: bold;
      font-size: 85%;
      color: #999;
    }
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 10px 5px;
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 432px);
  min-height: 200px;
  position: relative;
`;

export const Summary = styled.div`
  position: absolute;
  color: #848080;
  left: 10px;
  bottom: 18px;
  z-index: 10;
`;

export const LeftContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  gap: 10px;

  .crud-table {
    display: flex;
    height: calc(100% - 120px);
    width: 100%;
    flex: 1;
  }
`;

export const DashboardGroup = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 60px;
  gap: 3px;
  padding: 10px;
  border-right: 1px solid #f0f0f0;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #ffffff;

  &:last-child {
    border: none;
  }
  .name {
    font-size: 10px;
    font-weight: normal;
    color: #999;
    margin-bottom: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .square {
    background: ${props => props.color};
    width: 10px;
    height: 10px;
    margin-right: 7px;
  }
  .sum {
    display: flex;
    align-items: center;
    font-weight: 800;
    font-size: 20px;
    color: #444;

    span {
      font-size: 14px;
      color: #888;
      margin-right: 3px;
    }
  }
  .count {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    padding: 5px;
    height: 20px;
    bottom: -20px;
    width: 40px;
    border-radius: 3px;
    border: 1px solid #f0f0f0;
    border: 1px solid ${(props) => props.color};
    border-color: ${(props) => props.selected ? props.color : '#f0f0f0'};
    color: ${(props) => props.selected ? props.getCol(props.color) : '#555'};
    background: ${(props) => props.selected ? props.color : '#eee'};
  }
`;

export const Dashboard = styled.div`
  position: relative;
  display: flex;
  height: 120px;
  min-height: 120px;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 120px;
  padding: 0 10px;
  gap: 15px;
  overflow-x: auto;
  background: ${white.hex()};

  .section {
    border: 1px solid #f0f0f0;
    border-radius: 3px;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.03);
    grid-auto-columns: minmax(${(props) => props.cardSize}px, 1fr);
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 0px;
    padding: 10px 0;

    ${DashboardGroup} {
      width: ${(props) => props.cardSize}px;
    }
  }
`;
