import React, { useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { SubMenu } from '~/components/menu/sub-menu';
import { IconContext } from 'react-icons/lib';
import { primary, white, gray } from '~/components/mixins/color';

const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;

  img {
    width: auto;
    height: 45px;
  }
`

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: ${gray.fade(0.95).string()};
  margin-top: 10px;
`

const SidebarWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`

const SidebarNav = styled.nav`
  background: ${white.hex()};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
`

const MenuList = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: calc(100vh - 155px);
  overflow-x: hidden;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 1024px) {
    & {
      height: calc(100vh - 188px);
      max-height: calc(100vh - 188px);
    }
  }
`

const Sidebar = ({ icon, logo, sidebar, data }) => {
  const { location } = useHistory()
  const current = location.pathname
  let found = _.find(_.flattenDeep(_.map(data, 'subNav')), { path: current })
  if (!found) {
    found = _.find(data, { path: current })
  }

  const color = !!sidebar ? primary.hex() : gray.fade(0.35).string()
  const selectedPath = _.kebabCase(_.get(found, 'group') || 'home')
  const [selected, setSelected] = useState(selectedPath)
  const [showing, setShowing] = useState(selectedPath)

  const show = (selectedPath) => {
    const inShowing = (selectedPath === showing)
    setShowing(inShowing ? '-' : selectedPath)
  }

  return (
    <>
      <IconContext.Provider value={{ color, size: 18 }}>
        <SidebarNav>
          <SidebarWrap>
            <Logo>
              <img src={!sidebar ? icon : logo} alt="DocPay" />
            </Logo>
            <Divider />

            <MenuList>
              {data.map((item, index) => {
                return (
                  <SubMenu
                    item={item}
                    key={index}
                    mini={!sidebar}
                    selected={selected}
                    onSelect={setSelected}
                    showing={showing}
                    onShowing={show}
                  />
                )
              })}
            </MenuList>
          </SidebarWrap>
        </SidebarNav>
      </IconContext.Provider>
    </>
  )
}

export default Sidebar
