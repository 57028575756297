import _ from 'lodash';

export const statusMap = {
  binded: 'Vinculada',
  pending: 'Pré-cadastro',
  open: 'Aberta',
  inShipping: 'Em remessa',
  sent: 'Enviada',
  scheduled: 'Agendada',
  waitCancel: 'Em cancelamento',
  closed: 'Fechada',
  canceled: 'Cancelada',
  rejected: 'Rejeitada',
  waitingApproval: 'Em aprovação',
  returned: 'Devolvida',
  notApproved: 'Não Aprovada',
};

export const statusColors = {
  binded: '#e0e0e0',
  pending: '#efd600',
  open: '#40d1ff',
  inShipping: '#9033ff',
  sent: '#4c81f9',
  scheduled: '#6264a7',
  closed: '#77dd76',
  waitCancel: '#ee7e02',
  canceled: '#ba1f33',
  rejected: '#e067e0',
  waitingApproval: '#aaa',
  returned: '#ffeb3c',
  notApproved: '#fba6e6'
};

export const accountTypes = [
  { value: 'CC', label: 'Conta corrente' },
  { value: 'CD', label: 'Conta depósito' },
  { value: 'CG', label: 'Conta garantida' },
  { value: 'PG', label: 'Conta de pagamento' },
  { value: 'PP', label: 'Poupança' }
];

export const pixAccountTypes = [
  { value: '01', label: 'Conta corrente' },
  { value: '02', label: 'Conta pagamento' },
  { value: '03', label: 'Conta poupança' },
  { value: '04', label: 'Chave PIX' }
];

export const pixKeyTypes = [
  { value: '01', label: 'Telefone' },
  { value: '02', label: 'E-mail' },
  { value: '03', label: 'CPF' },
  { value: '03_', label: 'CNPJ' },
  { value: '04', label: 'Chave Aleatória' },
  { value: '05', label: 'Dados bancários' }
];

export const identityTypes = [
  { value: '0', label: 'Isento/Não Informar' },
  { value: '1', label: 'CPF' },
  { value: '2', label: 'CNPJ' },
  { value: '3', label: 'PIS/PASEP' },
  { value: '9', label: 'Outro' }
];

export const beneficiaryNotes = [
  {
    value: '0',
    label: 'Não emite aviso ao Beneficiário',
    bankCode: '341'
  },
  {
    value: '3',
    label: 'Emite aviso ao Beneficiário quando do agendamento do pagamento',
    bankCode: '341'
  },
  {
    value: '5',
    label: 'Emite aviso ao Beneficiário após pagamento efetuado',
    bankCode: '341'
  },
  {
    value: '9',
    label: 'Emite aviso ao Beneficiário tanto no agendamento quanto após o pagamento',
    bankCode: '341'
  },
  {
    value: '0',
    label: 'Não Emite Aviso',
    bankCode: '033'
  },
  {
    value: '2',
    label: 'Emite Aviso Somente para o Remetente',
    bankCode: '033'
  },
  {
    value: '5',
    label: 'Emite Aviso Somente para o Beneficiário',
    bankCode: '033'
  },
  {
    value: '6',
    label: 'Emite Aviso para o Remetente e Beneficiário',
    bankCode: '033'
  },
  {
    value: '0',
    label: 'Não Emite Aviso',
    bankCode: '237'
  },
  {
    value: '2',
    label: 'Emite Aviso Somente para o Remetente',
    bankCode: '237'
  },
  {
    value: '5',
    label: 'Emite Aviso Somente para o Beneficiário',
    bankCode: '237'
  },
  {
    value: '6',
    label: 'Emite Aviso para o Remetente e Beneficiário',
    bankCode: '237'
  },
  {
    value: '7',
    label: 'Emite Aviso para o Beneficiário e 2 Vias para o Remetente',
    bankCode: '237'
  },
  {
    value: '0',
    label: 'Não Emite Aviso',
    bankCode: '104'
  },
  {
    value: '2',
    label: 'Emite Aviso Somente para o Remetente',
    bankCode: '104'
  },
  {
    value: '5',
    label: 'Emite Aviso Somente para o Beneficiário',
    bankCode: '104'
  },
  {
    value: '6',
    label: 'Emite Aviso para o Remetente e Beneficiário',
    bankCode: '104'
  },
  {
    value: '7',
    label: 'Emite Aviso para o Beneficiário e 2 Vias para o Remetente',
    bankCode: '104'
  },
  {
    value: '0',
    label: 'Não Emite Aviso',
    bankCode: '001'
  }
];

export const allowedOrdersTypes = bankSettings => {
  if (_.isEmpty(bankSettings)) {
    return ordersTypeMap;
  }
  let map = {};

  for (let orderType in bankSettings) {
    let bankOpt = bankSettings[orderType] || {},
      allOpt = ordersTypeMap[orderType] || {},
      row = {};
    row.defaults = bankOpt.defaults || allOpt.defaults;
    row.entryType = _.filter(allOpt.entryType, r => bankOpt.entryType.includes(r.value));
    row.serviceType = _.filter(allOpt.serviceType, r => bankOpt.serviceType.includes(r.value));

    map[orderType] = row;
  }
  return map;
};

export const ordersTypeMap = {
  '10': {
    defaults: { serviceType: '20' },
    serviceType: [
      { value: '20', label: 'Pagamento Fornecedor' },
      { value: '30', label: 'Pagamento Salários' },
      { value: '98', label: 'Pagamentos Diversos' }
    ],
    entryType: [
      { value: '41', label: 'TED - Outra Titularidade' },
      { value: '43', label: 'TED - Mesma Titularidade' }
    ]
  },
  '11': {
    defaults: { serviceType: '20', entryType: '03' },
    serviceType: [
      { value: '20', label: 'Pagamento Fornecedor' },
      { value: '30', label: 'Pagamento Salários' },
      { value: '98', label: 'Pagamentos Diversos' }
    ],
    entryType: [{ value: '03', label: 'DOC/TED' }]
  },
  '12': {
    defaults: { serviceType: '20', entryType: '45' },
    serviceType: [
      { value: '20', label: 'Pagamento Fornecedor' },
      { value: '30', label: 'Pagamento Salários' },
      { value: '98', label: 'Pagamentos Diversos' }
    ],
    entryType: [{ value: '45', label: 'PIX Transferência' }]
  },
  '13': {
    defaults: { serviceType: '20', entryType: '01' },
    serviceType: [
      { value: '20', label: 'Pagamento Fornecedor' },
      { value: '30', label: 'Pagamento Salários' },
      { value: '98', label: 'Pagamentos Diversos' }
    ],
    entryType: [{ value: '01', label: 'Crédito em Conta Corrente/Salário' }]
  },
  '14': {
    defaults: { serviceType: '20', entryType: '05' },
    serviceType: [
      { value: '20', label: 'Pagamento Fornecedor' },
      { value: '30', label: 'Pagamento Salários' },
      { value: '98', label: 'Pagamentos Diversos' }
    ],
    entryType: [{ value: '05', label: 'Crédito em Conta Poupança' }]
  },
  '15': {
    defaults: { serviceType: '30', entryType: '71' },
    serviceType: [{ value: '30', label: 'Pagamento Salários' }],
    entryType: [{ value: '71', label: 'Depósito Judicial em Conta Corrente' }]
  },
  '16': {
    defaults: { serviceType: '20', entryType: '10' },
    serviceType: [{ value: '20', label: 'Pagamento Fornecedor' }],
    entryType: [{ value: '10', label: 'OP à disposição' }]
  },
  '20': {
    defaults: { serviceType: '20' },
    serviceType: [
      { value: '20', label: 'Pagamento Fornecedor' },
      { value: '98', label: 'Pagamentos Diversos' }
    ],
    entryType: [
      { value: '30', label: 'Liquidação de Títulos do Próprio Banco' },
      { value: '31', label: 'Pagamento de Títulos de Outros Bancos' }
    ]
  },
  '21': {
    defaults: { serviceType: '20', entryType: '47' },
    serviceType: [
      { value: '20', label: 'Pagamento Fornecedor' },
      { value: '98', label: 'Pagamentos Diversos' }
    ],
    entryType: [{ value: '47', label: 'PIX QR-CODE' }]
  },
  '30': {
    defaults: { serviceType: '22', entryType: '11' },
    serviceType: [
      { value: '22', label: 'Pagamento de Contas, Tributos e Impostos' },
      { value: '20', label: 'Pagamento Fornecedor' },
      { value: '98', label: 'Pagamentos Diversos' }
    ],
    entryType: [{ value: '11', label: 'Pagamento de Contas e Tributos com Código de Barras' }]
  },
  '31': {
    defaults: { serviceType: '22', entryType: '11' },
    serviceType: [{ value: '22', label: 'Pagamento de Contas, Tributos e Impostos' }],
    entryType: [
      { value: '11', label: 'Pagamento de Contas e Tributos com Código de Barras' },
      { value: '19', label: 'Tributo - IPTU - ISS - Outros Tributos Municipais' },
      { value: '91', label: 'GNRE e Tributos com Código de Barras' }
    ]
  },
  '40': {
    defaults: { serviceType: '22', entryType: '16' },
    serviceType: [{ value: '22', label: 'Pagamento de Contas, Tributos e Impostos' }],
    entryType: [{ value: '16', label: 'Tributo - DARF Normal' }]
  },
  '41': {
    defaults: { serviceType: '22', entryType: '18' },
    serviceType: [{ value: '22', label: 'Pagamento de Contas, Tributos e Impostos' }],
    entryType: [{ value: '18', label: 'Tributo - DARF Simples' }]
  },
  '42': {
    defaults: { serviceType: '22', entryType: '17' },
    serviceType: [{ value: '22', label: 'Pagamento de Contas, Tributos e Impostos' }],
    entryType: [{ value: '17', label: 'Tributo - GPS (Guia da Previdência Social)' }]
  },
  '43': {
    defaults: { serviceType: '22', entryType: '11' },
    serviceType: [{ value: '22', label: 'Pagamento de Contas, Tributos e Impostos' }],
    entryType: [
      { value: '11', label: 'Pagamento de Contas e Tributos com Código de Barras' },
      { value: '35', label: 'FGTS' }
    ]
  },
  '44': {
    defaults: { serviceType: '22', entryType: '22' },
    serviceType: [{ value: '22', label: 'Pagamento de Contas, Tributos e Impostos' }],
    entryType: [
      { value: '22', label: 'Tributo - GARE-SP ICMS' },
      { value: '23', label: 'Tributo - GARE-SP DR' },
      { value: '24', label: 'Tributo - GARE-SP ITCMD' }
    ]
  },
  '45': {
    defaults: { serviceType: '22', entryType: '25' },
    serviceType: [{ value: '22', label: 'Pagamento de Contas, Tributos e Impostos' }],
    entryType: [{ value: '25', label: 'Tributo - IPVA' }]
  },
  '46': {
    defaults: { serviceType: '22', entryType: '27' },
    serviceType: [{ value: '22', label: 'Pagamento de Contas, Tributos e Impostos' }],
    entryType: [{ value: '27', label: 'Tributo - DPVAT' }]
  },
  '47': {
    defaults: { serviceType: '22', entryType: '26' },
    serviceType: [{ value: '22', label: 'Pagamento de Contas, Tributos e Impostos' }],
    entryType: [{ value: '26', label: 'Tributo - Licenciamento' }]
  },
  '48': {
    defaults: { serviceType: '22', entryType: '21' },
    serviceType: [{ value: '22', label: 'Pagamento de Contas, Tributos e Impostos' }],
    entryType: [{ value: '21', label: 'Tributo - DARJ' }]
  },
  '50': {
    defaults: { serviceType: '00', entryType: '00' },
    serviceType: [{ value: '00', label: 'Não se aplica' }],
    entryType: [{ value: '00', label: 'Não se aplica' }]
  },
  '91': {
    defaults: { serviceType: '20' },
    serviceType: [{ value: '20', label: 'Pagamento Fornecedor' }],
    entryType: [
      { value: '30', label: 'Liquidação de Títulos do Próprio Banco' },
      { value: '31', label: 'Pagamento de Títulos de Outros Bancos' }
    ]
  }
};
