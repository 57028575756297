import { all } from 'redux-saga/effects';
import auth from '~/store/modules/auth/sagas';
import account from '~/store/modules/account/sagas';
import header from '~/store/modules/header/sagas';
import profile from '~/store/modules/profile/sagas';
import user from '~/store/modules/user/sagas';
import company from '~/store/modules/company/sagas';
import bankAccount from '~/store/modules/bank-account/sagas';
import bill from '~/store/modules/bill/sagas';
import billClient from '~/store/modules/bill-client/sagas';
import prepareBills from '~/store/modules/prepare-bills/sagas';
import setting from '~/store/modules/setting/sagas';
import shipping from '~/store/modules/shipping/sagas';
import response from '~/store/modules/response/sagas';
import businessPartner from '~/store/modules/business-partner/sagas';
import representative from '~/store/modules/representative/sagas';
import dashboard from '~/store/modules/dashboard/sagas';
import digitalCharge from '~/store/modules/digital-charge/sagas';
import paymentConciliation from '~/store/modules/payment-conciliation/sagas';
import paymentOrder from '~/store/modules/payment-order/sagas';
import bankStatement from '~/store/modules/bank-statement/sagas';
import bankContract from '~/store/modules/bank-contract/sagas';
import emailBatch from '~/store/modules/email-batch/sagas';
import parser from '~/store/modules/parser/sagas';
import preparePaymentOrder from '~/store/modules/prepare-payment-order/sagas';
import paymentApproval from '~/store/modules/payment-approval/sagas';
import emailTemplate from '~/store/modules/email-template/sagas';
import emailSetting from '~/store/modules/email-setting/sagas';
import webhookBill from '~/store/modules/webhook-bill/sagas';
import webhookPaymentOrder from '~/store/modules/webhook-payment-order/sagas';
import bankStatementClassifications from '~/store/modules/bank-statement-classifications/sagas';
import prepareBankStatements from '~/store/modules/prepare-bank-statement/sagas';
import creditsReceiveds from '~/store/modules/credits-receiveds/sagas';
import reportRequest from '~/store/modules/report-request/sagas';
import ediFile from '~/store/modules/edi-file/sagas';
import paymentEmailBatch from '~/store/modules/payment-email-batch/sagas';
import paymentApprovalsPending from '~/store/modules/payment-approvals-pending/sagas';

export default function* rootSaga() {
  return yield all([
    auth,
    account,
    header,
    profile,
    user,
    company,
    bankAccount,
    bill,
    billClient,
    prepareBills,
    setting,
    response,
    shipping,
    businessPartner,
    representative,
    dashboard,
    digitalCharge,
    paymentOrder,
    paymentConciliation,
    bankStatement,
    bankContract,
    emailBatch,
    parser,
    preparePaymentOrder,
    paymentApproval,
    emailTemplate,
    emailSetting,
    webhookBill,
    webhookPaymentOrder,
    bankStatementClassifications,
    prepareBankStatements,
    creditsReceiveds,
    reportRequest,
    ediFile,
    paymentEmailBatch,
    paymentApprovalsPending
  ]);
}
