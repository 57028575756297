import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  max-height: ${props => props.maxHeight};
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 10px;
  position: relative;

  @media (max-width: 425px) {
    max-height: 100%;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 0 auto;
  width: 100%;
  height: calc(100%);
  padding: 10px;
`;

export const TableContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 300px;
`;
