import React from 'react';
import Fieldset from '~/components/fieldset';
import { Autocomplete, Select, InputGroup, InputDate, InputMask } from '~/components/form';
import { MASK_CNPJ, MASK_CPF } from '~constants';
import { FlexRow, FlexCol } from '~/components/layout';
import { Row } from '~/components/layout';
import { isBefore, isAfter } from 'date-fns';
import { FilterContainer } from '~/components/crud/styles';
import _ from 'lodash';
import { formats } from '~/helper';

export default function FormFilter({ values, setFieldValue, onListCompanies }) {
  return (
    <FilterContainer>
      <Autocomplete
        name="company"
        keyField="id"
        label="Empresa"
        clearable={true}
        value={_.get(values, 'company')}
        valueFormat={row => `${formats.cnpj_cpf(row.identity)} - ${row.name || row.tradeName}`}
        loadData={onListCompanies}
        emptyText={'Pesquise uma empresa'}
        tipText={'Digite... '}
        loadingText={'Carregando...'}
        notFoundText={'Não encontrada'}
        />

      <InputGroup
        type="text"
        name="documentNumber"
        label="Número documento"
        maxLength={50} />

      <Fieldset label="Beneficiário">
        <FlexRow>
          <FlexCol flex="0 0 150px">
            <Select
              name="beneficiaryIdentityType"
              label="Tipo"
              onChange={evt => {
                setFieldValue('beneficiaryIdentityType', evt.target.value);
                setFieldValue('beneficiaryIdentity', '');
              }}
              options={{
                values: [
                  { value: 'company', label: 'CNPJ' },
                  { value: 'person', label: 'CPF' }
                ]
              }}
            />
          </FlexCol>

          <FlexCol flex="1">
            {_.get(values, 'beneficiaryIdentityType') !== 'person' && (
              <InputMask
                mask={MASK_CNPJ}
                name="beneficiaryIdentity"
                label={'CNPJ'}
              />
            )}

            {_.get(values, 'beneficiaryIdentityType') === 'person' && (
              <InputMask
                mask={MASK_CPF}
                name="beneficiaryIdentity"
                label={'CPF'}
              />
            )}
          </FlexCol>
        </FlexRow>

        <InputGroup
          type="text"
          name="beneficiaryName"
          label="Nome"
          maxLength={100} />
      </Fieldset>

      <Fieldset label="Data de Vencimento">
        <Row span={2}>
          <InputDate
            name="dueDateStart"
            label="De"
            onChange={value => {
              setFieldValue('dueDateStart', value)
              if (values.dueDateEnd && isAfter(value, values.dueDateEnd)) {
                setFieldValue('dueDateStart', values.dueDateEnd);
                setFieldValue('dueDateEnd', value)
              }
            }}
          />

          <InputDate
            name="dueDateEnd"
            label="Até"
            onChange={value => {
              setFieldValue('dueDateEnd', value)
              if (values.dueDateStart && isBefore(value, values.dueDateStart)) {
                setFieldValue('dueDateStart', value);
                setFieldValue('dueDateEnd', values.dueDateStart);
              }
            }}
          />
        </Row>
      </Fieldset>

      <Fieldset label="Data Limite">
        <Row span={2}>
          <InputDate
            name="limitDateStart"
            label="De"
            onChange={value => {
              setFieldValue('limitDateStart', value)
              if (values.limitDateEnd && isAfter(value, values.limitDateEnd)) {
                setFieldValue('limitDateStart', values.limitDateEnd);
                setFieldValue('limitDateEnd', value)
              }
            }}
          />

          <InputDate
            name="limitDateEnd"
            label="Até"
            onChange={value => {
              setFieldValue('limitDateEnd', value)
              if (values.limitDateStart && isBefore(value, values.limitDateStart)) {
                setFieldValue('limitDateStart', value);
                setFieldValue('limitDateEnd', values.limitDateStart);
              }
            }}
          />
        </Row>
      </Fieldset>
    </FilterContainer>
  );
}
