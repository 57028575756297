import React from 'react';
import * as Yup from 'yup';
import _ from 'lodash';
import { Row } from '~/components/layout';
import Fieldset from '~/components/fieldset';
import { InputDate, Autocomplete, Select } from '~/components/form';
import { FormContainer } from '~/pages/users/styles';
import { isBefore, isAfter } from 'date-fns';

export const ReportRequestForm = ({ errors, status, touched, values, isValid, previewMode, onListReportTypes, setFieldValue }) => {
  return (
    <FormContainer>
      <Autocomplete
        name="reportType"
        keyField="id"
        label="Tipo do Relatório"
        value={_.get(values, 'reportType')}
        disabled={previewMode}
        valueFormat={row => `${row.name}`}
        loadData={onListReportTypes}
        emptyText={'Pesquise um tipo de relatório'}
        tipText={'Digite... '}
        loadingText={'Carregando...'}
        notFoundText={'Não encontrado'}
      />

      <Select
        name="dateType"
        label="Tipo de Data"
        disabled={previewMode}
        options={{
          values: [
            {value: 'createdAt', label: 'Criação do Dado'},
            {value: 'updatedAt', label: 'Última Atualização no Dado'},
          ]
        }}
      />

      <Fieldset label={values.dateType === 'updatedAt' ? 'Atualizado em' : 'Cadastrado em'}>
        <Row span={2}>
          <InputDate
            name="dateFrom"
            label="De"
            disabled={previewMode}
            onChange={value => {
              setFieldValue('dateFrom', value);
              if (values.dateTo && isAfter(value, values.dateTo)) {
                setFieldValue('dateFrom', values.dateTo);
                setFieldValue('dateTo', value);
              }
            }}
          />

          <InputDate
            name="dateTo"
            label="Até"
            disabled={previewMode}
            onChange={value => {
              setFieldValue('dateTo', value);
              if (values.dateFrom && isBefore(value, values.dateFrom)) {
                setFieldValue('dateFrom', value);
                setFieldValue('dateTo', values.dateFrom);
              }
            }}
          />
        </Row>
      </Fieldset>
      {status && status.msg && <div>{status.msg}</div>}
    </FormContainer>
  );
};

export const ReportRequestSchema = Yup.object().shape({
  reportType: Yup.mixed().test('', 'Informe o Tipo do Relatório', function(val) {
    return !_.isEmpty(val);
  }),
  dateFrom: Yup.string().typeError('Informe a data de início').required('Informe a data de início'),
  dateTo: Yup.string().typeError('Informe a data de término').required('Informe a data de término')
});
