import { all, takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { Creators as EmailTemplatesActions, Types as EmailTemplatesTypes } from '~/store/modules/email-template';
import api from '~/services/api';
import { getError } from '~/helper';

export function* createOrUpdate({ data, actions }) {
  try {
    let editing = !!((data && data.id)),
      method = editing ? 'put' : 'post',
      path = editing ? 'update' : 'create';

    yield call(api[method], `email-templates/${path}`, data);

    toast.success(`Template de e-mail ${editing ? 'atualizado' : 'criado'} com sucesso`);
    actions.closeForm && actions.closeForm();
    actions.reloadList && actions.reloadList();
  } catch (error) {
    showError(error);
  }
}

export function* list({ params }) {
  try {
    const response = yield call(api.post, 'email-templates/list', params);
    yield put(EmailTemplatesActions.listSuccess(response.data));
  } catch (error) {
    showError(error);
  }
}

export function* load({ data }) {
  try {
    if (!data.id || data.id === 'new') {
      yield put(EmailTemplatesActions.loadSuccess({ active: true, type: 'text' }));
    } else {
      const response = yield call(api.get, 'email-templates/load', { params: { id: data.id } });
      yield put(EmailTemplatesActions.loadSuccess(response.data));
    }
  } catch (error) {
    showError(error);
  }
};

export function* remove({ data, actions }) {
  try {
    let params = _.pick(data, 'id');
    yield call(api.delete, `email-templates/remove`, { params });

    toast.success(`Template removido com sucesso`);
    actions.closeForm && actions.closeForm();
    actions.reloadList && actions.reloadList();
  } catch (error) {
    showError(error);
  }
};

export function showError(error) {
  const msg = getError(error);
  toast.error(msg);
};

export function* listBillTypes({ term, callback }) {
  try {
    const response = yield call(api.get, 'email-templates/list-bill-types', { params: { term } });
    yield call(callback, response.data);
  } catch (error) {}
}

export default all([
  takeLatest(EmailTemplatesTypes.LIST, list),
  takeLatest(EmailTemplatesTypes.LOAD, load),
  takeLatest(EmailTemplatesTypes.REMOVE, remove),
  takeLatest(EmailTemplatesTypes.CREATE_OR_UPDATE, createOrUpdate),
  takeLatest(EmailTemplatesTypes.LIST_BILL_TYPES, listBillTypes),
]);
