import styled, { css } from 'styled-components';
import { MdClose, MdFullscreen, MdFullscreenExit } from 'react-icons/md';
import { primary } from '~/components/mixins/color';

const fullscreen = css`
  width: 100%;
  height: 100%;
  margin: 0;
  border-radius: 0;
`;

const controlsCss = css`
  position: absolute;
  cursor: pointer;
  z-index: 3;
  padding: 0.1em;
  transition: 0.3s;
  width: 1.6em;
  :hover {
    transform: scale(1.07);
  }
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${props => (props.headLess ? primary.hex() : 'rgba(0, 0, 0, 0.6)')};
  display: grid;
  visibility: ${props => (props.isOpen ? 'visible' : 'hidden')};
  opacity: ${props => (props.isOpen ? '1' : '0')};
  transition: 0.1s;
  z-index: 1010;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
  color: rgb(0, 0, 0);
  font-size: 18px;
  font-weight: 500;
  padding-left: 10px;
  height: ${props => (props.children ? '40px' : '0px')};
  visibility: ${props => (props.children ? 'visible' : 'hidden')};
  div {
    width: calc(100% - ${props => (props.hideClose ? '5px' : '40px')});
    max-width: calc(100% - ${props => (props.hideClose ? '5px' : '40px')});
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const Container = styled.div`
  box-sizing: border-box;
  align-self: center;
  justify-self: center;
  background: ${props => props.background || '#fff'};
  width: ${props => (props.width ? props.width : '80vw')};
  height: ${props => (props.height ? props.height : '80vh')};
  position: relative;
  overflow: hidden;
  border-radius: 0.2em;
  transition: 0.3s;
  box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
  margin: calc(0.5em + 2vw);
  ${props => props.fullscreen && fullscreen}

  @media (max-width: 959px) {
    width: ${
      props => {
        if (props.fullscreen) return "100vw"
        return (props.width <= 768 ? props.width : "90vw")
      }
    };
  }

  @media (max-width: 425px) {
    width: ${
      props => {
        if (props.fullscreen) return "100vw"
        return (props.width <= 425 ? props.width : "90vw")
      }
    };
  }
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  overflow-y: auto;
  padding: ${(props) => props.noBodyPadding ? '0' : '10px'};
  height: calc(100% - ${(props) => (props.hideClose && !props.titleText) ? '60px' : '100px'});
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-right: 10px;
  height: ${props => (props.children ? "60px" : "0px")};
  visibility: ${props => (props.children ? "visible" : "hidden")};
  width: 100%;
  position: absolute;
  bottom: 0;

  .left {
    display: flex;
    flex: 1;
    justify-content: flex-start;
  }

  .right {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
`;

const Close = styled(MdClose)`
  ${controlsCss};
  border-radius: 0.4em;
  top: 0.7em;
  right: 0.5em;
  width: 20px;
  height: 20px;
`;

const FullscreenToggle = styled(MdFullscreen).attrs(props => ({
  as: props.fullscreen === "true" && MdFullscreenExit
}))`
  ${controlsCss};
  border-radius: 0.4em;
  top: 0.7em;
  right: 2em;
  width: 20px;
  height: 20px;
`;

export {
  Background,
  Title,
  Container,
  Content,
  Footer,
  Close,
  FullscreenToggle
};
