import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MdCheck, MdClear, MdErrorOutline } from 'react-icons/md';
import { IoWarningOutline } from 'react-icons/io5';
import { GrDocumentPdf } from 'react-icons/gr';
import Crud from '~/components/crud';
import { Creators as BillActions } from '~/store/modules/bill-client';
import { Creators as HeaderActions } from '~/store/modules/header';
import _ from 'lodash';
import queryString from 'query-string';
import { formats } from '~/helper';
import { lastDayOfMonth } from 'date-fns';
import { SubtitleItem, Subtitle } from '~/components/datatable/subtitle';
import CellStatus from '~/components/datatable/cell-status';
import { CellIcon } from '~/pages/bills/styles';
import { Container, CompanyContainer } from '~/pages/bills-client/styles';
import FormFilter from '~/pages/bills-client/filter';
import BillForm from '~/pages/bills-client/form';
import { statusColors, statusLabels } from '~/pages/bills';
import CellBank from '~/components/datatable/cell-bank';

const clonedStatusLabels = _.omit(statusLabels, 'pending', 'rejected');

const columns = [
  {
    name: ' ',
    selector: 'status',
    width: '10px',
    cell: row => {
      let status = row.registered && row.status === 'open' ? 'registered' : row.status;
      return <CellStatus title={statusLabels[status]} color={statusColors[status]} />;
    }
  },
  {
    name: 'Tipo',
    selector: 'billType',
    width: '100px',
    hide: 'lg',
    format: row => _.get(row, 'billType.name') || ''
  },
  {
    name: 'Documento',
    selector: 'documentNumber',
    width: '100px',
    hide: 'lg',
    format: row => _.get(row, 'documentNumber')
  },
  {
    name: 'Empresa / Pagador',
    selector: 'company',
    wrap: true,
    cell: row => {
      const company = _.get(row, 'bankContract.bankAccount.company') || {},
        hasError = !_.isEmpty(_.get(row, 'generationMessages.errors')),
        hasWarning = !_.isEmpty(_.get(row, 'generationMessages.warnings')),
        hasPdf = _.get(row, 'billData.generated'),
        hasIcon = hasError || hasWarning || hasPdf,
        bp = _.get(row, 'businessPartner') || {};
        return (
          <CompanyContainer hasIcon={hasIcon}>
            {hasIcon && (
              <div className="company-icons">
                {hasError && (
                  <CellIcon size={20} color="#ff0000">
                    <MdErrorOutline />
                  </CellIcon>
                )}
                {hasWarning && (
                  <CellIcon size={20} color="#efd600">
                    <IoWarningOutline />
                  </CellIcon>
                )}
                {hasPdf && (
                  <CellIcon size={18} color="#29A8E0">
                    <GrDocumentPdf />
                  </CellIcon>
                )}
              </div>
            )}
            <div className="company-text">
              <span>
                {formats.cnpj_cpf(_.get(company, 'identity', '')) +
                  ' ' +
                  (_.get(company, 'name', '') || _.get(company, 'tradeName', ''))}
              </span>
              <span>
                {formats.cnpj_cpf(_.get(bp, 'identity', '')) +
                  ' ' +
                  (_.get(bp, 'name', '') || _.get(bp, 'tradeName', ''))}
              </span>
            </div>
          </CompanyContainer>
        )
    }
  },
  {
    name: 'Nosso número',
    selector: 'billData',
    width: '100px',
    right: true,
    hide: 'lg',
    format: row => _.get(row, 'billData.ourNumber')
  },
  {
    name: 'Valor',
    selector: 'value',
    width: '100px',
    right: true,
    format: row => {
      return `${formats.currency(row.value, 'pt-BR', 'BRL')}`;
    }
  },
  {
    name: 'Cadastrado em',
    selector: 'createdAt',
    center: true,
    width: '100px',
    hide: 'lg',
    format: row => formats.dateTimeZone(_.get(row, 'createdAt'), 'dd/MM/yyyy')
  },
  {
    name: 'Vencimento',
    selector: 'dueDate',
    center: true,
    width: '100px',
    format: row => formats.dateTimeZone(_.get(row, 'dueDate'), 'dd/MM/yyyy')
  },
  {
    name: 'Pagamento',
    selector: 'payDate',
    center: true,
    width: '100px',
    format: row => formats.dateTimeZone(_.get(row, 'payDate'), 'dd/MM/yyyy') || '--'
  },
  {
    name: 'Agência / Conta',
    selector: 'bankContract',
    width: '140px',
    left: true,
    cell: (row) => <CellBank bankAccount={_.get(row, 'bankContract.bankAccount')} />
  },
  {
    name: 'Registrado ?',
    selector: 'lastResponseOccurrence',
    center: true,
    hide: 'lg',
    width: '80px',
    format: row => (_.get(row, 'registered') ? 'Sim' : 'Não')
  },
  {
    name: ' ',
    selector: 'id',
    width: '5px',
    center: true,
    hide: 'lg',
    cell: (row) => <span>&nbsp;</span>
  }
];

const customStyles = {
  rows: {
    denseStyle: {
      minHeight: '58px'
    }
  }
};

export default function Bills({ history, match, location, acls }) {
  const dispatch = useDispatch();
  const state = useSelector(state => state.billClient);
  const headerState = useSelector(state => state.header);
  const [openForm, setOpenForm] = useState(false);
  const showFilter = headerState.filter.visible;

  useEffect(() => {
    const config = {
      loading: state.loading,
      useFilter: true,
      filter: { ...headerState.filter, visible: false }
    };
    dispatch(HeaderActions.configure(config));
    // eslint-disable-next-line
  }, [state, dispatch]);

  useEffect(() => {
    let params = queryString.parse(location.search) || {},
      { day, month, year, typeDate } = params,
      fieldStartDate = 'dueStartDate',
      fieldEndDate = 'dueEndDate';

    if (month && year) {
      const date = !month || !year ? new Date() : new Date(year, month, day || 1);
      if (typeDate === 'payDate') {
        fieldStartDate = 'payStartDate';
        fieldEndDate = 'payEndDate';
      }

      params[fieldStartDate] = formats.date(date);
      const endDate = day ? date : lastDayOfMonth(date);
      params[fieldEndDate] = formats.date(endDate);
    } else {
      params = {};
    }
    handleFilter(params);
    // eslint-disable-next-line
  }, [location]);

  function handlePageChange(offset, limit) {
    dispatch(BillActions.list({ ...headerState.filter.data, offset, limit }));
  }

  function handleLoad(data) {
    setOpenForm(true);
    dispatch(BillActions.load(data.id));
  }

  function handleHideFilter() {
    dispatch(HeaderActions.hideFilter());
    setOpenForm(false);
  }

  function handleFilter(data) {
    handleHideFilter();
    dispatch(HeaderActions.callFilter({ ...data, offset: 0 }, BillActions.list));
  }

  async function handleDownloadFile(row, file) {
    let data = { id: row.id, filename: file.name };
    dispatch(BillActions.downloadFile(data));
  }

  function handleListBusinessPartners(term, callback) {
    dispatch(BillActions.listBusinessPartners(term, callback))
  }

  return (
    <Container>
      {!showFilter && !openForm && (
        <Subtitle bottom={25} left={20}>
          <span>Legenda:</span>
          {_.map(clonedStatusLabels, (label, status) => (
            <SubtitleItem key={status} color={statusColors[status]}>
              {label}
            </SubtitleItem>
          ))}
        </Subtitle>
      )}

      <Crud
        useOpenForm={true}
        openForm={openForm || showFilter}
        hideAdd={true}
        columns={columns}
        emptyText="Nenhuma fatura encontrada"
        formTitle={data => (showFilter ? `Filtro` : `Fatura (${formats.currency(_.get(data, 'value'))} - ${formats.dateTimeZone(_.get(data, 'dueDate'))})`)}
        data={state.data}
        onCloseFilter={handleHideFilter}
        tableLoading={state.loading}
        formLoading={state.formLoading}
        onChangePage={handlePageChange}
        onRowClicked={handleLoad}
        formOptions={{
          initialValues: showFilter ? headerState.filter.data : state.model
        }}
        renderForm={args => (showFilter ? 
          <FormFilter {...args} onListBusinessPartners={handleListBusinessPartners} /> 
          : <BillForm {...args} onDownloadFile={handleDownloadFile} loading={state.formLoading} />)}
        actions={
          showFilter
            ? [
                {
                  label: 'Limpar Filtro',
                  icon: MdClear,
                  action: () => handleFilter({})
                },
                {
                  label: 'Aplicar Filtro',
                  icon: MdCheck,
                  action: data => handleFilter(data)
                }
              ]
            : []
        }
        dataTableOptions={{
          customStyles,
          ignoreRowClicked: true,
          selectableRowsHighlight: false,
        }}
      />
    </Container>
  );
}
