import React, { useState } from 'react';
import _ from 'lodash';
import DataTable from '~/components/datatable';
import { TableContainer } from '~/pages/accounts/styles';
import { formats } from '~/helper';

const columns = [
    {
      name: 'Nome',
      selector: 'name',
      format: (row) => _.get(row, 'name')
    },
    {
      name: 'E-mail',
      selector: 'email',
      format: (row) => _.get(row, 'email')
    },
    {
      name: 'Último acesso',
      selector: 'lastAccess',
      width: '140px',
      format: (row) => formats.dateTimeZone(_.get(row, 'lastAccess.lastAccess.date'), 'dd/MM/yyyy HH:mm')
    }
  ];

const getRows = (array = [], offset, pageSize) => {
  return array.slice(offset, offset + pageSize);
};

export const TabUsers = ({ values }) => {
  const rows = _.get(values, 'users') || [];
  const count = _.size(rows);
  const pageSize = 50;
  const [ offset, setOffset ] = useState(0);

  const onChangePage = (offset) => {
    setOffset(offset);
  };

  return (
    <TableContainer>
      <DataTable
        emptyText="Nenhuma empresa vinculada"
        pageSize={pageSize}
        onChangePage={onChangePage}
        hideUpdateButton={true}
        data={{ rows: getRows(rows, offset, pageSize), offset, count, limit: pageSize }}
        columns={columns}
        extraOptions={{
          ignoreRowClicked: true,
          selectableRows: false,
          selectableRowsHighlight: false
        }}
        />
    </TableContainer>
  )
};
