import React, { useState } from 'react';
import _ from 'lodash';
import DataTable from '~/components/datatable';
import { TableContainer } from '~/pages/accounts/styles';
import { formats } from 'helper';

const columns = [
    {
      name: 'CNPJ/CPF',
      width: '150px',
      selector: 'identity',
      format: (row) => formats.cnpj_cpf(_.get(row, 'identity'))
    },
    {
      name: 'Nome',
      selector: 'name',
      format: (row) => _.get(row, 'name') || _.get(row, 'tradeName')
    }
  ];

const getRows = (array = [], offset, pageSize) => {
  return array.slice(offset, offset + pageSize);
};

export const TabCompanies = ({ values }) => {
  const rows = _.get(values, 'companies') || [];
  const count = _.size(rows);
  const pageSize = 50;
  const [ offset, setOffset ] = useState(0);

  const onChangePage = (offset) => {
    setOffset(offset);
  };

  return (
    <TableContainer>
      <DataTable
        emptyText="Nenhuma empresa vinculada"
        pageSize={pageSize}
        hideUpdateButton={true}
        onChangePage={onChangePage}
        data={{ rows: getRows(rows, offset, pageSize), offset, count, limit: pageSize }}
        columns={columns}
        extraOptions={{
          ignoreRowClicked: true,
          selectableRows: false,
          selectableRowsHighlight: false
        }}
        />
    </TableContainer>
  )
};
