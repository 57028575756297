import React from 'react';
import { InputGroup, InputDate } from '~/components/form';
import { FilterContainer } from '~/components/crud/styles';
import { Row } from '~/components/layout';
import Fieldset from '~/components/fieldset';
import { isBefore, isAfter } from 'date-fns';

export default function FormFilter({ values, setFieldValue }) {
  return (
    <FilterContainer>
      <InputGroup
        type="text"
        name="vatNumber"
        label="CNPJ/CPF"
        maxLength={18}
        />

      <InputGroup
        type="text"
        name="company"
        label="Empresa"
        maxLength={40}
        />

      <InputGroup
        type="text"
        name="filename"
        label="Nome do Arquivo"
        maxLength={100}
        />

      <InputGroup
        type="text"
        name="account"
        label="Nr.Conta"
        maxLength={20}
        />

      <Fieldset label="Importado em">
        <Row span={2}>
          <InputDate
            name="createdAtStart"
            label="De"
            onChange={value => {
              setFieldValue('createdAtStart', value);
              if (values.createdAtEnd && isAfter(value, values.createdAtEnd)) {
                setFieldValue('createdAtStart', values.createdAtEnd);
                setFieldValue('createdAtEnd', value);
              }
            }}
          />

          <InputDate
            name="createdAtEnd"
            label="Até"
            onChange={value => {
              setFieldValue('createdAtEnd', value);
              if (values.createdAtStart && isBefore(value, values.createdAtStart)) {
                setFieldValue('createdAtStart', value);
                setFieldValue('createdAtEnd', values.createdAtStart);
              }
            }}
          />
        </Row>
      </Fieldset>
    </FilterContainer>
  );
}
