import React from 'react';
import _ from 'lodash';
import { Select, Autocomplete, InputDate } from '~/components/form';
import { FilterContainer } from '~/components/crud/styles';
import { Row } from '~/components/layout';
import Fieldset from '~/components/fieldset';
import { formatBankAccountSimple, formatBankAccount } from '~/pages/bank-accounts/form';
import { isBefore, isAfter } from 'date-fns';

export default function FormFilter({ values, onListBankAccounts, setFieldValue }) {
  return (
    <FilterContainer>
      <Select
        name="layoutType"
        label="Layout do arquivo"
        options={{ values: [
            { "value": "", "label": "Todos"},
            { "value": "cnab240", "label": "CNAB240"},
            { "value": "ofx", "label": "OFX"}
          ] }}
          />

      <Autocomplete
        name="bankAccount"
        keyField='id'
        clearable={true}
        label="Conta bancária"
        value={_.get(values, 'bankAccount')}
        valueFormat={formatBankAccountSimple}
        optionsFormat={(row) => formatBankAccount(row, 'Empresa')}
        loadData={onListBankAccounts}
        emptyText={'Pesquise uma conta bancária'}
        tipText={'Digite... '}
        loadingText={'Carregando...'}
        notFoundText={'Não encontrada'}
        />

      <Fieldset label="Importado em">
        <Row span={2}>
          <InputDate
            name="createdAtStart"
            label="De"
            onChange={value => {
              setFieldValue('createdAtStart', value);
              if (values.createdAtEnd && isAfter(value, values.createdAtEnd)) {
                setFieldValue('createdAtStart', values.createdAtEnd);
                setFieldValue('createdAtEnd', value);
              }
            }}
          />

          <InputDate
            name="createdAtEnd"
            label="Até"
            onChange={value => {
              setFieldValue('createdAtEnd', value);
              if (values.createdAtStart && isBefore(value, values.createdAtStart)) {
                setFieldValue('createdAtStart', value);
                setFieldValue('createdAtEnd', values.createdAtStart);
              }
            }}
          />
        </Row>
      </Fieldset>
    </FilterContainer>
  );
}
