import { all, put, call, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import _ from 'lodash';
import api from '~/services/api';
import { getError } from  '~/helper';
import { Creators as BankAccountActions, Types as BankAccountTypes } from '~/store/modules/bank-account';

export function showError(error) {
  const msg =  getError(error);
  toast.error(msg);
};

export function* createOrUpdate({ data, actions }) {
  try {
    let editing = !!((data && data.id)),
      method = editing ? 'put' : 'post',
      path = editing ? 'update' : 'create';

    yield call(api[method], `bank-accounts/${path}`, data);

    toast.success(`Conta bancária ${editing ? 'atualizada' : 'criada'} com sucesso`);
    actions.closeForm && actions.closeForm();
    actions.reloadList && actions.reloadList();
  } catch (error) {
    showError(error);
  }
};

export function* remove({ data, actions }) {
  try {
    let params = _.pick(data, 'id');
    yield call(api.delete, `bank-accounts/remove`, { params });

    toast.success(`Conta bancária removida com sucesso`);
    actions.closeForm && actions.closeForm();
    actions.reloadList && actions.reloadList();
  } catch (error) {
    showError(error);
  }
};

export function* list({ params }) {
  try {
    const response = yield call(api.post, 'bank-accounts/list', params);
    yield put(BankAccountActions.listSuccess(response.data));
  } catch (error) {
    showError(error);
  }
};

export function* load({ data }) {
  try {
    if (_.isEmpty(data)) {
      yield put(BankAccountActions.loadSuccess({ bankAccount: { active: true } }));
      return;
    }
    const response = yield call(api.get, 'bank-accounts/load', { params: { id: data.id } });
    yield put(BankAccountActions.loadSuccess(response.data));
  } catch (error) {
    showError(error);
  }
};

export function* listBanks({ term, callback }) {
  try {
    const response = yield call(api.get, 'bank-accounts/list-banks', { params: { term } });
    yield call(callback, response.data);
  } catch (error) {
  }
};

export function* listCompanies({ term, callback }) {
  try {
    const response = yield call(api.get, 'bank-accounts/list-companies', { params: { term } });
    yield call(callback, response.data);
  } catch (error) {
  }
};

export function* listGroups({ term, callback }) {
  try {
    const response = yield call(api.get, 'bank-accounts/list-groups', { params: { term } });
    yield call(callback, response.data);
  } catch (error) {
  }
};

export default all([
  takeLatest(BankAccountTypes.LIST, list),
  takeLatest(BankAccountTypes.LOAD, load),
  takeLatest(BankAccountTypes.REMOVE, remove),
  takeLatest(BankAccountTypes.CREATE_OR_UPDATE, createOrUpdate),
  takeLatest(BankAccountTypes.LIST_BANKS, listBanks),
  takeLatest(BankAccountTypes.LIST_COMPANIES, listCompanies),
  takeLatest(BankAccountTypes.LIST_GROUPS, listGroups),
])
