import React, { useRef } from 'react';
import * as Yup from 'yup';
import _ from 'lodash';
import { FormContainer, SubjectDefinition, HtmlDefinition } from '~/pages/email-templates/styles';
import { RichEditor, InputGroup, Select, TextArea, Autocomplete } from '~/components/form';
import { FlexCol, FlexRow } from '~/components/layout';
import { slipVariables, paymentVariables } from '~/constants';
import { useDispatch } from 'react-redux';
import { Creators as EmailTemplateActions } from '~/store/modules/email-template';

export const types = {
  text: 'Texto',
  html: 'HTML',
};
export const typesPayment = types;
export const typesCharge = {...types, whatsapp: 'WhatsApp'};

const defaultText = `Olá <%=cliente.nome%> ! Segue o boleto *<%=boleto.linhaDigitavel%> * com vencimento em *<%=boleto.dataVencimento%> * no valor de * <%=boleto.valor%>*.
<%=boleto.link%>`;

export const EmailTemplatesForm = ({ setFieldValue, errors, touched, values, previewMode }) => {
  const dispatch = useDispatch();
  const inputSubjectRef = useRef(null);
  const inputHtmlRef = useRef(null);

  async function onListBillTypes(term, callback) {
    dispatch(EmailTemplateActions.listBillTypes(term, callback));
  }

  return (
    <FormContainer>
      <FlexRow>
        <FlexCol flex="0 0 150px">
          <Select
            name="movementType"
            label="Movimento"
            disabled={previewMode}
            value={_.get(values, 'movementType') || ''}
            hasError={errors.movementType && touched.movementType}
            options={{
              values: [
                { value: '', label: 'Escolha o tipo' },
                { value: 'payment', label: 'Pagamento' },
                { value: 'charge', label: 'Cobrança' }
              ]
            }}
            onChange={evt => {
              let value = _.get(evt, 'target.value');
              setFieldValue('movementType', value);
              setFieldValue('subject', '');
              setFieldValue('content', '');

              const movementType = _.get(values, 'movementType'),
                type = _.get(values, 'type');

              if (movementType === 'payment' && _.isEmpty(_.get(typesPayment, type))) {
                setFieldValue('type', 'text');
              }
            }}
          />
        </FlexCol>

        <FlexCol flex="1">
          <InputGroup
            type="text"
            name="description"
            label="Descrição"
            maxLength={100}
            hasError={_.get(errors, 'description') && _.get(touched, 'description')}
          />
        </FlexCol>
        <FlexCol flex="0 0 120px">
          <Select
            name="type"
            label="Tipo"
            disabled={previewMode}
            hasError={_.get(errors, 'type') && _.get(touched, 'type')}
            options={{
              values: [..._.map(_.get(values, 'movementType') === 'charge' ? typesCharge : typesPayment, (label, value) => ({ value, label }))]
            }}
            onChange={evt => {
              setFieldValue('type', _.get(evt, 'target.value'));
              _.get(evt, 'target.value') === 'whatsapp' && _.get(values, 'movementType') === 'charge'
                ? setFieldValue('content', defaultText)
                : setFieldValue('content', '');
            }}
          />
        </FlexCol>
      </FlexRow>

      {_.get(values, 'type') !== 'whatsapp' && _.get(values, 'movementType', '') !== '' && (
        <SubjectDefinition>
          <FlexRow justify="flex-end">
            {_.get(values, 'movementType', '') === 'charge' && (
              <FlexRow justify="flex-start">
                <Autocomplete
                  name="billType"
                  keyField="id"
                  label="Tipo da cobrança"
                  value={_.get(values, 'billType')}
                  valueFormat={row => `${row.name || ''}`}
                  loadData={onListBillTypes}
                  emptyText={'Selecione o tipo da cobrança'}
                  tipText={'Digite... '}
                  loadingText={'Carregando...'}
                  notFoundText={'Não encontrado'}
                  hasError={errors.billType && touched.billType}
                  onChange={value => {
                    setFieldValue('billType', value);
                    setFieldValue('billTypeId', value.id);
                  }}
                />
              </FlexRow>
            )}

            <FlexCol flex="0 0 200px">
              <Select
                noMargin={true}
                name="variables"
                label="Variáveis"
                disabled={previewMode || (!_.get(values, 'billType') && _.get(values, 'movementType') === 'charge')}
                value={''}
                options={{
                  values: [
                    { value: '', label: 'Variáveis' },
                    ..._.map(
                      _.filter(
                        [
                          ...(slipVariables[_.get(values, 'billType.code')] || []),
                          ...(_.filter(paymentVariables, o => {
                            return o.id !== 'pagamento.listaDocumentos';
                          }) || [])
                        ],
                        f => f.movementType === _.get(values, 'movementType')
                      ),
                      r => ({ value: r.id, ...r })
                    )
                  ]
                }}
                onChange={e => {
                  if (inputSubjectRef.current) {
                    const newText = `<%=${e.target.value}%>`;
                    const subject = _.get(values, 'subject') || '';
                    const position = inputSubjectRef.current.selectionStart || 0;
                    const text = subject.substr(0, position - 1) + ' ' + newText + ' ' + subject.substr(position, subject.length);
                    setFieldValue('subject', text);
                  }
                }}
              />
            </FlexCol>
          </FlexRow>

          <InputGroup
            type="text"
            ref={inputSubjectRef}
            name="subject"
            label="Assunto do e-mail"
            maxLength={200}
            disabled={previewMode}
            hasError={errors && errors.subject && touched.subject}
          />
        </SubjectDefinition>
      )}

      {['html', 'whatsapp'].includes(_.get(values, 'type')) && _.get(values, 'movementType', '') !== '' && (
        <HtmlDefinition>
          <FlexRow justify="flex-end">
            {_.get(values, 'movementType') === 'charge' && _.get(values, 'type') === 'whatsapp' && (
              <FlexCol flex="1 0 0 ">
                <Autocomplete
                  name="billType"
                  keyField="id"
                  label="Tipo da cobrança"
                  value={_.get(values, 'billType')}
                  valueFormat={row => `${row.name || ''}`}
                  loadData={onListBillTypes}
                  emptyText={'Selecione o tipo da cobrança'}
                  tipText={'Digite... '}
                  loadingText={'Carregando...'}
                  notFoundText={'Não encontrado'}
                  onChange={value => {
                    setFieldValue('billType', value);
                    setFieldValue('billTypeId', value.id);
                  }}
                />
              </FlexCol>
            )}
            <FlexCol flex="0 0 200px">
              <Select
                noMargin={true}
                name="variables"
                label="Variáveis"
                disabled={previewMode || (!_.get(values, 'billType') && _.get(values, 'movementType') === 'charge')}
                value={''}
                options={{
                  values: [
                    { value: '', label: 'Variáveis' },
                    ..._.map(
                      _.filter(
                        [...(slipVariables[_.get(values, 'billType.code')] || []), ...paymentVariables],
                        f => f.movementType === _.get(values, 'movementType')
                      ),
                      r => ({ value: r.id, ...r })
                    )
                  ]
                }}
                onChange={e => {
                  if (inputHtmlRef.current) {
                    const newText = `<%=${e.target.value}%>`;
                    const content = _.get(values, 'content') || '';
                    const position = inputHtmlRef.current.selectionStart || 0;
                    const text = content.substr(0, position - 1) + ' ' + newText + ' ' + content.substr(position, content.length);
                    setFieldValue('content', text);
                  }
                }}
              />
            </FlexCol>
          </FlexRow>

          <TextArea
            ref={inputHtmlRef}
            name="content"
            label="Conteúdo"
            disabled={previewMode}
            rows={15}
            hasError={errors && errors.content && touched.content}
          />
        </HtmlDefinition>
      )}

      {_.get(values, 'type') === 'text' && _.get(values, 'movementType', '') !== '' && (
        <RichEditor
          name="content"
          placeholder="Conteúdo"
          disabled={previewMode}
          heightText="400px"
          variables={_.map(
            _.filter([...(slipVariables[_.get(values, 'billType.code')] || []), ...paymentVariables], f => f.movementType === _.get(values, 'movementType')),
            r => ({ value: r.id, ...r })
          )}
        />
      )}
    </FormContainer>
  );
};

export const EmailTemplatesSchema = Yup.object().shape({
  movementType: Yup.string().required('Informe o movimento'),
  billType: Yup.object().test('charge', '', function (value) {
    const { path, parent, createError } = this;

    const billType = value || _.get(parent, 'billType'),
          movementType = _.get(parent, 'movementType');

    if(movementType === 'charge' && !billType) {
      return createError({ path, message: 'Informe o tipo da cobrança' })
    }

    return true;
  }).nullable(),
  content: Yup.string().test('contentRequired', '', function(value) {
    const { path, parent, createError } = this;
    const val = value || _.get(parent, 'content');
    // o richEditor quando está sem valores não fica como null||undefined||''
    // ele fica coma essa tag: <p><br></p>
    let isValid = val && !['<p><br></p>', ''].includes(val);

    if(!isValid) {
      return createError({ path, message: 'Informe o conteúdo' })
    }

    return isValid;
  }),
  type: Yup.string().required('Informe o tipo de template'),
  description: Yup.string().min(4, 'Verifique se a descrição está correto').required('Informe a descrição'),
  subject: Yup.mixed().test('match', '', function(value) {
    const { path, parent, createError } = this;
    const val = value || _.get(parent, 'subject');
    const type = _.get(parent, 'type');

    if (!val && (type !== 'whatsapp')) {
      return createError({ path, message: 'Informe o assunto' });
    }
    return true;
  })
});
