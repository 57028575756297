import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  max-height: ${props => props.maxHeight};
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 10px;

  @media (max-width: 425px) {
    max-height: 100%;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  padding: 10px;
`;

export const RemoveBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  font-size: 20px;
  color: #555;

  &:hover svg {
    color: #333;
  }
`;

export const BillDetailTableContainer = styled.div`
  display: flex;
  width: 100%;
`;
