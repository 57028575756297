
import React from 'react';
import _ from 'lodash';
import * as Yup from 'yup';
import { Select, InputGroup, CurrencyField, InputMask, InputDate } from '~/components/form';
import FieldSet from '~/components/fieldset';
import { BsRow, BsCol } from '~/components/layout';
import { MASK_CNPJ, MASK_CPF } from '~constants';
import { isValidCpf, isValidCnpj } from '~/validator';

export const id = 41;

export const onParentChange = ({ field, value, parent, setFieldValue }) => {
  let idField = 'orderData.identity',
    idTypeField = 'orderData.identityType',
    nameField = 'orderData.name';

  if (field === 'paymentOrderType' && _.get(parent, 'company')) {
    let identity = _.get(parent, 'company.identity');
    setFieldValue(idTypeField, _.size(identity) === 14 ? 'company' : 'person');
    setFieldValue(idField, identity);
    setFieldValue(nameField, _.get(parent, 'company.name'));

  } else if (field === 'company' && value) {
    let identity = _.get(value, 'identity');
    setFieldValue(idTypeField, _.size(identity) === 14 ? 'company' : 'person');
    setFieldValue(idField, identity);
    setFieldValue(nameField, _.get(value, 'name'));

  } else if (field === 'paymentOrderType' && _.get(parent, 'dueDate')) {
    setFieldValue('orderData.dueDate', _.get(parent, 'dueDate'));

  } else if (field === 'dueDate' && value) {
    setFieldValue('orderData.dueDate', value);
  }
};

export const Render = ({ values, errors, touched, setFieldValue, previewMode }) => {
  const editLocked = _.get(values, 'editLocked');
  return (
    <FieldSet label="Tributos - DARF Simples">
      <BsRow>
        <BsCol md={24} lg={5} xl={4}>
          <Select
            name="orderData.identityType"
            label="Tipo da inscrição *"
            disabled={previewMode || editLocked}
            hasError={_.get(errors, 'orderData.identityType') && _.get(touched, 'orderData.identityType')}
            onChange={(evt) => {
              setFieldValue('orderData.identityType', evt.target.value);
              setFieldValue('orderData.identity', '');
            }}
            options={{ values: [
                { value: '', 'label': '' },
                { value: 'company', 'label': 'CNPJ' },
                { value: 'person', 'label': 'CPF' },
              ]}}
            />
        </BsCol>

        <BsCol md={24} lg={7} xl={8}>
          {_.get(values, 'orderData.identityType') === 'person' &&
             <InputMask
              disabled={!_.get(values, 'orderData.identityType') || previewMode || editLocked}
              mask={MASK_CPF}
              name="orderData.identity"
              label={'Número da inscrição *'}
              hasError={_.get(errors, 'orderData.identity') && _.get(touched, 'orderData.identity')}
              />
          }
          {_.get(values, 'orderData.identityType') !== 'person' &&
            <InputMask
              disabled={!_.get(values, 'orderData.identityType') || previewMode || editLocked}
              mask={MASK_CNPJ}
              name="orderData.identity"
              label={'Número da inscrição *'}
              hasError={_.get(errors, 'orderData.identity') && _.get(touched, 'orderData.identity')}
              />
          }
        </BsCol>

        <BsCol md={24} lg={12} xl={12}>
          <InputGroup
            type="text"
            name="orderData.name"
            maxLength={30}
            disabled={previewMode || editLocked}
            label="Nome do contribuinte *"
            hasError={_.get(errors, 'orderData.name') && _.get(touched, 'orderData.name')}
            />
        </BsCol>
      </BsRow>

      <BsRow>
        <BsCol md={24} lg={8} xl={5}>
          <InputGroup
            type="text"
            name="orderData.revenueCode"
            maxLength={20}
            disabled={previewMode || editLocked}
            label="Código da receita *"
            hasError={_.get(errors, 'orderData.revenueCode') && _.get(touched, 'orderData.revenueCode')}
            />
        </BsCol>

        <BsCol md={24} lg={8} xl={4}>
          <InputDate
            name="orderData.period"
            label="Período *"
            monthView={true}
            disabled={previewMode || editLocked}
            hideErrorLabel={false}
            hasError={_.get(errors, 'orderData.period') && _.get(touched, 'orderData.period')}
            />
        </BsCol>

        <BsCol md={24} lg={8} xl={5}>
          <InputDate
            name="orderData.dueDate"
            label="Data de vencimento *"
            hideErrorLabel={false}
            disabled={true}
            hasError={_.get(errors, 'orderData.dueDate') && _.get(touched, 'orderData.dueDate')}
            />
        </BsCol>

        <BsCol md={24} lg={12} xl={5}>
          <CurrencyField
            name="orderData.grossIncome"
            label="Receita Bruta"
            disabled={previewMode || editLocked}
            hasError={_.get(errors, 'orderData.grossIncome') && _.get(touched, 'orderData.grossIncome')}
            />
        </BsCol>

        <BsCol md={24} lg={12} xl={5}>
          <CurrencyField
            name="orderData.percentual"
            label="Valor percentual"
            disabled={previewMode || editLocked}
            hasError={_.get(errors, 'orderData.percentual') && _.get(touched, 'orderData.percentual')}
            />
        </BsCol>
      </BsRow>

      <BsRow>
        <BsCol md={24} lg={12} xl={6}>
          <CurrencyField
            name="orderData.value"
            label="Valor principal *"
            disabled={previewMode || editLocked}
            hasError={_.get(errors, 'orderData.value') && _.get(touched, 'orderData.value')}
            onChange={(val) => {
              let value = val || 0,
                fineValue = _.get(values, 'orderData.fineValue') || 0,
                interestValue = _.get(values, 'orderData.interestValue') || 0;
              setFieldValue('orderData.total', (fineValue + interestValue + value));
            }}
            />
        </BsCol>

        <BsCol md={24} lg={12} xl={6}>
          <CurrencyField
            name="orderData.fineValue"
            label="Valor da multa"
            disabled={previewMode || editLocked}
            hasError={_.get(errors, 'orderData.fineValue') && _.get(touched, 'orderData.fineValue')}
            onChange={(val) => {
              let value = _.get(values, 'orderData.value') || 0,
                fineValue = val || 0,
                interestValue = _.get(values, 'orderData.interestValue') || 0;

              setFieldValue('orderData.total', (fineValue + interestValue + value));
            }}
            />
        </BsCol>

        <BsCol md={24} lg={12} xl={6}>
          <CurrencyField
            name="orderData.interestValue"
            label="Valor do juros"
            disabled={previewMode || editLocked}
            hasError={_.get(errors, 'orderData.interestValue') && _.get(touched, 'orderData.interestValue')}
            onChange={(val) => {
              let value = _.get(values, 'orderData.value') || 0,
                fineValue = _.get(values, 'orderData.fineValue') || 0,
                interestValue = val || 0;

              setFieldValue('orderData.total', (fineValue + interestValue + value));
            }}
            />
        </BsCol>

        <BsCol md={24} lg={12} xl={6}>
          <CurrencyField
            name="orderData.total"
            label="Valor total"
            disabled={true}
            hasError={_.get(errors, 'orderData.total') && _.get(touched, 'orderData.value')}
            />
        </BsCol>
      </BsRow>
    </FieldSet>
  )
};

export const Schema = (values) => ({
  orderData: Yup.object().typeError('Informe os dados da ordem').shape({
    identityType: Yup.string().required('Informe o tipo de inscrição'),
    identity: Yup.mixed().test('match', '', function(value) {
      const { path, parent, createError } = this;
      const val = value || _.get(parent, 'identity'),
        type = _.get(parent, 'identityType');
      if (!val) {
        return createError({ path, message: 'Informe o número de inscrição' });
      } else if (val && type === 'person' && !isValidCpf(val)) {
        return createError({ path, message: 'Informe um CPF válido' });
      } else if (val && type === 'company' && !isValidCnpj(val)) {
        return createError({ path, message: 'Informe um CNPJ válido' });
      }
      return true;
    }),
    name: Yup.string().required('Informe o nome do contribuinte'),
    revenueCode: Yup.string().required('Informe o código da receita'),
    grossIncome: Yup.string().required('Informe a receita bruta'),
    percentual: Yup.number().required('Informe o percentual'),
    period: Yup.string().typeError('Informe o período').required('Informe o período'),
    value: Yup.number().required('Informe o valor principal'),
    total: Yup.mixed().test('match', '', function(value) {
      const { path, parent, createError } = this;
      const val = value || _.get(parent, 'total');
      //baseVal = _.get(values, 'value') || 0;

      if (!val) {
        return createError({ path, message: 'Informe o valor principal' });
      }
      //else if (Number(val).toFixed(2) !== Number(baseVal).toFixed(2)) {
      //  return createError({ path, message: 'Valor total difere do Valor do cabeçalho' });
      //}
      return true;
    })
  })
});
