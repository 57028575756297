import React from 'react';
import { Select, InputDate } from '~/components/form';
import { FilterContainer } from '~/components/crud/styles';
import { Row } from '~/components/layout';
import Fieldset from '~/components/fieldset';
import { isBefore, isAfter } from 'date-fns';

export default function FormFilter({ values, setFieldValue, onListBankAccounts, onListCompanies }) {
  return (
    <FilterContainer>
      <Select
        name="success"
        label="Envio"
        options={{
          values: [
            { value: '', label: 'Todos' },
            { value: 'true', label: 'Enviados' },
            { value: 'false', label: 'Não enviados' }
          ]
        }}
      />

      <Fieldset label="Enviado em">
        <Row span={2}>
          <InputDate
            name="createdAtStart"
            label="De"
            onChange={value => {
              setFieldValue('createdAtStart', value);
              if (values.createdAtEnd && isAfter(value, values.createdAtEnd)) {
                setFieldValue('createdAtStart', values.createdAtEnd);
                setFieldValue('createdAtEnd', value);
              }
            }}
          />

          <InputDate
            name="createdAtEnd"
            label="Até"
            onChange={value => {
              setFieldValue('createdAtEnd', value);
              if (values.createdAtStart && isBefore(value, values.createdAtStart)) {
                setFieldValue('createdAtStart', value);
                setFieldValue('createdAtEnd', values.createdAtStart);
              }
            }}
          />
        </Row>
      </Fieldset>
    </FilterContainer>
  );
}
