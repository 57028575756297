import React, { memo, useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { SidebarLabel, MenuContent, SidebarText } from '~/components/menu/sub-menu';
import { MdExitToApp } from 'react-icons/md';
import logoIcon from '~/assets/logo-icon.svg';
import logoFull from '~/assets/logo-h.svg';
import MenuProfile from '~/components/header/menu-profile';
import { Container, Aside, Profile } from '~/components/menu/styles';
import { Creators as AuthActions } from '~/store/modules/auth';
import ReactTooltip from 'react-tooltip';
import SideBar from '~/components/menu/sidebar';
import { IconContext } from 'react-icons/lib';
import useClickAwayListener from '~/hooks/use-click-away-listener';
import { primary } from '~/components/mixins/color';

function Menu() {
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);;
  const sidebarData = useSelector(state => _.get(state, 'auth.sidebarData') || []);
  const user = useSelector(state => _.get(state, 'auth.user', {}));
  const name = _.get(user, 'name', '');

  useClickAwayListener({
    ref: wrapperRef,
    callback: () => showSidebar(false)
  });

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  function handleSignOut() {
    dispatch(AuthActions.logout(false))
  }

  const [sidebar, setSidebar] = useState(false)
  const showSidebar = show => setSidebar(show)

  return (
    <Container ref={wrapperRef} sidebar={sidebar} onMouseEnter={(e) => showSidebar(true)} onMouseLeave={e => showSidebar(false)}>
      <SideBar icon={logoIcon} logo={logoFull} sidebar={sidebar} data={sidebarData} />

      <Aside>
        <Profile sidebar={sidebar}>
          <MenuProfile align="start" direction="right" />
          {sidebar && <span>{name}</span>}
        </Profile>

        <IconContext.Provider value={{ color: primary.hex(), size: 20 }}>
          <div className="menu-bottom">
            <SidebarText onClick={handleSignOut} mini={String(!sidebar)}>
              <MenuContent>
                <MdExitToApp />
                {sidebar && <SidebarLabel>Sair</SidebarLabel>}
              </MenuContent>
            </SidebarText>
          </div>
        </IconContext.Provider>
      </Aside>
    </Container>
  )
}

export default memo(Menu)
