import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MdVpnKey, MdClear, MdCheck } from 'react-icons/md';
import _ from 'lodash';
import FormFilter from '~/pages/accounts/filter';
import { Creators as AccountActions } from '~/store/modules/account';
import { Creators as HeaderActions } from '~/store/modules/header';
import { Container } from '~/pages/accounts/styles';
import CellStatus from '~/components/datatable/cell-status';
import { AccountForm } from '~/pages/accounts/form';
import { SubtitleItem, Subtitle } from '~/components/datatable/subtitle';
import { red, green } from '~/components/mixins/color';
import confirm from '~/components/confirm';
import Crud from '~/components/crud';
import { formats } from '~/helper';

const columns = [
    {
      name: ' ',
      selector: 'status',
      width: '10px',
      cell: (row) => <CellStatus title="" color={row.active ?  green.hex() : red.hex()} />
    },
    {
      name: 'Nome',
      selector: 'name',
      cell: (row) => _.get(row, 'name')
    },
    {
      name: 'E-mail',
      selector: 'email',
    },
    {
      name: 'Cadastrado em',
      selector: 'createdAt',
      width: '140px',
      hide: 'md',
      format: (row) => formats.dateTimeZone(_.get(row, 'createdAt'), 'dd/MM/yyyy HH:mm')
    },
  ]

function Account({ acls, history }) {
  const dispatch = useDispatch();
  const state = useSelector(state => state.account);
  const headerState = useSelector(state => state.header);
  const [openForm, setOpenForm] = useState(false);
  const showFilter = headerState.filter.visible;

  useEffect(() => {
    const config = {
      loading: state.loading,
      useFilter: true,
      filter: { ...headerState.filter, visible: false }
    }
    dispatch(HeaderActions.configure(config));
    // eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    dispatch(AccountActions.list({ ...headerState.filter.data, offset: 0 }));
    // eslint-disable-next-line
  }, [dispatch]);

  function handlePageChange(offset, limit) {
    dispatch(AccountActions.list({ ...headerState.filter.data, offset, limit }));
  };

  function handleLoad(data) {
    setOpenForm(true);
    dispatch(AccountActions.load(data));
  };

  function handleHideFilter() {
    dispatch(HeaderActions.hideFilter());
    setOpenForm(false);
  };

  function handleFilter(data) {
    handleHideFilter();
    dispatch(HeaderActions.callFilter({ ...data, offset: 0 }, AccountActions.list));
  };

  async function handlePasswordReset(data, actions) {
    const result = await confirm.show({
        title: 'Atenção',
        text: `Deseja realmente redefinir a senha do usuário e enviar os dados de acesso por e-mail?`
      });

    if (result) {
      dispatch(AccountActions.resetPassword(data, actions));
    }
  };

  return (
    <Container>
      {!state.model &&
        <Subtitle bottom={25} left={20}>
          <span>Legenda:</span>
          <SubtitleItem color={green.hex()}>Ativo</SubtitleItem>
          <SubtitleItem color={red.hex()}>Inativo</SubtitleItem>
        </Subtitle>
      }

      <Crud
        useOpenForm={true}
        openForm={openForm || showFilter}
        hideAdd={true}
        columns={columns}
        emptyText="Nenhum conta encontrada"
        formTitle={(data) => (showFilter ? `Filtro` : `Conta (${_.get(data, 'name') || ''})`)}
        data={state.data}
        rightWidth={`${showFilter ? '500px' : '40%'}`}
        onCloseFilter={handleHideFilter}
        tableLoading={state.loading}
        formLoading={state.formLoading}
        onChangePage={handlePageChange}
        onRowClicked={handleLoad}
        formOptions={{
          initialValues: showFilter ? headerState.filter.data : state.model || {},
        }}
        renderForm={args => showFilter ?
          <FormFilter
            {...args}
            />
          :
          <AccountForm
            {...args}
            />
        }
        actions={showFilter ?
          [
            { label: 'Limpar Filtro', icon: MdClear, action: () => handleFilter({}) },
            { label: 'Aplicar Filtro', icon: MdCheck, action: data => handleFilter(data) }
          ]
          :
          [
          {
              label: 'Redefinir senha do usuário',
              icon: MdVpnKey,
              action: handlePasswordReset
            }
          ]
        }
        />
    </Container>
  );
}

export default Account;
