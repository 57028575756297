import React from 'react';
import _ from 'lodash';
import { FormContainer } from '~/pages/payment-email-batchs/styles';
import { InputLabel } from '~/components/form';
import { Row } from '~/components/layout';
import Tabs from '~/components/tabs';
import { TabSents } from '~/pages/payment-email-batchs/tab-sents';
import { TabSendError } from '~/pages/payment-email-batchs/tab-send-error';

const tabs = [
    { id: 'sents', label: 'Enviados', component: TabSents },
    { id: 'sendError', label: `Não enviados`, component: TabSendError },
  ];

export const EmailBatchForm = ({ errors, touched, values, canWrite, setFieldValue, openDocument, previewMode }) => {

    const sents = _.filter(values.emailBatchSends, (r) => !_.get(r, 'error')),
    sentError = _.filter(values.emailBatchSends, (r) => !!_.get(r, 'error'));

  return (
    <FormContainer>
      <Row>
        <InputLabel
          label="Autor"
          value={_.get(values, 'user.name') || 'Automático'} />
      </Row>

      <Tabs
        tabs={tabs}
        height="calc(100% - 70px)"
        initialTab={'sents'}
        openDocument={openDocument}
        sents={sents}
        sentError={sentError}
        />
    </FormContainer>
  )
};
