import { createActions, createReducer } from 'reduxsauce'
import produce from 'immer'
import { getAvatar } from '~/constants'
import * as helper from '~/store/modules/auth/helper'

export const { Types, Creators } = createActions(
  {
    login: ['data', 'actions'],
    loginSuccess: ['token', 'user', 'menu', 'headLess'],
    redefinePassword: ['data', 'actions'],
    localLogin: ['token', 'redirect'],
    localLoginWithMenu: ['token', 'redirect'],
    signUp: ['data', 'actions'],
    failure: ['error'],
    logout: ['headLess'],
    updateAvatarSuccess: ['avatar'],
  },
  { prefix: 'auth' },
)

const INITIAL_STATE = {
  token: null,
  menu: [],
  sidebarData: [],
  signed: false,
  headLess: false,
}

const handleLoginSuccess = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    draft.user = action.user
    draft.menu = action.menu
    draft.sidebarData = helper.getSideBarData(action.menu, action.user)
    draft.token = action.token
    draft.headLess = action.headLess || false
    draft.signed = true
  })

const handleLocalLogin = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    let resourceUrl = (String(action.redirect).split('/') || [])
      .slice(0, 2)
      .join('/')

    draft.user = { name: 'Usuário', profile: { name: 'Restrito' } }
    draft.menu = [{ path: resourceUrl, perms: ['W'] }]
    draft.sidebarData = []
    draft.token = action.token
    draft.headLess = true
    draft.signed = true
  })

const handleLogout = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.user = null
    draft.menu = null
    draft.sidebarData = null
    draft.token = null
    draft.signed = false
    draft.headLess = false
  })

const handleUpdateAvatarSuccess = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    if (draft.user) {
      draft.user.avatar = getAvatar(draft.user, true)
    }
  })

export default createReducer(INITIAL_STATE, {
  [Types.LOGIN_SUCCESS]: handleLoginSuccess,
  [Types.LOCAL_LOGIN]: handleLocalLogin,
  [Types.LOGOUT]: handleLogout,
  [Types.UPDATE_AVATAR_SUCCESS]: handleUpdateAvatarSuccess,
})
