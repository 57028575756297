import React, { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MdCheck, MdClear, MdErrorOutline } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { Creators as PaymentApprovalsPendingActions } from '~/store/modules/payment-approvals-pending';
import { Creators as HeaderActions } from '~/store/modules/header';
import FormFilter from '~/pages/payment-approvals-pending/filter';
import Crud from '~/components/crud';
import { formats } from '~/helper';
import useSearchParameters from '~/hooks/use-search-params';
import { CrudContainer, CompanyContainer, CellIcon, BankAccountContainer } from '~/pages/payment-approvals-pending/styles'
import { IoWarningOutline } from 'react-icons/io5'
import CellBank from '~/components/datatable/cell-bank'

const columns = [
  {
    name: 'Empresa / Beneficiário',
    selector: 'company',
    wrap: true,
    cell: (row) => {
      const company = _.get(row, 'company') || {},
        hasError = !_.isEmpty(_.get(row, 'generationMessages.errors')),
        hasWarning = !_.isEmpty(_.get(row, 'generationMessages.warnings')),
        bp = _.get(row, 'businessPartner') || {}

      return (
        <CompanyContainer hasIcon={hasError || hasWarning}>
          {(hasError || hasWarning) && (
            <div className="company-icons">
              {hasError && (
                <CellIcon size={20} color="#ff0000">
                  <MdErrorOutline />
                </CellIcon>
              )}
              {hasWarning && (
                <CellIcon size={20} color="#efd600">
                  <IoWarningOutline />
                </CellIcon>
              )}
            </div>
          )}
          <div className="company-text">
            <span>
              {formats.cnpj_cpf(_.get(company, 'identity', '')) +
                ' ' +
                (_.get(company, 'name', '') || _.get(company, 'tradeName', ''))}
            </span>
            <span>
              {formats.cnpj_cpf(_.get(bp, 'identity', '')) +
                ' ' +
                (_.get(bp, 'name', '') || _.get(bp, 'tradeName', ''))}
            </span>
          </div>
        </CompanyContainer>
      )
    },
  },
  {
    name: 'Tipo',
    selector: 'paymentOrderType',
    width: '180px',
    wrap: true,
    hide: 'lg',
    format: (row) => _.get(row, 'paymentOrderType.name'),
  },
  {
    name: 'Nr. Documento',
    selector: 'documentNumber',
    width: '150px',
    right: true,
    format: (row) => _.get(row, 'documentNumber'),
  },
  {
    name: 'Agendamento',
    selector: 'scheduledDate',
    width: '100px',
    right: true,
    format: (row) =>
      formats.dateTimeZone(_.get(row, 'scheduledDate'), 'dd/MM/yyyy'),
  },
  {
    name: 'Valor a Pagar',
    selector: 'total',
    right: true,
    width: '120px',
    compact: false,
    format: (row) => row.isPartial ? formats.currency(_.get(row, 'value')) :
      formats.currency(_.get(row, 'orderData.total') || _.get(row, 'value')),
  },
  {
    name: 'Agência / Conta',
    selector: 'bankAccount',
    width: '120px',
    left: true,
    cell: (row) => {
      return (
        <BankAccountContainer>
          <CellBank bankAccount={_.get(row, 'bankAccount')} />
        </BankAccountContainer>
      )
    }
  },
  {
    name: 'Aprovador por',
    selector: 'approvers',
    left: true,
    wrap: true,
    cell: (row) => {
      const paymentApprovals = _.get(row,'paymentApprovals[0]') || {},
        history = _.get(paymentApprovals,'history') || [],
        approvers = _.map(history, r => _.get(r,'user.name') || ''),
        approversNames = approvers.join(', ');

      return approversNames
    }
  }

]

const customStyles = {
  rows: {
    denseStyle: {
      minHeight: '58px',
    },
  },
}

function PaymentApprovalsPending({ acls, match }) {
  const dispatch = useDispatch();
  const state = useSelector(state => state.paymentApprovalsPending);
  const headerState = useSelector(state => state.header);
  const history = useHistory();
  const [openForm, setOpenForm] = useState(!!_.get(match, 'params.id'));
  const showFilter = headerState.filter.visible;


  const {
    location,
    routeParams,
    getAllParams,
    toStringify,
  } = useSearchParameters()

  useLayoutEffect(() => {
    const config = {
      loading: state.loading,
      useFilter: true,
      filter: {
        ...headerState.filter,
        visible: false,
      },
    }
    dispatch(HeaderActions.configure(config))
    dispatch(PaymentApprovalsPendingActions.listPaymentOrderTypes())
    // eslint-disable-next-line
  }, [])

  useLayoutEffect(() => {
    const { offset, limit = 20, ...filter } = getAllParams()

    dispatch(
      HeaderActions.callFilter(
        { ...filter, offset: _.toInteger(offset), limit: _.toInteger(limit) },
        PaymentApprovalsPendingActions.list,
      ),
    )
    // eslint-disable-next-line
  }, [location, routeParams])

  function handleFilter(filter) {
    const historySearch = {
      ..._.omit(filter, [
        'id',
        'company',
        'bankAccount',
        'scheduledDateStart',
        'scheduledDateEnd',
        'businessPartner',
        'paymentOrderType',
        'bankContract',
        'user'
      ]),
      offset: 0,
    }
    handleHideFilter()
    history.push({
      pathname: `/payment-approvals-pending`,
      search: `?${toStringify(historySearch)}`,
      state: filter,
    })
  }

  function handleHideFilter() {
    dispatch(HeaderActions.hideFilter());
    setOpenForm(false);
  }

  function handlePageChange(offset, limit) {
    const filter = getAllParams()
    history.push({
      pathname: '/payment-approvals-pending',
      search: `?${toStringify({
        ..._.omit(filter, [
          'id',
          'company',
          'bankAccount',
          'scheduledDateStart',
          'scheduledDateEnd',
          'businessPartner',
          'paymentOrderType',
          'bankContract',
          'user'
        ]),
        offset,
        limit,
      })}`,
      state: { ..._.omit(filter, 'id'), offset, limit },
    })
    setOpenForm(false)
  };

  function handleOpenDocument(data) {
    const filter = getAllParams()
    if(_.isEmpty(filter)){
      history.push(`/payment-orders/${_.get(data, 'id')}`);
    } else {
      history.push({
        pathname: `/payment-orders/${_.get(data, 'id')}`,
        search: `?${toStringify({
          ..._.omit(filter, [
            'id',
            'company',
            'bankAccount',
            'scheduledDateStart',
            'scheduledDateEnd',
            'businessPartner',
            'paymentOrderType',
            'bankContract',
            'user'
          ]),
        })}`,
        state: filter,
        sourcepath: 'payment-approvals-pending'
      })
    }
  }

  function handleListUsers(term, callback) {
    dispatch(PaymentApprovalsPendingActions.listUsers(term, callback));
  }

  function handleListPaymentOrderType(term, callback) {
    dispatch(PaymentApprovalsPendingActions.listPaymentOrderType(term, callback))
  }

  function handleListBankContracts(term, callback) {
    dispatch(PaymentApprovalsPendingActions.listBankContracts({ term }, callback))
  }

  const handleListCompanies = (params, callback) => {
    dispatch(PaymentApprovalsPendingActions.listCompanies(params, callback))
  }

  function handleListBusinessPartners(term, callback) {
    dispatch(PaymentApprovalsPendingActions.listBusinessPartners(term, callback))
  }

  function handListBankAccountsGroups(term, callback) {
    dispatch(PaymentApprovalsPendingActions.listBankAccountsGroups({ term }, callback))
  }

  return (
    <CrudContainer>
      <Crud
        rightWidth={`${showFilter ? 500 : 1000}px`}
        useOpenForm={true}
        openForm={openForm || showFilter}
        hideAdd={true}
        columns={columns}
        emptyText="Nenhuma aprovação pendente"
        formTitle={data => (showFilter ? `Filtro` : `Detalhes da aprovação`)}
        data={state.data}
        onCloseFilter={handleHideFilter}
        tableLoading={state.loading}
        formLoading={state.formLoading}
        onChangePage={handlePageChange}
        onRowClicked={handleOpenDocument}
        formOptions={{
          initialValues: showFilter ? headerState.filter.data : state.model
        }}
        renderForm={args =>
          showFilter && (
            <FormFilter 
              {...args} 
              onListUsers={handleListUsers}
              onListPaymentOrderType={handleListPaymentOrderType}
              onListBankContracts={handleListBankContracts}
              onListCompanies={handleListCompanies}
              onListBusinessPartners={handleListBusinessPartners}
              onListBankAccountsGroups={handListBankAccountsGroups}
            />
          )
        }
        actions={
          showFilter
            ? [
                { label: 'Limpar Filtro', icon: MdClear, action: () => handleFilter({}) },
                { label: 'Aplicar Filtro', icon: MdCheck, action: data => handleFilter(data) }
              ]
            : []
        }
        dataTableOptions={{
          customStyles
        }}
      />
    </CrudContainer>
  );
}

export default PaymentApprovalsPending;