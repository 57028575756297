import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { memo } from 'react'
import * as FaIcons from 'react-icons/fa'
import * as MdIcons from 'react-icons/md'
import styled from 'styled-components'
import Card from '~/components/kanban/card'
import { white, primary, link, lightGray } from '~/components/mixins/color'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import { DragDropContext } from 'react-beautiful-dnd'
import { formats } from '~/helper';
import { KanbanContainer } from './styles'

const AllIcons = { ...MdIcons, ...FaIcons }

const Content = styled.div`
  display: ${props => (props.visible ? 'grid' : 'none')};
  grid-auto-columns: 290px;
  grid-auto-flow: column;
  grid-gap: 10px;
  align-content: flex-start;
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;

  @media (max-width: 425px) {
    width: 100%;
    max-width: 300px;
    body {
      overflow-x: visible;
    }
  }
`

const Board = styled.div`
  display: flex;
  background: ${white.hex()};
  border-radius: 3px;
  box-shadow: 0.7px 1px 1px 0 rgba(134, 134, 134, 0.45);
  padding: 5px;
  height: calc(100vh - 195px);
  width: 290px;
  flex-direction: column;

  @media (max-width: 425px) {
    height: calc(100vh - 170px);
  }

  header {
    display: flex;
    flex: 0 0 80px;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding: 0 5px;

    .icons {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 3px;

      svg {
        cursor: help;
      }
      svg.action {
        cursor: pointer;
        color: #444;
        &:hover {
          color: ${primary.hex()};
        }
      }
    }

    h2 {
      font-weight: 500;
      font-size: 14px;
      color: #111;
      display: flex;
      align-items: center;
      justify-content: center;

      > div {
        display: flex;
        flex-direction: column;

        label {
          font-size: 14px;
        }
        span {
          font-size: 11px;
          color: #444;
          margin: 0;
        }
      }

      .logo {
        width: 26px;
        height: auto;
        object-fit: contain;
        margin-right: 10px;
      }

      span {
        font-size: 14px;
        color: #666;
        margin-left: 5px;
      }
    }
  }
`

const Box = styled.div`
  flex: 1;
  margin-top: 5px;
  min-height: calc( 100% - 80px - 141px );
  max-height: calc( 100% - 80px - 141px );
`;

const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${primary.fade(0.95).string()};
  flex: 0 0 140px;
`

const SummaryLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 28px;
  width: 100%;
  background: ${props =>
    props.index % 2 !== 0 ? lightGray.hex() : white.hex()};
  padding: 0 3px 0 3px;

  &:hover {
    background: ${primary.fade(0.94).string()};
  }

  label {
    font-size: 12px;
    font-weight: 600;
    color: ${link.hex()};
  }

  span {
    font-size: 11.5px;
    color: ${link.hex()};
  }
`

const KanbanHeader = ({ id, count, board, boardIconTip, boardIcons, onBoardAction, summaryInfo }) => {
  const Icon = AllIcons[board.icon] || AllIcons['MdMenu'],
    companyName = _.get(board,'extra.company.name','');

  let guaranteedContract = _.get(board, 'extra.fields.useGuaranteed'),
    guaranteedValue = _.get(board, 'extra.fields.guaranteedValue') || 0,
    accountsReceivable = _.get(_.find(summaryInfo, (r) => r.label === 'Contas a receber'),'raw') || 0;

  return (
    <header>
      <h2>
        {board.logo && <img className="logo" src={board.logo} alt="action" />}
        <div className="label">
          <label>
            {board.label} ({count})
          </label>
          <div className="text-truncate" title={companyName} style={{ maxWidth: '200px' }}>
            <span>{companyName}</span>
          </div>
          <span>{board.detail}</span>
          <span>{board.subDetail}</span>
          {guaranteedContract && <span style={ {color: `${ guaranteedValue > accountsReceivable ? '#b62f2f' : '#333'}`} } >Valor conta garantida: {formats.currency(guaranteedValue)}</span>}
        </div>
      </h2>

      <div className="icons">
        <Icon size={22} className="action" title={boardIconTip} onClick={() => onBoardAction(id) || _.noop} />

        {_.map(boardIcons, ({ icon: Ico, tip, color, action }, index) => (
          <Ico key={index} size={18} title={tip} onClick={action || _.noop} className={action ? "action" : ''} />
        ))}

      </div>
    </header>
  )
}

const KanbanSummary = ({ data }) => {
  if (_.size(data) === 0) return null

  return (
    <SummaryContainer>
      {_.map(data, (r, index) => {
        const label = _.get(r, 'label')
        const value = _.get(r, 'value')
        return (
          <SummaryLine key={index} index={index}>
            <label>{label}</label>
            <span>{value}</span>
          </SummaryLine>
        )
      })}
    </SummaryContainer>
  )
}

const KanbanCards = ({ cards, fieldId, options, onCardClick, isDragDisabled }) => {
  return (
    <KanbanContainer>
      {_.map(cards, (card, cix) => (
        <Draggable key={_.get(card, fieldId)} draggableId={_.get(card, fieldId)} index={cix} isDragDisabled={isDragDisabled}>
          {(provided, snapshot) => {
            let extraStyles = options.conditionalStyle ? options.conditionalStyle(card) : {};
            let icons = options.conditionalIcons ? options.conditionalIcons(card) : {};
            return (
              <Card
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={{
                  ...provided.draggableProps.style,
                  background: snapshot.isDragging ? primary.fade(0.9).string() : white.hex(),
                  color: '#333',
                  ...extraStyles
                }}
                value={card}
                icons={icons}
                options={options}
                handleClick={() => onCardClick(card)}
              />
            );
          }}
        </Draggable>
      ))}
    </KanbanContainer>
  );
}

const Kanban = ({
  loading,
  boards,
  cards,
  summary,
  cardField,
  cardOptions,
  boardIconTip,
  boardConditionalStyle,
  boardConditionalIcons,
  onCardClick = () => {},
  onChangeCards = () => {},
  onBoardAction,
  isDragDisabled,
}) => {
  return (
    <Content visible={!loading}>
      <DragDropContext onDragEnd={result => onChangeCards(result)}>
        {Object.entries(boards).map(([boardId, board], index) => {
          const boardItems = _.get(cards, boardId) || [];
          const boardStyle = boardConditionalStyle ? boardConditionalStyle(board) : {};
          const boardIcons = boardConditionalIcons ? boardConditionalIcons(board) : [];

          return (
            <Board key={boardId} style={boardStyle}>
              <KanbanHeader
                id={boardId}
                count={_.size(boardItems)}
                board={board}
                boardIconTip={boardIconTip}
                boardIcons={boardIcons}
                onBoardAction={onBoardAction}
                summaryInfo = {_.get(summary, boardId)}
              />
              <Droppable droppableId={boardId} key={boardId}>
                {(provided, snapshot) => (
                  <Box
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{
                      background: snapshot.isDraggingOver
                        ? '#ccd9e033'
                        : white.alpha(0.5).string(),
                      maxHeight: boardId === '000' && 'none'
                    }}
                    >
                    <KanbanCards
                      cards={boardItems}
                      fieldId={cardField}
                      options={cardOptions}
                      onCardClick={onCardClick}
                      isDragDisabled={isDragDisabled}
                    />
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
              <KanbanSummary data={_.get(summary, boardId) || {}} />
            </Board>
          )
        })}
      </DragDropContext>
    </Content>
  )
}

Kanban.propTypes = {
  loading: PropTypes.bool,
  boards: PropTypes.object.isRequired,
  cards: PropTypes.object.isRequired,
  summary: PropTypes.object,
  cardField: PropTypes.string.isRequired,
  onBoardAction: PropTypes.func
}

Kanban.defaultProps = {
  loading: false,
  cardField: 'id'
}

export default memo(Kanban)
