import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { formats, sortByMaxOrMin, sortCaseInsesitive, sortByDate } from '~/helper';
import Kanban from '~/components/kanban';
import { HiOutlineClipboardCheck, HiOutlineExternalLink } from 'react-icons/hi';
import { BiCalendarEvent } from 'react-icons/bi';
import { MdLock, MdWarningAmber } from 'react-icons/md';

import { formatBankAccountGrid } from '~/pages/bank-accounts/form';
import { Container, Content } from '~/pages/prepare-payment-order/styles';
import { FormHeader, FormToolbar } from '~/components/crud/styles';
import _ from 'lodash';
import { Creators as PreparePaymentOrderActions } from '~/store/modules/prepare-payment-order';
import { Creators as HeaderActions } from '~/store/modules/header';
import { primary, accent } from '~/components/mixins/color';
import Footer from '~/pages/prepare-payment-order/footer';
import PrepareModal from '~/pages/prepare-payment-order/modal-prepare';
import SummaryModal from '~/pages/prepare-payment-order/modal-summary';
import FormFilter from '~/pages/prepare-payment-order/filter';
import { Formik } from 'formik';
import { IconButton } from '~/components/button';
import { MdKeyboardBackspace, MdCheck, MdClear } from 'react-icons/md';
import confirm from '~/components/confirm';
import { VscServerProcess } from 'react-icons/vsc';

const cardOptions = {
  color: row => (row.bankAccountId === '000' ? accent.hex() : primary.hex()),
  title: 'paymentOrderType.name',
  subtitle: row => {
    return (
      <strong>
        {row.isPartial ? formats.currency(_.get(row, 'value')) : formats.currency(_.get(row, 'orderData.total') || _.get(row, 'value'))}
        {row.wrongValues ? ' (*)' : ''}
      </strong>
    );
  },
  info: row => (
    <>
      <p>{`Vencimento: ${formats.dateTimeZone(row.dueDate, 'dd/MM/yyyy')}`}</p>
      <p>{`Agendamento: ${formats.dateTimeZone(row.scheduledDate, 'dd/MM/yyyy')}`}</p>
    </>
  ),
  detail: row => {
    const cm = _.get(row, 'company') || {},
      bp = _.get(row, 'businessPartner') || {},
      amount = (row.isPartial ? row.baseAmount : row.value);

    return (
      <>
        <p>{`${formats.cnpj_cpf(cm.identity)} - ${cm.name || 'Sem empresa'}`}</p>
        <p>{`${formats.cnpj_cpf(bp.identity)} - ${bp.name || 'Sem beneficiário'}`}</p>
        <p>{`Valor do Documento: ${formats.currency(amount)}`}</p>
      </>
    );
  },
  conditionalStyle: row => {
    let scDate = _.get(row, 'scheduledDate');
    let style = {};

    if (_.get(row, 'wrongValues')) {
      style.background = '#fff4e6';
    }
    if (scDate && formats.dateTimeZone(scDate, 'yyyy-MM-dd') < formats.dateTimeZone(new Date(), 'yyyy-MM-dd')) {
      style.background = '#fee9eb';
    }
    if (_.get(row, 'isBlocked')) {
      style.background = '#cccccc';
      style.color = '#777777';
    }
    return style;
  },
  conditionalIcons: row => {
    let scDate = _.get(row, 'scheduledDate'),
      icons = [];

    if (scDate && formats.dateTimeZone(scDate, 'yyyy-MM-dd') < formats.dateTimeZone(new Date(), 'yyyy-MM-dd')) {
      icons.push({
        icon: BiCalendarEvent,
        color: '#de1919',
        tip: 'Ordem de pagamento vencida'
      });
    }
    if (_.get(row, 'wrongValues')) {
      icons.push({
        icon: MdWarningAmber,
        color: '#ff6300',
        tip: 'Ordem de pagamento inválida'
      });
    }
    if (_.get(row, 'isBlocked')) {
      icons.push({
        icon: MdLock,
        color: '#777777',
        tip: 'Ordem de pagamento bloqueada'
      });
    }
    return icons;
  }
};

const sortByPaymentOrderType = (a, b) => sortCaseInsesitive(a, b , 'paymentOrderType.name');
const sortByBusinessPartner = (a, b) => sortCaseInsesitive(a, b , 'businessPartner.name');
const sortByDocument = (a, b) => sortCaseInsesitive(a, b , 'documentNumber');
const sortByValue = (a, b) => sortByMaxOrMin(a, b, 'value');
const sortByTotal = (a, b) => {
  a = a.isPartial ? _.get(a, 'value') : _.get(a, 'orderData.total') || _.get(a, 'value');
  b = b.isPartial ? _.get(b, 'value') : _.get(b, 'orderData.total') || _.get(b, 'value');

  return a === b ? 0 : ( a > b ? 1 : -1 );
};
const sortByDueDate = (a, b) => sortByDate(a, b, 'dueDate')

const columns = [
  {
    name: 'Tipo da ordem',
    selector: 'paymentOrderType',
    compact: true,
    format: row => _.get(row, 'paymentOrderType.name'),
    sortable: true,
    sortFunction: sortByPaymentOrderType
  },
  {
    name: 'Documento',
    selector: 'documentNumber',
    width: '150px',
    format: row => _.get(row, 'documentNumber'),
    sortable: true,
    sortFunction: sortByDocument
  },
  {
    name: 'Beneficiário',
    selector: 'businessPartner',
    compact: true,
    format: row => {
      const name = _.get(row, 'businessPartner.name'),
        identity = _.get(row, 'businessPartner.identity');
      return `${formats.cnpj_cpf(identity)} - ${name}`;
    },
    sortable: true,
    sortFunction: sortByBusinessPartner
  },
  {
    name: 'Dt Vencimento',
    selector: 'dueDate',
    width: '100px',
    right: true,
    compact: true,
    format: row => formats.dateTimeZone(row.dueDate, 'dd/MM/yyyy'),
    sortable: true,
    sortFunction: sortByDueDate
  },
  {
    name: 'Valor Doc.',
    selector: 'value',
    right: true,
    width: '90px',
    compact: true,
    format: row => row.isPartial ? formats.currency(_.get(row, 'baseAmount')) : formats.currency(_.get(row, 'value')),
    sortable: true,
    sortFunction: sortByValue
  },
  {
    name: 'Valor a Pagar',
    selector: 'total',
    right: true,
    width: '90px',
    compact: true,
    format: row => row.isPartial ? formats.currency(_.get(row, 'value')) : formats.currency(_.get(row, 'orderData.total') || _.get(row, 'value')),
    sortable: true,
    sortFunction: sortByTotal
  },
];

const PreparePaymentOrder = ({ history, acls }) => {
  const canCreateShipping = acls.includes('S');
  const dispatch = useDispatch();
  const state = useSelector(state => state.preparePaymentOrder);
  const headerState = useSelector(state => state.header);
  const [openPrepareModal, setOpenPrepareModal] = useState(false);
  const [openSummaryPrepareModal, setOpenSummaryModal] = useState(false);
  const [dataToShipping, setDataToShipping] = useState({});
  const [selecteds, setSelecteds] = useState([]);
  const [bankAccountMovement, setBankAccountMovement] = useState();
  const canWrite = acls.includes('W');

  useEffect(() => {
    dispatch(
      HeaderActions.configure({
        subtitle: prepareSubtitle(),
        loading: state.loading,
        useFilter: true,
        filter: { ...headerState.filter, visible: false, scope: '/prepare-payment-order' }
      })
    );
    // eslint-disable-next-line
  }, [state, dispatch]);

  useEffect(() => {
    (() => {
      let { scheduledDateStart, scheduledDateEnd, company } = _.get(headerState, 'filter.data');
      handleFilter({ scheduledDateStart, scheduledDateEnd, company });
      dispatch(PreparePaymentOrderActions.listBankAccounts());
    })();
    // eslint-disable-next-line
  }, [dispatch]);

  const prepareSubtitle = () => {
    const scheduledDateStart = _.get(headerState, 'filter.data.scheduledDateStart');
    const scheduledDateEnd = _.get(headerState, 'filter.data.scheduledDateEnd');

    if (!scheduledDateStart || !scheduledDateEnd) {
      return '';
    }

    return `Período: ${formats.date(scheduledDateStart)} até ${formats.date(scheduledDateEnd)}`;
  };

  const handleHideFilter = () => {
    dispatch(HeaderActions.hideFilter());
  };

  const handleFilter = data => {
    dispatch(HeaderActions.callFilter(data, PreparePaymentOrderActions.load));
    dispatch(PreparePaymentOrderActions.listBankAccounts());
    handleHideFilter();
  };

  const onDragEnd = result => {
    if (!result.destination) return;
    dispatch(PreparePaymentOrderActions.movement(state.paymentOrders, result, state.bankAccounts));
  };

  const changeSelectPaymentOrders = data => {
    if (_.isArray(data)) {
      setSelecteds(data);
    } else {
      let list = _.cloneDeep(selecteds || []),
        index = _.findIndex(list, r => r.id === data.id);
      if (index >= 0) {
        list.splice(index, 1);
      } else {
        list.push(data);
      }
      setSelecteds(list);
    }
  };

  const handleSubmit = ({ bankAccount }, actions) => {
    actions.closeModal = () => setOpenPrepareModal(false);
    dispatch(PreparePaymentOrderActions.movementInBatch(bankAccount, selecteds, actions));
  };

  const handleListCompanies = (term, callback) => {
    dispatch(PreparePaymentOrderActions.listCompanies(term, callback));
  };

  function handleListBusinessPartners(term, callback) {
    dispatch(PreparePaymentOrderActions.listBusinessPartners(term, callback));
  }

  const prepareBankAccountsToSelect = (term, callback) => {
    const list = _.chain(state.bankAccounts)
      .filter(row => {
        let exists = false;

        const isBankAccount = row.id !== '000';
        if (_.isEmpty(term)) {
          return isBankAccount;
        }

        const termRegex = new RegExp(_.deburr(term), 'ig');
        const termRegexWithoutGlobalSearch = new RegExp(_.deburr(term), 'i');

        const accountNumberWithDigit = _.get(row?.extra, 'accountNumber') && _.get(row?.extra, 'accountNumberDigit') ? `${_.get(row?.extra, 'accountNumber')}-${_.get(row?.extra, 'accountNumberDigit')}` : '';
        const contractName = _.get(row, 'code') && _.get(row, 'subDetail') && accountNumberWithDigit ? `${_.get(row, 'code')} - ${accountNumberWithDigit} - ${_.get(row, 'subDetail')}` : '';
        const agencyWithDigit = _.get(row?.extra, 'bankBranch') && _.get(row?.extra, 'bankBranchDigit') ? `${_.get(row?.extra, 'bankBranch')}-${_.get(row?.extra, 'bankBranchDigit')}` : '';

        const existsLabel = termRegex.test(_.deburr(_.get(row, 'label'))); // Nome do banco
        const existsAccountNumber = termRegexWithoutGlobalSearch.test(accountNumberWithDigit); // Número da conta
        const existsCompayName = termRegex.test(_.deburr(_.get(row?.extra?.company, 'name'))); // Nome da empresa
        const existsSubDetail = termRegexWithoutGlobalSearch.test(_.get(row, 'subDetail')); // CNPJ
        const existsBankCode = termRegexWithoutGlobalSearch.test(_.get(row, 'code')); // Código do banco
        const existsContract = termRegex.test(_.deburr(contractName)); // Nome do Contrato
        const existsAgency = termRegexWithoutGlobalSearch.test(agencyWithDigit); // Agência + Digito

        exists = ( existsLabel || existsAccountNumber || existsCompayName || existsSubDetail || existsBankCode || existsContract || existsAgency);

        return isBankAccount && exists;
      })
      .map(row => {
        return row.extra;
      })
      .value();
    callback(list);
  };

  const handleBoardAction = boardId => {
    const bankAccount = boardId !== '000' ? state.bankAccounts[boardId] : null;
    setBankAccountMovement(bankAccount);
    setOpenPrepareModal(true);
  };

  const handleCardClick = ({ id }) => {
    history.push( {pathname: `payment-orders/${id}`, sourcepath: 'prepare-payment-orders' });
  };

  function handleListPaymentOrderType(term, callback) {
    dispatch(PreparePaymentOrderActions.listPaymentOrderType(term, callback));
  };

  function handleListBankContracts(term, callback) {
    dispatch(PreparePaymentOrderActions.listBankContracts({ term }, callback));
  }

  const validateOrders = (bankAccounts, data) => {
    let errors = {};

    for (let contractId in data) {
      let ordersList = data[contractId] || [],
        orderTypeMap = _.get(bankAccounts[contractId], 'ordersTypesMap') || {},
        bankAccount = _.get(bankAccounts[contractId], 'extra'),
        bankCode = _.get(bankAccount, 'bank.code');

      for (let order of ordersList) {
        let orderType = `${_.get(order, 'paymentOrderType.code')}`,
          typeMap = orderTypeMap[orderType] || {},
          reasons = [],
          hasError = false;

        if (!_.find(typeMap.entryType, { value: order.entryType }) || !_.find(typeMap.serviceType, { value: order.serviceType })) {
          hasError = true;
          reasons.push('invalid');
        }
        if (/^(10|11)$/.test(orderType) && bankCode === _.get(order, 'orderData.bank.code')) {
          reasons.push('invalid');
          hasError = true;
        }
        if (formats.dateTimeZone(_.get(order, 'scheduledDate'), 'yyyy-MM-dd') < formats.dateTimeZone(new Date(), 'yyyy-MM-dd')) {
          reasons.push('overdue');
          hasError = true;
        }
        if (_.get(order, 'wrongValues')) {
          reasons.push('invalid');
          hasError = true;
        }
        if (_.get(order, 'isBlocked')) {
          reasons.push('blocked');
          hasError = true;
        }

        if (hasError) {
          errors[contractId] = errors[contractId] || { orders: [], reasons: [], text: formatBankAccountGrid(bankAccount) };
          errors[contractId].orders.push(order.id);
          errors[contractId].reasons = _.concat(errors[contractId].reasons, _.uniq(reasons));
        }
      }
    }
    return { errors };
  };

  const onFooterClick = async ({ value }) => {
    const bankAccountid = _.get(value, 'id') || 'all';
    const data =
      bankAccountid === 'all' ? _.pickBy(state.paymentOrders, (v, k) => k !== '000' && _.size(v) > 0) : { [bankAccountid]: state.paymentOrders[bankAccountid] };
    const { errors } = validateOrders(state.bankAccounts, data);

    if (!_.isEmpty(errors)) {
      let orderIds = _.flattenDeep(_.map(_.values(errors), r => r.orders)),
        size = _.size(orderIds),
        invalidSize = _.size(_.filter(_.flattenDeep(_.map(_.values(errors), r => r.reasons)), r => r === 'invalid')),
        invalidText = invalidSize > 0 ? (invalidSize === 1 ? ', a inválida será marcada com (*)' : ', as inválidas serão marcadas com (*)') : '',
        message =
          size === 1
            ? `Uma ordem de pagamento está com problema${invalidText}, edite-a para continuar`
            : `${size} ordens de pagamento estão com problemas${invalidText}, edite-as para continuar`,
        entityFn = (len, singular, plural) => (len === 1 ? `Uma ordem ${singular}` : `${len} ordens ${plural}`);

      message += `<br/>`;
      for (let err of _.values(errors)) {
        let stats = _.groupBy(err.reasons, _.identity),
          invalidLen = _.size(stats['invalid']),
          overdueLen = _.size(stats['overdue']),
          blockedLen = _.size(stats['blocked']),
          parts = [];

        if (invalidLen > 0) {
          parts.push(entityFn(invalidLen, 'inválida', 'inválidas'));
        }
        if (overdueLen > 0) {
          parts.push(entityFn(overdueLen, 'vencida', 'vencidas'));
        }
        if (blockedLen > 0) {
          parts.push(entityFn(blockedLen, 'bloqueada', 'bloqueadas'));
        }
        message += `<br/>Contrato <strong>[${err.text}]</strong>: ${parts.join(', ')}`;
      }
      const result = await confirm.show({
        height: 300,
        width: 700,
        title: 'Atenção',
        text: message,
        options: [{ label: 'Fechar', value: 'skip' }, ...(invalidSize > 0 ? [{ label: 'Confirmar edição', value: 'edit', primary: true }] : [])]
      });

      if (result === 'edit') {
        dispatch(PreparePaymentOrderActions.markAsWrong({ ids: orderIds }));
      }
    } else {
      setDataToShipping(data);
      setOpenSummaryModal(true);
    }
  };

  const handleShippingCreate = () => {
    const closeModal = () => setOpenSummaryModal(false);
    dispatch(PreparePaymentOrderActions.shippingCreate(dataToShipping, { closeModal }));
  };

  const Filter = () => {
    return (
      <div className="right">
        <Formik onSubmit={handleFilter} initialValues={headerState.filter.data} enableReinitialize={true} validateOnMount={true}>
          {args => {
            return (
              <>
                <FormHeader>Filtro</FormHeader>

                <FormFilter
                  {...args}
                  onListPaymentOrderType={handleListPaymentOrderType}
                  onListBankContracts={handleListBankContracts}
                  onListCompanies={handleListCompanies}
                  onListBusinessPartners={handleListBusinessPartners}
                />

                <FormToolbar>
                  <IconButton title="Cancelar" disabled={state.loading} onClick={handleHideFilter}>
                    <MdKeyboardBackspace />
                  </IconButton>
                  <IconButton title="Limpar Filtro" disabled={state.loading} onClick={() => handleFilter({})}>
                    <MdClear />
                  </IconButton>
                  <IconButton title="Aplicar Filtro" disabled={state.loading} onClick={args.handleSubmit}>
                    <MdCheck />
                  </IconButton>
                </FormToolbar>
              </>
            );
          }}
        </Formik>
      </div>
    );
  };

  return (
    <Container showForm={headerState.filter.visible}>
      <div className="left">
        {_.size(state.bankAccounts) > 0 && (
          <Content>
            <Kanban
              summary={state.summaryData || {}}
              boards={state.bankAccounts}
              boardIconTip="Ver lista de ordens de pagamento"
              boardConditionalStyle={board => {
                let fileDestination = _.get(board, 'extra.bankContract.fileDestination');
                if (false && fileDestination === 'van') {
                  return { background: '#66666619' };
                }
                return {};
              }}
              boardConditionalIcons={board => {
                let bankContract = _.get(board, 'extra.bankContract') || {},
                  icons = [];

                if (bankContract.useApproval) {
                  icons.push({
                    icon: HiOutlineClipboardCheck,
                    color: '#444444',
                    tip: 'Contrato com aprovação de ordens de pagamento'
                  });
                }
                if (bankContract.fileDestination === 'van') {
                  icons.push({
                    icon: HiOutlineExternalLink,
                    color: '#444444',
                    tip: 'Remessas desse contrato são enviadas para a VAN'
                  });
                }

                if (board.id !== '000') {
                  icons.push({
                    icon: VscServerProcess,
                    tip: 'Gerar Remessa',
                    action: () => onFooterClick({ value: board })
                  });
                }

                return icons;
              }}
              cards={state.paymentOrders}
              cardOptions={cardOptions}
              cardField="id"
              onChangeCards={onDragEnd}
              onCardClick={handleCardClick}
              onBoardAction={canWrite ? handleBoardAction : _.noop}
              isDragDisabled={!canWrite}
            />
            <Footer
              data={state.bankAccounts}
              canCreateShipping={canCreateShipping}
              onClick={onFooterClick}
              company={_.get(headerState, 'filter.data.company') || {}}
            />
          </Content>
        )}
        <PrepareModal
          isOpen={openPrepareModal}
          closeModal={() => setOpenPrepareModal(false)}
          handleOnChangeSelect={changeSelectPaymentOrders}
          dataList={_.get(state.paymentOrders, _.get(bankAccountMovement, 'id') || '000')}
          dataColumns={columns}
          onListBankAccounts={prepareBankAccountsToSelect}
          selecteds={selecteds}
          bankAccountMovementFrom={bankAccountMovement}
          handleOnSubmit={handleSubmit}
        />
        <SummaryModal
          isOpen={openSummaryPrepareModal}
          closeModal={() => setOpenSummaryModal(false)}
          data={dataToShipping}
          bankAccounts={state.bankAccounts}
          onConfirm={handleShippingCreate}
          isSubmiting={state.isShippingCreating}
        />
      </div>

      <Filter />
    </Container>
  );
};

export default PreparePaymentOrder;
