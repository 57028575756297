import React from 'react';
import _ from 'lodash';
import { TabContainer, Item } from '~/pages/business-partners/styles';
import Fieldset from '~/components/fieldset';
import { Row, FlexRow } from '~/components/layout';
import { InputGroup, InputMask, Checkbox } from '~/components/form';
import { MASK_PHONE } from '~constants';
import { IconButton } from '~/components/button';
import { MdAdd, MdDelete } from 'react-icons/md';
import { Formik } from 'formik';
import { formats } from '~/helper';
import confirm from '~/components/confirm';

export const TabContacts = ({ values, setFieldValue, previewMode }) => {
  const contacts = _.get(values, 'extras.contacts') || [];
  const isCustomer = _.get(values, 'isCustomer');
  const isSupplier = _.get(values, 'isSupplier');

  const addContact = (contact, handleReset) => {
    if(isCustomer && !isSupplier){
      contact.isCustomer = true
      contact.isSupplier = false
    }
    if(!isCustomer && isSupplier){
      contact.isCustomer = false
      contact.isSupplier = true
    }
    setFieldValue('extras.contacts', [...contacts, contact]);
    handleReset();
  }

  async function removeContact(index) {
    if (index >= 0) {
      const result = await confirm.show({
        width: 450,
        title: 'Atenção',
        text: `Deseja realmente remover este contato ?`
      })

      if (result) {
        let list = _.cloneDeep(contacts)
        list.splice(index, 1)
        setFieldValue('extras.contacts', list)
      }
    }
  }

  const isAddDisabled = contact => {
    const { email, name } = contact
    return _.isEmpty(email) || _.isEmpty(name)
  }

  return (
    <Formik enableReinitialize={true} initialValues={{ name: '', email: '', phone: '', cellular: '' }}>
      {({ values, handleReset }) => (
        <TabContainer>
          <Fieldset label="Novo contato">
            { isCustomer && isSupplier &&
              <FlexRow direction="row">
                <Checkbox halfHeight={true} name="isSupplier" label="Fornecedor" disabled={previewMode} />
                <Checkbox halfHeight={true} name="isCustomer" label="Cliente" disabled={previewMode} />
              </FlexRow>
            }
            <Row span={3}>
              <InputGroup type="text" name="name" label="Nome" maxLength={100} />
              <InputMask name="phone" label="Telefone" value={values.phone || ''} mask={MASK_PHONE} />
              <InputMask name="cellular" label="Celular" value={values.cellular || ''} mask={MASK_PHONE} />
            </Row>
            <FlexRow direction="row">
              <InputGroup type="email" name="email" label="E-mail" maxLength={100} />
              <IconButton type="button" title="Adicionar" onClick={() => addContact(values, handleReset)} disabled={isAddDisabled(values)}>
                <MdAdd />
              </IconButton>
            </FlexRow>
          </Fieldset>

          <Fieldset label="Contatos">
            {_.isEmpty(contacts) && <span className="empty">Nenhum contato.</span>}

            {_.map(contacts || [], (value, index) => (
              <Item key={index} index={index}>
                <div className="column-data">
                  <div className="first">
                    <strong>{value.name}</strong>
                    <div>{value.email}</div>
                  </div>
                  { isCustomer && isSupplier &&
                    <>
                      <div className="supp-width">
                        <strong>Fornecedor</strong>
                        <div>{(_.get(value,'isSupplier') ? 'Sim' : 'Não')}</div>
                      </div>
                      <div className="cust-width">
                        <strong>Cliente</strong>
                        <div>{(_.get(value,'isCustomer') ? 'Sim' : 'Não')}</div>
                      </div>
                    </>
                  }
                  <div className="phone-width">
                    {(value.phone && formats.phone(value.phone))}
                  </div>
                  <div className="cell-width">
                    {(value.cellular && ` / ${formats.phone(value.cellular)}`)}
                  </div>
                </div>
                <IconButton size={36} margin="0">
                  <MdDelete onClick={() => removeContact(index)} />
                </IconButton>
              </Item>
            ))}
          </Fieldset>
        </TabContainer>
      )}
    </Formik>
  )
}
