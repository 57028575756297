import React from 'react'
import { Switch } from 'react-router-dom'
import Route from '~/routes/routes'
import Home from '~/pages/home'
import Help from '~/pages/help'
import Forbidden from '~/pages/forbidden'

import Auth from '~/pages/auth'
import Login from '~/pages/login'
import Accounts from '~/pages/accounts'
import BankAccounts from '~/pages/bank-accounts'
import BankStatements from '~/pages/bank-statements'
import BankContracts from '~/pages/bank-contracts'
import BusinessPartner from '~/pages/business-partners'
import Representatives from '~/pages/representatives'
import Companies from '~/pages/companies'
import Dashboard from '~/pages/dashboard'
import EmailBatchs from '~/pages/email-batchs'
import DigitalCharges from '~/pages/digital-charges'
import CreditsReceiveds from '~/pages/credits-receiveds'
import Landing from '~/pages/landing'
import Profiles from '~/pages/profiles'
import Responses from '~/pages/responses'
import Settings from '~/pages/settings'
import Shippings from '~/pages/shippings'
import Bill from '~/pages/bills'
import BillDetail from '~/pages/bills/form'
import BillsClient from '~/pages/bills-client'
import PrepareBills from '~/pages/prepare-bills'
import Users from '~/pages/users'
import Parsers from '~/pages/parsers'
import PaymentOrder from '~/pages/payment-orders'
import PaymentOrderDetail from '~/pages/payment-orders/form'
import PreparePaymentOrder from '~/pages/prepare-payment-order'
import PaymentApproval from '~/pages/payment-approvals'
import EmailTemplate from '~/pages/email-templates'
import EmailSettings from '~/pages/email-settings'
import WebhookBill from '~/pages/webhook-bills'
import WebhookPaymentOrder from '~/pages/webhook-payment-orders'
import PrepareBankStatement from '~/pages/prepare-bank-statements'
import BankStatementClassifications from '~/pages/bank-statement-classifications'
import BankStatementClassificationsDetail from '~/pages/bank-statement-classifications/details'
import ReportRequests from '~/pages/report-requests'
import EdiFiles from '~/pages/edi-files'
import PaymentEmailBatchs from '~/pages/payment-email-batchs'
import PaymentApprovalsPending from '~/pages/payment-approvals-pending'

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={Landing} />
      <Route exact path="/auth" component={Auth} />
      <Route exact path="/login" component={Login} isLogin />
      <Route exact path="/forbidden" component={Forbidden} />
      <Route exact path="/forbidden/:mode" component={Forbidden} />

      <Route path="/home" component={Home} isPrivate />
      <Route path="/help" component={Help} isPrivate />

      <Route path="/dashboard" component={Dashboard} isPrivate />

      <Route path="/users" component={Users} isPrivate />
      <Route path="/profiles" component={Profiles} isPrivate />

      <Route path="/settings" component={Settings} isPrivate />

      <Route exact path="/bills" component={Bill} isPrivate />
      <Route exact path="/bills/:id" component={BillDetail} isPrivate />
      <Route exact path="/bills-client" component={BillsClient} isPrivate />
      <Route exact path="/prepare-bills" component={PrepareBills} isPrivate />

      <Route
        exact
        path="/business-partners"
        component={BusinessPartner}
        isPrivate
      />
      <Route
        exact
        path="/business-partners/:id"
        component={BusinessPartner}
        isPrivate
      />

      <Route
        exact
        path="/representatives"
        component={Representatives}
        isPrivate
      />
      <Route
        exact
        path="/representatives/:id"
        component={Representatives}
        isPrivate
      />

      <Route exact path="/responses" component={Responses} isPrivate />
      <Route exact path="/responses/:id" component={Responses} isPrivate />

      <Route exact path="/shippings" component={Shippings} isPrivate />
      <Route exact path="/shippings/:id" component={Shippings} isPrivate />

      <Route
        exact
        path="/bank-statements"
        component={BankStatements}
        isPrivate
      />
      <Route
        exact
        path="/bank-statements/:id"
        component={BankStatements}
        isPrivate
      />

      <Route exact path="/bank-contracts" component={BankContracts} isPrivate />
      <Route
        exact
        path="/bank-contracts/:id"
        component={BankContracts}
        isPrivate
      />

      <Route exact path="/payment-orders" component={PaymentOrder} isPrivate />
      <Route
        exact
        path="/payment-orders/:id"
        component={PaymentOrderDetail}
        isPrivate
      />

      <Route
        exact
        path="/prepare-payment-orders"
        component={PreparePaymentOrder}
        isPrivate
      />

      <Route exact path="/email-batchs" component={EmailBatchs} isPrivate />
      <Route 
        exact 
        path="/email-batchs/:id" 
        component={EmailBatchs} 
        isPrivate 
      />

      <Route exact path="/accounts" component={Accounts} isPrivate />
      <Route exact path="/parsers" component={Parsers} isPrivate />
      <Route exact path="/companies" component={Companies} isPrivate />
      <Route exact path="/bank-accounts" component={BankAccounts} isPrivate />

      <Route
        exact
        path="/digital-charges"
        component={DigitalCharges}
        isPrivate
      />
      <Route
        exact
        path="/digital-charges/:id"
        component={DigitalCharges}
        isPrivate
      />
      <Route
        exact
        path="/credits-receiveds"
        component={CreditsReceiveds}
        isPrivate
      />
      <Route
        exact
        path="/credits-receiveds/:id"
        component={CreditsReceiveds}
        isPrivate
      />

      <Route
        exact
        path="/payment-approvals"
        component={PaymentApproval}
        isPrivate
      />

      <Route
        exact
        path="/email-templates"
        component={EmailTemplate}
        isPrivate
      />
      <Route exact path="/email-settings" component={EmailSettings} isPrivate />

      <Route exact path="/webhook-bills" component={WebhookBill} isPrivate />
      <Route exact path="/webhook-bills/:id" component={WebhookBill} isPrivate />

      <Route
        exact
        path="/webhook-payment-orders"
        component={WebhookPaymentOrder}
        isPrivate
      />

      <Route
        exact
        path="/prepare-bank-statements"
        component={PrepareBankStatement}
        isPrivate
      />

      <Route
        exact
        path="/prepare-bank-statements/:id"
        component={PrepareBankStatement}
        isPrivate
      />

      <Route
        exact
        path="/bank-statement-classifications"
        component={BankStatementClassifications}
        isPrivate
      />
      <Route
        exact
        path="/bank-statement-classifications/:id/:movementId"
        component={BankStatementClassificationsDetail}
        isPrivate
      />

      <Route exact path="/report-requests" component={ReportRequests} isPrivate />

      <Route exact path="/edi-files" component={EdiFiles} isPrivate />

      <Route exact path="/payment-email-batchs" component={PaymentEmailBatchs} isPrivate />
      <Route exact path="/payment-email-batchs/:id" component={PaymentEmailBatchs} isPrivate />

      <Route exact path="/payment-approvals-pending" component={PaymentApprovalsPending} isPrivate />

      <Route component={Forbidden} />
    </Switch>
  )
}
