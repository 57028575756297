import { all, put, call, takeLatest, delay } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import api from '~/services/api'
import { getError } from '~/helper'
import {
  Creators as PaymentOrderActions,
  Types as PaymentOrderTypes,
} from '~/store/modules/payment-order'
import history from '~/services/history'
import _ from 'lodash'
import confirm from '~/components/confirm'

export function showError(error) {
  const msg = getError(error)
  toast.error(msg)
}

export function* list({ params }) {
  try {
    const response = yield call(api.post, 'payment-orders/list', params)
    yield put(PaymentOrderActions.listSuccess(response.data))
  } catch (error) {
    showError(error)
  }
}

export function* load({ id }) {
  try {
    let data = {}
    if (id && id !== 'new') {
      const response = yield call(api.get, 'payment-orders/load', {
        params: { id },
      })
      data = response.data
    }

    yield put(PaymentOrderActions.loadData())
    yield put(PaymentOrderActions.loadSuccess(data))

    if (data.erpError) {
      let errMsg = `<p style="font-style: italic; padding:5px 10px;">${data.erpError}</p>`,
        mode = data.status === 'returned' ? 'devolução' : 'conciliação'

      const result = yield confirm.show({
        width: 800,
        height: 220,
        title: 'Atenção',
        text: `<strong>Ocorreu o erro abaixo durante a ${mode}</strong>: <br/>${errMsg}<br/> `,
        options: [
          { label: 'Ignorar', value: 'skip' },
          { label: 'Tentar novamente', value: 'send', primary: true },
        ],
      })

      if (result === 'send') {
        yield put(PaymentOrderActions.reprocessErp(_.pick(data, ['id','status'])))
        yield delay(500)
        yield put(PaymentOrderActions.load(_.get(data, 'id')))
      }
    }
  } catch (error) {
    yield put(PaymentOrderActions.loadSuccess({}, false))
    console.log(error)
    showError(error)
  }
}

export function* reprocessErp({ data }) {
  try {
    yield call(api.post, `payment-orders/reprocess-erp`, data)
    toast.success(`Tentativa de conciliação enviada novamente!`)
    yield put({ type: PaymentOrderTypes.LOAD, id: data.id })
  } catch (error) {
    showError(error)
  }
}

export function* requestErpData({ data }) {
  try {
    yield call(api.post, `payment-orders/request-erp-data`, data)
    toast.success(`Solicitação feita com sucesso!`)
    yield put({ type: PaymentOrderTypes.LOAD, id: data.id })
  } catch (error) {
    console.log(error)
    showError(error)
  }
}

export function* createOrUpdate({ data, actions }) {
  try {
    let formData = new FormData()
    formData.append('data', JSON.stringify(_.omit(data, 'files')))
    _.each(data.files, (file, index) => {
      formData.append(`files[${index}]`, file)
    })
    let editing = !!(data && data.id),
      method = editing ? 'put' : 'post',
      path = editing ? 'update' : 'create'

    let response = yield call(api[method], `payment-orders/${path}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      }),
      id = _.get(response, 'data.id')
    toast.success(
      `Ordem de pagamento ${editing ? 'atualizada' : 'criada'} com sucesso`,
    )

    if (actions.headLess) {
      yield put({ type: PaymentOrderTypes.LOAD, id })
    } else {
      yield put({ type: PaymentOrderTypes.LIST, params: { offset: 0 } })

      const historystate = _.get(history, 'location.state', ''),
        historysearch = _.get(history, 'location.search', ''),
        historysource = _.get(history, 'location.sourcepath')

      if (historysource) {
        const historyid = _.get(historystate, 'id')
        history.push({
          pathname: _.isEmpty(historyid)
            ? `/${historysource}`
            : `/${historysource}/${historyid}`,
          search: historysearch,
          state: historystate,
        })
      } else {
        history.push({
          pathname: `/payment-orders`,
          search: historysearch,
          state: historystate,
        })
      }
    }
  } catch (error) {
    showError(error)
  } finally {
    yield put(PaymentOrderActions.createOrUpdateSuccess())
  }
}

export function* loadData() {
  try {
    const response = yield call(api.get, 'payment-orders/load-data')
    yield put(PaymentOrderActions.loadDataSuccess(response.data))
  } catch (error) {
    showError(error)
  }
}

export function* listBankContracts({ params, callback }) {
  try {
    const response = yield call(api.get, 'payment-orders/list-bank-contracts', {
      params,
    })
    yield call(callback, response.data)
  } catch (error) {}
}

export function* listCompanies({ params, callback }) {
  try {
    const response = yield call(api.get, 'payment-orders/list-companies', {
      params,
    })
    yield call(callback, response.data)
  } catch (error) {}
}

export function* listBankAccountsGroups({ term, callback }) {
  try {
    const response = yield call(
      api.get,
      'payment-orders/list-bank-accounts-groups',
      { params: { term } },
    )
    yield call(callback, response.data)
  } catch (error) {}
}

export function* listPaymentOrderTypes() {
  try {
    const response = yield call(
      api.get,
      'payment-orders/list-payment-order-types',
    )
    yield put(PaymentOrderActions.listPaymentOrderTypesSuccess(response.data))
  } catch (error) {}
}

export function* listBanks({ params, callback }) {
  try {
    const response = yield call(api.get, 'payment-orders/list-banks', {
      params,
    })
    yield call(callback, response.data)
  } catch (error) {}
}

export function* listBusinessPartners({ term, callback }) {
  try {
    const response = yield call(
      api.get,
      'payment-orders/list-business-partners',
      { params: { term } },
    )
    yield call(callback, response.data)
  } catch (error) {}
}

export function* listCities({ state, term, callback }) {
  try {
    const response = yield call(api.get, 'payment-orders/list-cities', {
      params: { term, state },
    })
    yield call(callback, response.data)
  } catch (error) {}
}

export function* getPixQrCodeData({ code, model }) {
  try {
    const response = yield call(
      api.post,
      'payment-orders/get-pix-qr-code-data',
      { code },
    )
    yield put(PaymentOrderActions.getPixQrCodeDataSuccess(model, response.data))
  } catch (error) {
    showError(error)
    yield put(PaymentOrderActions.getPixQrCodeDataSuccess(model, {}))
  }
}

export function* saveBusinessPartnerModal({ data, model }) {
  try {
    let response = yield call(
      api.post,
      `payment-orders/create-business-partner`,
      data,
    )
    yield put(
      PaymentOrderActions.saveBusinessPartnerModalSuccess(response.data, model),
    )
    toast.success(`Beneficiário cadastrado com sucesso.`)
  } catch (error) {
    showError(error)
    yield put(PaymentOrderActions.saveBusinessPartnerModalSuccess({}, model))
  }
}

export function* cnpjSearch({ cnpj, model }) {
  try {
    const response = yield call(api.get, 'payment-orders/cnpj-search', {
      params: { cnpj },
    })
    const data = { ...model, ...response.data }
    yield put(PaymentOrderActions.searchCnpjResult(data, model))
  } catch (error) {
    yield put(PaymentOrderActions.searchCnpjResult(null, model))
    showError(error)
  }
}

export function* cancel({ data, action }) {
  try {
    yield call(api.post, 'payment-orders/cancel', { id: _.get(data, 'id'), cancellationReason: _.get(data, 'cancellationReason') })
    toast.success(`Ordem de pagamento cancelada com sucesso`)
    if(action) {
      action.toggleModal();
    }
    history.push('/payment-orders')
  } catch (error) {
    showError(error)
  } finally {
    yield put(PaymentOrderActions.createOrUpdateSuccess())
  }
}

export function* cancelBatch({ data, filter }) {
  try {
    yield call(api.post, `payment-orders/cancel-batch`, data)
    const message =
      _.size(data.ids) === 1
        ? 'Ordem de pagamento cancelada com sucesso!'
        : 'Ordens de pagamentos canceladas com sucesso!'
    toast.success(message)
    yield put(PaymentOrderActions.list({ ...filter, offset: 0 }))
  } catch (error) {
    showError(error)
  }
}

export function* block({ data }) {
  try {
    yield call(api.post, 'payment-orders/block', { id: _.get(data, 'id') })
    toast.success(`Ordem de pagamento bloqueada com sucesso`)
    history.push('/payment-orders')
  } catch (error) {
    showError(error)
  } finally {
    yield put(PaymentOrderActions.createOrUpdateSuccess())
  }
}

export function* unblock({ data }) {
  try {
    yield call(api.post, 'payment-orders/unblock', { id: _.get(data, 'id') })
    toast.success(`Ordem de pagamento desbloqueada com sucesso`)
    history.push('/payment-orders')
  } catch (error) {
    showError(error)
  } finally {
    yield put(PaymentOrderActions.createOrUpdateSuccess())
  }
}

export function* unlink({ data }) {
  try {
    yield call(api.post, 'payment-orders/unlink-digital-charge', {
      id: _.get(data, 'id'),
    })
    toast.success(`Ordem de pagamento desvinculada com sucesso`)
    history.push('/payment-orders')
  } catch (error) {
    showError(error)
  } finally {
    yield put(PaymentOrderActions.createOrUpdateSuccess())
  }
}

export function* listBusinessPartnerGroup({ term, callback }) {
  try {
    const response = yield call(
      api.get,
      'business-partners/list-business-partner-groups',
      { params: { term } },
    )
    yield call(callback, response.data)
  } catch (error) {}
}

export function* downloadFile({ data }) {
  try {
    const params = { id: data.id, filename: data.filename }
    const response = yield call(api.get, 'payment-orders/download-file', {
      params,
      responseType: 'blob',
    })

    api.download(response, data.filename)
  } catch (error) {
    showError(error)
  }
}

export function* changeScheduledDate({
  data = [],
  scheduledDate,
  actions: { closeModal, filterData },
}) {
  try {
    const ids = _.map(data, 'id')
    yield call(api.patch, 'payment-orders/change-scheduled-date', {
      ids,
      scheduledDate,
    })
    toast.success(`Ordem de pagamento desvinculada com sucesso`)
    yield put(PaymentOrderActions.list({ ...filterData, offset: 0 }))
    closeModal()
  } catch (error) {
    showError(error)
  }
}

export function* sendEmail({ data }) {
  if (!data.ids) {
    data.ids = []
    if (data.id) {
      data.ids.push(data.id)
    }
  }

  try {
    let response = yield call(api.post, `payment-orders/send-email`, data),
      message = 'E-mail enviado'
    toast.success(message)
    yield put(PaymentOrderActions.sendEmailSuccess(response.data))
  } catch (error) {
    console.log(error)
    showError(error)
  }
}

export function* reopenStatus({ data }) {
  try {
    yield call(api.post, 'payment-orders/reopen-status', { id: _.get(data, 'id') })
    toast.success(`Ordem de pagamento reaberta com sucesso`)
  } catch (error) {
    showError(error)
  } finally {
    yield put(PaymentOrderActions.createOrUpdateSuccess())
    yield put(PaymentOrderActions.reopenStatusSuccess(data))
  }
}

export function* reopenBatch({ data, filter }) {
  try {
    yield call(api.post, `payment-orders/reopen-batch`, data)
    const message =
      _.size(data.ids) === 1
        ? 'Ordem de pagamento reaberta com sucesso!'
        : 'Ordens de pagamentos reabertas com sucesso!'
    toast.success(message)
    yield put(PaymentOrderActions.list({ ...filter, offset: 0 }))
  } catch (error) {
    showError(error)
  }
}

export function* blockUnblockBatch({ data, filter }) {
  try {
    yield call(api.post, `payment-orders/block-unblock-batch`, data)

    const rows = _.get(data,'rows') || [],
      listIsBlocked = _.map(rows, 'isBlocked'),
      types = _.uniq(listIsBlocked);

    const blockedText = _.size(types) === 1
      ? types[0] === true
        ? _.size(data.ids) === 1 ? 'desbloqueada' : 'desbloqueadas'
        : _.size(data.ids) === 1 ? 'bloqueada' : 'bloqueadas'
      : _.size(data.ids) === 1 ? 'bloqueada/desbloqueada' : 'bloqueadas/desbloqueadas'

    const message =
      _.size(data.ids) === 1
        ? `Ordem de pagamento ${blockedText} com sucesso!`
        : `Ordens de pagamentos ${blockedText} com sucesso!`
    toast.success(message)
    yield put(PaymentOrderActions.list({ ...filter, offset: 0 }))
  } catch (error) {
    showError(error)
  }
}

export function* processInstruction({ data, action }) {
  try {
    yield call(api.post, 'payment-orders/process-instruction', data);
    toast.success(`Instrução processada com sucesso!`);
    action.toggleModal();
    yield put(PaymentOrderActions.load(_.get(data, 'paymentOrderId')));
  } catch (error) {
    showError(error);
  }
  yield put(PaymentOrderActions.processInstructionSuccess());
}

export default all([
  takeLatest(PaymentOrderTypes.LIST, list),
  takeLatest(PaymentOrderTypes.LOAD, load),
  takeLatest(PaymentOrderTypes.LOAD_DATA, loadData),
  takeLatest(PaymentOrderTypes.LIST_PAYMENT_ORDER_TYPES, listPaymentOrderTypes),
  takeLatest(PaymentOrderTypes.LIST_BANKS, listBanks),
  takeLatest(PaymentOrderTypes.LIST_BANK_CONTRACTS, listBankContracts),
  takeLatest(PaymentOrderTypes.LIST_COMPANIES, listCompanies),
  takeLatest(
    PaymentOrderTypes.LIST_BANK_ACCOUNTS_GROUPS,
    listBankAccountsGroups,
  ),
  takeLatest(PaymentOrderTypes.LIST_CITIES, listCities),
  takeLatest(PaymentOrderTypes.REQUEST_ERP_DATA, requestErpData),
  takeLatest(PaymentOrderTypes.LIST_BUSINESS_PARTNERS, listBusinessPartners),
  takeLatest(PaymentOrderTypes.CREATE_OR_UPDATE, createOrUpdate),
  takeLatest(PaymentOrderTypes.CANCEL, cancel),
  takeLatest(PaymentOrderTypes.CANCEL_BATCH, cancelBatch),
  takeLatest(PaymentOrderTypes.BLOCK, block),
  takeLatest(PaymentOrderTypes.UNBLOCK, unblock),
  takeLatest(PaymentOrderTypes.UNLINK, unlink),
  takeLatest(PaymentOrderTypes.GET_PIX_QR_CODE_DATA, getPixQrCodeData),
  takeLatest(
    PaymentOrderTypes.SAVE_BUSINESS_PARTNER_MODAL,
    saveBusinessPartnerModal,
  ),
  takeLatest(PaymentOrderTypes.SEARCH_CNPJ_REQUEST, cnpjSearch),
  takeLatest(PaymentOrderTypes.DOWNLOAD_FILE, downloadFile),
  takeLatest(
    PaymentOrderTypes.LIST_BUSINESS_PARTNER_GROUP,
    listBusinessPartnerGroup,
  ),
  takeLatest(PaymentOrderTypes.CHANGE_SCHEDULED_DATE, changeScheduledDate),
  takeLatest(PaymentOrderTypes.SEND_EMAIL, sendEmail),
  takeLatest(PaymentOrderTypes.REPROCESS_ERP, reprocessErp),
  takeLatest(PaymentOrderTypes.REOPEN_STATUS, reopenStatus),
  takeLatest(PaymentOrderTypes.REOPEN_BATCH, reopenBatch),
  takeLatest(PaymentOrderTypes.BLOCK_UNBLOCK_BATCH, blockUnblockBatch),
  takeLatest(PaymentOrderTypes.PROCESS_INSTRUCTION, processInstruction)
])
