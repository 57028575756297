import React, { useCallback } from 'react';
import Modal from '~/components/modal';
import { ModalContainer, BankContractContainer, ContentContainer, LineContainer, LineHeader, GroupValues } from '~pages/prepare-bills/styles';
import _ from 'lodash';
import EmptyState from '~/components/empty-state';
import { formats } from '~/helper';

const SummaryModal = ({ data, bankContracts, isOpen, closeModal, onConfirm, isSubmiting }) => {
  const BankContract = ({ id }) => {
    const bankContract = _.get(bankContracts, id) || {};
    return (
      <BankContractContainer>
        <div>
          {bankContract.logo && <img className="logo" src={bankContract.logo} alt="action" />}
          <h2>
            <label>{bankContract.label}</label>
            <span>{bankContract.detail}</span>
          </h2>
        </div>
      </BankContractContainer>
    );
  };

  const getLines = useCallback(() => {
    return _.chain(data)
      .pickBy((v, k) => _.size(v) > 0)
      .keys()
      .value();
  }, [data]);

  const Summary = ({ data }) => {
    const lines = getLines();

    if (_.size(lines) === 0) {
      return <EmptyState text="Sem movimento" visible={true} />;
    }

    return lines.map((id, index) => {
      const values = data[id];
      const total = _.reduce(values, (memo, row) => memo + _.get(row, 'value'), 0);
      return (
        <LineContainer key={id} index={index}>
          <BankContract id={id} />
          <GroupValues>
            <div className="f-150 f-center">
              <span>{_.size(values)}</span>
            </div>
            <div className="f-200 f-right">
              <span>{formats.currency(total)}</span>
            </div>
          </GroupValues>
        </LineContainer>
      );
    });
  };

  return (
    <Modal
      width="800px"
      height="600px"
      title="Sumário"
      hideClose={true}
      open={isOpen}
      bindRefWithSubmit={true}
      hide={closeModal}
      actions={[
        {
          label: 'Fechar',
          action: () => {
            closeModal();
          },
          disabled: isSubmiting
        },
        {
          label: isSubmiting ? 'Aguarde...' : `Gerar remessa${_.size(_.keys(data)) === 1 ? '' : 's'}`,
          action: () => onConfirm && onConfirm(),
          type: 'submit',
          primary: true,
          disabled: isSubmiting || _.size(getLines()) === 0
        }
      ]}
    >
      <ModalContainer>
        <ContentContainer>
          {_.size(getLines()) > 0 && (
            <LineHeader>
              <div className="f-1">
                <label>Contrato</label>
              </div>
              <div className="f-150 f-center">
                <label>Faturas</label>
              </div>
              <div className="f-200 f-right">
                <label>Valor</label>
              </div>
            </LineHeader>
          )}
          <Summary data={data} />
        </ContentContainer>
      </ModalContainer>
    </Modal>
  );
};

export default SummaryModal;
