import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import history from '~/services/history';
import GlobalStyle from '~/styles/global';
import { store, persistor } from '~/store';
import { GridProvider, gridTheme } from '~/components/layout';
import Routes from '~/routes';
import { ConnectedRouter } from 'connected-react-router';

const envNames = {
  production: '',
  stage: '-homolog',
  test: '-dev',
  development: '-local',
};

function App() {
  useEffect(() => {
   document.title = `DocPay @ v${process.env.REACT_APP_VERSION}${envNames[process.env.REACT_APP_ENV || 'development']}`;
  }, []);

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ConnectedRouter history={history}>
          <GridProvider gridTheme={gridTheme}>
            <Routes />
          </GridProvider>
          <GlobalStyle />
          <ToastContainer autoClose={1800} />
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
