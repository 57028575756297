import { createActions, createReducer } from 'reduxsauce';
import produce from 'immer';

export const { Types, Creators } = createActions({
  load: ['data'],
  loadSuccess: ['data'],
  list: ['params'],
  listSuccess: ['data'],
  cancel: ['id', 'actions'],
  save: ['data', 'actions'],
  reopen: ['data', 'actions'],
  clearModel: [],
  listBankContracts: ['term', 'callback'],
  listCompanies: ['term', 'callback'],
  listOrderDigitalCharge: ['params'],
  listOrderDigitalChargeSuccess: ['data'],
  unlink: ['id', 'actions'],
}, { prefix: 'digital-charge' });

const INITIAL_STATE = {
  loading: false,
  formLoading: false,
  data: { rows: [], count: 0, offset: 0 },
  model: {},
  listOrderDigitalCharge: []
};

const handleList = (state = INITIAL_STATE, action) => produce(state, draft => {
  draft.loading = true;
  draft.data = { rows: [], count: 0, offset: 0 };
  draft.model = {};
});

const handleListSuccess = (state = INITIAL_STATE, action) => produce(state, draft => {
  draft.loading = false;
  draft.data = action.data;
});

const handleLoad = (state = INITIAL_STATE, action) => produce(state, draft => {
  draft.model = {};
  draft.formLoading = true;
});

const handleLoadSuccess = (state = INITIAL_STATE, action) => produce(state, draft => {
  draft.formLoading = false;
  draft.model = action.data;
});

const handleClearModel = (state = INITIAL_STATE, action) => produce(state, draft => {
  draft.formLoading = false;
  draft.model = {};
});

const handleListOrderDigitalChargeSuccess = (state = INITIAL_STATE, action) => produce(state, draft => {
  draft.listOrderDigitalCharge = action.data;
});

export default createReducer(INITIAL_STATE, {
  [Types.LIST]: handleList,
  [Types.LOAD]: handleLoad,
  [Types.CLEAR_MODEL]: handleClearModel,
  [Types.LIST_SUCCESS]: handleListSuccess,
  [Types.LOAD_SUCCESS]: handleLoadSuccess,
  [Types.LIST_ORDER_DIGITAL_CHARGE_SUCCESS]: handleListOrderDigitalChargeSuccess,
});
