import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { getBankLogo, EmptyIcon } from '~/assets/banks';
import _ from 'lodash';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  pointer-events: none;
  font-size: 12px;
  line-height: 15px;

  .bank-icon {
    width: auto;
    max-width: 26px;
    height: 23px;
    margin-right: 5px;
    border-radius: 1px;
    text-align: center;
  }
  .bank-icon-svg svg {
    width: 26px;
    max-width: 26px;
    height: 26px;
    margin-right: 5px;
    border-radius: 1px;
    font-weight: bold;
  }
`;

const getBankText = ({ bankBranch, bankBranchDigit }) => {
  return bankBranch && bankBranchDigit ? `${bankBranch}-${bankBranchDigit}` : bankBranch ? bankBranch : '';
};

const getAccountText = ({ accountNumber, accountNumberDigit }) => {
  return accountNumber && accountNumberDigit ? `${accountNumber}-${accountNumberDigit}` : accountNumber ? accountNumber : '';
};

const CellBank = ({ bankAccount, bankCode }) => {
  let bank = _.get(bankAccount, 'bank.code') || bankCode;
  const bankIcon = getBankLogo(bank);

  if (!bankAccount && bankCode) {
    return (
      <Container>
        {bankIcon && <img className="bank-icon" alt="bank-icon" src={bankIcon} />}
        {!bankIcon && <span className="bank-icon-svg"> <EmptyIcon code={bank} /> </span>}
      </Container>
    );
  }

  if (!bankAccount || !bank) {
    return <Container></Container>
  }
  const bankText = getBankText(bankAccount);
  const accountText = getAccountText(bankAccount);

  return (
    <Container>
      {bankIcon && <img className="bank-icon" alt="bank-icon" src={bankIcon} />}
      {!bankIcon && <span className="bank-icon-svg"> <EmptyIcon code={bank} /> </span>}
      {bankText} <br />
      {accountText}
    </Container>
  );
}

CellBank.propTypes = {
  bankAccount: PropTypes.shape({
    bank: PropTypes.shape({
      code: PropTypes.string.isRequired
    }),
    bankNumber: PropTypes.string,
    bankNumberDigit: PropTypes.string,
    accountNumber: PropTypes.string,
    accountNumberDigit: PropTypes.string
  })
};

export default CellBank;
