import { all, put, call, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import _ from 'lodash';
import api from '~/services/api';
import { getError } from '~/helper';
import { Creators as PrepareBankStatementActions, Types as PrepareBankStatementTypes } from '~/store/modules/prepare-bank-statement';

export function showError(error) {
  const msg = getError(error);
  toast.error(msg);
}

export function* list({ params }) {
  try {
    const response = yield call(api.post, 'prepare-bank-statements/list', params);
    yield put(PrepareBankStatementActions.listSuccess(response.data));
  } catch (error) {
    showError(error);
  }
}

export function* load({ data }) {
  try {
    if (_.isEmpty(data)) {
      yield put(PrepareBankStatementActions.loadSuccess({ bankStatementClassification: { value: '', label: '' } }));
      return;
    }

    let model = {};
    if (_.get(data, 'id', false) && _.get(data, 'id') !== 'new') {
      let response = yield call(api.get, 'prepare-bank-statements/load', { params: { id: _.get(data, 'id') } });
      model = response.data;
    }
    
    yield put(PrepareBankStatementActions.loadSuccess(model));
  } catch (error) {
    showError(error);
  }
}

export function* listBankStatementClassification() {
  try {
    const response = yield call(api.get, 'prepare-bank-statements/list-bank-statement-classifications', { params: { noLimit: true } });
    yield put(PrepareBankStatementActions.listBankStatementClassificationSuccess(response.data));
  } catch (error) {}
}

export function* createOrUpdate({ data, actions }) {
  try {
    let editing = !!(data && data.id),
      method = editing ? 'put' : 'post',
      path = editing ? 'update' : 'create';

    yield call(api[method], `prepare-bank-statements/${path}`, data);

    toast.success(`Pré-classificação ${editing ? 'atualizada' : 'criada'} com sucesso`);
    actions.closeForm && actions.closeForm();
    actions.reloadList && actions.reloadList();
  } catch (error) {
    showError(error);
  }
}

export function* remove({ data, actions }) {
  try {
    let params = _.pick(data, 'id');
    yield call(api.delete, `prepare-bank-statements/remove`, { params });

    toast.success(`Pré-classificação removida com sucesso`);
    actions.closeForm && actions.closeForm();
    actions.reloadList && actions.reloadList();
  } catch (error) {
    showError(error);
  }
}

export default all([
  takeLatest(PrepareBankStatementTypes.LIST, list),
  takeLatest(PrepareBankStatementTypes.LIST_BANK_STATEMENT_CLASSIFICATION, listBankStatementClassification),
  takeLatest(PrepareBankStatementTypes.LOAD, load),
  takeLatest(PrepareBankStatementTypes.CREATE_OR_UPDATE, createOrUpdate),
  takeLatest(PrepareBankStatementTypes.REMOVE, remove)
]);
