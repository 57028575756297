import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import PortalLayout from '~/pages/_layouts/portal';
import DefaultLayout from '~/pages/_layouts/default';
import { store } from '~/store';
import _ from 'lodash';

const publicRoutes = ['/auth', '/login', '/home', '/help', '/forbidden', '/parsers']

const getLayout = (state, rest) => {
  const { auth: { menu, signed, headLess = false } } = state,
    { path = '' } = rest,
    menuItem = _.find(menu, (r) => r.path === path || path.startsWith(`${r.path}/`) ),
    isOk = (publicRoutes.includes(path) || menuItem),
    Layout = (!!signed) ? DefaultLayout : PortalLayout,
    redirectToHome = signed && [ '/' ].includes(path);

  return { signed: !!signed, redirectToHome, headLess, isOk: !!isOk, Layout, acls: _.get(menuItem, 'perms') || [] };
}
function setRedirectURL(path) {
  const isNotForm = /^\/[a-zA-Z]*(|-[a-zA-Z]*(|-[a-zA-Z]*))$/.test(path);
  const isNotHome = path !== '/home';

  const date = new Date();
  date.setSeconds(date.getSeconds() + 15);

  const expires = date.toUTCString();

  if(path !== '/' && isNotForm) { // /rota-exemplo
    localStorage.setItem('redirectToURL', JSON.stringify({ url: path, expires }));
  }
  else if(path !== '/' && !isNotForm && isNotHome) { // /rota-exemplo/id
    const redirectToURLWithoutId = `/${path.split('/')[1]}`; // /rota-exemplo
    localStorage.setItem('redirectToURL', JSON.stringify({ url: redirectToURLWithoutId, expires }));
  }
}

export default function RouteWrapper({ component: Component, isPrivate, isLogin, ...rest }) {
  let { signed, redirectToHome, isOk, headLess, Layout, acls } = getLayout(store.getState(), rest)

  if (!signed && headLess && isOk) {
    return <Redirect to='/forbidden/try-again' />;
  }

  const path = window.location.pathname;
  const isNotHomeOrLogin = path !== '/home' && path !== '/login';
  const isLogged = (signed && isOk);
  let canRedirect = true;

  if(isLogged && isNotHomeOrLogin) {
    canRedirect = false;
    setRedirectURL(path);
  }

  if (!signed && !isOk) {
    setRedirectURL(path)
    return <Redirect to='/login' />;
  }

  const redirect = JSON.parse(localStorage.getItem('redirectToURL'));
  const hasRedirectURL = Boolean(redirect);

  if(hasRedirectURL && signed && isOk && canRedirect) {
    localStorage.removeItem('redirectToURL');

    const { url, expires } = redirect;

    const routeIsNotExpired = new Date(expires).getTime() > new Date().getTime();

    if(routeIsNotExpired) return <Redirect to={url} />;
  }
  if (redirectToHome) {
    return <Redirect to='/home' />;
  }
  if (signed && !isOk) {
    return <Redirect to='/forbidden' />;
  }
  if(!signed && !redirectToHome && path === '/home') {
    return <Redirect to='/login' />;
  }

  return (
    <Route
      {...rest}
      render={props => (
        <Layout headLess={headLess} isLogin={isLogin}>
          <Component {...props} headLess={headLess} acls={acls} />
        </Layout>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false
};
