import api from '~/services/api';
import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { getError } from '~/helper';
import { Types as AccountTypes, Creators as AccountActions } from '~/store/modules/account';

export function showError(error) {
  const msg =  getError(error);
  toast.error(msg);
}
export function* list({ params }) {
  try {
    const response = yield call(api.post, 'accounts/list', params);
    yield put(AccountActions.listSuccess(response.data));
  } catch (error) {
    showError(error);
  }
};

export function* load({ data }) {
  try {
    const response = yield call(api.get, 'accounts/load', { params: { id: data.id } });
    yield put(AccountActions.loadSuccess(response.data));
  } catch (error) {
    showError(error);
  }
};

export function* resetPassword({ data, actions }) {
  try {
    let response = yield call(api.put, 'accounts/reset-password', data),
      result = response.data;

    toast.success(`Senha redefinida com sucesso, dados enviados para o e-mail: ${result.email}`);
    actions.closeForm && actions.closeForm();
    actions.reloadList && actions.reloadList();
  } catch (error) {
    showError(error);
  }
};

export default all([
  takeLatest(AccountTypes.LIST, list),
  takeLatest(AccountTypes.LOAD, load),
  takeLatest(AccountTypes.RESET_PASSWORD, resetPassword),
]);
