import { all, put, call, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import _ from 'lodash';
import api from '~/services/api';
import { getError } from  '~/helper';
import { Creators as PaymentConciliationActions, Types as PaymentConciliationTypes } from '~/store/modules/payment-conciliation';

export function showError(error) {
  const msg =  getError(error);
  toast.error(msg);
};

export function* load({ id }) {
  try {
    const response = yield call(api.get, 'payment-conciliation/load', { params: { id } });
    yield put(PaymentConciliationActions.loadSuccess(response.data));
  } catch (error) {
    showError(error);
  }
};

export function* list({ params }) {
  try {
    const response = yield call(api.post, 'payment-conciliation/list', params);
    yield put(PaymentConciliationActions.listSuccess(response.data));
  } catch (error) {
    showError(error);
  }
};

export function* create({ data, source, callback }) {
  try {
    let formData = new FormData();
    formData.append('data', JSON.stringify(_.omit(data, 'file')))
    formData.append('file', _.get(data, 'file[0]'))
    yield call(api.post, `payment-conciliation/create`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
    toast.success(`Arquivo processado com sucesso!`);
    callback();
  } catch (error) {
    showError(error);
  } finally {
    yield put(PaymentConciliationActions.createSuccess(data));
  }
};

export function* update({ data, source }) {
  try {
    yield call(api.put, `payment-conciliation/update`, data);
    toast.success(`Pagamento atualizado com sucesso`);
  } catch (error) {
    showError(error);
  }
};

export function* updateWithPassword({ data, source, callback }) {
  try {
    yield call(api.post, `payment-conciliation/update-password`, data);
    toast.success(`Anexo do e-mail reprocessado com sucesso`);
    yield put(PaymentConciliationActions.updateWithPasswordSuccess(data));
    callback();
  } catch (error) {
    showError(error);
  }
};

export function* listPayments({params, callback}) {
  try {
    const response = yield call(api.get, 'payment-conciliation/list-payments', { params });
    yield call(callback, response.data);
  } catch (error) {
  }
};

export default all([
  takeLatest(PaymentConciliationTypes.LOAD, load),
  takeLatest(PaymentConciliationTypes.LIST, list),
  takeLatest(PaymentConciliationTypes.UPDATE, update),
  takeLatest(PaymentConciliationTypes.CREATE, create),
  takeLatest(PaymentConciliationTypes.UPDATE_WITH_PASSWORD, updateWithPassword),
  takeLatest(PaymentConciliationTypes.LIST_PAYMENTS, listPayments),
]);
