import React from 'react';
import _ from 'lodash';
import { FormContainer, InputButton, AttachmentTableContainer, ContainerCopy } from '~/pages/bills-client/styles';
import { statusLabels } from '~/pages/bills';
import { MdFileDownload } from 'react-icons/md';
import { FaRegCopy } from 'react-icons/fa';
import { GrDocumentPdf } from 'react-icons/gr';
import Fieldset from '~/components/fieldset';
import DataTable from '~/components/datatable';
import { InputLabel } from '~/components/form';
import { BASE_URL } from '~/constants';
import { Row, FlexRow, FlexCol } from '~/components/layout';
import Spinner from '~/components/spinner';
import { IconButton } from '~/components/button';
import { formats } from 'helper';

function handlePdf(model) {
  const { id } = model;
  if (!_.isEmpty(id)) {
    window.open(`${BASE_URL}/bills/pdf/${id}`, '_blank');
  }
};

const customStyles = {
  rows: {
    denseStyle: {
      minHeight: '34px'
    }
  }
};

export default ({ values, loading, onDownloadFile }) => {
  let attachments = _.cloneDeep(_.get(values, 'attachments') || []);

  if (_.get(values, 'billData.generated')) {
    attachments.unshift({ name: 'fatura.pdf', type: 'local' });
  }

  if (loading) {
    return (
      <FormContainer>
        <Spinner visible={true} />
      </FormContainer>
    );
  }

  const getRecipient = data => {
    const identity = _.get(data, 'bankContract.bankAccount.company.identity'),
      name = _.get(data, 'bankContract.bankAccount.company.name');
    return !identity || !name ? '' : `${formats.cnpj_cpf(identity)} - ${name}`;
  };

  const formattedDigitableLine = (values = {}) => {
    const str = _.get(values, 'billData.digitableLine') || '';
    return formats.digitableLine(str);
  };

  const CopyIcon = ({ values }) => {
    return (
      <InputButton title="Clique para Copiar" onClick={() => navigator.clipboard.writeText(_.get(values, 'billData.digitableLine'))}>
        <FaRegCopy />
      </InputButton>
    );
  };
  
  const billStatus = _.get(values, 'registered') && _.get(values,'status') === 'open' ? 'registered' : _.get(values,'status');

  return (
    <FormContainer>
      <FlexRow gap="0px">
        <FlexCol flex="1">
          <InputLabel label="Linha digitável" value={formattedDigitableLine(values)} />
        </FlexCol>

        <FlexCol flex="0 0 40px">
          <ContainerCopy>
            <CopyIcon values={values} />
          </ContainerCopy>
        </FlexCol>
      </FlexRow>

      <Row span={3}>
        <InputLabel label="Situação" value={statusLabels[billStatus] || ''} />

        <InputLabel label="Nosso Número" value={_.get(values, 'billData.ourNumber') || ''} />

        <InputLabel label="Valor" value={formats.currency(_.get(values, 'value'))} />
      </Row>

      <Row>
        <InputLabel label="Beneficiário" value={getRecipient(values)} />
      </Row>

      <InputLabel label="Banco" value={`${_.get(values, 'bankContract.bankAccount.bank.code')} - ${_.get(values, 'bankContract.bankAccount.bank.name')}`} />

      <Row span={2}>
        <InputLabel label="Data de vencimento" value={formats.dateTimeZone(_.get(values, 'dueDate'), 'dd/MM/yyyy')} />
        <InputLabel label="Data de pagamento" value={formats.dateTimeZone(_.get(values, 'payDate'), 'dd/MM/yyyy')} />
      </Row>

      <Row span={2}>
        <InputLabel label="Número do documento" value={_.get(values, 'documentNumber') || ''} />

        <InputLabel label="Data do documento" value={formats.dateTimeZone(_.get(values, 'createdAt'), 'dd/MM/yyyy')} />
      </Row>

      <Fieldset label="Arquivos">
        <AttachmentTableContainer>
          <DataTable
            emptyText="Nenhum anexo vinculado"
            noPagination={true}
            padding="0"
            onRowClicked={_.noop}
            data={{ rows: attachments }}
            extraOptions={{
              noTableHead: true,
              customStyles,
            }}
            columns={[
              {
                name: 'Arquivo',
                selector: 'name',
                format: (row) => _.get(row, 'name') || ''
              },
              {
                name: 'Ação',
                width: '30px',
                center: true,
                cell: (row, index) => <>
                  <IconButton
                    size={28}
                    title="Baixar arquivo"
                    noMargin={true}
                    onClick={() =>  {
                      if (row.type === 'local') {
                        handlePdf(values)
                      } else {
                        onDownloadFile(values, row)
                      }
                    }}>
                    {row.type === 'local' ? <GrDocumentPdf /> : <MdFileDownload />}
                  </IconButton>
                </>
              }
            ]}
            />
        </AttachmentTableContainer>
      </Fieldset>
    </FormContainer>
  );
};
