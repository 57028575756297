import styled from 'styled-components';
import { white } from '~/components/mixins/color';
import { Form } from 'formik';

export const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  padding: 10px;
  position: relative;
`;

export const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 10px;
  background: ${white.hex()};
  overflow-x: hidden;
  overflow-y: auto;

  @media (max-width: 425px) {
    max-height: 100%;
  }
`;

export const FormToolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-height: 56px;
  height: 56px;
  border-top: 1px solid #e0e0e0;
  padding: 10px;
  background: ${white.hex()};
`;
