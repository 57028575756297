import React, { useState } from 'react';
import _ from 'lodash';
import DataTable from '~/components/datatable';
import { formats } from 'helper';
import TextWithHint from '~/components/text-with-hint';

const columns = [
    {
      name: 'Nosso número',
      selector: 'billData',
      cell: (row) => <TextWithHint text={_.get(row, 'billData.ourNumber') ||  _.get(row, 'ourNumber') || ''} />
    },
    {
      name: 'Vencimento',
      width: '110px',
      selector: 'dueDate',
      cell: (row) => <TextWithHint text={formats.dateTimeZone(_.get(row, 'dueDate'), 'dd/MM/yyyy')} />
    },
    {
      name: 'Valor (R$)',
      selector: 'value',
      width: '110px',
      right: true,
      cell: (row) => <TextWithHint text={formats.decimal(_.get(row, 'value'))} />
    },
    {
      name: 'Ocorrência',
      width: '62px',
      selector: 'occurrence',
      center: true,
      cell: (row) => <TextWithHint text={_.get(row, 'occurrence')} />
    },
    {
      name: 'Pago em',
      width: '110px',
      selector: 'payDate',
      right: true,
      cell: (row) => <TextWithHint text={formats.dateTimeZone(_.get(row, 'payDate'), 'dd/MM/yyyy')} />
    },
    {
      name: 'Pago (R$)',
      selector: 'payAmount',
      right: true,
      cell: (row) => <TextWithHint text={formats.decimal(_.get(row, 'payAmount'))} />
    },
    {
      name: 'Multa (R$)',
      selector: 'fine',
      right: true,
      cell: (row) => <TextWithHint text={formats.decimal(_.get(row, 'fine'))} />
    },
    {
      name: 'Juros (R$)',
      selector: 'interest',
      right: true,
      cell: (row) => <TextWithHint text={formats.decimal(_.get(row, 'interest'))} />
    }
  ],
  paymentColumns = [
    {
      name: 'Vencimento',
      width: '110px',
      selector: 'dueDate',
      cell: (row) => <TextWithHint text={formats.dateTimeZone(_.get(row, 'dueDate'), 'dd/MM/yyyy')} />
    },
    {
      name: 'Valor (R$)',
      selector: 'value',
      width: '110px',
      right: true,
      cell: (row) => <TextWithHint text={formats.decimal(_.get(row, 'value'))} />
    },
    {
      name: 'Ocorrência',
      width: '62px',
      selector: 'occurrence',
      center: true,
      cell: (row) => <TextWithHint text={_.get(row, 'occurrence')} />
    },
    {
      name: 'Pago em',
      width: '110px',
      selector: 'payDate',
      right: true,
      cell: (row) => <TextWithHint text={formats.dateTimeZone(_.get(row, 'payDate'), 'dd/MM/yyyy')} />
    },
    {
      name: 'Pago (R$)',
      selector: 'payAmount',
      right: true,
      cell: (row) => <TextWithHint text={formats.decimal(_.get(row, 'payAmount'))} />
    },
    {
      name: 'Multa (R$)',
      selector: 'fine',
      right: true,
      cell: (row) => <TextWithHint text={formats.decimal(_.get(row, 'fine'))} />
    },
    {
      name: 'Juros (R$)',
      selector: 'interest',
      right: true,
      cell: (row) => <TextWithHint text={formats.decimal(_.get(row, 'interest'))} />
    }
  ];

const getRows = (array = [], offset, limit) => {
  return array.slice(offset, offset + limit);
};

export const TabNotFounds = ({ isPayment, notFounds }) => {
  const count = _.size(notFounds);

  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(20);

  const onChangePage = (offset) => {
    setOffset(offset);
    setLimit(limit);
  };

  return (
    <DataTable
      emptyText="Nenhum documento não encontrado"
      pageSize={limit}
      keyField={isPayment ? 'id' : 'ourNumber'}
      onChangePage={onChangePage}
      hideUpdateButton={true}
      data={{ rows: getRows(notFounds, offset, limit), offset, count, limit: limit }}
      columns={isPayment ? paymentColumns : columns}
      extraOptions={{
        ignoreRowClicked: true,
        selectableRows: false,
        selectableRowsHighlight: false
      }}
    />
  )
};
