import { all, takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { Creators as WebhookActions, Types as WebhookTypes } from '~/store/modules/webhook-bill';
import api from '~/services/api';
import { getError } from '~/helper';

export function* resend({ data, actions }) {
  try {
    const response = yield call(api.post, `webhook-bills/resend`, data),
      resp = response.data || {};
    let hasError = false;

    for(let r in resp) {
      if (!resp[r]) {
        hasError = true;
      }
    }
    actions.closeForm && actions.closeForm();
    actions.reloadList && actions.reloadList();
    yield put(WebhookActions.resendSuccess());
    
    if (hasError) {
      toast.error(`Webhook não enviado`);
    } else {
      toast.success(`Webhook reenviado com sucesso`);
    }
  } catch (error) {
    showError(error);
  }
};

export function* resendBatch({ data }) {
  try {
    yield call(api.post, `webhook-bills/resend`, data)
    const message = `Webhooks reenviados.`
    toast.success(message)
    yield put(WebhookActions.resendSuccess());
    yield put(WebhookActions.list({ offset: 0 }))
  } catch (error) {
    showError(error)
  }
}

export function* list({ params }) {
  try {
    const response = yield call(api.post, 'webhook-bills/list', params);
    yield put(WebhookActions.listSuccess(response.data));
  } catch (error) {
    showError(error);
  }
};

export function* load({ data }) {
  try {
    const response = yield call(api.get, 'webhook-bills/load', { params: { id: data.id || 'new' } });
    yield put(WebhookActions.loadSuccess(response.data));
  } catch (error) {
    showError(error);
  }
};

export function showError(error) {
  const msg = getError(error);
  toast.error(msg);
};

export default all([
  takeLatest(WebhookTypes.LIST, list),
  takeLatest(WebhookTypes.LOAD, load),
  takeLatest(WebhookTypes.RESEND, resend),
  takeLatest(WebhookTypes.RESEND_BATCH, resendBatch),
]);
