import React from 'react';
import { Form, Formik } from 'formik';

import Modal from '~/components/modal';
import { FormContainer, ForgetPasswordLink } from '~pages/landing/styles';
import { InputGroup } from '~/components/form';
import Logo from '~components/logo';

const renderLogin = ({ errors, isValid, touched, values, changeLoginMode }) => {
  return (
    <>
      <Logo inverse height="50px" />

      <InputGroup
        type="username"
        name="username"
        maxLength={100}
        label="E-mail"
        autoFocus
        autoComplete="new-password"
        value={values.username}
        hasError={errors.username && touched.username}
        />

      <InputGroup
        type="password"
        name="password"
        maxLength={50}
        label="Senha"
        autoComplete="new-password"
        value={values.password}
        hasError={errors.password && touched.password}
        />

      <ForgetPasswordLink onClick={() => changeLoginMode(false)}>
        Esqueci minha senha
      </ForgetPasswordLink>
    </>
  );
};

const renderForgetPassword = ({ errors, isValid, touched, values, changeLoginMode }) => {
  return (
    <>
      <Logo inverse height="50px" />

      <InputGroup
        type="username"
        name="username"
        maxLength={100}
        label="Usuário (login)"
        autoFocus
        autoComplete="new-password"
        value={values.username}
        hasError={errors.username && touched.username}
        />

      <ForgetPasswordLink onClick={() => changeLoginMode(true)}>
        Voltar para o Login
      </ForgetPasswordLink>
    </>
  );
};

const LoginForm = ({ isOpen, headLess, toggleModal, handleOnSubmit, redefinePassword, isLoginMode, changeLoginMode }) => {
  return (
    <Formik
      initialValues={isLoginMode ? { username: '', password: '' } : { username: '' }}
      enableReinitialize={true}
      validateOnMount={false}
      onSubmit={isLoginMode ? handleOnSubmit : redefinePassword}
      initialTouched={{ 'username': true }}
      >
      { ({ isValid, isSubmitting, handleReset, values, ...formArgs }) => (
        <Modal
          width="400px"
          height="352px"
          hideClose={true}
          open={isOpen}
          bindRefWithSubmit={true}
          hide={toggleModal}
          headLess={headLess}
          actions={
            [
              {
                label: 'Cancelar',
                action: () => { handleReset(); toggleModal(); }
              },
              isLoginMode ?
                {
                  label: isSubmitting ? 'Carregando...' : 'Entrar',
                  action: () => handleOnSubmit({ ...values, headLess }, formArgs),
                  type: 'submit',
                  disabled: isSubmitting || !values.username || !values.password,
                  primary: true
                }
                :
                {
                  label: isSubmitting ? 'Enviando...' : 'Redefinir senha',
                  action: () => redefinePassword(values, formArgs),
                  type: 'submit',
                  disabled: isSubmitting || !values.username,
                  primary: true
                }
            ]
          }
          >
          <FormContainer>
            <Form>
              {isLoginMode && renderLogin({ ...formArgs, values, changeLoginMode })}
              {!isLoginMode && renderForgetPassword({ ...formArgs, values, changeLoginMode })}
            </Form>
          </FormContainer>
        </Modal>
      )}
    </Formik>
  )
};

export default LoginForm;
