import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Content, Toolbar, Profile } from '~/components/header/styles';
import MenuProfile from '~/components/header/menu-profile';

function Header({ children }) {
  const user = useSelector(state => _.get(state, 'auth.user', {}));
  const name = _.get(user, 'name', '');
  const accountName = _.get(user, 'profile.name', '');

  return (
    <Container>
      <Content>
        <Toolbar>
          {
            React.Children.map(children, child => {
              return React.cloneElement(child, {})
            })
          }
        </Toolbar>

        <Profile>
          <div className="profile-info">
            <strong title={name}>{name}</strong>
            <span>{accountName}</span>
          </div>
          <div className="profile-avatar">
            <MenuProfile align="end" direction="bottom" />
          </div>
        </Profile>
      </Content>
    </Container>
  );
}

Header.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

export default Header;
