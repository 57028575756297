import React, { useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MdSave, MdCheck, MdClear } from 'react-icons/md'
import { VscDebugRestartFrame } from 'react-icons/vsc'
import { RiFileList3Line } from 'react-icons/ri'
import { GrDocumentPdf } from 'react-icons/gr'
import { SiMicrosoftexcel } from 'react-icons/si'
import _ from 'lodash'
import confirm from '~/components/confirm'
import { Creators as ResponseActions } from '~/store/modules/response'
import { Creators as HeaderActions } from '~/store/modules/header'
import { Container } from '~/pages/responses/styles'
import { ResponseForm, ResponseSchema } from '~/pages/responses/form'
import FormFilter from '~/pages/responses/filter'
import Crud from '~/components/crud'
import { formats } from '~/helper'
import CellBank from '~/components/datatable/cell-bank'
import useSearchParameters from '~/hooks/use-search-params'
import { BASE_URL } from '~/constants'
import PDF from '~/components/pdf'

const labelsMap = {
  payment: 'Pagamento',
  charge: 'Cobrança',
  dda: 'DDA',
  creditReceived: 'Créditos Recebidos',
}

const columns = [
  {
    name: 'Tipo',
    selector: 'movementType',
    width: '200px',
    format: (row) => {
      let type = row.movementType || '',
        layout = _.toUpper(_.get(row, 'layoutType')) || ''
      return `${labelsMap[type] || type} - ${layout}`
    },
  },
  {
    name: 'Agência / Conta',
    selector: 'bankAccount',
    width: '160px',
    left: true,
    cell: (row) => (
      <CellBank
        bankCode={_.get(row, 'bank.code')}
        bankAccount={_.get(row, 'bankAccount')}
      />
    ),
  },
  {
    name: 'Qtde. registros',
    selector: 'recordsCount',
    width: '160px',
    center: true,
    format: (row) => formats.number(_.get(row, 'recordsCount')),
  },
  {
    name: 'Qtde. processados',
    selector: 'processedCount',
    width: '160px',
    center: true,
    format: (row) => formats.number(_.get(row, 'processedCount')),
  },
  {
    name: 'Autor',
    selector: 'user',
    hide: 'md',
    format: (row) =>
      _.get(row, 'extras.autoImport')
        ? '(Importado automaticamente)'
        : _.get(row, 'user.name') || '',
  },
  {
    name: 'Data retorno',
    selector: 'importedAt',
    hide: 'md',
    width: '140px',
    format: (row) =>
      formats.dateTimeZone(_.get(row, 'importedAt'), 'dd/MM/yyyy'),
  },
  {
    name: 'Importado em',
    selector: 'createdAt',
    hide: 'md',
    width: '140px',
    format: (row) =>
      formats.dateTimeZone(_.get(row, 'createdAt'), 'dd/MM/yyyy HH:mm'),
  },
]

const customStyles = {
  rows: {
    denseStyle: {
      minHeight: '42px',
    },
  },
}

function Response({ history, match, acls }) {
  const dispatch = useDispatch()
  const state = useSelector((state) => state.response)
  const headerState = useSelector((state) => state.header)
  const hasAccountsReceivable = acls.includes('CR')
  const hasAccountsPayable = acls.includes('CP')
  const canWrite =
    acls.includes('W') && (hasAccountsReceivable || hasAccountsPayable)
  const isNew = !_.get(state, 'model.id')
  const [openForm, setOpenForm] = useState(!!_.get(match, 'params.id'))
  const showFilter = headerState.filter.visible
  const canGeneratePdf = /^(payment|charge)$/.test(
    _.get(state, 'model.movementType'),
  )

  const {
    location,
    routeParams,
    getAllParams,
    toStringify,
  } = useSearchParameters()

  useLayoutEffect(() => {
    const config = {
      loading: state.loading,
      useFilter: true,
      filter: {
        ...headerState.filter,
        visible: false,
      },
    }
    dispatch(HeaderActions.configure(config))
    // eslint-disable-next-line
  }, [])

  useLayoutEffect(() => {
    const { id, offset = 0, limit = 20, ...filter } = getAllParams()

    const listMethod =
      _.isEmpty(id) || _.size(_.get(state, 'data.rows')) === 0
        ? ResponseActions.list
        : null

    if (id) {
      dispatch(ResponseActions.load({ id }))
    }

    dispatch(
      HeaderActions.callFilter(
        { ...filter, offset: _.toInteger(offset), limit: _.toInteger(limit) },
        listMethod,
      ),
    )
    // eslint-disable-next-line
  }, [location, routeParams])

  const getEmptyValue = () => {
    let movementType = ''

    if (hasAccountsPayable && !hasAccountsReceivable) {
      movementType = 'payment'
    } else if (!hasAccountsPayable && hasAccountsReceivable) {
      movementType = 'charge'
    }
    return { movementType }
  }

  function handleOnSubmit(data, actions) {
    dispatch(ResponseActions.create(data, actions))
  }

  function handlePageChange(offset, limit = 20) {
    const filter = getAllParams()
    history.push({
      pathname: '/responses',
      search: `?${toStringify({
        ..._.omit(filter, [
          'id',
          'company',
          'bankAccount',
          'createdAtStart',
          'createdAtEnd',
        ]),
        offset,
        limit,
      })}`,
      state: { ..._.omit(filter, 'id'), offset, limit },
    })
    setOpenForm(false)
  }

  function handleLoad(data) {
    const filter = getAllParams(),
      id = _.get(data, 'id') || 'new'

    if (id === 'new') {
      delete filter.id
    }

    history.push({
      pathname: `/responses/${id}`,
      search: `?${toStringify(
        _.omit(filter, [
          'id',
          'company',
          'bankAccount',
          'createdAtStart',
          'createdAtEnd',
        ]),
      )}`,
      state: filter,
    })

    setOpenForm(true)
  }

  function handleHideFilter() {
    const fromPage = _.get(location, 'state.from', false)
    dispatch(HeaderActions.hideFilter())
    setOpenForm(false)

    if (fromPage && fromPage !== 'responses') {
      history.goBack()
    }
  }

  async function handleDownloadFile(data) {
    dispatch(ResponseActions.downloadFile(data))
  }

  function handleFilter(filter) {
    const historySearch = {
      ..._.omit(filter, [
        'company',
        'bankAccount',
        'createdAtStart',
        'createdAtEnd',
      ]),
      offset: 0,
    }
    handleHideFilter()
    history.push({
      pathname: `/responses`,
      search: `?${toStringify(historySearch)}`,
      state: filter,
    })
  }

  function handleListBankAccounts(term, callback) {
    dispatch(ResponseActions.listBankAccounts(term, callback))
  }

  function handleListPaymentBank(term, callback) {
    dispatch(ResponseActions.listBanks(term, callback))
  }

  function handleListCompanies(term, callback) {
    dispatch(ResponseActions.listCompanies(term, callback))
  }

  function handleListChargeBankContracts(term, callback) {
    dispatch(ResponseActions.listBankContracts('charge', term, callback))
  }

  function handleListPaymentBankContracts(term, callback) {
    dispatch(ResponseActions.listBankContracts('payment', term, callback))
  }

  function handleChangeTab(tab) {
    dispatch(ResponseActions.changeTab(tab))
  }

  function handleGenerateXlsx(data) {
    dispatch(ResponseActions.generateXlsx(data.id))
  }

  const handleGeneratePdf = async (data) => {
    if (data.id) {
      await PDF.show({
        title: `Retorno - ${_.get(data, 'bank.code', '')} - ${_.get(
          data,
          'bankContract.bankAccount.bankBranch', ''
        )} - ${_.get(data, 'bankContract.bankAccount.accountNumber', '')}`,
        url: `${BASE_URL}/responses/pdf/${data.id}/retorno.pdf`,
        width: '90%',
        height: '95%',
      })
    }
  }

  const handleGenerateBillsPdf = async (ids, accountId) => {
    if (!_.isEmpty(ids)) {
      await PDF.show({
        title: `Boletos`,
        url: `${BASE_URL}/responses/pdf/${ids.join(
          ',',
        )}/${accountId}/boletos.pdf`,
        width: '90%',
        height: '95%',
      })
    }
  }

  const handleOpenDocument = (type) => (data) => {
    const filter = getAllParams()
    if ((type === 'charge' && _.get(data, 'bill.id')) || (type === 'payment' && _.get(data, 'paymentOrder.id'))) {
      history.push( {pathname: type === 'charge' ? `/bills/${_.get(data, 'bill.id')}` : `/payment-orders/${_.get(data, 'paymentOrder.id')}`,
      search: `?${toStringify({
        ..._.omit(filter, [
          'id',
          'company',
          'bankAccount',
          'createdAtStart',
          'createdAtEnd',
        ]),
      })}`,
      state: _.extend(filter, { from: 'responses' }), sourcepath: 'responses' })
    } else if (type === 'dda' && _.get(data, 'id')) {
      history.push(`/digital-charges/${_.get(data, 'id')}`, {
        from: 'responses',
      })
    } else if (type === 'creditReceived' && _.get(data, 'id')) {
      history.push(`/credits-receiveds/${_.get(data, 'id')}`, {
        from: 'responses',
      })
    }
  }

  async function handleReprocessConciliation(ids, type, actions) {
    let size = _.size(ids),
      isPayment = type === 'payment',
      text =
        size === 1
          ? 'a fatura não conciliada'
          : `as ${formats.number(size)} faturas não conciliadas`

    if (isPayment) {
      text =
        size === 1
          ? 'a ordem de pagamento não conciliada'
          : `as ${formats.number(size)} ordens de pagamentos não conciliadas`
    }
    let result = await confirm.show({
      width: 530,
      title: 'Atenção',
      text: `Deseja realmente reprocessar ${text} no ERP?`,
    })

    if (result) {
      dispatch(
        ResponseActions.reprocessConciliation({ ids, isPayment }, actions),
      )
    }
  }

  function getViewActions() {
    let type = _.get(state, 'model.movementType'),
      accountId = _.get(state, 'model.accountId'),
      billNotConcilieds = _.filter(
        _.get(state, 'model.bills'),
        (r) => !!_.get(r, 'bill.erpError'),
      ),
      billRegistereds = _.filter(
        _.get(state, 'model.bills'),
        (r) =>
          _.get(r, 'bill.status') === 'open' && _.get(r, 'bill.registered'),
      ),
      paymentNotConcilieds = _.filter(
        _.get(state, 'model.paymentOrders'),
        (r) => !!_.get(r, 'paymentOrder.erpError'),
      ),
      actions = []

    if (canGeneratePdf) {
      actions.push({
        label: 'Gerar PDF',
        icon: GrDocumentPdf,
        action: () => handleGeneratePdf(_.get(state, 'model') || {}),
      })

      actions.push({
        label: 'Gerar XLSX',
        icon: SiMicrosoftexcel,
        action: () => handleGenerateXlsx(_.get(state, 'model') || {}),
      })
    }

    if (
      type === 'charge' &&
      /^(bills|billsError)$/.test(state.currentTab) &&
      !_.isEmpty(billNotConcilieds)
    ) {
      actions.push({
        label: 'Reprocessar conciliação',
        icon: VscDebugRestartFrame,
        action: (actions) =>
          handleReprocessConciliation(
            _.map(billNotConcilieds, 'billId'),
            type,
            actions,
          ),
      })
    } else if (
      type === 'payment' &&
      /^(paymentOrders|paymentOrdersError)$/.test(state.currentTab) &&
      !_.isEmpty(paymentNotConcilieds)
    ) {
      actions.push({
        label: 'Reprocessar conciliação',
        icon: VscDebugRestartFrame,
        action: (actions) =>
          handleReprocessConciliation(
            _.map(paymentNotConcilieds, 'paymentOrderId'),
            type,
            actions,
          ),
      })
    }

    if (type === 'charge' && !_.isEmpty(billRegistereds)) {
      actions.push({
        label: 'Gerar PDF das faturas com situação "Registrada"',
        icon: RiFileList3Line,
        action: () =>
          handleGenerateBillsPdf(_.map(billRegistereds, 'billId'), accountId),
      })
    }
    return actions
  }

  return (
    <Container>
      <Crud
        useOpenForm={true}
        openForm={openForm || showFilter}
        hideAdd={!canWrite}
        columns={columns}
        emptyText="Nenhum retorno encontrado"
        formTitle={(data) =>
          showFilter
            ? `Filtro`
            : _.get(data, 'id') || state.formLoading
            ? `Detalhes do arquivo retorno`
            : `Importar arquivo retorno`
        }
        data={state.data}
        tableLoading={state.loading}
        formLoading={state.formLoading}
        onCloseFilter={handleHideFilter}
        onChangePage={handlePageChange}
        onRowClicked={handleLoad}
        rightWidth={`${isNew || showFilter ? 500 : 1200}px`}
        formOptions={{
          initialValues: showFilter
            ? headerState.filter.data
            : _.isEmpty(state.model)
            ? getEmptyValue()
            : state.model,
          validationSchema: showFilter ? undefined : ResponseSchema,
        }}
        renderForm={(args) =>
          showFilter ? (
            <FormFilter
              {...args}
              hasAccountsReceivable={hasAccountsReceivable}
              hasAccountsPayable={hasAccountsPayable}
              onListBankAccounts={handleListBankAccounts}
              onListCompanies={handleListCompanies}
            />
          ) : (
            <ResponseForm
              {...args}
              loading={state.formLoading}
              loadingMessage={state.formLoadingMessage}
              previewMode={!canWrite}
              hasAccountsReceivable={hasAccountsReceivable}
              hasAccountsPayable={hasAccountsPayable}
              onListPaymentBank={handleListPaymentBank}
              onListPaymentBankContracts={handleListPaymentBankContracts}
              onListChargeBankContracts={handleListChargeBankContracts}
              openDocument={handleOpenDocument}
              currentTab={state.currentTab}
              onChangeTab={handleChangeTab}
              onDownloadFile={handleDownloadFile}
            />
          )
        }
        actions={
          showFilter
            ? [
                {
                  label: 'Limpar Filtro',
                  icon: MdClear,
                  action: () => handleFilter({}),
                },
                {
                  label: 'Aplicar Filtro',
                  icon: MdCheck,
                  action: (data) => handleFilter(data),
                },
              ]
            : !isNew
            ? getViewActions()
            : [
                {
                  label: 'Salvar',
                  icon: MdSave,
                  isSubmit: true,
                  isDisabled: ({ isValid }) =>
                    !isValid || !canWrite || state.formLoading,
                  action: handleOnSubmit,
                },
              ]
        }
        dataTableOptions={{ customStyles }}
      />
    </Container>
  )
}

export default Response
