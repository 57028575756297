import { all, takeLatest, call, put } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import _ from 'lodash'
import {
  Creators as BusinessPartnerActions,
  Types as BusinessPartnerTypes,
} from '~/store/modules/business-partner'
import api from '~/services/api'
import { getError } from '~/helper'

export function* createOrUpdate({ data, actions }) {
  try {
    let editing = !!(data && data.id),
      method = editing ? 'put' : 'post',
      path = editing ? 'update' : 'create'

    yield call(api[method], `business-partners/${path}`, data)

    toast.success(
      `Parceiro de negócio ${editing ? 'atualizado' : 'criado'} com sucesso`,
    )
    actions.closeForm && actions.closeForm()
    actions.reloadList && actions.reloadList()
  } catch (error) {
    showError(error)
  }
}

export function* list({ params }) {
  try {
    const response = yield call(api.post, 'business-partners/list', params)
    yield put(BusinessPartnerActions.listSuccess(response.data))
  } catch (error) {
    showError(error)
  }
}

export function* load({ data }) {
  if (!data.id || data.id === 'new') {
    yield put(
      BusinessPartnerActions.loadSuccess({
        businessPartner: { active: true, identityType: 'company' },
      }),
    )
    return
  }
  try {
    const response = yield call(api.get, 'business-partners/load', {
      params: { id: data.id },
    })
    yield put(BusinessPartnerActions.loadSuccess(response.data))
  } catch (error) {
    showError(error)
  }
}

export function showError(error) {
  const msg = getError(error)
  toast.error(msg)
}

export function* cnpjSearch({ cnpj, model }) {
  try {
    const response = yield call(api.get, 'business-partners/cnpj-search', {
      params: { cnpj },
    })
    const data = { ...model, ...response.data }
    yield put(BusinessPartnerActions.searchCnpjResult(data, model))
  } catch (error) {
    yield put(BusinessPartnerActions.searchCnpjResult(null, model))
    showError(error)
  }
}

export function* createUser({ data, callback }) {
  try {
    if (!data.company) {
      toast.error('Informe de qual empresa será enviado o e-mail!')
      return
    }

    let response = yield call(api.post, 'business-partners/create-user', data),
      result = response.data

    toast.success(
      `Cadastro efetuado com sucesso, dados enviados para: ${result.email}`,
    )

    if (callback) {
      yield call(callback)
    }
  } catch (error) {
    showError(error)
  }
}

export function* resetUserPassword({ data, callback }) {
  try {
    if (!data.company) {
      toast.error('Informe de qual empresa será enviado o e-mail!')
      return
    }

    let response = yield call(
        api.post,
        'business-partners/reset-user-password',
        data,
      ),
      result = response.data

    toast.success(
      `Senha redefinida com sucesso, dados enviados para: ${result.email}`,
    )

    if (callback) {
      yield call(callback)
    }
  } catch (error) {
    showError(error)
  }
}

export function* listBusinessPartnerGroup({ term, callback }) {
  try {
    const response = yield call(
      api.get,
      'business-partners/list-business-partner-groups',
      { params: { term } },
    )
    yield call(callback, response.data)
  } catch (error) {}
}

export function* listRepresentative({ term, callback }) {
  try {
    const response = yield call(
      api.get,
      'business-partners/list-representative',
      { params: { term } },
    )
    yield call(callback, response.data)
  } catch (error) {}
}

export function* remove({ data, actions }) {
  try {
    let params = _.pick(data, 'id')
    yield call(api.delete, `business-partners/remove`, { params })

    toast.success(`Parceiro de negócios removido com sucesso`)
    actions.closeForm && actions.closeForm()
    actions.reloadList && actions.reloadList()
  } catch (error) {
    showError(error)
  }
}

export function* listCompanies({ term, callback }) {
  try {
    const response = yield call(api.get, 'business-partners/list-companies', {
      params: { term },
    })
    yield call(callback, response.data)
  } catch (error) {}
}

export default all([
  takeLatest(BusinessPartnerTypes.LIST, list),
  takeLatest(BusinessPartnerTypes.LOAD, load),
  takeLatest(BusinessPartnerTypes.CREATE_USER, createUser),
  takeLatest(BusinessPartnerTypes.RESET_USER_PASSWORD, resetUserPassword),
  takeLatest(BusinessPartnerTypes.CREATE_OR_UPDATE, createOrUpdate),
  takeLatest(BusinessPartnerTypes.SEARCH_CNPJ_REQUEST, cnpjSearch),
  takeLatest(
    BusinessPartnerTypes.LIST_BUSINESS_PARTNER_GROUP,
    listBusinessPartnerGroup,
  ),
  takeLatest(BusinessPartnerTypes.LIST_REPRESENTATIVE, listRepresentative),
  takeLatest(BusinessPartnerTypes.REMOVE, remove),
  takeLatest(BusinessPartnerTypes.LIST_COMPANIES, listCompanies),
])
