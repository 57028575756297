import React, { useState } from 'react';
import _ from 'lodash';
import { TabContainer } from '~/pages/business-partners/styles';
import DataTable from '~/components/datatable';
import { formats } from 'helper';

const getRows = (array = [], offset, pageSize) => {
  return array.slice(offset, offset + pageSize);
};

export const TabPaymentOrders = ({ errors, touched, values, previewMode, openPaymentOrder }) => {
  const paymentOrders = _.get(values, 'paymentOrders') || [];
  const count = _.size(paymentOrders);
  const pageSize = 50;
  const [ offset, setOffset ] = useState(0);

  const onChangePage = (offset) => {
    setOffset(offset);
  };

  return (
    <TabContainer>
      <DataTable
        emptyText="Nenhuma ordem de pagamento vinculada"
        pageSize={pageSize}
        onChangePage={onChangePage}
        hideUpdateButton={true}
        data={{ rows: getRows(paymentOrders, offset, pageSize), offset, count, limit: pageSize }}
        onRowClicked={openPaymentOrder}
        columns={[
          {
            name: 'Tipo',
            selector: 'paymentOrderType',
            format: (row) => _.get(row, 'paymentOrderType.name')
          },
          {
            name: 'Data agendamento',
            selector: 'scheduledDate',
            width: '140px',
            format: (row) => formats.dateTimeZone(_.get(row, 'scheduledDate'), 'dd/MM/yyyy')
          },
          {
            name: 'Data pagamento',
            selector: 'payDate',
            width: '140px',
            hide: 'md',
            format: (row) => formats.dateTimeZone(_.get(row, 'payDate'), 'dd/MM/yyyy') || '--'
          },
          {
            name: 'Valor',
            selector: 'value',
            width: '130px',
            right: true,
            format: (row) => formats.currency(_.get(row, 'value'))
          },
        ]}
      />
    </TabContainer>
  )
};
