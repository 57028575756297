import React, { useState } from 'react';
import { Autocomplete, InputMask } from '~/components/form';
import FieldSet from '~/components/fieldset';
import { BsRow, BsCol } from '~/components/layout';
import { isValidSlipDigitableLine } from '~/validator';
import { barcodeToDigitableLine, digitableLineToBarcode } from '~/helper';
import { MASK_TAX_DIGITABLE_LINE, MASK_BARCODE } from '~/constants';
import { FaBarcode } from 'react-icons/fa';
import { BiEditAlt } from 'react-icons/bi';
import * as Yup from 'yup';
import _ from 'lodash';

export const id = 30;

const findByBarcode = (code, list) => {
  let key = String(code).substr(16, 4),
    segment = String(code).substr(1, 1),
    found = _.find(list, { value: key, segment });
  return found;
};

export const Render = ({ values, errors, touched, previewMode, getDataList, setFieldValue, onDataList }) => {
  const [barCodeMode, setBarCodeMode] = useState(false);
  const toogleBarCodeMode = () => setBarCodeMode(previous => !previous);
  const editLocked = _.get(values, 'editLocked') || false;

  function sortByLabel(concessionaires) {
    return concessionaires.sort((a, b) => a.label.localeCompare(b.label));
  }

  return (
    <FieldSet label="Concessionárias - Conta">
      <BsRow>
        <BsCol md={24} lg={12} xl={11}>
          {!barCodeMode && (
            <InputMask
              icon={FaBarcode}
              mask={MASK_TAX_DIGITABLE_LINE}
              name="orderData.digitableLine"
              label="Linha digitável *"
              disabled={previewMode || editLocked}
              hasError={_.get(errors, 'orderData.digitableLine') && _.get(touched, 'orderData.digitableLine')}
              onChange={event => {
                let value = _.get(event, 'target.value');
                let digitableLine = value;
                let barcode = digitableLineToBarcode(value);
                setFieldValue('orderData.digitableLine', digitableLine);
                setFieldValue('orderData.barcode', barcode);

                if (barcode) {
                  setFieldValue('orderData.barcode', barcode);
                  setFieldValue('orderData.concessionaire', findByBarcode(digitableLine, getDataList('concessionaires')));
                }
              }}
              onIconClick={toogleBarCodeMode}
              iconTooltip="Editar código de barras"
            />
          )}
          {barCodeMode && (
            <InputMask
              icon={BiEditAlt}
              mask={MASK_BARCODE}
              name="orderData.barcode"
              label="Código de barras *"
              disabled={previewMode || editLocked}
              hasError={_.get(errors, 'orderData.barcode') && _.get(touched, 'orderData.barcode')}
              onChange={(event) => {
                let value = _.get(event, 'target.value');
                let digitableLine = barcodeToDigitableLine(value);
                let barcode = value;
                setFieldValue('orderData.digitableLine', digitableLine);
                setFieldValue('orderData.barcode', barcode);

                if (barcode) {
                  setFieldValue('orderData.barcode', barcode);
                  setFieldValue('orderData.concessionaire', findByBarcode(digitableLine.replace(/\D/g, ''), getDataList('concessionaires')));
                }
              }}
              onIconClick={toogleBarCodeMode}
              iconTooltip="Editar linha digitável"
            />
          )}
        </BsCol>

        <BsCol md={24} lg={12} xl={13}>
          <Autocomplete
            name="orderData.concessionaire"
            keyField="value"
            disabled={previewMode || editLocked}
            label="Concessionária *"
            value={_.get(values, 'orderData.concessionaire')}
            valueFormat={row => `${row.label || ''}`}
            loadData={onDataList('concessionaires', 'value', 'label', null, sortByLabel)}
            emptyText={'Selecione a Concessionária *'}
            tipText={'Digite... '}
            loadingText={'Carregando...'}
            notFoundText={'Não encontrada'}
          />
        </BsCol>
      </BsRow>
    </FieldSet>
  );
};

export const Schema = values => ({
  orderData: Yup.object().typeError('Informe os dados da ordem').shape({
    concessionaire: Yup.string().required('Informe a concessionária'),
    digitableLine: Yup.mixed().test('match', '', function(value) {
      const { path, parent, createError } = this;
      let val = (value || _.get(parent, 'orderData.digitableLine') || '').replace(/\D/g, '');

      if (!val) {
        return createError({ path, message: 'Informe a linha digitável' });
      }

      if (val && (![47,48].includes(_.size(val.replace(/\D/g, ''))) || !isValidSlipDigitableLine(val))) {
        return createError({ path, message: 'Informe uma linha digitável válida' });
      }

      if (!/8(2|3|4)/.test(val)) {
        return createError({ path, message: 'Segmento da linha digitável inválido' });
      }

      return true;
    })
  })
});
