
import React from 'react';
import _ from 'lodash';
import * as Yup from 'yup';
import { Select, InputGroup, CurrencyField, InputMask, Autocomplete, InputDate } from '~/components/form';
import FieldSet from '~/components/fieldset';
import { BsRow, BsCol } from '~/components/layout';
import { MASK_CNPJ, MASK_CPF } from '~constants';
import { isValidCpf, isValidCnpj } from '~/validator';

export const id = 46;

export const onParentChange = ({ field, value, parent, setFieldValue }) => {
  let idField = 'orderData.identity',
    idTypeField = 'orderData.identityType',
    nameField = 'orderData.name';

  if (field === 'paymentOrderType' && _.get(parent, 'company')) {
    let identity = _.get(parent, 'company.identity');
    setFieldValue(idTypeField, _.size(identity) === 14 ? 'company' : 'person');
    setFieldValue(idField, identity);
    setFieldValue(nameField, _.get(parent, 'company.name'));

  } else if (field === 'company' && value) {
    let identity = _.get(value, 'identity');
    setFieldValue(idTypeField, _.size(identity) === 14 ? 'company' : 'person');
    setFieldValue(idField, identity);
    setFieldValue(nameField, _.get(value, 'name'));

  } else if (field === 'bankAccount') {
    setFieldValue('orderData.state', null);
    setFieldValue('orderData.city', null);
  }
};

export const Render = ({ values, errors, touched, setFieldValue, previewMode, onDataList, onListCities }) => {

  let hasPayOpt = /^(033|341)$/.test(_.get(values, 'bankContract.bankAccount.bank.code')),
    bankCode = _.get(values, 'bankContract.bankAccount.bank.code'),
    allowedStates = _.get(values, `paymentOrderType.extras.bankState[${bankCode}]`) || [];
  const editLocked = _.get(values, 'editLocked') || false;

  if (!bankCode) {
    return (
      <BsRow className="form-warn">
        <BsCol md={24} lg={24} xl={24}>
          <h3>Escolha um contrato.</h3>
        </BsCol>
      </BsRow>
    );
  }
  if (bankCode && _.isEmpty(allowedStates)) {
    return (
      <BsRow className="form-warn">
        <BsCol md={24} lg={24} xl={24}>
          <h3>Esse tipo de ordem de pagamento não é suportado pelo banco da conta selecionada.</h3>
        </BsCol>
      </BsRow>
    );
  }

  return (
    <FieldSet label="Tributos - DPVAT">
      <BsRow>
        <BsCol md={24} lg={5} xl={4}>
          <Select
            name="orderData.identityType"
            label="Tipo da inscrição *"
            disabled={previewMode || editLocked}
            hasError={_.get(errors, 'orderData.identityType') && _.get(touched, 'orderData.identityType')}
            onChange={(evt) => {
              setFieldValue('orderData.identityType', evt.target.value);
              setFieldValue('orderData.identity', '');
            }}
            options={{ values: [
                { value: '', 'label': '' },
                { value: 'company', 'label': 'CNPJ' },
                { value: 'person', 'label': 'CPF' },
              ]}}
            />
        </BsCol>

        <BsCol md={24} lg={7} xl={8}>
          {_.get(values, 'orderData.identityType') === 'person' &&
             <InputMask
              disabled={!_.get(values, 'orderData.identityType') || previewMode || editLocked}
              mask={MASK_CPF}
              name="orderData.identity"
              label={'Número da inscrição *'}
              hasError={_.get(errors, 'orderData.identity') && _.get(touched, 'orderData.identity')}
              />
          }
          {_.get(values, 'orderData.identityType') !== 'person' &&
            <InputMask
              disabled={!_.get(values, 'orderData.identityType') || previewMode || editLocked}
              mask={MASK_CNPJ}
              name="orderData.identity"
              label={'Número da inscrição *'}
              hasError={_.get(errors, 'orderData.identity') && _.get(touched, 'orderData.identity')}
              />
          }
        </BsCol>

        <BsCol md={24} lg={12} xl={12}>
          <InputGroup
            type="text"
            name="orderData.name"
            maxLength={30}
            disabled={previewMode || editLocked}
            label="Nome do contribuinte *"
            hasError={_.get(errors, 'orderData.name') && _.get(touched, 'orderData.name')}
            />
        </BsCol>
      </BsRow>

      <BsRow>
        <BsCol md={24} lg={12} xl={6}>
          <InputDate
            name="orderData.year"
            label="Exercício *"
            yearView={true}
            disabled={previewMode || editLocked}
            hideErrorLabel={false}
            hasError={_.get(errors, 'orderData.year') && _.get(touched, 'orderData.year')}
            />
        </BsCol>

        <BsCol md={24} lg={12} xl={6}>
          <InputGroup
            type="text"
            name="orderData.renavam"
            maxLength={20}
            disabled={previewMode || editLocked}
            label="RENAVAM *"
            hasError={_.get(errors, 'orderData.renavam') && _.get(touched, 'orderData.renavam')}
            />
        </BsCol>

        <BsCol md={24} lg={12} xl={6}>
          <Autocomplete
            name="orderData.state"
            keyField="value"
            label="Estado *"
            disabled={previewMode || editLocked}
            value={_.get(values, 'orderData.state')}
            valueFormat={row => `${row.label || ''}`}
            loadData={onDataList('brStates', 'value', 'label', allowedStates)}
            emptyText={'Selecione o estado *'}
            tipText={'Digite... '}
            loadingText={'Carregando...'}
            notFoundText={'Não encontrado'}
            onChange={(val) => {
              setFieldValue('orderData.state', val);
              setFieldValue('orderData.city', null);
            }}
            />
        </BsCol>

        <BsCol md={24} lg={12} xl={6}>
          <Autocomplete
            name="orderData.city"
            keyField="value"
            label="Município *"
            disabled={previewMode || !_.get(values, 'orderData.state') || editLocked}
            value={_.get(values, 'orderData.city')}
            valueFormat={row => `${row.name || ''}`}
            loadData={(term, callback) => onListCities(_.get(values, 'orderData.state.value'), term, callback)}
            emptyText={'Selecione o município *'}
            tipText={'Digite... '}
            loadingText={'Carregando...'}
            notFoundText={'Não encontrado'}
            />
        </BsCol>
      </BsRow>

      <BsRow>
        <BsCol md={24} lg={8} xl={8}>
          <InputGroup
            type="text"
            name="orderData.licensePlate"
            maxLength={10}
            disabled={previewMode || editLocked}
            label="Placa *"
            hasError={_.get(errors, 'orderData.licensePlate') && _.get(touched, 'orderData.licensePlate')}
            />
        </BsCol>

        <BsCol md={24} lg={8} xl={8}>
          <CurrencyField
            name="orderData.value"
            label="Valor DPVAT *"
            disabled={previewMode || editLocked}
            hasError={_.get(errors, 'orderData.value') && _.get(touched, 'orderData.value')}
            />
        </BsCol>

        <BsCol md={24} lg={8} xl={8}>
          <CurrencyField
            name="orderData.discount"
            label="Valor do desconto"
            disabled={previewMode || editLocked}
            hasError={_.get(errors, 'orderData.discount') && _.get(touched, 'orderData.discount')}
            />
        </BsCol>
      </BsRow>

      {hasPayOpt &&
        <BsRow>
          <BsCol md={24} lg={24} xl={24}>
            <Select
              name="orderData.paymentOption"
              label="Opção de pagamento"
              disabled={previewMode || editLocked}
              hasError={_.get(errors, 'orderData.paymentOption') && _.get(touched, 'orderData.paymentOption')}
              options={{ values: [
                  { value: '', 'label': '' },
                  { value: '0', label: 'Pagamento de DPVAT' },
                  { value: '1', label: 'Parcela Única com desconto' },
                  { value: '2', label: 'Parcela Única sem desconto' },
                  { value: '3', label: 'Parcela No 1' },
                  { value: '4', label: 'Parcela No 2' },
                  { value: '5', label: 'Parcela No 3' },
                  { value: '6', label: 'Parcela No 4' },
                  { value: '7', label: 'Parcela No 5' },
                  { value: '8', label: 'Parcela No 6' },
                ]}}
              />
          </BsCol>
        </BsRow>
      }
    </FieldSet>
  )
};

export const Schema = (values) => ({
  orderData: Yup.object().typeError('Informe os dados da ordem').shape({
    year: Yup.string().typeError('Informe o exercício').required('Informe o exercício'),
    identityType: Yup.string().required('Informe o tipo de inscrição'),
    name: Yup.string().required('Informe o nome do contribuinte'),
    identity: Yup.mixed().test('match', '', function(value) {
      const { path, parent, createError } = this;
      const val = value || _.get(parent, 'identity'),
        type = _.get(parent, 'identityType');
      if (!val) {
        return createError({ path, message: 'Informe o número de inscrição' });
      } else if (val && type === 'person' && !isValidCpf(val)) {
        return createError({ path, message: 'Informe um CPF válido' });
      } else if (val && type === 'company' && !isValidCnpj(val)) {
        return createError({ path, message: 'Informe um CNPJ válido' });
      }
      return true;
    }),
    renavam: Yup.string().required('Informe o RENAVAM'),
    state: Yup.mixed().required('Informe o estado'),
    city: Yup.mixed().required('Informe o município'),
    licensePlate: Yup.string().required('Informe a placa'),
    value: Yup.number().required('Informe o valor do DPVAT'),
  })
});
