import React, { useEffect } from 'react'
import { Formik } from 'formik'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { MdCheck } from 'react-icons/md'
import { Creators as ParserActions } from '~/store/modules/parser'
import { Creators as HeaderActions } from '~/store/modules/header'
import { Container, FormPanel, ResultPanel } from '~/pages/parsers/styles'
import { FileUpload, Select } from '~/components/form'
import { BsRow, BsCol } from '~/components/layout'
import { IconButton } from '~/components/button'
import Loading from '~/components/loading'

const bankMap = {
  '000': {
    cobranca: ['cnab240'],
    dda: ['cnab240'],
    extrato: ['ofx', 'cnab240'],
    pagamento: ['cnab240'],
  },
  '001': {
    cobranca: ['cnab240', 'cnab400'],
    dda: ['cnab240'],
    extrato: ['ofx', 'cnab240'],
    pagamento: ['cnab240'],
  },
  '033': {
    cobranca: ['cnab240', 'cnab400'],
    dda: ['cnab240'],
    extrato: ['ofx'],
    pagamento: ['cnab240'],
  },
  '041': {
    cobranca: ['cnab240'],
    dda: ['cnab240'],
    extrato: ['ofx', 'cnab240'],
    pagamento: ['cnab240'],
  },
  '077': {
    cobranca: ['cnab400'],
    extrato: ['ofx'],
  },
  '104': {
    cobranca: ['cnab240', 'cnab400'],
    dda: ['cnab240'],
    extrato: ['ofx', 'cnab240'],
    pagamento: ['cnab240'],
  },
  '208': {
    cobranca: ['cnab240'],
    extrato: ['cnab240'],
    pagamento: ['cnab240'],
  },
  '218': {
    cobranca: ['cnab400'],
    extrato: ['cnab240'],
  },
  '237': {
    cobranca: ['cnab240', 'cnab400'],
    dda: ['cnab240'],
    extrato: ['ofx', 'cnab240'],
    pagamento: ['cnab240'],
  },
  '246': {
    cobranca: ['cnab240'],
    extrato: ['cnab240'],
  },
  '280': {
    extrato: ['ofx'],
  },
  '341': {
    cobranca: ['cnab240', 'cnab400'],
    dda: ['cnab240'],
    extrato: ['ofx', 'cnab240'],
    pagamento: ['cnab240'],
    'credito-recebido': ['cnab160','cnab750'],
  },
  '389': {
    cobranca: ['cnab400'],
    extrato: ['ofx', 'cnab240'],
  },
  '422': {
    cobranca: ['cnab400'],
    dda: ['cnab240'],
    extrato: ['ofx', 'cnab200'],
    pagamento: ['cnab240'],
  },
  '633': {
    extrato: ['ofx'],
  },
  '643': {
    cobranca: ['cnab400'],
    extrato: ['cnab240'],
  },
  '707': {
    cobranca: ['cnab400'],
    dda: ['cnab240'],
    extrato: ['ofx', 'cnab240'],
  },
  '748': {
    cobranca: ['cnab400'],
    extrato: ['ofx'],
    pagamento: ['cnab240'],
  },
  '752': {
    cobranca: ['cnab400'],
  },
  '756': {
    cobranca: ['cnab240'],
    extrato: ['ofx'],
    pagamento: ['cnab240'],
  },
}

const movementLabels = {
  cobranca: 'Cobrança',
  pagamento: 'Pagamento',
  extrato: 'Extrato',
  dda: 'DDA',
  'credito-recebido': 'Créditos Recebidos',
}

const getMovementTypes = (bank) => {
  let founds = _.keys(_.get(bankMap, bank))
  return _.map(founds, (value) => ({ value, label: movementLabels[value] }))
}

const getLayoutTypes = (bank, movementType) => {
  let founds = _.get(bankMap, `${bank}.${movementType}`)
  return _.map(founds, (value) => ({ value, label: _.toUpper(value) }))
}

function Parser() {
  const dispatch = useDispatch()
  const state = useSelector((state) => state.parser)

  useEffect(() => {
    const config = {
      loading: state.loading,
      useFilter: false,
    }
    dispatch(HeaderActions.configure(config))
    // eslint-disable-next-line
  }, [state, dispatch])

  function handleProcess(data) {
    dispatch(ParserActions.process(data))
  }

  if (state.loading) {
    return (
      <Container>
        <Loading size={50} label="Processando" />
      </Container>
    )
  }

  return (
    <Container>
      <ResultPanel>{JSON.stringify(state.model, null, 4)}</ResultPanel>

      <Formik initialValues={{}} enableReinitialize={true}>
        {({ values, setFieldValue }) => {
          const movementTypes = getMovementTypes(values.bankCode)
          const layoutTypes = getLayoutTypes(
            values.bankCode,
            values.movementType,
          )

          return (
            <FormPanel>
              <div className="form-header">Dados para processamento</div>

              <div className="form-body">
                <Select
                  name="bankCode"
                  label="Banco"
                  options={{
                    values: [
                      { value: '', label: 'Escolha um banco' },
                      { value: '000', label: '000 - FEBRABAN' },
                      { value: '001', label: '001 - Banco do Brasil' },
                      { value: '033', label: '033 - Santander' },
                      { value: '041', label: '041 - Banrisul' },
                      { value: '077', label: '077 - Banco Inter' },
                      { value: '104', label: '104 - Caixa Econômica Federal' },
                      { value: '208', label: '208 - Btg Pactual' },
                      { value: '218', label: '218 - Bs2' },
                      { value: '237', label: '237 - Bradesco' },
                      { value: '246', label: '246 - Abc Brasil' },
                      { value: '280', label: '280 - Avista' },
                      { value: '341', label: '341 - Itaú' },
                      { value: '422', label: '422 - Safra' },
                      { value: '633', label: '633 - Banco Rendimento' },
                      { value: '643', label: '643 - Banco Pine' },
                      { value: '707', label: '707 - Daycoval' },
                      { value: '748', label: '748 - Sicredi' },
                      { value: '752', label: '752 - Paribas' },
                      { value: '756', label: '756 - Sicoob' },
                    ],
                  }}
                  onChange={(evt) => {
                    const { value } = evt.target
                    setFieldValue('bankCode', value)
                    setFieldValue('movementType', '')
                    setFieldValue('layoutType', '')
                  }}
                />

                {values.bankCode && (
                  <>
                    <BsRow>
                      <BsCol md={24} lg={values.movementType ? 12 : 24}>
                        <Select
                          name="movementType"
                          label="Serviço"
                          options={{
                            values: [
                              { value: '', label: 'Escolha o serviço' },
                              ...movementTypes,
                            ],
                          }}
                          onChange={(evt) => {
                            const { value } = evt.target
                            setFieldValue('movementType', value)
                            setFieldValue('layoutType', '')
                          }}
                        />
                      </BsCol>

                      {values.movementType && (
                        <BsCol md={24} lg={12}>
                          <Select
                            name="layoutType"
                            label="Layout"
                            options={{
                              values: [
                                { value: '', label: 'Escolha o layout' },
                                ...layoutTypes,
                              ],
                            }}
                          />
                        </BsCol>
                      )}
                    </BsRow>

                    {values.movementType && values.layoutType && (
                      <FileUpload
                        id="file"
                        name="file"
                        accept=".ret,.rem,.ofx,.txt,.crt"
                        label="Arquivo"
                        multiple={false}
                        orientation="vertical"
                      />
                    )}
                  </>
                )}
              </div>

              <div className="form-actions">
                <IconButton
                  type="button"
                  title="Processar"
                  size={36}
                  disabled={
                    !values.file ||
                    !values.layoutType ||
                    (values.layoutType !== 'ofx' && !values.movementType)
                  }
                  onClick={() => handleProcess(values)}
                >
                  <MdCheck />
                </IconButton>
              </div>
            </FormPanel>
          )
        }}
      </Formik>
    </Container>
  )
}

export default Parser
