import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MdSave, MdDelete, MdClear, MdCheck } from 'react-icons/md'
import { FaUserPlus, FaUserLock } from 'react-icons/fa'
import _ from 'lodash'
import FormFilter from '~/pages/business-partners/filter'
import { Creators as BusinessPartnerActions } from '~/store/modules/business-partner'
import { Creators as HeaderActions } from '~/store/modules/header'
import { Container } from '~/pages/business-partners/styles'
import CellStatus from '~/components/datatable/cell-status'
import {
  BusinessPartnerForm,
  BusinessPartnerSchema,
} from '~/pages/business-partners/form'
import { SubtitleItem, Subtitle } from '~/components/datatable/subtitle'
import { red, green } from '~/components/mixins/color'
import confirm from '~/components/confirm'
import Crud from '~/components/crud'
import { formats, arrayInsertAt, validateEmail } from '~/helper'

const columns = ({ hasAccountsReceivable, hasAccountsPayable }) => {
  let cols = [
    {
      name: ' ',
      selector: 'status',
      width: '10px',
      cell: (row) => (
        <CellStatus title="" color={row.active ? green.hex() : red.hex()} />
      ),
    },
    {
      name: 'CNPJ/CPF',
      selector: 'identity',
      width: '185px',
      format: (row) => formats.cnpj_cpf(row.identity),
    },
    {
      name: 'Nome',
      selector: 'name',
      format: (row) => row.name || '',
    },
    {
      name: 'E-mail',
      hide: 'md',
      selector: 'email',
    },
    {
      name: 'Cadastrado em',
      selector: 'createdAt',
      width: '140px',
      hide: 'md',
      format: (row) =>
        formats.dateTimeZone(_.get(row, 'createdAt'), 'dd/MM/yyyy HH:mm'),
    },
  ]

  if (hasAccountsPayable && hasAccountsReceivable) {
    arrayInsertAt(cols, 3, {
      name: 'Fornecedor',
      selector: 'isSupplier',
      width: '110px',
      center: true,
      format: (row) => (row.isSupplier ? 'Sim' : 'Não'),
    })
    arrayInsertAt(cols, 4, {
      name: 'Cliente',
      selector: 'isCustomer',
      width: '110px',
      center: true,
      format: (row) => (row.isCustomer ? 'Sim' : 'Não'),
    })
  }
  return cols
}

function BusinessPartner({ match, acls, history }) {
  const dispatch = useDispatch()
  const state = useSelector((state) => state.businessPartner)
  const headerState = useSelector((state) => state.header)
  const canWrite = acls.includes('W')
  const hasAccountsReceivable = acls.includes('CR')
  const hasAccountsPayable = acls.includes('CP')
  const [openForm, setOpenForm] = useState(!!_.get(match, 'params.id'))
  const showFilter = headerState.filter.visible

  const [openPasswordResetModal, setOpenPasswordResetModal] = useState(false)
  const [openCreateUserModal, setOpenCreateUserModal] = useState(false)

  useEffect(() => {
    const config = {
      loading: state.loading,
      useFilter: true,
      filter: { ...headerState.filter, visible: false },
    }
    dispatch(HeaderActions.configure(config))
    // eslint-disable-next-line
  }, [state])

  useEffect(() => {
    dispatch(
      BusinessPartnerActions.list({ ...headerState.filter.data, offset: 0 }),
    )
    // eslint-disable-next-line
  }, [dispatch])

  useEffect(() => {
    let id = _.get(match, 'params.id')
    if (id) {
      dispatch(BusinessPartnerActions.load({ id }))
      history.replace('/business-partners')
    }
  }, [match, dispatch, history])

  async function handleRemove(values, actions) {
    const result = await confirm.show({
      title: 'Atenção',
      text: `Deseja realmente remover este parceiro de negócio? Essa ação não poderá ser revertida!`,
    })

    if (result) {
      dispatch(BusinessPartnerActions.remove(values, actions))
    }
  }

  function handleOnSubmit(data, actions) {
    dispatch(BusinessPartnerActions.createOrUpdate(data, actions))
  }

  function handlePageChange(offset, limit) {
    dispatch(
      BusinessPartnerActions.list({
        ...headerState.filter.data,
        offset,
        limit,
      }),
    )
  }

  function handleLoad(data) {
    setOpenForm(true)
    dispatch(BusinessPartnerActions.load(data))
  }

  function handleOpenBill(data) {
    history.push({
      pathname: `/bills/${data.id}`,
      sourcepath: 'business-partners',
    })
  }

  function handleCreateUser(data, callback) {
    dispatch(BusinessPartnerActions.createUser(data, callback))
  }

  function handlePasswordReset(data, callback) {
    dispatch(BusinessPartnerActions.resetUserPassword(data, callback))
  }

  function handleOpenPaymentOrder(data) {
    history.push({
      pathname: `/payment-orders/${data.id}`,
      sourcepath: 'business-partners',
    })
  }

  function handleHideFilter() {
    dispatch(HeaderActions.hideFilter())
    dispatch(BusinessPartnerActions.clearModel())
    setOpenForm(false)
  }

  function handleFilter(data) {
    handleHideFilter()
    dispatch(
      HeaderActions.callFilter(
        { ...data, offset: 0 },
        BusinessPartnerActions.list,
      ),
    )
  }

  function handleSearchCnpj(value, model) {
    dispatch(BusinessPartnerActions.searchCnpjRequest(value, model))
  }

  const handleListBusinessPartnerGroup = (term, callback) => {
    dispatch(BusinessPartnerActions.listBusinessPartnerGroup(term, callback))
  }

  const handleListRepresentative = (term, callback) => {
    dispatch(BusinessPartnerActions.listRepresentative(term, callback))
  }

  async function handleUserCreateUser(data = {}) {
    const company = _.get(data, 'company')
    if (!_.isEmpty(company)) {
      handleCreateUser({ ...data, company })
    } else {
      setOpenCreateUserModal(true)
    }
  }

  async function handleUserPasswordReset(data = {}) {
    const company = _.get(data, 'company')
    if (!_.isEmpty(company)) {
      handlePasswordReset({ ...data, company })
    } else {
      setOpenPasswordResetModal(true)
    }
  }

  function getActions() {
    let actions = [
      {
        label: 'Salvar',
        icon: MdSave,
        isSubmit: true,
        isDisabled: () => !canWrite,
        action: handleOnSubmit,
      },
    ]

    if (_.get(state, 'model.id') && !_.get(state, 'model.hasUser')) {
      actions.unshift({
        label: 'Cadastrar usuário',
        icon: FaUserPlus,
        isDisabled: ({ values }) =>
          !values.email || !validateEmail(values.email),
        action: handleUserCreateUser,
      })
    } else if (_.get(state, 'model.id') && _.get(state, 'model.hasUser')) {
      actions.unshift({
        label: 'Redefinir senha do usuário',
        icon: FaUserLock,
        isDisabled: ({ values }) => !values.email,
        action: handleUserPasswordReset,
      })
    }

    if (_.get(state, 'model.id')) {
      actions.push({
        label: 'Remover',
        icon: MdDelete,
        isDisabled: () => !canWrite,
        action: handleRemove,
      })
    }

    return actions
  }

  return (
    <Container>
      {!state.model && (
        <Subtitle bottom={25} left={20}>
          <span>Legenda:</span>
          <SubtitleItem color={green.hex()}>Ativo</SubtitleItem>
          <SubtitleItem color={red.hex()}>Inativo</SubtitleItem>
        </Subtitle>
      )}

      <Crud
        useOpenForm={true}
        openForm={openForm || showFilter}
        hideAdd={!canWrite}
        columns={columns({ hasAccountsPayable, hasAccountsReceivable })}
        emptyText="Nenhum parceiro de negócio encontrado"
        formTitle={(data) =>
          showFilter
            ? `Filtro`
            : _.get(data, 'id')
            ? `Parceiro de negócio (${formats.cnpj_cpf(data.identity)})`
            : `Novo parceiro de negócio`
        }
        data={state.data}
        rightWidth={`${showFilter ? '500px' : '800px'}`}
        onCloseFilter={handleHideFilter}
        tableLoading={state.loading}
        formLoading={state.formLoading}
        onChangePage={handlePageChange}
        onRowClicked={handleLoad}
        formOptions={{
          initialValues: showFilter
            ? headerState.filter.data
            : state.model || { identityType: 'company' },
          validationSchema: showFilter ? undefined : BusinessPartnerSchema,
          initialTouched: showFilter ? {} : { identity: true },
        }}
        renderForm={(args) =>
          showFilter ? (
            <FormFilter
              {...args}
              hasAccountsReceivable={hasAccountsReceivable}
              hasAccountsPayable={hasAccountsPayable}
            />
          ) : (
            <BusinessPartnerForm
              {...args}
              previewMode={!canWrite}
              hasAccountsReceivable={hasAccountsReceivable}
              hasAccountsPayable={hasAccountsPayable}
              openBill={handleOpenBill}
              openPaymentOrder={handleOpenPaymentOrder}
              onSearchCnpj={handleSearchCnpj}
              onListBusinessPartnerGroup={handleListBusinessPartnerGroup}
              onListRepresentative={handleListRepresentative}
              passwordModal={{
                openPasswordResetModal,
                setOpenPasswordResetModal,
                handlePasswordReset,
              }}
              createUserModal={{
                openCreateUserModal,
                setOpenCreateUserModal,
                handleCreateUser,
              }}
            />
          )
        }
        actions={
          showFilter
            ? [
                {
                  label: 'Limpar Filtro',
                  icon: MdClear,
                  action: () => handleFilter({}),
                },
                {
                  label: 'Aplicar Filtro',
                  icon: MdCheck,
                  action: (data) => handleFilter(data),
                },
              ]
            : getActions()
        }
      />
    </Container>
  )
}

export default BusinessPartner
