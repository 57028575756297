import { all, put, call, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '~/services/api';
import { getError } from  '~/helper';
import { Creators as DashboardActions, Types as DashboardTypes } from '~/store/modules/dashboard';

export function showError(error) {
  const msg =  getError(error);
  toast.error(msg);
}

export function* load({ params }) {
  try {
    const response = yield call(api.post, 'dashboard/load', params);
    yield put(DashboardActions.loadSuccess(response.data));
  } catch (error) {
    yield put(DashboardActions.loadSuccess({}));
    showError(error);
  }
};

export function* listBankAccounts({ term, callback }) {
  try {
    const response = yield call(api.get, 'dashboard/list-bank-accounts', { params: { term } });
    yield call(callback, response.data);
  } catch (error) {
  }
};

export function* listCompanies({ term, callback }) {
  try {
    const response = yield call(api.get, 'dashboard/list-companies', { params: { term } });
    yield call(callback, response.data);
  } catch (error) {
  }
};

export default all([
  takeLatest(DashboardTypes.LOAD, load),
  takeLatest(DashboardTypes.LIST_COMPANIES, listCompanies),
  takeLatest(DashboardTypes.LIST_BANK_ACCOUNTS, listBankAccounts),
]);
