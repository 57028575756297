import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { formats, sortCaseInsesitive, sortByDate, sortByMaxOrMin } from '~/helper';
import { Container, Content } from '~/pages/prepare-bills/styles';
import { Formik } from 'formik';
import { IconButton } from '~/components/button';
import { MdKeyboardBackspace, MdCheck } from 'react-icons/md';
import { BiCalendarEvent } from 'react-icons/bi';
import { HiOutlineExternalLink } from 'react-icons/hi';
import { formatBankAccountGrid } from '~/pages/bank-accounts/form';
import { Creators as PrepareBillsActions } from '~/store/modules/prepare-bills';
import { Creators as HeaderActions } from '~/store/modules/header';
import { Creators as BillActions } from '~/store/modules/bill';
import { FormHeader, FormToolbar } from '~/components/crud/styles';
import FormFilter from '~/pages/prepare-bills/filter';
import Footer from '~/pages/prepare-bills/footer';
import SummaryModal from '~/pages/prepare-bills/modal-summary';
import PrepareModal from '~/pages/prepare-bills/modal-prepare';
import Kanban from '~/components/kanban';
import confirm from '~/components/confirm';
import _ from 'lodash';
import { accent, primary } from '~/components/mixins/color';
import { VscServerProcess } from 'react-icons/vsc';

const sortByBusinessPartner = (a, b) => sortCaseInsesitive(a, b , 'businessPartner.name');
const sortByDocument = (a, b) => sortCaseInsesitive(a, b , 'documentNumber');
const sortByBillType = (a, b) => sortCaseInsesitive(a, b , 'billType.name');
const sortByValue = (a, b) => sortByMaxOrMin(a, b, 'value');
const sortByDueDate = (a, b) => sortByDate(a, b , 'dueDate');

const columns = [
  {
    name: 'Tipo da cobrança',
    selector: 'billType',
    compact: true,
    width: '130px',
    format: row => _.get(row, 'billType.name'),
    sortable: true,
    sortFunction: sortByBillType
  },
  {
    name: 'Documento',
    selector: 'documentNumber',
    width: '150px',
    format: row => _.get(row, 'documentNumber'),
    sortable: true,
    sortFunction: sortByDocument
  },
  {
    name: 'Pagador',
    selector: 'businessPartner',
    compact: true,
    format: row => {
      const name = _.get(row, 'businessPartner.name'),
        identity = _.get(row, 'businessPartner.identity');
      return `${formats.cnpj_cpf(identity)} - ${name}`;
    },
    sortable: true,
    sortFunction: sortByBusinessPartner
  },
  {
    name: 'Dt Vencimento',
    selector: 'dueDate',
    width: '100px',
    right: true,
    compact: true,
    format: row => formats.dateTimeZone(row.dueDate, 'dd/MM/yyyy'),
    sortable: true,
    sortFunction: sortByDueDate
  },
  {
    name: 'Valor',
    selector: 'value',
    right: true,
    width: '90px',
    compact: true,
    format: row => formats.currency(_.get(row, 'value')),
    sortable: true,
    sortFunction: sortByValue
  }
];

const cardOptions = {
  color: row => (row.bankContractId === '000' ? accent.hex() : primary.hex()),
  title: row => `Doc: ${_.get(row, 'documentNumber') || ' '}`,
  subtitle: row => <strong>{formats.currency(_.get(row, 'value'))}</strong>,
  info: row => (
    <>
      <p>{`Vencimento: ${formats.dateTimeZone(row.dueDate, 'dd/MM/yyyy')}`}</p>
    </>
  ),
  detail: row => {
    const cm = _.get(row, 'company') || {},
      bp = _.get(row, 'businessPartner') || {};

    return (
      <>
        <p>{`${formats.cnpj_cpf(cm.identity)} - ${cm.name || 'Sem emissor'}`}</p>
        <p>{`${formats.cnpj_cpf(bp.identity)} - ${bp.name || 'Sem pagador'}`}</p>
      </>
    );
  },
  conditionalStyle: row => {
    let val = _.get(row, 'dueDate'),
      style = {};

    if (val && formats.dateTimeZone(val, 'yyyy-MM-dd') < formats.dateTimeZone(new Date(), 'yyyy-MM-dd')) {
      style = {
        background: '#fee9eb'
      };
    }
    return style;
  },
  conditionalIcons: row => {
    let val = _.get(row, 'dueDate'),
      icons = [];

    if (val && formats.dateTimeZone(val, 'yyyy-MM-dd') < formats.dateTimeZone(new Date(), 'yyyy-MM-dd')) {
      icons.push({
        icon: BiCalendarEvent,
        color: '#de1919',
        tip: 'Fatura vencida'
      });
    }
    return icons;
  }
};

const PrepareBills = ({ history, acls }) => {
  const canCreateShipping = acls.includes('S');
  const dispatch = useDispatch();
  const state = useSelector(state => state.prepareBills);
  const headerState = useSelector(state => state.header);
  const [bankContractMovement, setBankContractMovement] = useState();
  const [openPrepareModal, setOpenPrepareModal] = useState(false);
  const [openSummaryModal, setOpenSummaryModal] = useState(false);
  const [selecteds, setSelecteds] = useState([]);
  const [dataToShipping, setDataToShipping] = useState({});
  const canWrite = acls.includes('W');

  useEffect(() => {
    dispatch(
      HeaderActions.configure({
        subtitle: prepareSubTitle(),
        loading: state.loading,
        useFilter: true,
        filter: { ...headerState.filter, visible: false, scope: '/bills-prepare' }
      })
    );
    // eslint-disable-next-line
  }, [state, dispatch]);

  useEffect(() => {
    (() => {
      let { dueDateStart, dueDateEnd, company } = _.get(headerState, 'filter.data');
      handleFilter({ dueDateStart, dueDateEnd, company });
      dispatch(PrepareBillsActions.listBankContracts());
    })();
    // eslint-disable-next-line
  }, [dispatch]);

  const prepareSubTitle = () => {
    const dueDateStart = _.get(headerState, 'filter.data.dueDateStart');
    const dueDateEnd = _.get(headerState, 'filter.data.dueDateEnd');

    if (!dueDateStart || !dueDateEnd) {
      return '';
    }
    return `Período ${formats.date(dueDateStart)} até ${formats.date(dueDateEnd)}`;
  };

  const handleHideFilter = () => {
    dispatch(HeaderActions.hideFilter());
  };

  const handleFilter = data => {
    dispatch(HeaderActions.callFilter(data, PrepareBillsActions.load));
    dispatch(PrepareBillsActions.listBankContracts());
    handleHideFilter();
  };

  const handleListBillTypes = (term, callback) => {
    dispatch(PrepareBillsActions.listBillTypes(term, callback));
  };

  const handleListCompanies = (term, callback) => {
    dispatch(PrepareBillsActions.listCompanies(term, callback));
  };
  function handleListBusinessPartners(term, callback) {
    dispatch(BillActions.listBusinessPartners(term, callback));
  }
  const handleCardClick = ({ id }) => {
    history.push({ pathname: `/bills/${id}`, sourcepath: 'prepare-bills' });
  };

  const validateBills = (bankAccounts, data) => {
    let errors = {};

    for (let contractId in data) {
      let billsList = data[contractId] || [],
        bankAccount = _.get(bankAccounts[contractId], 'extra.bankAccount');

      for (let bill of billsList) {
        let hasError = false,
          reasons = [];

        if (formats.dateTimeZone(_.get(bill, 'dueDate'), 'yyyy-MM-dd') < formats.dateTimeZone(new Date(), 'yyyy-MM-dd')) {
          reasons.push('overdue');
          hasError = true;
        }

        if (hasError) {
          errors[contractId] = errors[contractId] || { bills: [], reasons: [], text: formatBankAccountGrid(bankAccount) };
          errors[contractId].bills.push(bill.id);
          errors[contractId].reasons = _.concat(errors[contractId].reasons, _.uniq(reasons));
        }
      }
    }
    return { errors };
  };

  const onDragEnd = result => {
    if (!result.destination) return;
    dispatch(PrepareBillsActions.movement(state.bills, result, state.bankContracts));
  };

  const handleBoardAction = boardId => {
    const bankContract = boardId !== '000' ? state.bankContracts[boardId] : null;
    setBankContractMovement(bankContract);
    setOpenPrepareModal(true);
  };

  const changeSelectBills = data => {
    if (_.isArray(data)) {
      setSelecteds(data);
    } else {
      let list = _.cloneDeep(selecteds || []),
        index = _.findIndex(list, r => r.id === data.id);
      if (index >= 0) {
        list.splice(index, 1);
      } else {
        list.push(data);
      }
      setSelecteds(list);
    }
  };

  const prepareBankContractsToSelect = (term, callback) => {
    console.table(state.bankContracts);
    const list = _.chain(state.bankContracts)
      .filter(row => {
        let exists = false;

        const isBankAccount = row.id !== '000';
        if (_.isEmpty(term)) {
          return isBankAccount;
        }

        const termRegex = new RegExp(_.deburr(term), 'ig');
        const termRegexWithoutGlobalSearch = new RegExp(_.deburr(term), 'i');

        const accountNumberWithDigit =
          _.get(row?.extra?.bankAccount, 'accountNumber') && _.get(row?.extra?.bankAccount, 'accountNumberDigit')
            ? `${_.get(row?.extra?.bankAccount, 'accountNumber')}-${_.get(row?.extra?.bankAccount, 'accountNumberDigit')}`
            : '';
        const contractName =
          _.get(row, 'code') && _.get(row, 'subDetail') && accountNumberWithDigit
            ? `${_.get(row, 'code')} - ${accountNumberWithDigit} - ${_.get(row, 'subDetail')}`
            : '';
        const agencyWithDigit =
          _.get(row?.extra?.bankAccount, 'bankBranch') && _.get(row?.extra?.bankAccount, 'bankBranchDigit')
            ? `${_.get(row?.extra?.bankAccount, 'bankBranch')}-${_.get(row?.extra?.bankAccount, 'bankBranchDigit')}`
            : '';

        const existsLabel = termRegex.test(_.deburr(_.get(row, 'label'))); // Nome do banco
        const existsAccountNumber = termRegexWithoutGlobalSearch.test(accountNumberWithDigit); // Número da conta
        const existsCompayName = termRegex.test(_.deburr(_.get(row?.extra?.company, 'name'))); // Nome da empresa
        const existsSubDetail = termRegexWithoutGlobalSearch.test(_.get(row, 'subDetail')); // CNPJ
        const existsBankCode = termRegexWithoutGlobalSearch.test(_.get(row, 'code')); // Código do banco
        const existsContract = termRegex.test(_.deburr(contractName)); // Nome do Contrato
        const existsAgency = termRegexWithoutGlobalSearch.test(agencyWithDigit); // Agência + Digito

        exists = existsLabel || existsAccountNumber || existsCompayName || existsSubDetail || existsBankCode || existsContract || existsAgency;

        return isBankAccount && exists;
      })
      .map(row => {
        return row.extra;
      })
      .value();
    callback(list);
  };

  const handleSubmit = ({ bankContract }, actions) => {
    actions.closeModal = () => setOpenPrepareModal(false);
    dispatch(PrepareBillsActions.movementInBatch(bankContract, selecteds, actions));
  };

  const onFooterClick = async ({ value }) => {
    const bankContractId = _.get(value, 'id') || 'all';
    const data = bankContractId === 'all' ? _.pickBy(state.bills, (v, k) => k !== '000' && _.size(v) > 0) : { [bankContractId]: state.bills[bankContractId] };
    const { errors } = validateBills(state.bankContracts, data);

    if (!_.isEmpty(errors)) {
      let billIds = _.flattenDeep(_.map(_.values(errors), r => r.bills)),
        size = _.size(billIds),
        message = size === 1 ? `Uma fatura está com problema, edite-a para continuar` : `${size} faturas estão com problemas, edite-as para continuar`,
        entityFn = (len, singular, plural) => (len === 1 ? `Uma fatura ${singular}` : `${len} Faturas ${plural}`);

      message += `<br/>`;
      for (let err of _.values(errors)) {
        let stats = _.groupBy(err.reasons, _.identity),
          overdueLen = _.size(stats['overdue']),
          parts = [];

        if (overdueLen > 0) {
          parts.push(entityFn(overdueLen, 'vencida', 'vencidas'));
        }
        message += `<br/>Contrato <strong>[${err.text}]</strong>: ${parts.join(', ')}`;
      }
      await confirm.show({
        height: 300,
        width: 650,
        title: 'Atenção',
        text: message,
        options: [{ label: 'Fechar', value: 'skip' }]
      });
    } else {
      setDataToShipping(data);
      setOpenSummaryModal(true);
    }
  };

  const handleShippingCreate = () => {
    const closeModal = () => setOpenSummaryModal(false);
    dispatch(PrepareBillsActions.shippingCreate(dataToShipping, { closeModal }));
  };

  const Filter = () => {
    return (
      <div className="right">
        <Formik onSubmit={handleFilter} initialValues={headerState.filter.data} enableReinitialize={true} validateOnMount={true}>
          {args => {
            return (
              <>
                <FormHeader>Filtro</FormHeader>
                <FormFilter
                  {...args}
                  onListBillTypes={handleListBillTypes}
                  onListCompanies={handleListCompanies}
                  onListBusinessPartners={handleListBusinessPartners}
                />
                <FormToolbar>
                  <IconButton title="Cancelar" disabled={state.loading} onClick={handleHideFilter}>
                    <MdKeyboardBackspace />
                  </IconButton>
                  <IconButton title="Aplicar Filtro" disabled={state.loading} onClick={args.handleSubmit}>
                    <MdCheck />
                  </IconButton>
                </FormToolbar>
              </>
            );
          }}
        </Formik>
      </div>
    );
  };

  return (
    <Container showForm={headerState.filter.visible}>
      <div className="left">
        {_.size(state.bankContracts) > 0 && (
          <Content>
            <Kanban
              summary={state.summaryData || {}}
              boards={state.bankContracts}
              boardIconTip="Ver lista de faturas"
              boardConditionalStyle={board => {
                let bankContract = _.get(board, 'extra') || {};
                if (false && bankContract.fileDestination === 'van') {
                  return { background: '#66666619' };
                }
                return {};
              }}
              boardConditionalIcons={board => {
                let bankContract = _.get(board, 'extra') || {},
                  icons = [];

                if (bankContract.fileDestination === 'van') {
                  icons.push({
                    icon: HiOutlineExternalLink,
                    color: '#444444',
                    tip: 'Remessas desse contrato são enviadas para a VAN'
                  });
                }

                if (board.id !== '000') {
                  icons.push({
                    icon: VscServerProcess,
                    tip: 'Gerar Remessa',
                    action: () => onFooterClick({ value: bankContract })
                  });
                }

                return icons;
              }}
              cards={state.bills}
              cardOptions={cardOptions}
              onCardClick={handleCardClick}
              onChangeCards={onDragEnd}
              onBoardAction={canWrite ? handleBoardAction : _.noop}
              cardField="id"
              isDragDisabled={!canWrite}
            />
            <Footer
              data={state.bankContracts}
              canCreateShipping={canCreateShipping}
              onClick={onFooterClick}
              billType={_.get(headerState, 'filter.data.billType') || {}}
            />
          </Content>
        )}
        <PrepareModal
          isOpen={openPrepareModal}
          closeModal={() => setOpenPrepareModal(false)}
          dataList={_.get(state.bills, _.get(bankContractMovement, 'id') || '000')}
          dataColumns={columns}
          selecteds={selecteds}
          handleOnChangeSelect={changeSelectBills}
          onListBankContracts={prepareBankContractsToSelect}
          bankContractMovementFrom={bankContractMovement}
          handleOnSubmit={handleSubmit}
        />
        <SummaryModal
          isOpen={openSummaryModal}
          closeModal={() => setOpenSummaryModal(false)}
          data={dataToShipping}
          bankContracts={state.bankContracts}
          onConfirm={handleShippingCreate}
          isSubmiting={state.isShippingCreating}
        />
      </div>
      <Filter />
    </Container>
  );
};

export default PrepareBills;
