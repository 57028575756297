
import styled from 'styled-components';
import { white, primary, link } from '~/components/mixins/color';
import { Button } from '~components/button';

export const SignButton = styled(Button)`
  border: none;
  background: #ffffff40;
  color: #fff;
  &:hover:enabled, &:focus:enabled {
    background: #ffffff80;
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const Version = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  padding: 10px;
  color: ${white.hex()};
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const Navbar = styled.div`
  display: flex;
  flex-direction: row;
  flex: 0 0 70px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 5px 100px;
  background: ${primary.hex()};

  .links {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;

    a {
      text-decoration: none;
      color: #fff;
      margin: 0 10px;
      font-weight: bold;
    }
  }

  > div {
    display: flex;
  }
`;

export const Contents = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  height: calc(100vh - 70px);
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  user-select: none;
`;

export const ForgetPasswordLink = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 14px;
  color: ${link.hex()};
  cursor: pointer;
  width: 100%;
  text-align: right;
  &:hover {
    text-decoration: underline;
  }
`;

const gradient = `linear-gradient(135deg, ${primary.hex()}, ${primary.hex()}, ${primary.fade(0.35).string()})`

export const Lane = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  padding: 40px;
  color: ${props => props.inverse ? white.hex() : primary.hex()};
  background: ${props => props.inverse ? gradient : white.hex() };
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
`

export const LaneContents = styled.div`
  display: flex;
  max-width: 1140px;
  flex-direction: column;
  width: 100%;
  align-self: center;

  h1 {
    font-size: 26px;
    margin-bottom: 30px;
  }

  .main {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;

    .block {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;
      div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: calc(100%);
      }
    }
    h2 {
      font-size: 38px;
    }
    h4 {
      font-size: 28px;
      margin-top: 30px;
      font-weight: normal;
    }
    p {
      flex: 0 0 22px;
      font-size: 18px;
    }
    .action {
      width: 50%;
      height: auto;
      object-fit: contain;
    }
  }

  .about {
    display: flex;
    flex-direction: row;
    width: 100%;
    color: #555;
    padding-top: 30px;
    align-items: center;
  }
  .about-title {
    color: #555;
    font-size: 24px;
    line-height: 30px;
    font-weight: normal;
  }
  .about-diagram {
    flex: 0 0 50%;
    display: flex;
    justify-content: center;
    img {
      width: 80%;
    }
  }
  .about-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    h5 {
      font-size: 24px;
      font-weight: bold;
      margin: 30px 0;
    }
    p {
      font-size: 18px;
    }
  }
  .how-it-works-image {
    border: thin dashed #fff;
    padding: 30px;
    border-radius: 2px;
    text-align: center;

    img {
      height: 300px;
      width: auto;
    }
  }
  .how-it-works-contents {
    margin-top: 25px;
    line-height: 26px;
    font-size: 22px;
    text-align: justify;
  }
  .registered {
    h3 {
      font-size: 24px;
    }
    p {
      text-align: justify;
      font-size: 18px;
      line-height: 24px;
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }
  .features {
    h3 {
      font-size: 24px;
    }
    p {
      text-align: justify;
      font-size: 18px;
      line-height: 24px;
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }
  .who-we-are {
    text-align: justify;
    font-size: 22px;
    line-height: 36px;
    color: #555;
  }
  .contact {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 30px;

    a {
      text-decoration: none;
      color: #fff;
      font-size: 60px
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      span {
        font-size: 16px;
        margin-top: 8px;
      }
    }
  }
`;

export const BackToTop = styled.a`
  position: absolute;
  bottom: 20px;
  right: 20px;
  border-radius: 3px;
  color: ${primary.hex()};
  border: thin solid ${primary.hex()};
  font-size: 24px;
  width: 24px;
  height: 24px;
  background: #fff;
  text-align: center;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  `

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;

  h3 {
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    font-weight: normal;
  }

  form {
    text-align: center;
    width: 100%;
  }

  img {
    margin-top: 20px;
    margin-bottom: 30px;
  }
`;

export const EmailConfirmContents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  background: #fff;
  color: #888;
  height: 100%;
  width: 600px;
  padding: 50px 0;

  h3 {
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    font-weight: normal;
    margin-bottom: 30px;
  }

  h5 {
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    font-weight: normal;
    margin-bottom: 50px;
  }

  a, a:hover, a:active, a:focus, a:visited {
    color: ${primary.hex()};
    font-weight: bold;
    text-decoration: none;
  }

  button {
    align-self: flex-end;
    display: flex;
  }
`;
