import { put, call, takeLatest, select, all } from '@redux-saga/core/effects';
import { showError } from '~/helper';
import api from '~/services/api';
import { formats } from '~/helper';
import { Creators as Actions, Types } from '~/store/modules/prepare-bills';
import _ from 'lodash';
import { toast } from 'react-toastify';

function* getFilterData() {
  const headerState = yield select(state => state.header);
  return _.get(headerState, 'filter.data') || {};
}

export function* load({ params }) {
  try {
    const response = yield call(api.post, 'prepare-bills/load', { ...params });
    yield put(Actions.loadSuccess(response.data));
  } catch (error) {
    showError(error);
  }
}

export function* listBankContracts({ params }) {
  try {
    const response = yield call(api.get, 'prepare-bills/list-bank-contracts');
    yield put(Actions.listBankContractsSuccess(response.data));
  } catch (error) {
    showError(error);
  }
}

export function* listBillTypes({ term, callback }) {
  try {
    const response = yield call(api.get, '/prepare-bills/list-bill-types', { params: term });
    yield call(callback, response.data);
  } catch (error) {
    showError(error);
  }
}

export function* listCompanies({ term, callback }) {
  try {
    const response = yield call(api.get, 'prepare-bills/list-companies', {
      params: { term }
    });
    yield call(callback, response.data);
  } catch (error) {}
}

const checkContractPolicy = (bankContract, items) => {
  let errorMessage = '',
    dstPolicy = _.get(bankContract, 'documentBindPolicy') || 'any',
    dstIdentity = _.get(bankContract, 'bankAccount.company.identity'),
    dstIdentityBase = String(dstIdentity).substr(0, 8);

  for(let item of items) {
    let identity = _.get(item, 'company.identity'),
      identityBase = String(identity).substr(0, 8);

    if (dstPolicy === 'baseCnpj' && dstIdentityBase !== identityBase) {
      errorMessage = `Contrato destino aceita apenas faturas de CNPJs com raiz ${dstIdentityBase}`;
      return { allowByContract: false, errorMessage };
    } else if (dstPolicy === 'cnpj' && dstIdentity !== identity) {
      errorMessage = `Contrato destino aceita apenas faturas do CNPJ ${formats.cnpj_cpf(dstIdentity)}`;
      return { allowByContract: false, errorMessage };
    }
  }
  return { allowByContract: true };
};

export function* movement({ oldData, data, bankContracts }) {
  try {
    const { source, destination } = data;
    const previewData = _.cloneDeep(oldData);
    const sourceItems = _.get(previewData, source.droppableId) || [];
    const [updated] = sourceItems.splice(source.index, 1);
    const dstBankContract = _.get(bankContracts, `${_.get(destination, 'droppableId')}.extra`);
    const { allowByContract, errorMessage } = checkContractPolicy(dstBankContract, [updated]);

    if (!allowByContract) {
      showError({ error: { message: errorMessage } });
      return;
    }

    if (source.droppableId !== destination.droppableId) {
      updated.bankContractId = destination.droppableId;

      const destItems = _.get(previewData, destination.droppableId) || [];
      destItems.splice(destination.index, 0, updated);

      const preparedData = {
        ...previewData,
        [source.droppableId]: [...sourceItems],
        [destination.droppableId]: [...destItems]
      };

      yield put(Actions.movementSuccess(preparedData));
      yield call(api.put, `prepare-bills/change-bank-contract/${updated.id}`, _.pick(updated, 'bankContractId'));
    }
  } catch (error) {
    showError(error);
    yield put(Actions.load(yield call(getFilterData)));
  }
}

export function* movementInBatch({ bankContract, selecteds, actions }) {
  try {
    const { allowByContract, errorMessage } = checkContractPolicy(bankContract, selecteds);

    if (!allowByContract) {
      showError({ error: { message: errorMessage } });
      return;
    }
    actions.setSubmitting && actions.setSubmitting(true);
    const bankContractId = _.get(bankContract, 'id');
    const billsId = _.map(selecteds, r => r.id);
    yield call(api.put, `prepare-bills/change-bank-contract-in-batch`, { billsId, bankContractId });
    yield put(Actions.load(yield call(getFilterData)));
    actions.closeModal();
  } catch (error) {
    showError(error);
  } finally {
    actions.setSubmitting && actions.setSubmitting(false);
    actions.resetForm && actions.resetForm();
  }
}

export function* handleshippingCreate({ data, actions }) {
  try {
    yield call(api.post, 'prepare-bills/create-charge-shipping', { ...data });

    const size = _.size(_.keys(data));
    const message = size === 1 ? `Remessa criada com sucesso.` : `${size} Remessas criadas com sucesso.`;
    toast.success(message);
    actions.closeModal && actions.closeModal();

    yield put(Actions.load(yield call(getFilterData)));
  } catch (error) {
    showError(error);
  } finally {
    yield put(Actions.shippingCreateSuccess());
  }
}

export default all([
  takeLatest(Types.LOAD, load),
  takeLatest(Types.LIST_BANK_CONTRACTS, listBankContracts),
  takeLatest(Types.LIST_BILL_TYPES, listBillTypes),
  takeLatest(Types.LIST_COMPANIES, listCompanies),
  takeLatest(Types.MOVEMENT, movement),
  takeLatest(Types.MOVEMENT_IN_BATCH, movementInBatch),
  takeLatest(Types.SHIPPING_CREATE, handleshippingCreate)
]);
