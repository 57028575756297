import { useLocation, useParams } from 'react-router-dom'
import _ from 'lodash'

const useSearchParameters = () => {
  const location = useLocation(),
    routeParams = useParams()

  const getLocationData = () =>
    Object.fromEntries(new URLSearchParams(_.get(location, 'search', {})))

  const getRouteParams = () => routeParams

  const getAllParams = () => 
    _.chain({})
      .merge(_.get(location,'state',{}), getLocationData(), routeParams)
      .omitBy(_.isNil)
      .omitBy(_.isUndefined)
      .value()
    
  const toStringify = (data = {}) => new URLSearchParams(data)

  return {
    location,
    routeParams,
    getLocationData,
    getRouteParams,
    getAllParams,
    toStringify,
  }
}

export default useSearchParameters
