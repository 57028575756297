import styled, { css } from 'styled-components';
import { primary, white, link } from '~/components/mixins/color';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  .left {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(100%);
    width: ${props => (props.showForm ? 'calc(75%)' : 'calc(100%)')};
    padding: 10px;
    ::-webkit-scrollbar {
      display: none;
    }
    & ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 5px;
      height: 10px;
    }
    & ::-webkit-scrollbar-thumb {
      cursor: pointer;
      border-radius: 1px;
      background: rgba(0, 0, 0, 0.15);
      -webkit-transition: color 0.2s ease;
      transition: color 0.2s ease;
    }
  }

  .right {
    display: ${props => (props.showForm ? 'flex' : 'none')};
    box-shadow: -1px -1px 8px 1px rgb(0 0 0 / 10%);
    flex-direction: column;
    position: relative;
    flex: 0 0 25%;
    background: #fff;
    width: 100%;
    border-radius: 3px;
    justify-content: space-between;
  }

  ${props =>
    props.showForm &&
    css`
      .right {
        opacity: 1;
      }
      @media (max-width: 1024px) {
        .left {
          display: none;
          visibility: hidden;
        }
        .right {
          flex: 1;
          margin-left: 0px;
        }
      }
    `
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  width: 100%;
  padding: 0 10px;
  margin-top: 10px;
  background: ${white.hex()};
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: calc(100%);
  span {
    margin: 0 0 5px;
  }
`;

export const BankAccountContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  margin: 10px 10px 10px 0;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;

    .logo {
      width: 28px;
      height: auto;
      object-fit: contain;
      margin-right: 10px;
    }

    h2 {
      font-weight: 500;
      display: flex;
      flex-direction: column;
      justify-content: center;

      label {
        font-size: 15px;
        color: #111;
        text-transform: lowercase;

        &::first-line {
          text-transform: capitalize;
        }
      }

      span {
        font-size: 13px;
        color: #444;
      }
    }
  }

  h1 {
    font-size: 15px;
    color: #666;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  .f-1 {
    display: flex;
    flex: 1;
  }
  .f-150 {
    display: flex;
    flex: 0 0 150px;
  }
  .f-200 {
    display: flex;
    flex: 0 0 200px;
  }
  .f-right {
    justify-content: flex-end;
  }
  .f-center {
    justify-content: center;
  }
`;

export const LineHeader = styled.div`
  display: flex;
  gap: 10px;
  line-height: 24px;
  font-size: 12px;
  color: #666;
  font-weight: 600;
  align-items: center;
  background: #fefefe;
  padding: 5px;
`;

export const GroupValues = styled.div`
  display: flex;
  flex: 0 0 360px;
  gap: 10px;
  align-items: center;
`

export const LineContainer = styled.div`
  display: flex;
  gap: 10px;
  background: ${props => (props && props.index % 2 === 0 ? primary.fade(0.9).string() : white.hex())};
  align-items: center;
  padding: 5px;

  span {
    font-size: 14px;
    color: #666;
    font-weight: 600;
  }
`;

export const Company = styled.div`
  display: flex;
  color: ${link.hex()};
  font-weight: 600;
  font-size: 14px;
`;
