import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import _ from 'lodash';
import Modal from '~/components/modal';
import { ModalContainer } from '~pages/prepare-bills/styles';
import { Autocomplete } from '~components/form';
import { Creators as BusinessPartnerActions } from '~/store/modules/business-partner';
import { useDispatch } from 'react-redux';
import { formats } from '~/helper';

const CreateUserModal = ({
  values,
  createUserModal,
}) => {

  const [company, setCompany] = useState(null);
  const dispatch = useDispatch();

  var data = {...values, company: null};

  const handleLoadData = (term, callback) => {
    dispatch(BusinessPartnerActions.listCompanies(term, callback));
  };

  const closeCreateUserModal = () => {
    createUserModal.setOpenCreateUserModal(false);
  }

  const onSubmit = (data) => {
    createUserModal.handleCreateUser({...data, company}, closeCreateUserModal);
  }

  return (
    <Formik initialValues={values} onSubmit={onSubmit} validateOnMount={false}>
      {({ handleReset, errors, touched }) => {
        return (
          <Modal
            width="630px"
            height="230px"
            title="Atenção"
            hideClose={true}
            open={createUserModal.openCreateUserModal}
            bindRefWithSubmit={true}
            hide={closeCreateUserModal}
            actions={[
              {
                label: 'Não',
                action: () => {
                  handleReset();
                  closeCreateUserModal();
                }
              },
              {
                label: 'Sim',
                action: () => onSubmit(data),
                type: 'submit',
                primary: true
              }
            ]}
          >
            <ModalContainer>
              <Form>
                <Autocomplete
                  autoFocus={true}
                  name="company"
                  keyField="id"
                  clearable={true}
                  label="Empresa"
                  valueFormat={row => `${formats.cnpj_cpf(row.identity)} - ${row.name || row.tradeName}`}
                  tipText={'Digite... '}
                  loadingText={'Carregando...'}
                  notFoundText={'Não encontrada'}
                  emptyText={'Informe a empresa'}
                  value={company}
                  loadData={handleLoadData}
                  hasError={_.get(errors, 'company') && _.get(touched, 'company')}
                  onChange={(args) => setCompany(args)}
                />
                <br />
                <p>Deseja realmente cadastrar um usuário para o parceiro de negócio e enviar os dados de acesso por e-mail?</p>
              </Form>
            </ModalContainer>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default CreateUserModal;