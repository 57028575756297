import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import Modal from '~/components/modal';
import { ModalContainer } from '~pages/payment-orders/styles';
import { InputDate } from '~/components/form';
import { startOfDay } from 'date-fns';

const ChangeScheduledDateModal = ({ isOpen, closeModal, handleOnSubmit }) => {
  const today = startOfDay(new Date());

  return (
    <Formik initialValues={{ scheduledDate: today }} validationSchema={schema} onSubmit={handleOnSubmit} validateOnMount={false}>
      {({ errors, touched, isValid, isSubmitting, handleSubmit, handleReset }) => {
        return (
          <Modal
            width="400px"
            height="170px"
            title="Alteração data de agendamento em lote"
            hideClose={true}
            open={isOpen}
            bindRefWithSubmit={true}
            hide={closeModal}
            actions={[
              {
                label: 'Cancelar',
                action: () => {
                  handleReset();
                  closeModal();
                }
              },
              {
                label: 'Confirmar',
                action: handleSubmit,
                type: 'submit',
                disabled: !isValid || isSubmitting,
                primary: true
              }
            ]}
          >
            <ModalContainer>
              <InputDate
                name="scheduledDate"
                label="Data de agendamento *"
                hideErrorLabel={false}
                minDate={today}
                hasError={_.get(errors, 'scheduledDate') && _.get(touched, 'scheduledDate')}
              />
            </ModalContainer>
          </Modal>
        );
      }}
    </Formik>
  );
};

const schema = Yup.object().shape({
  scheduledDate: Yup.date().min(startOfDay(new Date()), 'A nova data de agendamento deve ser superior a date atual')
});

export default ChangeScheduledDateModal;
