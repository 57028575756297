import React, { useLayoutEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { MdCheck, MdClear, MdSave, MdCancel, MdEditNote, MdQueuePlayNext } from 'react-icons/md'
import _ from 'lodash'
import Crud from '~/components/crud'
import CellStatus from '~/components/datatable/cell-status'
import {
  Container,
  LeftContainer,
  Dashboard,
  DashboardGroup,
} from '~/pages/credits-receiveds/styles'
import { Creators as CreditsReceivedsActions } from '~/store/modules/credits-receiveds'
import { Creators as HeaderActions } from '~/store/modules/header'
import { formats, getColorByContrast } from '~/helper'
import confirm from '~/components/confirm'
import FormFilter from '~/pages/credits-receiveds/filter'
import Form from '~/pages/credits-receiveds/form'
import CellBank from '~/components/datatable/cell-bank'
import useSearchParameters from '~/hooks/use-search-params'
import { toast } from 'react-toastify'
import { isValidCnpj } from '~/validator'

export const statusColors = {
  open: '#90d1ff',
  binded: '#a3d865',
  discarded: '#BA1F33',
  processed: '#6264a7',
}

export const statusLabels = {
  open: 'Aberto',
  binded: 'Vinculado',
  discarded: 'Descartado',
  processed: 'Processado'
}

export const docmentTypes = {
  D: 'DOC',
  T: 'TED',
  F: 'Transferência',
  P: 'Pix',
}

export const columns = [
  {
    name: ' ',
    selector: 'status',
    width: '10px',
    cell: (row) => {
      let status = row.status || 'open'
      return (
        <CellStatus title={statusLabels[status]} color={statusColors[status]} />
      )
    },
  },
  {
    name: 'Banco',
    selector: 'bankContract',
    width: '100px',
    cell: (row) => (
      <CellBank
        bankCode={String(_.get(row, 'bankContract.bankAccount.bank.code'))}
        bankAccount={_.get(row, 'bankContract.bankAccount')}
      />
    ),
  },
  {
    name: 'Remetente / Empresa',
    selector: 'bankContract',
    wrap: true,
    hide: 'lg',
    cell: (row) => {
      const company = _.get(row, 'bankContract.bankAccount.company') || {},
        name = _.get(row, 'senderName') || '',
        identity = isValidCnpj(_.get(row, 'senderIdentity'))
          ? _.get(row, 'senderIdentity')
          : String(_.get(row, 'senderIdentity')).length > 3
          ? String(_.get(row, 'senderIdentity')).substring(3)
          : _.get(row, 'senderIdentity')

      return (
        <table className="company-info">
          <tbody>
            <tr>
              <td width="120">{formats.cnpj_cpf(identity)}</td>
              <td title={name}>{_.truncate(name, { length: 50 })}</td>
            </tr>
            <tr>
              <td width="120">{formats.cnpj_cpf(company.identity)}</td>
              <td title={company.name || company.tradeName}>
                {_.truncate(company.name || company.tradeName, { length: 50 })}
              </td>
            </tr>
          </tbody>
        </table>
      )
    },
  },
  {
    name: 'Nr.controle',
    width: '110px',
    compact: true,
    center: true,
    selector: 'controlNumber',
    format: (row) => _.get(row, 'controlNumber'),
  },
  {
    name: 'Número documento',
    width: '250px',
    compact: true,
    center: true,
    selector: 'docNumber',
    format: (row) => _.get(row, 'docNumber'),
  },
  {
    name: 'Data lançamento',
    selector: 'docDate',
    center: true,
    width: '100px',
    hide: 'lg',
    format: (row) => formats.dateTimeZone(_.get(row, 'docDate'), 'dd/MM/yyyy'),
  },
  {
    name: 'Valor',
    width: '180px',
    right: true,
    compact: false,
    selector: 'value',
    format: (row) => {
      return `${formats.currency(row.value, 'pt-BR', 'BRL')}`
    },
  },
  {
    name: 'Tipo',
    selector: 'docType',
    width: '130px',
    hide: 'lg',
    format: (row) => docmentTypes[_.get(row, 'docType')],
  },
]

const detailTitle = (values = {}) => {
  let dateText = formats.dateTimeZone(values.docDate, 'dd/MM/yyyy'),
    valueText = formats.currency(values.value, 'pt-BR', 'BRL')
  return `Crédito recebido - ${dateText} - ${valueText}`
}

const customStyles = {
  rows: {
    denseStyle: {
      minHeight: '58px',
    },
  },
}

const formActions = (values, canWrite, onCancel, onProcessed, onSave, onReopen) => {
  let actions = []

  if (canWrite && values.status === 'open') {
    actions.push({
      label: 'Descartar',
      icon: MdCancel,
      action: onCancel,
    })
    actions.push({
      label: 'Enviar ao ERP',
      icon: MdQueuePlayNext,
      action: onProcessed,
    })
    actions.push({
      label: 'Processar',
      icon: MdSave,
      action: onSave,
    })
  }
  if (canWrite && values.status === 'processed') {
    actions.push({
      label: 'Renviar ao ERP',
      icon: MdQueuePlayNext,
      action: onProcessed,
    })
    actions.push({
      label: 'Reabrir',
      icon: MdEditNote,
      action: onReopen,
    })
  }
  if (canWrite && values.status === 'discarded') {
    actions.push({
      label: 'Mudar status para aberta',
      icon: MdEditNote,
      action: onReopen,
    })
  }
  return actions
}

const getColorByBgColor = (bgColor) => {
  return getColorByContrast(bgColor, '#fff', '#000')
}

const getElem = ({ label, value }) => {
  return () => (
    <span>
      <strong>{label}</strong>&nbsp;{value}
      <i style={{ padding: '0 5px' }}></i>
    </span>
  )
}

const getDivider = (array, breakLine) => {
  if (breakLine) {
    array.push(() => <span className="break"></span>)
  } else if (_.size(array) > 0) {
    array.push(() => <span className="spacing"></span>)
  }
}

const getDateFilterText = (startDate, endDate) => {
  if (startDate && endDate) {
    return `${formats.date(startDate)} até ${formats.date(endDate)}`
  } else if (startDate && !endDate) {
    return `a partir de ${formats.date(startDate)}`
  } else if (!startDate && endDate) {
    return `até ${formats.date(endDate)}`
  }
  return ''
}

const getSubtitle = (filter) => {
  let elements = [],
    company = _.get(filter, 'company')

  if (!_.isEmpty(company)) {
    getDivider(elements)
    elements.push(
      getElem({
        label: 'Empresa',
        value: `${formats.cnpj_cpf(company.identity)} ${company.name}`,
      }),
    )
  }
  if (!_.isEmpty(filter.senderIdentity)) {
    getDivider(elements)
    elements.push(
      getElem({
        label: 'Remetente',
        value: `${formats.cnpj_cpf(filter.senderIdentity)}`,
      }),
    )
  }

  if (filter.docDateStart || filter.docDateEnd) {
    getDivider(elements)
    elements.push(
      getElem({
        label: 'Data de vencimento',
        value: getDateFilterText(filter.docDateStart, filter.docDateEnd),
      }),
    )
  }

  if (_.isEmpty(elements)) {
    return null
  }

  return _.map(elements, (Element, index) => <Element key={index} />)
}

export default function CreditsReceiveds({ history, match, acls }) {
  const canWrite = acls.includes('W')
  const dispatch = useDispatch()
  const state = useSelector((state) => state.creditsReceiveds)
  const headerState = useSelector((state) => state.header)
  const dashboard = _.get(state, 'data.summary') || {}
  const filter = _.get(headerState, 'filter.data')
  const statusSelected = _.get(filter, 'status') || 'open'
  const [openForm, setOpenForm] = useState(!!_.get(match, 'params.id'))
  const showFilter = headerState.filter.visible
  const statusGroup = ['open', 'binded', 'discarded', 'processed']
  const cardSize = 250

  const {
    location,
    routeParams,
    getAllParams,
    toStringify,
  } = useSearchParameters()

  useLayoutEffect(() => {
    const config = {
      loading: state.loading,
      useFilter: true,
      filter: {
        ...headerState.filter,
        status: statusSelected,
        visible: false,
      },
      subtitle: getSubtitle(filter || {}),
    }
    dispatch(HeaderActions.configure(config))
    // eslint-disable-next-line
  }, [])

  useLayoutEffect(() => {
    const { id, offset, limit = 20, ...filter } = getAllParams()

    const listMethod =
      (_.isEmpty(id) && !showFilter) || _.size(_.get(state, 'data.rows')) === 0
        ? CreditsReceivedsActions.list
        : null

    if (id && !showFilter) {
      dispatch(CreditsReceivedsActions.load({ id }))
    }

    dispatch(
      HeaderActions.callFilter(
        { ...filter, offset: _.toInteger(offset), limit: _.toInteger(limit) },
        listMethod,
      ),
    )
    // eslint-disable-next-line
  }, [location, routeParams])

  function handleLoad(data) {
    const filter = getAllParams()
    history.push({
      pathname: `/credits-receiveds/${_.get(data, 'id', '')}`,
      search: `?${toStringify(
        _.omit(filter, [
          'id',
          'company',
          'bankAccount',
          'createdAtStart',
          'createdAtEnd',
          'docDateStart',
          'docDateEnd',
        ]),
      )}`,
      state: filter,
    })
    setOpenForm(true)
  }

  function handlePageChange(offset, limit) {
    const filter = getAllParams()
    history.push({
      pathname: '/credits-receiveds',
      search: `?${toStringify({
        ..._.omit(filter, [
          'id',
          'company',
          'bankAccount',
          'createdAtStart',
          'createdAtEnd',
          'docDateStart',
          'docDateEnd',
        ]),
        offset,
        limit,
      })}`,
      state: { ..._.omit(filter, 'id'), offset, limit },
    })
    setOpenForm(false)
  }

  function handleHideFilter() {
    const fromPage = _.get(location, 'state.from', false)
    dispatch(HeaderActions.hideFilter())
    dispatch(CreditsReceivedsActions.clearModel())
    setOpenForm(false)

    if (fromPage && fromPage !== 'credits-receiveds') {
      history.goBack()
    }
  }

  function handleFilter(filter) {
    const historySearch = {
      ..._.omit(filter, [
        'company',
        'bankAccount',
        'createdAtStart',
        'createdAtEnd',
        'docDateStart',
        'docDateEnd',
      ]),
      offset: 0,
    }
    handleHideFilter()
    history.push({
      pathname: `/credits-receiveds`,
      search: `?${toStringify(historySearch)}`,
      state: filter,
    })
  }

  function handleListBills(params) {
    dispatch(CreditsReceivedsActions.listBills(params))
  }

  function handleListCompanies(term, callback) {
    dispatch(CreditsReceivedsActions.listCompanies(term, callback))
  }

  function handleListBankContracts(term, callback) {
    dispatch(CreditsReceivedsActions.listBankContracts(term, callback))
  }

  const handleOpenDocument = (data) => {
    const filter = getAllParams()
    history.push({ pathname: `/bills/${data.id}`,
      search: `?${toStringify(
        _.omit(filter, [
          'company',
          'bankAccount',
          'createdAtStart',
          'createdAtEnd',
          'docDateStart',
          'docDateEnd',
        ]),
      )}`,
      state: filter,
      sourcepath: 'credits-receiveds'})
  }

  function filterStatus(status) {
    handleFilter({ ...filter, status })
  }

  async function handleSave(data, actions) {
    let selection = _.get(data, 'orderSelection') || []

    if (_.isEmpty(selection)) {
      toast.error(
        'Não é possível processar crédito recebido sem víncular documentos!',
      )
      return
    }

    let selectionSum = _.get(data, 'orderSelectionSum') || 0,
      options = {
        width: 450,
        title: 'Atenção',
        text: 'Deseja realmente processar o crédito recebido?',
      }

    if (!_.isEmpty(selection) && _.round(selectionSum, 2) !== _.round(data.value, 2)) {
      const docText =
        _.size(selection) === 1
          ? 'do documento selecionado'
          : 'dos documentos selecionados'

      options.text = `O valor total ${docText} (${formats.currency(
        selectionSum,
      )}) difere do valor do crédito recebido (${formats.currency(
        data.value,
      )}), vínculo não pode ser efetuado.`

      await confirm.show({
        ...options,
        options: [{ label: 'Fechar', value: false }],
      })

      return
    }

    const result = await confirm.show(options)

    if (result) {
      dispatch(CreditsReceivedsActions.save(data, actions))
    }
  }

  async function handleReopen(data, actions) {
    let result = await confirm.show({
      width: 500,
      title: 'Atenção',
      text: `Deseja realmente alterar o status do crédito recebido para "aberta"?`,
    })

    if (result) {
      dispatch(CreditsReceivedsActions.reopen(data, actions))
    }
  }

  async function handleProcessed(data, actions) {
    let result = await confirm.show({
      width: 600,
      title: 'Atenção',
      text: `Deseja realmente enviar o crédito recebido ao ERP?`,
    })

    if (result) {
      dispatch(CreditsReceivedsActions.processed(data.id, actions))
    }
  }

  async function handleCancel(data, actions) {
    let result = await confirm.show({
      width: 600,
      title: 'Atenção',
      text: `Deseja realmente descartar o crédito recebido?`,
    })

    if (result) {
      dispatch(CreditsReceivedsActions.cancel(data.id, actions))
    }
  }

  const renderLeftComponent = ({ Table }) => {
    return (
      <LeftContainer>
        <Dashboard cardSize={cardSize}>
          <div className="section">
            {_.map(statusGroup, (sts, index) => (
              <DashboardGroup
                key={index}
                selected={statusSelected === sts}
                getCol={getColorByBgColor}
                color={statusColors[sts]}
                onClick={() => filterStatus(sts)}
              >
                <div className="sum">
                  <span>$ </span>
                  {formats.numberScale(_.get(dashboard, `${sts}.sum`))}
                </div>
                <div className="name">
                  <div className="square"></div>
                  {statusLabels[sts]}
                </div>
                <div className="count">
                  {formats.number(_.get(dashboard, `${sts}.count`))}
                </div>
              </DashboardGroup>
            ))}
          </div>
        </Dashboard>

        <div className="crud-table">{Table}</div>
      </LeftContainer>
    )
  }

  return (
    <Container>
      <Crud
        openForm={openForm || showFilter}
        useOpenForm={true}
        data={state.data}
        columns={columns}
        onRowClicked={handleLoad}
        onChangePage={handlePageChange}
        onCloseFilter={handleHideFilter}
        tableLoading={state.loading}
        formLoading={state.formLoading}
        formTitle={(row) => (showFilter ? 'Filtro' : detailTitle(row))}
        hideAdd={true}
        rightPadding="0"
        renderLeftComponent={renderLeftComponent}
        rightWidth={`${showFilter ? '500px' : '920px'}`}
        emptyText="Nenhum crédito recebido encontrado"
        formOptions={{
          onSubmit: handleFilter,
          initialValues: showFilter ? headerState.filter.data : state.model,
        }}
        renderForm={(args) =>
          showFilter ? (
            <FormFilter {...args} onListCompanies={handleListCompanies} />
          ) : (
            <Form
              {...args}
              previewMode={!canWrite || _.get(state, 'model.status') !== 'open'}
              onOpenDocument={handleOpenDocument}
              onListBankContracts={handleListBankContracts}
              onListBills={handleListBills}
              bills={state.bills}
            />
          )
        }
        actions={
          !showFilter
            ? formActions(
                _.get(state, 'model') || {},
                canWrite,
                handleCancel,
                handleProcessed,
                handleSave,
                handleReopen,
              )
            : [
                {
                  label: 'Limpar Filtro',
                  icon: MdClear,
                  action: () => handleFilter({ status: statusSelected }),
                },
                {
                  label: 'Aplicar Filtro',
                  icon: MdCheck,
                  action: (data) => handleFilter(data),
                },
              ]
        }
        dataTableOptions={{
          customStyles,
        }}
      />
    </Container>
  )
}
