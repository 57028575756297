import { all, takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { Creators as EmailBatchActions, Types as EmailBatchTypes } from '~/store/modules/email-batch';
import api from '~/services/api';
import { getError } from '~/helper';

export function* list({ params }) {
  try {
    const response = yield call(api.post, 'email-batchs/list', params);
    yield put(EmailBatchActions.listSuccess(response.data));
  } catch (error) {
    showError(error);
  }
};

export function* load({data}) {
  try {
    const response = yield call(api.get, 'email-batchs/load', { params: { id: data.id } });
    yield put(EmailBatchActions.loadSuccess(response.data));
  } catch (error) {
    showError(error);
  }
};

export function* listUsers({term, callback}) {
  try {
    const response = yield call(api.get, 'email-batchs/list-users', { params: { term } });
    yield call(callback, response.data);
  } catch (error) {
  }
};

export function showError(error) {
  const msg = getError(error);
  toast.error(msg);
};

export default all([
  takeLatest(EmailBatchTypes.LIST, list),
  takeLatest(EmailBatchTypes.LOAD, load),
  takeLatest(EmailBatchTypes.LIST_USERS, listUsers),
]);
