import React from 'react';
import * as Yup from 'yup';
import _ from 'lodash';
import { Checkbox, InputGroup, Autocomplete } from '~/components/form';
import { FormContainer } from '~/pages/users/styles';

export const UserForm = ({ errors, status, touched, values, isValid, previewMode, onListProfiles, setFieldValue }) => {
  return (
    <FormContainer>
      <Checkbox name="active" label="Ativo" halfHeight={true} disabled={previewMode} />

      <Autocomplete
        name="profile"
        keyField="id"
        label="Perfil"
        value={_.get(values, 'profile')}
        disabled={previewMode}
        valueFormat={row => `${row.name}`}
        loadData={onListProfiles}
        emptyText={'Pesquise um perfil'}
        tipText={'Digite... '}
        loadingText={'Carregando...'}
        notFoundText={'Não encontrado'}
      />

      <InputGroup disabled={previewMode} type="text" name="name" label="Nome" maxLength={100} hasError={errors.name && touched.name} />

      <InputGroup
        disabled={previewMode}
        type="email"
        name="email"
        label="E-mail"
        maxLength={100}
        hasError={errors.email && touched.email}
        onChange={evt => {
          setFieldValue('email', evt.target.value.toLowerCase());
          setFieldValue('username', evt.target.value.toLowerCase());
        }}
      />

      <InputGroup disabled={_.get(values, 'id') || previewMode} type="hidden" name="username" label="Usuário (Login)" maxLength={100} />

      {status && status.msg && <div>{status.msg}</div>}
    </FormContainer>
  );
};

export const UserSchema = Yup.object().shape({
  name: Yup.string()
    .min(4, 'Verifique se o nome está correto')
    .required('Informe o nome'),
  email: Yup.string()
    .matches(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'Informe um E-mail válido')
    .nullable().email('Informe um E-mail válido'),
  profile: Yup.mixed().test('', 'Informe o Perfil', function(val) {
    return !_.isEmpty(val);
  })
});
