import { all, takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { Creators as EmailSettingsActions, Types as EmailsTypes } from '~/store/modules/email-setting';
import api from '~/services/api';
import { getError } from '~/helper';

export function* createOrUpdate({ data, actions }) {
  try {
    let editing = !!(data && data.id),
      method = editing ? 'put' : 'post',
      path = editing ? 'update' : 'create'

    yield call(api[method], `email-settings/${path}`, data)
    toast.success(`Configurações de e-mail ${editing ? 'atualizada' : 'criada'} com sucesso`);
    actions.closeForm && actions.closeForm();
    actions.reloadList && actions.reloadList();
  } catch (error) {
    showError(error)
  } finally {
    actions.setSubmitting(false)
  }
}

export function* list({ params }) {
  try {
    const response = yield call(api.get, 'email-settings/list', { params })
    yield put(EmailSettingsActions.listSuccess(response.data))
  } catch (error) {
    showError(error)
  }
}

export function* load({ data }) {
  try {
    if (!data.id || data.id === 'new') {
      yield put(EmailSettingsActions.loadSuccess({ active: true }));
    } else {
      const response = yield call(api.get, 'email-settings/load', { params: { id: data.id } });
      yield put(EmailSettingsActions.loadSuccess(response.data));
    }

  } catch (error) {
    showError(error)
  }
}

export function* listBankContracts({ params, callback }) {
  try {
    const response = yield call(api.get, 'email-settings/list-bank-contracts', { params });
    yield call(callback, response.data);
  } catch (error) {
  }
}

export function* listCompanies({ term, callback }) {
  try {
    const response = yield call(api.get, 'email-settings/list-companies', { params: { term } });
    yield call(callback, response.data);
  } catch (error) {
  }
};

export function* listBusinessPartnerGroup({ term, callback }) {
  try {
    const response = yield call(api.get, 'email-settings/list-business-partner-groups', { params: { term } });
    yield call(callback, response.data);
  } catch (error) {
  }
};

export function* listTemplates() {
  try {
    const response = yield call(api.get, 'email-settings/list-templates')
    yield put(EmailSettingsActions.templatesSuccess(response.data))
  } catch (error) {
    showError(error)
  }
};

export function showError(error) {
  const msg = getError(error)
  toast.error(msg)
};

export function* remove({ data, actions }) {
  try {
    let params = _.pick(data, 'id');
    yield call(api.delete, `email-settings/remove`, { params });

    toast.success(`Configuração removida com sucesso`);
    actions.closeForm && actions.closeForm();
    actions.reloadList && actions.reloadList();
  } catch (error) {
    showError(error);
  }
};

export default all([
  takeLatest(EmailsTypes.LIST, list),
  takeLatest(EmailsTypes.LOAD, load),
  takeLatest(EmailsTypes.REMOVE, remove),
  takeLatest(EmailsTypes.LIST_BANK_CONTRACTS, listBankContracts),
  takeLatest(EmailsTypes.LIST_COMPANIES, listCompanies),
  takeLatest(EmailsTypes.LIST_TEMPLATES, listTemplates),
  takeLatest(EmailsTypes.LIST_BUSINESS_PARTNER_GROUP, listBusinessPartnerGroup),
  takeLatest(EmailsTypes.CREATE_OR_UPDATE, createOrUpdate)
]);
