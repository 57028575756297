import { createActions, createReducer } from 'reduxsauce';
import produce from 'immer';
import _ from 'lodash';
import { documentsTypeMap } from '~/pages/responses/form'

export const { Types, Creators } = createActions({
  load: ['data'],
  loadSuccess: ['data'],
  list: ['params'],
  listSuccess: ['data'],
  create: ['data', 'actions'],
  downloadFile: ['data'],
  download: ['data'],
  generateXlsx: ['data'],
  formActionDone: [],
  reprocessConciliation: ['data', 'actions'],
  changeTab: ['tab'],
  listBankContracts: ['movementType', 'term', 'callback'],
  listBankAccounts: ['term', 'callback'],
  listCompanies: ['term', 'callback'],
  listBanks: ['term', 'callback'],
}, { prefix: 'responses' });

const INITIAL_STATE = {
  loading: false,
  formLoading: false,
  data: { rows: [], count: 0, offset: 0 },
  model: {}
};

const handleList = (state = INITIAL_STATE, action) => produce(state, (draft) => {
  draft.loading = true;
  draft.data = { rows: [], count: 0, offset: 0 };
  draft.model = {};
});

const handleListSuccess = (state = INITIAL_STATE, action) => produce(state, (draft) => {
  draft.loading = false;
  draft.data = action.data;
});

const handleLoad = (state = INITIAL_STATE, action) => produce(state, (draft) => {
  draft.model = {};
  draft.formLoading = true;
  draft.formLoadingMessage = 'Carregando...';
});

const handleLoadSuccess = (state = INITIAL_STATE, action) => produce(state, (draft) => {
  const type = _.get(action, 'data.response.movementType');
  draft.formLoading = false;
  draft.formLoadingMessage = '';
  draft.model = _.get(action, 'data.response');
  draft.currentTab = documentsTypeMap[type]
});

const changeTab = (state = INITIAL_STATE, action) => produce(state, (draft) => {
  draft.currentTab = _.get(action, 'tab');
});

const formActionDone = (state = INITIAL_STATE, action) => produce(state, draft => {
  draft.formLoading = false;
  draft.formLoadingMessage = '';
});

const formAction = (message) => (state = INITIAL_STATE, action) => produce(state, draft => {
  draft.formLoading = true;
  draft.formLoadingMessage = message;
});

export default createReducer(INITIAL_STATE, {
  [Types.LIST]: handleList,
  [Types.LIST_SUCCESS]: handleListSuccess,
  [Types.LOAD]: handleLoad,
  [Types.LOAD_SUCCESS]: handleLoadSuccess,
  [Types.CHANGE_TAB]: changeTab,
  [Types.FORM_ACTION_DONE]: formActionDone,
  [Types.CREATE]: formAction('Processando arquivo...'),
  [Types.GENERATE_XLSX]: formAction('Gerando planilha...'),
});
