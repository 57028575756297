import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import Modal from '~/components/modal';
import { ModalContainer, BankContractContainer } from '~pages/prepare-bills/styles';
import { Autocomplete } from '~components/form';
import DataTable from '~/components/datatable';

import { formatBankContractCharge, formatBankContract } from '~/pages/bank-contracts/form';

const PrepareBillModal = ({
  isOpen,
  dataList,
  dataColumns,
  selecteds,
  closeModal,
  handleOnSubmit,
  onListBankContracts,
  handleOnChangeSelect,
  bankContractMovementFrom
}) => {

  const isInvalid = values => {
    if (!values.bankContract || _.size(selecteds) === 0) {
      return true;
    }
    return false;
  };

  const getTitle = () => {
    if (!bankContractMovementFrom) return 'Selecione os títulos que serão pagos';
    return (
      <BankContractContainer>
        <div>
          {bankContractMovementFrom.logo && <img className="logo" src={bankContractMovementFrom.logo} alt="action" />}
          <h2>
            <label>{bankContractMovementFrom.label}</label>
            <span>{bankContractMovementFrom.detail}</span>
          </h2>
        </div>
        <h1>[Selecione o contrato de destino]</h1>
      </BankContractContainer>
    );
  };

  const handleLoadData = (term, callback) => {
    onListBankContracts(term, data => {
      const newData = _.filter(data, f => f.id !== _.get(bankContractMovementFrom, 'id'));
      callback(newData);
    });
  };

  const getSecondaryActions = ({ isSubmitting, values, setSubmitting }) => {
    if (!bankContractMovementFrom) return [];
    return [
      {
        label: 'Retornar para Faturas',
        action: () => {
          setSubmitting(true);
          const data = _.cloneDeep(values);
          delete data.bankContract;
          handleOnSubmit(data, { setSubmitting });
        },
        type: 'button',
        disabled: isSubmitting || _.size(selecteds) === 0
      }
    ];
  };

  return (
    <Formik initialValues={{}} validationSchema={schema} onSubmit={handleOnSubmit} validateOnMount={false}>
      {({ errors, isValid, touched, handleSubmit, handleReset, isSubmitting, values, setSubmitting }) => {
        return (
          <Modal
            width="950px"
            height="600px"
            title={getTitle()}
            hideClose={true}
            open={isOpen}
            bindRefWithSubmit={true}
            hide={closeModal}
            actions={[
              {
                label: 'Cancelar',
                action: () => {
                  handleReset();
                  closeModal();
                }
              },
              {
                label: 'Confirmar',
                action: handleSubmit,
                type: 'submit',
                disabled: !isValid || isSubmitting || isInvalid(values),
                primary: true
              }
            ]}
            secondaryActions={getSecondaryActions({ isSubmitting, values, setSubmitting })}
          >
            <ModalContainer>
              <Form>
                <Autocomplete
                  autoFocus={true}
                  name="bankContract"
                  keyField="id"
                  clearable={true}
                  label="Contrato"
                  valueFormat={formatBankContractCharge}
                  optionsFormat={r => formatBankContract(r, { billType: true })}
                  tipText={'Digite... '}
                  loadingText={'Carregando...'}
                  notFoundText={'Não encontrada'}
                  emptyText={'Pesquise um contrato'}
                  value={_.get(values, 'bankContract')}
                  loadData={handleLoadData}
                  hasError={_.get(errors, 'bankContract') && _.get(touched, 'bankContract')}
                />
              </Form>

              <div style={{ height: '90%' }}>
                <DataTable
                  emptyText="Nenhuma cobrança encontrada"
                  data={{
                    rows: dataList
                  }}
                  columns={dataColumns}
                  noPagination={true}
                  hideUpdateButton={true}
                  onRowClicked={handleOnChangeSelect}
                  extraOptions={{
                    ignoreRowClicked: true,
                    selectableRows: true,
                    selectableRowsHighlight: false,
                    selectableRowSelected: row => {
                      return _.find(selecteds, { id: row.id });
                    },
                    onSelectedRowsChange: ({ allSelected, selectedCount, selectedRows }) => {
                      handleOnChangeSelect(selectedRows);
                    }
                  }}
                />
              </div>
            </ModalContainer>
          </Modal>
        );
      }}
    </Formik>
  );
};

const schema = Yup.object().shape({
  bankContract: Yup.mixed().required('Selecione um contrato')
});

export default PrepareBillModal;
