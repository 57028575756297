import React from 'react'
import PropTypes from 'prop-types'
import { FieldArray, ErrorMessage } from 'formik'
import styled, { css } from 'styled-components'

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  hr {
    line-height: 1em;
    position: relative;
    outline: 0;
    border: 0;
    color: #000;
    text-align: center;
    height: 1.5em;
    opacity: 0.5;
    margin: 5px 0px;

    &:before {
      content: '';
      background: linear-gradient(to right, transparent, #000, transparent);
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      height: 1px;
    }

    &:after {
      content: attr(data-content);
      position: relative;
      display: inline-block;
      color: #000;
      padding: 0 0.5em;
      line-height: 1.5em;
      color: #000;
      background-color: #fcfcfa;
    }
  }

  label {
    display: block;
    padding-left: 15px;
    text-indent: -15px;
    color: #555;
  }
  input[type='checkbox'] {
    width: 13px;
    height: 13px;
    padding: 0;
    margin: 0 5px 0 0;
    vertical-align: bottom;
    position: relative;
    top: -1px;
    overflow: hidden;
  }
`

const Box = styled.div`
  overflow-y: auto;
  height: ${(props) => props.height};
  padding: 0px 10px 10px;
  margin-bottom: ${(props) => (props.noMargin ? '0' : '10')}px;

  ${(props) =>
    !props.noLabel &&
    css`
      border-left: 1px solid rgba(0, 0, 0, 0.05);
      border-right: 1px solid rgba(0, 0, 0, 0.05);
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    `}
`

const buildGrid = (isVertical, size) => {
  let css = `grid-template-columns: repeat(${size}, 1fr);`
  if (isVertical) {
    css = `grid-template-rows: repeat(${size}, 1fr);`
  }
  return css
}

const BoxContent = styled.div`
  display: grid;
  gap: 8px;
  ${({ isVertical, size }) => buildGrid(isVertical, size)}
`

const CheckBoxContaienr = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 33.8px;
`

const GroupCheckbox = (props) => {
  const {
    label,
    name,
    values,
    disabled,
    allowedValues,
    maxHeight = '100%',
    noLabel = false,
    direction = 'vertical',
    noMargin = false,
    customStyle
  } = props
  const allowedKeys = Object.keys(allowedValues) || []
  return (
    <Container>
      {!noLabel && <hr data-content={label} />}
      <Box height={maxHeight} noLabel={noLabel} noMargin={noMargin}>
        <FieldArray id={name} name={name}>
          {({ push, remove, form }) => (
            <BoxContent
              isVertical={direction === 'vertical'}
              size={allowedKeys.length}
              style={customStyle}
            >
              {allowedKeys.map((key) => (
                <CheckBoxContaienr key={key} >
                  <input
                    type="checkbox"
                    name={name}
                    disabled={disabled}
                    checked={values.includes(key)}
                    onChange={async (event) => {
                      if (event.target.checked) {
                        push(key)
                      } else {
                        const idx = values.indexOf(key)
                        await remove(idx)
                      }
                      form.validateForm()
                    }}
                  />
                  <label>{allowedValues[key]}</label>
                </CheckBoxContaienr>
              ))}
            </BoxContent>
          )}
        </FieldArray>
      </Box>
      <ErrorMessage name={props.name} className="error" component="div" />
    </Container>
  )
}

GroupCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  hasError: PropTypes.bool,
  values: PropTypes.array.isRequired,
  allowedValues: PropTypes.object.isRequired,
}

GroupCheckbox.defaultProps = {
  hasError: false,
  values: [],
  allowedValues: {},
}

export default GroupCheckbox
