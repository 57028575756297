import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MdSave, MdVpnKey, MdClear, MdCheck } from 'react-icons/md';
import _ from 'lodash';
import FormFilter from '~/pages/users/filter';
import { Creators as UserActions } from '~/store/modules/user';
import { Creators as HeaderActions } from '~/store/modules/header';
import CellStatus from '~/components/datatable/cell-status';
import { Container } from '~/pages/users/styles';
import { UserForm, UserSchema } from '~/pages/users/form';
import confirm from '~/components/confirm';
import { SubtitleItem, Subtitle } from '~/components/datatable/subtitle';
import { red, green } from '~/components/mixins/color';
import Crud from '~/components/crud';
import { formats } from '~/helper';

const columns = [
    {
      name: ' ',
      selector: 'status',
      width: '10px',
      cell: (row) => <CellStatus title="" color={row.active ?  green.hex() : red.hex()} />
    },
    {
      name: 'Nome',
      selector: 'name',
      cell: (row) => _.get(row, 'name')
    },
    {
      name: 'E-mail',
      selector: 'email',
      cell: (row) => _.get(row, 'email')
    },
    {
      name: 'Perfil',
      selector: 'profile',
      width: '200px',
      format: (row) => _.get(row, 'profile.name')
    },
    {
      name: 'Último acesso',
      selector: 'lastAccess',
      hide: 'md',
      width: '140px',
      format: (row) => formats.dateTimeZone(_.get(row, 'lastAccess.lastAccess.date'), 'dd/MM/yyyy HH:mm')
    },
    {
      name: 'Cadastrado em',
      selector: 'createdAt',
      hide: 'md',
      width: '140px',
      format: (row) => formats.dateTimeZone(_.get(row, 'createdAt'), 'dd/MM/yyyy HH:mm')
    },
  ]

function User({ match, acls }) {
  const dispatch = useDispatch();
  const state = useSelector(state => state.user);
  const headerState = useSelector(state => state.header);
  const canWrite = acls.includes('W');
  const [openForm, setOpenForm] = useState(!!_.get(match, 'params.id'));
  const showFilter = headerState.filter.visible;

  useEffect(() => {
    const config = {
      loading: state.loading,
      useFilter: true,
      filter: { ...headerState.filter, visible: false }
    }
    dispatch(HeaderActions.configure(config));
    // eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    dispatch(UserActions.list({ ...headerState.filter.data, offset: 0 }));
    // eslint-disable-next-line
  }, [dispatch]);

  function handleOnSubmit(data, actions) {
    dispatch(UserActions.createOrUpdate(data, actions));
  };

  async function handlePasswordReset(data, actions) {
    const result = await confirm.show({
        title: 'Atenção',
        text: `Deseja realmente redefinir a senha do usuário "${data.name}"?`
      });

    if (result) {
      dispatch(UserActions.resetPassword(data, actions));
    }
  };

  function handleLoad(data) {
    setOpenForm(true);
    dispatch(UserActions.load(data));
  };

  function handlePageChange(offset, limit) {
    dispatch(UserActions.list({ ...headerState.filter.data, offset, limit }));
  };

  function handleListProfiles(term, callback) {
    dispatch(UserActions.listProfiles(term, callback));
  };

  function handleHideFilter() {
    dispatch(HeaderActions.hideFilter());
    dispatch(UserActions.clearModel());
    setOpenForm(false);
  };

  function handleFilter(data) {
    handleHideFilter();
    dispatch(HeaderActions.callFilter({ ...data, offset: 0 }, UserActions.list));
  };

  return (
    <Container>
      {!openForm && !showFilter &&
        <Subtitle bottom={25} left={20}>
          <span>Legenda:</span>
          <SubtitleItem color={green.hex()}>Ativo</SubtitleItem>
          <SubtitleItem color={red.hex()}>Inativo</SubtitleItem>
        </Subtitle>
      }

      <Crud
        useOpenForm={true}
        openForm={openForm || showFilter}
        hideAdd={!canWrite}
        columns={columns}
        emptyText="Nenhum usuário encontrado"
        formTitle={data => showFilter ? `Filtro` : ((_.get(data, 'id')) ? `Usuário (${data.name})` : `Novo usuário`)}
        data={state.data}
        tableLoading={state.loading}
        onCloseFilter={handleHideFilter}
        formLoading={state.formLoading}
        onChangePage={handlePageChange}
        onRowClicked={handleLoad}
        formOptions={{
          initialValues: showFilter ? headerState.filter.data : (state.model || {}),
          validationSchema: showFilter ? undefined : UserSchema,
          initialTouched: showFilter ? {} : { permissions: true }
        }}
        renderForm={args => showFilter ?
          <FormFilter
            {...args}
            onListProfiles={handleListProfiles}
            />
          :
          <UserForm
            {...args}
            previewMode={!canWrite}
            onListProfiles={handleListProfiles}
            />
        }
        actions={showFilter ?
          [
            { label: 'Limpar Filtro', icon: MdClear, action: () => handleFilter({}) },
            { label: 'Aplicar Filtro', icon: MdCheck, action: data => handleFilter(data) }
          ]
          :
          [
            {
              label: 'Redefinir Senha',
              icon: MdVpnKey,
              isDisabled: ({ isValid }) => !_.get(state, 'model.id') || !canWrite,
              action: handlePasswordReset
            },
            {
              label: 'Salvar',
              icon: MdSave,
              isSubmit: true,
              isDisabled: () => !canWrite,
              action: handleOnSubmit
            }
          ]
        }
        />
    </Container>
  );
}

export default User;
