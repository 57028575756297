import _ from 'lodash';
import React, { useLayoutEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useWindowSize from '~/hooks/use-window-size';
import { Container, LeftContainer, Dashboard, DashboardGroup } from '~/pages/bank-statement-classifications/styles';
import FormFilter from '~/pages/bank-statement-classifications/filter';
import { formats, getColorByContrast } from '~/helper';
import { Creators as HeaderActions } from '~/store/modules/header';
import { Creators as BankStatementClassificationsActions } from '~/store/modules/bank-statement-classifications';
import Crud from '~/components/crud';
import CellStatus from '~/components/datatable/cell-status';
import CellBank from '~/components/datatable/cell-bank';
import { MdCheck, MdClear } from 'react-icons/md';
import useSearchParameters from '~/hooks/use-search-params';
import { formatBankAccountGrid } from '~/pages/bank-accounts/form';
import { red } from '~/components/mixins/color';

export const statusLabels = {
  open: 'Aberto',
  running: 'Em processamento',
  closed: 'Fechado',
  canceled: 'Cancelado',
  discarded: 'Descartado'
};

export const statusColors = {
  open: '#40d1ff',
  running: '#9033ff',
  closed: '#77dd76',
  canceled: '#BA1F33',
  discarded: '#ee7e02'
};

const customStyles = {
  rows: {
    denseStyle: {
      minHeight: '45px'
    }
  }
};

const getColorByBgColor = bgColor => {
  return getColorByContrast(bgColor, '#fff', '#000');
};

const getDivider = (array, breakLine) => {
  if (breakLine) {
    array.push(() => <span className="break"></span>);
  } else if (_.size(array) > 0) {
    array.push(() => <span className="spacing"></span>);
  }
};

const getElem = ({ label, value }) => {
  return () => (
    <span>
      <strong>{label}</strong>&nbsp;{value}
      <i style={{ padding: '0 5px' }}></i>
    </span>
  );
};

const getDateFilterText = (startDate, endDate) => {
  if (startDate && endDate) {
    return `${formats.date(startDate)} até ${formats.date(endDate)}`;
  } else if (startDate && !endDate) {
    return `a partir de ${formats.date(startDate)}`;
  } else if (!startDate && endDate) {
    return `até ${formats.date(endDate)}`;
  }
  return '';
};

const getSubtitle = filter => {
  let elements = [],
    company = _.get(filter, 'company'),
    bankContract = _.get(filter, 'bankContract');

  if (!_.isEmpty(bankContract)) {
    getDivider(elements);
    elements.push(
      getElem({
        label: 'Contrato',
        value: formatBankAccountGrid(bankContract.bankAccount)
      })
    );
  }

  if (!_.isEmpty(company)) {
    getDivider(elements);
    elements.push(
      getElem({
        label: 'Empresa',
        value: `${formats.cnpj_cpf(company.identity)} ${company.name}`
      })
    );
  }
  if (filter.startDateStart || filter.endDateEnd) {
    getDivider(elements);
    elements.push(
      getElem({
        label: 'Data de criação',
        value: getDateFilterText(filter.startDateStart, filter.endDateEnd)
      })
    );
  }

  if (_.isEmpty(elements)) {
    return null;
  }

  return _.map(elements, (Element, index) => <Element key={index} />);
};

const columns = [
  {
    name: ' ',
    selector: 'status',
    width: '12px',
    cell: row => {
      let status = row.registered && row.status === 'open' ? 'registered' : row.status;
      return <CellStatus title={statusLabels[status]} color={statusColors[status]} />;
    }
  },
  {
    name: 'Agência / Conta',
    selector: 'bankAccount',
    width: '140px',
    cell: row => <CellBank bankAccount={_.get(row, 'movement.bankAccount')} />
  },
  {
    name: 'Empresa',
    selector: 'company',
    hide: 'lg',
    width: '300px',
    wrap: true,
    cell: row => {
      const company = _.get(row, 'movement.bankAccount.company') || {};
      return (
        <table className="company-info">
          <tbody>
            <tr>
              <td width="120">{formats.cnpj_cpf(company.identity)}</td>
            </tr>
            <tr>
              <td>{company.name || company.tradeName} &nbsp;</td>
            </tr>
          </tbody>
        </table>
      );
    }
  },
  {
    name: 'Nome do arquivo',
    selector: 'movement.filename',
    hide: 'lg'
  },
  {
    name: 'Período',
    selector: 'startDate',
    center: true,
    width: '180px',
    format: row => (
      <span>
        {formats.dateTimeZone(_.get(row, 'startDate'), 'dd/MM/yyyy')} - {formats.dateTimeZone(_.get(row, 'endDate'), 'dd/MM/yyyy')}
      </span>
    )
  },
  {
    name: 'Última atualização',
    selector: 'updatedAt',
    center: true,
    width: '130px',
    format: row => (
      <span>
        {formats.dateTimeZone(_.get(row, 'updatedAt'), 'dd/MM/yyyy HH:mm:ss')}
      </span>
    )
  }
];

export default function BankStatementClassifications({ history, match, acls }) {
  const dispatch = useDispatch();
  const windowSize = useWindowSize();
  const headerState = useSelector(state => state.header);
  const state = useSelector(state => state.bankStatementClassifications);
  const showFilter = _.get(headerState, 'filter.visible');
  const filter = _.get(headerState, 'filter.data');
  const statusSelected = _.get(filter, 'status') || 'open';
  const statusGroup = ['open', 'running', 'closed', 'canceled', 'discarded'];
  const [openForm, setOpenForm] = useState(!!_.get(match, 'params.id'));
  const cardSize = Math.max(155, Math.floor(((windowSize.width || 1000) - 140) / 5));
  const dashboard = _.get(state, 'data.summary') || {};

  const {
    location,
    routeParams,
    getAllParams,
    toStringify 
  } = useSearchParameters()

  useLayoutEffect(() => {
    const config = {
      loading: state.loading,
      useFilter: true,
      filter: {
        ...headerState.filter,
        visible: false,
      },
      subtitle: getSubtitle(filter || {})
    }
    dispatch(HeaderActions.configure(config))
    // eslint-disable-next-line
  }, [])

  useLayoutEffect(() => {
    const { id, offset = 0, limit = 20, status = 'open', ...filter } = getAllParams()
    const listMethod =
      _.isEmpty(id) || _.size(_.get(state, 'data.rows')) === 0
        ? BankStatementClassificationsActions.list
        : null

    if (id) {
      dispatch(BankStatementClassificationsActions.load({ id }))
    }

    dispatch(
      HeaderActions.callFilter(
        { ...filter, status, offset: _.toInteger(offset), limit: _.toInteger(limit) },
        listMethod,
      ),
    )
    // eslint-disable-next-line
  }, [location, routeParams])


  function handleHideFilter() {
    dispatch(HeaderActions.hideFilter());
    setOpenForm(false);
  }

  function handleFilter(filter) {
    const historySearch = {
      ..._.omit(filter, [
        'company',
        'bankAccount',
        'createdAtStart',
        'createdAtEnd',
        'startDateStart',
        'endDateEnd'
      ]),
      offset: 0,
    }
    handleHideFilter()
    history.push({
      pathname: `/bank-statement-classifications`,
      search: `?${toStringify(historySearch)}`,
      state: filter,
    })
  }

  function filterStatus(status) {
    handleFilter({ ...filter, status });
  }

  function handlePageChange(offset, limit) {
    const filter = getAllParams()
    history.push({
      pathname: '/bank-statement-classifications',
      search: `?${toStringify({
        ..._.omit(filter, [
          'id',
          'company',
          'bankAccount',
          'createdAtStart',
          'createdAtEnd',
          'startDateStart',
          'endDateEnd'
        ]),
        offset,
        limit,
      })}`,
      state: { ..._.omit(filter, 'id'), offset, limit },
    })
    setOpenForm(false)
  }

  function handleLoad(data = {}) {
    const filter = getAllParams()
    history.push({
      pathname: `/bank-statement-classifications/${_.get(data, 'id', '')}/${_.get(data, 'movement.id')}`,
      search: `?${toStringify(
        _.omit(filter, [
          'id',
          'company',
          'bankAccount',
          'createdAtStart',
          'createdAtEnd',
          'startDateStart',
          'endDateEnd'
        ]),
      )}`,
      state: filter,
    })
    setOpenForm(true)
  }

  function handleListBankAccounts(term, callback) {
    dispatch(BankStatementClassificationsActions.listBankAccounts({ term }, callback));
  }

  function handleListCompanies(term, callback) {
    dispatch(BankStatementClassificationsActions.listCompanies({ term }, callback));
  }

  const renderLeftComponent = ({ Table }) => {
    return (
      <LeftContainer>
        <Dashboard cardSize={cardSize}>
          <div className="section">
            {_.map(statusGroup, (sts, index) => (
              <DashboardGroup
                key={index}
                selected={statusSelected === sts}
                getCol={getColorByBgColor}
                color={statusColors[sts]}
                title={formats.currency(_.get(dashboard, `${sts}.total`))}
                onClick={() => filterStatus(sts)}
              >
                <div className="sum">
                  <span>$ </span>
                  <div style={{ color: _.get(dashboard, `${sts}.total`) < 0 ? red.hex() : '#444' }}>{formats.numberScale(_.get(dashboard, `${sts}.total`))}</div>
                </div>
                <div className="name">
                  <div className="square"></div>
                  {statusLabels[sts]}
                </div>
                <div className="count">{formats.number(_.get(dashboard, `${sts}.count`))}</div>
              </DashboardGroup>
            ))}
          </div>
        </Dashboard>

        <div className="crud-table">{Table}</div>
      </LeftContainer>
    );
  };

  return (
    <Container>
      <Crud
        useOpenForm={true}
        openForm={openForm || showFilter}
        data={state.data}
        columns={columns}
        onRowClicked={handleLoad}
        onChangePage={handlePageChange}
        onCloseFilter={handleHideFilter}
        tableLoading={state.loading}
        hideAdd={true}
        emptyText="Nenhuma classificação encontrada"
        formTitle={() => 'Filtro'}
        renderLeftComponent={renderLeftComponent}
        formOptions={{
          initialValues: headerState.filter.data,
          onSubmit: handleFilter
        }}
        renderForm={args => <FormFilter {...args} onListBankAccounts={handleListBankAccounts} onListCompanies={handleListCompanies} />}
        actions={[
          {
            label: 'Limpar Filtro',
            icon: MdClear,
            action: () => handleFilter({ status: statusSelected })
          },
          {
            label: 'Aplicar Filtro',
            icon: MdCheck,
            isSubmit: true,
            action: handleFilter
          }
        ]}
        dataTableOptions={{
          customStyles
        }}
      />
    </Container>
  );
}
