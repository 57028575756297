import { createActions, createReducer } from 'reduxsauce';
import produce from 'immer';
import _ from 'lodash';

export const { Types, Creators } = createActions(
  {
    list: ['params'],
    listSuccess: ['data'],
    listBankStatementClassification: [],
    listBankStatementClassificationSuccess: ['data'],
    load: ['data'],
    loadSuccess: ['model'],
    clearModel: [],
    createOrUpdate: ['data', 'actions'],
    remove: ['data', 'actions']
  },
  { prefix: 'prepare-bank-statement' }
);

const INITIAL_STATE = {
  loading: false,
  formLoading: false,
  model: null,
  data: { rows: [], count: 0, offset: 0 },
  optionsClassification: []
};

const list = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.loading = true;
    draft.data = { rows: [], count: 0, offset: 0 };
    draft.detail = {};
    draft.selecteds = [];
  });

const listSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.loading = false;
    draft.data = action.data;
  });

const listBankStatementClassification = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.loading = true;
    draft.optionsClassification = [];
  });

const listBankStatementClassificationSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.loading = false;
    draft.optionsClassification = _.map(action.data, r => ({ value: r.id, label: `[${r.code}] - ${r.description}` }));
  });

const handleLoad = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.model = {};
    draft.formLoading = true;
  });

const handleLoadSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.formLoading = false;
    draft.model = action.model;
  });

const handleClearModel = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.model = null;
    draft.formLoading = false;
  });

export default createReducer(INITIAL_STATE, {
  [Types.LIST]: list,
  [Types.LIST_SUCCESS]: listSuccess,
  [Types.LIST_BANK_STATEMENT_CLASSIFICATION]: listBankStatementClassification,
  [Types.LIST_BANK_STATEMENT_CLASSIFICATION_SUCCESS]: listBankStatementClassificationSuccess,
  [Types.LOAD]: handleLoad,
  [Types.LOAD_SUCCESS]: handleLoadSuccess,
  [Types.CLEAR_MODEL]: handleClearModel
});
