import { all, put, call, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '~/services/api';
import { getError } from '~/helper';
import { Creators as BankStatementClassificationsActions, Types as BankStatementClassificationsTypes } from '~/store/modules/bank-statement-classifications';

export function showError(error) {
  const msg = getError(error);
  toast.error(msg);
}

export function* list({ params }) {
  try {
    const response = yield call(api.post, 'bank-statement-classifications/list', params);
    yield put(BankStatementClassificationsActions.listSuccess(response.data));
  } catch (error) {
    showError(error);
  }
}

export function* listBankAccounts({ params, callback }) {
  try {
    const response = yield call(api.get, 'bank-statement-classifications/list-bank-accounts', { params });
    yield call(callback, response.data);
  } catch (error) {}
}

export function* listCompanies({ params, callback }) {
  try {
    const response = yield call(api.get, 'bank-statement-classifications/list-companies', { params });
    yield call(callback, response.data);
  } catch (error) {}
}

export function* listBankStatementClassification() {
  try {
    const response = yield call(api.get, 'bank-statement-classifications/list-bank-statement-classifications', { params: { noLimit: true } });
    yield put(BankStatementClassificationsActions.listBankStatementClassificationSuccess(response.data));
  } catch (error) {}
}

export function* load(params) {
  try {
    const response = yield call(api.get, 'bank-statement-classifications/details', { params });
    yield put(BankStatementClassificationsActions.loadSuccess(response.data));
  } catch (error) {
    showError(error);
  }
}

export function* updateBankClassification({ params }) {
  try {
    yield call(api.post, 'bank-statement-classifications/update-bank-classification', { ...params });
    yield put(BankStatementClassificationsActions.load(params.positionId, params.movementId));
    toast.success('Classificação processada com sucesso');
  } catch (error) {
    showError(error);
    yield put(BankStatementClassificationsActions.load(params.positionId, params.movementId));
  }
}

export function* sendToFirebase(params) {
  try {
    const response = yield call(api.get, 'bank-statement-classifications/send-to-firebase', { params });
    yield put(BankStatementClassificationsActions.sendToFirebaseSuccess(response.data));
    yield put(BankStatementClassificationsActions.load(params.id, params.movementId));
    toast.success('Solicitação de processamento do extrato foi enviada com sucesso');
  } catch (error) {
    showError(error);
  }
}

export function* clearClassification(params) {
  try {
    const response = yield call(api.get, 'bank-statement-classifications/clear-classification', { params });
    yield put(BankStatementClassificationsActions.clearClassificationSuccess(response.data));
    yield put(BankStatementClassificationsActions.load(params.id, params.movementId));
    toast.success('As classificações das linhas do extrato foram desfeitas com sucesso');
  } catch (error) {
    showError(error);
  }
}

export function* discard(params) {
  try {
    const response = yield call(api.get, 'bank-statement-classifications/discard', { params });
    yield put(BankStatementClassificationsActions.discardSuccess(response.data));
    yield put(BankStatementClassificationsActions.load(params.id, params.movementId));
    toast.success('A classificação do extrato foi descartada com sucesso');
  } catch (error) {
    showError(error);
  }
}

export function* cancel(params) {
  try {
    yield call(api.delete, `bank-statement-classifications/cancel`, { params });
    yield put(BankStatementClassificationsActions.load(params.id, params.movementId));
    toast.success(`Classificação cancelada com sucesso!`);
  } catch (error) {
    showError(error);
  }
}

export function* reclassifyBankStatement(params) {
  try {
    yield call(api.patch, `bank-statement-classifications/reclassify-bank-statement`, params);
    yield put(BankStatementClassificationsActions.load(params.id, params.movementId));
    toast.success(`Reclassificação efetuada com sucesso!`);
  } catch (error) {
    showError(error);
  }
}

export function* getPaymentOrders({ params, toogleModal }) {
  try {
    const response = yield call(api.get, 'bank-statement-classifications/payment-orders', { params });
    yield put(BankStatementClassificationsActions.getPaymentOrdersSuccess(response.data));
    toogleModal && toogleModal();
  } catch (error) {
    showError(error);
  }
}

export default all([
  takeLatest(BankStatementClassificationsTypes.LIST, list),
  takeLatest(BankStatementClassificationsTypes.LIST_BANK_ACCOUNTS, listBankAccounts),
  takeLatest(BankStatementClassificationsTypes.LIST_COMPANIES, listCompanies),
  takeLatest(BankStatementClassificationsTypes.LIST_BANK_STATEMENT_CLASSIFICATION, listBankStatementClassification),
  takeLatest(BankStatementClassificationsTypes.LOAD, load),
  takeLatest(BankStatementClassificationsTypes.UPDATE_BANK_CLASSIFICATION, updateBankClassification),
  takeLatest(BankStatementClassificationsTypes.SEND_TO_FIREBASE, sendToFirebase),
  takeLatest(BankStatementClassificationsTypes.CLEAR_CLASSIFICATION, clearClassification),
  takeLatest(BankStatementClassificationsTypes.DISCARD, discard),
  takeLatest(BankStatementClassificationsTypes.CANCEL, cancel),
  takeLatest(BankStatementClassificationsTypes.RECLASSIFY_BANK_STATEMENT, reclassifyBankStatement),
  takeLatest(BankStatementClassificationsTypes.GET_PAYMENT_ORDERS, getPaymentOrders)
]);
