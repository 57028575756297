import React, { useState } from 'react';
import { Select, InputGroup, CurrencyField, InputMask, InputLabel } from '~/components/form';
import FieldSet from '~/components/fieldset';
import { BsRow, BsCol } from '~/components/layout';
import _ from 'lodash';
import { onlyNumbers, barcodeToDigitableLine, digitableLineToBarcode, barcodeDecimal } from '~/helper';
import { isValidSlipDigitableLine, isValidCpf, isValidCnpj } from '~/validator';
import { MASK_CPF, MASK_CNPJ, MASK_BANK_DIGITABLE_LINE, MASK_BARCODE } from '~/constants';
import * as Yup from 'yup';
import { FaBarcode } from 'react-icons/fa';
import { BiEditAlt } from 'react-icons/bi';
import confirm from '~/components/confirm';

export const id = 20;

export const onParentChange = ({ field, value, parent, setFieldValue }) => {
  if (field === 'paymentOrderType') {
    if ( _.get(parent, 'businessPartner')) {
      let identity = _.get(parent, 'businessPartner.identity');
      setFieldValue('orderData.assignorIdentityType', _.size(identity) === 14 ? 'company' : 'person');
      setFieldValue('orderData.assignorIdentity', identity);
      setFieldValue('orderData.assignorName', _.get(parent, 'businessPartner.name'));
    }

    if (_.get(parent, 'company')) {
      let identity = _.get(parent, 'company.identity');
      setFieldValue('orderData.payeerIdentityType', _.size(identity) === 14 ? 'company' : 'person');
      setFieldValue('orderData.payeerIdentity', identity);
      setFieldValue('orderData.payeerName', _.get(parent, 'company.name'));
    }

  } else if (field === 'businessPartner' && value) {
    let identity = _.get(value, 'identity');
    setFieldValue('orderData.assignorIdentityType', _.size(identity) === 14 ? 'company' : 'person');
    setFieldValue('orderData.assignorIdentity', identity);
    setFieldValue('orderData.assignorName', _.get(value, 'name'));

  } else if (field === 'company' && value) {
    let identity = _.get(value, 'identity');
    setFieldValue('orderData.payeerIdentityType', _.size(identity) === 14 ? 'company' : 'person');
    setFieldValue('orderData.payeerIdentity', identity);
    setFieldValue('orderData.payeerName', _.get(value, 'name'));

  } else if (field === 'bankAccount' && value) {
    let bankCode = _.get(value, 'bank.code'),
      val = _.get(parent, 'orderData.digitableLine');
    if (val) {
      setFieldValue('entryType', bankCode === String(val).substring(0, 3) ? '30' : '31');
    }
  }
};

const EntityRow = ({ field, label, values, errors, touched, setFieldValue, previewMode }) => {
  const fieldIdType = `orderData.${field}IdentityType`,
    fieldId = `orderData.${field}Identity`,
    fieldName = `orderData.${field}Name`;
  const editLocked = _.get(values, 'editLocked') || false;

  return (
    <BsRow>
      <BsCol md={10} lg={6} xl={4}>
        <Select
          name={fieldIdType}
          value={_.get(values, fieldIdType) || ''}
          label={`${label} tipo inscrição`}
          disabled={previewMode || editLocked}
          hasError={_.get(errors, fieldIdType) && _.get(touched, fieldIdType)}
          onChange={evt => {
            setFieldValue(fieldIdType, evt.target.value);
            setFieldValue(fieldId, '');
            setFieldValue(fieldName, '');
          }}
          options={{
            values: [
              { value: '', label: 'Não informar' },
              { value: 'company', label: 'CNPJ' },
              { value: 'person', label: 'CPF' }
            ]
          }}
        />
      </BsCol>

      <BsCol md={14} lg={8} xl={8}>
        {_.get(values, fieldIdType) === 'person' && (
          <InputMask
            mask={MASK_CPF}
            name={fieldId}
            label={`CPF do ${label} *`}
            disabled={previewMode || !_.get(values, fieldIdType) || editLocked}
            hasError={_.get(errors, fieldId) && _.get(touched, fieldId)}
          />
        )}
        {_.get(values, fieldIdType) === 'company' && (
          <InputMask
            mask={MASK_CNPJ}
            name={fieldId}
            label={`CNPJ do ${label} *`}
            disabled={previewMode || !_.get(values, fieldIdType) || editLocked}
            hasError={_.get(errors, fieldId) && _.get(touched, fieldId)}
          />
        )}
        {!_.get(values, fieldIdType) && <InputLabel label={`Inscrição do ${label}`} value=" " />}
      </BsCol>

      <BsCol md={24} lg={10} xl={12}>
        {_.get(values, fieldIdType) ? (
          <InputGroup type="text" name={fieldName} disabled={previewMode || !_.get(values, fieldIdType) || editLocked} maxLength={40} label={`Nome do ${label} *`} />
        ) : (
          <InputLabel label={`Nome do ${label}`} value=" " />
        )}
      </BsCol>
    </BsRow>
  );
};

export const Render = props => {
  const { errors, previewMode: readOnly, fromDda, touched, setFieldValue, values } = props,
    previewMode = readOnly || fromDda;
  const [barCodeMode, setBarCodeMode] = useState(false);
  const toogleBarCodeMode = () => setBarCodeMode(previous => !previous);
  const editLocked = _.get(values, 'editLocked') || false;

  return (
    <FieldSet label="Título - Boleto">
      <BsRow>
        <BsCol md={24} lg={24} xl={24}>
          {!barCodeMode && (
            <InputMask
              icon={FaBarcode}
              mask={MASK_BANK_DIGITABLE_LINE}
              name="orderData.digitableLine"
              label="Linha digitável *"
              disabled={previewMode || editLocked}
              hasError={_.get(errors, 'orderData.digitableLine') && _.get(touched, 'orderData.digitableLine')}
              onChange={async (event) => {
                let value = _.get(event, 'target.value');
                let headerValue = _.get(values, 'value');
                let barcodeValue = barcodeDecimal(String(value).slice(-10));
                let bankCode = _.get(values, 'bankContract.bankAccount.bank.code');

                if (_.size(value) === 47 && headerValue && Number(barcodeValue).toFixed(2) !== Number(headerValue).toFixed(2)) {
                  const result = await confirm.show({
                    title: 'Atenção',
                    width: 500,
                    text: `O valor da linha digitável não é igual ao valor do título, deseja continuar?`
                  });

                  if (!result) {
                    event.preventDefault();
                    event.stopPropagation();
                    setFieldValue('orderData.digitableLine', '0');
                    setFieldValue('orderData.barcode', '0');
                    return;
                  }
                }

                setFieldValue('orderData.digitableLine', value);
                setFieldValue('orderData.barcode', digitableLineToBarcode(value));

                if (_.size(value) === 47) {
                  setFieldValue('entryType', bankCode === String(value).substr(0, 3) ? '30' : '31');
                  if (Number(barcodeValue) > 0) {
                    setFieldValue('value', barcodeValue);
                  }
                  setFieldValue('orderData.value', barcodeValue);
                  setFieldValue('orderData.total', barcodeValue);
                }
              }}
              onIconClick={toogleBarCodeMode}
              iconTooltip="Editar código de barras"
            />
          )}
          {barCodeMode && (
            <InputMask
              icon={BiEditAlt}
              mask={MASK_BARCODE}
              name="orderData.barcode"
              label="Código de barras *"
              disabled={previewMode || editLocked}
              hasError={_.get(errors, 'orderData.barcode') && _.get(touched, 'orderData.barcode')}
              onChange={async(event) => {
                let value = _.get(event, 'target.value');
                let headerValue = _.get(values, 'value');
                let barcodeValue = barcodeDecimal(String(value).substr(9, 10));
                let bankCode = _.get(values, 'bankContract.bankAccount.bank.code');

                if (_.size(value) === 44 && headerValue && Number(barcodeValue).toFixed(2) !== Number(headerValue).toFixed(2)) {
                  const result = await confirm.show({
                    title: 'Atenção',
                    width: 500,
                    text: `O valor do código de barras não é igual ao valor do título, deseja continuar?`
                  });

                  if (!result) {
                    setFieldValue('orderData.digitableLine', '0');
                    setFieldValue('orderData.barcode', '0');
                    return;
                  }
                }

                setFieldValue('orderData.barcode', value);
                setFieldValue('orderData.digitableLine', barcodeToDigitableLine(value));

                if (_.size(value) === 44) {
                  setFieldValue('entryType', bankCode === String(value).substr(0, 3) ? '30' : '31');
                  if (Number(barcodeValue) > 0) {
                    setFieldValue('value', barcodeValue);
                  }
                  setFieldValue('orderData.value', barcodeValue);
                  setFieldValue('orderData.total', barcodeValue);
                }
              }}
              onIconClick={toogleBarCodeMode}
              iconTooltip="Editar linha digitável"
            />
          )}
        </BsCol>
      </BsRow>

      <BsRow span={5}>
        <BsCol md={12} lg={12} xl={5}>
          <CurrencyField
            name="orderData.value"
            label="Valor do título *"
            disabled={previewMode || editLocked}
            hasError={_.get(errors, 'orderData.value') && _.get(touched, 'orderData.value')}
            onChange={val => {
              let value = val || 0,
                fineValue = _.get(values, 'orderData.fineValue') || 0,
                interestValue = _.get(values, 'orderData.interestValue') || 0,
                discountValue = _.get(values, 'orderData.discountValue') || 0;
              setFieldValue('orderData.total', value + fineValue + interestValue - discountValue);
            }}
          />
        </BsCol>

        <BsCol md={12} lg={12} xl={5}>
          <CurrencyField
            name="orderData.fineValue"
            label="Valor da multa"
            disabled={readOnly || editLocked}
            hasError={_.get(errors, 'orderData.fineValue') && _.get(touched, 'orderData.fineValue')}
            onChange={val => {
              let value = _.get(values, 'orderData.value') || 0,
                fineValue = val || 0,
                interestValue = _.get(values, 'orderData.interestValue') || 0,
                discountValue = _.get(values, 'orderData.discountValue') || 0;
              setFieldValue('orderData.total', value + fineValue + interestValue - discountValue);
            }}
          />
        </BsCol>

        <BsCol md={12} lg={12} xl={5}>
          <CurrencyField
            name="orderData.interestValue"
            label="Valor do juros"
            disabled={readOnly || editLocked}
            hasError={_.get(errors, 'orderData.interestValue') && _.get(touched, 'orderData.interestValue')}
            onChange={val => {
              let value = _.get(values, 'orderData.value') || 0,
                fineValue = _.get(values, 'orderData.fineValue') || 0,
                interestValue = val || 0,
                discountValue = _.get(values, 'orderData.discountValue') || 0;
              setFieldValue('orderData.total', value + fineValue + interestValue - discountValue);
            }}
          />
        </BsCol>

        <BsCol md={12} lg={12} xl={5}>
          <CurrencyField
            name="orderData.discountValue"
            label="Valor do descontos"
            disabled={readOnly || editLocked}
            hasError={_.get(errors, 'orderData.discountValue') && _.get(touched, 'orderData.discountValue')}
            onChange={val => {
              let value = _.get(values, 'orderData.value') || 0,
                fineValue = _.get(values, 'orderData.fineValue') || 0,
                interestValue = _.get(values, 'orderData.interestValue') || 0,
                discountValue = val || 0;
              setFieldValue('orderData.total', value + fineValue + interestValue - discountValue);
            }}
          />
        </BsCol>

        <BsCol md={24} lg={24} xl={4}>
          <CurrencyField
            name="orderData.total"
            label="Valor total"
            disabled={true}
            hasError={_.get(errors, 'orderData.total') && _.get(touched, 'orderData.value')}
          />
        </BsCol>
      </BsRow>

      <EntityRow {...props} field="payeer" label="Sacado/Pagador" previewMode={true} />
      <EntityRow {...props} field="assignor" label="Cedente/Beneficiário" previewMode={previewMode} />
      <EntityRow {...props} field="guarantor" label="Sacador/Avalista" previewMode={previewMode} />
    </FieldSet>
  );
};

export const Schema = values => ({
  orderData: Yup.object()
    .typeError('Informe os dados da ordem')
    .shape({
      value: Yup.number().required('Informe o valor do título'),
      assignorIdentity: Yup.mixed().test('match', '', function(value) {
        const { path, parent, createError } = this;
        const val = value || _.get(parent, 'assignorIdentity'),
          type = _.get(parent, 'assignorIdentityType');

        if (val && type === 'person' && !isValidCpf(val)) {
          return createError({ path, message: 'Informe um CPF válido para o Cedente' });
        } else if (val && type === 'company' && !isValidCnpj(val)) {
          return createError({ path, message: 'Informe um CNPJ válido para o Cedente' });
        }
        return true;
      }),
      payeerIdentity: Yup.mixed().test('match', '', function(value) {
        const { path, parent, createError } = this;
        const val = value || _.get(parent, 'payeerIdentity'),
          type = _.get(parent, 'payeerIdentityType');
        if (val && type === 'person' && !isValidCpf(val)) {
          return createError({ path, message: 'Informe um CPF válido para o Sacado' });
        } else if (val && type === 'company' && !isValidCnpj(val)) {
          return createError({ path, message: 'Informe um CNPJ válido para o Sacado' });
        }
        return true;
      }),
      guarantorIdentity: Yup.mixed().test('match', '', function(value) {
        const { path, parent, createError } = this;
        const val = value || _.get(parent, 'guarantorIdentity'),
          type = _.get(parent, 'guarantorIdentityType');
        if (val && type === 'person' && !isValidCpf(val)) {
          return createError({ path, message: 'Informe um CPF válido para o Avalista' });
        } else if (val && type === 'company' && !isValidCnpj(val)) {
          return createError({ path, message: 'Informe um CNPJ válido para o Avalista' });
        }
        return true;
      }),
      digitableLine: Yup.mixed().test('match', '', function(value) {
        const { path, parent, createError } = this;
        const val = value || _.get(parent, 'digitableLine');
        if (!val) {
          return createError({ path, message: 'Informe a linha digitável' });
        } else if (val && (_.size(onlyNumbers(val)) !== 47 || !isValidSlipDigitableLine(val))) {
          return createError({ path, message: 'Informe uma linha digitável válida' });
        }
        return true;
      }),
      total: Yup.mixed().test('match', '', function(value) {
        const { path, parent, createError } = this;
        const val = value || _.get(parent, 'total');
        //baseVal = _.get(values, 'value') || 0;

        if (!val) {
          return createError({ path, message: 'Informe o valor do título' });
        }
        //else if (Number(val).toFixed(2) !== Number(baseVal).toFixed(2)) {
        //  return createError({ path, message: `Valor total difere do Valor do cabeçalho - ${val.toFixed(2)} - ${baseVal.toFixed(2)}` });
        //}
        return true;
      })
    })
});
