import React, { useState, useCallback, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { MdAdd, MdKeyboardBackspace } from 'react-icons/md';
import { BsPatchQuestion } from 'react-icons/bs';
import { Formik } from 'formik';
import _ from 'lodash';
import { toast } from 'react-toastify';
import Spinner from '~/components/spinner';
import DataTable from '~/components/datatable';
import FloatingButton from '~/components/floatbutton';
import { Container, FormHeader, FormToolbar } from '~/components/crud/styles';
import { IconButton } from '~/components/button';

const customStyles = {
  headCells: {
    style: {
      paddingLeft: '5px',
      paddingRight: '5px'
    }
  },
  cells: {
    style: {
      paddingLeft: '5px',
      paddingRight: '5px'
    }
  }
};

const isValid = async (form) => {
  let errors = await form.validateForm(),
    objectFields = _.filter(_.map(errors, (value, key) => _.isObject(value) && key), (r) => !!r);

  if (!_.isEmpty(errors)) {
    let base = _.omit(errors, objectFields);

    _.each(objectFields, (field) => {
      if (_.isEmpty(base) && !_.isEmpty(_.get(errors, field))) {
        base = _.get(errors, field);
      }
    })
    toast.error(_.first(_.values(base)) || 'Cadastro inválido');
    return false;
  }
  return true;
};

function Crud({ data, hideAdd, openForm = false, useOpenForm, columns, dataTableOptions, formTitle, rightWidth, emptyText, keyField, onCloseFilter, onRowClicked, onChangePage, tableLoading, formLoading, actions, renderForm, renderLeftComponent, formOptions }) {

  const [ showForm, setShowForm ] = useState(openForm);
  const closeForm = () => {
    setShowForm(false);
    onCloseFilter && onCloseFilter();
  };
  const reloadList = () => {
    onChangePage(0);
  };

  let flag = showForm;
  if (useOpenForm) {
    flag = openForm;
  }
  const useColumns = _.map(((flag) ? _.filter(columns, (r) => !r.hide) : columns), (c) => ({ compact: true, ...c }));
  const handleOnSelect = useCallback(value => {
    setShowForm(true)
    onRowClicked(value)
  }, [setShowForm, onRowClicked]);

  const Table = useMemo(() =>
    <DataTable
      columns={useColumns}
      data={data}
      emptyText={emptyText}
      loading={tableLoading}
      onChangePage={onChangePage}
      onRowClicked={handleOnSelect}
      keyField={keyField}
      extraOptions={{ customStyles, ...(dataTableOptions || {}) }}
      />
  , [data, useColumns, dataTableOptions, emptyText, handleOnSelect, keyField, onChangePage, tableLoading]);

  const LeftComponent = useMemo(() => {
    return renderLeftComponent ? renderLeftComponent({ Table }) : Table;
  }, [renderLeftComponent, Table]);

  return (
    <Container showForm={flag} rightWidth={rightWidth}>
      <div className='left'>
        {LeftComponent}

        {(!flag && !hideAdd) &&
          <FloatingButton
            icon={MdAdd}
            onClick={(e) => handleOnSelect({})}
            />
        }
      </div>

      {(flag) &&
        <div className='right'>
          <Formik
            enableReinitialize={true}
            validateOnMount={!formLoading}
            {...formOptions}
            >
            {(args) => {
              return <>
                <FormHeader>
                  {formTitle(args.values || {})}
                  <Spinner visible={formLoading} />
                </FormHeader>

                <fieldset className="form-contents" disabled={formLoading}>
                  {renderForm(args)}
                </fieldset>

                <FormToolbar>
                  <IconButton title="Voltar" disabled={formLoading} onClick={closeForm}>
                    <MdKeyboardBackspace />
                  </IconButton>
                  {_.map(actions, (row, index) => {
                    let Icon = row.icon || BsPatchQuestion;

                    return <IconButton
                      key={index}
                      type={'button'}
                      title={row.label}
                      disabled={(formLoading || args.isValidating || row.disabled || (row.isDisabled && row.isDisabled(args)))}
                      onClick={async() => {
                        let valid = (row.isSubmit) ? await isValid(args) : true;
                        if (valid) {
                          row.action(args.values, { reloadList, closeForm, ...args })
                        }
                      }}>
                      <Icon />
                    </IconButton>
                  })}
                </FormToolbar>
              </>
            }}
          </Formik>
        </div>
      }
    </Container>
  );
}

Crud.propTypes = {
  data: PropTypes.object.isRequired,
  tableLoading: PropTypes.bool,
  formLoading: PropTypes.bool,
  columns: PropTypes.array.isRequired,
  emptyText: PropTypes.string.isRequired,
  onRowClicked: PropTypes.func,
  form: PropTypes.func,
  formTitle: PropTypes.func,
  onChangePage: PropTypes.func.isRequired
}

Crud.defaultProps = {
  formTitle: () => '',
  onRowClicked: _.noop,
  formLoading: false,
  tableLoading: false,
}

export default memo(Crud)
