import React from 'react';
import { InputGroup } from '~/components/form';
import { FilterContainer } from '~/components/crud/styles';

export default function FormFilter({ values }) {
  return (
    <FilterContainer>
      <InputGroup
        type="text"
        name="name"
        label="Nome"
        maxLength={30} />
    </FilterContainer>
  );
}
