import { all, put, call, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import _ from 'lodash'
import api from '~/services/api'
import { getError } from '~/helper'
import {
  Creators as BankStatementActions,
  Types as BankStatementTypes,
} from '~/store/modules/bank-statement'

export function showError(error) {
  const msg = getError(error)
  toast.error(msg)
}

export function* list({ params }) {
  try {
    const response = yield call(api.post, 'bank-statements/list', params)
    yield put(BankStatementActions.listSuccess(response.data))
  } catch (error) {
    showError(error)
  }
}

export function* load({ data }) {
  try {
    let id = _.get(data, 'id'),
      model = {}

    if (id && id !== 'new') {
      const response = yield call(api.get, 'bank-statements/load', {
        params: { id },
      })
      model = response.data
    }

    yield put(BankStatementActions.loadSuccess(model))
  } catch (error) {
    showError(error)
  }
}

export function* loadStatement({ id, offset, limit }) {
  try {
    const response = yield call(api.get, 'bank-statements/load', {
      params: { id, offset, limit },
    })
    yield put(BankStatementActions.loadStatementSuccess(response.data))
  } catch (error) {
    showError(error)
  }
}

export function* create({ data, actions }) {
  try {
    let formData = new FormData()
    formData.append('data', JSON.stringify(_.omit(data, 'file')))
    formData.append('file', _.get(data, 'file[0]'))
    const response = yield call(api.post, `bank-statements/create`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    toast.success(`Arquivo enviado com sucesso!`)
    if (_.get(response, 'data.warning')) {
      toast.warn(_.get(response, 'data.message'))
    }
    actions.closeForm && actions.closeForm()
    actions.reloadList && actions.reloadList()
  } catch (error) {
    showError(error)
  } finally {
    yield put({ type: BankStatementTypes.CREATE_SUCCESS })
  }
}

export function* remove({ data, actions }) {
  try {
    yield call(api.delete, `bank-statements/remove`, { params: data })
    toast.success(`Entradas vinculadas ao arquivo foram removidas com sucesso!`)
    actions.closeForm && actions.closeForm()
    actions.reloadList && actions.reloadList()
  } catch (error) {
    showError(error)
  }
}

export function* listBankAccounts({ term, callback }) {
  try {
    const response = yield call(api.get, 'bank-statements/list-bank-accounts', {
      params: { term },
    })
    yield call(callback, response.data)
  } catch (error) {}
}

export function* downloadFile({ data }) {
  try {
    const params = {
      id: data.id,
      filename: data.filename,
      identity: data.bankAccount.company.identity,
    }
    const response = yield call(api.get, 'bank-statements/download-file', {
      params,
      responseType: 'blob',
    })
    api.download(response, data.filename)
  } catch (error) {
    showError(error)
  }
}

export default all([
  takeLatest(BankStatementTypes.CREATE, create),
  takeLatest(BankStatementTypes.LOAD, load),
  takeLatest(BankStatementTypes.LOAD_STATEMENT, loadStatement),
  takeLatest(BankStatementTypes.LIST, list),
  takeLatest(BankStatementTypes.REMOVE, remove),
  takeLatest(BankStatementTypes.LIST_BANK_ACCOUNTS, listBankAccounts),
  takeLatest(BankStatementTypes.DOWNLOAD_FILE, downloadFile),
])
