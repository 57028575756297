import React, { useState } from 'react';
import _ from 'lodash';
import DataTable from '~/components/datatable';
import { TabContainer } from '~/pages/responses/styles';
import { formats } from 'helper';
import TextWithHint from '~/components/text-with-hint';

const columns = [
    {
      name: 'Data Emissão',
      width: '130px',
      selector: 'docDate',
      cell: (row) => <TextWithHint text={formats.dateTimeZone(_.get(row, 'docDate'), 'dd/MM/yyyy')}/>
    },
    {
      name: 'Vencimento',
      width: '120px',
      selector: 'dueDate',
      cell: (row) => <TextWithHint text={formats.dateTimeZone(_.get(row, 'dueDate'), 'dd/MM/yyyy')}/>
    },
    {
      name: 'Data limite',
      width: '120px',
      selector: 'limitDate',
      cell: (row) => <TextWithHint text={formats.dateTimeZone(_.get(row, 'limitDate'), 'dd/MM/yyyy')}/>
    },
    {
      name: 'Valor (R$)',
      selector: 'value',
      width: '120px',
      right: true,
      cell: (row) => <TextWithHint text={formats.decimal(_.get(row, 'value'))}/>
    },
    {
      name: 'Beneficiário',
      selector: 'beneficiaryIdentity',
      hide: 'md',
      wrap: true,
      cell: row => {
        let name = _.get(row, 'beneficiaryName'),
        identity = _.get(row, 'beneficiaryIdentity');
        return <TextWithHint text={(identity && name) ?  `${formats.cnpj_cpf(identity)} - ${name}` : ''} />;
      }
    },
  ];

const getRows = (array = [], offset, limit) => {
  return array.slice(offset, offset + limit);
};

export const TabDdas = ({ ddas, openDocument }) => {
  const count = _.size(ddas);

  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(20);

  const onChangePage = (offset, limit) => {
    setOffset(offset);
    setLimit(limit);
  };

  return (
    <TabContainer>
      <DataTable
        emptyText="Nenhum pagamento processado"
        pageSize={limit}
        onChangePage={onChangePage}
        hideUpdateButton={true}
        data={{ rows: getRows(ddas, offset, limit), offset, count, limit: limit }}
        onRowClicked={openDocument('dda')}
        columns={columns}
      />
    </TabContainer>
  )
};
