import React from 'react'
import Fieldset from '~/components/fieldset'
import {
  Autocomplete,
  Select,
  InputGroup,
  InputDate,
  InputMask,
} from '~/components/form'
import { MASK_CNPJ, MASK_CPF } from '~constants'
import { FlexRow, FlexCol } from '~/components/layout'
import { Row } from '~/components/layout'
import { isBefore, isAfter } from 'date-fns'
import { FilterContainer } from '~/components/crud/styles'
import _ from 'lodash'
import { formats } from '~/helper'

export default function FormFilter({ values, setFieldValue, onListCompanies }) {
  return (
    <FilterContainer>
      <Autocomplete
        name="company"
        keyField="id"
        label="Empresa"
        clearable={true}
        value={_.get(values, 'company')}
        valueFormat={(row) =>
          `${formats.cnpj_cpf(row.identity)} - ${row.name || row.tradeName}`
        }
        loadData={onListCompanies}
        emptyText={'Pesquise uma empresa'}
        tipText={'Digite... '}
        loadingText={'Carregando...'}
        notFoundText={'Não encontrada'}
      />

      <InputGroup
        type="text"
        name="documentNumber"
        label="Número documento"
        maxLength={50}
      />

      <Fieldset label="Remetente">
        <FlexRow>
          <FlexCol flex="0 0 150px">
            <Select
              name="senderIdentityType"
              label="Tipo"
              onChange={(evt) => {
                setFieldValue('senderIdentityType', evt.target.value)
                setFieldValue('senderIdentity', '')
              }}
              options={{
                values: [
                  { value: 'company', label: 'CNPJ' },
                  { value: 'person', label: 'CPF' },
                ],
              }}
            />
          </FlexCol>

          <FlexCol flex="1">
            {_.get(values, 'senderIdentityType') !== 'person' && (
              <InputMask
                mask={MASK_CNPJ}
                name="senderIdentity"
                label={'CNPJ'}
              />
            )}

            {_.get(values, 'senderIdentityType') === 'person' && (
              <InputMask mask={MASK_CPF} name="senderIdentity" label={'CPF'} />
            )}
          </FlexCol>
        </FlexRow>

        <InputGroup
          type="text"
          name="senderName"
          label="Nome"
          maxLength={100}
        />
      </Fieldset>

      <Fieldset label="Data lançamento">
        <Row span={2}>
          <InputDate
            name="docDateStart"
            label="De"
            onChange={(value) => {
              setFieldValue('docDateStart', value)
              if (values.docDateEnd && isAfter(value, values.docDateEnd)) {
                setFieldValue('docDateStart', values.docDateEnd)
                setFieldValue('docDateEnd', value)
              }
            }}
          />

          <InputDate
            name="docDateEnd"
            label="Até"
            onChange={(value) => {
              setFieldValue('docDateEnd', value)
              if (values.docDateStart && isBefore(value, values.docDateStart)) {
                setFieldValue('docDateStart', value)
                setFieldValue('docDateEnd', values.docDateStart)
              }
            }}
          />
        </Row>
      </Fieldset>
    </FilterContainer>
  )
}
