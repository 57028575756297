import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  max-height: ${props => props.maxHeight};
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 10px;
  position: relative;

  @media (max-width: 425px) {
    max-height: 100%;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: calc(100%);
  padding: 5px;
`;

export const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  height: calc(100%);
  padding: 10px;
  padding-top: 0px;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const Line = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font: 14px 'Ubuntu',sans-serif;
  color: #666;
  width: 100%;
`;