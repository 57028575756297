import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
  border: 1px solid #e0e0e0;
  position: ${props => props.position};
  display: grid;
  grid-auto-flow: column;
  grid-gap: 15px;
  z-index: 2;
  padding: 5px;
  font-size: 10px;
  color: #676767;
  top: ${(props) => props.top ? `${props.top}px` : 'unset'};
  right: ${(props) => props.right ? `${props.right}px` : 'unset'};
  bottom: ${(props) => props.bottom ? `${props.bottom}px` : 'unset'};
  left: ${(props) => props.left ? `${props.left}px` : 'unset'};
  span {
    margin: 0 !important;
  }
  @media (max-width: 1024px) {
    grid-gap: 10px;
    padding: 2px;
    border: none;
    font-size: 9px;
    top: ${(props) => props.top ? `${props.top}px` : 'unset'};
    right: ${(props) => props.right ? `${props.right}px` : 'unset'};
    bottom: ${(props) => props.bottom ? `${props.bottom - 12}px` : 'unset'};
    left: ${(props) => props.left ? `${props.left}px` : 'unset'};
  }
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  pointer-events: none;

  .cell-contents {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: calc(100% - 15px);
  }
`;

const Square = styled.div`
  background: ${props => props.color};
  width: 10px;
  height: 10px;
  margin-right: 5px;
`;

function Subtitle({ top, right, bottom, left, position, children }) {
  position = position || 'absolute';
  return (
    <Container top={top} right={right} bottom={bottom} left={left} position={position}>
      {children}
    </Container>
  );
}

Subtitle.propTypes = {
  top: PropTypes.number,
  right: PropTypes.number,
  bottom: PropTypes.number,
  left: PropTypes.number,
  position: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

function SubtitleItem({ color, children }) {
  return (
    <ItemContainer>
      <Square color={color} />
      <div className="cell-contents">
        {children}
      </div>
    </ItemContainer>
  );
}

SubtitleItem.propTypes = {
  color: PropTypes.string,
}

export { Subtitle, SubtitleItem };
