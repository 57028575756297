import React from 'react'
import FieldSet from '~/components/fieldset';
import { BsRow, BsCol } from '~/components/layout';

export const id = 20;

export const Render = (props) => {

  return (
    <FieldSet label="Débito automático">
      <BsRow className="form-warn">
        <BsCol md={24} lg={24} xl={24}>
          <h3>Tipo de cobrança não suportado.</h3>
        </BsCol>
      </BsRow>
    </FieldSet>
  );

};

export const Schema = (values) => ({
});
