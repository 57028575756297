import styled, { css } from 'styled-components';
import { gray, quinary } from '~/components/mixins/color';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  .left {
    display: flex;
    flex: 1;
    width: calc(100% - ${props => props.rightWidth || '35%'} - 40px);
  }
  .right {
    display: flex;
    position: relative;
    flex: 0 0 ${props => props.rightWidth || '35%'};
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    background: #fff;
    margin-left: 10px;
    border-radius: 2px;
    opacity: 0;
    transition: opacity 2s ease;
    box-shadow: -1px -1px 8px 1px rgb(0 0 0 / 10%);

    .form-contents {
      border: none;
      margin: 0;
      padding: 0;
      display: flex;
      height: 100%;
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  ${props =>
    props.showForm &&
    css`
      .right {
        opacity: 1;
      }
      @media (max-width: 1024px) {
        .left {
          display: none;
          visibility: hidden;
        }
        .right {
          flex: 1;
          margin-left: 0px;
        }
      }
    `}
`;

export const FormToolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-height: 56px;
  height: 56px;
  border-top: 1px solid #e0e0e0;
  padding: 10px;
`;

export const FormHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  height: 50px;
  padding: 0 10px;
  color: ${gray.hex()};
  border-bottom: 1px solid ${quinary.hex()};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100%);
  padding: 10px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  display: ${props => (props.visible ? 'grid' : 'none')};
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  opacity: ${props => (props.visible ? '1' : '0')};
  transition: 0.1s;
  z-index: 3;
`;
