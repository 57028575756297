import React, { useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  MdCheck,
  MdClear,
  MdFileDownload,
  MdDelete,
  MdCancel,
} from 'react-icons/md'
import { SiMicrosoftexcel } from 'react-icons/si'
import { GrDocumentPdf } from 'react-icons/gr'
import { RiFileList3Line } from 'react-icons/ri'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import { Creators as ShippingActions } from '~/store/modules/shipping'
import { Creators as HeaderActions } from '~/store/modules/header'
import { Container } from '~/pages/shippings/styles'
import { ShippingForm } from '~/pages/shippings/form'
import FormFilter from '~/pages/shippings/filter'
import CellStatus from '~/components/datatable/cell-status'
import Crud from '~/components/crud'
import { SubtitleItem, Subtitle } from '~/components/datatable/subtitle'
import { blue, green, purple } from '~/components/mixins/color'
import confirm from '~/components/confirm'
import { formats, showError } from '~/helper'
import CellBank from '~/components/datatable/cell-bank'
import { BASE_URL } from '~/constants'
import PDF from '~/components/pdf'
import api from '~/services/api'
import useSearchParameters from '~/hooks/use-search-params'

const statsColors = {
  open: green.hex(),
  closed: blue.hex(),
  canceled: '#BA1F33',
  processing: purple.hex(),
}

const columns = [
  {
    name: ' ',
    selector: 'status',
    width: '10px',
    cell: (row) => (
      <CellStatus title="" color={statsColors[row.status || 'open']} />
    ),
  },
  {
    name: 'Tipo',
    selector: 'movementType',
    width: '180px',
    format: (row) => {
      let type = /^(payment)$/.test(row.movementType)
          ? 'Pagamento'
          : 'Cobrança',
        layout = _.toUpper(_.get(row, 'bankContract.layoutType')) || ''
      return `${type} - ${layout}`
    },
  },
  {
    name: 'Sequencial',
    selector: 'sequence',
    center: true,
    hide: 'md',
    width: '150px',
  },
  {
    name: 'Agência / Conta',
    selector: 'bankAccount',
    width: '160px',
    left: true,
    cell: (row) => <CellBank bankAccount={_.get(row, 'bankAccount')} />,
  },
  {
    name: 'Empresa',
    selector: 'company',
    hide: 'md',
    format: (row) => {
      let company = _.get(row, 'bankAccount.company') || {}
      return `${formats.cnpj_cpf(company.identity)} - ${
        company.name || company.tradeName
      }`
    },
  },
  {
    name: 'Registros',
    selector: 'records',
    width: '160px',
    center: true,
    cell: (row) => formats.number(row.records),
  },
  {
    name: 'Cadastrada em',
    selector: 'createdAt',
    hide: 'md',
    width: '140px',
    format: (row) =>
      formats.dateTimeZone(_.get(row, 'createdAt'), 'dd/MM/yyyy HH:mm'),
  },
]

const customStyles = {
  rows: {
    denseStyle: {
      minHeight: '42px',
    },
  },
}

function Shipping({ acls, match }) {
  const dispatch = useDispatch()
  const state = useSelector((state) => state.shipping)
  const headerState = useSelector((state) => state.header)
  const canWrite = acls.includes('W')
  const hasAccountsReceivable = acls.includes('CR')
  const hasAccountsPayable = acls.includes('CP')
  const history = useHistory()
  const [openForm, setOpenForm] = useState(!!_.get(match, 'params.id'))
  const [loadingPdf, setLoadingPdf] = useState(false)
  const showFilter = headerState.filter.visible
  const canGeneratePdf =
    /^(closed)$/.test(_.get(state, 'model.status')) &&
    /^(payment|charge)$/.test(_.get(state, 'model.movementType'))
  const canGenerateBillBatchPdf =
    /^(closed)$/.test(_.get(state, 'model.status')) &&
    _.get(state, 'model.bankContract.fields.ourNumberMode') === 'auto' &&
    _.get(state, 'model.bankContract.fields.billPdfBatchDownload') === 'true'

  const {
    location,
    routeParams,
    getAllParams,
    toStringify,
  } = useSearchParameters()

  useLayoutEffect(() => {
    const config = {
      loading: state.loading,
      useFilter: true,
      filter: {
        ...headerState.filter,
        visible: false,
      },
    }
    dispatch(HeaderActions.configure(config))
    // eslint-disable-next-line
  }, [])

  useLayoutEffect(() => {
    const { id, offset, limit = 20, ...filter } = getAllParams()

    const listMethod =
      _.isEmpty(id) || _.size(_.get(state, 'data.rows')) === 0
        ? ShippingActions.list
        : null

    if (id) {
      dispatch(ShippingActions.load({ id }))
    }

    dispatch(
      HeaderActions.callFilter(
        { ...filter, offset: _.toInteger(offset), limit: _.toInteger(limit) },
        listMethod,
      ),
    )
    // eslint-disable-next-line
  }, [location, routeParams])

  async function handleDownloadFile(data) {
    let isPayment = _.get(data, 'movementType') === 'payment',
      paymentWarning = '',
      actions = {
        closeForm: handleHideFilter,
        reloadList: () => handlePageChange(0),
      }

    if (
      isPayment &&
      _.find(
        data.paymentOrders,
        (r) => _.get(r, 'paymentOrder.status') === 'sent',
      )
    ) {
      paymentWarning =
        'Esta remessa contém ordens de pagamento não processadas, deseja inclui-las em nova remessa?'
    }

    if (_.get(data, 'status') === 'closed' || (isPayment && !paymentWarning)) {
      dispatch(ShippingActions.downloadFile(data, actions))
      return
    }

    const bankContractData = _.get(state, 'model.bankContract')
    const onlyDownloadFile =
      _.get(bankContractData, 'autoCloseShipping', false) &&
      _.get(bankContractData, 'fileDestination') === 'van'
    const message = onlyDownloadFile
      ? 'Está ação irá apenas baixar o arquivo, fechamento de fatura por VAN'
      : 'A remessa será fechada, deseja continuar ?'

    let result = await confirm.show({
      title: 'Atenção',
      width: 500,
      text: paymentWarning || message,
    })

    if (result) {
      dispatch(ShippingActions.downloadFile(data, actions))
    }
  }

  function handlePageChange(offset, limit = 20) {
    const filter = getAllParams()
    history.push({
      pathname: '/shippings',
      search: `?${toStringify({
        ..._.omit(filter, [
          'id',
          'company',
          'bankAccount',
          'createdAtStart',
          'createdAtEnd',
        ]),
        offset,
        limit,
      })}`,
      state: { ..._.omit(filter, 'id'), offset, limit },
    })
    setOpenForm(false)
  }

  function handleLoad(data) {
    const filter = getAllParams()
    history.push({
      pathname: `/shippings/${_.get(data, 'id', '')}`,
      search: `?${toStringify(
        _.omit(filter, [
          'id',
          'company',
          'bankAccount',
          'createdAtStart',
          'createdAtEnd',
        ]),
      )}`,
      state: filter,
    })
    setOpenForm(true)
  }

  function handleHideFilter() {
    const fromPage = _.get(location, 'state.from', false)
    dispatch(HeaderActions.hideFilter())
    setOpenForm(false)

    if(fromPage && (fromPage !== 'shippings')) {
      history.goBack()
    }
  }

  function handleFilter(filter) {
    const historySearch = {
      ..._.omit(filter, [
        'company',
        'bankAccount',
        'createdAtStart',
        'createdAtEnd',
      ]),
      offset: 0,
    }
    handleHideFilter()
    history.push({
      pathname: `/shippings`,
      search: `?${toStringify(historySearch)}`,
      state: filter,
    })
  }

  function handleGenerateXlsx(data) {
    dispatch(ShippingActions.generateXlsx(data.id))
  }

  const handleGeneratePdf = async (data) => {
    if (data.id) {
      await PDF.show({
        title: `Remessa - ${_.get(data, 'bankAccount.bank.code')} - ${_.get(
          data,
          'bankAccount.bankBranch',
        )} - ${_.get(data, 'bankAccount.accountNumber')}`,
        url: `${BASE_URL}/shippings/pdf/${data.id}/remessa.pdf`,
        width: '90%',
        height: '95%',
      })
    }
  }

  function handleListBankAccounts(term, callback) {
    dispatch(ShippingActions.listBankAccounts(term, callback))
  }

  function handleListCompanies(term, callback) {
    dispatch(ShippingActions.listCompanies(term, callback))
  }

  async function handleRemoveBill(
    { id, isMostRecent },
    billId,
    billOccId,
    occurrence,
  ) {
    let confirmOpts = {
      width: 600,
      title: 'Atenção',
      text: `A Fatura será removida dessa remessa, deseja continuar ?`,
      options: [{ label: 'Não', value: 'skip' }],
    }

    if (occurrence !== '02') {
      confirmOpts.options.push({ label: 'Sim', value: 'remove', primary: true })
    }
    let result = await confirm.show(confirmOpts)

    if (/^(remove)$/.test(result)) {
      let actions = {
        closeForm: handleHideFilter,
        reloadList: () => handlePageChange(0),
      }
      dispatch(ShippingActions.removeBill(billOccId, id, billId, actions))
    }
  }

  async function handleRemovePayment(
    { id },
    paymentId,
    paymentOccId,
    occurrence,
  ) {
    if (occurrence === '99') {
      await confirm.show({
        width: 500,
        title: 'Atenção',
        text: `Instruções de exclusão não podem ser removidas da remessa!`,
        options: [{ label: 'Entendi', value: 'ok', primary: true }],
      })
      return
    }
    if (occurrence === '17') {
      await confirm.show({
        width: 500,
        title: 'Atenção',
        text: `Instruções de alteração de valor a pagar não podem ser removidas da remessa!`,
        options: [{ label: 'Entendi', value: 'ok', primary: true }],
      })
      return
    }
    let result = await confirm.show({
      title: 'Atenção',
      width: 600,
      text: `O Pagamento será removido da remessa e só será incluido novamente através do detalhe dele, deseja continuar ?`,
    })

    if (result) {
      let actions = {
        closeForm: handleHideFilter,
        reloadList: () => handlePageChange(0),
      }
      dispatch(
        ShippingActions.removePayment(paymentOccId, id, paymentId, actions),
      )
    }
  }

  async function handleRemovePayments({ id }, actions) {
    let result = await confirm.show({
      title: 'Atenção',
      width: 600,
      text: `A remessa será removida e todos os pagamentos voltarão para o status "Aberto", deseja continuar ?`,
    })

    if (result) {
      dispatch(ShippingActions.removePayments(id, actions))
    }
  }

  async function handleRemoveBills({ id }, actions) {
    let result = await confirm.show({
      title: 'Atenção',
      text: `A remessa será removida e todos as faturas voltarão para o status "Aberta", deseja continuar ?`,
    })

    if (result) {
      dispatch(ShippingActions.removeBills(id, actions))
    }
  }

  async function handleCancelChargeMovement({ id }, actions) {
    let { option, prompt } = await confirm.show({
      title: 'Atenção',
      text: `A remessa será cancelada e todos as faturas voltarão para o status "Aberta", deseja continuar ?`,
      width: 900,
      height: 150,
      isPrompt: true,
      promptLabel: 'Motivo do cancelamento',
      promptLength: 5,
      asObject: true,
    })

    if (option) {
      dispatch(ShippingActions.cancelChargeMovement(id, prompt, actions))
    }
  }

  async function handleCancelPaymentMovement({ id }, actions) {
    let { option, prompt } = await confirm.show({
      title: 'Atenção',
      text: `A remessa será cancelada e todos as ordens de pagamento voltarão para o status "Aberta", deseja continuar ?`,
      width: 900,
      height: 150,
      isPrompt: true,
      promptLabel: 'Motivo do cancelamento',
      promptLength: 5,
      asObject: true,
    })

    if (option) {
      dispatch(ShippingActions.cancelPaymentMovement(id, prompt, actions))
    }
  }

  const canRemovePaymentMovement = (data) => {
    if (_.size(data) === 0) {
      return false
    }
    const found = _.find(
      data,
      (r) =>
        _.get(r, 'occurrence.code') !== '00' ||
        _.get(r, 'paymentOrder.status') !== 'inShipping',
    )
    return !found
  }

  const canRemoveBillMovement = (data) => {
    if (_.size(data) === 0) {
      return false
    }
    const found = _.find(
      data,
      (r) =>
        _.get(r, 'occurrence.code') === '02' ||
        _.get(r, 'bill.status') !== 'inShipping',
    )
    return !found
  }

  const canCancelChargeMovement = (data) => {
    if (_.size(data) === 0) {
      return false
    }

    const occurrencesDontAllowCancel =
      _.filter(data, (f) => f.occurrence.code !== '01').length > 0
    const billStatusDontAllowCancel =
      _.filter(data, (f) => !['sent', 'inShipping'].includes(f.bill.status))
        .length > 0

    return !occurrencesDontAllowCancel && !billStatusDontAllowCancel
  }

  const canCancelPaymentMovement = (data) => {
    if (_.size(data) === 0) {
      return false
    }
    const occurrencesDontAllowCancel =
      _.filter(data, (f) => !/(00|17|09|99)/.test(f.occurrence.code)).length > 0
    const paymentStatusDontAllowCancel =
      _.filter(
        data,
        (f) => !['sent', 'inShipping'].includes(f.paymentOrder.status),
      ).length > 0
    return !occurrencesDontAllowCancel && !paymentStatusDontAllowCancel
  }

  function handleOpenDocument(isPayment, id) {
    const filter = getAllParams()
    history.push({ pathname: `/${!isPayment ? 'bills' : 'payment-orders'}/${id}`,
      search: `?${toStringify(
        _.omit(filter, [
          'id',
          'company',
          'bankAccount',
          'createdAtStart',
          'createdAtEnd',
        ]),
      )}`,
      state: filter,
      sourcepath: 'shippings'})
  }

  const handleGenerateBillsPdf = async (ids, accountId) => {
    try {
      if (!_.isEmpty(ids)) {
        setLoadingPdf(true)

        const filename = 'remessa.pdf',
          url = `${BASE_URL}/shippings/pdf/${accountId}/boletos.pdf`,
          response = await api.post(url, { ids }, { responseType: 'blob' })

        //for IE
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(response.data, filename)
        } else {
          const fileUrl = window.URL.createObjectURL(response.data)
          window.open(fileUrl, '_blank')
        }
      }
    } catch (error) {
      showError(error)
    } finally {
      setLoadingPdf(false)
    }
  }

  const getActionsForm = () => {
    const bills = _.get(state, 'model.bills') || []
    const paymentOrders = _.get(state, 'model.paymentOrders') || [],
      movementStatus = _.get(state, 'model.status')

    const actions = []

    if (movementStatus !== 'canceled') {
      actions.push({
        label: 'Baixar arquivo',
        icon: MdFileDownload,
        isDisabled: () => (_.isEmpty(bills) && _.isEmpty(paymentOrders)),
        action: handleDownloadFile,
      })
    }

    if (movementStatus === 'closed') {
      if (canCancelChargeMovement(bills)) {
        actions.unshift({
          label: 'Cancelar remessa',
          icon: MdCancel,
          action: handleCancelChargeMovement,
        })
      }
      if (canCancelPaymentMovement(paymentOrders)) {
        actions.unshift({
          label: 'Cancelar remessa',
          icon: MdCancel,
          action: handleCancelPaymentMovement,
        })
      }
    }

    if (canGeneratePdf) {
      actions.unshift({
        label: 'Gerar XLSX',
        icon: SiMicrosoftexcel,
        action: () => handleGenerateXlsx(_.get(state, 'model') || {}),
      })

      actions.unshift({
        label: 'Gerar PDF',
        icon: GrDocumentPdf,
        action: () => handleGeneratePdf(_.get(state, 'model') || {}),
      })
    }

    if (canRemovePaymentMovement(paymentOrders)) {
      actions.unshift({
        label: 'Excluir remessa',
        icon: MdDelete,
        action: handleRemovePayments,
      })
    }

    if (canRemoveBillMovement(bills)) {
      actions.unshift({
        label: 'Excluir remessa',
        icon: MdDelete,
        action: handleRemoveBills,
      })
    }

    if (canGenerateBillBatchPdf) {
      let accountId = _.get(state, 'model.accountId')

      actions.push({
        label: 'Gerar PDF das faturas com boleto',
        icon: RiFileList3Line,
        loading: loadingPdf,
        action: () => handleGenerateBillsPdf(_.map(bills, 'billId'), accountId),
      })
    }

    return actions
  }

  return (
    <Container>
      {!openForm && !showFilter && (
        <Subtitle bottom={25} left={20}>
          <span>Legenda:</span>
          <SubtitleItem color={statsColors['open']}>Aberta</SubtitleItem>
          <SubtitleItem color={statsColors['closed']}>Fechada</SubtitleItem>
          <SubtitleItem color={statsColors['canceled']}>Cancelada</SubtitleItem>
          <SubtitleItem color={statsColors['processing']}>
            Processando
          </SubtitleItem>
        </Subtitle>
      )}

      <Crud
        useOpenForm={true}
        openForm={openForm || showFilter}
        hideAdd={true}
        columns={columns}
        emptyText="Nenhuma remessa encontrada"
        formTitle={(data) =>
          showFilter ? `Filtro` : `Remessa (${data.sequence || ''})`
        }
        data={state.data}
        onCloseFilter={handleHideFilter}
        tableLoading={state.loading}
        formLoading={state.formLoading || loadingPdf}
        rightWidth={`${showFilter ? 500 : 1000}px`}
        onChangePage={handlePageChange}
        onRowClicked={handleLoad}
        formOptions={{
          initialValues: showFilter ? headerState.filter.data : state.model,
        }}
        renderForm={(args) =>
          showFilter ? (
            <FormFilter
              {...args}
              hasAccountsReceivable={hasAccountsReceivable}
              hasAccountsPayable={hasAccountsPayable}
              onListBankAccounts={handleListBankAccounts}
              onListCompanies={handleListCompanies}
            />
          ) : (
            <ShippingForm
              {...args}
              openDocument={handleOpenDocument}
              removeBill={handleRemoveBill}
              removePayment={handleRemovePayment}
              permissions={state.permissions}
              canWrite={canWrite}
              hasAccountsReceivable={hasAccountsReceivable}
              hasAccountsPayable={hasAccountsPayable}
              previewMode={!canWrite || state.formLoading}
              loading={state.formLoading}
              loadingMessage={state.formLoadingMessage}
            />
          )
        }
        actions={
          showFilter
            ? [
                {
                  label: 'Limpar Filtro',
                  icon: MdClear,
                  action: () => handleFilter({}),
                },
                {
                  label: 'Aplicar Filtro',
                  icon: MdCheck,
                  action: (data) => handleFilter(data),
                },
              ]
            : getActionsForm()
        }
        dataTableOptions={{ customStyles }}
      />
    </Container>
  )
}

export default Shipping
