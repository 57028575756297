import React from 'react';
import styled, { css } from 'styled-components';
import { secondary } from '~/components/mixins/color';

const Container = styled.fieldset`
  border: 1px solid ${secondary.hex()};
  padding: 10px;
  width: 100%;
  height: ${props => props.height || 'auto'};
  min-width: 0;
  margin-bottom: ${props => (props.noMargin ? '0' : '10px')};
  legend {
    color: #666;
    font-size: 12px;
    padding: 0 10px;
  }
  ${props =>
    props.clickable &&
    css`
      cursor: pointer;
      & * {
        cursor: pointer;
      }
      &:hover {
        opacity: 0.8;
      }
    `}
`;

const Fieldset = ({ className, label, clickable, onClick, noMargin, height, children }) => {
  return (
    <Container className={className || ''} clickable={clickable} noMargin={noMargin} onClick={onClick} height={height}>
      <legend>{label}</legend>
      {children}
    </Container>
  );
};

export default Fieldset;
