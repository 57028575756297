import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import styled from 'styled-components';
import Cropper from 'react-easy-crop';
import _ from 'lodash';

const FileContainer = styled.div`
  display: flex;
  height: ${(props) => props.height || 200}px;
  width: 100%;
  position: relative;
  user-select: none;

  .cropper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .file-chooser {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    color: #666;
    cursor: pointer;
    height: ${(props) => props.height || 200}px;
    width: 100%;
    border: 3px dashed rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    min-height: ${(props) => props.height || 200}px;
    input {
      display: none;
    }
  }
`;

const fileFromBase64 = async (imageB64) => {
  const resp = await fetch(imageB64);
  const blob = await resp.blob();
  return new File([blob], `image.jpg`);
};

const createImage = (url) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', error => reject(error));

    image.src = url;
  });
};

const getCroppedBase64 = async(imageSrc, pixelCrop) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;
  const ctx = canvas.getContext('2d');

  ctx.fillStyle = '#ffffff';
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  ctx.drawImage(image, pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height, 0, 0, pixelCrop.width, pixelCrop.height);

  return canvas.toDataURL('image/jpeg');
};

const readFile = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
};

export const FileImageCrop = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const onCropComplete = useCallback(async(image, areaPixels) => {
    try {
      const base64img = await getCroppedBase64(image, areaPixels);

      if (props.valueAsBase64) {
        setFieldValue(field.name, base64img);
      } else {
        setFieldValue(field.name, await fileFromBase64(base64img));
      }
    } catch(err) {
    }
    // eslint-disable-next-line
  }, []);

  const onFileChange = async (e) => {
    const file = _.get(e, 'target.files[0]');
    if (file) {
      setImageSrc(await readFile(file));
    }
  };

  return (
    <FileContainer height={props.height}>
      {imageSrc &&
        <div className="cropper">
          <Cropper
            image={imageSrc}
            crop={crop}
            zoom={zoom}
            minZoom={0.3}
            zoomWithScroll={true}
            cropShape="rect"
            showGrid={false}
            aspect={props.aspect || 4/3}
            onCropChange={setCrop}
            restrictPosition={false}
            onCropComplete={(area, areaPixels) => onCropComplete(imageSrc, areaPixels)}
            onZoomChange={setZoom}
            />
        </div>
      }
      {!imageSrc &&
        <label className="file-chooser">
          <input type="file" onChange={onFileChange} accept="image/*" />
          {props.label || 'Selecione uma imagem'}
        </label>
      }
    </FileContainer>
  );
};

FileImageCrop.propTypes = {
  height: PropTypes.number.isRequired
};

FileImageCrop.defaultProps = {
  height: 200
};

export default FileImageCrop;
