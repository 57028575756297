import _ from 'lodash';
import React from 'react';
import { InputLabel } from '~/components/form';
import { Row } from '~/components/layout';
import Modal from '~/components/modal';
import { formats } from '~/helper';
import { ModalContainer } from './styles';

const ShowPaymentOrderModal = ({ isOpen, toggleModal, paymentOrderData }) => {
  const company = _.get(paymentOrderData, 'company') || {},
    bp = _.get(paymentOrderData, 'businessPartner') || {};

  return (
    <Modal
      width="50%"
      height="235px"
      title="Ordem de pagamento"
      hideClose={true}
      open={isOpen}
      bindRefWithSubmit={true}
      noBodyPadding={true}
      hide={toggleModal}
      actions={[
        {
          label: 'Fechar',
          action: () => {
            toggleModal();
          }
        }
      ]}
    >
      <ModalContainer>
        <Row span={2}>
          <InputLabel label="Empresa" value={`${formats.cnpj_cpf(company.identity)} ${company.name || company.tradeName}`} />
          <InputLabel label="Beneficiário" value={`${formats.cnpj_cpf(bp.identity)} ${bp.name || bp.tradeName}`} />
        </Row>
        <Row span={3}>
          <InputLabel label="Nº documento" value={paymentOrderData.documentNumber} />
          <InputLabel label="Valor" value={formats.currency(paymentOrderData.value || 0)} />
          <InputLabel label="Data de vencimento" value={formats.dateTimeZone(paymentOrderData.dueDate)} />
        </Row>
      </ModalContainer>
    </Modal>
  );
};

export default ShowPaymentOrderModal;
