import { createActions, createReducer } from 'reduxsauce'
import produce from 'immer'

export const { Types, Creators } = createActions(
  {
    configure: ['options'],
    success: ['options'],
    showFilter: [],
    hideFilter: [],
    filterData: [],
    callFilter: ['data', 'actionFilter'],
    applyFilter: ['data'],
  },
  { prefix: 'header' },
)

const INITIAL_STATE = {
  filter: {
    scope: 'root',
    use: false,
    visible: false,
    data: {},
  },
}

const handleConfigure = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    const {
      title,
      subtitle,
      count,
      loading,
      useFilter,
      filter = INITIAL_STATE.filter,
      scope = window.location.pathname,
    } = action.options

    draft.title = title
    draft.subtitle = subtitle
    draft.count = count
    draft.loading = loading
    draft.scope = scope
    draft.filter = { ...filter, use: !!useFilter }
  })

const handleShowFilter = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.filter.visible = true
  })

const handleHideFilter = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.filter.visible = false
  })

const handleApplyFilter = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    draft.filter.data = action.data || {}
  })

export default createReducer(INITIAL_STATE, {
  [Types.SUCCESS]: handleConfigure,
  [Types.SHOW_FILTER]: handleShowFilter,
  [Types.HIDE_FILTER]: handleHideFilter,
  [Types.APPLY_FILTER]: handleApplyFilter,
})
