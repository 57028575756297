import _ from 'lodash';
import React, { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from '~/pages/prepare-bank-statements/styles';
import { Creators as HeaderActions } from '~/store/modules/header';
import { Creators as PrepareBankStatementActions } from '~/store/modules/prepare-bank-statement';
import Crud from '~/components/crud';
import { MdCheck, MdClear, MdSave, MdDelete } from 'react-icons/md';
import FormFilter from './filter';
import { PrepareBankStatementForm, PrepareBankStatementSchema } from './form';
import confirm from '~/components/confirm';
import useSearchParameters from '~/hooks/use-search-params';

const columns = [
  {
    name: 'Descrição',
    selector: 'description'
  },
  {
    name: 'Classificação',
    selector: 'bankStatementClassification',
    cell: row => `${_.get(row, 'bankStatementClassification.code', '')} - ${_.get(row, 'bankStatementClassification.description', '')}`
  },
  {
    name: 'Débito',
    selector: 'debit',
    width: '80px',
    center: true,
    cell: row => (row.debit ? 'Sim' : 'Não')
  },
  {
    name: 'Crédito',
    selector: 'credit',
    center: true,
    width: '80px',
    cell: row => (row.credit ? 'Sim' : 'Não')
  }
];
export default function PrepareBankStatement({ history, match, acls }) {
  const dispatch = useDispatch();
  const state = useSelector(state => state.prepareBankStatement);
  const headerState = useSelector(state => state.header);
  const [openForm, setOpenForm] = useState(!!_.get(match, 'params.id'));
  const showFilter = headerState.filter.visible;
  const canWrite = acls.includes('W');

  const {
    location,
    routeParams,
    getAllParams,
    toStringify,
  } = useSearchParameters()

  useLayoutEffect(() => {
    const config = {
      loading: state.loading,
      useFilter: true,
      filter: {
        ...headerState.filter,
        visible: false,
      },
    }
    dispatch(HeaderActions.configure(config))
    dispatch(PrepareBankStatementActions.listBankStatementClassification());
    // eslint-disable-next-line
  }, [])

  useLayoutEffect(() => {
    const { id, offset, limit = 20, ...filter } = getAllParams()

    const listMethod =
      _.isEmpty(id) || _.size(_.get(state, 'data.rows')) === 0
        ? PrepareBankStatementActions.list
        : null

    if (id) {
      dispatch(PrepareBankStatementActions.load({ id }))
    }

    dispatch(
      HeaderActions.callFilter(
        { ...filter, offset: _.toInteger(offset), limit: _.toInteger(limit) },
        listMethod,
      ),
    )
    // eslint-disable-next-line
  }, [location, routeParams])

  function handleLoad(data) {
    let filter = getAllParams()
    let pathname = `/prepare-bank-statements`

    if(_.get(data, 'id', false)) {
      filter.id = _.get(data, 'id', '')
    }
    else {
      delete filter.id
      pathname = `${pathname}/new`
    }
    
    history.push({
      pathname,
      search: `?${toStringify(
        _.omit(filter, [
          'company',
          'bankAccount',
          'createdAtStart',
          'createdAtEnd',
        ]),
      )}`,
      state: filter,
    })
    setOpenForm(true)
  }

  function handlePageChange(offset, limit) {
    const filter = getAllParams()
    history.push({
      pathname: '/prepare-bank-statements',
      search: `?${toStringify({
        ..._.omit(filter, [
          'id',
          'company',
          'bankAccount',
          'createdAtStart',
          'createdAtEnd',
        ]),
        offset,
        limit,
      })}`,
      state: { ..._.omit(filter, 'id'), offset, limit },
    })
    setOpenForm(false)
  }

  function handleHideFilter() {
    dispatch(HeaderActions.hideFilter());
    dispatch(PrepareBankStatementActions.clearModel());
    setOpenForm(false);
  }

  function handleFilter(filter) {
    const historySearch = {
      ..._.omit(filter, [
        'company',
        'bankAccount',
        'createdAtStart',
        'createdAtEnd',
      ]),
      offset: 0,
    }
    handleHideFilter()
    history.push({
      pathname: `/prepare-bank-statements`,
      search: `?${toStringify(historySearch)}`,
      state: filter,
    })
  }

  function handleOnSubmit(data, actions) {
    dispatch(PrepareBankStatementActions.createOrUpdate(data, actions));
  }

  async function handleRemove(values, actions) {
    const result = await confirm.show({
      title: 'Atenção',
      text: `Deseja realmente remover esta pré-classificação? Essa ação não poderá ser revertida!`
    });

    if (result) {
      dispatch(PrepareBankStatementActions.remove(values, actions));
    }
  }

  return (
    <Container>
      <Crud
        useOpenForm={true}
        openForm={openForm || showFilter}
        hideAdd={!canWrite}
        columns={columns}
        emptyText="Nenhum pré-classificação encontrada"
        formTitle={data => (showFilter ? `Filtro` : _.get(data, 'id') ? `Pré-classificação (${data.description})` : `Nova pré-classificação`)}
        data={state.data}
        tableLoading={state.loading}
        onCloseFilter={handleHideFilter}
        formLoading={state.formLoading}
        onChangePage={handlePageChange}
        onRowClicked={handleLoad}
        formOptions={{
          initialValues: showFilter ? headerState.filter.data : state.model || {},
          validationSchema: showFilter ? undefined : PrepareBankStatementSchema,
          initialTouched: {}
        }}
        renderForm={args =>
          showFilter ? (
            <FormFilter {...args} />
          ) : (
            <PrepareBankStatementForm {...args} previewMode={!canWrite} optionsClassification={_.get(state, 'optionsClassification') || []} />
          )
        }
        actions={
          showFilter
            ? [
                { label: 'Limpar Filtro', icon: MdClear, action: () => handleFilter({}) },
                { label: 'Aplicar Filtro', icon: MdCheck, action: data => handleFilter(data) }
              ]
            : [
                {
                  label: 'Salvar',
                  icon: MdSave,
                  isSubmit: true,
                  isDisabled: () => !canWrite,
                  action: handleOnSubmit
                },
                {
                  label: 'Remover',
                  icon: MdDelete,
                  isDisabled: () => !canWrite,
                  action: handleRemove
                }
              ]
        }
      />
    </Container>
  );
}
