import React from 'react';
import _ from 'lodash';
import { InputGroup, Select, InputMask } from '~/components/form';
import { MASK_CNPJ, MASK_CPF } from '~constants';
import { FlexRow, FlexCol } from '~/components/layout';
import { FilterContainer } from '~/components/crud/styles';

export default function FormFilter({ values, setFieldValue, onListBankAccounts, onListCompanies, hasAccountsReceivable, hasAccountsPayable }) {
  return (
    <FilterContainer>
      {(hasAccountsReceivable && hasAccountsPayable) &&
        <Select
          name="partnerType"
          label="Tipo"
          options={{ values: [
              { "value": "", "label": "Todos"},
              { "value": "customer", "label": "Clientes"},
              { "value": "supplier", "label": "Fornecedores"}
            ] }}
            />
      }

      <FlexRow>
        <FlexCol flex="0 0 150px">
          <Select
            name="identityType"
            label="Tipo"
            onChange={evt => {
              setFieldValue('identityType', evt.target.value);
              setFieldValue('identity', '');
            }}
            options={{
              values: [
                { value: 'company', label: 'CNPJ' },
                { value: 'person', label: 'CPF' }
              ]
            }}
          />
        </FlexCol>

        <FlexCol flex="1">
          {_.get(values, 'identityType') !== 'person' && (
            <InputMask
              mask={MASK_CNPJ}
              name="identity"
              label={'CNPJ'}
            />
          )}

          {_.get(values, 'identityType') === 'person' && (
            <InputMask
              mask={MASK_CPF}
              name="identity"
              label={'CPF'}
            />
          )}
        </FlexCol>
      </FlexRow>

      <InputGroup
        type="text"
        name="name"
        label="Nome / Razão social"
        maxLength={100}
        />

      <InputGroup
        type="text"
        name="tradeName"
        label="Apelido / Nome fantasia"
        maxLength={100}
        />
    </FilterContainer>
  );
}
