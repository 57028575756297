import { all, put, call, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '~/services/api';
import { getError } from '~/helper';
import { Creators as Actions, Types } from '~/store/modules/payment-approvals-pending';

export function showError(error) {
  const msg = getError(error);
  toast.error(msg);
}

export function* list({ params }) {
  try {
    const response = yield call(api.post, 'payment-approvals-pending/list', params);
    yield put(Actions.listSuccess(response.data));
  } catch (error) {
    showError(error);
  }
};

export function* listPaymentOrderTypes() {
  try {
    const response = yield call(api.get, 'payment-orders/list-payment-order-types');
    yield put(Actions.listPaymentOrderTypesSuccess(response.data));
  } catch (error) {}
}

export function* listBankContracts({ params, callback }) {
  try {
    const response = yield call(api.get, 'payment-orders/list-bank-contracts', { params });
    yield call(callback, response.data);
  } catch (error) {}
}

export function* listCompanies({ params, callback }) {
  try {
    const response = yield call(api.get, 'payment-orders/list-companies', { params });
    yield call(callback, response.data);
  } catch (error) {}
}

export function* listBusinessPartners({ term, callback }) {
  try {
    const response = yield call(api.get, 'payment-orders/list-business-partners', { params: { term } });
    yield call(callback, response.data);
  } catch (error) {}
}

export function* listUsers({term, callback}) {
  try {
    const response = yield call(api.get, 'email-batchs/list-users', { params: { term } });
    yield call(callback, response.data);
  } catch (error) {}
};

export default all([
  takeLatest(Types.LIST, list),
  takeLatest(Types.LIST_PAYMENT_ORDER_TYPES, listPaymentOrderTypes),
  takeLatest(Types.LIST_BANK_CONTRACTS, listBankContracts),
  takeLatest(Types.LIST_COMPANIES, listCompanies),
  takeLatest(Types.LIST_BUSINESS_PARTNERS, listBusinessPartners),
  takeLatest(Types.LIST_USERS, listUsers),
]);
