import React from 'react';
import _ from 'lodash';
import { useField, useFormikContext } from 'formik';
import styled, { css } from 'styled-components';
import { formats, getFileAsBase64 } from '~/helper';
import confirm from '~/components/confirm';
import { IconButton } from '~/components/button';
import { MdDelete } from 'react-icons/md';
import { lightGray } from '~/components/mixins/color';

const Container = styled.div`
  display: flex;
  background: ${lightGray.hex()};
  border: 2px solid ${lightGray.hex()};
  font: 14px 'Ubuntu',sans-serif;
  color: #555;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  margin: ${(props) => props.noMargin ? '0' : '0 0 10px'};
  height: auto;
  min-height: 46px;

  ${(props) => props.disabled && css`
    opacity: 0.75;
    cursor: not-allowed;
    user-select: none;
  `}

  input[type="file"] {
    display: none;
  }

  label {
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
  }
`;

const FileContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  padding: 0 10px;
  gap: 10px;
  .name {
    display: flex;
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    align-items: center;
    justify-content: flex-start;
  }
  .size {
    display: flex;
    flex: 0 0 100px;
    max-width: 30%;
    font-weight: 800;
    margin-left: 5px;
    font-size: 11px;
    align-items: center;
    justify-content: flex-end;
  }
  .action {
    display: flex;
    flex: 0 0 46px;
    align-items: center;
    justify-content: flex-end;
  }
`;

const handleChanges = ({props, setFieldValue, field}, files) => {
  if (_.size(files) > 0) {
    getFileAsBase64(files[0], (contents) => {
      let data = _.pick(files[0], 'name', 'size', 'originalFilename');
      data.contents = contents;
      setFieldValue(field.name, data);
      props.onChange && props.onChange({ target: { value: data } });
    });
  }
};

const FileUploadInline = (props) => {
  const { setFieldValue } = useFormikContext();
  const [ field ] = useField(props);
  const params = { props, field, setFieldValue };
  const { accept, previewMode } = props;

  const onInputChange = (e) => {
    e.persist();
    handleChanges(params, e.target.files);
  };
  
  const clearFile = async () => {
    const result = await confirm.show({ title: 'Atenção', text: `Deseja realmente remover este arquivo?` });

    if (result) {
      setFieldValue(props.name, null);
    }
  };

  return (
    <Container disabled={props.disabled} noMargin={props.noMargin}>
      {_.isEmpty(field.value) &&
        <label>
          <input type="file" accept={accept} disabled={previewMode} onChange={onInputChange} />
          {props.label}
        </label>
      }

      {!_.isEmpty(field.value) &&
        <FileContainer>
          <div className="name">
           {props.selectedLabel} {_.get(field, 'value.name')}
          </div>

          <div className="size">
            ({formats.fileSize(_.get(field, 'value.size') || 0)})
          </div>

          <div className="action">
            <IconButton title="Limpar" disabled={previewMode} onClick={clearFile}>
              <MdDelete />
            </IconButton>
          </div>
        </FileContainer>
      }
    </Container>
  );
}

export default FileUploadInline;
