import styled from 'styled-components';


export const Container = styled.div`
  height: 100%;
  max-height: ${props => props.maxHeight};
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 10px;
  position: relative;

  @media (max-width: 425px) {
    max-height: 100%;
  }
`;
