import React from 'react';
import _ from 'lodash';
import DataTable from '~/components/datatable';
import { formats } from 'helper';
import { scopeTypes } from '~/pages/email-settings/form';
import { PaymentDetailTableContainer } from '~/pages/payment-email-batchs/styles';

const columns = [
    {
      name: 'Enviado em',
      width: '140px',
      selector: 'sentAt',
      format: (row) => formats.dateTimeZone(_.get(row, 'sendAt'), 'dd/MM/yyyy HH:mm')
    },
    {
      name: 'Templates',
      selector: 'emailSettings',
      wrap: true,
      format: (row) => {
        let templatesList = _.filter(_.get(row, 'emailSettings') || [], (r) => !!r.description),
          templates = [];

        for(let row of templatesList) {
          templates.push(`${row.description} (${scopeTypes[row.scope]})`)
        }
        return templates.join(', ')
      }
    },
    {
      name: 'Ordens de pagamento',
      selector: 'pagamento',
      wrap: true,
      format: (row) => (1)
    },
  ];

export const TabSents = ({ sents, openDocument }) => {

  return (
    <>
      <DataTable
        emptyText="Nenhuma Ordem de pagamento encontrada."
        noPagination={true}
        hideUpdateButton={true}
        data={{ rows: sents}}
        onRowClicked={_.noop}
        padding="0"
        columns={columns}
        extraOptions={{
          expandableRows: true,
          highlightOnHover: true,
          expandOnRowClicked: true,
          selectableRows: false,
          selectableRowsHighlight: false,
          expandableRowsComponent: <PaymentOrderRow sents={sents} openDocument={openDocument} />
        }}
      />
      <label><b>Total de Ordens de pagamento:</b> {_.size(sents || [])}</label>
    </>
    
  )
};

const columnsDetails = [
  {
    name: 'Nr. Documento',
    selector: 'documentNumber',
    width: '150px',
    format: (row) => _.get(row, 'documentNumber')
  },
  {
    name: 'Nosso número',
    selector: 'ourNumber',
    width: '150px',
    format: (row) => _.get(row, 'ourNumber')
  },
  {
    name: 'Vencimento',
    width: '120px',
    selector: 'dueDate',
    format: (row) => formats.dateTimeZone(_.get(row, 'dueDate'), 'dd/MM/yyyy')
  },
  {
    name: 'Valor (R$)',
    selector: 'value',
    width: '120px',
    right: true,
    format: (row) => formats.decimal(_.get(row, 'value'))
  },
];

const PaymentOrderRow = ({ data = {}, openDocument }) => {

  const rows = [data.paymentOrder];

  return (
    <PaymentDetailTableContainer style={{height: _.size(rows) === 1 ? '70px' : '140px'}}>
      <DataTable
        noPagination={true}
        emptyText="Nenhuma Ordem de pagamento encontrada."
        hideUpdateButton={true}
        noTableHeader={true}
        data={{ rows }}
        onRowClicked={openDocument}
        padding="0"
        columns={columnsDetails}
        footer={true}
        extraOptions={{
          selectableRowsHighlight: false
        }}
      />
    </PaymentDetailTableContainer>
      
  );
};