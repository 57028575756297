import _ from 'lodash'
import {
  format,
  parse,
  parseISO,
  startOfMonth,
  lastDayOfMonth,
  isValid,
} from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import { ptBR } from 'date-fns/locale'
import { toast } from 'react-toastify'

export const formats = {
  capitalize: (str) => {
    return !str
      ? ''
      : _.toLower(_.trim(str)).replace(/\w\S*/g, (w) =>
          w.replace(/^\w/, _.toUpper),
        )
  },
  phone: (str) => {
    let cleaned = onlyNumbers(str) || '',
      size = _.size(cleaned)
    if (![10, 11, 12, 13].includes(size)) {
      return str
    }
    if (size === 10) {
      return cleaned.replace(/^(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3')
    } else if (size === 11) {
      return cleaned.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3')
    } else if (size === 12) {
      return cleaned.replace(/^(\d{2})(\d{2})(\d{4})(\d{4})$/, '+$1 ($2) $3-$4')
    }
    return cleaned.replace(/^(\d{2})(\d{2})(\d{5})(\d{4})$/, '+$1 ($2) $3-$4')
  },
  cep: (str) => {
    let cleaned = onlyNumbers(str)
    return _.size(cleaned) !== 8
      ? ''
      : cleaned.replace(/^(\d{2})(\d{3})(\d{3})$/, '$1.$2-$3')
  },
  cnpj: (str) => {
    let cleaned = onlyNumbers(str)
    return _.size(cleaned) !== 14
      ? ''
      : cleaned.replace(
          /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
          '$1.$2.$3/$4-$5',
        )
  },
  cpf: (str) => {
    let cleaned = onlyNumbers(str)
    return _.size(cleaned) !== 11
      ? ''
      : cleaned.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4')
  },
  cnpj_cpf: (str) => {
    let cleaned = onlyNumbers(str)
    return _.size(cleaned) === 11 ? formats.cpf(cleaned) : formats.cnpj(cleaned)
  },
  dateTimeZone: (date, mask = 'dd/MM/yyyy') => {
    let value = formats.getDate(date)
    value = isValid(value) ? value : ''
    return !value
      ? ''
      : format(zonedTimeToUtc(value, 'America/Sao_Paulo'), mask, {
          locale: ptBR,
        })
  },
  date: (date, mask = 'dd/MM/yyyy') => {
    let value = formats.getDate(date)
    value = isValid(value) ? value : ''

    return !value ? '' : format(value, mask, { locale: ptBR })
  },
  getDate: (value) => {
    if (_.isDate(value) && !_.isString(value)) {
      return value
    }
    return value && _.isString(value) ? parseISO(value) : null
  },
  fileSize: (bytes) => {
    let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    if (bytes === 0) {
      return '0 Byte'
    }
    let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i]
  },
  numberScale: (value) => {
    const sizes = ['', 'K', 'M', 'B', 'T', 'Q'],
      isNegative = value < 0
    if (!value) {
      return '0'
    }
    if (value < 0) {
      value *= -1
    }
    if(value > 0 && value < 1) {
      return '0'
    }
    const i = parseInt(Math.floor(Math.log(value) / Math.log(1000)))
    const formatedValue = `${parseInt(value / Math.pow(1000, i), 10)}${
      sizes[i]
    }`
    return isNegative ? `(${formatedValue})` : formatedValue
  },
  currency: (value = 0, locale = 'pt-BR', currencyCode = 'BRL') => {
    return parseFloat(value).toLocaleString(locale, {
      style: 'currency',
      currency: currencyCode,
    })
  },
  percent: (value = 0, locale = 'pt-BR') => {
    return parseFloat(value).toLocaleString(locale, { style: 'percent' })
  },
  decimal: (value = 0, locale = 'pt-BR', decimalPlaces = 2) => {
    return parseFloat(value).toLocaleString(locale, {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
    })
  },
  number: (value = 0, locale = 'pt-BR', currencyLocale = 'BRL') => {
    return isNaN(value) || value === null
      ? ''
      : parseFloat(value).toLocaleString(locale)
  },
  plural: (value = 0, singular, plural) => {
    return value === 1 ? singular : plural
  },
  pis_pasep: (str) => {
    let cleaned = onlyNumbers(str)
    if (!cleaned || _.size(cleaned) !== 11) return ''
    return cleaned.replace(/^(\d{3})(\d{4})(\d{3})(\d{1})$/, '$1.$2.$3-$4')
  },
  digitableLine: (str) => {
    let cleaned = onlyNumbers(str)
    if (!cleaned || cleaned.length === 0) return ''
    if (_.size(cleaned) === 48) {
      return cleaned.replace(
        /^(\d{11})(\d{1})(\d{11})(\d{1})(\d{11})(\d{1})(\d{11})(\d{1})$/,
        '$1 $2 $3 $4 $5 $6 $7 $8',
      )
    }
    return cleaned.replace(
      /^(\d{5})(\d{5})(\d{5})(\d{6})(\d{5})(\d{6})(\d{1})(\d{14})$/,
      '$1.$2 $3.$4 $5.$6 $7 $8',
    )
  },
}

export const getMonths = function () {
  const months = []
  for (let i = 0; i < 12; i++) {
    months.push({ id: i, label: ptBR.localize.month(i) })
  }
  return months
}

export const generateYears = function (left, rigth) {
  const now = new Date().getFullYear(),
    min = now - left,
    max = now + rigth,
    years = []

  for (let i = min; i <= max; i++) {
    years.push(i)
  }

  return years
}

export const getLastDayOfMonthFromString = (value) => {
  try {
    const date = parse(value, 'dd/MM/yyyy', new Date())
    return formats.date(lastDayOfMonth(date), 'dd/MM/yyyy')
  } catch (err) {
    return ''
  }
}

export const getFirstDayOfMonthFromString = (value) => {
  try {
    const date = parse(value, 'dd/MM/yyyy', new Date())
    return formats.date(startOfMonth(date), 'dd/MM/yyyy')
  } catch (err) {
    return ''
  }
}

export function getColorByContrast(bgColor, lightColor, darkColor) {
  if (!bgColor) {
    return darkColor
  }
  let color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor,
    r = parseInt(color.substring(0, 2), 16),
    g = parseInt(color.substring(2, 4), 16),
    b = parseInt(color.substring(4, 6), 16),
    uicolors = [r / 255, g / 255, b / 255]
  let c = _.map(uicolors, (col) => {
    if (col <= 0.03928) {
      return col / 12.92
    }
    return Math.pow((col + 0.055) / 1.055, 2.4)
  })
  let threshold = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2]
  return threshold > 0.179 ? darkColor : lightColor
}

export const onlyNumbers = (value) => {
  if (!value) {
    return null
  }
  return String(value).replace(/[^0-9]/g, '')
}

export const getError = (payload) => {
  let message =
    _.get(payload, 'response.data.message') || _.get(payload, 'error.message')
  if (!message) {
    console.error(payload)
  }
  return message || 'Falha na comunicação com o servidor. Tente novamente!'
}

export const arrayInsertAt = (array, index, ...elements) => {
  array.splice(index, 0, ...elements)
}

export const getSlipType = (value) => {
  let code = String(value).replace(/[^0-9]/g, '')

  if (
    code.substr(-14) === '00000000000000' ||
    code.substr(5, 14) === '00000000000000'
  ) {
    return 'CARTAO_DE_CREDITO'
  } else if (/^81/.test(code)) {
    return 'ARRECADACAO_PREFEITURA'
  } else if (/^82/.test(code)) {
    return 'CONVENIO_SANEAMENTO'
  } else if (/^83/.test(code)) {
    return 'CONVENIO_ENERGIA_ELETRICA_E_GAS'
  } else if (/^84/.test(code)) {
    return 'CONVENIO_TELECOMUNICACOES'
  } else if (/^85/.test(code)) {
    return 'ARRECADACAO_ORGAOS_GOVERNAMENTAIS'
  } else if (/^86/.test(code)) {
    return 'OUTROS'
  } else if (/^87/.test(code)) {
    return 'ARRECADACAO_TAXAS_DE_TRANSITO'
  }
  return 'BANCO'
}

export const digitableLineToBarcode = (value) => {
  let val = String(value).replace(/[^0-9]/g, ''),
    type = getSlipType(val),
    result = ''

  if (/^(BANCO|CARTAO_DE_CREDITO)$/.test(type)) {
    result =
      val.substr(0, 4) +
      val.substr(32, 1) +
      val.substr(33, 14) +
      val.substr(4, 5) +
      val.substr(10, 10) +
      val.substr(21, 10)
  } else {
    val = val.split('')
    val.splice(11, 1)
    val.splice(22, 1)
    val.splice(33, 1)
    val.splice(44, 1)
    val = val.join('')
    result = val
  }
  return result
}

export const mod10 = (num) => {
  let total = 0,
    fator = 2

  for (let i = num.length - 1; i >= 0; i--) {
    let temp = (parseInt(num[i]) * fator).toString()
    let tempSum = 0
    for (let j = 0; j < temp.length; j++) {
      tempSum += parseInt(temp[j])
    }
    total += tempSum
    fator = fator === 2 ? 1 : 2
  }
  let resto = total % 10
  return resto === 0 ? 0 : 10 - resto
}

export const mod11 = (num) => {
  let sequencia = [4, 3, 2, 9, 8, 7, 6, 5],
    digit = 0,
    j = 0,
    DAC = 0

  for (let i = 0; i < num.length; i++) {
    let mult = sequencia[j]
    j++
    j %= sequencia.length
    digit += mult * parseInt(num.charAt(i))
  }
  DAC = digit % 11

  if (DAC === 0 || DAC === 1) {
    return 0
  }

  if (DAC === 10) {
    return 1
  }

  return 11 - DAC
}

export const barcodeToDigitableLine = (barcode) => {
  if (_.size(barcode) !== 44) {
    return ''
  }

  let val = String(barcode).replace(/[^0-9]/g, ''),
    type = getSlipType(val)

  if (/^(BANCO|CARTAO_DE_CREDITO)$/.test(type)) {
    let c = String(barcode).match(
        /^(\d{4})(\d{1})(\d{14})(\d{5})(\d{10})(\d{10})$/,
      ),
      line = `${c[1]}${c[4]}${mod10(`${c[1]}${c[4]}`)}${c[5]}${mod10(c[5])}${
        c[6]
      }${mod10(c[6])}${c[2]}${c[3]}`

    return line.replace(
      /^(\d{5})(\d{5})(\d{5})(\d{6})(\d{5})(\d{6})(\d{1})(\d{14})$/,
      '$1.$2 $3.$4 $5.$6 $7 $8',
    )
  } else if (/^(ARRECADACAO_ORGAOS_GOVERNAMENTAIS)$/.test(type)) {
    let c = String(barcode).match(/^(\d{11})(\d{11})(\d{11})(\d{11})$/),
      line = `${c[1]}${mod11(c[1])}${c[2]}${mod11(c[2])}${c[3]}${mod11(c[3])}${
        c[4]
      }${mod11(c[4])}`

    return line.replace(
      /^(\d{11})(\d{1})(\d{11})(\d{1})(\d{11})(\d{1})(\d{11})(\d{1})$/,
      '$1 $2 $3 $4 $5 $6 $7 $8',
    )
  } else {
    let c = String(barcode).match(/^(\d{11})(\d{11})(\d{11})(\d{11})$/),
      line = `${c[1]}${mod10(c[1])}${c[2]}${mod10(c[2])}${c[3]}${mod10(c[3])}${
        c[4]
      }${mod10(c[4])}`

    return line.replace(
      /^(\d{11})(\d{1})(\d{11})(\d{1})(\d{11})(\d{1})(\d{11})(\d{1})$/,
      '$1 $2 $3 $4 $5 $6 $7 $8',
    )
  }
}

export const barcodeDecimal = (valueString) => {
  let base = String(valueString),
    number = `${base.slice(0, _.size(base) - 2)}.${base.slice(-2)}`
  return isNaN(number) ? 0 : Number(number)
}

export function showError(error) {
  const msg = getError(error)
  toast.error(msg)
}

export function getFileAsBase64(file, callback) {
  let reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = function () {
    callback(reader.result)
  }
  reader.onerror = function (error) {
    console.log('Error: ', error)
    callback('')
  }
}

export const asNumber = (value) => {
  return isNaN(value) ? 0 : Number(value)
}

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const QueryString = {
  getData(location) {
    return Object.fromEntries(
      new URLSearchParams(_.get(location, 'search', {})),
    )
  },
  stringify: (data = {}) => new URLSearchParams(data),
}

// Sort functions to use on colums (react-data-table-component)
export const sortCaseInsesitive = (a, b, selector) => {
  a = _.deburr(_.get(a, selector)).toLowerCase();
  b = _.deburr(_.get(b, selector)).toLowerCase();

  return a === b ? 0 : ( a > b ? 1 : -1 );
}
export const sortByDate = (a, b, selector) => {
  a = new Date(_.get(a, selector)).getTime();
  b = new Date(_.get(b, selector)).getTime();

  return a === b ? 0 : ( a > b ? 1 : -1 )
}
export const sortByMaxOrMin = (a, b, selector) => {
  a = _.get(a, selector);
  b = _.get(b, selector);

  return a === b ? 0 : ( a > b ? 1 : -1 );
}
