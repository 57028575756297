import { all, takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { Creators as ProfileActions, Types as ProfileTypes } from '~/store/modules/profile';
import api from '~/services/api';
import { getError } from '~/helper';

export function* createOrUpdate({ data, actions }) {
  try {
    let editing = !!((data && data.id)),
      method = editing ? 'put' : 'post',
      path = editing ? 'update' : 'create';

    yield call(api[method], `profiles/${path}`, data);

    toast.success(`Perfil ${editing ? 'atualizado' : 'criado'} com sucesso`);
    actions.closeForm && actions.closeForm();
    actions.reloadList && actions.reloadList();
  } catch (error) {
    showError(error);
  }
}

export function* list({ params }) {
  try {
    const response = yield call(api.post, 'profiles/list', params);
    yield put(ProfileActions.listSuccess(response.data));
  } catch (error) {
    showError(error);
  }
}

export function* load({ data }) {
  try {
    const response = yield call(api.get, 'profiles/load', { params: { id: data.id || 'new' } });
    yield put(ProfileActions.loadSuccess(response.data));
  } catch (error) {
    showError(error);
  }
}

export function showError(error) {
  const msg = getError(error);
  toast.error(msg);
}

export default all([
  takeLatest(ProfileTypes.LIST, list),
  takeLatest(ProfileTypes.LOAD, load),
  takeLatest(ProfileTypes.CREATE_OR_UPDATE, createOrUpdate),
]);
