import { createActions, createReducer } from 'reduxsauce';
import produce from 'immer';
import _ from 'lodash';

export const { Types, Creators } = createActions({
  list: ['params'],
  listSuccess: ['data'],
  load: ['data'],
  loadSuccess: ['model'],
  createOrUpdate: ['data', 'actions'],
  searchCnpjRequest: ['cnpj', 'model'],
  searchCnpjResult: ['data', 'model'],
  listBusinessPartnerGroup: ['term', 'callback'],
  listRepresentative: ['term', 'callback'],
  createUser: ['data', 'callback'],
  resetUserPassword: ['data', 'callback'],
  clearModel: [],
  remove: ['data', 'actions'],
  listCompanies: ['params', 'callback'],
}, { prefix: 'businessPartners' });

const INITIAL_STATE = {
  loading: false,
  formLoading: false,
  term: null,
  model: null,
  data: { rows: [], count: 0, offset: 0 }
};

const handleList = (state = INITIAL_STATE, action) => produce(state, draft => {
  draft.term = action.term;
  draft.loading = true;
  draft.data = { rows: [], count: 0, offset: 0 }
});

const handleListSuccess = (state = INITIAL_STATE, action) => produce(state, draft => {
  draft.loading = false;
  draft.data = action.data;
});

const handleLoad = (state = INITIAL_STATE, action) => produce(state, draft => {
  draft.model = {};
  draft.formLoading = true;
});

const handleLoadSuccess = (state = INITIAL_STATE, action) => produce(state, draft => {
  draft.formLoading = false;

  let model = _.get(action, 'model.businessPartner'),
    contacts = _.get(model, 'extras.contacts') || [];
  _.set(model, 'extras.contacts', _.isArray(contacts) ? contacts : [contacts]);

  draft.model = model;
});

const searchCnpjRequest = (state = INITIAL_STATE) => produce(state, draft => {
  draft.formLoading = true;
});

const searchCnpjResult = (state = INITIAL_STATE, action) => produce(state, draft => {
  draft.formLoading = false;
  draft.model = _.get(action, 'model', {});
  draft.model.identity = _.get(action, 'data.identity');
  draft.model.name = _.get(action, 'data.name');
  draft.model.tradeName = _.get(action, 'data.tradeName');
  draft.model.phone = _.get(action, 'data.phone');
  draft.model.email = _.get(action, 'data.email');
  draft.model.address = _.get(action, 'data.address');
});

const handleClearModel = (state = INITIAL_STATE, action) => produce(state, draft => {
  draft.model = null;
  draft.formLoading = false;
});

export default createReducer(INITIAL_STATE, {
  [Types.LIST]: handleList,
  [Types.LIST_SUCCESS]: handleListSuccess,
  [Types.LOAD]: handleLoad,
  [Types.LOAD_SUCCESS]: handleLoadSuccess,
  [Types.SEARCH_CNPJ_REQUEST]: searchCnpjRequest,
  [Types.SEARCH_CNPJ_RESULT]: searchCnpjResult,
  [Types.CLEAR_MODEL]: handleClearModel,
});
