import { put, call, all, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '~/services/api';
import { getError } from  '~/helper';
import { Creators as BillActions, Types as BillTypes } from '~/store/modules/bill-client';

export function showError(error) {
  const msg =  getError(error);
  toast.error(msg);
};

export function* load({ id }) {
  try {
    const response = yield call(api.get, 'bills-client/load', { params: { id } });
    yield put(BillActions.loadSuccess(response.data));
  } catch (error) {
    yield put(BillActions.loadSuccess({}));
    showError(error);
  }
};

export function* list({ params }) {
  try {
    const response = yield call(api.post, 'bills-client/list', params);
    yield put(BillActions.listSuccess(response.data));
  } catch (error) {
    showError(error);
  }
};

export function* downloadFile({ data }) {
  try {
    const params = { id: data.id, filename: data.filename }
    const response = yield call(api.get, 'bills-client/download-file', { params, responseType: 'blob' });

    api.download(response, data.filename)
  } catch (error) {
    showError(error);
  }
};

export function* listBusinessPartners({ term, callback }) {
  try {
    const response = yield call(api.get, 'bills-client/list-business-partners', {
      params: { term },
    })
    yield call(callback, response.data)
  } catch (error) {}
}

export default all([
  takeLatest(BillTypes.LOAD, load),
  takeLatest(BillTypes.LIST, list),
  takeLatest(BillTypes.DOWNLOAD_FILE, downloadFile),
  takeLatest(BillTypes.LIST_BUSINESS_PARTNERS, listBusinessPartners),
]);
