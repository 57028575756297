import styled from 'styled-components';
import { Form } from 'formik';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 10px;
  position: relative;

  .company-info {
    line-height: 15px;
    width: 100%;
    height: 80%;
    pointer-events: none;
    font-size: 12px;

    td {
      min-height: 20px;
      height: auto;
      vertical-align: middle;
    }
    .title {
      width: 70px;
      font-weight: bold;
      font-size: 85%;
      color: #999;
    }
  }
`;

export const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  padding: 10px;
  .files {
    padding: 0 10px;
    margin-bottom: 5px;
    background: #fbfafa;
    color: #555;
  }
`;

export const InputButton = styled.div`
  font-size: 20px;
  cursor: pointer;
  right: 0 !important;
  padding: 10px;

  &:hover {
    color: #666 !important;
    background: rgba(0, 0, 0, 0.08);
  }
`;

export const ContainerCopy = styled.div`
  background: #dedede;
  padding-top: 1px;
`;

export const AttachmentTableContainer = styled.div`
  display: flex;
  width: 100%;
  height: 170px;
  min-height: 170px;
`;

export const CompanyContainer = styled.div`
  width: 100%;
  height: 100%;
  font-size: 12px;
  padding: 5px;
  display: flex;
  pointer-events: none;

  .company-icons {
    display: grid;
    place-items: center;
    width: 24px;
  }

  .company-text {
    width: ${(props) => `calc(100% - ${props.hasIcon ? '24px' : '0px'}) `};
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 3px;
    }
  }
`