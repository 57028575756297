import React from 'react';
import PropTypes from 'prop-types';
import { useField, ErrorMessage } from 'formik';
import styled, { css } from 'styled-components';
import { primary, lightGray, gray } from '~/components/mixins/color';
import _ from 'lodash';
import arrow from '~/assets/arrow.svg';

const Group = styled.div`
  display: grid;
  position: relative;
  width: 100%;

  select {
    height: 46px;
    width: 100%;
    color: ${gray.hex()};
    line-height: 1.3;
    padding: 18px 0 5px 10px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: ${lightGray.hex()} url(${arrow});
    border: 2px solid ${lightGray.hex()};
    border-radius: 2px;
    background-repeat: no-repeat, repeat;
    background-position: right 1.5em top 50%, 0 0;
    background-size: .65em auto, 100%;
    margin: ${props => (props.noMargin ? '0' : '0 0 10px')};

    &:focus {
      border: 2px solid ${primary.hex()};
    }

    &:disabled {
      opacity: 0.55;
    }

    &:focus + label,
    &:not([value=""]) + label {
      font-size: 70%;
      transform: translate3d(0, -100%, 0);
      opacity: 1;
      top: 18px;
      position: absolute;
    }
    &:not([value=""]) + label {
      color: ${gray.hex()}
    }
    &:focus + label {
      color: ${gray.hex()}
    }
    & + label {
      font-size: 70%;
      top: 11px;
      padding-left: 10px;
      transition: all 200ms;
      color: ${gray.hex()}
      opacity: 0.7;
    }
     ${props =>
       props.disabled &&
       css`
         opacity: 0.55;
         user-select: none;
         pointer-events: none;
         color: #b7b7b7;
         & + label {
           color: #b7b7b7 !important;
         }
       `};
  }
`;

const Select = ({ name, label, hasError, options, disabled = false, value, noMargin, ...rest }) => {
  const [field] = useField({ name });

  const OptionDefault = ({ defaults }) => {
    const { value, label } = defaults;
    if (!value && !label) return null;
    return (
      <option value={value} key={value}>
        {label}
      </option>
    );
  };

  
  const Options = ({ values }) => {
    const groups = _.groupBy(values, 'group')
    
    return _.map(groups,(row, group) => {
      if(group === 'undefined'){
        return row.map((opt) => (
          <option value={opt.value} key={opt.value}>
            {opt.label}
          </option>
        ))
      }
      return (
        <optgroup label={group} key={group}>
          {
            row.map((opt) => (
              <option value={opt.value} key={opt.value}>
                {opt.label}
              </option>
            ))
          }
        </optgroup>
      )
      
    })
  }

  return (
    <Group disabled={disabled} noMargin={noMargin}>
      <select
        {...field}
        {...rest}
        disabled={disabled}
        value={(_.isUndefined(field.value) || _.isNull(field.value)) ? '' : field.value}
        className={!!hasError ? 'error-border' : ''}
        placeholder={label}>
        {options.defaults && <OptionDefault defaults={options.defaults} />}
        <Options {...options} />
      </select>
      <label htmlFor={name}>{label}</label>
      {hasError && <ErrorMessage name={name} className="error" component="div" />}
    </Group>
  );
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  hasError: PropTypes.bool,
  options: PropTypes.shape({
    defaults: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    }),
    values: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        group: PropTypes.string,
      })
    )
  })
};

export default Select;

/*
  Exemplo de uso

  <Select
    name="profile"
    hasError={errors.profile && touched.profile}
    label="Perfil"
    options={
      {
        defaults: {value: "", label: "Selecione um perfil"},
        values: Object.keys(PROFILES).map(key => ({"value": key, "label": PROFILES[key]}))
      }
    }
    />
*/
