import React from 'react'
import * as Yup from 'yup'
import _ from 'lodash'
import { FormContainer } from '~/pages/email-settings/styles'
import {
  InputGroup,
  Select,
  GroupCheckbox,
  Autocomplete,
} from '~/components/form'
import Fieldset from '~/components/fieldset'
import {
  formatBankContractSimple,
  formatBankContract,
} from '~/pages/bank-contracts/form'
import { formats } from '~/helper'

const ALLOWED_DAYS = {
  '0': 0,
  '1': 1,
  '2': 2,
  '3': 3,
  '4': 4,
  '5': 5,
  '6': 6,
  '7': 7,
  '8': 8,
  '9': 9,
  '10': 10,
  '11': 11,
  '12': 12,
  '13': 13,
  '14': 14,
  '15': 15,
  '16': 16,
  '17': 17,
  '18': 18,
  '19': 19,
  '20': 20,
  '21': 21,
  '22': 22,
  '23': 23,
  '24': 24,
  '25': 25,
  '26': 26,
  '27': 27,
  '28': 28,
  '29': 29,
  '30': 30,
  '35': 35,
  '40': 40,
  '45': 45,
  '50': 50,
  '55': 55,
  '60': 60,
  '65': 65,
  '70': 70,
  '75': 75,
  '80': 80,
  '85': 85,
  '90': 90,
}
const ALLOWED_HOURS = { '11': '11 Horas da manhã', '22': '10 Horas da noite' }

const getAllowedDay = (isOverdue) => {
  let result = _.omit(ALLOWED_DAYS, [
    '20',
    '25',
    '35',
    '40',
    '45',
    '50',
    '55',
    '65',
    '70',
    '75',
    '80',
    '85',
  ])

  if (isOverdue) {
    result = _.omit(ALLOWED_DAYS, ['0'])
  }

  result = _.chain(result)
    .values()
    .sort()
    .reduce((obj, k) => {
      obj[k] = k
      return obj
    }, {})
    .value()

  return result
}

export const eventTypes = [
  { value: 'slip_generated', label: 'Fatura gerada', movementType: 'charge' },
  { value: 'slip_paid', label: 'Fatura paga', movementType: 'charge' },
  { value: 'slip_before_overdue', label: 'Fatura a vencer', movementType: 'charge' },
  { value: 'slip_overdue', label: 'Fatura vencida', movementType: 'charge' },
  { value: 'slip_duedate_change', label: 'Fatura vencimento alterado', movementType: 'charge' },
  { value: 'slip_write_off', label: 'Fatura cancelada (Baixa pelo Banco)', movementType: 'charge' },
  { value: 'payment_order_closed', label: 'Ordem de pagamento fechada (Comprovante de Pagamento)', movementType: 'payment' },
  { value: 'payment_order_grouped', label: 'Ordem de pagamento fechada (Agrupamento Diário)', movementType: 'payment' },
]

export const scopeTypes = {
  general: 'Geral',
  company: 'Empresa',
  bankContract: 'Contrato',
  businessPartnerGroup: 'Grupo de parceiro',
}

const getTemplateOpts = (templates, eventType) => {
  let data = []
  if (eventType) {
    let filter = _.find(eventTypes, { value: eventType })
    let filterTemplates = _.filter(templates, {
      movementType: _.get(filter, 'movementType'),
    })
    if (!_.isEmpty(filterTemplates)) {
      _.map(filterTemplates, (tmp) => {
        data.push({ value: tmp.value, label: tmp.label })
      })
    }
  } else {
    data = templates
  }
  return data
}

const getMovementType = (eventTypeParam) => {
  if (eventTypeParam) {
    let filterEventType = _.find(eventTypes, { value: eventTypeParam })
    return filterEventType.movementType || ''
  } else {
    return ''
  }
}

const customStyles = {
  display:'flex', 
  flexWrap: 'wrap'
}

export const EmailSettingsForm = ({
  setFieldValue,
  setFieldTouched,
  errors,
  touched,
  values,
  templates,
  previewMode,
  onListBankContracts,
  onListCompanies,
  onListBusinessPartnerGroup,
}) => {
  return (
    <FormContainer>
      <InputGroup
        type="text"
        name="description"
        label="Descrição"
        maxLength={100}
        hasError={_.get(errors, 'description') && _.get(touched, 'description')}
      />

      <Select
        name="eventType"
        label="Evento"
        value={_.get(values, 'eventType') || ''}
        disabled={previewMode}
        hasError={_.get(errors, 'eventType') && _.get(touched, 'eventType')}
        options={{
          values: [
            { value: '', label: 'Selecione o evento' },
            ..._.map(eventTypes, (evt) => ({
              value: evt.value,
              label: evt.label,
            })),
          ],
        }}
        onChange={(evt) => {
          const { name, value } = evt.target
          if (['slip_before_overdue', 'slip_overdue'].includes(value)) {
            setFieldTouched('notificationDays')
            setFieldValue('notificationDays', [])
          }
          if (['slip_overdue'].includes(value)) {
            setFieldTouched('notifyPeriods')
            setFieldValue('notifyPeriods', [])
          }
          setFieldValue(name, value)
          setFieldValue('emailTemplateId', '')
          setFieldValue('scope', '')
        }}
      />

      {/^(slip_before_overdue|slip_overdue)$/.test(
        _.get(values, 'eventType'),
      ) && (
        <Fieldset label="Dias para alerta">
          <GroupCheckbox
            noLabel={true}
            maxHeight="auto"
            name="notificationDays"
            values={_.get(values, 'notificationDays') || []}
            disabled={previewMode}
            allowedValues={getAllowedDay(
              _.get(values, 'eventType') === 'slip_overdue',
            )}
            hasError={!_.isEmpty(_.trim(errors.notificationDays))}
            direction="horizontal"
            noMargin={true}
            customStyle={customStyles}
          />
        </Fieldset>
      )}

      {/^(slip_overdue)$/.test(_.get(values, 'eventType')) && (
        <Fieldset label="Horário de agendamento para início de envio dos e-mails">
          <GroupCheckbox
            noLabel={true}
            maxHeight="auto"
            name="notifyPeriods"
            values={_.get(values, 'notifyPeriods') || []}
            disabled={previewMode}
            allowedValues={ALLOWED_HOURS}
            hasError={!_.isEmpty(_.trim(errors.notifyPeriods))}
            direction="horizontal"
            noMargin={true}
          />
        </Fieldset>
      )}

      <Select
        name="scope"
        label="Escopo"
        value={_.get(values, 'scope') || ''}
        disabled={previewMode}
        hasError={_.get(errors, 'scope') && _.get(touched, 'scope')}
        options={{
          values: [
            { value: '', label: 'Selecione o escopo' },
            ..._.map(scopeTypes, (label, value) => ({ value, label })),
          ],
        }}
        onChange={(evt) => {
          const { name, value } = evt.target
          setFieldValue('extras', {})
          setFieldValue(name, value)
        }}
      />

      {_.get(values, 'scope') === 'bankContract' && (
        <Autocomplete
          autoFocus={true}
          name="extras.contract"
          label="Contrato"
          value={_.get(values, 'extras.contract')}
          keyField="id"
          clearable={true}
          disabled={previewMode}
          loadData={(term, callback) =>
            onListBankContracts(
              term,
              getMovementType(_.get(values, 'eventType')),
              callback,
            )
          }
          valueFormat={formatBankContractSimple}
          optionsFormat={formatBankContract}
          emptyText={'Pesquise um contrato'}
          tipText={'Digite... '}
          loadingText={'Carregando...'}
          notFoundText={'Não encontrada'}
        />
      )}
      {_.get(values, 'scope') === 'company' && (
        <Autocomplete
          autoFocus={true}
          name="extras.company"
          label="Empresa"
          value={_.get(values, 'extras.company')}
          keyField="id"
          clearable={true}
          disabled={previewMode}
          loadData={onListCompanies}
          valueFormat={(row) =>
            `${formats.cnpj_cpf(row.identity)} - ${row.name || row.tradeName}`
          }
          emptyText={'Pesquise uma empresa'}
          tipText={'Digite... '}
          loadingText={'Carregando...'}
          notFoundText={'Não encontrada'}
        />
      )}
      {_.get(values, 'scope') === 'businessPartnerGroup' && (
        <Autocomplete
          autoFocus={true}
          name="extras.businessPartnerGroup"
          label="Grupo de parceiro"
          value={_.get(values, 'extras.businessPartnerGroup')}
          keyField="id"
          clearable={true}
          disabled={previewMode}
          loadData={onListBusinessPartnerGroup}
          valueFormat={(row) => `${row.code} - ${row.name}`}
          emptyText={'Pesquise um grupo de parceiro'}
          tipText={'Digite... '}
          loadingText={'Carregando...'}
          notFoundText={'Não encontrada'}
        />
      )}
      <Select
        name="emailTemplateId"
        label="Template"
        disabled={previewMode}
        value={_.get(values, 'emailTemplateId') || ''}
        hasError={
          _.get(errors, 'emailTemplateId') && _.get(touched, 'emailTemplateId')
        }
        options={{
          values: [
            { value: '', label: '' },
            ...getTemplateOpts(templates, _.get(values, 'eventType')),
          ],
        }}
      />
    </FormContainer>
  )
}

export const EmailSettingsSchema = Yup.object().shape({
  description: Yup.string().required('Informe uma descrição'),
  scope: Yup.string().required('Selecione o escopo a ser utilizado'),
  eventType: Yup.string().required('Informe o evento de disparo'),
  notificationDays: Yup.mixed().test('match', '', function (value) {
    const { path, parent, createError } = this
    const val = value || _.get(parent, path)
    const eventType = _.get(parent, 'eventType')
    if (
      ['slip_before_overdue', 'slip_overdue'].includes(eventType) &&
      _.size(val) === 0
    ) {
      return createError({ path, message: 'Selecione os dias para alerta' })
    }
    return true
  }),
  notifyPeriods: Yup.mixed().test('match', '', function (value) {
    const { path, parent, createError } = this
    const val = value || _.get(parent, path)
    const eventType = _.get(parent, 'eventType')
    if (['slip_overdue'].includes(eventType) && _.size(val) === 0) {
      return createError({
        path,
        message: 'Selecione o período para envio dos e-mails',
      })
    }
    return true
  }),
  extras: Yup.object()
    .when('scope', {
      is: 'bankContract',
      then: Yup.object({
        contract: Yup.string().nullable().required('Selecione um contrato'),
      }),
    })
    .when('scope', {
      is: 'company',
      then: Yup.object({
        company: Yup.string().nullable().required('Selecione uma empresa'),
      }),
    })
    .when('scope', {
      is: 'businessPartnerGroup',
      then: Yup.object({
        businessPartnerGroup: Yup.string()
          .nullable()
          .required('Selecione um grupo de parceiro'),
      }),
    }),
  emailTemplateId: Yup.string().required(
    'Selecione o template a ser utilizado',
  ),
})
