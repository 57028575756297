import React, { useEffect } from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { Creators as HeaderActions } from '~/store/modules/header';
import { Creators as AuthActions } from '~/store/modules/auth';
import queryString from 'query-string';
import styled from 'styled-components';
import { primary } from '~/components/mixins/color';

const Container = styled.div`
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  text-align: center;
  padding: 10px;
  min-width: 300px;

  h1 {
    font-size: 100px;
    text-align: center;
    color: ${primary.hex()};
    font-weight: 100;
    margin: 0;
  }

  p {
    color: ${primary.hex()};
    font-size: 18px;
  }
`;


function Auth({ location }) {
  const dispatch = useDispatch();
  const params = queryString.parse(location.search);

  useEffect(() => {
    dispatch(HeaderActions.configure({}));
  }, [dispatch]);

  useEffect(() => {
    let token = _.get(params, 'token'),
      redirect = _.get(params, 'redirect');

    if (token && redirect && redirect !== '/home') {
      dispatch(AuthActions.localLogin(token, redirect));
    }
    if (token && redirect === '/home') {
      dispatch(AuthActions.localLoginWithMenu(token, redirect));
    }
  }, [params, dispatch]);

  return (
    <Container>
      <h1>403</h1>
      <p>Você não tem permissão para acessar essa página!</p>
    </Container>
  );
}

export default Auth;
