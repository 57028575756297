import styled from 'styled-components';
import { primary } from '~/components/mixins/color';

export const Container = styled.div`
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 10px;
  min-width: 300px;

  h1 {
    font-size: 40px;
    text-align: left;
    color: ${primary.hex()};
    font-weight: 700;
    margin-bottom: 30px;
  }

  p {
    color: ${primary.hex()};
    font-size: 22px;
  }
`;
