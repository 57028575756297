import React, { useCallback } from 'react';
import Modal from '~/components/modal';
import { HiOutlineClipboardCheck } from 'react-icons/hi';
import { ModalContainer, BankAccountContainer, ContentContainer, LineContainer, LineHeader, GroupValues } from '~pages/prepare-payment-order/styles';
import _ from 'lodash';
import EmptyState from '~/components/empty-state';
import { formats } from '~/helper';

const SymmaryPreparePaymentOrderModal = ({ data, bankAccounts, isOpen, closeModal, onConfirm, isSubmiting }) => {

  const BankAccount = ({ id }) => {
    const bankAccount = _.get(bankAccounts, id) || {};
    return (
      <BankAccountContainer>
        <div>
          {bankAccount.logo && <img className="logo" src={bankAccount.logo} alt="action" />}
          <h2>
            <label>{bankAccount.label}</label>
            <span>{bankAccount.detail}</span>
          </h2>
        </div>

        {_.get(bankAccount, 'extra.bankContract.useApproval') &&
          <div>
            <HiOutlineClipboardCheck  size={24} color="#444" title="Contrato com aprovação de ordens de pagamento" />
          </div>
        }
      </BankAccountContainer>
    );
  };

  const getLines = useCallback(() => {
    return _.chain(data)
      .pickBy((v, k) => _.size(v) > 0)
      .keys()
      .value();
  }, [data]);

  const Summary = ({ data }) => {
    const lines = getLines();

    if (_.size(lines) === 0) {
      return <EmptyState text="Sem movimento" visible={true} />;
    }

    return lines.map((id, index) => {
      const values = data[id];
      const total = _.reduce(values, (memo, row) => {
        let orderDataTotal = _.get(row,'orderData.total') || 0;
        let val = orderDataTotal !== 0 ? orderDataTotal : _.get(row, 'value');
        if (_.get(row, 'isPartial')) {
          val = _.get(row, 'value', 0);
        }
        return memo + val;
      }, 0);

      return (
        <LineContainer key={id} index={index}>
          <BankAccount id={id} />
          <GroupValues>
            <div className="f-150 f-center">
              <span>{_.size(values)}</span>
            </div>
            <div className="f-200 f-right">
              <span>{formats.currency(total)}</span>
            </div>
          </GroupValues>
        </LineContainer>
      );
    });
  };

  return (
    <Modal
      width="800px"
      height="600px"
      title="Sumário"
      hideClose={true}
      open={isOpen}
      bindRefWithSubmit={true}
      hide={closeModal}
      actions={[
        {
          label: 'Fechar',
          action: () => closeModal(),
          disabled: isSubmiting
        },
        {
          label: isSubmiting ? 'Aguarde...' : `Gerar remessa${_.size(_.keys(data)) === 1 ? '' : 's'}`,
          action: () => onConfirm && onConfirm(),
          type: 'submit',
          primary: true,
          disabled: isSubmiting || _.size(getLines()) === 0
        }
      ]}
    >
      <ModalContainer>
        <ContentContainer>
          {_.size(getLines()) > 0 && (
            <LineHeader>
              <div className="f-1">
                <label>Contrato</label>
              </div>
              <div className="f-150 f-center">
                <label>Ordens de pagamento</label>
              </div>
              <div className="f-200 f-right">
                <label>Valor</label>
              </div>
            </LineHeader>
          )}
          <Summary data={data} />
        </ContentContainer>
      </ModalContainer>
    </Modal>
  );
};

export default SymmaryPreparePaymentOrderModal;
