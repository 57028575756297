
import React from 'react';
import Modal from '~/components/modal';
import styled from 'styled-components';
import Fieldset from '~/components/fieldset';
import EmailTip from '~/assets/tips/settings-email-gmail-tip.gif';

const FormContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin-bottom: 10px;

  pre {
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    width: 100%;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.25;
    font-size: 12px;
    tab-size: 2;
    hyphens: none;
    padding: 10px;
    background: #f0f0f0;
  }
  ul {
    margin-left: 10px;
    list-style-type: none;
  }
  ul > li {
    text-indent: -5px;
    height: 22px;
    strong {
      padding-left: 5px;
    }
  }
  ul > li:before {
    content: "-";
    text-indent: -5px;
  }

  p {
    line-height: 20px;
    font-size: 14px;
    margin: 5px 0;
    font-weight: normal;
  }
  p.left-space {
    padding-left: 20px;
  }
  p.bold {
    font-weight: bold;
    color: #444;
    margin-top: 10px;
  }
  .tip-image {
    width: calc(100% - 30px);
    text-align: center;
    border: 1px dotted silver;
    margin: 5px; 
  }
`;

const EmailInfoModal = ({ isOpen, toggleModal }) => {
  return (
    <Modal
      width="820px"
      height="600px"
      title={`Email - Problemas comuns no envio`}
      hideClose={true}
      open={isOpen}
      hide={toggleModal}
      actions={[{ label: 'Fechar', action: toggleModal }]}
      >
      <FormContainer>
        <Fieldset label="Gmail">
          <p>Caso emails enviados usando o Gmail não cheguem provavelmente você não tem uma das opções abaixo configuradas:</p>

          <p className="bold">1 - Método menos seguro</p>
          <p className="left-space">
            Habilite "Acesso a app menos seguro" em <a rel="noopener noreferrer" target="_blank" href="https://myaccount.google.com/lesssecureapps">https://myaccount.google.com/lesssecureapps</a>
          </p>

          <p className="bold">2 - Método mais seguro (recomendado)</p>
          <p className="left-space">
            Ative a "Verificação em duas etapas" em <a rel="noopener noreferrer" target="_blank" href="https://myaccount.google.com/signinoptions/two-step-verification">https://myaccount.google.com/signinoptions/two-step-verification</a>
          </p>

          <p className="left-space">
            Crie e use uma "Senhas de app" em <a rel="noopener noreferrer" target="_blank" href="https://myaccount.google.com/apppasswords">https://myaccount.google.com/apppasswords</a>
          </p>

          <p className="left-space">
            <img className="tip-image" alt="tip" src={EmailTip} />
          </p>
        </Fieldset>
      </FormContainer>
    </Modal>
  )
};

export default EmailInfoModal;
