import React from 'react';
import styled from 'styled-components';
import * as Bs from 'styled-bootstrap-grid';

export const gridTheme = {
  gridColumns: 24,
  breakpoints: {
    xxl: 1440,
    xl: 1200,
    lg: 992,
    md: 768,
    sm: 576,
    xs: 575,
  },
  row: {
    padding: 5,
  },
  col: {
    padding: 5,
  },
  container: {
    padding: 5,
    maxWidth: {
      xxl: 1141,
      xl: 1140,
      lg: 960,
      md: 720,
      sm: 540,
      xs: 540,
    },
  },
};

export const GridProvider = Bs.GridThemeProvider;

export const BsRow = Bs.Row;

export const BsCol = Bs.Col;

export const BsContainer = ({ children }) => {
  return (
    <Bs.Container fluid={true}>
      { children }
    </Bs.Container>
  );
};

export const Row = styled.div`
  display: grid;
  min-width: 0;
  width: 100%;
  grid-gap: ${(props) => props.gap || '10px'};
  grid-template-columns: repeat(${(props) => props.span || 1}, 1fr);
  align-items: ${(props) => props.align || 'flex-start'};
`;

export const FlexRow = styled.div`
  display: flex;
  gap: ${(props) => props.gap || '10px'};
  width: 100%;
  min-width: 0;
  flex-direction: ${(props) => props.direction || 'row'};
  align-items: ${(props) => props.align || 'flex-start'};
  justify-content: ${(props) => props.justify || 'flex-start'};
`;

export const FlexCol = styled.div`
  display: flex;
  min-width: 0;
  flex: ${(props) => props.flex || '1'};
  flex-direction: ${(props) => props.direction || 'row'};
  align-items: ${(props) => props.align || 'flex-start'};
  justify-content: ${(props) => props.justify || 'flex-start'};
`;
