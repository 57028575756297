import React from 'react';
import * as Yup from 'yup';
import _ from 'lodash';
import { PERMISSIONS_NAMES } from '~/constants';
import { FormContainer } from '~/pages/profiles/styles';
import { Checkbox, InputGroup } from '~/components/form';
import GroupCheckbox from 'pages/profiles/group-checkbox'

export const ProfileForm = ({ errors, permissions, status, touched, values, previewMode }) => {

  return (
    <FormContainer>
      <Checkbox
        halfHeight={true}
        name="active"
        label="Ativo"
        disabled={previewMode}
        />

      <InputGroup
        type="text"
        name="name"
        label="Nome"
        maxLength={30}
        disabled={previewMode}
        hasError={errors.name && touched.name} />

      <GroupCheckbox
        maxHeight="auto"
        label="Permissões"
        name="permissions"
        disabled={previewMode}
        values={_.get(values, 'permissions') || []}
        allowedValues={permissions}
        permsNames={PERMISSIONS_NAMES}
        hasError={errors.name && touched.name} />

      {status && status.msg && <div>{status.msg}</div>}
    </FormContainer>
  )
};

export const ProfileSchema = Yup.object().shape({
  name: Yup.string().min(4, 'Verifique se o nome está correto').required('Informe o nome'),
  permissions: Yup.mixed().test('', 'Informe ao menos uma permissão', function() {
    const { path, parent } = this
    return !_.isEmpty(_.get(parent, path));
  })
});
