import React from 'react';
import _ from 'lodash';
import { InputGroup, Select } from '~/components/form';
import { FilterContainer } from '~/components/crud/styles';
import { eventTypes, scopeTypes } from '~/pages/email-settings/form';

export default function FormFilter() {
  return (
    <FilterContainer>
      <InputGroup
        type="text"
        name="description"
        label="Descrição"
        maxLength={100} />

      <Select
        name="eventType"
        label="Evento"
        options={{
          values: [
            { value: '', label: 'Todos' },
            ..._.map(eventTypes, (props) => ({ value: props.value, label: props.label }))
          ]
        }}
      />

      <Select
        name="scope"
        label="Escopo"
        options={{
          values: [
            { value: '', label: 'Todos' },
            ..._.map(scopeTypes, (label, value) => ({ value, label }))
          ]
        }}
      />
    </FilterContainer>
  );
}
