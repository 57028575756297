import React from 'react';
import { Row } from '~/components/layout';
import { FilterContainer } from '~/components/crud/styles';
import { InputGroup, Select } from '~/components/form';

export default function FormFilter({ values, setFieldValue, onListBankContracts, onListBusinessPartners, onListCompanies }) {
  return (
    <FilterContainer>
      <Row>
        <InputGroup type="text" name="description" label="Descrição" />
      </Row>
      <Row>
        <Select
          name="valueType"
          label="Tipo"
          onChange={evt => {
            setFieldValue('valueType', evt.target.value);
          }}
          options={{
            values: [
              { value: '', label: 'Todos' },
              { value: 'debit', label: 'Débito' },
              { value: 'credit', label: 'Crédito' }
            ]
          }}
        />
      </Row>
    </FilterContainer>
  );
}
