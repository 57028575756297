
import React from 'react';
import _ from 'lodash';
import Modal from '~/components/modal';
import Tabs from '~/components/tabs';
import Fieldset from '~/components/fieldset';
import styled from 'styled-components';
import { eventsMap as billEventsMap } from '~/pages/webhook-bills/form';
import { eventsMap as paymentOrderEventsMap } from '~/pages/webhook-payment-orders/form';

const FormContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 0px 10px;

  pre {
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    width: 100%;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.25;
    font-size: 12px;
    tab-size: 2;
    hyphens: none;
    padding: 10px;
    background: #f0f0f0;
  }
  ul {
    margin-left: 10px;
    list-style-type: none;
  }
  ul > li {
    text-indent: -5px;
    height: 22px;
    strong {
      padding-left: 5px;
    }
  }
  ul > li:before {
    content: "-";
    text-indent: -5px;
  }

  p {
    line-height: 20px;
    font-size: 14px;
    margin: 5px 0;
    font-weight: normal;
  }
`;

const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 300px;
  position: relative;
`;

const billJson = {
  entity: 'bill',
  events: [
    {
      id: 'ID_DA_FATURA',
      event: 'EVENTO_DA_FATURA',
      extras: {
        DatabaseId: 'DADOS_DO_ERP',
        BplId: 'DADOS_DO_ERP',
        TransId: 'DADOS_DO_ERP',
        LineId: 'DADOS_DO_ERP',
        BaseType: 'DADOS_DO_ERP',
        BaseEntry: 'DADOS_DO_ERP',
        BaseLine: 'DADOS_DO_ERP',
        DocNum: 'DADOS_DO_ERP',
        Reference1: 'DADOS_DO_ERP',
        Reference2: 'DADOS_DO_ERP',
        Reference3: 'DADOS_DO_ERP',
      }
    }
  ]
};

const paymentOrderJson = {
  entity: 'payment_order',
  events: [
    {
      id: 'ID_DA_ORDEM_DE_PAGAMENTO',
      event: 'EVENTO_DA_ORDEM_DE_PAGAMENTO',
      extras: {
        DatabaseId: 'DADOS_DO_ERP',
        BplId: 'DADOS_DO_ERP',
        TransId: 'DADOS_DO_ERP',
        LineId: 'DADOS_DO_ERP',
        BaseType: 'DADOS_DO_ERP',
        BaseEntry: 'DADOS_DO_ERP',
        BaseLine: 'DADOS_DO_ERP',
        DocNum: 'DADOS_DO_ERP',
        Reference1: 'DADOS_DO_ERP',
        Reference2: 'DADOS_DO_ERP',
        Reference3: 'DADOS_DO_ERP',
      }
    }
  ]
};

const TabBills = () => {
  return (
    <TabContainer>
      <pre>{JSON.stringify(billJson, null, 2)}</pre>

      <p> Os eventos de fatura são: </p>

      <ul>
        {_.map(billEventsMap, (label, id) => (
          <li key={id}>
            <strong>{id}</strong>: {label}
          </li>
        ))}
      </ul>
    </TabContainer>
  );
};

const TabPaymentOrders = () => {
  return (
    <TabContainer>
      <pre>{JSON.stringify(paymentOrderJson, null, 2)}</pre>

      <p> Os eventos de ordem de pagamento são: </p>

      <ul>
        {_.map(paymentOrderEventsMap, (label, id) => (
          <li key={id}>
            <strong>{id}</strong>: {label}
          </li>
        ))}
      </ul>
    </TabContainer>
  );
};

const tabs = [
  { id: 'bills', label: 'Fatura', component: TabBills },
  { id: 'paymentOrders', label: 'Ordem de pagamento', component: TabPaymentOrders },
];

const WebhookEventModal = ({ isOpen, toggleModal }) => {
  return (
    <Modal
      width="700px"
      height="70%"
      title={`Webhook - Eventos`}
      hideClose={true}
      open={isOpen}
      hide={toggleModal}
      actions={[{ label: 'Fechar', action: toggleModal }]}
      >
      <FormContainer>
        <p> O DocPay enviará um JSON via requisições do tipo POST e espera uma resposta (dentro de 3 segundos) com código de status 200, o conteúdo do JSON está detalhado abaixo: </p>

        <Fieldset label="Eventos de webhook">
          <Tabs
            tabs={tabs}
            initialTab={'bills'}
            height="calc(100% - 70px)"
            />
        </Fieldset>

        <br/>
        <br/>
      </FormContainer>
    </Modal>
  )
};

export default WebhookEventModal;
