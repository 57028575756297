import React, { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaUserPlus, FaUserLock } from 'react-icons/fa';
import { MdSave, MdClear, MdCheck } from 'react-icons/md';
import _ from 'lodash';
import FormFilter from '~/pages/representatives/filter';
import { Creators as RepresentativeActions } from '~/store/modules/representative';
import { Creators as HeaderActions } from '~/store/modules/header';
import CellStatus from '~/components/datatable/cell-status';
import { Container } from '~/pages/representatives/styles';
import { RepresentativeForm, RepresentativeSchema } from '~/pages/representatives/form';
import confirm from '~/components/confirm';
import { SubtitleItem, Subtitle } from '~/components/datatable/subtitle';
import useSearchParameters from '~/hooks/use-search-params'
import { red, green } from '~/components/mixins/color';
import Crud from '~/components/crud';
import { formats } from '~/helper';

const columns = [
    {
      name: ' ',
      selector: 'status',
      width: '10px',
      cell: (row) => <CellStatus title="" color={row.active ?  green.hex() : red.hex()} />
    },
    {
      name: 'Nome',
      selector: 'name',
      cell: (row) => _.get(row, 'name')
    },
    {
      name: 'E-mail',
      selector: 'email',
      cell: (row) => _.get(row, 'email')
    },
    {
      name: 'Cadastrado em',
      selector: 'createdAt',
      hide: 'md',
      width: '140px',
      format: (row) => formats.dateTimeZone(_.get(row, 'createdAt'), 'dd/MM/yyyy HH:mm')
    }
  ]

function Representative({ history, match, acls }) {
  const dispatch = useDispatch();
  const state = useSelector(state => state.representative);
  const headerState = useSelector(state => state.header);
  const canWrite = acls.includes('W');
  const [openForm, setOpenForm] = useState(!!_.get(match, 'params.id'));
  const showFilter = headerState.filter.visible;

  const {
    location,
    routeParams,
    getAllParams,
    toStringify,
  } = useSearchParameters()

  useLayoutEffect(() => {
    const config = {
      loading: state.loading,
      useFilter: true,
      filter: {
        ...headerState.filter,
        visible: false,
      },
    }
    dispatch(HeaderActions.configure(config))
    // eslint-disable-next-line
  }, [])

  useLayoutEffect(() => {
    const { id, offset = 0, limit = 20, ...filter } = getAllParams()

    const listMethod =
      _.isEmpty(id) || _.size(_.get(state, 'data.rows')) === 0
        ? RepresentativeActions.list
        : null

    if (id) {
      dispatch(RepresentativeActions.load({ id }))
    }

    dispatch(
      HeaderActions.callFilter(
        { ...filter, offset: _.toInteger(offset), limit: _.toInteger(limit) },
        listMethod,
      ),
    )
    // eslint-disable-next-line
  }, [location, routeParams])


  function handleOnSubmit(data, actions) {
    dispatch(RepresentativeActions.createOrUpdate(data, actions));
  };

  async function handlePasswordReset(data = {}, actions) {
    const result = await confirm.show({
        title: 'Atenção',
        text: `Deseja realmente redefinir a senha do representante "${_.get(data, 'name', '')}"?`
      });

    if (result) {
      dispatch(RepresentativeActions.resetPassword(data, actions));
    }
  };

  function handleLoad(data) {
    const filter = getAllParams(),
      id = _.get(data, 'id') || 'new'

    if (id === 'new') {
      delete filter.id
    }

    history.push({
      pathname: `/representatives/${id}`,
      search: `?${toStringify(
        _.omit(filter, [
          'id'
        ]),
      )}`,
      state: filter,
    })

    setOpenForm(true)
  };

  function handlePageChange(offset = 0, limit = 20) {
    const filter = getAllParams()
    history.push({
      pathname: '/representatives',
      search: `?${toStringify({
        ..._.omit(filter, [
          'id'
        ]),
        offset,
        limit,
      })}`,
      state: { ..._.omit(filter, 'id'), offset, limit },
    })
    setOpenForm(false)
  };

  function handleHideFilter() {
    dispatch(HeaderActions.hideFilter())
    setOpenForm(false)
  };

  function handleFilter(filter) {
    const historySearch = {
      ...filter,
      offset: 0,
    }
    handleHideFilter()
    history.push({
      pathname: `/representatives`,
      search: `?${toStringify(historySearch)}`,
      state: filter,
    })
  };

  async function handleCreateUser(data, actions) {
    const result = await confirm.show({
        title: 'Atenção',
        text: `Deseja realmente cadastrar um usuário para o representante e enviar os dados de acesso por e-mail?`
      });

    if (result) {
      dispatch(RepresentativeActions.createUser(data, actions));
    }
  };

  function getActions() {
    let actions = [
        {
          label: 'Salvar',
          icon: MdSave,
          isSubmit: true,
          isDisabled: () => !canWrite,
          action: handleOnSubmit
        }
      ];

    if (_.get(state, 'model.id') && !_.get(state, 'model.userId')) {
      actions.unshift({
        label: 'Cadastrar usuário',
        icon: FaUserPlus,
        isDisabled: ({ values }) => !values.email,
        action: handleCreateUser
      });
    } else if (_.get(state, 'model.id') && _.get(state, 'model.userId')) {
      actions.unshift({
        label: 'Redefinir senha',
        icon: FaUserLock,
        isDisabled: ({ values }) => !values.email,
        action: handlePasswordReset
      });
    }

    return actions;
  };

  return (
    <Container>
      {!openForm && !showFilter &&
        <Subtitle bottom={25} left={20}>
          <span>Legenda:</span>
          <SubtitleItem color={green.hex()}>Ativo</SubtitleItem>
          <SubtitleItem color={red.hex()}>Inativo</SubtitleItem>
        </Subtitle>
      }

      <Crud
        useOpenForm={true}
        openForm={openForm || showFilter}
        hideAdd={!canWrite}
        columns={columns}
        emptyText="Nenhum representante encontrado"
        formTitle={data => showFilter ? `Filtro` : ((_.get(data, 'id')) ? `Representante (${data.name})` : `Novo representante`)}
        data={state.data}
        tableLoading={state.loading}
        onCloseFilter={handleHideFilter}
        formLoading={state.formLoading}
        onChangePage={handlePageChange}
        onRowClicked={handleLoad}
        formOptions={{
          initialValues: showFilter ? headerState.filter.data : (state.model || {}),
          validationSchema: showFilter ? undefined : RepresentativeSchema,
          initialTouched: showFilter ? {} : { permissions: true }
        }}
        renderForm={args => showFilter ?
          <FormFilter
            {...args}
            />
          :
          <RepresentativeForm
            {...args}
            previewMode={!canWrite}
            />
        }
        actions={showFilter ?
          [
            { label: 'Limpar Filtro', icon: MdClear, action: () => handleFilter({}) },
            { label: 'Aplicar Filtro', icon: MdCheck, action: data => handleFilter(data) }
          ]
          :
          getActions()
        }
        />
    </Container>
  );
}

export default Representative;
