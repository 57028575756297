import styled from 'styled-components';
import { Form } from 'formik';

export const Container = styled.div`
  height: 100%;
  max-height: ${props => props.maxHeight};
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 10px;
  position: relative;

  @media (max-width: 425px) {
    max-height: 100%;
  }
`;

export const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100%);
  padding: 10px;
  overflow-y: auto;
  overflow-x: hidden;
`;
