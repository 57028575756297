import { ErrorMessage, FieldArray } from 'formik';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Fieldset from '~/components/fieldset';
import { secondary } from '~/components/mixins/color';
import { getSideBarData } from '~/store/modules/auth/helper';

const PermissonRow = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  height: auto;
  min-height: 26px;
  line-height: 20px;
  background: ${(props) => !props.even ? '#fafafa' : 'transparent' };
  .group-sub {
    padding-left: 25px;
  }
  div {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-end;
  }
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .group-sub {
    padding-left: 25px;
  }
  .group-box {
    display: flex;
    flex-direction: column;
    border: 1px solid ${secondary.hex()};
    padding: 0;
    width: 100%;
    height: 100%;
    margin-top: 10px;
    legend {
      color: #666;
      padding: 0;
      font-size: 14px;
      height: 25px;
      line-height: 25px;
      label {
        padding-left: 7px;
      }
    }
  }

  label {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 8px;
    text-indent: 0px;
    color: #4A4C50;
    line-height: 20px;
    width: 100%;
  }
  input[type='checkbox'] {
    width: 13px;
    height: 13px;
    padding: 0;
    margin: 0 5px 0 0;
    vertical-align: bottom;
    position: relative;
    top: -1px;
    overflow: hidden;
  }

`;

const Box = styled.div`
  overflow-y: auto;
  height: ${(props) => props.height};
`;

const PermissionBox = ({ rule, values, sub, name, index, form, permsNames, disabled }) => {
  return (
    <PermissonRow even={index % 2 === 0}>
      <label className={sub ? 'group-sub' : ''}>
        <input
          type='checkbox'
          disabled={disabled}
          name={`${name}.${index}`}
          checked={!!_.find(values, { code: rule.code })}
          onChange={async (event) => {
            let valuesRef = _.cloneDeep(values);
            if (event.target.checked) {
              valuesRef.push({
                code: rule.code,
                perms: rule.permissions || [],
              });
            } else {
              valuesRef = _.filter(
                valuesRef,
                (r) => r.code !== rule.code
              );
            }
            form.setFieldValue(name, valuesRef);
            form.validateForm();
          }}
        />
        {rule.title}
      </label>
      <FieldArray>
        {({ push, remove, form }) => (
          <div>
            {_.map(rule.permissions, (perm, pIndex) => (
              <label key={pIndex}>
                <input
                  type='checkbox'
                  disabled={disabled}
                  checked={(
                    _.get(
                      _.find(values, { code: rule.code }),
                      `perms`
                    ) || []
                  ).includes(perm)}
                  onChange={async (event) => {
                    const idxParent = _.findIndex(values, {
                      code: rule.code,
                    });
                    let valuesRef = _.cloneDeep(values);

                    if (idxParent >= 0) {
                      valuesRef[idxParent].perms =
                        valuesRef[idxParent].perms || [];

                      if (event.target.checked) {
                        valuesRef[idxParent].perms.push(perm);
                      } else {
                        valuesRef[idxParent].perms = _.filter(
                          valuesRef[idxParent].perms,
                          (r) => r !== perm
                        );
                      }
                      form.setFieldValue(name, valuesRef);
                      form.validateForm();
                    }
                  }}
                />
                {permsNames[perm] || perm}
              </label>
            ))}
          </div>
        )}
      </FieldArray>
    </PermissonRow>
  );
};

const GroupCheckbox = (props) => {
  const { label, name, values, allowedValues, permsNames, disabled, maxHeight = '100%' } = props,
    groups = _.filter(getSideBarData(allowedValues), null),
    selecteds = _.map(values, 'code');

  return (
    <Container>
      <Fieldset label={label}>
        <Box height={maxHeight}>
          <FieldArray id={name} name={name}>
            {({ form }) => (
              <div>
                {_.map(groups, (group, gIndex) => (
                  (!group.subNav) ?
                    <PermissionBox
                      key={gIndex}
                      index={gIndex}
                      name={name}
                      sub={false}
                      rule={group}
                      values={values}
                      form={form}
                      permsNames={permsNames}
                      disabled={disabled}
                      />
                   :
                   <fieldset key={gIndex} className="group-box">
                      <legend>
                        <label>
                          <input
                            type='checkbox'
                            disabled={disabled}
                            checked={_.isEmpty(_.difference(_.map(group.subNav, 'code'), selecteds))}
                            onChange={async (event) => {
                              let valuesRef = _.cloneDeep(values);
                              if (event.target.checked) {
                                _.each(group.subNav, (r) => {
                                  valuesRef.push({
                                    code: r.code,
                                    perms: r.permissions || [],
                                  });
                                });
                              } else {
                                let groupCodes = _.map(group.subNav, 'code');
                                valuesRef = _.filter(valuesRef, (r) => !groupCodes.includes(r.code));
                              }
                              form.setFieldValue(name, valuesRef);
                              form.validateForm();
                            }}
                          />
                          {group.title}
                        </label>
                      </legend>
                      {_.map(group.subNav || [], (rule, index) => (
                        <PermissionBox
                          key={index}
                          index={index}
                          name={name}
                          sub={true}
                          rule={rule}
                          values={values}
                          form={form}
                          permsNames={permsNames}
                          disabled={disabled}
                          />
                      ))}
                   </fieldset>
                ))}
              </div>
            )}
          </FieldArray>
        </Box>
      </Fieldset>
      <ErrorMessage name={props.name} className={'error'} component='div' />
    </Container>
  );
};

GroupCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  hasError: PropTypes.bool,
  values: PropTypes.array.isRequired,
  allowedValues: PropTypes.array.isRequired,
};

GroupCheckbox.defaultProps = {
  hasError: false,
  values: [],
  allowedValues: {},
};

export default GroupCheckbox;
