import React from 'react';
import * as Yup from 'yup';
import _ from 'lodash';
import { InputGroup } from '~/components/form';
import { FormContainer } from '~/pages/users/styles';
import Select from 'react-select';
import { primary, secondary } from '~/components/mixins/color';
import { Row } from '~/components/layout';

const customStyles = {
  control: (base, { isFocused, isSelected, isDisabled }) => ({
    ...base,
    border: `1px solid ${secondary.hex()}`,
    height: '40px',
    color: 'white',
    borderRadius: '3px',
    marginBottom: '10px',
    '&:focus': {
      borderColor: primary.hex()
    },
    '&:hover': {
      borderColor: primary.hex()
    }
  })
};

export const PrepareBankStatementForm = ({ errors, touched, values, previewMode, setFieldValue, optionsClassification = [] }) => {
  return (
    <FormContainer>
      <Row>
        <InputGroup
          disabled={previewMode}
          type="text"
          name="description"
          label="Descrição"
          maxLength={200}
          hasError={errors.description && touched.description}
        />
      </Row>

      <Row>
        <Select
          disabled={previewMode}
          isClearable={true}
          isSearchable={true}
          menuPortalTarget={document.body}
          menuPosition="fixed"
          menuPlacement="auto"
          menuContainerStyle={{ top: 'auto', bottom: '100%' }}
          styles={customStyles}
          value={_.get(values, 'bankStatementClassification')}
          name="bankStatementClassification"
          label="Classificação"
          placeholder="Selecione a classificação"
          options={optionsClassification}
          onChange={value => {
            setFieldValue('bankStatementClassification', value);
          }}
        />
      </Row>

      <Row>
        <Select
          disabled={previewMode}
          isClearable={true}
          isSearchable={true}
          menuPortalTarget={document.body}
          menuPosition="fixed"
          menuPlacement="auto"
          menuContainerStyle={{ top: 'auto', bottom: '100%' }}
          styles={customStyles}
          value={_.get(values, 'valueType') || 'both'}
          name="valueType"
          label="Tipo"
          placeholder="Selecione o tipo de movimento"
          onChange={value => {
            setFieldValue('valueType', value);
          }}
          options={[
            { value: 'both', label: 'Todos' },
            { value: 'debit', label: 'Débito' },
            { value: 'credit', label: 'Crédito' }
          ]}
        />
      </Row>
    </FormContainer>
  );
};

export const PrepareBankStatementSchema = Yup.object().shape({
  description: Yup.string()
    .min(2, 'Verifique se a descrição está correta')
    .required('Informe a descrição'),
    bankStatementClassification: Yup.mixed().required('Selecione uma classificação')
});
