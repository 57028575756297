import React from 'react';
import _ from 'lodash';
import { InputLabel } from '~/components/form';

export const TabGeneral = ({ values }) => {
  
  return (
    <>
      <InputLabel
        value={_.get(values, 'active') ? 'Sim' : 'Não'}
        label="Ativa" />

      <InputLabel
        value={_.get(values, 'name') || ''}
        label="Nome" />

      <InputLabel
        value={_.get(values, 'email') || ''}
        label="E-mail" />
    </>
  )
};
