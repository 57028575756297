import { createActions, createReducer } from 'reduxsauce';
import { allowedOrdersTypes } from '~/pages/payment-orders/constants';
import produce from 'immer';
import _ from 'lodash';

export const { Types, Creators } = createActions(
  {
    list: ['params'],
    listSuccess: ['data'],
    load: ['id'],
    loadSuccess: ['model'],
    loadData: [],
    loadDataSuccess: ['data'],
    updateOrdersTypesMap: ['data', 'callback'],
    listPaymentOrderTypes: [],
    listPaymentOrderTypesSuccess: ['data'],
    listBanks: ['params', 'callback'],
    listBankContracts: ['params', 'callback'],
    listCompanies: ['params', 'callback'],
    listBankAccountsGroups: ['params', 'callback'],
    listBusinessPartners: ['term', 'callback'],
    listCities: ['state', 'term', 'callback'],
    listPaymentOrderType: ['term', 'callback'],
    listBusinessPartnerGroup: ['term', 'callback'],
    requestErpData: ['data'],
    createOrUpdate: ['data', 'actions'],
    createOrUpdateSuccess: [],
    unlink: ['data'],
    unlinkSuccess: [],
    cancel: ['data', 'action'],
    cancelSuccess: [],
    cancelBatch: ['data', 'filter'],
    block: ['data'],
    blockSuccess: [],
    unblock: ['data'],
    unblockSuccess: [],
    getPixQrCodeData: ['code', 'model'],
    getPixQrCodeDataSuccess: ['model', 'data'],
    openBusinessPartnerModal: [],
    closeBusinessPartnerModal: [],
    saveBusinessPartnerModal: ['data', 'model'],
    saveBusinessPartnerModalSuccess: ['data', 'model'],
    searchCnpjRequest: ['cnpj', 'model'],
    searchCnpjResult: ['data', 'model'],
    downloadFile: ['data'],
    changeSelectPayments: ['data'],
    changeScheduledDate: ['data', 'scheduledDate', 'actions'],
    sendEmail: ['data'],
    sendEmailSuccess: ['data'],
    reprocessErp: ['data'],
    reopenStatus: ['data'],
    reopenStatusSuccess: ['data'],
    reopenBatch: ['data', 'filter'],
    blockUnblockBatch: ['data', 'filter'],
    processInstruction: ['data', 'action'],
    processInstructionSuccess: []
  },
  { prefix: 'paymentOrder' }
);

const INITIAL_STATE = {
  loading: false,
  formLoading: false,
  isOpenBusinessPartnerModal: false,
  isLoadingBusinessPartnerModal: false,
  data: {},
  model: {},
  businessPartner: {},
  ordersTypesMap: {},
  paymentOrderTypes: [],
  selecteds: []
};

const handleList = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.loading = true;
    draft.data = { rows: [], count: 0, offset: 0 };
    draft.model = {};
    draft.selecteds = [];
  });

const handleListSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.loading = false;
    draft.data = _.get(action, 'data');
  });

const handleLoad = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.model = {};
    draft.formLoading = true;
  });

const handleLoadSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.formLoading = false;
    let model = _.get(action, 'model');

    let typeCode = _.get(model, 'paymentOrderType.code'),
      ordersType = _.get(model, 'bankContract.bankAccount.bank.settings.payment.ordersType'),
      ordersTypesObject = allowedOrdersTypes(ordersType),
      ordersTypeOpts = _.get(ordersTypesObject, `${typeCode}`) || {},
      defaults = ordersTypeOpts.defaults || {},
      entryTypes = ordersTypeOpts.entryType || [],
      serviceTypes = ordersTypeOpts.serviceType || [];

    model.entryType = model.entryType || '';
    model.serviceType = model.serviceType || '';

    // valores padrão
    if (defaults.serviceType && !model.serviceType) {
      model.serviceType = defaults.serviceType;
    }
    if (defaults.entryType && !model.entryType) {
      model.entryType = defaults.entryType;
    }
    // modo preguiça do usuário
    if (!model.entryType && typeCode === 10) {
      let cmpIdentity = _.get(model, 'company.identity'),
        bpIdentity = _.get(model, 'businessPartner.identity');

      if (cmpIdentity && bpIdentity) {
        model.entryType = bpIdentity === cmpIdentity ? '43' : '41';
      }
    } else if (!model.entryType && typeCode === 20) {
      let bankCode = _.get(model, 'bankAccount.bank.code'),
        barcode = _.get(model, 'orderData.barcode');

      if (bankCode && barcode) {
        model.entryType = bankCode === String(barcode).substring(0, 3) ? '30' : '31';
      }
    }
    // validação
    if (!_.find(entryTypes, { value: model.entryType })) {
      model.entryType = '';
    }
    if (!_.find(serviceTypes, { value: model.serviceType })) {
      model.serviceType = '';
    }

    draft.model = model;
    draft.ordersTypesMap = ordersTypesObject;
  });

const updateOrdersTypesMap = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.ordersTypesMap = allowedOrdersTypes(_.get(action, 'data'));

    action.callback && action.callback(draft.ordersTypesMap);
  });

const handleLoadData = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.formLoading = true;
  });

const handleLoadDataSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.formLoading = false;
    _.each(_.get(action, 'data'), (value, key) => {
      draft[key] = value;
    });
  });

const handleCreateOrUpdate = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.formLoading = true;
  });

const handleCreateOrUpdateSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.formLoading = false;
  });

const handleGetPixQrCodeData = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.formLoading = true;
  });

const handleGetPixQrCodeDataSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.formLoading = false;
    let model = _.get(action, 'model');
    _.set(model, 'orderData.pixUrl', _.get(action, 'data.pixUrl'));
    _.set(model, 'orderData.transactionId', _.get(action, 'data.txId'));

    draft.model = model;
  });

const handleListPaymentOrderTypes = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.loading = true;
    draft.paymentOrderTypes = [];
  });

const handleListPaymentOrderTypesSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.loading = false;
    draft.paymentOrderTypes = _.get(action, 'data');
  });

const handleListPaymentOrderType = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    let termRegex = new RegExp(_.deburr(action.term), 'i'),
      filtered = _.filter(state.paymentOrderTypes, r => {
        return termRegex.test(r.name);
      });
    action.callback(filtered);
  });

const openBusinessPartnerModal = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.businessPartner = { active: true, identityType: 'company' };
    draft.isOpenBusinessPartnerModal = true;
  });

const closeBusinessPartnerModal = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.isOpenBusinessPartnerModal = false;
  });

const saveBusinessPartnerModal = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.isLoadingBusinessPartnerModal = true;
  });

const saveBusinessPartnerModalSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.isOpenBusinessPartnerModal = false;
    draft.isLoadingBusinessPartnerModal = false;
    let model = _.get(action, 'model');
    _.set(model, 'businessPartner', _.pick(_.get(action, 'data'), 'id', 'identity', 'name', 'tradeName'));

    draft.model = model;
    draft.businessPartner = {};
  });

const searchCnpjRequest = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.isLoadingBusinessPartnerModal = true;
  });

const searchCnpjResult = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.isLoadingBusinessPartnerModal = false;
    draft.businessPartner = _.get(action, 'model', {});
    draft.businessPartner.identity = _.get(action, 'data.identity');
    draft.businessPartner.name = _.get(action, 'data.name');
    draft.businessPartner.tradeName = _.get(action, 'data.tradeName');
    draft.businessPartner.phone = _.get(action, 'data.phone');
    draft.businessPartner.email = _.get(action, 'data.email');
    draft.businessPartner.address = _.get(action, 'data.address');
  });

const handleCancel = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.formLoading = true;
  });

const handleCancelSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.formLoading = false;
  });

const handleBlock = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.formLoading = true;
  });

const handleBlockSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.formLoading = false;
  });

const handleUnBlock = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.formLoading = true;
  });

const handleUnBlockSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.formLoading = false;
  });

const handleUnlink = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.formLoading = true;
  });

const handleUnlinkSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.formLoading = false;
  });

const changeSelectPayments = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.selecteds = action.data;
  });

const sendEmailSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.loading = false;
    draft.selecteds = [];
  });

const reopenStatus = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.formLoading = true;
  });

const reopenStatusSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.formLoading = false;

    const model = {
      ..._.get(action, 'data'),
      status: 'pending'
    };

    draft.model = model;

  });

const processInstruction = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.modalLoading = true;
  });

const processInstructionSuccess = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.modalLoading = false;
  });

export default createReducer(INITIAL_STATE, {
  [Types.LIST]: handleList,
  [Types.LIST_SUCCESS]: handleListSuccess,
  [Types.LOAD]: handleLoad,
  [Types.LOAD_SUCCESS]: handleLoadSuccess,
  [Types.LOAD_DATA]: handleLoadData,
  [Types.LOAD_DATA_SUCCESS]: handleLoadDataSuccess,
  [Types.UPDATE_ORDERS_TYPES_MAP]: updateOrdersTypesMap,
  [Types.CREATE_OR_UPDATE]: handleCreateOrUpdate,
  [Types.CREATE_OR_UPDATE_SUCCESS]: handleCreateOrUpdateSuccess,
  [Types.CANCEL]: handleCancel,
  [Types.CANCEL_SUCCESS]: handleCancelSuccess,
  [Types.BLOCK]: handleBlock,
  [Types.BLOCK_SUCCESS]: handleBlockSuccess,
  [Types.UNBLOCK]: handleUnBlock,
  [Types.UNBLOCK_SUCCESS]: handleUnBlockSuccess,
  [Types.UNLINK]: handleUnlink,
  [Types.UNLINK_SUCCESS]: handleUnlinkSuccess,
  [Types.GET_PIX_QR_CODE_DATA]: handleGetPixQrCodeData,
  [Types.GET_PIX_QR_CODE_DATA_SUCCESS]: handleGetPixQrCodeDataSuccess,
  [Types.LIST_PAYMENT_ORDER_TYPE]: handleListPaymentOrderType,
  [Types.LIST_PAYMENT_ORDER_TYPES]: handleListPaymentOrderTypes,
  [Types.LIST_PAYMENT_ORDER_TYPES_SUCCESS]: handleListPaymentOrderTypesSuccess,
  [Types.OPEN_BUSINESS_PARTNER_MODAL]: openBusinessPartnerModal,
  [Types.CLOSE_BUSINESS_PARTNER_MODAL]: closeBusinessPartnerModal,
  [Types.SAVE_BUSINESS_PARTNER_MODAL]: saveBusinessPartnerModal,
  [Types.SAVE_BUSINESS_PARTNER_MODAL_SUCCESS]: saveBusinessPartnerModalSuccess,
  [Types.SEARCH_CNPJ_REQUEST]: searchCnpjRequest,
  [Types.SEARCH_CNPJ_RESULT]: searchCnpjResult,
  [Types.CHANGE_SELECT_PAYMENTS]: changeSelectPayments,
  [Types.SEND_EMAIL_SUCCESS]: sendEmailSuccess,
  [Types.REOPEN_STATUS]: reopenStatus,
  [Types.REOPEN_STATUS_SUCCESS]: reopenStatusSuccess,
  [Types.PROCESS_INSTRUCTION]: processInstruction,
  [Types.PROCESS_INSTRUCTION_SUCCESS]: processInstructionSuccess,
});
