import styled, { css } from 'styled-components';
import { MdClose } from 'react-icons/md';
import wave from '~/assets/wave.svg';

const controlsCss = css`
  position: absolute;
  cursor: pointer;
  z-index: 4;
  padding: 0.1em;
  transition: 0.3s;
  width: 1.6em;
  :hover {
    transform: scale(1.07);
  }
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: grid;
  visibility: ${props => (props.isOpen ? "visible" : "hidden")};
  opacity: ${props => (props.isOpen ? "1" : "0")};
  transition: 0.1s;
  z-index: 1000;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #ffffff;
  background: #323639;
  font-size: 16px;
  font-weight: 500;
  padding-left: 10px;
  width: 100%;
  height: ${props => (props.children ? "40px" : "0px")};
  visibility: ${props => (props.children ? "visible" : "hidden")};
  div {
    width: calc(100% - 40px);
    max-width: calc(100% - 40px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const Container = styled.div`
  box-sizing: border-box;
  align-self: center;
  justify-self: center;
  background: ${props => props.background || "#fff"};
  width: ${props => (props.width ? props.width : "95%")};
  height: ${props => (props.height ? props.height : "95%")};
  position: relative;
  min-height: 150px;
  min-width: 300px;
  position: relative;
  overflow: hidden;
  border-radius: 0.2em;
  transition: 0.3s;
  color: #ffffff;
  box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
`;

const Content = styled.div`
  width: 100%;
  height: calc(100% - 40px);
  overflow: auto;
  background: url(${wave}) center center no-repeat;
  background-size: auto 40px;

`;

const Close = styled(MdClose)`
  ${controlsCss};
  border-radius: 0.4em;
  top: 0.7em;
  right: 0.5em;
  width: 20px;
  height: 20px;
`;

export { Background, Container, Title, Content, Close };
