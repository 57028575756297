import { createActions, createReducer } from 'reduxsauce';
import produce from 'immer';
import _ from 'lodash';

export const { Types, Creators } = createActions(
  {
    list: ['params'],
    listSuccess: ['data'],
    listPaymentOrderTypes: [],
    listPaymentOrderTypesSuccess: ['data'],
    listBankContracts: ['params', 'callback'],
    listCompanies: ['params', 'callback'],
    listBusinessPartners: ['term', 'callback'],
    listUsers: ['term', 'callback'],
    listPaymentOrderType: ['term', 'callback'],
  },
  { prefix: 'paymentApprovalsPending' }
);

const INITIAL_STATE = {
  loading: false,
  detailLoading: false,
  formLoading: false,
  loadingMessage: 'Carregando ...',
  data: {},
  model: {},
  businessPartner: {},
  ordersTypesMap: {},
  paymentOrderTypes: []
};

const handleList = (state = INITIAL_STATE, action) => produce(state, draft => {
  draft.loading = true;
  draft.data = { rows: [], count: 0, offset: 0 }
  draft.model = {}
});

const handleListSuccess = (state = INITIAL_STATE, action) => produce(state, draft => {
  draft.loading = false;
  draft.data = action.data;
});

const handleListPaymentOrderTypes = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.loading = true;
    draft.paymentOrderTypes = [];
  });

const handleListPaymentOrderTypesSuccess = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.loading = false;
    draft.paymentOrderTypes = _.get(action, 'data');
  });

const handleListPaymentOrderType = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    let termRegex = new RegExp(_.deburr(action.term), 'i'),
      filtered = _.filter(state.paymentOrderTypes, r => {
        return termRegex.test(r.name);
      });
    action.callback(filtered);
  });

export default createReducer(INITIAL_STATE, {
  [Types.LIST]: handleList,
  [Types.LIST_SUCCESS]: handleListSuccess,
  [Types.LIST_PAYMENT_ORDER_TYPES]: handleListPaymentOrderTypes,
  [Types.LIST_PAYMENT_ORDER_TYPES_SUCCESS]: handleListPaymentOrderTypesSuccess,
  [Types.LIST_PAYMENT_ORDER_TYPE]: handleListPaymentOrderType,
});
