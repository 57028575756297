import { all, put, call, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '~/services/api';
import _ from 'lodash';
import { getError } from '~/helper';
import { Creators as CreditsReceivedsActions, Types as CreditsReceivedsTypes } from '~/store/modules/credits-receiveds';

export function showError(error) {
  const msg = getError(error);
  toast.error(msg);
};

export function* list({ params }) {
  try {
    const response = yield call(api.post, 'credits-receiveds/list', params);
    yield put(CreditsReceivedsActions.listSuccess(response.data));
  } catch (error) {
    showError(error);
  }
};

export function* load({ data }) {
  try {
    const response = yield call(api.get, 'credits-receiveds/load', { params: { id: _.get(data, 'id') } });
    yield put(CreditsReceivedsActions.loadSuccess(response.data));
  } catch (error) {
    showError(error);
  }
};

export function* processed({ id, actions }) {
  try {
    yield call(api.post, 'credits-receiveds/processed', { id });
    toast.success(`Crédito recebido processado com sucesso`);
    actions.closeForm && actions.closeForm();
    actions.reloadList && actions.reloadList();
  } catch (error) {
    showError(error);
  }
};

export function* cancel({ id, actions }) {
  try {
    yield call(api.post, 'credits-receiveds/cancel', { id });
    toast.success(`Crédito recebido descartado com sucesso`);
    actions.closeForm && actions.closeForm();
    actions.reloadList && actions.reloadList();
  } catch (error) {
    showError(error);
  }
};

export function* save({ data, actions }) {
  try {
    let response = yield call(api.post, 'credits-receiveds/save', data);

    if (_.get(response, 'data.processed')) {
      toast.success(`Crédito recebido processado com sucesso`);
      actions.closeForm && actions.closeForm();
      actions.reloadList && actions.reloadList();
    } else {
      toast.error('Crédito recebido não processado, tente mais tarde!');
    }
  } catch (error) {
    showError(error);
  }
};

export function* reopen({ data, actions }) {
  try {
    yield call(api.post, 'credits-receiveds/reopen', data);

    toast.success(`Crédito recebido alterado com sucesso`);

    actions.closeForm && actions.closeForm();
    actions.reloadList && actions.reloadList();
  } catch (error) {
    showError(error);
  }
};

export function* listCompanies({ term, callback }) {
  try {
    const response = yield call(api.get, 'credits-receiveds/list-companies', {
      params: { term }
    });
    yield call(callback, response.data);
  } catch (error) {}
};

export function* listBankContracts({ term, callback }) {
  try {
    const response = yield call(api.get, 'credits-receiveds/list-bank-contracts', {
      params: { term }
    });
    yield call(callback, response.data);
  } catch (error) {}
};

export function* listBills({ params }) {
  try {
    const response = yield call(api.post, 'credits-receiveds/list-bills', params);
    yield put(CreditsReceivedsActions.listBillsSuccess(response.data));
  } catch (error) {}
};

export default all([
  takeLatest(CreditsReceivedsTypes.LOAD, load),
  takeLatest(CreditsReceivedsTypes.LIST, list),
  takeLatest(CreditsReceivedsTypes.PROCESSED, processed),
  takeLatest(CreditsReceivedsTypes.CANCEL, cancel),
  takeLatest(CreditsReceivedsTypes.SAVE, save),
  takeLatest(CreditsReceivedsTypes.REOPEN, reopen),
  takeLatest(CreditsReceivedsTypes.LIST_COMPANIES, listCompanies),
  takeLatest(CreditsReceivedsTypes.LIST_BANK_CONTRACTS, listBankContracts),
  takeLatest(CreditsReceivedsTypes.LIST_BILLS, listBills),
]);
