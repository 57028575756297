import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';
import { gray, white, accent, secondary, tertiary } from '~/components/mixins/color';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0px;
`;

const Group = styled.div`
  display: grid;
  position: relative;
  margin: ${props => props.noMargin ? '0' : '0 0 10px'};
  width: 100%;
  min-width: 0px;

  input {
    background: ${secondary.hex()};
    border: 2px solid ${tertiary.hex()};
    border-radius: 2px;
    padding: ${props => !props.noLabel ? '19px 10px 6px 10px' : '12px 10px 12px 10px'};
    height: 46px;
    margin-bottom: ${(props) => props.noMargin ? 0 : 10}px;
    width: 100%;
    color: ${gray.hex()};
    position: relative;
    transition: border-color 0.2s ease-in 0s;

    &:focus-within {
      border-color: ${accent.hex()};
    }

    &:disabled {
      opacity: 0.5;
    }
    & + label {
      font-size: 70%;
      transform: translate3d(0, -100%, 0);
      opacity: 1;
      top: 20px;
      color: ${white.hex()};
      position: absolute;
      top: 15px;
      padding-left: 10px;
      transition: all 200ms;
      color: ${gray.hex()};
      opacity: 0.75;
    }
  }
`;

const hasVal = (val) => {
  return !_.isUndefined(val) && !_.isNull(val) && val !== '';
};

const InputRaw = ({type, value, label, name, noMargin, hasError, ...rest}) => (
  <Container>
    <Group noMargin={noMargin} noLabel={!label}>
      <input
        type={type}
        placeholder={label}
        value={hasVal(value) ? value : ''}
        className={!!hasError ? 'error' : ''}
        {...rest} />
      <label htmlFor={label}>{label}</label>
    </Group>
  </Container>
);

InputRaw.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  hasError: PropTypes.bool,
  noMargin: PropTypes.bool,
}

InputRaw.defaultProps = {
  hasError: false
}

export default InputRaw;
