import React from 'react';
import * as Yup from 'yup';
import _ from 'lodash';
import { Row } from '~/components/layout';
import { InputLabel, TextArea } from '~/components/form';
import { FormContainer } from '~/pages/users/styles';

export const EdiFileForm = ({ errors, status, touched, values, isValid, previewMode, setFieldValue }) => {
  return (
    <FormContainer>
      <InputLabel
        label="CNPJ/CPF" 
        value={_.get(values, 'vatNumber')}
      />
      <InputLabel
        label="Empresa" 
        value={_.get(values, 'company')}
      />
      <InputLabel
        label="Nome do Arquivo" 
        value={_.get(values, 'filename')}
      />

      <Row span={2}>
        <InputLabel
          label="Banco" 
          value={_.get(values, 'bankCode')}
        />
        <InputLabel
          label="Nr.Conta" 
          value={_.get(values, 'account')}
        />
      </Row>

      <Row span={2}>
        <InputLabel
          label="Layout" 
          value={_.get(values, 'layout')}
        />
        <InputLabel
          label="Tipo" 
          value={_.get(values, 'type')}
        />
      </Row>

      <TextArea
        value={_.get(values, 'message')}
        name="message"
        label="Mensagem"
        disabled={true}
        rows={5}
      />
      {status && status.msg && <div>{status.msg}</div>}
    </FormContainer>
  );
};

export const EdiFileSchema = Yup.object().shape({
  filename: Yup.string().typeError('Nome do Arquivo').required('Nome do Arquivo')
});
