import React from 'react';
import * as Yup from 'yup';
import _ from 'lodash';
import { FormContainer } from '~/pages/bank-accounts/styles';
import { Checkbox, InputGroup, Autocomplete, InputLabel, Select } from '~/components/form';
import { FlexRow, FlexCol, BsRow, BsCol } from '~/components/layout';
import { formats } from '~/helper';

export const formatBankAccountGrid = val => {
  let acctData = val || {},
    bankBranch = _.get(acctData, 'bankBranch') || '',
    bankBranchDigit = _.get(acctData, 'bankBranchDigit') || '',
    accountNumber = _.get(acctData, 'accountNumber') || '',
    accountNumberDigit = _.get(acctData, 'accountNumberDigit') || '',
    bankBranchText = bankBranch && bankBranchDigit ? `${bankBranch}-${bankBranchDigit}` : bankBranch ? bankBranch : '',
    accountText = accountNumber && accountNumberDigit ? `${accountNumber}-${accountNumberDigit}` : accountNumber ? accountNumber : '';

  return !bankBranch ? '' : `${bankBranchText} / ${accountText}`;
};

export const formatBankAccountSimple = (r, omitBankName, omitCompany) => {
  let bank = omitBankName ? _.get(r, 'bank.code') : `${_.get(r, 'bank.code')} ${_.get(r, 'bank.name')}`,
    bankBranch = `${_.get(r, 'bankBranch')}-${_.get(r, 'bankBranchDigit')}`,
    accountNumber = `${_.get(r, 'accountNumber')}-${_.get(r, 'accountNumberDigit')}`,
    company = formats.cnpj_cpf(_.get(r, 'company.identity')),
    companyName = _.get(r, 'company.name');
  if (!companyName) {
    return !_.get(r, 'bank.code') ? '' : `${bank} - ${bankBranch} - ${accountNumber}`;
  }
  else {
    return !_.get(r, 'bank.code') ? '' : `${bank} - ${bankBranch} - ${accountNumber} - ${company} - ${companyName}`;
  }
};

export const formatBankAccount = (r, companyAlias) => {
  let bank = `${_.get(r, 'bank.code')} ${_.get(r, 'bank.name')}`,
    bankBranch = `${_.get(r, 'bankBranch')}-${_.get(r, 'bankBranchDigit')}`,
    accountNumber = `${_.get(r, 'accountNumber')}-${_.get(r, 'accountNumberDigit')}`,
    company = `${formats.cnpj_cpf(_.get(r, 'company.identity'))} - ${_.get(r, 'company.name')}`;

  return (
    <span>
      <strong>Banco</strong>: {bank}
      <br />
      <strong>Agência</strong>: {bankBranch}, <strong>Conta</strong>: {accountNumber}
      <br />
      <strong>{companyAlias || 'Empresa'}</strong>: {company}
    </span>
  );
};

export const BankAccountSchema = values =>
  Yup.object().shape({
    bank: Yup.mixed().required('Selecione o banco'),
    company: Yup.mixed().required('Selecione a empresa'),
    bankBranch: Yup.string().required('Informe a agência'),
    bankBranchDigit: Yup.string().required('Informe o dígito'),
    accountNumber: Yup.string().required('Informe o número da conta'),
    accountNumberDigit: Yup.string().required('Informe o dígito')
  });

export const BankAccountForm = ({ errors, touched, setFieldValue, values, previewMode, onListBanks, onListCompanies, onListGroup }) => {
  return (
    <FormContainer>
      <Checkbox name="active" label="Ativo" halfHeight={true} disabled={previewMode || _.get(values, 'unsupported')} />

      <Autocomplete
        name="company"
        keyField="id"
        label="Empresa"
        disabled={previewMode}
        clearable={true}
        value={_.get(values, 'company')}
        valueFormat={row => `${formats.cnpj_cpf(row.identity)} - ${row.name || row.tradeName}`}
        loadData={onListCompanies}
        emptyText={'Pesquise uma empresa'}
        tipText={'Digite... '}
        loadingText={'Carregando...'}
        notFoundText={'Não encontrada'}
      />

      <Autocomplete
        name="bank"
        keyField="id"
        label="Banco"
        disabled={previewMode}
        clearable={true}
        value={_.get(values, 'bank')}
        valueFormat={row => `${row.code} - ${row.name}`}
        loadData={onListBanks}
        emptyText={'Pesquise um banco'}
        tipText={'Digite... '}
        loadingText={'Carregando...'}
        notFoundText={'Não encontrado'}
      />

      {_.get(values, 'bank') && <InputLabel value={_.get(values, 'bank.onlyApi') ? 'Sim' : 'Não'} label="Banco Digital" />}

      <FlexRow>
        <FlexCol flex="1">
          <InputGroup
            type="text"
            name="bankBranch"
            label="Número da agência"
            maxLength={10}
            disabled={previewMode}
            hasError={_.get(errors, 'bankBranch') && _.get(touched, 'bankBranch')}
          />
        </FlexCol>

        <FlexCol flex="0 0 150px">
          <InputGroup
            type="text"
            name="bankBranchDigit"
            label="Digito da agência"
            maxLength={2}
            disabled={previewMode}
            hasError={_.get(errors, 'bankBranchDigit') && _.get(touched, 'bankBranchDigit')}
          />
        </FlexCol>
      </FlexRow>

      <FlexRow>
        <FlexCol flex="1">
          <InputGroup
            type="text"
            name="accountNumber"
            label="Número da conta"
            maxLength={20}
            disabled={previewMode}
            hasError={_.get(errors, 'accountNumber') && _.get(touched, 'accountNumber')}
          />
        </FlexCol>

        <FlexCol flex="0 0 150px">
          <InputGroup
            type="text"
            name="accountNumberDigit"
            label="Digito da conta"
            maxLength={2}
            disabled={previewMode}
            hasError={_.get(errors, 'accountNumberDigit') && _.get(touched, 'accountNumberDigit')}
          />
        </FlexCol>

        {
          _.get(values, 'bank.code') === '104' &&

          <FlexCol flex="0 0 150px">
            <InputGroup
              type="text"
              name="operationNumber"
              label="Operação"
              maxLength={3}
              disabled={previewMode}
              hasError={_.get(errors, 'operationNumber') && _.get(touched, 'operationNumber')}
            />
          </FlexCol>
        }
      </FlexRow>

      <BsRow>
        <BsCol md={24} lg={12}>
          <InputGroup
            type="text"
            name="surName"
            label="Apelido"
            maxLength={30}
            disabled={previewMode}
          />
        </BsCol>
        <BsCol md={24} lg={12}>
          <Autocomplete
            name="group"
            keyField="group"
            label="Grupo"
            disabled={previewMode}
            clearable={true}
            value={_.get(values, 'group')}
            valueFormat={row => `${row.group}`}
            loadData={onListGroup}
            emptyText={'Grupo'}
            tipText={'Digite... '}
            loadingText={'Carregando...'}
            notFoundText={'Não encontrado'}
            allowAdd={true}
          />
        </BsCol>
      </BsRow>

      <BsRow>
        <BsCol md={24} lg={12}>
          <Select
            name="autoProcessBankPages"
            label="Processar extrato bancário automaticamente"
            disabled={previewMode}
            options={{
              values: [
                { value: 'false', label: 'Não' },
                { value: 'true', label: 'Sim' }
              ]
            }}
          />
        </BsCol>
      </BsRow>

    </FormContainer>
  );
};
