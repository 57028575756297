import _ from 'lodash';

const prepareData = (values) => {
  return _.map((values), (r) => ({
      title: _.get(r, 'label'),
      ..._.pick(r, 'path', 'icon', 'id', 'code', 'perms', 'permissions'),
      group: r.group || _.kebabCase(r.path)
    }));
};

export const getSideBarData = (menu, user) => {
  let data = [];

  _.each(_.groupBy(menu, 'group'), (values, title) => {
    if (title === 'undefined') {
      data = data.concat(prepareData(values));
    } else {
      data.push({
        title,
        group: _.kebabCase(title),
        icon: _.get(values, '[0].groupIcon'),
        subNav: prepareData(values)
      });
    }
  });

  if (user) {
    data.unshift({ title: 'Página inicial', icon: 'MdHome', group: 'home', path: '/home', noBreadcrumb: true });
    if (_.get(user, 'account.accountType') !== 'client') {
      data.push({ title: 'Ajuda', icon: 'MdHelp', group: 'help', path: '/help' });
    }
  }
  return data;
};
