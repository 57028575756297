import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import actionImg from '~/assets/action.svg';
import diagramImg from '~/assets/diagram.png';
import howItWorkImg from '~/assets/how-it-work.png';
import Logo from '~components/logo';
import useModal from '~/hooks/use-modal';
import LoginForm from '~/pages/landing/login-form';
import SignUpForm from '~/pages/landing/signup-form';
import { MdArrowDropUp } from 'react-icons/md';
import { FaGlobe, FaInstagram, FaFacebook, FaTwitter, FaYoutube, FaLinkedin } from 'react-icons/fa';
import { Contents, Container, Lane, LaneContents, Navbar, SignButton, BackToTop, Version } from '~pages/landing/styles';
import { Creators as AuthActions } from '~/store/modules/auth';
import { Creators as AccountActions } from '~/store/modules/account';
import { useHistory } from 'react-router-dom';

export default function Landing({ headLess }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoginOpen, toggleLoginModal] = useModal();
  const [isLoginMode, changeLoginMode] = useState(true);
  const [isSignUpOpen, toggleSignUpModal] = useModal();

  const handleLoginSubmit = (data, action) => {
    dispatch(AuthActions.login(data, action));
  };

  const handleSignUpSubmit = (data, action) => {
    dispatch(AccountActions.signUp(data, { ...action, toggleModal: toggleSignUpModal }));
  };

  const redefinePassword = (data, action) => {
    dispatch(AuthActions.redefinePassword(data, { ...action, toogleForm: () => changeLoginMode(true) }));
  };

  if (headLess) {
    return (
      <Container>
        <LoginForm
          isOpen={true}
          headLess={headLess}
          toggleModal={toggleLoginModal}
          handleOnSubmit={handleLoginSubmit}
          redefinePassword={redefinePassword}
          isLoginMode={isLoginMode}
          changeLoginMode={changeLoginMode}
        />
      </Container>
    );
  }

  return (
    <Container>
      <Navbar>
        <Logo height="38px" />

        <div className="links">
          <a href="#about">O que é?</a>
          <a href="#how-it-works">Como funciona</a>
          <a href="#registered">Boleto Registrado</a>
          <a href="#features">Funcionalidades</a>
          <a href="#who-we-are">Quem somos</a>
          <a href="#contact">Contato</a>
        </div>

        <div>
          <SignButton onClick={() => history.push('/login')}> Entrar </SignButton>
        </div>
      </Navbar>

      <Contents>
        <Version>Versão: v{process.env.REACT_APP_VERSION}</Version>
        <Lane id="app" inverse={true} style={{ height: '100%' }}>
          <LaneContents style={{ height: '100%' }}>
            <div className="main">
              <div className="block">
                <div>
                  <h2>Sua nova plataforma de boletos</h2>
                  <h4>Tenha boletos integrados ao seu ERP</h4>
                </div>

                <p>Plataforma simplificada de Gestão de Boletos</p>
              </div>
              <img className="action" src={actionImg} alt="action" />
            </div>
          </LaneContents>
        </Lane>

        <Lane id="about" inverse={false}>
          <LaneContents>
            <h1>O que é?</h1>

            <h3 className="about-title">
              Uma plataforma completa para gestão dos seus boletos registrados. Para quem possui uma conta bancária habilitada a emitir boletos junto ao banco.
            </h3>

            <div className="about">
              <div className="about-diagram">
                <img alt="diagram" src={diagramImg} />
              </div>

              <div className="about-list">
                <h5>Dinheiro rápido na sua conta</h5>
                <p>
                  Você já negociou a melhor taxa com seu banco, agora é só receber seus pagamentos via boleto direto na sua conta bancária e sem intermediários.
                </p>
                <h5>Informações automáticas</h5>
                <p>Chega de trabalho manual. Crie ou agende os boletos e o sistema automaticamente registra seus boletos.</p>
                <h5>Digital, disponível e independente</h5>
                <p>
                  Mantenha o histórico da sua cobrança independente do banco que você esteja utilizando. Todos seus boletos estarão disponíveis, mesmo após
                  realização da baixa no banco.
                </p>
                <h5>Cobrança ágil</h5>
                <p>Otimize sua cobrança disponibilizando a 2ª via do boleto atualizado.</p>
              </div>
            </div>
          </LaneContents>
          <BackToTop href="#app">
            {' '}
            <MdArrowDropUp />{' '}
          </BackToTop>
        </Lane>

        <Lane id="how-it-works" inverse={true}>
          <LaneContents>
            <h1>Como funciona</h1>

            <div className="how-it-works-image">
              <img alt="how-it-works" src={howItWorkImg} />
            </div>

            <div className="how-it-works-contents">
              O <strong>DocPay</strong> não faz a intermediação do pagamento, por isso é necessário que você já possua uma conta bancária autorizada a emitir
              boletos junto ao banco. Ou seja, é necessário ter um convênio/contrato de cobrança com o banco. Se a sua conta ainda não está pronta para receber
              pagamentos via boleto, contrate o serviço através dos canais de atendimento do banco ou diretamente com seu gerente e nesse caso não esqueça de
              negociar as melhores taxas.
              <br />
              <br />
              Sim, negocie taxas melhores! Hoje existe banco que está oferecendo pacote de boletos grátis, como o <strong>Sklbank</strong>!. Por isso, pesquise
              bem antes de escolher o pacote de cobrança do banco.
            </div>
          </LaneContents>
          <BackToTop href="#app">
            {' '}
            <MdArrowDropUp />{' '}
          </BackToTop>
        </Lane>

        <Lane id="registered" inverse={false}>
          <LaneContents>
            <h1>Boleto Registrado</h1>

            <div className="registered" style={{ color: '#555' }}>
              <h3>Boletos com mais informações</h3>
              <p>os registrados precisam apresentar tanto o CPF ou CNPJ do comprador quanto do vendedor. Essa medida garante uma maior transparência.</p>

              <h3>Boletos mais seguros</h3>
              <p>
                Uma maior segurança também é uma das vantagens de emitir boletos com registro, pois isso evita que ocorram fraudes, o que é bem comum, causando
                prejuízos aos empresários. Essa segurança acontece porque com o registro o banco tem maior controle sobre os boletos.
                <br />A instituição bancária da sua empresa se torna responsável pela emissão do boleto bancário, bem como pelo gerenciamento desses documentos.
                Por sua vez, o empresário deve só determinar a data limite de cada pagamento. E se o boleto não é pago, a dívida é protestada.
              </p>

              <h3>Cadastro de boleto como débito automático</h3>
              <p>
                Mais uma facilidade e, por isso, um benefício do boleto bancário registrado é que ele pode ser cadastrado como débito automático. Essa
                possibilidade é, cada vez mais, aceita pelos consumidores, que têm a sua vida descomplicada.
                <br />
                Isso acontece porque o pagamento é cobrado diretamente na sua conta. Para quem vende, essa medida é ainda mais vantajosa, uma vez que são
                reduzidas as chances dos clientes esquecerem de pagar. Reduzindo, assim, o número de clientes inadimplentes da sua empresa.
              </p>

              <h3>Boletos não pagos podem ser cancelados</h3>
              <p>
                Já que são os bancos nos quais você tem conta que gerenciam os boletos registrados emitidos pela sua empresa, são eles que o atualizam sobre os
                documentos que ainda não foram pagos. A partir disso, você pode buscar os seus clientes para propor uma negociação.
              </p>

              <h3>Melhores taxas de boleto no longo prazo</h3>
              <p>
                Com o boleto bancário registrado, os bancos oferecem mais serviços aos seus clientes e, por isso, há mais taxas. No entanto, com o passar do
                tempo, se a sua empresa tiver um bom histórico de emissões e compensações de boletos é possível negociar os preços das taxas. Tornando-as mais
                vantajosas para o seu negócio.
              </p>
            </div>
          </LaneContents>
          <BackToTop href="#app">
            {' '}
            <MdArrowDropUp />{' '}
          </BackToTop>
        </Lane>

        <Lane id="features" inverse={true}>
          <LaneContents>
            <h1>Funcionalidades</h1>

            <div className="features">
              <h3>Emissão simples e rápida</h3>
              <p>
                Perdendo tempo emitindo boleto para seu cliente? Gere Boletos, envie por e-mail e disponibilize a 2ª via online com apenas 1 clique. Agora você
                tem um sistema grátis gerador de boletos digitais que otimiza seu tempo e oferece comodidade aos seus clientes.
              </p>

              <h3>Segunda via rápida</h3>
              <p>
                Complicado para seu cliente obter o boleto correto para pagamento? Não importa se o boleto foi perdido, extraviado ou se passou do vencimento, a
                segunda via estará sempre disponível online e atualizada com o cálculo de multa e juros para o caso de boletos em atraso.
              </p>

              <h3>Personalização do boleto</h3>
              <p>
                Que tal fortalecer sua marca e promover seu negócio? Aprimore seu profissionalismo e a experiência do seu cliente personalizando seu boleto e
                e-mail de cobrança. Comunique melhor sua marca, promova seus serviços/produtos, ofereça maior confiança para seu cliente. Clique aqui e veja
                como alguns de nossos clientes tornaram seus boletos mais únicos e profissionais.
              </p>

              <h3>Informações bancárias automáticas</h3>
              <p>
                Registro automático ou manual? Gere arquivos de remessa e processe arquivos de retorno CNAB/FEBRABAN para registrar seus boletos e visualizar as
                ocorrências bancárias informadas. Se preferir, deixe esse trabalho com a gente automatizando a troca de arquivos online e visualize tudo no
                sistema ou integre ao seu sistema utilizando nossa API.
              </p>

              <h3>Multi bancos suportados</h3>
              <p>
                Banco do Brasil, Bradesco, Caixa, Itaú/Unibanco, Santander, Sicredi, Sicoob
                <br />
                Não encontrou seu banco, entre em contato conosco
              </p>
            </div>
          </LaneContents>
          <BackToTop href="#app">
            {' '}
            <MdArrowDropUp />{' '}
          </BackToTop>
        </Lane>

        <Lane id="who-we-are" inverse={false}>
          <LaneContents>
            <h1>Quem somos</h1>

            <div className="who-we-are">
              O Grupo SKILL foi fundado em 7 de janeiro de 1979, pelas empreendedoras mãos dos irmãos Pinto de Faria. Passados mais de 40 anos apostando na
              prestação de serviços personalizados de consultoria e contabilidade, sempre em sintonia com as tendências do mercado, o negócio transformou-se em
              referência nacional de seu ramo.
              <br />
              <br />
              Em 2007, ainda aliamos nossa expertise nas áreas tributária e fiscal ao conhecimento de Tecnologia da Informação, criando a SkillConsulting.
              <br />
              <br />
              Por meio de nosso conhecimento ímpar da parte fiscal e tributária nacional e das particularidades de cada legislação, os profissionais da SKILL
              estão aptos a atuar em todas as regiões do Brasil – reforçando assim o nosso orgulho de ser uma empresa genuinamente brasileira.
            </div>
          </LaneContents>
          <BackToTop href="#app">
            {' '}
            <MdArrowDropUp />{' '}
          </BackToTop>
        </Lane>

        <Lane id="contact" inverse={true}>
          <LaneContents>
            <h1>Contato</h1>

            <div className="contact">
              <a href="http://www.gruposkill.com.br/" target="_blank" rel="noopener noreferrer">
                <FaGlobe />
                <span>Site</span>
              </a>
              <a href="https://www.facebook.com/GrupoSkillConsultoria" target="_blank" rel="noopener noreferrer">
                <FaFacebook />
                <span>Facebook</span>
              </a>
              <a href="https://twitter.com/GrupoSkill" target="_blank" rel="noopener noreferrer">
                <FaTwitter />
                <span>Twitter</span>
              </a>
              <a href="https://www.youtube.com/c/gruposkill" target="_blank" rel="noopener noreferrer">
                <FaYoutube />
                <span>YouTube</span>
              </a>
              <a href="https://www.instagram.com/grupo_skill/" target="_blank" rel="noopener noreferrer">
                <FaInstagram />
                <span>Instagram</span>
              </a>
              <a href="http://www.linkedin.com/company/skill-consulting-business-inteligence" target="_blank" rel="noopener noreferrer">
                <FaLinkedin />
                <span>LinkedIn</span>
              </a>
            </div>
          </LaneContents>
        </Lane>

        {isLoginOpen && (
          <LoginForm
            isOpen={isLoginOpen}
            toggleModal={toggleLoginModal}
            handleOnSubmit={handleLoginSubmit}
            redefinePassword={redefinePassword}
            isLoginMode={isLoginMode}
            changeLoginMode={changeLoginMode}
          />
        )}
        {isSignUpOpen && <SignUpForm isOpen={isSignUpOpen} toggleModal={toggleSignUpModal} handleOnSubmit={handleSignUpSubmit} />}
      </Contents>
    </Container>
  );
}
