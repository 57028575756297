import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '~/services/api';
import _ from 'lodash';
import { Creators as AuthActions, Types as AuthTypes } from '~/store/modules/auth';
import history from '~/services/history';
import { getAvatar } from '~/constants';
import { getError } from '~/helper';

export function showError(error) {
  const msg =  getError(error);
  toast.error(msg);
};

const getGravatarImage = async ({ email }) => {
  try {
    let response = await api.get('users/avatar', { params: { email } });
    return _.get(response, 'data.base64');
  } catch(err) {
  }
  return null;
};

export function* login({ data, actions }) {
  try {
    actions.setSubmitting(true);
    const response = yield call(api.post, 'auth/login', data),
      { token, me, menu } = response.data,
      avatarBase64 = yield getGravatarImage(me);
    me.avatar = getAvatar({ ...me, avatarBase64 });
    actions.setSubmitting(false);
    yield put(AuthActions.loginSuccess(token, me, menu));
  } catch (error) {
    actions.setSubmitting(false);
    yield put(AuthActions.failure(error));
  }
};

export function* localLogin({ token, redirect }) {
  api.updateToken(token, true);
  const response = yield call(api.get, 'auth/me');
  const { me, menu } = response.data;
  const avatarBase64 = yield getGravatarImage(me);
  me.avatar = getAvatar({ ...me, avatarBase64 });
  yield put(AuthActions.loginSuccess(token, me, menu, true));
  history.push(redirect);
};

export function* localLoginWithMenu({ token, redirect }) {
  api.updateToken(token, redirect);
  const response = yield call(api.get, 'auth/me'),
    { me, menu } = response.data,
    avatarBase64 = yield getGravatarImage(me);
  me.avatar = getAvatar({ ...me, avatarBase64 });
  yield put(AuthActions.loginSuccess(token, me, menu));
  history.push(redirect);
};

export function* redefinePassword({ data, actions }) {
  try {
    actions.setSubmitting(true);
    yield call(api.post, 'auth/forget-password', data);
    toast.success(`Senha redefinida com sucesso!`);
    actions.closeForm && actions.closeForm();
    actions.reloadList && actions.reloadList();
    actions.setSubmitting(false);
  } catch (error) {
    actions.setSubmitting(false);
    showError(error);
  }
};

export async function loginSuccess({ token }) {
  if (token) {
    api.updateToken(token);
  }
  history.push('/home');
};

export function failure({ error }) {
  const msg = getError(error);
  toast.error(msg);
};

export function setToken({ payload }) {
  if (!payload) return;
  const { token, headLess } = payload.auth;
  if (token) {
    api.updateToken(token, headLess);
  }
};

export function logout({ headLess }) {
  const page = headLess ? '/forbidden/try-again' : '/login';
  history.push(page);
};

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest(AuthTypes.LOGIN, login),
  takeLatest(AuthTypes.LOCAL_LOGIN, localLogin),
  takeLatest(AuthTypes.LOCAL_LOGIN_WITH_MENU, localLoginWithMenu),
  takeLatest(AuthTypes.REDEFINE_PASSWORD, redefinePassword),
  takeLatest(AuthTypes.LOGIN_SUCCESS, loginSuccess),
  takeLatest(AuthTypes.FAILURE, failure),
  takeLatest(AuthTypes.LOGOUT, logout)
]);
