import React from 'react';
import _ from 'lodash';
import { Autocomplete, Checkbox, Select, InputGroup, InputMask, InputPhone } from '~/components/form';
import { MASK_CNPJ, MASK_CPF, MASK_CEP } from '~constants';
import Fieldset from '~/components/fieldset';
import { BsRow, BsCol, Row, FlexRow, FlexCol } from '~/components/layout';
import { TabContainer } from '~/pages/business-partners/styles';
import { brStatesValues } from '~/constants';
import { onlyNumbers } from '~/helper';
import { statusLabels } from '~/pages/bills';

const clonedStatusLabels = _.omit(statusLabels, 'pending', 'rejected');

export const TabGeneral = ({ errors, touched, values, setFieldTouched, setFieldValue, status, onListBusinessPartnerGroup, onSearchCnpj, previewMode, hasAccountsReceivable, hasAccountsPayable }) => {
  return (
    <TabContainer>
      <FlexRow direction="row" span={(hasAccountsReceivable && hasAccountsPayable) ? 3 : 1}>
        <Checkbox halfHeight={true} name="active" label="Ativo" disabled={previewMode} />

        {(hasAccountsReceivable && hasAccountsPayable) &&
          <Checkbox halfHeight={true} name="isSupplier" label="Fornecedor" disabled={previewMode} />
        }

        {(hasAccountsReceivable && hasAccountsPayable) &&
          <Checkbox halfHeight={true} name="isCustomer" label="Cliente" disabled={previewMode} />
        }
      </FlexRow>

      <FlexRow>
        <FlexCol flex="0 0 150px">
          <Select
            name="identityType"
            label="Tipo *"
            disabled={previewMode}
            hasError={_.get(errors, 'identityType') && _.get(touched, 'identityType')}
            onChange={evt => {
              setFieldValue('identityType', evt.target.value);
              setFieldValue('identity', '');
            }}
            options={{
              values: [
                { value: '', label: '' },
                { value: 'company', label: 'CNPJ' },
                { value: 'person', label: 'CPF' }
              ]
            }}
          />
        </FlexCol>

        <FlexCol flex="1">
          {_.get(values, 'identityType') !== 'person' && (
            <InputMask
              disabled={previewMode || _.get(values, 'identityType') === ''}
              mask={MASK_CNPJ}
              name="identity"
              label={'CNPJ *'}
              hasError={_.get(errors, 'identity') && _.get(touched, 'identity')}
              onBlur={e => {
                const value = onlyNumbers(e.target.value) || '';
                setFieldTouched('identity', true);
                if (value && value.length === 14 && onSearchCnpj) {
                  onSearchCnpj(value, values);
                }
              }}
            />
          )}

          {_.get(values, 'identityType') === 'person' && (
            <InputMask
              disabled={previewMode || _.get(values, 'identityType') === ''}
              mask={MASK_CPF}
              name="identity"
              label={'CPF *'}
              hasError={_.get(errors, 'identity') && _.get(touched, 'identity')}
            />
          )}
        </FlexCol>
      </FlexRow>

      <InputGroup
        type="text"
        name="name"
        maxLength={100}
        label={_.get(values, 'identityType') === 'company' ? 'Razão Social' : 'Nome'}
        disabled={previewMode}
        hasError={errors.name && touched.name}
      />

      <InputGroup
        type="text"
        name="tradeName"
        maxLength={100}
        label={_.get(values, 'identityType') === 'company' ? 'Nome Fantasia' : 'Apelido'}
        disabled={previewMode}
        hasError={errors.tradeName && touched.tradeName}
      />

      <Autocomplete
        autoFocus={true}
        name="businessPartnerGroup"
        label="Grupo de parceiro"
        value={_.get(values, 'businessPartnerGroup')}
        keyField="id"
        clearable={true}
        disabled={previewMode}
        loadData={onListBusinessPartnerGroup}
        valueFormat={row => `${row.code} - ${row.name}`}
        emptyText={'Pesquise um grupo de parceiro'}
        tipText={'Digite... '}
        loadingText={'Carregando...'}
        notFoundText={'Não encontrada'}
        />

      <FlexRow>
        <div style={{ flex: '1' }}>
          <InputGroup type="email" name="email" label="E-mail" maxLength={100} disabled={previewMode} hasError={errors.email && touched.email} />
        </div>

        <div style={{ flex: '0 0 150px' }}>
          <InputPhone
            name="phone"
            label="Telefone"
            country="BR"
            disabled={previewMode}
            value={_.get(values, 'phone')}
            hasError={errors.phone && touched.phone}
          />
        </div>
      </FlexRow>

      <Fieldset label="Status individual para fatura do portal do cliente">
        <BsRow>
          <BsCol sm={24} md={24} lg={24}>
            <label className={'labelIn'}>
              <input
                type='checkbox'
                disabled={previewMode}
                name={'extras.usePnFilter'}
                checked={_.get(values,'extras.usePnFilter') || false}
                onChange={async (event) => {
                  setFieldValue('extras.usePnFilter', event.target.checked)
                  if(!event.target.checked){
                    setFieldValue('extras.filterDefaults', {});
                  }
                }}
              />
            {`Utilizar status diferente das configurações default`}
            </label>
          </BsCol>
        </BsRow>
        <BsRow>
          {_.map(clonedStatusLabels, (label, id) => (
            <BsCol key={id} sm={12} md={4} lg={4}>
              <Checkbox halfHeight={true} name={`extras.filterDefaults.${id}`} label={label} disabled={previewMode || !_.get(values, 'extras.usePnFilter')} />
            </BsCol>
          ))}
        </BsRow>
      </Fieldset>

      <Fieldset label="Enviar e-mail sobre">
        {(hasAccountsReceivable && _.get(values, 'isCustomer')) &&
          <Fieldset label="Faturas">
            <FlexRow direction="row">
              <Checkbox
                halfHeight={true}
                noMargin={true}
                name="emailOptions.slip_generated"
                label="Gerada"
                disabled={previewMode} />

              <Checkbox
                halfHeight={true}
                noMargin={true}
                name="emailOptions.slip_before_overdue"
                label="A vencer"
                disabled={previewMode} />

              <Checkbox
                halfHeight={true}
                noMargin={true}
                name="emailOptions.slip_overdue"
                label="Vencida"
                disabled={previewMode} />

              <Checkbox
                halfHeight={true}
                noMargin={true}
                name="emailOptions.slip_paid"
                label="Paga"
                disabled={previewMode} />

              <Checkbox
                halfHeight={true}
                noMargin={true}
                name="emailOptions.slip_duedate_change"
                label="Vencimento alterado"
                disabled={previewMode} />

              <Checkbox
                  halfHeight={true}
                  noMargin={true}
                  name="emailOptions.slip_write_off"
                  label="Cancelada"
                  disabled={previewMode} />
                  
            </FlexRow>
          </Fieldset>
        }
        {(hasAccountsPayable && _.get(values, 'isSupplier')) &&
          <Fieldset label="Ordens de pagamento">
            <FlexRow direction="row">
              <Checkbox
                halfHeight={true}
                noMargin={true}
                name="emailOptions.payment_order_closed"
                label="Comprovante de pgto"
                disabled={previewMode} />
            </FlexRow>
          </Fieldset>
        }
      </Fieldset>

      <Fieldset label="Endereço">
        <FlexRow>
          <div style={{ flex: '1' }}>
            <InputGroup
              type="text"
              name="address.address"
              label="Rua *"
              maxLength={50}
              disabled={previewMode}
              hasError={_.get(errors, 'address.address') && _.get(touched, 'address.address')}
            />
          </div>

          <div style={{ flex: '0 0 150px' }}>
            <InputGroup
              type="text"
              name="address.number"
              label="Número *"
              maxLength={30}
              disabled={previewMode}
              hasError={_.get(errors, 'address.number') && _.get(touched, 'address.number')}
            />
          </div>
        </FlexRow>

        <InputGroup
          type="text"
          name="address.complements"
          label="Complemento"
          maxLength={50}
          disabled={previewMode}
          hasError={_.get(errors, 'address.complements') && _.get(touched, 'address.complements')}
        />

        <Row span={2}>
          <InputGroup
            type="text"
            name="address.block"
            label="Bairro *"
            disabled={previewMode}
            hasError={_.get(errors, 'address.block') && _.get(touched, 'address.block')}
          />

          <InputMask
            name="address.zip_code"
            label="CEP *"
            maxLength={20}
            mask={MASK_CEP}
            disabled={previewMode}
            value={_.get(values, 'address.zip_code')}
            hasError={_.get(errors, 'address.zip_code') && _.get(touched, 'address.zip_code')}
          />
        </Row>

        <Row span={2}>
          <InputGroup
            type="text"
            name="address.city"
            label="Cidade *"
            maxLength={50}
            disabled={previewMode}
            hasError={_.get(errors, 'address.city') && _.get(touched, 'address.city')}
          />

          <Select
            name="address.state"
            label="Estado *"
            disabled={previewMode}
            value={_.get(values, 'address.state') || ''}
            options={{ values: [{ value: '', label: '' }, ...brStatesValues] }}
            hasError={_.get(errors, 'address.state') && _.get(touched, 'address.state')}
          />
        </Row>
      </Fieldset>

      {status && status.msg && <div>{status.msg}</div>}
    </TabContainer>
  );
};
