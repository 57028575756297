import { all, put, call, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '~/services/api';
import { getError } from '~/helper';
import { Creators as Actions, Types } from '~/store/modules/payment-approval';

export function showError(error) {
  const msg = getError(error);
  toast.error(msg);
}

export function* load({ params, callback }) {
  try {
    const response = yield call(api.post, 'payment-approvals/load', params);
    yield put(Actions.loadSuccess(response.data));
    callback && callback(response.data, params);
  } catch (error) {
    yield put(Actions.loadSuccess({}));
    showError(error);
  }
}

export function* loadDetails({ params, callback }) {
  try {
    const response = yield call(api.post, 'payment-approvals/load-details', params);
    yield put(Actions.loadDetailsSuccess(response.data));
    callback && callback(response.data);
  } catch (error) {
    yield put(Actions.loadDetailsSuccess({}));
    showError(error);
  }
}

export function* loadOrder({ params, callback }) {
  try {
    const response = yield call(api.post, 'payment-approvals/load-order', params);
    yield put(Actions.loadOrderSuccess(response.data));
    callback && callback(response.data);
  } catch (error) {
    yield put(Actions.loadOrderSuccess({}));
    showError(error);
  }
}

export function* downloadFile({ data }) {
  try {
    const params = { id: data.id, filename: data.filename };
    const response = yield call(api.get, 'payment-approvals/download-file', { params, responseType: 'blob' });

    api.download(response, data.filename);
  } catch (error) {
    showError(error);
  }
}

export function* executeApproval({ params, callback }) {
  try {
    yield call(api.post, 'payment-approvals/execute-approval', params);
    toast.success(`${params.approved ? 'Aprovação' : 'Rejeição'} registrada com sucesso.`);
    yield put(Actions.executeApprovalSuccess({}));
    callback && callback(params.filterGroup);
  } catch (error) {
    showError(error);
    yield put(Actions.executeApprovalSuccess({}));
    callback && callback();
  }
}

export function* listBankContracts({ params, callback }) {
  try {
    const response = yield call(api.get, 'payment-approvals/list-bank-contracts', { params });
    yield call(callback, response.data);
  } catch (error) {}
}

export function* listCompanies({ params, callback }) {
  try {
    const response = yield call(api.get, 'payment-approvals/list-companies', { params });
    yield call(callback, response.data);
  } catch (error) {}
}

export function* listBusinessPartners({ term, callback }) {
  try {
    const response = yield call(api.get, 'payment-approvals/list-business-partners', { params: { term } });
    yield call(callback, response.data);
  } catch (error) {}
}

export default all([
  takeLatest(Types.LOAD, load),
  takeLatest(Types.LOAD_DETAILS, loadDetails),
  takeLatest(Types.LOAD_ORDER, loadOrder),
  takeLatest(Types.DOWNLOAD_FILE, downloadFile),
  takeLatest(Types.EXECUTE_APPROVAL, executeApproval),
  takeLatest(Types.LIST_BANK_CONTRACTS, listBankContracts),
  takeLatest(Types.LIST_COMPANIES, listCompanies),
  takeLatest(Types.LIST_BUSINESS_PARTNERS, listBusinessPartners)
]);
