import React, { useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import EmptyState from '~/components/empty-state';

export const Container = styled.div`
  width: 100%;
  height: calc(100%);

  table {
    width: 100%;
    height: 100%;
    border: 1px solid #eee;

    tbody {
      display:block;
      width: 100%;
      height: calc(100% - ${(props) => (props.hasHeader ? 30 : 0) + (props.hasFooter ? 30 : 0)}px);
      max-height: calc(100% - ${(props) => (props.hasHeader ? 30 : 0) + (props.hasFooter ? 30 : 0)}px);
      overflow-y: auto;
      overflow-x: hidden;

      ::-webkit-scrollbar {
        //display: none;
      }
    }
    thead tr, thead tr th {
      height: 30px;
      background: #ffffff;
    }
    tfoot tr, tfoot tr td {
      height: 30px;
      background: #eee;
    }
    tbody tr {
      border-bottom: 1px solid #f0f0f0;
    }
    tbody tr, tbody tr td {
      height: ${(props) => props.rowHeight}px;
    }
    thead, tbody tr, tfoot {
      display: table;
      width: 100%;
      table-layout: fixed;
    }
    tbody, tbody tr,
    thead, thead tr {
      width: 100%;
    }
    thead tr th {
      font-size: 12px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.87);
      vertical-align: middle;
    }
    tfoot tr td {
      font-size: 12px;
      font-weight: 800;
      color: rgba(0, 0, 0, 0.87);
      vertical-align: middle;
    }
    tbody tr td {
      font-weight: 400;
      font-size: 13px;
      vertical-align: middle;
    }
    tbody tr.selected td {
      color: #ffffff !important;
      background-color: #29a8e0 !important;
    }
    tbody tr {
      color: rgba(0, 0, 0, 0.87);
      background-color: #fafafa;
    }
    tbody tr:nth-child(even) {
      color: rgba(0, 0, 0, 0.87);
      background-color: #ffffff;
    }
    tbody tr:hover td {
      cursor: pointer;
      color: #596077;
      background-color: rgba(41, 168, 224, 0.06);
    }
    tbody tr.empty, tbody tr.empty td {
      height: 100%;
    }
    tbody tr.empty:hover td {
      cursor: default;
      color: rgba(0, 0, 0, 0.87);
      background-color: transparent;
    }

    tr td:first-child, tr th:first-child {
      padding-left: 5px;
    }
    tr td:last-child, tr th:last-child {
      padding-right: 5px;
    }
  }

`;

const tdAttributes = ['className', 'style', 'width', 'height', 'align'];

const getTdAttr = (col) => {
  let attrs = {};
  for(let attr in col) {
    let val = col[attr];
    if (val && tdAttributes.includes(attr)) {
      attrs[attr] = val;
    }
  }
  return attrs;
};

function Table({ rowHeight = 30, fixedSelected, keyField = 'id', columns, rows, footer, onRowClicked, emptyText = 'Sem resultados' })  {
  const [selected, setSelected] = useState(fixedSelected);
  const hasFooter = !_.isEmpty(footer);

  const selectedVal = fixedSelected || selected;

  return (
    <Container rowHeight={rowHeight} hasHeader={true} hasFooter={hasFooter}>
      <table cellSpacing="0" cellPadding="0">
        <thead>
          <tr>
            {_.map(columns, (col, index) => (
              <th key={`th-${index}`} {...getTdAttr(col)}>
                {col.label}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {_.isEmpty(rows) &&
            <tr className="empty">
              <td align="center" colSpan={_.size(columns)}>
                <EmptyState
                  text={emptyText}
                  height="100%"
                  size="110px"
                  fontSize="16px"
                  visible={true}
                  />
              </td>
            </tr>
          }

          {_.map(rows, (row, index) => (
            <tr
              key={`r-${index}`}
              className={(selectedVal && selectedVal === _.get(row, keyField)) ? 'selected' : ''}
              onClick={() => {
                setSelected(_.get(row, keyField));
                onRowClicked(row, index)
              }}
              >
              {_.map(columns, (col, cIndex) => (
                <td key={`tb-${cIndex}`} {...getTdAttr(col)}>
                  {col.format(row, index)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>

        {hasFooter &&
          <tfoot>
            <tr>
              {_.map(footer, (col, index) => (
                <td key={`tf-${index}`} {...getTdAttr(col)}>
                  {col.format(index)}
                </td>
              ))}
            </tr>
          </tfoot>
        }
      </table>
    </Container>
  );
};

export default Table;
