import React from 'react';
import { Select, Autocomplete, InputGroup, InputMask, InputLabel, InputPhone } from '~/components/form';
import FieldSet from '~/components/fieldset';
import { BsRow, BsCol } from '~/components/layout';
import { pixKeyTypes, pixAccountTypes } from '~/pages/payment-orders/constants';
import { isValidCpf, isValidCnpj, isValidEmail } from '~/validator';
import * as Yup from 'yup';
import _ from 'lodash';
import { MASK_CPF, MASK_CNPJ, MASK_UUID } from '~/constants';
import { onlyNumbers } from '~/helper';

export const id = 12;

export const onParentChange = ({ field, value, parent, setFieldValue }) => {
  let idField = 'orderData.pixKey',
    idTypeField = 'orderData.pixKeyType',
    nameField = 'orderData.name'

  if (field === 'paymentOrderType' && _.get(parent, 'businessPart;ner')) {
    let identity = _.get(parent, 'businessPartner.identity');
    setFieldValue(idTypeField, _.size(identity) === 14 ? '03_' : '03');
    setFieldValue(idField, identity);
    setFieldValue(nameField, _.get(parent, 'businessPartner.name'));
  } else if (field === 'businessPartner' && value) {
    let identity = _.get(value, 'identity');
    setFieldValue(idTypeField, _.size(identity) === 14 ? '03_' : '03');
    setFieldValue(idField, identity);
    setFieldValue('orderData.pixAccountType', '04');
    setFieldValue(nameField, _.get(value, 'name'));
  }
};

export const Render = ({ errors, touched, values, previewMode, setFieldValue, onListBanks }) => {
  const getPixAccountTypes = values => {
    const pixKeyType = _.get(values, 'orderData.pixKeyType')
    if (pixKeyType === '05') {
      return pixAccountTypes.filter(r => r.value !== '04')
    } else {
      return pixAccountTypes.filter(r => r.value === '04')
    }
  };

  const pixKeyToFieldBusinessPartner = {
    '01': 'phone',
    '02': 'email',
    '03': 'identity'
  };

  const editLocked = _.get(values, 'editLocked') || false;

  return (
    <FieldSet label="Transferência - PIX">
      <BsRow>
        <BsCol md={24} lg={6} xl={6}>
          <Select
            name="orderData.pixKeyType"
            label="Tipo de chave *"
            disabled={previewMode || editLocked}
            hasError={_.get(errors, 'orderData.pixKeyType') && _.get(touched, 'orderData.pixKeyType')}
            options={{ values: [{ value: '', label: '' }, ...pixKeyTypes] }}
            onChange={evt => {
              let value = _.get(evt, 'target.value')
              setFieldValue('orderData.pixKeyType', value)
              setFieldValue('orderData.pixAccountType', value === '05' ? '01' : '04')

              const noSet =
                (value === '03' && _.get(values, 'businessPartner.identityType') === 'company') ||
                (value === '03_' && _.get(values, 'businessPartner.identityType') === 'person')

              const field = pixKeyToFieldBusinessPartner[onlyNumbers(value)]

              if (!noSet && !_.isEmpty(field)) {
                setFieldValue('orderData.pixKey', _.get(values, `businessPartner.${field}`))
              } else {
                setFieldValue('orderData.pixKey', '')
              }
            }}
          />
        </BsCol>

        <BsCol md={24} lg={6} xl={6}>
          <Select
            name="orderData.pixAccountType"
            label="Tipo de transferência *"
            disabled={previewMode || _.get(values, 'orderData.pixKeyType') !== '05' || editLocked}
            hasError={_.get(errors, 'orderData.pixAccountType') && _.get(touched, 'orderData.pixAccountType')}
            options={{ values: [{ value: '', label: '' }, ...getPixAccountTypes(values)] }}
          />
        </BsCol>

        {_.get(values, 'orderData.pixKeyType') !== '05' && (
          <BsCol md={24} lg={12} xl={12}>
            {_.get(values, 'orderData.pixKeyType') === '03' && (
              <InputMask
                mask={MASK_CPF}
                name="orderData.pixKey"
                label="CPF *"
                disabled={previewMode || editLocked}
                hasError={_.get(errors, 'orderData.pixKey') && _.get(touched, 'orderData.pixKey')}
              />
            )}
            {_.get(values, 'orderData.pixKeyType') === '03_' && (
              <InputMask
                mask={MASK_CNPJ}
                name="orderData.pixKey"
                label="CNPJ *"
                disabled={previewMode || editLocked}
                hasError={_.get(errors, 'orderData.pixKey') && _.get(touched, 'orderData.pixKey')}
              />
            )}
            {_.get(values, 'orderData.pixKeyType') === '01' && (
              <InputPhone
                name="orderData.pixKey"
                label="Telefone *"
                country="BR"
                disabled={previewMode || editLocked}
                hasError={_.get(errors, 'orderData.pixKey') && _.get(touched, 'orderData.pixKey')}
              />
            )}
            {_.get(values, 'orderData.pixKeyType') === '02' && (
              <InputGroup
                type="text"
                name="orderData.pixKey"
                label="E-mail *"
                disabled={previewMode || editLocked}
                maxLength="80"
                hasError={_.get(errors, 'orderData.pixKey') && _.get(touched, 'orderData.pixKey')}
              />
            )}
            {_.get(values, 'orderData.pixKeyType') === '04' && (
              <InputMask
                mask={MASK_UUID}
                name="orderData.pixKey"
                label="Chave Aleatória *"
                disabled={previewMode || editLocked}
                hasError={_.get(errors, 'orderData.pixKey') && _.get(touched, 'orderData.pixKey')}
              />
            )}
            {!_.get(values, 'orderData.pixKeyType') && <InputLabel label={`Chave PIX`} value=" " />}
          </BsCol>
        )}

        {_.get(values, 'orderData.pixKeyType') === '05' && (
          <>
            <BsCol md={24} lg={12} xl={12}>
              <Autocomplete
                name="orderData.bank"
                minLength={0}
                keyField="code"
                label="Banco *"
                disabled={previewMode || editLocked}
                value={_.get(values, 'orderData.bank')}
                valueFormat={row => `${row.code} - ${row.name}`}
                loadData={onListBanks}
                emptyText={'Pesquise um banco'}
                tipText={'Digite... '}
                loadingText={'Carregando...'}
                notFoundText={'Não encontrado'}
                onChange={value => setFieldValue('orderData.bank', value)}
                hasError={_.get(errors, 'orderData.bank') && _.get(touched, 'orderData.bank')}
              />
            </BsCol>

            <BsCol md={24} lg={8} xl={8}>
              <InputGroup
                type="text"
                name="orderData.bankBranch"
                label="Agência *"
                disabled={previewMode || editLocked}
                maxLength="10"
                hasError={_.get(errors, 'orderData.bankBranch') && _.get(touched, 'orderData.bankBranch')}
              />
            </BsCol>

            <BsCol md={24} lg={4} xl={4}>
              <InputGroup
                type="text"
                name="orderData.bankBranchDigit"
                label="Digito agência"
                disabled={previewMode || editLocked}
                maxLength="2"
                hasError={_.get(errors, 'orderData.bankBranchDigit') && _.get(touched, 'orderData.bankBranchDigit')}
              />
            </BsCol>

            <BsCol md={24} lg={8} xl={8}>
              <InputGroup
                type="text"
                name="orderData.accountNumber"
                label="Contrato *"
                disabled={previewMode || editLocked}
                maxLength="20"
                hasError={_.get(errors, 'orderData.accountNumber') && _.get(touched, 'orderData.accountNumber')}
              />
            </BsCol>

            <BsCol md={24} lg={4} xl={4}>
              <InputGroup
                type="text"
                name="orderData.accountNumberDigit"
                label="Digito da conta *"
                disabled={previewMode || editLocked}
                maxLength="2"
                hasError={_.get(errors, 'orderData.accountNumberDigit') && _.get(touched, 'orderData.accountNumberDigit')}
              />
            </BsCol>
          </>
        )}
      </BsRow>

      <BsRow>
        <BsCol md={24} lg={24} xl={24}>
          <InputGroup
            type="text"
            name="orderData.name"
            label="Beneficiário *"
            disabled={previewMode || editLocked}
            hasError={_.get(errors, 'orderData.name') && _.get(touched, 'orderData.name')}
          />
        </BsCol>
      </BsRow>

      <BsRow>
        <BsCol md={24} lg={18} xl={18}>
          <InputGroup type="text" name="orderData.message" disabled={previewMode || editLocked} label="Mensagem" />
        </BsCol>

        <BsCol md={24} lg={6} xl={6}>
          <InputLabel label={`Câmara Centralizadora`} value="009 - PIX (SPI)" />
        </BsCol>
      </BsRow>
    </FieldSet>
  )
}

export const Schema = values => ({
  orderData: Yup.object().typeError('Informe os dados da ordem').shape({
    name: Yup.string().required('Informe o Beneficiário'),
    pixKeyType: Yup.string().required('Informe o tipo de chave'),
    pixKey: Yup.mixed().test('match', '', function(value) {
      const { path, parent, createError } = this
      const val = value || _.get(parent, 'pixKey'),
        type = _.get(parent, 'pixKeyType')

      if (!val && type && type !== '05') {
        return createError({ path, message: 'Informe a chave PIX' })
      } else if (val && type === '03' && !isValidCpf(val)) {
        return createError({ path, message: 'Informe um CPF válido' })
      } else if (val && type === '03_' && !isValidCnpj(val)) {
        return createError({ path, message: 'Informe um CNPJ válido' })
      } else if (val && type === '01' && _.size(val) !== 10 && _.size(val) !== 11) {
        return createError({ path, message: 'Informe um telefone válido' })
      } else if (val && type === '02' && !isValidEmail(val)) {
        return createError({ path, message: 'Informe um e-mail válido' })
      } else if (val && type === '04' && _.size(val) < 20) {
        return createError({ path, message: 'Informe uma chave aleatória válida' })
      }
      return true
    }),
    pixAccountType: Yup.mixed().test('match', '', function(value) {
      const { path, parent, createError } = this
      const val = value || _.get(parent, 'pixAccountType'),
        type = _.get(parent, 'pixKeyType')
      if (!val && type === '05') {
        return createError({ path, message: 'Informe o tipo de transferência' })
      }
      return true
    }),
    bank: Yup.mixed().test('match', '', function(value) {
      const { path, parent, createError } = this
      const val = value || _.get(parent, 'bank'),
        type = _.get(parent, 'pixKeyType')
      if (!val && type === '05') {
        return createError({ path, message: 'Informe o banco' })
      }
      return true
    }),
    bankBranch: Yup.mixed().test('match', '', function(value) {
      const { path, parent, createError } = this
      const val = value || _.get(parent, 'bankBranch'),
        type = _.get(parent, 'pixKeyType')
      if (!val && type === '05') {
        return createError({ path, message: 'Informe a agência' })
      }
      return true
    }),
    accountNumber: Yup.mixed().test('match', '', function(value) {
      const { path, parent, createError } = this
      const val = value || _.get(parent, 'accountNumber'),
        type = _.get(parent, 'pixKeyType')
      if (!val && type === '05') {
        return createError({ path, message: 'Informe a conta corrente' })
      }
      return true
    }),
    accountNumberDigit: Yup.mixed().test('match', '', function(value) {
      const { path, parent, createError } = this
      const val = value || _.get(parent, 'accountNumberDigit'),
        type = _.get(parent, 'pixKeyType')
      if (!val && type === '05') {
        return createError({ path, message: 'Informe o digito da conta' })
      }
      return true
    })
  })
});
