
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import createStore from './create-store';
import persistReducers from './persist-reducers';
import rootReducer from './modules/root-reducer';
import rootSaga from './modules/root-saga';
import { createLogger } from 'redux-logger';
import history from '~/services/history';

const sagaMiddleware = createSagaMiddleware({});
const middlewares = [sagaMiddleware];
if (process.env.REACT_APP_REDUX_LOGGER === 'true') {
  middlewares.push(createLogger({}));
}
const store = createStore(persistReducers(rootReducer(history)), middlewares);
const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persistor };
