import { all, takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { Creators as EdiFileActions, Types as EdiFileTypes } from '~/store/modules/edi-file';
import api from '~/services/api';
import { getError } from '~/helper';
import _ from 'lodash';

export function* list({ params }) {
  try {
    const response = yield call(api.post, 'edi-files/list', params);
    yield put(EdiFileActions.listSuccess(response.data));
  } catch (error) {
    showError(error);
  }
}

export function* load({ data }) {
  try {
    const response = yield call(api.get, 'edi-files/load', { params: { id: data.id || 'new' } });
    yield put(EdiFileActions.loadSuccess(response.data));
  } catch (error) {
    showError(error);
  }
}

export function* downloadFile({ data }) {
  try {
    const params = { id: _.get(data, 'id', '') },
      response = yield call(api.get, 'edi-files/download-file', { params, responseType: 'blob' }),
      filename = _.get(data, 'filename', '');
    api.download(response, filename);
  } catch (error) {
    showError(error);
  }
  yield put(EdiFileActions.formActionDone());
}

export function* reprocessFile({ data, actions }) {
  try {
    const params = { id: _.get(data, 'id', '') };
    yield call(api.post, 'edi-files/reprocess-file', params);
    toast.success(`Reprocessamento solicitado com sucesso`);
    actions.closeForm && actions.closeForm();
    actions.reloadList && actions.reloadList();
  } catch (error) {
    showError(error);
  }
};

export function showError(error) {
  const msg = getError(error);
  toast.error(msg);
}

export default all([
  takeLatest(EdiFileTypes.LIST, list),
  takeLatest(EdiFileTypes.LOAD, load),
  takeLatest(EdiFileTypes.DOWNLOAD_FILE, downloadFile),
  takeLatest(EdiFileTypes.REPROCESS_FILE, reprocessFile),
]);
