import React from 'react';
import _ from 'lodash';
import { formatBankContractSimple } from '~/pages/bank-contracts/form';
import Modal from '~/components/modal';
import { AttachmentTableContainer, DetailTableContainer, ApprovalDetailTableContainer } from '~/pages/payment-orders/styles';
import { ModalContainer } from '~/pages/payment-approvals/styles';
import EmptyState from '~/components/empty-state';
import { IconButton } from '~/components/button';
import { InputLabel } from '~/components/form';
import { BsContainer, BsRow, BsCol } from '~/components/layout';
import { statusMap } from '~/pages/payment-orders/constants';
import { MdFileDownload } from 'react-icons/md';
import { formats } from '~/helper';
import DataTable from '~/components/datatable';
import Fieldset from '~/components/fieldset';

const customStyles = {
  rows: {
    denseStyle: {
      minHeight: '30px'
    }
  }
};

const renderApprovalsHistory = values => {
  let approvals = _.get(values, 'approvalHistory') || [];

  if (_.isEmpty(approvals)) {
    return null;
  }

  return (
    <BsRow>
      <BsCol md={24} lg={24} xl={24}>
        <Fieldset label="Histórico de aprovações">
          <DetailTableContainer>
            <DataTable
              noPagination={true}
              emptyText="Nenhuma aprovação encontrada."
              hideUpdateButton={true}
              noTableHeader={true}
              data={{ rows: approvals }}
              onRowClicked={_.noop}
              padding="0"
              columns={[
                {
                  name: 'Descrição',
                  selector: 'completed',
                  compact: true,
                  format: row => {
                    let text = 'Em Aprovação';

                    if (row.completed) {
                      let repproved = _.find(_.get(row, 'history'), { approved: false });
                      text = repproved ? 'Rejeitada' : 'Aprovada';
                    }
                    return `Rotina de aprovação - ${text}`;
                  }
                },
                {
                  name: 'Min. Aprovadores',
                  selector: 'createdAt',
                  compact: true,
                  center: true,
                  width: '300px',
                  format: row => `Mínimo de aprovadores: ${_.get(row, 'criteria.minApprovers')}`
                },
                {
                  name: 'Data',
                  selector: 'createdAt',
                  compact: true,
                  right: true,
                  width: '140px',
                  format: row => formats.dateTimeZone(_.get(row, 'createdAt'), 'dd/MM/yyyy HH:mm')
                }
              ]}
              extraOptions={{
                noTableHead: true,
                expandableRows: true,
                highlightOnHover: true,
                expandOnRowClicked: true,
                selectableRows: false,
                selectableRowsHighlight: false,
                expandableRowDisabled: row => _.isEmpty(_.get(row, 'history')),
                expandableRowsComponent: <HistoryRow />
              }}
            />
          </DetailTableContainer>
        </Fieldset>
      </BsCol>
    </BsRow>
  );
};

const HistoryRow = ({ data = {} }) => {
  let rows = _.orderBy(data.history, ['createdAt'], ['desc']);

  return (
    <ApprovalDetailTableContainer>
      <DataTable
        noPagination={true}
        emptyText="Nenhum histórico encontrado."
        hideUpdateButton={true}
        noTableHeader={true}
        data={{ rows }}
        onRowClicked={_.noop}
        padding="0"
        columns={[
          {
            name: 'Usuário',
            selector: 'user',
            compact: true,
            width: '300px',
            format: row => _.get(row, 'user.name')
          },
          {
            name: 'Decisão',
            selector: 'approved',
            compact: true,
            center: true,
            width: '100px',
            format: row => (row.approved ? 'Aprovou' : 'Rejeitou')
          },
          {
            name: 'Localização',
            selector: 'extras',
            compact: true,
            center: true,
            width: '200px',
            cell: row => {
              let tip = _.get(row, 'extras.positionTip') || `${_.get(row, 'extras.position.latitude')}, ${_.get(row, 'extras.position.longitude')}`,
                text = _.get(row, 'extras.positionText') || `${_.get(row, 'extras.position.latitude')}, ${_.get(row, 'extras.position.longitude')}`;

              return (
                <div className="text-truncate" title={`Ver no mapa: ${tip}`}>
                  <a className="app-link" rel="noopener noreferrer" target="_blank" href={_.get(row, 'extras.position.link')}>
                    {text}
                  </a>
                </div>
              );
            }
          },
          {
            name: 'IP',
            selector: 'extras',
            compact: true,
            center: true,
            width: '180px',
            format: row => _.get(row, 'extras.access.address')
          },
          {
            name: 'Motivo da rejeição',
            selector: 'extras',
            compact: true,
            format: row => _.get(row, 'extras.rejectReason') || ''
          },
          {
            name: 'Data',
            selector: 'createdAt',
            compact: true,
            width: '140px',
            format: row => formats.dateTimeZone(_.get(row, 'createdAt'), 'dd/MM/yyyy HH:mm')
          }
        ]}
        extraOptions={{
          highlightOnHover: false,
          selectableRows: false,
          selectableRowsHighlight: false
        }}
      />
    </ApprovalDetailTableContainer>
  );
};

function PaymentOrderDetail({ isOpen, toggleModal, loading, values, onApproval, handleDownloadAttachment }) {

  return (
    <Modal
      width="calc(100% - 60px)"
      height="calc(100% - 60px)"
      title={`Detalhe - Ordem de pagamento`}
      hideClose={true}
      noBodyPadding={true}
      open={isOpen}
      hide={toggleModal}
      actions={[
        { label: 'Aprovar', color: 'success', action: () => onApproval(true, toggleModal) },
        { label: 'Rejeitar', color: 'error', action: () => onApproval(false, toggleModal) },
        { label: 'Fechar', action: toggleModal }
      ]}
    >
      <>
        {loading && <EmptyState visible={true} text="Carregando..." />}

        {!_.get(values, 'id') && <EmptyState visible={true} text="Ordem de pagamento não encontrada!" />}

        {!loading && _.get(values, 'id') && (
          <ModalContainer>
            <BsContainer>
              <div className="main">
                <Fieldset label="Informações gerais">
                  <BsRow>
                    <BsCol md={12} lg={6}>
                      <InputLabel label="Número" value={values.yourNumber} />
                    </BsCol>

                    <BsCol md={12} lg={12}>
                      <InputLabel label="Tipo da ordem" value={_.get(values, 'paymentOrderType.name')} />
                    </BsCol>

                    <BsCol md={12} lg={6}>
                      <InputLabel label="Situação" value={statusMap[values.status]} />
                    </BsCol>
                  </BsRow>

                  <BsRow>
                    <BsCol md={12} lg={6}>
                      <InputLabel label="Data vencimento" value={formats.dateTimeZone(_.get(values, 'dueDate'), 'dd/MM/yyyy')} />
                    </BsCol>

                    <BsCol md={12} lg={6}>
                      <InputLabel label="Data agendamento" value={formats.dateTimeZone(_.get(values, 'scheduledDate'), 'dd/MM/yyyy')} />
                    </BsCol>

                    <BsCol md={12} lg={6}>
                      <InputLabel label="Nº documento" value={_.get(values, 'documentNumber')} />
                    </BsCol>

                    <BsCol md={12} lg={6}>
                      <InputLabel label="Nosso número" value={values.ourNumber} />
                    </BsCol>
                  </BsRow>

                  <BsRow>
                    <BsCol md={12} lg={6}>
                      <InputLabel label="Moeda" value="BRL (Real)" />
                    </BsCol>

                    <BsCol md={6} lg={3}>
                      <InputLabel label="Valor Doc." value={values.isPartial ? formats.currency(_.get(values, 'baseAmount')) : formats.currency(_.get(values, 'value'))} />
                    </BsCol>

                    <BsCol md={6} lg={3}>
                      <InputLabel label="Valor a Pagar" value={values.isPartial ? formats.currency(_.get(values, 'value')) : formats.currency(_.get(values, 'orderData.total') || _.get(values, 'value'))} />
                    </BsCol>

                    <BsCol md={24} lg={12}>
                      <InputLabel
                        label="Beneficiário"
                        value={`${formats.cnpj_cpf(_.get(values, 'businessPartner.identity'))} - ${_.get(values, 'businessPartner.name')}`}
                      />
                    </BsCol>
                  </BsRow>

                  <BsRow>
                    <BsCol md={24} lg={12}>
                      <InputLabel label="Empresa" value={`${formats.cnpj_cpf(_.get(values, 'company.identity'))} - ${_.get(values, 'company.name')}`} />
                    </BsCol>

                    <BsCol md={24} lg={12}>
                      <InputLabel label="Contrato (débito)" value={formatBankContractSimple(_.get(values, 'bankContract'))} />
                    </BsCol>
                  </BsRow>
                </Fieldset>

                <BsRow>
                  <BsCol md={24} lg={10}>
                    <Fieldset label="Itens do documento">
                      <AttachmentTableContainer>
                        <DataTable
                          emptyText="Nenhum item vinculado"
                          noPagination={true}
                          padding="0px"
                          onRowClicked={_.noop}
                          data={{ rows: _.get(values, 'extras.viewData.documentLines') || [] }}
                          extraOptions={{ customStyles }}
                          columns={[
                            {
                              name: 'Descrição',
                              selector: 'description',
                              cell: row => {
                                let text = _.get(row, 'description') || '';
                                return (
                                  <div className="text-truncate" title={text}>
                                    {' '}
                                    {text}{' '}
                                  </div>
                                );
                              }
                            },
                            {
                              name: 'Valor',
                              selector: 'lineTotal',
                              right: true,
                              width: '140px',
                              format: row => formats.currency(_.get(row, 'lineTotal') || 0)
                            }
                          ]}
                        />
                      </AttachmentTableContainer>
                    </Fieldset>
                  </BsCol>

                  <BsCol md={24} lg={14}>
                    {_.size(values, 'extras.viewData.costingCenters') > 0 && (
                      <Fieldset label="Centro de custos">
                        <BsRow>
                          <BsCol md={24} lg={24}>
                            <AttachmentTableContainer>
                              <DataTable
                                noPagination={true}
                                onRowClicked={_.noop}
                                data={{ rows: _.get(values, 'extras.viewData.costingCenters') || [] }}
                                extraOptions={{ noTableHead: true, customStyles }}
                                columns={[
                                  {
                                    name: 'Código',
                                    selector: 'code'
                                  },
                                  {
                                    name: 'Descrição',
                                    selector: 'name'
                                  },
                                  {
                                    name: 'Valor',
                                    selector: 'amount',
                                    right: true,
                                    format: row => formats.currency(_.get(row, 'amount') || 0)
                                  }
                                ]}
                              />
                            </AttachmentTableContainer>
                          </BsCol>
                        </BsRow>
                      </Fieldset>
                    )}

                    <Fieldset label="Anexos">
                      <AttachmentTableContainer>
                        <DataTable
                          emptyText="Nenhum anexo vinculado"
                          noPagination={true}
                          padding="0px"
                          onRowClicked={_.noop}
                          data={{ rows: _.get(values, 'attachments') || [] }}
                          extraOptions={{ noTableHead: true, customStyles }}
                          columns={[
                            {
                              name: 'Arquivo',
                              selector: 'name',
                              format: row => _.get(row, 'name') || ''
                            },
                            {
                              name: 'Ação',
                              width: '80px',
                              center: true,
                              cell: (row, index) => (
                                <>
                                  <IconButton
                                    size={28}
                                    title="Baixar arquivo"
                                    noMargin={true}
                                    onClick={() => handleDownloadAttachment({ id: _.get(values, 'id'), filename: row.name })}
                                  >
                                    <MdFileDownload />
                                  </IconButton>
                                </>
                              )
                            }
                          ]}
                        />
                      </AttachmentTableContainer>
                    </Fieldset>
                  </BsCol>
                </BsRow>

                {renderApprovalsHistory(values)}

              </div>
            </BsContainer>
          </ModalContainer>
        )}
      </>
    </Modal>
  );
}

export default PaymentOrderDetail;
