import { createActions, createReducer } from 'reduxsauce';
import produce from 'immer';

export const { Types, Creators } = createActions({
  list: ['params'],
  listSuccess: ['data'],
  load: ['data'],
  loadSuccess: ['model'],
  clearModel: [],
  createOrUpdate: ['data', 'actions'],
  updatePassword: ['data', 'actions'],
  updateAvatar: ['data', 'actions'],
  listProfiles: ['term', 'callback'],
  resetPassword: ['data', 'actions'],
}, { prefix: 'users' });

const INITIAL_STATE = {
  loading: false,
  formLoading: false,
  term: null,
  model: null,
  data: { rows: [], count: 0, offset: 0 }
};

const handleList = (state = INITIAL_STATE, action) => produce(state, draft => {
  draft.term = action.term;
  draft.loading = true;
  draft.data = { rows: [], count: 0, offset: 0 }
});

const handleListSuccess = (state = INITIAL_STATE, action) => produce(state, draft => {
  draft.loading = false;
  draft.data = action.data;
});

const handleLoad = (state = INITIAL_STATE, action) => produce(state, draft => {
  draft.model = {};
  draft.formLoading = true;
});

const handleClearModel = (state = INITIAL_STATE, action) => produce(state, draft => {
  draft.model = null;
  draft.formLoading = false;
});

const handleLoadSuccess = (state = INITIAL_STATE, action) => produce(state, draft => {
  draft.formLoading = false;
  draft.model = action.model;
});

export default createReducer(INITIAL_STATE, {
  [Types.LIST]: handleList,
  [Types.LIST_SUCCESS]: handleListSuccess,
  [Types.LOAD]: handleLoad,
  [Types.LOAD_SUCCESS]: handleLoadSuccess,
  [Types.CLEAR_MODEL]: handleClearModel,
});
