import React from 'react';
import styled from 'styled-components';
import logo from '~/assets/logo-white-v.svg';
import logoInverse from '~/assets/logo-v.svg';
import PropTypes from 'prop-types';

const BaseImage = styled.img`
    height: ${(props) => props.height || '55px'};
    border: none;
    user-select: none;
    pointer-events: none;
  `

const Image = ({ inverse, height }) => <BaseImage src={!inverse ? logo : logoInverse} height={height} alt="logo"></BaseImage>

Image.propTypes = {
  height: PropTypes.string
}

export default Image;
