import { combineReducers } from 'redux';
import auth from '~/store/modules/auth';
import account from '~/store/modules/account';
import header from '~/store/modules/header';
import profile from '~/store/modules/profile';
import user from '~/store/modules/user';
import company from '~/store/modules/company';
import bankAccount from '~/store/modules/bank-account';
import bill from '~/store/modules/bill';
import billClient from '~/store/modules/bill-client';
import prepareBills from '~/store/modules/prepare-bills';
import setting from '~/store/modules/setting';
import response from '~/store/modules/response';
import shipping from '~/store/modules/shipping';
import businessPartner from '~/store/modules/business-partner';
import representative from '~/store/modules/representative';
import dashboard from '~/store/modules/dashboard';
import digitalCharge from '~/store/modules/digital-charge';
import paymentConciliation from '~/store/modules/payment-conciliation';
import paymentOrder from '~/store/modules/payment-order';
import bankStatement from '~/store/modules/bank-statement';
import bankContract from '~/store/modules/bank-contract';
import emailBatch from '~/store/modules/email-batch';
import parser from '~/store/modules/parser';
import preparePaymentOrder from '~/store/modules/prepare-payment-order';
import paymentApproval from '~/store/modules/payment-approval';
import emailTemplate from '~/store/modules/email-template';
import emailSetting from '~/store/modules/email-setting';
import webhookBill from '~/store/modules/webhook-bill';
import webhookPaymentOrder from '~/store/modules/webhook-payment-order';
import bankStatementClassifications from '~/store/modules/bank-statement-classifications';
import prepareBankStatement from '~/store/modules/prepare-bank-statement';
import creditsReceiveds from '~/store/modules/credits-receiveds';
import reportRequest from '~/store/modules/report-request';
import ediFile from '~/store/modules/edi-file';
import paymentEmailBatch from '~/store/modules/payment-email-batch';
import paymentApprovalsPending from '~/store/modules/payment-approvals-pending';

import { connectRouter } from 'connected-react-router';

export default history =>
  combineReducers({
    router: connectRouter(history),
    auth,
    account,
    header,
    profile,
    user,
    company,
    bankAccount,
    bill,
    billClient,
    prepareBills,
    setting,
    response,
    shipping,
    businessPartner,
    representative,
    dashboard,
    digitalCharge,
    paymentConciliation,
    paymentOrder,
    bankStatement,
    bankContract,
    emailBatch,
    parser,
    preparePaymentOrder,
    paymentApproval,
    emailTemplate,
    emailSetting,
    webhookBill,
    webhookPaymentOrder,
    bankStatementClassifications,
    prepareBankStatement,
    creditsReceiveds,
    reportRequest,
    ediFile,
    paymentEmailBatch,
    paymentApprovalsPending
  });
