import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import { Background, Container, Title, Content, Footer } from '~/components/confirm/styles';
import { Button } from '~/components/button';
import { Formik } from 'formik';
import { TextArea } from '~/components/form';

class ConfirmMulti extends React.Component {
  state = {
    isOpen: false,
    confirmProps: {}
  };

  resolve = null;

  static create(props = {}) {
    const el = document.createElement('div');
    document.body.appendChild(el);
    return ReactDOM.render(<ConfirmMulti confirmProps={props} />, el);
  }

  selectOption = (option, values) => {
    const asObject = _.get(this.state, 'confirmProps.asObject') || false;

    this.setState({ isOpen: false });
    this.resolve(!asObject ? option : { option, prompt: values.prompt });
  };

  show = (props = {}) => {
    if ((props.height && isNaN(props.height)) || (props.width && isNaN(props.width))) {
      throw new Error('width and height must be numeric!')
    }
    const confirmProps = { ...this.props.confirmProps, ...props };
    this.setState({ isOpen: true, confirmProps });
    return new Promise(res => {
      this.resolve = res;
    });
  };

  render() {
    const { isOpen, confirmProps } = this.state,
      { title, text, width = 450, height = 120, options, isPrompt, promptLabel, promptLength = 10 } = confirmProps,
      useHeight = isPrompt ? (height + 92) : height;

    if (!isOpen) {
      return null;
    }
    return (
      <Background isOpen={isOpen}>
        <Container height={useHeight > window.innerHeight ? '90%' : `${useHeight}px`} width={width > window.innerWidth ? '90%' : `${width}px`}>
          <Title>{title}</Title>

          <Formik
            enableReinitialize={true}
            validateOnMount={false}
            initialValues={{}}>
            {({ values }) => <>
              <Content>
                <div dangerouslySetInnerHTML={{__html: text}} />

                {isPrompt &&
                  <div style={{ marginTop: '10px' }}>
                    <TextArea
                      name="prompt"
                      label={promptLabel || 'Mensagem'}
                      rows={2}
                      />
                  </div>
                }
              </Content>
              <Footer>
                {_.map(options, (option, index) => (
                  <Button
                    key={index}
                    color={option.color || (option.primary ? 'primary' : '')}
                    disabled={option.primary && isPrompt && _.size(_.get(values, 'prompt')) < promptLength}
                    onClick={() => this.selectOption(option.value, values)}>
                    {option.label}
                  </Button>
                ))}
              </Footer>
            </>}
          </Formik>
        </Container>
      </Background>
    );
  }
}

export default ConfirmMulti;
