import { useState, useEffect } from 'react';
import _ from 'lodash';

const gmapsLink = ({ latitude, longitude }) => {
  let lat = Number(latitude || 0).toFixed(6),
    long = Number(longitude || 0).toFixed(6);
  return `http://maps.google.com/maps?q=${lat},${long}`;
};

const usePosition = (watch = false) => {
  const [position, setPosition] = useState({});
  const [error, setError] = useState(null);
  const [permission, setPermission] = useState(null);
  const settings = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };
  
  const onChange = (position) => {
    const data = _.pick(position.coords || {}, 'latitude', 'longitude', 'accuracy', 'heading', 'speed');
    data.timestamp = _.get(position, 'timestamp');
    data.link = gmapsLink(data);
    setPosition(data);
  };

  const onError = ({ code, message }) => {
    if (message === 'User denied Geolocation') {
      setError('denied');
    } else {
      console.log(error);
    }
  };

  const accessLocation = () => navigator.geolocation.getCurrentPosition(onChange, onError, settings);

  useEffect(() => {
    navigator.permissions
      .query({ name: 'geolocation' })
      .then((result) => {
        setPermission(result.state);
        if (result.state === 'denied') {
          setError('denied');
        }
        result.onchange = function () {
          setPermission(result.state);
        };
      });

    const geo = navigator.geolocation;
    if (!geo) {
      setError('unsupported');
      return;
    }
    if (watch) {
      const watcher = navigator.geolocation.watchPosition(onChange, onError, settings);
      return () => navigator.geolocation.clearWatch(watcher);
    }
    accessLocation();
    // eslint-disable-next-line
  }, []);

  return { position, positionPerm: permission, positionError: error };
};

export default usePosition;