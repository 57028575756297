import React, { useState } from 'react';
import _ from 'lodash';
import { MdDelete } from 'react-icons/md';
import { FormContainer, DocumentContainer, RemoveBtn } from '~/pages/shippings/styles';
import DataTable from '~/components/datatable';
import Fieldset from '~/components/fieldset';
import { InputLabel } from '~/components/form';
import { Row, FlexRow, FlexCol } from '~/components/layout';
import Loading from '~/components/loading';
import { formats } from 'helper';
import TextWithHint from '~/components/text-with-hint';

const getRows = (array = [], offset, limit) => {
  let list = _.orderBy(array, ['billData.ourNumber'], ['desc']);
  return list.slice(offset, offset + limit);
};

export const ShippingForm = ({
  errors,
  touched,
  values,
  loading,
  loadingMessage,
  canWrite,
  setFieldValue,
  openDocument,
  previewMode,
  removeBill,
  removePayment,
  hasAccountsReceivable,
  hasAccountsPayable
}) => {
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(20);
  const isPayment = _.get(values, 'movementType') === 'payment';
  const documents = !isPayment ? values.bills : values.paymentOrders;
  const docField = !isPayment ? 'bill' : 'paymentOrder';
  const count = _.size(documents);
  const removeAction = isPayment ? removePayment : removeBill;
  const bankAccount = _.get(values, 'bankAccount') || {};

  if (loading) {
    return (
      <FormContainer>
        <Loading size={36} label={loadingMessage || 'Processando...'} />
      </FormContainer>
    );
  }

  const onChangePage = (page, limit) => {
    setOffset(page);
    setLimit(limit);
  };

  let columns = [
    {
      name: 'Vencimento',
      selector: 'occurrence',
      width: '120px',
      cell: row => <TextWithHint text={formats.dateTimeZone(_.get(row, 'dueDate') || _.get(row, `${docField}.dueDate`), 'dd/MM/yyyy')}/>
    },
    {
      name: 'Ocorrência',
      selector: 'occurrence',
      center: true,
      cell: row => <TextWithHint text={`${_.get(row, 'occurrence.code')} - ${_.get(row, 'occurrence.description')}`} />
    },
    {
      name: 'Valor Doc.',
      selector: docField,
      width: '140px',
      right: true,
      cell: row => <TextWithHint text={_.get(row, `${docField}.isPartial`) ? formats.currency(_.get(row, `${docField}.baseAmount`)) : formats.currency(_.get(row, `${docField}.value`))} />
    }
  ];

  if (!isPayment) {
    columns.unshift({
      name: 'Nosso Número',
      selector: 'bill',
      width: '150px',
      cell: row => <TextWithHint text={_.get(row, 'bill.billData.ourNumber')} />
    });
  } else {
    columns.unshift({
      name: 'Nº Documento',
      selector: 'paymentOrder',
      width: '150px',
      center: true,
      cell: row => <TextWithHint text={_.get(row, 'paymentOrder.documentNumber')} />
    });
    columns.unshift({
      name: 'Tipo',
      selector: 'paymentOrder',
      width: '220px',
      cell: row => <TextWithHint text={_.get(row, 'paymentOrder.paymentOrderType.name')} />
    });
    columns.push({
      name: 'Valor a Pagar',
      selector: 'paymentOrder',
      width: '140px',
      right: true,
      cell: row => <TextWithHint text={_.get(row, 'paymentOrder.isPartial') ? formats.currency(_.get(row, `paymentOrder.value`)) : formats.currency(_.get(row, 'paymentOrder.orderData.total') || _.get(row, `paymentOrder.value`))} />
    });
  }
  if (_.get(values, 'status') === 'open') {
    columns.push({
      name: 'Ação',
      width: '80px',
      center: true,
      cell: row => {
        let occurrence = _.get(row, 'occurrence.code');
        return (
          <RemoveBtn onClick={() => removeAction(values, _.get(row, `${docField}.id`), row.id, occurrence)}>
            <MdDelete />
          </RemoveBtn>
        );
      }
    });
  }
  return (
    <FormContainer>
      <FlexRow>
        <FlexCol flex="1">
          <InputLabel label="Banco" value={!_.get(bankAccount, 'bank.code') ? '' : `${_.get(bankAccount, 'bank.code')} - ${_.get(bankAccount, 'bank.name')}`} />
        </FlexCol>

        <FlexCol flex="0 0 32%">
          <InputLabel label="Número sequencial" value={_.get(values, 'sequence') || ''} />
        </FlexCol>
      </FlexRow>

      <Row span={3}>
        <InputLabel label="Agência" value={!bankAccount.bankBranch ? '' : `${bankAccount.bankBranch}-${bankAccount.bankBranchDigit}`} />

        <InputLabel label="Conta" value={!bankAccount.accountNumber ? '' : `${bankAccount.accountNumber}-${bankAccount.accountNumberDigit}`} />

        <InputLabel
          label="Tipo"
          value={`${/^(payment)$/.test(_.get(values, 'movementType')) ? 'Pagamento' : 'Cobrança'} - ${_.toUpper(_.get(values, 'bankContract.layoutType')) ||
            ''}`}
        />
      </Row>

      <Row span={3}>
        <InputLabel label="Motivo do cancelamento" value={_.get(values, 'extras.cancelationReason')} />
      </Row>

      <DocumentContainer>
        <Fieldset label={isPayment ? 'Pagamentos' : 'Faturas'}>
          {
            <DataTable
              emptyText={isPayment ? 'Nenhum pagamento vinculado' : 'Nenhuma fatura vinculada'}
              pageSize={limit}
              data={{ rows: getRows(documents, offset, limit), offset, count, limit: limit }}
              onRowClicked={r => openDocument(isPayment, _.get(r, `${docField}.id`))}
              onChangePage={onChangePage}
              hideUpdateButton={true}
              columns={columns}
            />
          }
        </Fieldset>
      </DocumentContainer>
    </FormContainer>
  );
};
