import _ from 'lodash';

export const rejectCodesMap = {
  '001': ['03'],
  '033': ['03'],
  '104': ['03'],
  '237': ['03'],
  '341': ['03'],
};

export const occurencesReasonMap = {
  '001': {
    '02': {
      '0': 'Por meio magnetico',
      '11': 'Por via convencional',
      '16': 'Por alteracao do codigo do cedente',
      '17': 'Por alteracao da variacao',
      '18': 'por alteracao da carteira'
    },
    '09': {
      '0': 'Solicitada pelo cliente',
      '15': 'Protestado',
      '18': 'Por alteracao da carteira',
      '19': 'Debito automatico',
      '31': 'Liquidado anteriormente',
      '32': 'Habilitado em processo',
      '33': 'Incobravel por nosso intermedio',
      '34': 'Transferido para creditos em liquidação invalida',
      '46': 'Por alteracao do codigo do cedente',
      '47': 'Por alteracao da variacao',
      '51': 'Acerto',
      '90': 'Baixa automatica'
    },
    '10': {
      '0': 'Solicitada pelo cliente',
      '15': 'Protestado',
      '18': 'Por alteracao da carteira',
      '19': 'Debito automatico',
      '31': 'Liquidado anteriormente',
      '32': 'Habilitado em processo',
      '33': 'Incobravel por nosso intermedio',
      '34': 'Transferido para creditos em liquidação invalida',
      '46': 'Por alteracao do codigo do cedente',
      '47': 'Por alteracao da variacao',
      '51': 'Acerto',
      '90': 'Baixa automatica'
    },
    '20': {
      '0': 'Solicitada pelo cliente',
      '15': 'Protestado',
      '18': 'Por alteracao da carteira',
      '19': 'Debito automatico',
      '31': 'Liquidado anteriormente',
      '32': 'Habilitado em processo',
      '33': 'Incobravel por nosso intermedio',
      '34': 'Transferido para creditos em liquidação invalida',
      '46': 'Por alteracao do codigo do cedente',
      '47': 'Por alteracao da variacao',
      '51': 'Acerto',
      '90': 'Baixa automatica'
    },
    '03': {
      '1': 'Identificacao invalida',
      '2': 'Variacao da carteira invalida',
      '3': 'Valor dos juros por um dia inválido',
      '4': 'Valor do desconto invalido',
      '5': 'Especie de titulo invalida para carteira/variação',
      '6': 'Especie de valor variavel inválido',
      '7': 'Prefixo da agencia usuaria inválido',
      '8': 'Valor do titulo/apolice invalido',
      '9': 'Data de vencimento invalida',
      '10': 'Fora do prazo',
      '11': 'Inexistencia de margem para desconto',
      '12': 'O Banco nao tem agencia na praça do sacado',
      '13': 'Razoes cadastrais',
      '14': 'Sacado interligado com o sacador',
      '15': 'Titulo sacado contra orgao do Poder Público',
      '16': 'Titulo preenchido de forma irregular',
      '17': 'Titulo rasurado',
      '18': 'Endereço do sacado não localizado ou incompleto',
      '19': 'Codigo do cedente invalido',
      '20': 'Nome/endereco do cliente não informado/ECT/',
      '21': 'Carteira invalida',
      '22': 'Quantidade de valor variavel inválida',
      '23': 'Faixa nosso-numero excedida',
      '24': 'Valor do abatimento invalido',
      '25': 'Novo numero do tit. dado pelo cedente inválido',
      '26': 'Valor do IOF de seguro invalido',
      '27': 'Nome do sacado/cedente invalido ou não informado',
      '28': 'Data do novo vencimento invalida',
      '29': 'Endereco nao informado',
      '30': 'Registro de titulo já liquidado',
      '31': 'Numero do bordero invalido',
      '32': 'Nome da pessoa autorizada inválido',
      '33': 'Nosso numero jah existente',
      '34': 'Numero da prestacao do contrato inválido',
      '35': 'percentual de desconto invalido',
      '36': 'Dias para fichamento de protesto inválido',
      '37': 'Data de emissao do titulo inválida',
      '38': 'Data do vencimento anterior aa data da emissao do titulo',
      '39': 'Comando de alteracao indevido para a carteira',
      '40': 'Tipo de moeda invalido',
      '41': 'Abatimento nao permitido',
      '42': 'CEP/UF invalido/nao compativeis /ECT/',
      '43': 'Codigo de unidade variavel incompatível com a data de emissão do título',
      '44': 'Dados para debito ao sacado inválidos',
      '45': 'Carteira/variacao encerrada',
      '46': 'Convenio encerrado',
      '47': 'Titulo tem valor diverso do informado',
      '48': 'Motivo de baixa invalido para a carteira',
      '49': 'Abatimento a cancelar nao consta do título',
      '50': 'Comando incompativel com a carteira',
      '51': 'Codigo do convenente invalido',
      '52': 'Abatimento igual ou maior que o valor do título',
      '53': 'Titulo jah se encontra na situação pretendida',
      '54': 'Titulo fora do prazo admitido para a conta 1',
      '55': 'Novo vencimento fora dos limites da carteira',
      '56': 'Titulo nao pertence ao convenente',
      '57': 'Variacao incompativel com a carteira',
      '58': 'Impossivel a transferencia para a cart. indicada',
      '59': 'Titulo vencido em transferência para a cart.51',
      '60': 'Titulo com prazo superior a 179 dias em transferencia para carteira 51',
      '61': 'Titulo jah foi fichado para protesto',
      '62': 'Alteracao da situacao de debito inválida para o código de responsabilidade',
      '63': 'DV do nosso numero invalido',
      '64': 'Titulo nao passivel de débito/baixa-situação anormal',
      '65': 'Titulo com ordem de não protestar-não pode ser encaminhado a cartório',
      '67': 'Titulo/carne rejeitado',
      '69': 'Valor/Percentual de Juros Inválido',
      '70': 'Título já se encontra isento de juros',
      '71': 'Código de Juros Inválido',
      '72': 'Pedido de Protesto Recusado - Cidade do Sacado Não Protesta Títulos do sacado.',
      '80': 'Nosso numero invalido',
      '81': 'Data para concessao do desconto inválida.',
      '82': 'CEP do sacado invalido',
      '83': 'Carteira/variacao nao localizada no cedente',
      '84': 'Titulo nao localizado na existência',
      '99': 'Outros motivos'
    },
    '06': {
      '1': 'Terminal de Auto-Atendimento',
      '2': 'Internet',
      '3': 'Central de Atendimento (URA)',
      '4': 'Gerenciador Financeiro',
      '5': 'Central de Atendimento',
      '6': 'Outro Canal de Auto-atendimento',
      '7': 'Correspondente Bancário',
      '8': 'Guichê de Caixa',
      '9': 'Arquivo-Eletrônico',
      '10': 'Compensação',
      '11': 'Outro Canal Eletrônico'
    }
  },
  '237': {
    '06': {
      '1': 'CICS (AT00)',
      '2': 'BDN MULTI SAQUE',
      '7': 'TERM. GER. CBCA PF8',
      '10': 'TER. GER. CBCA SENHAS',
      '13': 'FONE FÁCIL',
      '14': 'INTERNET',
      '24': 'TERM. MULTI FUNÇÃO',
      '27': 'PAG CONTAS',
      '35': 'NET EMPRESA',
      '52': 'SHOP CREDIT',
      '67': 'DEB AUTOMÁTICO',
      '73': 'PAG FOR',
      '74': 'BOCA DO CAIXA',
      '75': 'RETAGUARDA',
      '76': 'SUBCENTRO',
      '77': 'CARTAO DE CRÉDITO',
      '78': 'COMPENSAÇÃO ELETRÔNICA',
      '80': 'BANCO POSTAL',
      '82': 'BRADESCO EXPRESSO'
    },
    '15': {
      '1': 'CICS (AT00)',
      '2': 'BDN MULTI SAQUE',
      '7': 'TERM. GER. CBCA PF8',
      '10': 'TER. GER. CBCA SENHAS',
      '13': 'FONE FÁCIL',
      '14': 'INTERNET',
      '24': 'TERM. MULTI FUNÇÃO',
      '27': 'PAG CONTAS',
      '35': 'NET EMPRESA',
      '52': 'SHOP CREDIT',
      '67': 'DEB AUTOMÁTICO',
      '73': 'PAG FOR',
      '74': 'BOCA DO CAIXA',
      '75': 'RETAGUARDA',
      '76': 'SUBCENTRO',
      '77': 'CARTAO DE CRÉDITO',
      '78': 'COMPENSAÇÃO ELETRÔNICA',
      '80': 'BANCO POSTAL',
      '82': 'BRADESCO EXPRESSO'
    },
    '17': {
      '1': 'CICS (AT00)',
      '2': 'BDN MULTI SAQUE',
      '7': 'TERM. GER. CBCA PF8',
      '10': 'TER. GER. CBCA SENHAS',
      '13': 'FONE FÁCIL',
      '14': 'INTERNET',
      '24': 'TERM. MULTI FUNÇÃO',
      '27': 'PAG CONTAS',
      '35': 'NET EMPRESA',
      '52': 'SHOP CREDIT',
      '67': 'DEB AUTOMÁTICO',
      '73': 'PAG FOR',
      '74': 'BOCA DO CAIXA',
      '75': 'RETAGUARDA',
      '76': 'SUBCENTRO',
      '77': 'CARTAO DE CRÉDITO',
      '78': 'COMPENSAÇÃO ELETRÔNICA',
      '80': 'BANCO POSTAL',
      '82': 'BRADESCO EXPRESSO'
    },
    '02': {
      '0': 'Ocorrência aceita',
      '1': 'Código do Banco inválido',
      '4': 'Código do movimento não permitido para a carteira',
      '15': 'Características da cobrança incompatíveis',
      '17': 'Data de vencimento anterior a data de emissão',
      '21': 'Espécie do Título inválido',
      '24': 'Data da emissão inválida',
      '27': 'Valor/taxa de juros mora inválido',
      '38': 'Prazo para protesto inválido',
      '39': 'Pedido para protesto não permitido para título',
      '43': 'Prazo para baixa e devolução inválido',
      '45': 'Nome do Sacado inválido',
      '46': 'Tipo/num. de inscrição do Sacado inválidos',
      '47': 'Endereço do Sacado não informado',
      '48': 'CEP Inválido',
      '50': 'CEP referente a Banco correspondente',
      '53': 'Nº de inscrição do Sacador/avalista inválidos (CPF/CNPJ)',
      '54': 'Sacador/avalista não informado',
      '67': 'Débito automático agendado',
      '68': 'Débito não agendado - erro nos dados de remessa',
      '69': 'Débito não agendado - Sacado não consta no cadastro de autorizante',
      '70': 'Débito não agendado - Cedente não autorizado pelo Sacado',
      '71': 'Débito não agendado - Cedente não participa da modalidade de déb.automático',
      '72': 'Débito não agendado - Código de moeda diferente de R$',
      '73': 'Débito não agendado - Data de vencimento inválida/vencida',
      '75': 'Débito não agendado - Tipo do número de inscrição do sacado debitado inválido',
      '86': 'Seu número do documento inválido',
      '89': 'E-mail Sacado não enviado – título com débito automático',
      '90': 'E-mail sacado não enviado – título de cobrança sem registro'
    },
    '03': {
      '2': 'Código do registro detalhe inválido',
      '3': 'Código da ocorrência inválida',
      '4': 'Código de ocorrência não permitida para a carteira',
      '5': 'Código de ocorrência não numérico',
      '7': 'Agência/conta/Digito -',
      '8': 'Nosso número inválido',
      '9': 'Nosso número duplicado',
      '10': 'Carteira inválida',
      '13': 'Identificação da emissão do bloqueto inválida',
      '16': 'Data de vencimento inválida',
      '18': 'Vencimento fora do prazo de operação',
      '20': 'Valor do Título inválido',
      '21': 'Espécie do Título inválida',
      '22': 'Espécie não permitida para a carteira',
      '24': 'Data de emissão inválida',
      '28': 'Código do desconto inválido',
      '38': 'Prazo para protesto inválido',
      '44': 'Agência Cedente não prevista',
      '45': 'Nome do sacado não informado',
      '46': 'Tipo/número de inscrição do sacado inválidos',
      '47': 'Endereço do sacado não informado',
      '48': 'CEP Inválido',
      '50': 'CEP irregular - Banco Correspondente',
      '63': 'Entrada para Título já cadastrado',
      '65': 'Limite excedido',
      '66': 'Número autorização inexistente',
      '68': 'Débito não agendado - erro nos dados de remessa',
      '69': 'Débito não agendado - Sacado não consta no cadastro de autorizante',
      '70': 'Débito não agendado - Cedente não autorizado pelo Sacado',
      '71': 'Débito não agendado - Cedente não participa do débito Automático',
      '72': 'Débito não agendado - Código de moeda diferente de R$',
      '73': 'Débito não agendado - Data de vencimento inválida',
      '74': 'Débito não agendado - Conforme seu pedido, Título não registrado',
      '75': 'Débito não agendado – Tipo de número de inscrição do debitado inválido'
    },
    '10': {
      '0': 'Baixado Conforme Instruções da Agência',
      '14': 'Título Protestado',
      '15': 'Título excluído',
      '16': 'Título Baixado pelo Banco por decurso Prazo',
      '20': 'Título Baixado e transferido para desconto'
    },
    '27': {
      '4': 'Código de ocorrência não permitido para a carteira',
      '7': 'Agência/Conta/dígito inválidos',
      '8': 'Nosso número inválido',
      '10': 'Carteira inválida',
      '15': 'Carteira/Agência/Conta/nosso número inválidos',
      '40': 'Título com ordem de protesto emitido',
      '42': 'Código para baixa/devolução via Telebradesco inválido',
      '60': 'Movimento para Título não cadastrado',
      '77': 'Transferência para desconto não permitido para a carteira',
      '85': 'Título com pagamento vinculado'
    },
    '28': {
      '2': 'Tarifa de permanência título cadastrado',
      '3': 'Tarifa de sustação',
      '4': 'Tarifa de protesto',
      '5': 'Tarifa de outras instruções',
      '6': 'Tarifa de outras ocorrências',
      '8': 'Custas de protesto',
      '12': 'Tarifa de registro',
      '13': 'Tarifa título pago no Bradesco',
      '14': 'Tarifa título pago compensação',
      '15': 'Tarifa título baixado não pago',
      '16': 'Tarifa alteração de vencimento',
      '17': 'Tarifa concessão abatimento',
      '18': 'Tarifa cancelamento de abatimento',
      '19': 'Tarifa concessão desconto',
      '20': 'Tarifa cancelamento desconto',
      '21': 'Tarifa título pago cics',
      '22': 'Tarifa título pago Internet',
      '23': 'Tarifa título pago term. gerencial serviços',
      '24': 'Tarifa título pago Pág-Contas',
      '25': 'Tarifa título pago Fone Fácil',
      '26': 'Tarifa título Déb. Postagem',
      '27': 'Tarifa impressão de títulos pendentes',
      '28': 'Tarifa título pago BDN',
      '29': 'Tarifa título pago Term. Multi Função',
      '30': 'Impressão de títulos baixados',
      '31': 'Impressão de títulos pagos',
      '32': 'Tarifa título pago Pagfor',
      '33': 'Tarifa reg/pgto – guichê caixa',
      '34': 'Tarifa título pago retaguarda',
      '35': 'Tarifa título pago Subcentro',
      '36': 'Tarifa título pago Cartão de Crédito',
      '37': 'Tarifa título pago Comp Eletrônica',
      '38': 'Tarifa título Baix. Pg. Cartório',
      '39': 'Tarifa título baixado acerto BCO',
      '40': 'Baixa registro em duplicidade',
      '41': 'Tarifa título baixado decurso prazo',
      '42': 'Tarifa título baixado Judicialmente',
      '43': 'Tarifa título baixado via remessa',
      '44': 'Tarifa título baixado rastreamento',
      '45': 'Tarifa título baixado conf. Pedido',
      '46': 'Tarifa título baixado protestado',
      '47': 'Tarifa título baixado p/ devolução',
      '48': 'Tarifa título baixado franco pagto',
      '49': 'Tarifa título baixado SUST/RET/CARTÓRIO',
      '50': 'Tarifa título baixado SUS/SEM/REM/CARTÓRIO',
      '51': 'Tarifa título transferido desconto',
      '52': 'Cobrado baixa manual',
      '53': 'Baixa por acerto cliente',
      '54': 'Tarifa baixa por contabilidade',
      '55': 'BIFAX',
      '56': 'Consulta informações via internet',
      '57': 'Arquivo retorno via internet',
      '58': 'Tarifa emissão Papeleta',
      '59': 'Tarifa fornec papeleta semi preenchida',
      '60': 'Acondicionador de papeletas (RPB)S',
      '61': 'Acond. De papelatas (RPB)s PERSONAL',
      '62': 'Papeleta formulário branco',
      '63': 'Formulário A4 serrilhado',
      '64': 'Fornecimento de softwares transmiss',
      '65': 'Fornecimento de softwares consulta',
      '66': 'Fornecimento Micro Completo',
      '67': 'Fornecimento MODEN',
      '68': 'Fornecimento de máquina FAX',
      '69': 'Fornecimento de máquinas óticas',
      '70': 'Fornecimento de Impressoras',
      '71': 'Reativação de título',
      '72': 'Alteração de produto negociado',
      '73': 'Tarifa emissão de contra recibo',
      '74': 'Tarifa emissão 2ª via papeleta',
      '75': 'Tarifa regravação arquivo retorno',
      '76': 'Arq. Títulos a vencer mensal',
      '77': 'Listagem auxiliar de crédito',
      '78': 'Tarifa cadastro cartela instrução permanente',
      '79': 'Canalização de Crédito',
      '80': 'Cadastro de Mensagem Fixa',
      '81': 'Tarifa reapresentação automática título',
      '82': 'Tarifa registro título déb. Automático',
      '83': 'Tarifa Rateio de Crédito',
      '84': 'Emissão papeleta sem valor',
      '85': 'Sem uso',
      '86': 'Cadastro de reembolso de diferença',
      '87': 'Relatório fluxo de pagto',
      '88': 'Emissão Extrato mov. Carteira',
      '89': 'Mensagem campo local de pagto',
      '90': 'Cadastro Concessionária serv. Publ.',
      '91': 'Classif. Extrato Conta Corrente',
      '92': 'Contabilidade especial',
      '93': 'Realimentação pagto',
      '94': 'Repasse de Créditos',
      '95': 'Tarifa reg. pagto Banco Postal',
      '96': 'Tarifa reg. Pagto outras mídias',
      '97': 'Tarifa Reg/Pagto – Net Empresa',
      '98': 'Tarifa título pago vencido',
      '99': 'TR Tít. Baixado por decurso prazo',
      '100': 'Arquivo Retorno Antecipado',
      '101': 'Arq retorno Hora/Hora',
      '102': 'TR. Agendamento Déb Aut',
      '103': 'TR. Tentativa cons Déb Aut',
      '104': 'TR Crédito on-line',
      '105': 'TR. Agendamento rat. Crédito',
      '106': 'TR Emissão aviso rateio',
      '107': 'Extrato de protesto',
      '110': 'Tarifa reg/pagto Bradesco Expresso',
    },
    '30': {
      '1': 'Código do Banco inválido',
      '4': 'Código de ocorrência não permitido para a carteira',
      '5': 'Código da ocorrência não numérico',
      '8': 'Nosso número inválido',
      '15': 'Característica da cobrança incompatível',
      '16': 'Data de vencimento inválido',
      '17': 'Data de vencimento anterior a data de emissão',
      '18': 'Vencimento fora do prazo de operação',
      '24': 'Data de emissão Inválida',
      '26': 'Código de juros de mora inválido',
      '27': 'Valor/taxa de juros de mora inválido',
      '28': 'Código de desconto inválido',
      '29': 'Valor do desconto maior/igual ao valor do Título',
      '30': 'Desconto a conceder não confere',
      '31': 'Concessão de desconto já existente ( Desconto anterior )',
      '32': 'Valor do IOF inválido',
      '33': 'Valor do abatimento inválido',
      '34': 'Valor do abatimento maior/igual ao valor do Título',
      '38': 'Prazo para protesto inválido',
      '39': 'Pedido de protesto não permitido para o Título',
      '40': 'Título com ordem de protesto emitido',
      '42': 'Código para baixa/devolução inválido',
      '46': 'Tipo/número de inscrição do sacado inválidos',
      '48': 'Cep Inválido',
      '53': 'Tipo/Número de inscrição do sacador/avalista inválidos',
      '54': 'Sacador/avalista não informado',
      '57': 'Código da multa inválido',
      '58': 'Data da multa inválida',
      '60': 'Movimento para Título não cadastrado',
      '79': 'Data de Juros de mora Inválida',
      '80': 'Data do desconto inválida',
      '85': 'Título com Pagamento Vinculado.',
      '88': 'E-mail Sacado não lido no prazo 5 dias',
      '91': 'E-mail sacado não recebido'
    },
    '32': {
      '1': 'Código do Banco inválido',
      '2': 'Código do registro detalhe inválido',
      '4': 'Código de ocorrência não permitido para a carteira',
      '5': 'Código de ocorrência não numérico',
      '7': 'Agência/Conta/dígito inválidos',
      '8': 'Nosso número inválido',
      '10': 'Carteira inválida',
      '15': 'Características da cobrança incompatíveis',
      '16': 'Data de vencimento inválida',
      '17': 'Data de vencimento anterior a data de emissão',
      '18': 'Vencimento fora do prazo de operação',
      '20': 'Valor do título inválido',
      '21': 'Espécie do Título inválida',
      '22': 'Espécie não permitida para a carteira',
      '24': 'Data de emissão inválida',
      '28': 'Código de desconto via Telebradesco inválido',
      '29': 'Valor do desconto maior/igual ao valor do Título',
      '30': 'Desconto a conceder não confere',
      '31': 'Concessão de desconto - Já existe desconto anterior',
      '33': 'Valor do abatimento inválido',
      '34': 'Valor do abatimento maior/igual ao valor do Título',
      '36': 'Concessão abatimento - Já existe abatimento anterior',
      '38': 'Prazo para protesto inválido',
      '39': 'Pedido de protesto não permitido para o Título',
      '40': 'Título com ordem de protesto emitido',
      '41': 'Pedido cancelamento/sustação para Título sem instrução de protesto',
      '42': 'Código para baixa/devolução inválido',
      '45': 'Nome do Sacado não informado',
      '46': 'Tipo/número de inscrição do Sacado inválidos',
      '47': 'Endereço do Sacado não informado',
      '48': 'CEP Inválido',
      '50': 'CEP referente a um Banco correspondente',
      '53': 'Tipo de inscrição do sacador avalista inválidos',
      '60': 'Movimento para Título não cadastrado',
      '85': 'Título com pagamento vinculado',
      '86': 'Seu número inválido',
      '94': 'Título Penhorado – Instrução Não Liberada pela Agência'
    }
  },
  '104': {
    '03': {
      '1': 'Código do banco inválido.',
      '2': 'Código do registro inválido.',
      '3': 'Código do segmento inválido.',
      '5': 'Código de movimento inválido.',
      '6': 'Tipo/número de inscrição do cedente inválido.',
      '7': 'Agência/Conta/DV inválido.',
      '8': 'Nosso número inválido.',
      '9': 'Nosso número duplicado.',
      '10': 'Carteira inválida.',
      '11': 'Forma de cadastramento do título inválido.',
      '12': 'Tipo de documento inválido.',
      '13': 'Identificação da emissão do bloqueto inválida.',
      '14': 'Identificação da distribuição do bloqueto inválida.',
      '15': 'Características da cobrança incompatíveis.',
      '16': 'Data de vencimento inválida.',
      '20': 'Valor do título inválido.',
      '21': 'Espécie do título inválida.',
      '23': 'Aceite inválido.',
      '24': 'Data da emissão inválida.',
      '26': 'Código de juros de mora inválido.',
      '27': 'Valor/Taxa de juros de mora inválido.',
      '28': 'Código do desconto inválido.',
      '29': 'Valor do desconto maior ou igual ao valor do título.',
      '30': 'Desconto a conceder não confere.',
      '32': 'Valor do IOF inválido.',
      '33': 'Valor do abatimento inválido.',
      '37': 'Código para protesto inválido.',
      '38': 'Prazo para protesto inválido.',
      '40': 'Título com ordem de protesto emitida.',
      '42': 'Código para baixa/devolução inválido.',
      '43': 'Prazo para baixa/devolução inválido.',
      '44': 'Código da moeda inválido.',
      '45': 'Nome do sacado não informado.',
      '46': 'Tipo/número de inscrição do sacado inválido.',
      '47': 'Endereço do sacado não informado.',
      '48': 'CEP inválido.',
      '49': 'CEP sem praça de cobrança (não localizado).',
      '52': 'Unidade da federação inválida.',
      '53': 'Tipo/número de inscrição do sacador/avalista inválido.',
      '57': 'Código da multa inválido.',
      '58': 'Data da multa inválida.',
      '59': 'Valor/Percentual da multa inválido.',
      '60': 'Movimento para título não cadastrado.',
      '61': 'Agência cobradora inválida.',
      '62': 'Tipo de impressão inválido.',
      '63': 'Entrada para título já cadastrado.',
      '68': 'Movimentação inválida para o título.',
      '69': 'Alteração de dados inválida.',
      '70': 'Apelido do cliente não cadastrado.',
      '71': 'Erro na composição do arquivo.',
      '72': 'Lote de serviço inválido.',
      '73': 'Código do cedente inválido.',
      '74': 'Cedente não pertence a cobrança eletrônica/apelido não confere com cedente.',
      '75': 'Nome da empresa inválido.',
      '76': 'Nome do banco inválido.',
      '77': 'Código da remessa inválido.',
      '78': 'Data/Hora de geração do arquivo inválida.',
      '79': 'Número seqüencial do arquivo inválido.',
      '80': 'Número da versão do Layout do arquivo/lote inválido.',
      '81': 'Literal ‘REMESSA-TESTE’ válida somente para fase de testes.',
      '82': 'Literal ‘REMESSA-TESTE’ obrigatório para fase de testes.',
      '83': 'Tipo/número de inscrição da empresa inválido.',
      '84': 'Tipo de operação inválido.',
      '85': 'Tipo de serviço inválido.',
      '86': 'Forma de lançamento inválido.',
      '87': 'Número da remessa inválido.',
      '88': 'Número da remessa menor/igual que da remessa anterior.',
      '89': 'Lote de serviço divergente.',
      '90': 'Número seqüencial do registro inválido.',
      '91': 'Erro na seqüência de segmento do registro detalhe.',
      '92': 'Código de movimento divergente entre grupo de segmentos.',
      '93': 'Quantidade de registros no lote inválido.',
      '94': 'Quantidade de registros no lote divergente.',
      '95': 'Quantidade de lotes do arquivo inválido.',
      '96': 'Quantidade de lotes no arquivo divergente.',
      '97': 'Quantidade de registros no arquivo inválido.'
    },
    '26': {
      '1': 'Código do banco inválido.',
      '2': 'Código do registro inválido.',
      '3': 'Código do segmento inválido.',
      '5': 'Código de movimento inválido.',
      '6': 'Tipo/número de inscrição do cedente inválido.',
      '7': 'Agência/Conta/DV inválido.',
      '8': 'Nosso número inválido.',
      '9': 'Nosso número duplicado.',
      '10': 'Carteira inválida.',
      '11': 'Forma de cadastramento do título inválido.',
      '12': 'Tipo de documento inválido.',
      '13': 'Identificação da emissão do bloqueto inválida.',
      '14': 'Identificação da distribuição do bloqueto inválida.',
      '15': 'Características da cobrança incompatíveis.',
      '16': 'Data de vencimento inválida.',
      '20': 'Valor do título inválido.',
      '21': 'Espécie do título inválida.',
      '23': 'Aceite inválido.',
      '24': 'Data da emissão inválida.',
      '26': 'Código de juros de mora inválido.',
      '27': 'Valor/Taxa de juros de mora inválido.',
      '28': 'Código do desconto inválido.',
      '29': 'Valor do desconto maior ou igual ao valor do título.',
      '30': 'Desconto a conceder não confere.',
      '32': 'Valor do IOF inválido.',
      '33': 'Valor do abatimento inválido.',
      '37': 'Código para protesto inválido.',
      '38': 'Prazo para protesto inválido.',
      '40': 'Título com ordem de protesto emitida.',
      '42': 'Código para baixa/devolução inválido.',
      '43': 'Prazo para baixa/devolução inválido.',
      '44': 'Código da moeda inválido.',
      '45': 'Nome do sacado não informado.',
      '46': 'Tipo/número de inscrição do sacado inválido.',
      '47': 'Endereço do sacado não informado.',
      '48': 'CEP inválido.',
      '49': 'CEP sem praça de cobrança (não localizado).',
      '52': 'Unidade da federação inválida.',
      '53': 'Tipo/número de inscrição do sacador/avalista inválido.',
      '57': 'Código da multa inválido.',
      '58': 'Data da multa inválida.',
      '59': 'Valor/Percentual da multa inválido.',
      '60': 'Movimento para título não cadastrado.',
      '61': 'Agência cobradora inválida.',
      '62': 'Tipo de impressão inválido.',
      '63': 'Entrada para título já cadastrado.',
      '68': 'Movimentação inválida para o título.',
      '69': 'Alteração de dados inválida.',
      '70': 'Apelido do cliente não cadastrado.',
      '71': 'Erro na composição do arquivo.',
      '72': 'Lote de serviço inválido.',
      '73': 'Código do cedente inválido.',
      '74': 'Cedente não pertence a cobrança eletrônica/apelido não confere com cedente.',
      '75': 'Nome da empresa inválido.',
      '76': 'Nome do banco inválido.',
      '77': 'Código da remessa inválido.',
      '78': 'Data/Hora de geração do arquivo inválida.',
      '79': 'Número seqüencial do arquivo inválido.',
      '80': 'Número da versão do Layout do arquivo/lote inválido.',
      '81': 'Literal ‘REMESSA-TESTE’ válida somente para fase de testes.',
      '82': 'Literal ‘REMESSA-TESTE’ obrigatório para fase de testes.',
      '83': 'Tipo/número de inscrição da empresa inválido.',
      '84': 'Tipo de operação inválido.',
      '85': 'Tipo de serviço inválido.',
      '86': 'Forma de lançamento inválido.',
      '87': 'Número da remessa inválido.',
      '88': 'Número da remessa menor/igual que da remessa anterior.',
      '89': 'Lote de serviço divergente.',
      '90': 'Número seqüencial do registro inválido.',
      '91': 'Erro na seqüência de segmento do registro detalhe.',
      '92': 'Código de movimento divergente entre grupo de segmentos.',
      '93': 'Quantidade de registros no lote inválido.',
      '94': 'Quantidade de registros no lote divergente.',
      '95': 'Quantidade de lotes do arquivo inválido.',
      '96': 'Quantidade de lotes no arquivo divergente.',
      '97': 'Quantidade de registros no arquivo inválido.'
    },
    '30': {
      '1': 'Código do banco inválido.',
      '2': 'Código do registro inválido.',
      '3': 'Código do segmento inválido.',
      '5': 'Código de movimento inválido.',
      '6': 'Tipo/número de inscrição do cedente inválido.',
      '7': 'Agência/Conta/DV inválido.',
      '8': 'Nosso número inválido.',
      '9': 'Nosso número duplicado.',
      '10': 'Carteira inválida.',
      '11': 'Forma de cadastramento do título inválido.',
      '12': 'Tipo de documento inválido.',
      '13': 'Identificação da emissão do bloqueto inválida.',
      '14': 'Identificação da distribuição do bloqueto inválida.',
      '15': 'Características da cobrança incompatíveis.',
      '16': 'Data de vencimento inválida.',
      '20': 'Valor do título inválido.',
      '21': 'Espécie do título inválida.',
      '23': 'Aceite inválido.',
      '24': 'Data da emissão inválida.',
      '26': 'Código de juros de mora inválido.',
      '27': 'Valor/Taxa de juros de mora inválido.',
      '28': 'Código do desconto inválido.',
      '29': 'Valor do desconto maior ou igual ao valor do título.',
      '30': 'Desconto a conceder não confere.',
      '32': 'Valor do IOF inválido.',
      '33': 'Valor do abatimento inválido.',
      '37': 'Código para protesto inválido.',
      '38': 'Prazo para protesto inválido.',
      '40': 'Título com ordem de protesto emitida.',
      '42': 'Código para baixa/devolução inválido.',
      '43': 'Prazo para baixa/devolução inválido.',
      '44': 'Código da moeda inválido.',
      '45': 'Nome do sacado não informado.',
      '46': 'Tipo/número de inscrição do sacado inválido.',
      '47': 'Endereço do sacado não informado.',
      '48': 'CEP inválido.',
      '49': 'CEP sem praça de cobrança (não localizado).',
      '52': 'Unidade da federação inválida.',
      '53': 'Tipo/número de inscrição do sacador/avalista inválido.',
      '57': 'Código da multa inválido.',
      '58': 'Data da multa inválida.',
      '59': 'Valor/Percentual da multa inválido.',
      '60': 'Movimento para título não cadastrado.',
      '61': 'Agência cobradora inválida.',
      '62': 'Tipo de impressão inválido.',
      '63': 'Entrada para título já cadastrado.',
      '68': 'Movimentação inválida para o título.',
      '69': 'Alteração de dados inválida.',
      '70': 'Apelido do cliente não cadastrado.',
      '71': 'Erro na composição do arquivo.',
      '72': 'Lote de serviço inválido.',
      '73': 'Código do cedente inválido.',
      '74': 'Cedente não pertence a cobrança eletrônica/apelido não confere com cedente.',
      '75': 'Nome da empresa inválido.',
      '76': 'Nome do banco inválido.',
      '77': 'Código da remessa inválido.',
      '78': 'Data/Hora de geração do arquivo inválida.',
      '79': 'Número seqüencial do arquivo inválido.',
      '80': 'Número da versão do Layout do arquivo/lote inválido.',
      '81': 'Literal ‘REMESSA-TESTE’ válida somente para fase de testes.',
      '82': 'Literal ‘REMESSA-TESTE’ obrigatório para fase de testes.',
      '83': 'Tipo/número de inscrição da empresa inválido.',
      '84': 'Tipo de operação inválido.',
      '85': 'Tipo de serviço inválido.',
      '86': 'Forma de lançamento inválido.',
      '87': 'Número da remessa inválido.',
      '88': 'Número da remessa menor/igual que da remessa anterior.',
      '89': 'Lote de serviço divergente.',
      '90': 'Número seqüencial do registro inválido.',
      '91': 'Erro na seqüência de segmento do registro detalhe.',
      '92': 'Código de movimento divergente entre grupo de segmentos.',
      '93': 'Quantidade de registros no lote inválido.',
      '94': 'Quantidade de registros no lote divergente.',
      '95': 'Quantidade de lotes do arquivo inválido.',
      '96': 'Quantidade de lotes no arquivo divergente.',
      '97': 'Quantidade de registros no arquivo inválido.'
    },
    '99': {
      '1': 'Movimento sem Cedente Correspondente',
      '2': 'Movimento sem Título Correspondente',
      '8': 'Movimento para Título já com Movimentação no dia',
      '9': 'Nosso Número não Pertence ao Cedente',
      '10': 'Inclusão de Título já Existente',
      '12': 'Movimento Duplicado',
      '13': 'Entrada Inválida para Cobrança Caucionada (Cedente não possui conta Caução)',
      '20': 'CEP do Sacado não Encontrado (Não foi possível a Determinação da Agência Cobradora para o Título)',
      '21': 'Agência Cobradora não Encontrada (Agência Designada para Cobradora não Cadastrada no Sistema)',
      '22': 'Agência Cedente não Encontrada (Agência do Cedente não Cadastrada no Sistema)',
      '45': 'Data de Vencimento com prazo mais de 1 ano',
      '49': 'Movimento Inválido para Título Baixado/Liquidado',
      '50': 'Movimento Inválido para Título enviado ao Cartório',
      '54': 'Faixa de CEP da Agência Cobradora não Abrange CEP do Sacado',
      '55': 'Título já com Opção de Devolução',
      '56': 'Processo de Protesto em Andamento',
      '57': 'Título já com Opção de Protesto',
      '58': 'Processo de Devolução em Andamento',
      '59': 'Novo Prazo p/ Protesto/Devolução Inválido',
      '76': 'Alteração de Prazo de Protesto Inválida',
      '77': 'Alteração de Prazo de Devolução Inválida',
      '81': 'CEP do Sacado Inválido',
      '82': 'CGC/CPF do Sacado Inválido (Dígito não Confere)',
      '83': 'Número do Documento (Seu Número) inválido',
      '84': 'Protesto inválido para título sem Número do Documento (Seu Número)'
    }
  },
  '341': {
    '03': {
      '3': 'Ag. cobradora - não foi possível atribuir a agência  pelo cep ou cep inválido',
      '4': 'Estado - sigla do estado inválida',
      '5': 'Data vencimento - prazo da operação menor que prazo mínimo ou maior que o máximo',
      '7': 'Valor do título - valor do título maior que 10.000.000,00',
      '8': 'Nome do sacado  - não informado ou deslocado',
      '9': 'Agencia/conta  - agência encerrada',
      '10': 'Logradouro  - não informado ou deslocado',
      '11': 'Cep - cep não numérico',
      '12': 'Sacador / avalista  - nome não informado ou deslocado (bancos correspondentes)',
      '13': 'Estado/cep  - cep incompatível com a sigla do estado',
      '14': 'Nosso número - nosso número já registrado no cadastro do banco ou fora da faixa',
      '15': 'Nosso número - nosso número em duplicidade no mesmo movimento',
      '18': 'Data de entrada - data de entrada inválida para operar com esta carteira',
      '19': 'Ocorrência - ocorrência inválida',
      '21': 'Ag. cobradora  - testado da agência diferente do estado do sacado',
      '22': 'Carteira  - carteira não permitida (necessário cadastrar faixa livre)',
      '26': 'Agência/conta - agência/conta não liberada para operar com cobrança',
      '27': 'Cnpj inapto - cnpj do cedente inapto',
      '29': 'Código empresa - categoria da conta inválida',
      '30': 'Entrada bloqueada - entradas bloqueadas, conta suspensa em cobrança',
      '31': 'Agência/conta - conta não tem permissão para protestar (contate seu gerente)',
      '35': 'Valor do iof - iof maior que 5%',
      '36': 'Qtdade de moeda - quantidade de moeda incompatível com valor do título',
      '37': 'Cnpj/cpf do sacado - não numérico ou igual a zeros',
      '42': 'Nosso número - nosso número fora de faixa',
      '52': 'Ag. cobradora - empresa não aceita banco correspondente',
      '53': 'Ag. cobradora - empresa não aceita banco correspondente - cobrança mensagem',
      '54': 'Data de vencto - banco correspondente - título com vencimento inferior a 15 dias',
      '55': 'Dep/bco corresp - cep não pertence à depositária informada',
      '56': 'Dt vencto/bco corresp - vencto superior a 180 dias da data de entrada',
      '57': 'Data de vencto  - cep só depositária bco do brasil com vencto inferior a 8 dias',
      '60': 'Abatimento - valor do abatimento inválido',
      '61': 'Juros de mora - juros de mora maior que o permitido',
      '65': 'Taxa financto - taxa inválida (vendor)',
      '66': 'Data de vencto - invalida/fora de prazo de operação (mínimo ou máximo)',
      '67': 'Valor/qtidade - valor do título/quantidade de moeda inválido',
      '68': 'Carteira  - carteira inválida',
      '69': 'Carteira - carteira inválida para títulos com rateio de crédito',
      '70': 'Agência/conta - cedente não cadastrado para fazer rateio de crédito',
      '78': 'Agência/conta - duplicidade de agência/conta beneficiária do rateio de crédito',
      '80': 'Agência/conta - quantidade de contas beneficiárias do rateio maior do que o permitido (máximo de 30 contas por título)',
      '81': 'Agência/conta - conta para rateio de crédito inválida / não pertence ao itaú',
      '82': 'Desconto/abati-mento - desconto/abatimento não permitido para títulos com rateio de crédito',
      '83': 'Valor do título - valor do título menor que a soma dos valores estipulados para rateio',
      '84': 'Agência/conta - agência/conta beneficiária do rateio é a centralizadora de crédito do cedente',
      '85': 'Agência/conta - agência/conta do cedente é contratual / rateio de crédito não permitido',
      '86': 'Tipo de valor - código do tipo de valor inválido / não previsto para títulos com rateio de crédito',
      '87': 'Agência/conta - registro tipo 4 sem informação de agências/contas beneficiárias do rateio',
      '90': 'Nro da linha - cobrança mensagem - número da linha da mensagem inválido',
      '97': 'Sem mensagem - cobrança mensagem sem mensagem (só de campos fixos), porém com registro do tipo 7 ou 8',
      '98': 'Flash inválido - registro mensagem sem flash cadastrado ou flash informado diferente do cadastrado',
      '99': 'Flash inválido - conta de cobrança com flash cadastrado e sem registro de mensagem correspondente',
      '91': 'Dac - dac agência / conta corrente inválido',
      '92': 'Dac - dac agência/conta/carteira/nosso número inválido',
      '93': 'Estado - sigla estado inválida',
      '94': 'Estado - sigla estada incompatível com cep do sacado',
      '95': 'Cep - cep do sacado não numérico ou inválido',
      '96': 'Endereço - endereço / nome / cidade sacado inválido'
    },
    '17': {
      '2': 'Agência cobradora inválida ou com o mesmo conteúdo',
      '4': 'Sigla do estado inválida',
      '5': 'Data de vencimento inválida ou com o mesmo conteúdo',
      '6': 'Valor do título com outra alteração simultânea',
      '8': 'Nome do sacado com o mesmo conteúdo',
      '9': 'Agência/conta incorreta',
      '11': 'Cep inválido',
      '13': 'Seu número com o mesmo conteúdo',
      '16': 'Abatimento/alteração do valor do título ou solicitação de baixa bloqueada',
      '20': 'Espécie inválida',
      '21': 'Agência cobradora não consta no cadastro de depositária ou em encerramento',
      '23': 'Data de emissão do título inválida ou com mesmo conteúdo',
      '41': 'Campo aceite inválido ou com mesmo conteúdo',
      '42': 'Alteração inválida para título vencido',
      '43': 'Alteração bloqueada - vencimento já alterado',
      '53': 'Instrução com o mesmo conteúdo',
      '54': 'Data vencimento para bancos correspondentes inferior ao aceito pelo banco',
      '55': 'Alterações iguais para o mesmo controle (agência/conta/carteira/nosso número)',
      '56': 'Cnpj/cpf inválido não numérico ou zerado',
      '57': 'Prazo de vencimento inferior a 15 dias',
      '60': 'Valor de iof - alteração não permitida para carteiras de n.s. - moeda variável',
      '61': 'Título já baixado ou liquidado ou não existe título correspondente no sistema',
      '66': 'Alteração não permitida para carteiras de notas de seguros - moeda variável',
      '81': 'Alteração bloqueada - título com protesto',
      '87': 'Alteração bloqueada - título com rateio de crédito'
    },
    '16': {
      '1': 'Instrução/ocorrência não existente',
      '3': 'Conta não tem permissão para protestar (contate seu gerente)',
      '6': 'Nosso número igual a zeros',
      '9': 'Cnpj/cpf  do sacador/avalista inválido',
      '10': 'Valor do abatimento igual ou maior que o valor do título',
      '14': 'Registro em duplicidade',
      '15': 'Cnpj/cpf informado sem nome do sacador/avalista',
      '19': 'Valor do abatimento maior que 90% do valor do título',
      '21': 'Título não registrado no sistema',
      '22': 'Título baixado ou liquidado',
      '23': 'Instrução não aceita por ter sido emitido último aviso ao sacado',
      '24': 'Instrução incompatível - existe instrução de protesto para o título',
      '25': 'Instrução incompatível - não existe instrução de protesto para o título',
      '26': 'Instrução não aceita por já ter sido emitida a ordem de protesto ao cartório',
      '27': 'Instrução não aceita por não ter sido emitida a ordem de protesto ao cartório',
      '28': 'Já existe uma mesma instrução cadastrada anteriormente para o título',
      '29': 'Valor líquido + valor do abatimento diferente do valor do título registrado',
      '30': 'Existe uma instrução de não protestar ativa para o título',
      '31': 'Existe uma ocorrência do sacado que bloqueia a instrução',
      '32': 'Depositária do título = 9999 ou carteira não aceita protesto',
      '33': 'Alteração de vencimento igual à registrada no sistema ou que torna o título vencido',
      '34': 'Instrução de emissão de aviso de cobrança para título vencido antes do vencimento',
      '35': 'Solicitação de cancelamento de instrução inexistente',
      '36': 'Título sofrendo alteração de controle (agência/conta/carteira/nosso número)',
      '37': 'Instrução não permitida para a carteira',
      '38': 'Instrução não permitida para título com rateio de crédito'
    },
    '15': {
      '1': 'Carteira/nº número não numérico',
      '4': 'Nosso número em duplicidade num mesmo movimento',
      '5': 'Solicitação de baixa para título já baixado ou liquidado',
      '6': 'Solicitação de baixa para título não registrado no sistema',
      '7': 'Cobrança prazo curto - solicitação de baixa p/ título não registrado no sistema',
      '8': 'Solicitação de baixa para título em floating',
      '10': 'Valor do titulo faz parte de garantia de emprestimo',
      '11': 'Pago através do sispag por crédito em c/c e não baixado'
    },
    '18': {
      '16': 'Abatimento/alteração do valor do título ou solicitação de baixa bloqueados',
    },
    '69': {
      '11': 'Cheque sem fundos - primeira apresentação.',
      '12': 'Cheque sem fundos - segunda apresentação.',
      '13': 'Conta encerrada.',
      '14': 'Prática espúria.',
      '20': 'Folha de cheque cancelada por solicitação do correntista.',
      '21': 'Contra-ordem (ou revogação) ou oposição (ou sustação) ao pagamento pelo emitente ou pelo portador.',
      '22': 'Divergência ou insuficiência de assinatura.',
      '23': 'Cheques emitidos por entidades e órgãos da administração pública federal direta e indireta, em desacordo com os requisitos constantes do artigo 74, § 2º, do decreto-lei nº 200, de 25.02.1967.',
      '24': 'Bloqueio judicial ou determinação do banco central do brasil.',
      '25': 'Cancelamento de talonário pelo banco sacado.',
      '28': 'Contra-ordem (ou revogação) ou oposição (ou sustação) ao pagamento ocasionada por furto ou roubo.',
      '29': 'Cheque bloqueado por falta de confirmação do recebimento do talonário pelo correntista.',
      '30': 'Furto ou roubo de malotes.',
      '31': 'Erro formal (sem data de emissão, com o mês grafado numericamente, ausência de assinatura, não-registro do valor por extenso).',
      '32': 'Ausência ou irregularidade na aplicação do carimbo de compensação.',
      '33': 'Divergência de endosso.',
      '34': 'Cheque apresentado por estabelecimento bancário que não o indicado no cruzamento em preto, sem o endosso-mandato.',
      '35': 'Cheque fraudado, emitido sem prévio controle ou responsabilidade do estabelecimento bancário (cheque universal), ou ainda com adulteração da praça sacada.',
      '36': 'Cheque emitido com mais de um endosso.',
      '40': 'Moeda inválida.',
      '41': 'Cheque apresentado a banco que não o sacado.',
      '42': 'Cheque não-compensável na sessão ou sistema de compensação em que foi apresentado.',
      '43': 'Cheque, devolvido anteriormente pelos motivos 21, 22, 23, 24, 31 ou 34, não-passível de reapresentação em virtude de persistir o motivo da devolução.',
      '44': 'Cheque prescrito.',
      '45': 'Cheque emitido por entidade obrigada a realizar movimentação e utilização de recursos financeiros do tesouro nacional mediante ordem bancária.',
      '48': 'Cheque de valor superior ao estabelecido, emitido sem a identificação do beneficiário, devendo ser devolvido a qualquer tempo.',
      '49': 'Remessa nula, caracterizada pela reapresentação de cheque devolvido pelos motivos 12, 13, 14, 20, 25, 28, 30, 35, 43, 44 e 45, podendo a sua devolução ocorrer a qualquer tempo.'
    }
  },
  '033': {
    '03': {
      '1': 'Codigo do banco invalido',
      '2': 'codigo do regsitro detalhe invalido',
      '3': 'Codigo do segmento invalido',
      '4': 'Codigo do movimento não permitido para carteira',
      '5': 'Codigo de movimento invalido',
      '6': 'Tipo/numero de inscrição do cedente invalidos',
      '7': 'Agencia/conta/DV invalido',
      '8': 'Nosso numero invalido',
      '9': 'Nosso numero duplicado',
      '10': 'Carteira invalida',
      '11': 'Forma de cadastramento do titulo invalida',
      '12': 'Tipo de documento invalido',
      '13': 'Identificação da emissão do bloqueto invalida',
      '14': 'Identificação da distribuição do bloqueto invalida',
      '15': 'Caracteristicas da cobrança imcompativeis',
      '16': 'Data de vencimento invalida',
      '17': 'Data de vencimento anterior a data de emissao',
      '18': 'Vencimento fora do prazo de operação',
      '19': 'Titulo a cargo de bancos correspndentes com vencimento inferior a xx dias',
      '20': 'Valor do título invalido',
      '21': 'Especie do titulo invalida',
      '22': 'Especie nao permitida para a carteira',
      '23': 'Aceite invalido',
      '24': 'Data de emissão inválida',
      '25': 'Data de emissão posterior a data de entrada',
      '26': 'Código de juros de mora inválido',
      '27': 'Valor/Taxa de juros de mora inválido',
      '28': 'Código de desconto inválido',
      '29': 'Valor do desconto  maior ou igual ao  valor do título',
      '30': 'Desconto a conceder não confere',
      '31': 'Concessão de desconto - já existe desconto anterior',
      '32': 'Valor do IOF',
      '33': 'Valor do abatimento inválido',
      '34': 'Valor do abatimento  maior ou igual ao valor do título',
      '35': 'Abatimento a conceder não confere',
      '36': 'Concessão de abatimento - já existe abatimento anterior',
      '37': 'Código para protesto inválido',
      '38': 'Prazo para protesto inválido',
      '39': 'Pedido de protesto não permitido para o título',
      '40': 'Título com ordem de protesto emitida',
      '41': 'Pedido de cancelamento/sustação para títulos sem instrução de  protesto',
      '42': 'Código para baixa/devolução inválido',
      '43': 'Prazo para baixa/devolução inválido',
      '44': 'Código de moeda inválido',
      '45': 'Nome do sacados  não informado',
      '46': 'Tipo /Número de inscrição do sacado inválidos',
      '47': 'Endereço do sacado não informado',
      '48': 'CEP inválido',
      '49': 'CEP sem praça de cobrança (não localizado)',
      '50': 'CEP referente a um Banco Correspondente',
      '51': 'CEP incompatível com a unidade de federação',
      '52': 'Unidade de federação inválida',
      '53': 'Tipo/Número de inscrição do sacador/avalista inválidos',
      '54': 'Sacador/Avalista não informado',
      '55': 'Nosso número no Banco Correspondente não informado',
      '56': 'Código do Banco Correspondente não informado',
      '57': 'Código da multa inválido',
      '58': 'Data da  multa inválida',
      '59': 'Valor/Percentual da  multa inválido',
      '60': 'Movimento  para  título não cadastrado',
      '61': 'Alteração de agência cobradora/dv inválida',
      '62': 'Tipo de impressão inválido',
      '63': 'Entrada para título já cadastrado',
      '64': 'Número da linha inválido'
    },
    '06': {
      '1': 'Por saldo',
      '2': 'Por conta',
      '3': 'No próprio banco',
      '4': 'Compensação eletrônica',
      '5': 'Compensação convencional',
      '6': 'Arquivo magnético',
      '7': 'Após feriado local',
      '8': 'Em cartório',
      '9': 'Pagamento Parcial',
    },
    '07': {
      '1': 'Por saldo',
      '2': 'Por conta',
      '3': 'No próprio banco',
      '4': 'Compensação eletrônica',
      '5': 'Compensação convencional',
      '6': 'Arquivo magnético',
      '7': 'Após feriado local',
      '8': 'Em cartório',
      '9': 'Pagamento Parcial',
    },
    '08': {
      '1': 'Por saldo',
      '2': 'Por conta',
      '3': 'No próprio banco',
      '4': 'Compensação eletrônica',
      '5': 'Compensação convencional',
      '6': 'Arquivo magnético',
      '7': 'Após feriado local',
      '8': 'Em cartório',
      '9': 'Pagamento Parcial',
    },
    '16': {
      '1': 'Por saldo',
      '2': 'Por conta',
      '3': 'No próprio banco',
      '4': 'Compensação eletrônica',
      '5': 'Compensação convencional',
      '6': 'Arquivo magnético',
      '7': 'Após feriado local',
      '8': 'Em cartório',
      '9': 'Pagamento Parcial',
    },
    '17': {
      '1': 'Por saldo',
      '2': 'Por conta',
      '3': 'No próprio banco',
      '4': 'Compensação eletrônica',
      '5': 'Compensação convencional',
      '6': 'Arquivo magnético',
      '7': 'Após feriado local',
      '8': 'Em cartório',
      '9': 'Pagamento Parcial',
    },
    '09': {
      '9': 'Comandada banco',
      '10': 'Comandada cliente arquivo',
      '11': 'Comandada cliente on-line',
      '12': 'Decurso prazo – cliente',
      '13': 'Decurso prazo – banco',
      '92': 'Pagamento PIX',
      '93': 'Baixa Operacional enviado pela CIP',
      '94': 'Cancelamento de Baixa Operacional enviado pela CIP',
    },
    '10': {
      '9': 'Comandada banco',
      '10': 'Comandada cliente arquivo',
      '11': 'Comandada cliente on-line',
      '12': 'Decurso prazo – cliente',
      '13': 'Decurso prazo – banco',
      '92': 'Pagamento PIX',
      '93': 'Baixa Operacional enviado pela CIP',
      '94': 'Cancelamento de Baixa Operacional enviado pela CIP',
    },
    '26': {
      '1': 'Codigo do banco invalido',
      '2': 'codigo do regsitro detalhe invalido',
      '3': 'Codigo do segmento invalido',
      '4': 'Codigo do movimento não permitido para carteira',
      '5': 'Codigo de movimento invalido',
      '6': 'Tipo/numero de inscrição do cedente invalidos',
      '7': 'Agencia/conta/DV invalido',
      '8': 'Nosso numero invalido',
      '9': 'Nosso numero duplicado',
      '10': 'Carteira invalida',
      '11': 'Forma de cadastramento do titulo invalida',
      '12': 'Tipo de documento invalido',
      '13': 'Identificação da emissão do bloqueto invalida',
      '14': 'Identificação da distribuição do bloqueto invalida',
      '15': 'Caracteristicas da cobrança imcompativeis',
      '16': 'Data de vencimento invalida',
      '17': 'Data de vencimento anterior a data de emissao',
      '18': 'Vencimento fora do prazo de operação',
      '19': 'Titulo a cargo de bancos correspndentes com vencimento inferior a xx dias',
      '20': 'Valor do título invalido',
      '21': 'Especie do titulo invalida',
      '22': 'Especie nao permitida para a carteira',
      '23': 'Aceite invalido',
      '24': 'Data de emissão inválida',
      '25': 'Data de emissão posterior a data de entrada',
      '26': 'Código de juros de mora inválido',
      '27': 'Valor/Taxa de juros de mora inválido',
      '28': 'Código de desconto inválido',
      '29': 'Valor do desconto  maior ou igual ao  valor do título',
      '30': 'Desconto a conceder não confere',
      '31': 'Concessão de desconto - já existe desconto anterior',
      '32': 'Valor do IOF',
      '33': 'Valor do abatimento inválido',
      '34': 'Valor do abatimento  maior ou igual ao valor do título',
      '35': 'Abatimento a conceder não confere',
      '36': 'Concessão de abatimento - já existe abatimento anterior',
      '37': 'Código para protesto inválido',
      '38': 'Prazo para protesto inválido',
      '39': 'Pedido de protesto não permitido para o título',
      '40': 'Título com ordem de protesto emitida',
      '41': 'Pedido de cancelamento/sustação para títulos sem instrução de  protesto',
      '42': 'Código para baixa/devolução inválido',
      '43': 'Prazo para baixa/devolução inválido',
      '44': 'Código de moeda inválido',
      '45': 'Nome do sacados  não informado',
      '46': 'Tipo /Número de inscrição do sacado inválidos',
      '47': 'Endereço do sacado não informado',
      '48': 'CEP inválido',
      '49': 'CEP sem praça de cobrança (não localizado)',
      '50': 'CEP referente a um Banco Correspondente',
      '51': 'CEP incompatível com a unidade de federação',
      '52': 'Unidade de federação inválida',
      '53': 'Tipo/Número de inscrição do sacador/avalista inválidos',
      '54': 'Sacador/Avalista não informado',
      '55': 'Nosso número no Banco Correspondente não informado',
      '56': 'Código do Banco Correspondente não informado',
      '57': 'Código da multa inválido',
      '58': 'Data da  multa inválida',
      '59': 'Valor/Percentual da  multa inválido',
      '60': 'Movimento  para  título não cadastrado',
      '61': 'Alteração de agência cobradora/dv inválida',
      '62': 'Tipo de impressão inválido',
      '63': 'Entrada para título já cadastrado',
      '64': 'Número da linha inválido'
    },
    '30': {
      '1': 'Codigo do banco invalido',
      '2': 'codigo do regsitro detalhe invalido',
      '3': 'Codigo do segmento invalido',
      '4': 'Codigo do movimento não permitido para carteira',
      '5': 'Codigo de movimento invalido',
      '6': 'Tipo/numero de inscrição do cedente invalidos',
      '7': 'Agencia/conta/DV invalido',
      '8': 'Nosso numero invalido',
      '9': 'Nosso numero duplicado',
      '10': 'Carteira invalida',
      '11': 'Forma de cadastramento do titulo invalida',
      '12': 'Tipo de documento invalido',
      '13': 'Identificação da emissão do bloqueto invalida',
      '14': 'Identificação da distribuição do bloqueto invalida',
      '15': 'Caracteristicas da cobrança imcompativeis',
      '16': 'Data de vencimento invalida',
      '17': 'Data de vencimento anterior a data de emissao',
      '18': 'Vencimento fora do prazo de operação',
      '19': 'Titulo a cargo de bancos correspndentes com vencimento inferior a xx dias',
      '20': 'Valor do título invalido',
      '21': 'Especie do titulo invalida',
      '22': 'Especie nao permitida para a carteira',
      '23': 'Aceite invalido',
      '24': 'Data de emissão inválida',
      '25': 'Data de emissão posterior a data de entrada',
      '26': 'Código de juros de mora inválido',
      '27': 'Valor/Taxa de juros de mora inválido',
      '28': 'Código de desconto inválido',
      '29': 'Valor do desconto  maior ou igual ao  valor do título',
      '30': 'Desconto a conceder não confere',
      '31': 'Concessão de desconto - já existe desconto anterior',
      '32': 'Valor do IOF',
      '33': 'Valor do abatimento inválido',
      '34': 'Valor do abatimento  maior ou igual ao valor do título',
      '35': 'Abatimento a conceder não confere',
      '36': 'Concessão de abatimento - já existe abatimento anterior',
      '37': 'Código para protesto inválido',
      '38': 'Prazo para protesto inválido',
      '39': 'Pedido de protesto não permitido para o título',
      '40': 'Título com ordem de protesto emitida',
      '41': 'Pedido de cancelamento/sustação para títulos sem instrução de  protesto',
      '42': 'Código para baixa/devolução inválido',
      '43': 'Prazo para baixa/devolução inválido',
      '44': 'Código de moeda inválido',
      '45': 'Nome do sacados  não informado',
      '46': 'Tipo /Número de inscrição do sacado inválidos',
      '47': 'Endereço do sacado não informado',
      '48': 'CEP inválido',
      '49': 'CEP sem praça de cobrança (não localizado)',
      '50': 'CEP referente a um Banco Correspondente',
      '51': 'CEP incompatível com a unidade de federação',
      '52': 'Unidade de federação inválida',
      '53': 'Tipo/Número de inscrição do sacador/avalista inválidos',
      '54': 'Sacador/Avalista não informado',
      '55': 'Nosso número no Banco Correspondente não informado',
      '56': 'Código do Banco Correspondente não informado',
      '57': 'Código da multa inválido',
      '58': 'Data da  multa inválida',
      '59': 'Valor/Percentual da  multa inválido',
      '60': 'Movimento  para  título não cadastrado',
      '61': 'Alteração de agência cobradora/dv inválida',
      '62': 'Tipo de impressão inválido',
      '63': 'Entrada para título já cadastrado',
      '64': 'Número da linha inválido'
    }
  },
  '422': {
    '03': {
      '1': 'MOEDA INVÁLIDA',
      '2': 'MOEDA INVÁLIDA PARA CARTEIRA',
      '7': 'CEP NÃO CORRESPONDE UF',
      '8': 'VALOR JUROS AO DIA MAIOR QUE 5% DO VALOR DO TÍTULO',
      '9': 'USO EXCLUSIVO NÃO NUMÉRICO PARA COBRANCA EXPRESS',
      '10': 'IMPOSSIBILIDADE DE REGISTRO - CONTATE O SEU GERENTE',
      '11': 'NOSSO NÚMERO FORA DA FAIXA',
      '12': 'CEP DE CIDADE INEXISTENTE',
      '13': 'CEP FORA DE FAIXA DA CIDADE',
      '14': 'UF INVÁLIDO PARA CEP DA CIDADE',
      '15': 'CEP ZERADO',
      '16': 'CEP NÃO CONSTA NA TABELA SAFRA',
      '17': 'CEP NÃO CONSTA TABELA BANCO CORRESPONDENTE',
      '19': 'PROTESTO IMPRATICÁVEL',
      '20': 'PRIMEIRA INSTRUÇÃO DE COBRANÇA INVALIDA',
      '21': 'SEGUNDA INSTRUÇÃO DE COBRANÇA INVÁLIDA',
      '23': 'TERCEIRA INSTRUÇÃO DE COBRANÇA INVÁLIDA',
      '26': 'CÓDIGO DE OPERAÇÃO/OCORRÊNCIA INVÁLIDO',
      '27': 'OPERAÇÃO INVÁLIDA PARA O CLIENTE',
      '28': 'NOSSO NÚMERO NÃO NUMÉRICO OU ZERADO',
      '29': 'NOSSO NÚMERO COM DÍGITO DE CONTROLE ERRADO/INCONSISTENTE',
      '30': 'VALOR DO ABATIMENTO NÃO NUMÉRICO OU ZERADO',
      '31': 'SEU NÚMERO EM BRANCO',
      '32': 'CÓDIGO DA CARTEIRA INVÁLIDO',
      '36': 'DATA DE EMISSÃO INVÁLIDA',
      '37': 'DATA DE VENCIMENTO INVÁLIDA',
      '38': 'DEPOSITÁRIA INVÁLIDA',
      '39': 'DEPOSITÁRIA INVÁLIDA PARA O CLIENTE',
      '40': 'DEPOSITÁRIA NÃO CADASTRADA NO BANCO',
      '41': 'CÓDIGO DE ACEITE INVÁLIDO',
      '42': 'ESPÉCIE DE TÍTULO INVÁLIDO',
      '43': 'INSTRUÇÃO DE COBRANÇA INVÁLIDA',
      '44': 'VALOR DO TÍTULO NÃO NUMÉRICO OU ZERADO',
      '46': 'VALOR DE JUROS NÃO NUMÉRICO OU ZERADO',
      '47': 'DATA LIMITE PARA DESCONTO INVÁLIDA',
      '48': 'VALOR DO DESCONTO INVÁLIDO',
      '49': 'VALOR IOF. NÃO NUMÉRICO OU ZERADO (SEGUROS)',
      '51': 'CÓDIGO DE INSCRIÇÃO DO SACADO INVÁLIDO',
      '53': 'NÚMERO DE INSCRIÇÃO DO SACADO NÃO NÚMERICO OU DÍGITO ERRADO',
      '54': 'NOME DO SACADO EM BRANCO',
      '55': 'ENDEREÇO DO SACADO EM BRANCO',
      '56': 'CLIENTE NÃO CADASTRADO',
      '58': 'PROCESSO DE CARTÓRIO INVÁLIDO',
      '59': 'ESTADO DO SACADO INVÁLIDO',
      '60': 'CEP/ENDEREÇO DIVERGEM DO CORREIO',
      '61': 'INSTRUÇÃO AGENDADA PARA AGÊNCIA',
      '62': 'OPERAÇÃO INVÁLIDA PARA A CARTEIRA',
      '64': 'TÍTULO INEXISTENTE (TFC)',
      '65': 'OPERAÇÃO / TITULO JÁ EXISTENTE',
      '66': 'TÍTULO JÁ EXISTE (TFC)',
      '67': 'DATA DE VENCIMENTO INVÁLIDA PARA PROTESTO',
      '68': 'CEP DO SACADO NÃO CONSTA NA TABELA',
      '69': 'PRAÇA NÃO ATENDIDA PELO SERVIÇO CARTÓRIO',
      '70': 'AGÊNCIA INVÁLIDA',
      '72': 'TÍTULO JÁ EXISTE (COB)',
      '74': 'TÍTULO FORA DE SEQÜÊNCIA',
      '78': 'TÍTULO INEXISTENTE (COB) ',
      '79': 'OPERAÇÃO NÃO CONCLUÍDA',
      '80': 'TÍTULO JÁ BAIXADO',
      '83': 'PRORROGAÇÃO/ALTERAÇÃO DE VENCIMENTO INVÁLIDA',
      '85': 'OPERAÇÃO INVÁLIDA PARA A CARTEIRA',
      '86': 'ABATIMENTO MAIOR QUE VALOR DO TÍTULO',
      '88': 'TÍTULO RECUSADO COMO GARANTIA (SACADO/NOVO/EXCLUSIVO/ALÇADA COMITÊ)',
      '89': 'ALTERAÇÃO DE DATA DE PROTESTO INVÁLIDA',
      '94': 'ENTRADA TÍTULO COBRANÇA DIRETA INVÁLIDA',
      '95': 'BAIXA TÍTULO COBRANÇA DIRETA INVÁLIDA',
      '96': 'VALOR DO TÍTULO INVÁLIDO',
      '98': 'PCB DO TFC DIVERGEM DA PCB DO COB',
      '100': 'INSTRUÇÃO NÃO PERMITIDA - TÍT COM PROTESTO (SE TÍTULO PROTESTADO NÃO PODE NEGATIVAR)',
      '101': 'INSTRUÇÃO INCOMPATÍVEL - NÃO EXISTE INSTRUÇÃO DE NEGATIVAR PARA O TÍTULO',
      '102': 'INSTRUÇÃO NÃO PERMITIDA - PRAZO INVÁLIDO PARA NEGATIVAÇÃO (MÍNIMO 2 DIAS CORRIDOS APÓS O VENCIMENTO)',
      '103': 'INSTRUÇÃO NÃO PERMITIDA - TÍT INEXISTENTE',
    }
  },
  '745': {
    '03': {
      '1': 'Código do Banco Inválido',
      '2': 'Código do Registro Detalhe Inválido',
      '4': 'Código de Movimento Não Permitido para Carteira',
      '5': 'Código de Movimento Inválido',
      '7': 'Conta informada inválida ou não implementada',
      '8': 'Nosso Número Inválido',
      '9': 'Nosso Número Duplicado',
      '10': 'Carteira Inválida',
      '11': 'Forma de Cadastramento do Título Inválido',
      '13': 'Identificação da Emissão do Boleto Inválida',
      '15': 'Características da Cobrança Incompatíveis',
      '16': 'Data de Vencimento Inválida',
      '18': 'Vencimento Fora do Prazo de Operação',
      '20': 'Valor do Título Inválido',
      '21': 'Espécie de Título Inválida',
      '23': 'Aceite Inválido',
      '24': 'Data da Emissão Inválida',
      '26': 'Código de Juros de Mora Inválido',
      '29': 'Valor do Desconto Maior ou Igual ao Valor do Título',
      '30': 'Desconto a Conceder Não Confere',
      '34': 'Valor do Abatimento Maior ou Igual ao Valor do Título',
      '38': 'Prazo para instrução Inválido',
      '39': 'Pedido de Protesto Não Permitido para o Título',
      '40': 'Alteração para título já protestado ou em cartório',
      '41': 'Pedido de Cancelamento/Sustação para Títulos sem Instrução de Protesto',
      '44': 'Código da Moeda Inválido',
      '45': 'Nome do Pagador Não Informado',
      '46': 'Tipo/Número de Inscrição do Pagador Inválidos',
      '47': 'Endereço do Pagador Não Informado',
      '48': 'CEP Inválido',
      '50': 'CEP Referente a um Banco Correspondente',
      '51': 'CEP incompatível com a Unidade da Federação',
      '52': 'Unidade da Federação Inválida',
      '58': 'Data da Multa Inválida',
      '59': 'Valor/Percentual da Multa Inválido',
      '60': 'Movimento para Título Não Cadastrado',
      '63': 'Entrada para Título já Cadastrado',
      '79': 'Data Juros de Mora Inválido',
      '80': 'Data do Desconto Inválida',
      '86': 'Seu Número Inválido',
      '87': 'e-mail/SMS enviado',
      '91': 'e-mail do Pagador inválido',
      '99': 'Rejeição da alteração do número do título pelo cedente',
      'A1': 'Rejeição da alteração do número controle do participante',
      'A2': 'Rejeição da alteração dos dados do Pagador',
      'A3': 'Rejeição da alteração dos dados do Pagador/avalista',
      'B1': 'Transferido de Portfólio',
      'B3': 'Tipo de Pagamento Inválido',
      'B4': 'Valor Máximo/Percentual Inválido',
      'B5': 'Valor Mínimo/Percentual Inválido',
      'U2': 'Valor da multa inválido',
      'U3': 'Aceita pagamento divergente U5 = Código da Multa Inválido',
      'U6': 'Data da Multa Inválida',
      'U7': 'Código de Juro inválido',
      'U8': 'Condição de Pagamento inválida',
      'U9': 'Tipo de código de barras inválido',
      'UA': 'Indicador de apresentação vlr mínimo invalido',
      'X1': 'Alteração para título com instrução de negativação.',
      'X2': 'Título sem instrução de negativação na Serasa',
      'X3': 'Pedido de negativação na Serasa não aceito para o título',
      'X4': 'Serviço de Negativação na Serasa não contratado ou condição não passível de aceitação',
      'X5': 'Pedido de negativação agendado',
    },
    '06': {
      '1': 'Por Saldo',
      '2': 'Por Conta',
      '4': 'Compensação Eletrônica',
      '5': 'Compensação Convencional',
      '6': 'Por Meio Eletrônico',
      '7': 'Após Feriado Local',
      '08': 'Em Cartório',
      '38': 'Liquidação Interbancária STR',
      '88': 'Liquidação Parcial FLEX',
      '99': 'Liquidação no próprio banco',
    },
    '09': {
      '9': 'Comandada Banco',
      '10': 'Comandada Cliente Arquivo',
      '11': 'Comandada Cliente On-line',
      '12': 'Decurso Prazo Cliente',
      '13': 'Decurso Prazo - Banco',
      '14': 'Protestado',
    },
    '10': {
      '9': 'Comandada pelo Banco',
      '10': 'Comandada Cliente/CNAB',
      '11': 'Comandada Cliente On-line',
      '12': 'Decurso de Prazo Cliente',
      '13': 'Decurso de Prazo Banco',
      '14': 'Protestado',
      '15': 'Transferido de carteira',
    },
    '15': {
      '8': 'Em cartório',
    },
    '17': {
      '9': 'Comandada Banco',
      '10': 'Comandada Cliente Arquivo',
      '11': 'Comandada Cliente On-line',
      '12': 'Decurso Prazo Cliente',
      '13': 'Decurso Prazo - Banco',
      '14': 'Protestado',
    },
    '28': {
      '8': 'Custas de Protesto',
      '9': 'Custas de Sustação de Protesto',
      '10': 'Custas de Cartório Distribuidor',
    },
    '90': {
      '1': 'Pagador Aceitou o Título',
      '2': 'Recusado pelo Pagador',
      '3': 'Decurso de Prazo CIP',
    }
  }
};

export const isRejectOccurrence = (bank, code) => {
  return (_.get(rejectCodesMap, bank) || []).includes(code);
};

export const getOccurrenceReason = (bank, code, reasonCode) => {
  return _.get(occurencesReasonMap, `[${bank}][${code}][${Number(String(reasonCode))}]`) || '';
};

export const getOccurrenceReasonLabel = (bank, code, reasonCode) => {
  return (!reasonCode) ? '' : `${reasonCode} - ${getOccurrenceReason(bank, code, reasonCode)}`;
};
