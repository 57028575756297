import React, { memo, useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { Menu, MenuItem, MenuDivider, MenuButton, MenuHeader } from '@szhsin/react-menu';
import ImageAuth from '~/components/html/image';
import { getAvatarDefault } from '~/constants';
import { useSelector, useDispatch } from 'react-redux';
import { Creators as UserActions } from '~/store/modules/user';
import UserModals from '~/pages/users/modals';
import { Creators as AuthActions } from '~/store/modules/auth';

const ProfileMenuButton = styled(MenuButton)`
  border: none;
  background: transparent;
`;

const ProfileMenuHeader = styled(MenuHeader)`
  text-align: left;
`;

const areEquals = (prevProps, nextProps) => {
  const key = 'children.props.avatar';
  return _.get(prevProps, key) === _.get(nextProps, key);
};

const MenuProfile = ({ align, direction }) => {
  const dispatch = useDispatch();
  const user = useSelector(state => _.get(state, 'auth.user', {}));
  const avatar = _.get(user, 'avatar', '');
  const [avatarIsOpen, setAvatarIsOpen] = useState(false);
  const [passIsOpen, setPassIsOpen] = useState(false);
  const isTemporaryAccess = (_.get(user, 'account.accountType') === 'temporary');

  function handleUpdatePassword(data, actions) {
    actions.closeModal = () => setPassIsOpen(false);
    dispatch(UserActions.updatePassword(data, actions));
  }

  function handleUpdateAvatar(data, actions) {
    const { avatar } = data;
    actions.closeModal = () => setAvatarIsOpen(false);
    dispatch(UserActions.updateAvatar(avatar, actions));
  }

  function handleSignOut() {
    dispatch(AuthActions.logout(false));
  }

  return (
    <>
      <Menu
        align={align}
        direction={direction}
        menuButton={
          <ProfileMenuButton>
            <ImageAuth src={avatar} defaultImage={getAvatarDefault()} />
          </ProfileMenuButton>
        }
        >
        <ProfileMenuHeader>
          {isTemporaryAccess ? 'Acesso temporário' : 'Perfil do usuário'}
        </ProfileMenuHeader>

        {!isTemporaryAccess &&
          <MenuItem onClick={() => setAvatarIsOpen(true)}>Alterar Foto</MenuItem>
        }
        {!isTemporaryAccess &&
          <MenuItem onClick={() => setPassIsOpen(true)}>Alterar senha</MenuItem>
        }
        <MenuDivider />
        <MenuItem onClick={handleSignOut}>Sair</MenuItem>
      </Menu>

      <UserModals
        profileData={user}
        passwordFrmIsOpen={passIsOpen}
        closeModalPassword={() => setPassIsOpen(false)}
        handleUpdatePassword={handleUpdatePassword}
        avatarData={{ avatar }}
        avatarFrmIsOpen={avatarIsOpen}
        closeModalAvatar={() => setAvatarIsOpen(false)}
        handleUpdateAvatar={handleUpdateAvatar}
      />
    </>
  );
};

export default memo(MenuProfile, areEquals);
