import React from 'react';
import _ from 'lodash';
import { Autocomplete } from '~/components/form';
import Fieldset from '~/components/fieldset';
import { IconButton } from '~/components/button';
import { MdDelete } from 'react-icons/md';
import { TabContainer, Item, ContainerRepresentatives } from '~/pages/business-partners/styles';
import confirm from '~/components/confirm';

export const TabRepresentatives = ({ values, setFieldValue, onListRepresentative, previewMode }) => {
  const representatives = _.get(values, 'representatives', [])

  async function removeRepresentative(index) {
    if (index >= 0) {
      const result = await confirm.show({
        width: 450,
        title: 'Atenção',
        text: `Deseja realmente remover este representante?`
      })

      if (result) {
        let list = _.cloneDeep(representatives)
        list.splice(index, 1)
        setFieldValue('representatives', list)
      }
    }
  }

  return (
    <TabContainer>
      <Autocomplete
        autoFocus={true}
        name="representative"
        label="Representante"
        keyField="id"
        clearable={true}
        disabled={previewMode}
        loadData={onListRepresentative}
        valueFormat={row => `${_.get(row, 'name', '')} - ${_.get(row, 'email', '')}`}
        onChange={representative => {
          if(representative && !_.isEmpty(representative)) {
            setFieldValue('representatives', [...representatives, representative]);
          }
        }}
        emptyText={'Pesquise um representante'}
        tipText={'Digite... '}
        loadingText={'Carregando...'}
        notFoundText={'Não encontrado'}
        />

      <ContainerRepresentatives>
        <Fieldset label="Representantes(s) associado(s)">
          {_.isEmpty(representatives) && <span className="empty">Nenhum representante.</span>}

          {_.map(representatives || [], (value, index) => (
            <Item key={index} index={index}>
              <div className="column-data">
                <div className="first">
                  <strong>{_.get(value, 'name', '')}</strong>
                  <div>{_.get(value, 'email', '')}</div>
                </div>
              </div>
              <IconButton size={36} margin="0">
                <MdDelete onClick={() => removeRepresentative(index)} />
              </IconButton>
            </Item>
          ))}
        </Fieldset>
      </ContainerRepresentatives>
    </TabContainer>
  );
};
