import React from 'react';
import _ from 'lodash';
import { Select, Autocomplete, InputDate } from '~/components/form';
import { Row } from '~/components/layout';
import Fieldset from '~/components/fieldset';
import { FilterContainer } from '~/components/crud/styles';
import { formatBankAccountSimple, formatBankAccount } from '~/pages/bank-accounts/form';
import { formats } from '~/helper';
import { isBefore, isAfter } from 'date-fns';

export default function FormFilter({ values, onListBankAccounts, onListCompanies, hasAccountsReceivable, hasAccountsPayable, setFieldValue }) {
  return (
    <FilterContainer>
      <Select
        name="status"
        label="Situação"
        options={{
          values: [
            { value: '', label: 'Todas' },
            { value: 'open', label: 'Aberta' },
            { value: 'closed', label: 'Fechada' },
            { value: 'canceled', label: 'Cancelada' },
            { value: 'processing', label: 'Processando' }
          ]
        }}
      />

      {hasAccountsReceivable && hasAccountsPayable && (
        <Select
          name="movementType"
          label="Tipo"
          options={{
            values: [
              { value: '', label: 'Todos' },
              { value: 'charge', label: 'Cobrança' },
              { value: 'payment', label: 'Pagamento' }
            ]
          }}
        />
      )}

      <Autocomplete
        name="bankAccount"
        keyField="id"
        label="Contrato"
        clearable={true}
        value={_.get(values, 'bankAccount')}
        valueFormat={formatBankAccountSimple}
        optionsFormat={row => formatBankAccount(row, 'Beneficiário')}
        loadData={onListBankAccounts}
        emptyText={'Pesquise um contrato'}
        tipText={'Digite... '}
        loadingText={'Carregando...'}
        notFoundText={'Não encontrada'}
      />

      <Autocomplete
        name="company"
        keyField="id"
        label="Empresa"
        clearable={true}
        value={_.get(values, 'company')}
        valueFormat={row => `${formats.cnpj_cpf(row.identity)} - ${row.name || row.tradeName}`}
        loadData={onListCompanies}
        emptyText={'Pesquise uma empresa'}
        tipText={'Digite... '}
        loadingText={'Carregando...'}
        notFoundText={'Não encontrada'}
      />

      <Fieldset label="Cadastrada em">
        <Row span={2}>
          <InputDate
            name="createdAtStart"
            label="De"
            onChange={value => {
              setFieldValue('createdAtStart', value);
              if (values.createdAtEnd && isAfter(value, values.createdAtEnd)) {
                setFieldValue('createdAtStart', values.createdAtEnd);
                setFieldValue('createdAtEnd', value);
              }
            }}
          />

          <InputDate
            name="createdAtEnd"
            label="Até"
            onChange={value => {
              setFieldValue('createdAtEnd', value);
              if (values.createdAtStart && isBefore(value, values.createdAtStart)) {
                setFieldValue('createdAtStart', value);
                setFieldValue('createdAtEnd', values.createdAtStart);
              }
            }}
          />
        </Row>
      </Fieldset>
    </FilterContainer>
  );
}
