import { all, put, call, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import _ from 'lodash';
import api from '~/services/api';
import { getError } from  '~/helper';
import { Creators as ParserActions, Types as ParserTypes } from '~/store/modules/parser';

export function showError(error) {
  const msg =  getError(error);
  toast.error(msg);
};

export function* handleProcess({ data }) {
  try {
    let formData = new FormData();
    formData.append('data', JSON.stringify(_.omit(data, 'file')));
    formData.append('file', _.get(data, 'file[0]'));
    let result = yield call(api.post, `parsers/process`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
    toast.success(`Arquivo processado com sucesso!`);
    yield put(ParserActions.processSuccess(result.data));
  } catch (error) {
    showError(error);
  }
};

export default all([
  takeLatest(ParserTypes.PROCESS, handleProcess),
]);
