import React from 'react';
import { FooterContainer, BillType } from '~/pages/prepare-bills/styles';
import { Menu, MenuItem, MenuDivider } from '@szhsin/react-menu';
import styled from 'styled-components';
import _ from 'lodash';
import { Button } from '~/components/button';

const Line = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    width: 24px;
    height: auto;
    object-fit: contain;
  }

  h4 {
    font-size: 13px;
    min-width: 125px;
  }

  span {
    font-size: 14px;
    color: #666;
    margin-left: 20px;
  }
`;

const Footer = ({ data = {}, billType, onClick, canCreateShipping }) => {
  const getMenus = () => {
    return _.filter(data, (r) => r.id !== '000');
  };

  const handleClick = ({ value }) => {
    onClick && onClick({ value });
  };

  const BankContract = ({ data }) => (
    <div>
      <Menu menuButton={<Button>Gerar remessa</Button>}>
        <MenuItem value="all" onClick={e => handleClick(e)}>
          Todas as cobranças
        </MenuItem>
        <MenuDivider />
        {_.map(data, bankContract => (
          <MenuItem key={bankContract.id} value={bankContract} onClick={e => handleClick(e)}>
            <Line>
              {bankContract.logo && <img className="logo" src={bankContract.logo} alt={bankContract.label} />}
              <span>{bankContract.detail}</span>
            </Line>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );

  return (
    <FooterContainer>
      <BillType>{_.isEmpty(billType) ? 'Todos os tipos de cobrança' : `Tipo de cobrança: ${billType.name}`}</BillType>
      {canCreateShipping && <BankContract data={getMenus()} /> }
    </FooterContainer>
  );
};

export default Footer;
