import React from 'react';
import * as Yup from 'yup';
import _ from 'lodash';
import { MdFileDownload } from 'react-icons/md';
import { IconButton } from '~/components/button';
import { FormContainer } from '~/pages/responses/styles';
import { Select, FileUpload, Autocomplete, InputLabel } from '~/components/form';
import { FlexRow, FlexCol } from '~/components/layout';
import { formatBankContractSimple, formatBankContract } from '~/pages/bank-contracts/form';
import Loading from '~/components/loading';
import Tabs from '~/components/tabs';
import { TabDdas } from '~/pages/responses/tab-ddas';
import { TabcreditReceived } from '~/pages/responses/tab-credits-receiveds';
import { TabPaymentOrders } from '~/pages/responses/tab-payment-orders';
import { TabBills } from '~/pages/responses/tab-bills';
import { TabNotFounds } from '~/pages/responses/tab-not-founds';
import { TabNotProcesseds } from '~/pages/responses/tab-not-processeds';

export const documentsTypeMap = {
  'payment': 'paymentOrders',
  'charge': 'bills',
  'dda': 'ddas',
  'creditReceived': 'creditReceived',
};

const documentsBaseMap = {
  'payment': 'paymentOrder',
  'charge': 'bill',
};

const typeProcessedsLabel = {
  'payment': 'Pagamentos não processados',
  'charge': 'Faturas não processadas',
  'dda': 'Cobranças não processadas',
  'creditReceived': 'Créditos recebidos não processados'
};

const typeFoundsLabel = {
  'payment': 'Pagamentos não encontrados',
  'charge': 'Faturas não encontradas',
  'dda': 'Cobranças não encontradas',
  'creditReceived': 'Créditos recebidos não encontrados',
};

const layoutsCredits = [
  { value: '', label: 'Selecione o Layout' },
  { value: 'cnab160', label: 'CNAB160' },
  { value: 'cnab750', label: 'CNAB750' },
];

const getTabs = (type, notFounds, notProcesseds, documentsWithError) => {
  if (!type) {
    return '';
  }
  let tabs = [{ id: 'bills', label: 'Faturas processadas', component: TabBills }];
  if (!_.isEmpty(documentsWithError)) {
    tabs.push({ id: 'billsError', label: 'Faturas processadas (com erro)', component: TabBills });
  }

  if (type === 'payment') {
    tabs = [{ id: 'paymentOrders', label: 'Pagamentos processados', component: TabPaymentOrders }];

    if (!_.isEmpty(documentsWithError)) {
      tabs.push({ id: 'paymentOrdersError', label: 'Pagamentos processados (com erro)', component: TabPaymentOrders });
    }
  }
  if (type === 'dda') {
    tabs = [{ id: 'ddas', label: 'Cobranças capturadas', component: TabDdas }];
  }
  if (type === 'creditReceived') {
    tabs = [{ id: 'creditReceived', label: 'Créditos recebidos capturados', component: TabcreditReceived }];
  }
  if (!_.isEmpty(notProcesseds)) {
    tabs.push({ id: 'notProcesseds', label: typeProcessedsLabel[type], component: TabNotProcesseds });
  }
  if (!_.isEmpty(notFounds)) {
    tabs.push({ id: 'notFounds', label: typeFoundsLabel[type], component: TabNotFounds });
  }
  return tabs;
};

export const getMovementTypes = ({ hasAccountsReceivable, hasAccountsPayable }) => {
  const list = [];

  if (hasAccountsReceivable) {
    list.push({ value: 'charge', label: 'Cobrança' });
    list.push({ value: 'creditReceived', label: 'Créditos recebidos' });
  }
  if (hasAccountsPayable) {
    list.push({ value: 'payment', label: 'Pagamento' });
    list.push({ value: 'dda', label: 'DDA' });
  }
  return list;
};

const renderNewForm = ({ values, setFieldValue, onListChargeBankContracts, onListPaymentBankContracts, onListPaymentBank, hasAccountsReceivable, hasAccountsPayable }) => {

  const movementTypeList = getMovementTypes({ hasAccountsReceivable, hasAccountsPayable });

  return (
    <FormContainer>
      {(_.size(movementTypeList)) > 1 &&
        <Select
          name="movementType"
          label="Tipo"
          options={{ values: [
            { value: '', label: '' },
            ...movementTypeList
          ]}}
          onChange={(event) => {
            setFieldValue('movementType', _.get(event, 'target.value'));
            setFieldValue('bankAccount', null);
            setFieldValue('bankContract', null);
          }}
          />
      }

      {(/^(payment)$/.test(_.get(values, 'movementType')) && hasAccountsPayable) &&
        <Autocomplete
          autoFocus={true}
          name="bankContract"
          keyField="id"
          label="Contrato"
          tipText="Digite... "
          loadingText="Carregando..."
          notFoundText="Não encontrado"
          emptyText="Informe um contrato"
          value={values.bankContract}
          loadData={onListPaymentBankContracts}
          valueFormat={formatBankContractSimple}
          optionsFormat={formatBankContract}
          />
      }

      {(/^(dda|creditReceived)$/.test(_.get(values, 'movementType')) && hasAccountsPayable) &&
        <Autocomplete
          name="bank"
          keyField="id"
          label="Banco"
          value={_.get(values, 'bank')}
          valueFormat={row => `${row.code} - ${row.name}`}
          loadData={onListPaymentBank}
          emptyText={'Pesquise um banco'}
          tipText={'Digite... '}
          loadingText={'Carregando...'}
          notFoundText={'Não encontrado'}
          />
      }

      {(/^(creditReceived)$/.test(_.get(values, 'movementType')) && hasAccountsReceivable) &&
        <Select
          name="layoutType"
          label="Layout do arquivo"
          value={values.layoutType}
          options={{ values: layoutsCredits }} />
      }

      {(_.get(values, 'movementType') === 'charge' && hasAccountsReceivable) &&
        <Autocomplete
          autoFocus={true}
          name="bankContract"
          keyField="id"
          label="Contrato"
          tipText="Digite... "
          loadingText="Carregando..."
          notFoundText="Não encontrado"
          emptyText="Informe um contrato"
          value={values.bankContract}
          loadData={onListChargeBankContracts}
          valueFormat={formatBankContractSimple}
          optionsFormat={formatBankContract}
          />
      }

      {(_.get(values, 'bank') || _.get(values, 'bankAccount') || _.get(values, 'bankContract')) &&
        <FileUpload
          id="file"
          name="file"
          accept=".ret,.txt,.crt"
          label="Arquivo"
          multiple={false}
          orientation="vertical"
          />
      }
    </FormContainer>
  )
};

export const ResponseForm = (props) => {
  const { values, openDocument, onDownloadFile, currentTab, onChangeTab } = props;

  if (props.loading) {
    return (
      <FormContainer>
        <Loading size={36} label={props.loadingMessage || 'Carregando...'} />
      </FormContainer>
    );
  }
  if (!_.get(values, 'id')) {
    return renderNewForm(props);
  }
  const type = _.get(values, 'movementType'),
    field = documentsBaseMap[type],
    documentList = _.get(values, documentsTypeMap[type]) || [],
    notProcesseds = _.get(values, 'extras.notProcesseds') || [],
    notFounds = _.get(values, 'extras.notFounds') || [],
    bank = _.get(values, 'bankAccount.bank') || _.get(values, 'bank') || {};

  const documents = _.filter(documentList, (r) => !_.get(r, `${field}.erpError`));
  const documentsWithError = _.filter(documentList, (r) => !!_.get(r, `${field}.erpError`));

  return (
    <FormContainer>
      <FlexRow>
        <FlexCol flex="1">
          <InputLabel
            label="Nome do arquivo"
            value={_.get(values, 'filename')} />
        </FlexCol>

        <FlexCol flex="0 0 50px" align="center">
          <IconButton size={46} noMargin={true} title="Baixar arquivo" onClick={() => onDownloadFile(values)}>
            <MdFileDownload />
          </IconButton>
        </FlexCol>
      </FlexRow>

      <FlexRow>
        <FlexCol flex="1">
          <InputLabel
            label="Banco"
            value={`${_.padStart(bank.code, 3, '0')} - ${bank.name || ''}`} />
        </FlexCol>

        <FlexCol flex="0 0 100px">
          <InputLabel
            label="Agência"
            value={_.get(values, 'bankContract.bankAccount.bankBranch')} />
        </FlexCol>

        <FlexCol flex="0 0 100px">
          <InputLabel
            label="Conta"
            value={_.get(values, 'bankContract.bankAccount.accountNumber')} />
        </FlexCol>
      </FlexRow>

      <Tabs
        type={type}
        tabs={getTabs(type, notFounds, notProcesseds, documentsWithError)}
        initialTab={documentsTypeMap[type]}
        currentTab={currentTab}
        changeTab={onChangeTab}
        height="calc(100% - 70px)"
        openDocument={openDocument}
        documentsWithError={documentsWithError}
        paymentOrders={documents}
        bills={documents}
        ddas={documents}
        creditReceived={documents}
        notFounds={notFounds}
        notProcesseds={notProcesseds}
        />
    </FormContainer>
  )
};

export const ResponseSchema = Yup.object().shape({
  movementType: Yup.mixed().required('Selecione o tipo de arquivo'),
  bankContract: Yup.mixed().test('match', '', function(value) {
    const { path, parent, createError } = this;
    const val = value || _.get(parent, 'bankContract'),
      type = _.get(parent, 'movementType');
    if (/^(payment|charge)$/.test(type) && !val) {
      return createError({ path, message: 'Informe o contrato' });
    }
    return true;
  }),
  bank: Yup.mixed().test('match', '', function(value) {
    const { path, parent, createError } = this;
    const val = value || _.get(parent, 'bank'),
      type = _.get(parent, 'movementType');
    if (/^(dda)$/.test(type) && !val) {
      return createError({ path, message: 'Informe o banco' });
    }
    return true;
  }),
  file: Yup.mixed().required('Escolha o arquivo'),
});
