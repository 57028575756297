import React from 'react';
import PropTypes from 'prop-types';
import { Field, ErrorMessage } from 'formik';

const Input = ({type, name, hasError, ...rest}) => (
  <>
    <Field
      id={name}
      name={name}
      >
      {({field}) => (
        <input
          type={type}
          value={field.value || ''}
          className={!!hasError ? "error" : ""}
          {...field}
          {...rest} />
      )}
    </Field>
    <ErrorMessage name={name} className="error" component="div" />
  </>
);

Input.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  hasError: PropTypes.bool,
}

Input.defaultProps = {
  hasError: false
}

export default Input;
