import React from 'react';
import _ from 'lodash'
import Fieldset from '~/components/fieldset';
import { Row } from '~/components/layout';
import { FilterContainer } from '~/components/crud/styles';
import { InputGroup, InputDate, Select, CurrencyField, Autocomplete } from '~/components/form';
import { isBefore, isAfter } from 'date-fns';
import { formats } from '~/helper'

export default function FormFilter({ values, setFieldValue, onListBusinessPartners }) {
  return (
    <FilterContainer>
      <Row span={2}>
        <InputGroup
          type="text"
          name="ourNumber"
          label="Nosso número"
          maxLength={30}
        />

        <Select
          name="status"
          label="Situação"
          options={{
            values: [
              { value: '', label: 'Todas' },
              { value: 'open', label: 'Aberta' },
              { value: 'inShipping', label: 'Em remessa' },
              { value: 'sent', label: 'Enviada' },
              { value: 'registered', label: 'Registrada' },
              { value: 'paid', label: 'Liquidada' },
              { value: 'canceled', label: 'Cancelada' },
            ]
          }}
        />
      </Row>

      <Row>
        <InputGroup
          type="text"
          name="documentNumber"
          label="Número do documento"
          maxLength={30}
          />
      </Row>

      <Row>
        <InputGroup
          type="text"
          name="beneficiary"
          label="CPF/CNPJ do beneficiário"
          maxLength={20}
          />
      </Row>

      <Autocomplete
        name="businessPartner"
        label="Pagador"
        value={_.get(values, 'businessPartner')}
        keyField="id"
        clearable={true}
        valueFormat={(row) => `${formats.cnpj_cpf(row.identity)} - ${row.name}`}
        loadData={onListBusinessPartners}
        emptyText={'Pesquise um pagador'}
        tipText={'Digite... '}
        loadingText={'Carregando...'}
        notFoundText={'Não encontrado'}
      />

      <Fieldset label="Cadastrado em">
        <Row span={2}>
          <InputDate
            name="createdAtStart"
            label="De"
            onChange={value => {
              setFieldValue('createdAtStart', value)
              if (values.createdAtEnd && isAfter(value, values.createdAtEnd)) {
                setFieldValue('createdAtStart', values.createdAtEnd);
                setFieldValue('createdAtEnd', value)
              }
            }}
          />

          <InputDate
            name="createdAtEnd"
            label="Até"
            onChange={value => {
              setFieldValue('createdAtEnd', value)
              if (values.createdAtStart && isBefore(value, values.createdAtStart)) {
                setFieldValue('createdAtStart', value);
                setFieldValue('createdAtEnd', values.createdAtStart);
              }
            }}
          />
        </Row>
      </Fieldset>

      <Fieldset label="Data de Vencimento">
        <Row span={2}>
          <InputDate
            name="dueDateStart"
            label="De"
            onChange={value => {
              setFieldValue('dueDateStart', value)
              if (values.dueDateEnd && isAfter(value, values.dueDateEnd)) {
                setFieldValue('dueDateStart', values.dueDateEnd);
                setFieldValue('dueDateEnd', value)
              }
            }}
          />

          <InputDate
            name="dueDateEnd"
            label="Até"
            onChange={value => {
              setFieldValue('dueDateEnd', value)
              if (values.dueDateStart && isBefore(value, values.dueDateStart)) {
                setFieldValue('dueDateStart', value);
                setFieldValue('dueDateEnd', values.dueDateStart);
              }
            }}
          />
        </Row>
      </Fieldset>

      <Fieldset label="Data de Pagamento">
        <Row span={2}>
          <InputDate
            name="payDateStart"
            label="De"
            onChange={value => {
              setFieldValue('payDateStart', value)
              if (values.payDateEnd && isAfter(value, values.payDateEnd)) {
                setFieldValue('payDateStart', values.payDateEnd);
                setFieldValue('payDateEnd', value)
              }
            }}
          />

          <InputDate
            name="payDateEnd"
            label="Até"
            onChange={value => {
              setFieldValue('payDateEnd', value)
              if (values.payDateStart && isBefore(value, values.payDateStart)) {
                setFieldValue('payDateStart', value);
                setFieldValue('payDateEnd', values.payDateStart);
              }
            }}
          />
        </Row>
      </Fieldset>

      <Fieldset label="Valor (R$)">
        <Row span={2}>
          <CurrencyField
            name="valueStart"
            label="De"
            />

          <CurrencyField
            name="valueEnd"
            label="Até"
            />
        </Row>
      </Fieldset>
    </FilterContainer>
  );
}
